import React, { useEffect } from "react";

import { fetchAbout } from "../../states/actions/fetchAbout";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";


import AboutBanner from './section/about-banner';
import WhyUs from './section/why-us';
import Overview from './section/overview';
import Testimonial from './section/testimonial';
import PartnerLogos from './section/partner-logos';


export default function About() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchAbout.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchAbout.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchAbout());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const about = content;

    return (
        <>
            <AboutBanner data={about.intro} />
            <WhyUs data={about.choose} />
            <Overview data={about.overview} />
            <Testimonial data={about.testimonial_sec} />
            <PartnerLogos data={about.partner_logos} />
        </>
    )
}
