import React from "react";
import { consoleLog } from "../../../../../helpers/api";
import Select from "react-select";

export default function PromotionStep2({
  setFormStep,
  formStep,
  isFormProcessing,
  handleAddNewPrice,
  prices,
  handleRemovePrice,
  handlePriceChange,
  expenses,
}) {
  const options = [
    { label: "First Month", value: "first" },
    { label: "Last Month", value: "last" },
    { label: "2nd Month", value: "2nd Month" },
    { label: "3rd Month", value: "3rd Month" },
    { label: "4th Month", value: "4th Month" },
    { label: "5th Month", value: "5th Month" },
    { label: "6th Month", value: "6th Month" },
    { label: "7th Month", value: "7th Month" },
    { label: "8th Month", value: "8th Month" },
    { label: "9th Month", value: "9th Month" },
    { label: "10th Month", value: "10th Month" },
    { label: "11th Month", value: "11th Month" },
    { label: "12th Month", value: "12th Month" },
    { label: "13th Month", value: "13th Month" },
    { label: "14th Month", value: "14th Month" },
    { label: "15th Month", value: "15th Month" },
    { label: "16th Month", value: "16th Month" },
    { label: "17th Month", value: "17th Month" },
    { label: "18th Month", value: "18th Month" },
    { label: "19th Month", value: "19th Month" },
    { label: "20th Month", value: "20th Month" },
    { label: "21st Month", value: "21st Month" },
    { label: "22nd Month", value: "22nd Month" },
    { label: "23rd Month", value: "23rd Month" },
    { label: "24th Month", value: "24th Month" },
  ];
  console.log(prices?.prices);
  return (
    <>
      <fieldset>
        <div className="listing_c_flex_c">
          <div className=" form_row row flex ">
            <div className="col-xs-12">
              <div className="listing_c_flex flex head_flex listing_c_flex_4 promotion_c_flex">
                <div className="col">
                  <h6>Expense</h6>
                </div>

                <div className="col promotion_discount">
                  <h6>Discount</h6>
                </div>
                <div className="col">
                  <h6>All Cycles</h6>
                </div>
                <div className="col">
                  <h6>Cycles</h6>
                </div>
              </div>
              {prices != undefined &&
              prices !== null &&
              prices?.prices?.length > 0
                ? prices.prices?.map((price, index) => {
                    return (
                      <div className="add_more_term_c" key={price?.randomKey}>
                        <div
                          className="closeBtn"
                          onClick={() => handleRemovePrice(index)}
                        ></div>
                        <div className="listing_c_flex flex listing_c_flex_4 promotion_c_flex">
                          <div className="col">
                            <div className="form_blk">
                              <span className="mini-show-cell">Expense</span>
                              <select
                                name="expense"
                                id="expense"
                                className="txtBox"
                                onChange={(e) => handlePriceChange(index, e)}
                              >
                                <option
                                  value="rent"
                                  selected={
                                    price?.expense === "rent" ? true : false
                                  }
                                >
                                  Rent
                                </option>
                                {expenses?.length > 0
                                  ? expenses?.map((expense, index) => {
                                      return (
                                        <option
                                          value={
                                            expense?.name + ":" + expense?.type
                                          }
                                          selected={
                                            price?.expense === expense?.name
                                              ? true
                                              : false
                                          }
                                          key={index}
                                        >
                                          {expense?.name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                            </div>
                          </div>

                          <div className="col promotion_discount">
                            <div className="form_blk">
                              <span className="mini-show-cell">Discount</span>
                              <input
                                type="text"
                                className="txtBox"
                                name="discount"
                                defaultValue={price?.discount}
                                onChange={(e) => handlePriceChange(index, e)}
                              />
                              <select
                                name="type"
                                id="type"
                                className=""
                                onChange={(e) => handlePriceChange(index, e)}
                              >
                                <option
                                  value="amount"
                                  selected={
                                    price?.type === "amount" ? true : false
                                  }
                                >
                                  $
                                </option>
                                <option
                                  value="percent"
                                  selected={
                                    price?.type === "percent" ? true : false
                                  }
                                >
                                  %
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form_blk">
                              <span className="mini-show-cell">All Cycles</span>
                              <select
                                name="allcycle"
                                id="allcycle"
                                className="txtBox"
                                onChange={(e) => handlePriceChange(index, e)}
                                disabled={
                                  price?.expense_type === "one_time"
                                    ? true
                                    : false
                                }
                              >
                                <option
                                  value="no"
                                  selected={
                                    price?.allcycle === "no" ? true : false
                                  }
                                >
                                  No
                                </option>
                                <option
                                  value="yes"
                                  selected={
                                    price?.allcycle === "yes" ? true : false
                                  }
                                >
                                  Yes
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col">
                            <div className="form_blk">
                              <span className="mini-show-cell">Cycles</span>
                              <Select
                                options={options}
                                defaultValue={options.filter((option) =>
                                  price?.cycle?.includes(option.value)
                                )}
                                onChange={(selectedOption) =>
                                  handlePriceChange(
                                    index,
                                    selectedOption,
                                    "cycle"
                                  )
                                }
                                isMulti
                                isDisabled={
                                  price?.expense_type === "one_time" ||
                                  price?.allcycle === "yes"
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div className="gap_line_divide"></div>
              <div className="bTn justify_center_flex">
                <a
                  href="#!"
                  className="webBtn mini_web_btn"
                  onClick={handleAddNewPrice}
                >
                  <i className="fa fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => setFormStep(formStep - 1)}
          >
            Previous
          </button>
          <button
            type="submit"
            className="webBtn colorBtn"
            disabled={isFormProcessing}
          >
            Save {isFormProcessing ? <i className="spinner"></i> : ""}
          </button>
        </div>
      </fieldset>
    </>
  );
}
