import React, { useEffect } from "react";

import { fetchCategoryBlog } from "../../states/actions/fetchCategoryBlog";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import { useParams } from "react-router-dom";

import BlogGrid from './section/blog-grid';
export default function CategoryPosts() {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchCategoryBlog.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchCategoryBlog.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchCategoryBlog(slug));
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;

    const blog = content;

    return (
        <>
            <BlogGrid data={blog.posts} />

        </>
    )
}
