import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { fetchHome } from "../../states/actions/fetchHome";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";


import BackgroundCheck from './section/background-check';
import Banner from './section/banner';
import ExtraInfoSection from './section/extraInfoSection';
import FeaturedProperties from './section/featured-properties';
import Features from './section/features';
import HowItWorks from './section/how-it-works';
import InfoSection from './section/info_section';
import Locations from './section/locations';
import TopMarkets from './section/top-markets';
import { checkUrl } from "../../helpers/api";
// import { SEO } from '../seo/index';

export default function Home() {
    const setTitle = title => {
        const el = document.querySelector('title');
        el.innerText = `${el.text} | ${title}`;
    };

    const setDescription = desc => {
        const el = document.querySelector("meta[name='description']");
        el.setAttribute('content', desc)
    }
    const setMetaTitle = desc => {
        const el = document.querySelector("meta[name='title']");
        el.setAttribute('content', desc)
    }
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchHome.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchHome.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchHome());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;

    const home = content;
    return (
        <>
            <Helmet>
                <title>{data.page_title + " | " + settings?.site_name}</title>
                <meta name="title" content={settings?.site_name + "-" + home.banner?.heading} />
                <meta name="description" content={settings?.site_name + "-" + home.banner?.search_text} />
                <meta name="keywords" content={data.page_title} />
                <meta property="og:title" content={settings?.site_name + "-" + home.banner?.heading} />
                <meta
                    property="og:descripiton"
                    content={settings?.site_name + "-" + home.banner?.search_text}
                />
                <meta property="og:url" content={window.location.href} />
                <meta
                    property="og:image"
                    content={checkUrl(`${settings.site_domain}/project/public/storage/images/${settings?.site_thumb}`)}
                />
                <meta property="og:type" content="website" />
            </Helmet>
            <Banner data={home.banner} />
            <Features data={home.features} />
            <FeaturedProperties data={home.properties} />
            <HowItWorks data={home.how_it_works} />
            <BackgroundCheck data={home.background_check} />
            <InfoSection data={home.info_section} />
            <ExtraInfoSection data={home.extra_info_section} />
            <Locations data={home.locations} />
            <TopMarkets data={home.markets} />
        </>
    )
}
