import React, { useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";

import { ToastContainer } from "react-toastify";
import {
  fetchUserFavorites,
  deleteUserPropertyFavorite,
} from "../../../states/actions/fetchUserFavorites";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import FavoriteBlk from "./sections/favoriteBlk";
export default function Favorites() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state?.fetchUserFavorites?.content);
  const isLoading = useSelector(
    (state) => state?.fetchUserFavorites?.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  useEffect(() => {
    dispatch(fetchUserFavorites());
  }, []);

  useDocumentTitle("Favorites", "Loftus");
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const properties = data?.properties;

  return (
    <>
      <main index className="main_dashboard">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb page_name="Favorites" />
            <div className="page_body">
              <FavoriteBlk items={properties} />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
