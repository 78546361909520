import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchSingleMaintenanceStaff,
  postStaffPerson,
} from "../../../states/actions/fetchMaintenanceStaff";
import { consoleLog } from "../../../helpers/api";

export default function ViewMAintenanceDetail() {
  const { person_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceStaff.content);
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceStaff.isLoading
  );
  useEffect(() => {
    dispatch(fetchSingleMaintenanceStaff(person_id));
  }, []);
  useDocumentTitle("View Service Staff Details - Loftus");
  if (isLoading) return <LoadingScreen />;

  const staff_row = data?.staff_row;
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <div className="dash_heading_sec staff_head_flex_view">
              <div className="main_page_heading">
                <NavLink to="/">Dashboard</NavLink>
                <span>/</span>
                <NavLink to="/service-staff">Service Staff</NavLink>
                <span>/</span>
                <h2>{staff_row?.fname + " " + staff_row?.lname}</h2>
              </div>

              <div className="bTn">
                <NavLink
                  to={"/edit-maintenance-person/" + person_id}
                  className="webBtn"
                >
                  Edit
                </NavLink>
              </div>
            </div>
            <div className="page_body">
              <div className="maintenance_detail_flex flex">
                <div className="colL">
                  <div className="inner">
                    <div className="image">
                      <img src="/images/dashboard/3.jpg" alt="" />
                    </div>
                    <div className="txt">
                      <h5>{staff_row?.fname + " " + staff_row?.lname}</h5>
                      <p>N/A</p>
                      <a href="">
                        <i className="fa fa-phone"></i>
                        <span>N/A</span>
                      </a>
                      <a href="">
                        <i className="fa fa-envelope"></i>
                        <span>{staff_row?.email}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="colR">
                  <div className="inner">
                    <h4>Assigned Properties</h4>
                    <ul>
                      {staff_row?.properties_arr?.map((property, p_index) => {
                        return (
                          <li key={p_index}>
                            <NavLink to={"/user-property/" + property?.slug}>
                              {property?.label}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
