import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/commonCss.css";
import "./assets/css/bootstrap.min.css";

import "./App.css";
import "./assets/css/dashboard.css";
import "./assets/css/user-dashboard.css";
import "./assets/css/responsive.css";
import "./assets/css/lightgallery.min.css";

import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import store from "./states/store";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
