import React, { useState } from "react";
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from "react-hook-form";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export default function PaymentPopup({ closePopup, siteSettings }) {
    const [checkoutError, setCheckoutError] = useState();
    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    const { register, trigger, handleSubmit, setValue, control, watch, formState: { errors, isValid } } = useForm({ mode: 'all' });
    const watchAllFields = watch();
    let stripePromise = ''
    if (siteSettings.site_stripe_type === 1 || siteSettings.site_stripe_type === '1') {
        stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
    }
    else if (siteSettings.site_stripe_type === 0 || siteSettings.site_stripe_type === '0') {
        stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
    }
    return <>
        <section className="popup review_popup" data-popup="send-message">
            <div className="tableDv">
                <div className="tableCell">

                    <div className="_inner">
                        <div className="crosBtn" onClick={closePopup}></div>
                        <h3>Proceed with payment</h3>
                        <Elements stripe={stripePromise}>
                            <form action="" method="POST" >
                                <div className="row formRow">
                                    <div className="col-xs-6">
                                        <h6>Card Holder Name</h6>
                                        <div className="form_blk deposit_form_blk">
                                            <input type="text" name="card_holder_name" id="card_holder_name" className="txtBox"  {...register("card_holder_name")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="card_holder_name"
                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Elements>
                    </div>
                </div>
            </div>
        </section>
    </>;
}
