import React from 'react'
import AddBranchForm from './add-branch-form'

export default function AddBranchBlk({ changeStep, step, amenities, handleBranch, isFormProcessing, branch_row, states }) {

    return (
        <>
            <div className="page_body">
                <div className="option_lbl small_opt_lbl justify_center_lbl_cell">
                    <ul>
                        <li className={step === 0 ? "active" : ""}>Step 1</li>
                        <li className={step === 1 ? "active" : ""}>Step 2</li>
                        <li className={step === 2 ? "active" : ""}>Step 3</li>
                    </ul>
                </div>
                <div className="dash_form_blk">
                    <AddBranchForm changeStep={changeStep} step={step} amenities={amenities} isFormProcessing={isFormProcessing} handleBranch={handleBranch} branch_row={branch_row} states={states} />
                </div>
            </div>
        </>
    )
}
