import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  consoleLog,
  imageValidation,
  projectImageUpload,
} from "../../../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import Thumbnail from "../../../common/thumbnail";

import { useSelector, useDispatch } from "react-redux";
import { postStaffPerson } from "../../../../states/actions/fetchMaintenanceStaff";

export default function MaintenanceForm({
  properties,
  staff_row,
  person_id = null,
  is_staff_edit_popup=false
}) {
  const dispatch = useDispatch();
  const handleSavePerson = (frmData) => {
    frmData={...frmData,is_staff_edit_popup:is_staff_edit_popup}
    dispatch(postStaffPerson(frmData, person_id));
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceStaff.isFormProcessing
  );
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();

 

  useEffect(() => {
    if (staff_row?.id > 0) {
      setValue("fname", staff_row?.fname);
      setValue("lname", staff_row?.lname);
      setValue("email", staff_row?.email);
      setValue("offering_rate", staff_row?.offering_rate ? staff_row?.offering_rate : "");
      setValue("property", staff_row?.properties_arr);
    }
  }, [staff_row]);
  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      property: JSON.stringify(frmData?.property),type:"direct"
    };
    handleSavePerson(frmData);
  };
  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row form_row">
          <div className="col-xs-12 txtGrp">
            {/* <h6>Email</h6> */}
            <div className="form_blk">
              <input
                type="text"
                name=""
                id=""
                placeholder="Email"
                className="txtBox"
                {...register("email", {
                  required: "Required",
                  pattern: {
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                    message: "Email format is not valid!",
                  },
                })}
                disabled={staff_row?.id > 0 ? true : false}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12 txtGrp">
            {/* <h6>Email</h6> */}
            <div className="form_blk">
              <input
                type="text"
                name=""
                id=""
                placeholder="Offering Rate"
                className="txtBox"
                {...register("offering_rate", {
                  required: "Required"})}
              />
              <ErrorMessage
                errors={errors}
                name="offering_rate"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12 txtGrp">
            {/* <h6>Properties</h6> */}
            <div className="form_blk">
              <Controller
                {...register("property", { required: "Required" })}
                name="property"
                id="property"
                className=""
                control={control}
                defaultValue={watchAllFields?.property}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={properties}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      isMulti
                      label="Select Property"
                      value={properties?.filter(function (option) {
                        return value?.some(
                          (selected) => selected.value === option.value
                        );
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="property"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="bTn justify_btn_center">
          <button
            className="button webBtn colorBtn"
            disabled={
              isFormProcessing === true
                ? true
                : false
            }
          >
            Submit{" "}
            {isFormProcessing === true ? (
              <i className="spinner"></i>
            ) : (
              ""
            )}
          </button>
        </div>
      </form>
    </>
  );
}
