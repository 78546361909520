import {
  FETCH_LOGIN_CONTENT,
  FETCH_LOGIN_CONTENT_SUCCESS,
  FETCH_LOGIN_CONTENT_FAILED,
  LOGIN_MESSAGE,
  LOGIN_MESSAGE_SUCCESS,
  LOGIN_MESSAGE_FAILED,
  GOOGLE_LOGIN_MESSAGE,
  GOOGLE_LOGIN_MESSAGE_SUCCESS,
  GOOGLE_LOGIN_MESSAGE_FAILED,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  isGoogleProcessing: false,
  emptyFormData: false,
  authToken: localStorage.getItem("authToken"),
  mem_type: localStorage.getItem("mem_type"),
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_LOGIN_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_LOGIN_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_LOGIN_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case LOGIN_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };

    case LOGIN_MESSAGE_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      localStorage.setItem("mem_type", payload.mem_type);
      if (payload?.mem_permissions) {
        localStorage.setItem("mem_permissions", payload.mem_permissions);
      }
      if (payload?.super_admin) {
        localStorage.setItem("super_admin", payload.super_admin);
      }
      if (payload?.sub_admin) {
        localStorage.setItem("sub_admin", payload.sub_admin);
      }
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case LOGIN_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case GOOGLE_LOGIN_MESSAGE:
      return {
        ...state,
        isGoogleProcessing: true,
      };
    case GOOGLE_LOGIN_MESSAGE_SUCCESS:
      localStorage.setItem("authToken", payload.authToken);
      localStorage.setItem("mem_type", payload.mem_type);
      return {
        ...state,
        isGoogleProcessing: false,
        emptyFormData: true,
      };
    case GOOGLE_LOGIN_MESSAGE_FAILED:
      return {
        ...state,
        isGoogleProcessing: false,
        error: payload,
      };

    default:
      return state;
  }
}
