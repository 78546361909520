import React from "react";

export default function ContactSort({ handleBranchChange, branches }) {
  return (
    <>
      <div class="filter_status_offer cell_status_offer_cell listingFilterMargin">
        <div class="col_ofer_filter">
          <div class="sorted_heading">
            <span>Branch :</span>
          </div>
          <div class="sorted_drop_down">
            <select
              name="branch_id"
              id="branch_id"
              className="txtBox"
              onChange={(e) => handleBranchChange(e, "branch_id")}
            >
              <option value="all">All</option>
              {branches?.map((branch, index) => {
                return <option value={branch?.value}>{branch?.label}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
