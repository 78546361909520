import React from "react";
import { NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";

export default function FloorStep1({
  changeStep,
  step,
  branches,
  control,
  register,
  isValid,
  errors,
  floor_plan_row,
  trigger,
  watchAllFields,
}) {
  //
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Branch</h6>
            <div className="form_blk">
              <Controller
                {...register("branch", { required: "Required" })}
                name="branch"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row.branch
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={branches}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      defaultValue={branches.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="branch"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Floor Plan</h6>
            <div className="form_blk">
              <input
                type="text"
                name="floor_plan"
                id="floor_plan"
                className="txtBox"
                {...register("floor_plan", { required: "Required" })}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row.floor_plan
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="floor_plan"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <NavLink to="/floor-plans" className="webBtn cancleBtn">
            Cancel
          </NavLink>
          {watchAllFields?.branch !== undefined &&
          watchAllFields?.branch !== null &&
          watchAllFields?.branch !== "" &&
          watchAllFields?.floor_plan !== undefined &&
          watchAllFields?.floor_plan !== null &&
          watchAllFields?.floor_plan !== "" ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
