import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";

export default function LoginForm({
  data,
  handleLogin,
  isFormProcessing,
  emptyFormData,
  onFailure,
  onSuccess,
  isGoogleProcessing,
}) {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "424406682782-ermmvl6s2uc4fnuif45gf3commgkbnuo.apps.googleusercontent.com",
        scope: "email",
        plugin_name: "PLUGIN",
      });
    }
    gapi.load("client:auth2", start);
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    handleLogin(data);
  };
  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <h3>{data.heading}</h3>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Email Address
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className="txtBox"
            {...register("email", {
              required: "Required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                message: "Email format is not valid!",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp pasDv">
          <label htmlFor="" className="move">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="txtBox"
            {...register("password", {
              required: "Required",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>

        <div className="txtGrp flex">
          <div className="lblBtn">
            <input
              type="checkbox"
              name="remeberMe"
              id="remember"
              defaultChecked
            />
            <label htmlFor="remember">Remember me</label>
          </div>
          <Link to="/forgot-password" id="pass">
            Forgot Password?
          </Link>
        </div>
        <div className="bTn text-center">
          <button
            type="submit"
            className="webBtn blockBtn icoBtn popBtn"
            data-popup="show-phone"
            disabled={isFormProcessing === true ? "disabled" : ""}
          >
            {isFormProcessing === true ? (
              <i className="spinner"></i>
            ) : (
              "Sign in"
            )}
          </button>
        </div>

        <div className="oRLine">
          <span>OR</span>
        </div>
        <div className="socialBtn flex">
          {/* <button type="button" className="gmBtn"><Image src={data.google_img} alt={data.heading} /> Sign in with Google</button> */}
          <GoogleLogin
            clientId="424406682782-ermmvl6s2uc4fnuif45gf3commgkbnuo.apps.googleusercontent.com"
            buttonText={
              isGoogleProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Sign in with Google"
              )
            }
            className="gmBtn"
            onSuccess={onSuccess}
            onFailure={onFailure}
            disabled={isGoogleProcessing === true ? "disabled" : ""}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </form>
    </>
  );
}
