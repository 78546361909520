import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

export default function RentalListingStep2({ step, changeStep, register, isValid, errors, control, trigger }) {

    return (
        <>
            <fieldset>
                <h4 className="screening_heading_af">Are applicants required to provide their screening results with their offer? Screening results include credit, criminal, eviction proceedings, and ID verification reports.  </h4>
                <div className="form_row row justify_center_flex">
                    <div className="col-xs-6">
                        <ul className="total_count_price_listing full_wide_price_listing rent_listing_package">
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="N" id="none" {...register("screening", { required: "Required" })} />
                                    <label htmlFor="none">No</label>
                                </div>
                            </li>
                            {/* <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CC" id="credit-criminal" {...register("screening", { required: "Required" })} />
                                    <label htmlFor="credit-criminal">Credit & Criminal</label>
                                </div>
                            </li>
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CCE" id="credit-criminal-evictions" {...register("screening", { required: "Required" })} />
                                    <label htmlFor="credit-criminal-evictions">Credit & Criminal and Evictions</label>
                                </div>
                            </li>
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CCI" id="credit-criminal-id" {...register("screening", { required: "Required" })} />
                                    <label htmlFor="credit-criminal-id">Credit & Criminal and ID</label>
                                </div>
                            </li> */}
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CCEI" id="credit-criminal-evictions-id" checked {...register("screening", { required: "Required" })} />
                                    <label htmlFor="credit-criminal-evictions-id">Yes</label>
                                </div>
                            </li>
                        </ul>
                        <ErrorMessage
                            errors={errors}
                            name="screening"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>

                <div className="cmnBtn text-center">
                    <button type="button" className="webBtn backBtn" onClick={() => changeStep(step - 1)}>Previous</button>
                    {
                        !isValid ?
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                trigger();
                            }} >Next</button>
                            :
                            <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)} disabled={!isValid}>Next</button>
                    }
                </div>
            </fieldset>
        </>
    )
}
