import React, { useState, useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import OfferTabs from "./sections/offers/tabs";
import OfferTabGrids from "./sections/offers/tabs-grid";

import { fetchUserOffers } from "../../../states/actions/fetchUserOffers";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { getApiData, postData } from "../../../helpers/api";
import OfferSort from "./sections/offers/sort";
import Sidebar from "../dashboard-company/sections/sidebar";

export default function Offers({ mem_type }) {
  const [offerState, setOfferState] = useState({
    property_id: "all",
    listing_type: "all",
    offer_status: "all",
    role: "my_offers",
  });
  const [offerType, setOfferType] = useState(
    mem_type === "company" ? "received" : "my_offers"
  );
  const [receivedOfferType, setReceivedOfferType] = useState("pending");
  const [isSortLoading, setIsSortLoading] = useState(false);
  const [myOfferType, setMyOfferType] = useState("incomplete");
  const changeType = (type) => {
    setOfferType(type);
  };
  const changeReceivedType = (type) => {
    setReceivedOfferType(type);
  };
  const changeMyOffersType = (type) => {
    setMyOfferType(type);
  };
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchUserOffers.content);
  const isLoading = useSelector((state) => state.fetchUserOffers.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  useEffect(() => {
    dispatch(fetchUserOffers());
    dispatch(fetchSiteSettings());
  }, []);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const [offersArr, setOffersArr] = useState({
    my_offers: [],
    pending_offers: [],
    accepted_offers: [],
    rejected_offers: [],
    received_offers: [],
    loading: true,
    received_offers_count: 0,
    my_offers_count: 0,
    received_accepted_offers: [],
    received_rejected_offers: [],
  });
  useEffect(() => {}, [offerState]);
  async function handlePropertyChange(e, input_type) {
    let input_value = e.target.value;
    setIsSortLoading(true);
    if (input_type === "re_property_id") {
      setOfferState({ ...offerState, property_id: e.target.value });
    } else if (input_type === "my_property_id") {
      setOfferState({ ...offerState, property_id: e.target.value });
    } else {
      setOfferState({ ...offerState, [e.target.name]: e.target.value });
    }
    if (input_type === "role") {
      setOfferState({
        ...offerState,
        property_id: "all",
        [e.target.name]: e.target.value,
      });
    }

    var form_data = new FormData();

    if (input_type === "role") {
      form_data.append("role", input_value);
      form_data.append("property_id", "all");
      form_data.append("listing_type", offerState?.listing_type);
      setOfferType(input_value);
    }
    if (input_type === "my_listing_type") {
      form_data.append("listing_type", input_value);
      form_data.append("property_id", offerState?.property_id);
      form_data.append("role", offerState?.role);
    }
    if (input_type === "listing_type") {
      form_data.append("listing_type", input_value);
      form_data.append("property_id", offerState?.property_id);
      form_data.append("role", offerState?.role);
    }
    if (input_type === "re_listing_type") {
      form_data.append("listing_type", input_value);
      form_data.append("property_id", offerState?.property_id);
      form_data.append("role", offerState?.role);
    }
    if (input_type === "my_property_id") {
      form_data.append("property_id", input_value);
      form_data.append("listing_type", offerState?.listing_type);
      form_data.append("role", offerState?.role);
    }
    if (input_type === "re_property_id") {
      form_data.append("property_id", input_value);
      form_data.append("listing_type", offerState?.listing_type);
      form_data.append("role", offerState?.role);
    }

    form_data.append("type", offerType);
    // setOffersArr({ ...offersArr, loading: true });
    await postData("api/get-property-offers", form_data).then((data) => {
      setIsSortLoading(false);
      if (data?.status === 1) {
        if (data?.offer_type === "received") {
          // if (data?.received_offers?.length <= 0) {
          //   if (data?.received_accepted_offers?.length <= 0) {
          //     if (data?.received_rejected_offers?.length <= 0) {
          //     } else {
          //       changeReceivedType("rejected");
          //     }
          //   } else {
          //     changeReceivedType("accepted");
          //   }
          // } else if (data?.offers?.length > 0) {
          //   changeReceivedType("pending");
          // }
          changeReceivedType(data?.received_offer_tab_type);
          setOffersArr({
            // ...offersArr,
            received_offers: data?.received_offers,
            received_offers: data?.received_offers,
            received_offers_count: data?.received_offers_count,
            received_accepted_offers: data?.received_accepted_offers,
            received_rejected_offers: data?.received_rejected_offers,
            loading: false,
          });
        } else if (data?.offer_type === "my_offers") {
          // if (data?.offers?.length <= 0) {
          //   if (data?.pending_offers?.length <= 0) {
          //     if (data?.accepted_offers?.length <= 0) {
          //       if (data?.rejected_offers?.length <= 0) {
          //       } else {
          //         changeMyOffersType("rejected");
          //       }
          //     } else {
          //       changeMyOffersType("accepted");
          //     }
          //   } else {
          //     changeMyOffersType("pending");
          //   }
          // } else if (data?.offers?.length > 0) {
          //   changeMyOffersType("incomplete");
          // }
          changeMyOffersType(data?.my_offers_offer_tab_type);
          setOffersArr({
            // ...offersArr,
            my_offers: data?.offers,
            pending_offers: data?.pending_offers,
            accepted_offers: data?.accepted_offers,
            rejected_offers: data?.rejected_offers,
            loading: false,
          });
        }
      }
    });
    // setProperty(properties.properties);
  }
  useEffect(() => {
    if (
      data?.offers !== undefined &&
      data?.offers !== null &&
      data?.received_offers !== undefined &&
      data?.received_offers !== null
    ) {
      setOffersArr({
        ...offersArr,
        my_offers: data?.offers,
        pending_offers: data?.pending_offers,
        accepted_offers: data?.accepted_offers,
        rejected_offers: data?.rejected_offers,
        received_offers: data?.received_offers,
        loading: false,
        received_offers_count: data?.received_offers_count,
        received_accepted_offers: data?.received_accepted_offers,
        received_rejected_offers: data?.received_rejected_offers,
        my_offers_count: data?.offers_count,
      });
      updateOffersTabStatus(
        data?.offers,
        data?.pending_offers,
        data?.accepted_offers,
        data?.rejected_offers,
        data?.received_offers,
        data?.received_accepted_offers,
        data?.received_rejected_offers
      );
    }
  }, [data]);

  const updateOffersTabStatus = (
    offers,
    pending_offers,
    accepted_offers,
    rejected_offers,
    received_offers,
    received_accepted_offers,
    received_rejected_offers
  ) => {
    if (offers?.length > 0) {
      changeMyOffersType("incomplete");
    } else if (pending_offers?.length > 0) {
      changeMyOffersType("pending");
    } else if (accepted_offers?.length > 0) {
      changeMyOffersType("accepted");
    } else if (rejected_offers?.length > 0) {
      changeMyOffersType("rejected");
    } else {
      changeMyOffersType("no_sent_offers");
    }
    if (received_offers?.length > 0) {
      setReceivedOfferType("pending");
    } else if (received_accepted_offers?.length > 0) {
      setReceivedOfferType("accepted");
    } else if (received_rejected_offers?.length > 0) {
      setReceivedOfferType("rejected");
    } else {
      setReceivedOfferType("no_received_offers");
    }
  };
  useEffect(() => {}, [offerState]);
  useDocumentTitle("Offers - Loftus");
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;

  return (
    <>
      {mem_type === "company" ? (
        <div index="" className="main_dashboard commercial_dashboard_new">
          <section className="dashboard">
            <div className="contain">
              <div className="flex dash_intro_flex">
                <div className="colL">
                  <Sidebar active="offers" />
                </div>
                <div className="colR">
                  <Breadcrumb page_name="Offers" />
                  <div className="offer_dash_page leases_page_dash rental-offer-page-dt">
                    <div className="outer_list hide_sm_clmn whole_lst_lnk offers_outer_list">
                      <div className="tab-content relative">
                        {offersArr?.loading === true ||
                        isSortLoading === true ? (
                          <div className="loadingDisabled">
                            <i className="spinner"></i>
                          </div>
                        ) : (
                          ""
                        )}
                        {offerType === "received" ? (
                          <OfferSort
                            my_properties={data?.my_properties}
                            handlePropertyChange={handlePropertyChange}
                            changeReceivedType={changeReceivedType}
                            receivedOfferType={receivedOfferType}
                            pending_count={offersArr?.received_offers?.length}
                            accepted_count={
                              offersArr?.received_accepted_offers?.length
                            }
                            rejected_count={
                              offersArr?.received_rejected_offers?.length
                            }
                            type={offerType}
                            offerState={offerState}
                            mem_type="company"
                          />
                        ) : (
                          ""
                        )}
                        {offerType === "received" ? (
                          receivedOfferType === "pending" &&
                          offersArr?.received_offers?.length > 0 ? (
                            <OfferTabGrids
                              items={offersArr?.received_offers}
                              mem_type={mem_type}
                            />
                          ) : receivedOfferType === "accepted" &&
                            offersArr?.received_accepted_offers?.length > 0 ? (
                            <OfferTabGrids
                              items={offersArr?.received_accepted_offers}
                              mem_type={mem_type}
                            />
                          ) : receivedOfferType === "rejected" &&
                            offersArr?.received_rejected_offers?.length > 0 ? (
                            <OfferTabGrids
                              items={offersArr?.received_rejected_offers}
                              mem_type={mem_type}
                            />
                          ) : receivedOfferType === "no_received_offers" ? (
                            <div className="alert alert-danger">
                              No offers found!
                            </div>
                          ) : (
                            <div className="alert alert-danger">
                              No offers found!
                            </div>
                          )
                        ) : (
                          <div className="alert alert-danger">
                            No offers found!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <main index className="main_dashboard">
          <section className="dashboard">
            <div className="contain">
              <Breadcrumb page_name="Offers" />
              <div className="page_body">
                <div className="offer_dash_page leases_page_dash rental-offer-page-dt">
                  <OfferTabs
                    type={offerType}
                    handleType={changeType}
                    active_count={
                      offersArr?.received_offers?.length +
                      offersArr?.received_accepted_offers?.length +
                      offersArr?.received_rejected_offers?.length
                    }
                    pending_count={
                      offersArr?.my_offers_count > 0
                        ? offersArr?.my_offers_count
                        : "0"
                    }
                    mem_type={mem_type}
                  />
                  <div className="outer_list hide_sm_clmn whole_lst_lnk offers_outer_list">
                    <div className="tab-content relative">
                      {offersArr?.loading === true || isSortLoading === true ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {offerType === "received" ? (
                        <OfferSort
                          my_properties={data?.my_properties}
                          handlePropertyChange={handlePropertyChange}
                          changeReceivedType={changeReceivedType}
                          receivedOfferType={receivedOfferType}
                          pending_count={offersArr?.received_offers?.length}
                          accepted_count={
                            offersArr?.received_accepted_offers?.length
                          }
                          rejected_count={
                            offersArr?.received_rejected_offers?.length
                          }
                          type={offerType}
                          total_landlord_count={
                            offersArr?.received_offers?.length +
                            offersArr?.received_accepted_offers?.length +
                            offersArr?.received_rejected_offers?.length
                          }
                          total_tenant_count={
                            offersArr?.my_offers?.length +
                            offersArr?.pending_offers?.length +
                            offersArr?.accepted_offers?.length +
                            offersArr?.rejected_offers?.length
                          }
                          handleType={changeType}
                          offerState={offerState}
                        />
                      ) : offerType === "my_offers" ? (
                        <OfferSort
                          received_properties={data?.received_properties}
                          handlePropertyChange={handlePropertyChange}
                          changeReceivedType={changeMyOffersType}
                          receivedOfferType={myOfferType}
                          incomplete_count={offersArr?.my_offers?.length}
                          pending_count={offersArr?.pending_offers?.length}
                          accepted_count={offersArr?.accepted_offers?.length}
                          rejected_count={offersArr?.rejected_offers?.length}
                          type={offerType}
                          total_landlord_count={
                            offersArr?.received_offers?.length +
                            offersArr?.received_accepted_offers?.length +
                            offersArr?.received_rejected_offers?.length
                          }
                          total_tenant_count={
                            offersArr?.my_offers?.length +
                            offersArr?.pending_offers?.length +
                            offersArr?.accepted_offers?.length +
                            offersArr?.rejected_offers?.length
                          }
                          handleType={changeType}
                          offerState={offerState}
                        />
                      ) : (
                        ""
                      )}
                      {offerType === "received" ? (
                        receivedOfferType === "pending" &&
                        offersArr?.received_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.received_offers}
                            mem_type={mem_type}
                          />
                        ) : receivedOfferType === "accepted" &&
                          offersArr?.received_accepted_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.received_accepted_offers}
                            mem_type={mem_type}
                          />
                        ) : receivedOfferType === "rejected" &&
                          offersArr?.received_rejected_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.received_rejected_offers}
                            mem_type={mem_type}
                          />
                        ) : receivedOfferType === "no_received_offers" ? (
                          <div className="alert alert-danger">
                            No offers found!
                          </div>
                        ) : offerState?.property_id !== "all" ||
                          offerState?.listing_type !== "all" ? (
                          <div className="alert alert-danger">
                            No Offers found!
                          </div>
                        ) : (
                          <div className="alert alert-danger">
                            No offers found!
                          </div>
                        )
                      ) : offerType === "my_offers" && mem_type === "member" ? (
                        myOfferType === "incomplete" &&
                        offersArr?.my_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.my_offers}
                            mem_type={mem_type}
                          />
                        ) : myOfferType === "pending" &&
                          offersArr?.pending_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.pending_offers}
                            mem_type={mem_type}
                          />
                        ) : myOfferType === "accepted" &&
                          offersArr?.accepted_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.accepted_offers}
                            mem_type={mem_type}
                          />
                        ) : myOfferType === "rejected" &&
                          offersArr?.rejected_offers?.length > 0 ? (
                          <OfferTabGrids
                            items={offersArr?.rejected_offers}
                            mem_type={mem_type}
                          />
                        ) : myOfferType === "no_sent_offers" ? (
                          <div className="alert alert-danger">
                            No offers found!
                          </div>
                        ) : offerState?.property_id !== "all" ||
                          offerState?.listing_type !== "all" ? (
                          <div className="alert alert-danger">
                            No Offers found!
                          </div>
                        ) : (
                          <div className="alert alert-danger">
                            No offers found!
                          </div>
                        )
                      ) : (
                        <div className="alert alert-danger">
                          No offers found!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}
