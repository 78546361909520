import React from "react";
import { ErrorMessage } from "@hookform/error-message";

export default function SendOfferStep1({
  step,
  changeStep,
  tenants,
  handleAddTenant,
  handleTenantChange,
  handleRemoveTenant,
  tenantsVerified,
  handleVerifyTenants,
  tenantsVerifiedLoading,
  trigger,
  offer_row = false,
  removeTenant = false,
  tenantLoading,
}) {
  return (
    <>
      <fieldset>
        <div className="medium_blk relative">
          {removeTenant != false && removeTenant === true ? (
            <div className="loadingDisabled">
              <i className="spinner"></i>
            </div>
          ) : (
            ""
          )}
          {tenantLoading === true ? (
            <div className="loadingDisabled">
              <i className="spinner"></i>
            </div>
          ) : (
            ""
          )}
          <h4 className="ofer_heading">Add additional tenant(s):</h4>
          {tenants !== undefined && tenants !== null
            ? tenants.tenants?.map((tenant, index) => {
                return (
                  <>
                    <div
                      className="form_row row add_tenants"
                      key={index}
                      data-index={index}
                    >
                      {/* {
                                            index !== 0 || (offer_row !== false && offer_row?.id > 0) ?
                                                <div className="closeBtn" onClick={(e) => handleRemoveTenant(index, e)}></div>
                                                :

                                                <div className="closeBtn" onClick={(e) => handleRemoveTenant(index, e)}></div>
                                        } */}
                      <div
                        className="closeBtn"
                        onClick={(e) => handleRemoveTenant(index, e)}
                      ></div>
                      <div className="col-xs-4">
                        <div className="form_blk">
                          <input
                            type="text"
                            name="fname"
                            id="fname"
                            className="txtBox"
                            placeholder="First Name"
                            onChange={(e) => handleTenantChange(index, e)}
                            defaultValue={tenant.fname}
                            disabled={
                              offer_row !== false &&
                              offer_row?.id > 0 &&
                              tenant?.disabled !== false
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className="form_blk">
                          <input
                            type="text"
                            name="lname"
                            id="lname"
                            className="txtBox"
                            placeholder="Last Name"
                            onChange={(e) => handleTenantChange(index, e)}
                            defaultValue={tenant.lname}
                            disabled={
                              offer_row !== false &&
                              offer_row?.id > 0 &&
                              tenant?.disabled !== false
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xs-4">
                        <div className="form_blk">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="txtBox"
                            placeholder="Tenants Email"
                            onChange={(e) => handleTenantChange(index, e)}
                            defaultValue={tenant.email.split(" ").join("")}
                            disabled={
                              offer_row !== false &&
                              offer_row?.id > 0 &&
                              tenant?.disabled !== false
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
          <div
            className="add_more_tenats auto_plus_mrgn"
            onClick={handleAddTenant}
          >
            <i className="fa fa-plus"></i>
          </div>
          {tenants !== undefined &&
          tenants !== null &&
          tenants.tenants?.length > 0 &&
          tenants.tenants[0]?.fname !== "" ? (
            <div className="medium_blk">
              <div className="tenants_lbl flex">
                {tenants.tenants?.map((tenant, index) => {
                  return (
                    <>
                      {tenant.fname !== "" || tenant.lname !== "" ? (
                        <span key={index}>
                          {tenant.fname + " " + tenant.lname}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="bTn cmnBtn text-center offering_send_btns bTn_single">
            {
              // tenantsVerified === false && (tenants !== undefined && tenants !== null && tenants.tenants?.length > 0 && tenants.tenants[0]?.fname !== '') ?
              tenants !== undefined &&
              tenants !== null &&
              tenants.tenants?.length > 0 &&
              tenants.tenants[0]?.fname !== "" ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => handleVerifyTenants()}
                  disabled={tenantsVerifiedLoading === true ? true : false}
                >
                  Next{" "}
                  <i
                    className={
                      tenantsVerifiedLoading === true
                        ? "spinner"
                        : "spinner hidden"
                    }
                  ></i>
                </button>
              ) : (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => changeStep(step + 1)}
                >
                  Next
                </button>
              )
            }
          </div>
        </div>
      </fieldset>
    </>
  );
}
