import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import { useParams } from "react-router-dom";

import {
  fetchSinglePromotion,
  postPromotion,
  getExistingPropertyPromotion,
  emptyStatusValue,
} from "../../../states/actions/fetchSinglePromotions";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import AddPromotionForm from "./promotions/form";
export default function AddPromotion() {
  const { promotion_id } = useParams();
  const [formStep, setFormStep] = useState(0);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSinglePromotions.content);
  const isPropertyExisted = useSelector(
    (state) => state.fetchSinglePromotions.isPropertyExisted
  );
  const expenses = useSelector((state) => state.fetchSinglePromotions.expenses);
  const isPropertyExistedStatus = useSelector(
    (state) => state.fetchSinglePromotions.isPropertyExistedStatus
  );
  const isLoading = useSelector(
    (state) => state.fetchSinglePromotions.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSinglePromotions.isFormProcessing
  );
  const isPropertyExistedLoading = useSelector(
    (state) => state.fetchSinglePromotions.isPropertyExistedLoading
  );
  useEffect(() => {
    dispatch(fetchSinglePromotion(promotion_id));
  }, []);
  const handleSubmitForm = (frmData) => {
    // ; return;
    dispatch(postPromotion(frmData, promotion_id));
  };
  useEffect(() => {
    if (promotion_id > 0 && data?.promotion_row === null) {
      window.location.href = "/promotions";
    }
  }, [promotion_id, data?.promotion_row]);

  useDocumentTitle(
    data?.promotion_row?.id > 0
      ? "Edit Promotion - Loftus"
      : "Add Promotion - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const promotion_row = data?.promotion_row;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="promotions" />
              </div>
              <div className="colR ">
                <Breadcrumb
                  page_name={
                    promotion_id > 0 ? "Edit Promotion" : "Add Promotion"
                  }
                  parent_name="Promotions"
                  parent_link="/promotions"
                />
                <div className="page_body">
                  <div className="option_lbl">
                    <ul>
                      <li className={formStep === 0 ? "active" : ""}>Step 1</li>
                      <li className={formStep === 1 ? "active" : ""}>Step 2</li>
                    </ul>
                  </div>
                  <div className="dash_form_blk">
                    <AddPromotionForm
                      formStep={formStep}
                      setFormStep={setFormStep}
                      branches={branches}
                      handleSubmitForm={handleSubmitForm}
                      isFormProcessing={isFormProcessing}
                      promotion_row={promotion_row}
                      isPropertyExisted={isPropertyExisted}
                      isPropertyExistedLoading={isPropertyExistedLoading}
                      getExistingPropertyPromotion={
                        getExistingPropertyPromotion
                      }
                      isPropertyExistedStatus={isPropertyExistedStatus}
                      emptyStatusValue={emptyStatusValue}
                      expenses={expenses}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
