import React from "react";
import { consoleLog, downloadCSV } from "../../../../helpers/api";

export default function ListingFilter({
  branches,
  handleBranchChange,
  is_property,
  properties,
}) {
  const downloadPropertiesCsv = (e) => {
    e.preventDefault();
    const data = [];
    data.push(["Branch ID", "Branch", "Property ID", "Property Address"]);

    for (let i = 0; i < properties?.length; i++) {
      const item = properties[i];
      data.push([item.branch_id, item.branch, item.id, item.address]);
    }
    downloadCSV(data, "branch-properties");
  };
  return (
    <>
      <div
        className={
          is_property
            ? "filter_status_offer cell_status_offer_cell listingFilterMargin"
            : "filter_status_offer cell_status_offer_cell"
        }
      >
        {properties?.length > 0 ? (
          <button
            className="lst_download_icon"
            type="button"
            onClick={downloadPropertiesCsv}
          >
            <img src="/images/download.png" alt="" />
          </button>
        ) : (
          ""
        )}
        {branches != undefined && branches !== null && branches?.length > 0 ? (
          <div className="col_ofer_filter">
            <div className="sorted_heading">
              <span>Branch :</span>
            </div>
            <div className="sorted_drop_down">
              <select
                name=""
                id=""
                className="txtBox"
                onChange={handleBranchChange}
              >
                <option value="all">All</option>

                {branches?.map((branch, index) => {
                  return <option value={branch?.value}>{branch?.label}</option>;
                })}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
