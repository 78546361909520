import React, { useEffect } from "react";
import ResetPasswordForm from "./section/reset-password-form";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import {
  fetchResetPassword,
  postReset,
} from "../../states/actions/fetchResetPassword";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

export default function ResetPassword() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const handleResetPassword = (formData) => {
    dispatch(postReset(formData, token));
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchResetPassword.isFormProcessing
  );
  const emptyFormData = useSelector(
    (state) => state.fetchResetPassword.emptyFormData
  );
  const data = useSelector((state) => state.fetchResetPassword.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector((state) => state.fetchResetPassword.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");
  useEffect(() => {
    if (token && authToken) {
      localStorage.clear();
      window.location.reload();
    }
  }, [token, authToken]);

  useEffect(() => {
    dispatch(fetchResetPassword(token));
    dispatch(fetchSiteSettings());
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let content = data.content;
  const intro = content;

  return (
    <>
      <section className="logon">
        <div className="contain">
          <div className="log_blk">
            <ResetPasswordForm
              data={intro}
              handleResetPassword={handleResetPassword}
              isFormProcessing={isFormProcessing}
              emptyFormData={emptyFormData}
            />

            <div className="haveAccount text-center resetAccount">
              <span>Don’t have an account?</span>
              <Link to="/signup">Sign up</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
