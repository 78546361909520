import React, { useEffect } from "react";

import { fetchTerms } from "../../states/actions/fetchTerms";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

export default function TermsConditions() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchTerms.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchTerms.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchTerms());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.content) ? data.content.page_title : data.content, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;

    return (
        <>
            <section className="textual_page">
                <div className="contain">
                    <div className="text_blk">
                        <div className="sec_heading">
                            <h2>{content.page_title}</h2>
                        </div>
                        <div className="ckEditor">
                            <div dangerouslySetInnerHTML={{ __html: content.policy_text }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
