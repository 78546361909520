import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";
import {
  FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT,
  FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS,
  FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED,
  SEARCH_COMMERCIAL_LISTINGS,
  SEARCH_COMMERCIAL_LISTINGS_SUCCESS,
  SEARCH_COMMERCIAL_LISTINGS_FAILED,
} from "./actionTypes";

export const fetchCommercialFrontendProperty =
  (slug, commercialGetParams) => (dispatch) => {
    dispatch({
      type: FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT,
      payload: null,
    });
    http
      .post(
        "commercial-property-details-page/" + slug,
        helpers.doObjToFormData(commercialGetParams)
      )
      .then(({ data }) => {
        dispatch({
          type: FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED,
          payload: error,
        });
      });
  };
export const fetchBranchListings =
  (slug, commercialGetParams) => (dispatch) => {
    dispatch({
      type: SEARCH_COMMERCIAL_LISTINGS,
      payload: null,
    });
    http
      .post(
        "search-commercial-listings/" + slug,
        helpers.doObjToFormData(commercialGetParams)
      )
      .then(({ data }) => {
        dispatch({
          type: SEARCH_COMMERCIAL_LISTINGS_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEARCH_COMMERCIAL_LISTINGS_FAILED,
          payload: error,
        });
      });
  };
