import React from "react";
import Image from '../../common/image';

export default function Intro({ data }) {
    return (
        <>
            <section className="testimonial_sec">
                <div className="left_side_image">
                    <Image src={data.image} alt={data.heading} />
                </div>
                <div className="contain">
                    <div className="cntnt mission_cn_p text-left">
                        <div className="sec_heading text-left">
                            <h2>{data.heading}</h2>
                            <p>{data.text}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
