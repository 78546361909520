import {
    FETCH_VERIFY_SETTINGS,
    FETCH_VERIFY_SETTINGS_SUCCESS,
    FETCH_VERIFY_SETTINGS_FAILED,
} from "../actions/actionTypes";
const authToken = localStorage.getItem("authToken");
const initialState = {
    isLoading: true,
    verify: null,
    error: false,
    isFormProcessing: false,
    isFormImageProcessing: false,
    image: "",
    questionAdded: false
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_VERIFY_SETTINGS:
            return {
                ...state,
                isLoading: true,
                verify: null,
            };
        case FETCH_VERIFY_SETTINGS_SUCCESS:
            if (authToken === null || authToken === undefined || authToken === '') {
                window.location.href("/login");
            }
            if (payload != null && payload.member.mem_status === 0) {
                window.location.replace("/login");
            }
            if (payload != null && payload.member.mem_verified === 0) {
                window.location.replace("/member-verification");
            }
            return {
                ...state,
                isLoading: false,
                verify: payload,
            };

        case FETCH_VERIFY_SETTINGS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };

        default:
            return state;
    }
}
