import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { fetchBranch, postBranch } from "../../../states/actions/fetchBranch";
import { useSelector, useDispatch } from "react-redux";
// import { fetchSiteSettings } from '../../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import Sidebar from './sections/sidebar';
import Breadcrumb from '../dashboard/sections/breadcrumb';
import AddBranchBlk from './sections/AddBranchBlk';

export default function AddBranch() {
    const { branch_id } = useParams();
    const [formStep, setFormStep] = useState(0);
    const changeStep = (step) => {
        setFormStep(step);
    };
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchBranch.content);
    const isLoading = useSelector((state) => state.fetchBranch.isLoading);
    const isFormProcessing = useSelector(
        (state) => state.fetchBranch.isFormProcessing
    );
    const handleBranch = data => {
        dispatch(postBranch(data, branch_id))
    }
    useEffect(() => {
        dispatch(fetchBranch(branch_id));
    }, []);
    // useEffect(() => {
    //     if (branch_id > 0 && (data?.branch_row === null || data?.branch_row === undefined)) {
    //         window.location.href = "/branches";
    //     }
    // }, [branch_id, data?.branch_row]);
    useDocumentTitle(data != undefined && data != null && data?.branch_row != undefined && data?.branch_row !== null ? 'Edit Branch - Loftus' : 'Add Branch - Loftus');
    if (isLoading) return <LoadingScreen />;
    const amenities = data?.amenities;
    const branch_row = data?.branch_row;
    const states = data?.states;
    return (
        <>
            <div index="" className="main_dashboard commercial_dashboard_new">
                <ToastContainer />
                <section className="dashboard">
                    <div className="contain">
                        <div className="flex dash_intro_flex">
                            <div className="colL">
                                <Sidebar active="branches" />
                            </div>
                            <div className="colR">
                                <Breadcrumb page_name={branch_row != undefined && branch_row !== null ? 'Edit Branch' : 'Add Branch'} parent_name="Branches" parent_link="/branches" />
                                <AddBranchBlk changeStep={changeStep} step={formStep} amenities={amenities} isFormProcessing={isFormProcessing} handleBranch={handleBranch} branch_row={branch_row} states={states} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
