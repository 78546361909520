import React from "react";
import { Link } from "react-router-dom";
import SocialLinks from "../../includes/social-links";
import ContactForm from "./contact-form";

export default function ContactIntro({ data, handleContact, isFormProcessing, emptyFormData }) {
    return (
        <>
            <section className="contact_page">
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <div className="form_cntnt">
                                <div className="sec_heading">
                                    <div dangerouslySetInnerHTML={{ __html: data.text }} />
                                </div>
                            </div>
                            <ContactForm handleContact={handleContact} isFormProcessing={isFormProcessing} emptyFormData={emptyFormData} />

                        </div>
                        <div className="colR" style={{ backgroundImage: "url(" + data.image + ")" }}>
                            <div className="form_cntnt">
                                <h3>{data.heading}</h3>
                            </div>
                            <ul>
                                <li>
                                    <i className="fa fa-map-marker" />
                                    <span className="cntact_lnks">{data.address}</span>
                                </li>
                                <li>
                                    <i className="fa fa-envelope" />
                                    <Link to="/?" className="cntact_lnks">{data.email}</ Link>
                                </li>
                                <li>
                                    <i className="fa fa-phone" />
                                    <Link to="/?" className="cntact_lnks">{data.phone}</ Link>
                                </li>
                            </ul>
                            <div className="bTn">
                                <Link to={data.right_btn_lbl_link} className="webBtn colorBtn">{data.right_btn_lbl}</ Link>
                            </div>
                            <SocialLinks data={data.social_links} />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
