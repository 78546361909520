import {
    FETCH_USER_SINGLE_LISTINGS_CONTENT,
    FETCH_USER_SINGLE_LISTINGS_CONTENT_SUCCESS,
    FETCH_USER_SINGLE_LISTINGS_CONTENT_FAILED,
    USER_SINGLE_LISTINGS_DELETE__MESSAGE,
    USER_SINGLE_LISTINGS_DELETE_MESSAGE_SUCCESS,
    USER_SINGLE_LISTINGS_DELETE__MESSAGE_FAILED,
    SAVE_LISTING_CREDIT_CARD_SUCCESS,
    CARD_STATUS_CHANGE_LISTING
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
    payment_methods: [],
    isAddNewCardProcessing: false,
    isCardSaved: false

};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case CARD_STATUS_CHANGE_LISTING:
            return {
                ...state,
                isCardSaved: false
            }
        case SAVE_LISTING_CREDIT_CARD_SUCCESS:
            if (payload?.status) {

                return {
                    ...state,
                    isAddNewCardProcessing: false,
                    payment_methods: payload?.payment_methods,
                    isCardSaved: true
                };

            }
            else {
                return {
                    ...state,
                    isAddNewCardProcessing: false,
                };
            }
        case FETCH_USER_SINGLE_LISTINGS_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_USER_SINGLE_LISTINGS_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
                payment_methods: payload?.payment_methods
            };
        case FETCH_USER_SINGLE_LISTINGS_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case USER_SINGLE_LISTINGS_DELETE__MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case USER_SINGLE_LISTINGS_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case USER_SINGLE_LISTINGS_DELETE__MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
