import React from 'react'

export default function Features({ data }) {
    return (
        <>
            <section className="feature_icon">
                <div className="contain">
                    <div className="flex">
                        {data?.map((val, index) => {
                            return (
                                <div className="col" key={index}>
                                    <div className="inner">
                                        <div className="image">
                                            <img src={val.image} alt={val.title} />
                                        </div>
                                        <h4>{val.title}</h4>
                                        <p>{val.text}</p>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </section>
        </>
    )
}
