import React, { useState } from "react";
import PropertyBlk from "../../common/propertyBlk";
import PropertyChat from "../single-property/section/chat";

export default function PropertyInfo({
  property_row,
  user,
  branch_id = false,
}) {
  const [viewMsg, setViewMsg] = useState(false);
  const ToggleViewMsg = () => {
    setViewMsg(!viewMsg);
  };
  return (
    <>
      <div className="inner_colL">
        <div className="landlord_card cmn_bt_mrgn">
          <div className="main_heading">
            <h3>Landlord's Information</h3>
          </div>
          <div className="flex">
            <div className="image_icon">
              <img src={user?.image} alt={user?.name} />
            </div>
            <div className="txt">
              <h5>{user?.name}</h5>
              {/* <p>{user?.address}</p> */}
              <a
                href="javascript:void(0)"
                onClick={ToggleViewMsg}
                className="mini_btn cstm_mini_search popBtn"
                data-popup="send-message"
              >
                <i className="fa fa-comments"></i> <span>Start Chat</span>
              </a>
            </div>
          </div>
        </div>
        <div className="property_card propert_list cmn_bt_mrgn">
          <PropertyBlk data={property_row} branch_id={branch_id} />
        </div>
      </div>
      {/* <section className={viewMsg ? "popup small_popup send_msg active" : "popup small_popup send_msg"} data-popup="send-message">
                <div className="tableDv">
                    <div className="tableCell">
                        <div className="_inner">
                            <div className="crosBtn" onClick={ToggleViewMsg} />
                            <h4>Send Message</h4>
                            <form action>
                                <div className="txtGrp">
                                    <textarea name id className="txtBox" placeholder="write your message here" defaultValue={""} />
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="webBtn colorBtn">Send</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}
      {viewMsg ? (
        <PropertyChat
          ToggleViewMsg={ToggleViewMsg}
          property_mem_id={user?.mem_id}
        />
      ) : (
        ""
      )}
    </>
  );
}
