import React from "react";

export default function ChatHeader({ user_info }) {
    return <>
        <div className="chatPerson">
            <div className="backBtn"><a href="javascript:void(0)" className="fi-arrow-left"></a></div>
            <div className="ico"><img src={user_info?.user_dp} alt="" /></div>
            <h6>{user_info?.mem_type === 'company' ? user_info?.mem_fullname : user_info?.mem_fname + " " + user_info?.mem_lname}</h6>

        </div>
    </>;
}
