import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import GoogleMapReact from "google-map-react";
import GoogleMaps from "../../../../common/GoogleMaps";
import { replaceSpaceWith20 } from "../../../../../helpers/api";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function PropertyStep1({
  step,
  changeStep,
  states,
  register,
  errors,
  control,
  watchAllFields,
  property_row,
  checkAddressAvailability,
  addressAvailable,
  isValid,
  trigger,
  showPopup,
  addressLoading,
  checkTitleAvailability,
  titleAvailable,
  titleLoading,
}) {
  const defaultProps = {
    center: {
      lat: 40.71692902989122,
      lng: -74.02915385324695,
    },
    zoom: 11,
  };
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Property Title</h6>
            <div className="form_blk">
              <input
                type="text"
                name="title"
                id="title"
                className="txtBox"
                {...register("title", { required: "Required" })}
                defaultValue={property_row !== null ? property_row?.title : ""}
              />
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
              {watchAllFields?.title !== "" &&
              titleAvailable?.title !== true &&
              titleAvailable?.msg !== "" ? (
                <p className="error">{titleAvailable?.msg}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-xs-6"></div>
          <div className="col-xs-6">
            <h6>Address Line 1</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line1"
                id="address_line1"
                className="txtBox"
                {...register("address_line1", {
                  required: "Required",
                  minLength: {
                    value: 1,
                    message:
                      "The AddressLine1 field must be between 1 and 50 characters long",
                  },
                  maxLength: {
                    value: 50,
                    message:
                      "The AddressLine1 field must be between 1 and 50 characters long",
                  },
                })}
                defaultValue={property_row?.address_line1}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="address_line1"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Address Line 2</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line2"
                id="address_line2"
                className="txtBox"
                {...register("address_line2")}
                defaultValue={
                  watchAllFields?.address_line2 !== null &&
                  watchAllFields?.address_line2 !== undefined &&
                  watchAllFields?.address_line2 !== "undefined" &&
                  watchAllFields?.address_line2 !== ""
                    ? watchAllFields?.address_line2
                    : ""
                }
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>City</h6>
            <div className="form_blk">
              <input
                type="text"
                name="city"
                id="city"
                className="txtBox"
                {...register("city", { required: "Required" })}
                defaultValue={property_row?.city}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="city"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>State</h6>
            <div className="form_blk">
              <Controller
                {...register("state", { required: "Required" })}
                name="state"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.state
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={states}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select State"
                      isDisabled={
                        property_row !== undefined &&
                        property_row !== "" &&
                        property_row !== null
                          ? true
                          : false
                      }
                      defaultValue={states.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="state"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>ZIP Code</h6>
            <div className="form_blk">
              <input
                type="text"
                name="zip_code"
                id="zip_code"
                className="txtBox"
                {...register("zip_code", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  minLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                  maxLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                })}
                defaultValue={property_row?.zip_code}
                disabled={
                  property_row !== undefined &&
                  property_row !== "" &&
                  property_row !== null
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="zip_code"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="small_flex_form">
          <div className="address_show flex">
            {addressAvailable?.address !== null &&
            addressAvailable?.status === 1 ? (
              <p>{addressAvailable?.address}</p>
            ) : addressAvailable?.status === 0 ? (
              <p className="error">
                <i className="fi-warning"></i> {addressAvailable?.msg}
              </p>
            ) : (
              <p></p>
            )}
            <div className="bTn">
              {addressAvailable?.address !== null &&
              addressAvailable?.status === 1 ? (
                <div className="text-success addressMsg">
                  <i className="fi-check-alt"></i> {addressAvailable?.msg}
                </div>
              ) : addressAvailable?.address != null &&
                addressAvailable?.status === 0 ? (
                <div
                  className="text-danger addressMsg"
                  onClick={() => showPopup()}
                >
                  <i className="fa-exclamation-circle fa"></i> Address is
                  occupied. Use different address
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="bTn">
              {addressAvailable?.address !== null &&
              addressAvailable?.status === 1 &&
              addressAvailable?.latitude !== "" &&
              addressAvailable?.longitude !== "" ? (
                addressAvailable?.address === null &&
                watchAllFields?.address_line1 === "" &&
                watchAllFields?.city === "" &&
                watchAllFields?.state === "" &&
                watchAllFields?.zip_code === "" ? (
                  <div className="webBtn popBtn emptyBtn" disabled="true">
                    Fill the address first
                  </div>
                ) : (
                  <a
                    href="#!"
                    className="webBtn popBtn"
                    data-popup="address-not-match"
                    onClick={() => checkAddressAvailability(watchAllFields)}
                    disabled={addressLoading === true ? true : false}
                  >
                    CHECK ADDRESS AVAILABILITY{" "}
                    {addressLoading === true ? <i className="spinner"></i> : ""}
                  </a>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {addressAvailable?.address !== null &&
        addressAvailable?.status === 1 &&
        addressAvailable?.latitude !== "" &&
        addressAvailable?.longitude !== "" ? (
          <>
            {/* <GoogleMaps latitude={addressAvailable?.latitude} longitude={addressAvailable?.longitude} address={addressAvailable?.address} /> */}
            <div className="gMap">
              <iframe
                src={
                  "https://www.google.com/maps?q=" +
                  replaceSpaceWith20(addressAvailable?.address) +
                  "&output=embed"
                }
                width="100%"
                height="400"
                style={{ border: "0" }}
                loading="lazy"
              ></iframe>
            </div>
          </>
        ) : property_row?.formatted_address !== "" &&
          property_row?.formatted_address !== null &&
          property_row?.formatted_address !== undefined ? (
          <div className="gMap">
            <iframe
              src={
                "https://www.google.com/maps?q=" +
                replaceSpaceWith20(property_row?.formatted_address) +
                "&output=embed"
              }
              width="100%"
              height="400"
              style={{ border: "0" }}
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          ""
        )}
        <div className="cmnBtn text-center">
          <NavLink to="/properties" className="webBtn cancleBtn">
            Cancel
          </NavLink>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              {property_row !== "" &&
              property_row !== undefined &&
              property_row !== null &&
              property_row?.id !== ""
                ? "Next"
                : "Check Address Availability"}
            </button>
          ) : titleAvailable?.title !== true &&
            property_row?.title !== watchAllFields?.title ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() =>
                checkTitleAvailability(watchAllFields, property_row?.id)
              }
              disabled={titleLoading === true ? true : false}
            >
              Next
              {titleLoading === true ? <i className="spinner"></i> : ""}
            </button>
          ) : property_row !== "" &&
            property_row !== undefined &&
            property_row !== null &&
            property_row?.id !== "" &&
            property_row?.title === watchAllFields?.title ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          ) : addressAvailable?.address !== null &&
            addressAvailable?.status === 1 &&
            addressAvailable?.address !== "" ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid || addressAvailable?.status === 0}
            >
              Next
            </button>
          ) : addressAvailable?.address != null &&
            addressAvailable?.status === 0 ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                showPopup();
              }}
            >
              Next
            </button>
          ) : property_row !== "" &&
            property_row !== undefined &&
            property_row !== null &&
            property_row?.id !== "" ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => checkAddressAvailability(watchAllFields)}
              disabled={addressLoading === true ? true : false}
            >
              Check Address Availability{" "}
              {addressLoading === true ? <i className="spinner"></i> : ""}
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
