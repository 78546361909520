import React from "react";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");
export default function Sidebar({ active }) {
  return (
    <>
      <div className="side_bar_dash_links">
        {mem_permissions === "admin" || mem_permissions === "power" ? (
          <ul>
            <li className={active === "dashboard" ? "active" : ""}>
              <NavLink to="/dashboard">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/1.svg" alt="" />
                </div>
                <span>Dashboard</span>
              </NavLink>
            </li>

            <li
              className={
                active === "properties" ||
                active === "add-property" ||
                active === "edit-property"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/properties">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/2.svg" alt="" />
                </div>
                <span>Properties</span>
              </NavLink>
            </li>

            <li
              className={
                active === "listing" ||
                active === "add-listing" ||
                active === "edit-listing"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/listing">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/3.svg" alt="" />
                </div>
                <span>Listings</span>
              </NavLink>
            </li>
            <li className={active === "offers" ? "active" : ""}>
              <NavLink to="/offers">
                <div className="small_icon">
                  <img src="/images/badge-check.svg" alt="" />
                </div>
                <span>Offers</span>
              </NavLink>
            </li>
            <li className={active === "leases" ? "active" : ""}>
              <NavLink to="/leases">
                <div className="small_icon">
                  <img src="/images/document.svg" alt="" />
                </div>
                <span>Leases</span>
              </NavLink>
            </li>
            <li className={active === "payments" ? "active" : ""}>
              <NavLink to="/payments">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/14.svg" alt="" />
                </div>
                <span>Payments</span>
              </NavLink>
            </li>

            <li className={active === "billing" ? "active" : ""}>
              <NavLink to="/billing">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/5.svg" alt="" />
                </div>
                <span>Billing</span>
              </NavLink>
            </li>

            <li className={active === "expenses" ? "active" : ""}>
              <NavLink to="/expenses">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/6.svg" alt="" />
                </div>
                <span>Expenses</span>
              </NavLink>
            </li>

            <li className={active === "promotions" ? "active" : ""}>
              <NavLink to="/promotions">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/7.svg" alt="" />
                </div>
                <span>Promotions</span>
              </NavLink>
            </li>

            <li className={active === "credits" ? "active" : ""}>
              <NavLink to="/credits">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/8.svg" alt="" />
                </div>
                <span>Credits</span>
              </NavLink>
            </li>

            <li
              className={
                active === "floor-plans" ||
                active === "add-floor-plan" ||
                active === "edit-floor-plan"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/floor-plans">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/9.svg" alt="" />
                </div>
                <span>Floor Plan</span>
              </NavLink>
            </li>

            <li className={active === "media" ? "active" : ""}>
              <NavLink to="/media">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/10.svg" alt="" />
                </div>
                <span>Media</span>
              </NavLink>
            </li>

            <li className={active === "documents" ? "active" : ""}>
              <NavLink to="/documents">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/11.svg" alt="" />
                </div>
                <span>Documents</span>
              </NavLink>
            </li>
            <li className={active === "employee" ? "active" : ""}>
              <NavLink to="/employee">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/12.svg" alt="" />
                </div>
                <span>Employees</span>
              </NavLink>
            </li>

            <li
              className={
                active === "branches" ||
                active === "add-branch" ||
                active === "edit-branch"
                  ? "active"
                  : ""
              }
            >
              <NavLink to="/branches">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/13.svg" alt="" />
                </div>
                <span>Branches</span>
              </NavLink>
            </li>

            <li className={active === "contacts" ? "active" : ""}>
              <NavLink to="/contacts">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/15.svg" alt="" />
                </div>
                <span>Contacts</span>
              </NavLink>
            </li>
            <li className={active === "service-requests" ? "active" : ""}>
              <NavLink to="/service-requests">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/1.svg" alt="" />
                </div>
                <span>Service Requests</span>
              </NavLink>
            </li>
          </ul>
        ) : mem_permissions === "maintenance" ? (
          <ul>
            <li className={active === "service-requests" ? "active" : ""}>
              <NavLink to="/service-requests">
                <div className="small_icon">
                  <img src="/images/dashboard/icons/1.svg" alt="" />
                </div>
                <span>Service Requests</span>
              </NavLink>
            </li>
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
