import React, { useState, useRef, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Link } from "react-router-dom";
import Image from "../../common/image";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import InputMask from "react-input-mask";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
export default function SignupFormGoogle({
  data,
  handleSignup,
  isFormProcessing,
  onSuccess,
  onFailure,
  googleValues,
}) {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId:
          "424406682782-ermmvl6s2uc4fnuif45gf3commgkbnuo.apps.googleusercontent.com",
        scope: "email",
      });
    }

    gapi.load("client:auth2", start);
  }, []);
  useEffect(() => {
    if (googleValues !== null) {
      setValue(
        "fname",
        googleValues !== null ? googleValues?.profileObj?.givenName : ""
      );
      setValue(
        "lname",
        googleValues !== null ? googleValues?.profileObj?.familyName : ""
      );
      setValue(
        "email",
        googleValues !== null ? googleValues?.profileObj?.email : ""
      );
    }
  }, [googleValues]);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const password = useRef({});
  const watchAllFields = watch();
  password.current = watch("password", "");

  const onSubmit = (data) => {
    // ; return;
    handleSignup(data);
  };

  return (
    <>
      <form
        action=""
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="none"
      >
        <h3>Complete sign up</h3>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            First Name
          </label>
          <input
            type="text"
            name="fname"
            id="fname"
            className="txtBox"
            {...register("fname", { required: "Required" })}
            autoComplete="none"
            defaultValue={watchAllFields?.fname}
            disabled
          />
          <ErrorMessage
            errors={errors}
            name="fname"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Last Name
          </label>
          <input
            type="text"
            name="lname"
            id="lname"
            className="txtBox"
            {...register("lname", { required: "Required" })}
            autoComplete="none"
            defaultValue={watchAllFields?.lname}
            disabled
          />
          <ErrorMessage
            errors={errors}
            name="lname"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Email Address
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className="txtBox"
            {...register("email", {
              required: "Required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                message: "Email format is not valid!",
              },
            })}
            autoComplete="off"
            autoSave="none"
            defaultValue={watchAllFields?.email}
            disabled
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Phone Number
          </label>
          <InputMask
            mask="+1 (999) 999-9999"
            name="phone"
            id="phone"
            className="txtBox"
            autoComplete="none"
            {...register("phone", {
              required: "Required",
            })}
          />
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp pasDv">
          <label htmlFor="" className="move">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="txtBox"
            {...register("password", {
              required: "Required",
              pattern: {
                value:
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                message:
                  "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp pasDv">
          <label htmlFor="" className="move">
            Confirm Password
          </label>
          <input
            type="password"
            name="confirm_password"
            id="confirm_password"
            className="txtBox"
            {...register("confirm_password", {
              required: "Required",
              validate: (val) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="confirm_password"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp flex">
          <div className="lblBtn">
            <input
              type="checkbox"
              name="confirm"
              id="confirm"
              {...register("confirm", { required: "Required" })}
            />
            <label htmlFor="confirm">
              By signing up, I agree to Loftus'
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy.
              </Link>
            </label>
          </div>
          <ErrorMessage
            errors={errors}
            name="confirm"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="bTn text-center">
          <button
            type="submit"
            className="webBtn blockBtn icoBtn popBtn"
            data-popup="show-phone"
            disabled={isFormProcessing === true ? "disabled" : ""}
          >
            {isFormProcessing === true ? <i className="spinner"></i> : "Submit"}
          </button>
        </div>
      </form>
    </>
  );
}
