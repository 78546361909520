import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { useForm } from "react-hook-form";
export default function MaintenanceRequestForm({
  properties,
  handleSaveRequest,
  isFormProcessing,
  maintenance_row,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const onSubmit = (frmData) => {
    handleSaveRequest(frmData);
  };
  useEffect(() => {
    if (
      maintenance_row?.id > 0 &&
      maintenance_row !== undefined &&
      maintenance_row !== null
    ) {
      setValue(
        "property_id",
        maintenance_row?.property_encoded_id
          ? maintenance_row?.property_encoded_id
          : ""
      );
    }
  }, [maintenance_row]);

  console.log(watchAllFields);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="row form_row">
          {/* {maintenance_row?.id <= 0 ||
          maintenance_row === undefined ||
          maintenance_row === null ? ( */}
          <div class="col-xs-12">
            <h6>Choose Property</h6>
            <div class="form_blk">
              <Controller
                {...register("property_id", { required: "Required" })}
                name="property_id"
                id="property_id"
                className=""
                control={control}
                defaultValue={watchAllFields?.property_id}
                disabled={
                  maintenance_row?.id > 0 &&
                  maintenance_row !== undefined &&
                  maintenance_row !== null
                    ? true
                    : false
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={properties}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      // isMulti
                      label="Select Property"
                      value={
                        value
                          ? properties?.filter(function (option) {
                              if (
                                maintenance_row?.id > 0 &&
                                maintenance_row !== undefined &&
                                maintenance_row !== null
                              ) {
                                return option.value === value;
                              } else {
                                return option.value === value.value;
                              }
                            })
                          : ""
                      }
                      isDisabled={
                        maintenance_row?.id > 0 &&
                        maintenance_row !== undefined &&
                        maintenance_row !== null
                          ? true
                          : false
                      }
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="property_id"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          {/* ) : (
            ""
          )} */}
          <div class="col-xs-12">
            <h6>Purpose</h6>
            <div class="form_blk">
              <input
                type="text"
                name=""
                id=""
                class="txtBox"
                {...register("purpose", {
                  required: "Required",
                })}
                defaultValue={
                  maintenance_row?.id > 0 ? maintenance_row?.purpose : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="purpose"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          <div class="col-xs-12">
            <h6>Description</h6>
            <div class="form_blk">
              <textarea
                name=""
                id=""
                class="txtBox"
                {...register("description", {
                  required: "Required",
                })}
              >
                {maintenance_row?.id > 0 ? maintenance_row?.description : ""}
              </textarea>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          {maintenance_row?.id <= 0 ||
          maintenance_row === undefined ||
          maintenance_row === null ? (
            <>
              <div class="col-xs-12 permission_blk">
                <h5>
                  Do you grant permission to the service staff to enter the
                  premises and work on the submitted service request if you are
                  not home?
                </h5>
                <div class="flex permission_flex">
                  <div class="lblBtn">
                    <input
                      type="radio"
                      name="permission"
                      value="yes"
                      {...register("grant_permissions", {
                        required: "Required",
                      })}
                      defaultChecked={true}
                    />
                    <label for="">Yes</label>
                  </div>
                  <div class="lblBtn">
                    <input
                      type="radio"
                      name="permission"
                      value="no"
                      {...register("grant_permissions", {
                        required: "Required",
                      })}
                    />
                    <label for="">No</label>
                  </div>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="grant_permissions"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <br />
        <div class="bTn justify_btn_center">
          <button
            class="button webBtn colorBtn"
            type="submit"
            disabled={isFormProcessing}
          >
            {maintenance_row?.id > 0 ? "Update" : "Submit"}
            {isFormProcessing ? <i className="spinner"></i> : ""}
          </button>
        </div>
      </form>
    </>
  );
}
