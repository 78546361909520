import React, { useEffect } from "react";
import MediaImages from "./media/images";
import MediaThumbnail from "./media/thumbnail";
import Thumbnail from "../../../common/thumbnail";
import { imageValidation, projectImageUpload } from "../../../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";

export default function AddMediaForm({
  setThumbnail,
  path,
  imageThumbnail,
  uploadMultipleImages,
  images,
  handleRemoveImage,
  branches,
  handleMedia,
  isFormProcessing,
  media_row,
  setImages,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  useEffect(() => {
    if (
      media_row !== undefined &&
      media_row !== null &&
      media_row !== "" &&
      parseInt(media_row?.id) > 0
    ) {
      setValue("branch", media_row?.branch_id);
      setThumbnail(media_row?.imageThumbnail);
      setImages({ ...images, images: media_row?.images_arr, loading: false });
    }
  }, [media_row]);
  async function handleUploadThumbnail(e) {
    setThumbnail("loading.gif");
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
      setThumbnail(null);
      toast.error(valid.error, TOAST_SETTINGS);
    } else {
      let image = await projectImageUpload(files, "media");
      if (image.status === 1) {
        setThumbnail(image.image_name);
      } else {
        setThumbnail(null);
        toast.error(image.msg, TOAST_SETTINGS);
      }
    }
  }
  function deleteThumbnail() {
    setThumbnail(null);
  }
  const onSubmit = (data) => {
    let newData = "";
    if (
      imageThumbnail !== undefined &&
      imageThumbnail !== null &&
      imageThumbnail !== "" &&
      images?.images !== null &&
      images?.images !== undefined &&
      images?.images !== "" &&
      images?.images?.length > 0
    ) {
      newData = {
        ...data,
        images: images?.images,
        imageThumbnail: imageThumbnail,
      };
      //
      handleMedia(newData);
    } else if (
      imageThumbnail === undefined ||
      imageThumbnail === null ||
      imageThumbnail === ""
    ) {
      toast.error("Please upload a thumbnail ", TOAST_SETTINGS);
    } else {
      toast.error(
        "Please upload at least one image for the images module!",
        TOAST_SETTINGS
      );
    }

    // // ;
  };
  return (
    <>
      <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer />
        <div className="heighlight_fieldset">
          <div className="form_row row flex justify_center_flex_crud">
            <div className="col-xs-6">
              <div className="crud_image">
                <div className="inner_curd">
                  <h6>Branch</h6>

                  <div className="form_blk">
                    <Controller
                      {...register("branch", { required: "Required" })}
                      name="branch"
                      control={control}
                      defaultValue={media_row?.branch_id}
                      render={({ field: { onChange, value, ref, name } }) => (
                        <>
                          <Select
                            options={branches}
                            onChange={(val) => {
                              onChange(val.value);
                            }}
                            label="Select Branch"
                            value={branches.find((c) => c.value === value)}
                          />
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="branch"
                      render={({ message }) => (
                        <p className="error">
                          <i className="fi-warning"></i> {message}
                        </p>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="gap_line_divide"></div>
            </div>

            <div className="col-xs-12">
              <div className="form_blk">
                <MediaThumbnail
                  imageThumbnail={imageThumbnail}
                  deleteThumbnail={deleteThumbnail}
                  handleUploadThumbnail={handleUploadThumbnail}
                  path={path}
                />
                <MediaImages
                  uploadMultipleImages={uploadMultipleImages}
                  images={images}
                  path={path}
                  handleRemoveImage={handleRemoveImage}
                />
              </div>
            </div>
          </div>
          <div className="cmnBtn text-center">
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing === true ? "disabled" : ""}
            >
              {isFormProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Finish"
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
