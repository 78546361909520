import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExtendListingForm from "./extendListingForm";
import { consoleLog } from "../../../../helpers/api";

export default function ExtendRentListingPopup({
  hideUpdateListingPopup,
  listingPopup,
  siteSettings,
  extendLoading,
  handleExtendListing,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const calculateListingFee = () => {
    let listing_fee =
      parseInt(watchAllFields?.expires_in) *
      parseFloat(siteSettings?.site_listing_fee);
    return (
      parseFloat(listing_fee) + parseFloat(calculateListingProcessingFee())
    );
  };
  const calculateListingProcessingFee = () => {
    if (
      watchAllFields?.expires_in !== "" &&
      watchAllFields?.expires_in !== undefined &&
      watchAllFields?.expires_in !== null
    ) {
      let listing_fee =
        parseInt(watchAllFields?.expires_in) *
        parseFloat(siteSettings?.site_listing_fee);
      let processing_fee =
        (listing_fee * siteSettings?.site_processing_fee) / 100;
      return parseFloat(processing_fee.toFixed(2));
    } else {
      return 0;
    }
  };
  useEffect(() => {
    setValue("listing_type", listingPopup?.item?.listing_type);
  }, [listingPopup]);

  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const choosePaymentMethod = (value) => {
    setPaymentMethod(value);
  };
  const [checkoutError, setCheckoutError] = useState();
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const [formStep, setFormStep] = useState(0);
  const changeStep = (step) => {
    setFormStep(step);
  };
  const [isProcessing, setProcessingTo] = useState(false);

  let stripePromise = "";
  if (
    siteSettings.site_stripe_type === 1 ||
    siteSettings.site_stripe_type === "1"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_testing_api_key);
  } else if (
    siteSettings.site_stripe_type === 0 ||
    siteSettings.site_stripe_type === "0"
  ) {
    stripePromise = loadStripe(siteSettings?.site_stripe_live_api_key);
  }

  const getPaypalObject = (order) => {
    if (order.status === "COMPLETED") {
      let data = watchAllFields;
      let newData = {
        ...data,
        paymentMethod: paymentMethod,
        order_id: order.id,
        payer_id: order.payer.payer_id,
        amount: calculateListingFee(),
      };
      // ;
      // dispatch(postListing(newData))
      handleExtendListing(newData, listingPopup?.item?.id);
    } else {
      consoleLog(order);
    }
  };
  const cancelPaypalOrder = (data) => {
    consoleLog("cancel", data);
    // toast.error('You canceled the payment with PayPal.', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
  };
  const errorPaypalOrder = (data) => {
    consoleLog("error", data);
    toast.error("There is a technical problem. Try again later.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return (
    <>
      <section className="popup extend_popup" data-popup="create-new-listing">
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={hideUpdateListingPopup}></div>
              <h4>Extend listing duration:</h4>
              <Elements stripe={stripePromise}>
                <ExtendListingForm
                  register={register}
                  handleSubmit={handleSubmit}
                  formStep={formStep}
                  changeStep={changeStep}
                  watchAllFields={watchAllFields}
                  hideUpdateListingPopup={hideUpdateListingPopup}
                  paymentMethod={paymentMethod}
                  choosePaymentMethod={choosePaymentMethod}
                  checkoutError={checkoutError}
                  getPaypalObject={getPaypalObject}
                  cancelPaypalOrder={cancelPaypalOrder}
                  errorPaypalOrder={errorPaypalOrder}
                  handleCardDetailsChange={handleCardDetailsChange}
                  isProcessing={isProcessing}
                  errors={errors}
                  isValid={isValid}
                  trigger={trigger}
                  setProcessingTo={setProcessingTo}
                  setCheckoutError={setCheckoutError}
                  extendLoading={extendLoading}
                  handleExtendListing={handleExtendListing}
                  listingPopup={listingPopup}
                  calculateListingFee={calculateListingFee}
                  siteSettings={siteSettings}
                  calculateListingProcessingFee={calculateListingProcessingFee}
                />
              </Elements>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
