import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import {
  getPackageName,
  isPackageUpgraded,
  postData,
  doObjToFormData,
  format_amount,
} from "../../../helpers/api";

export default function SendOfferStep3({
  step,
  changeStep,
  register,
  errors,
  isValid,
  property_row,
  offer_row,
  trigger,
  package_verified,
  package_upgrade,
  package_names,
  watchAllFields,
  isProcessing,
  isFormProcessing,
  check_renter_verification_status,
  packageVerificationLoading,
  member,
  is_user_verified = false,
  isPackageUpgradedFlag,
  site_package_cost,
  member_tu_verification_status,
  manualOfferTenant,
}) {
  return (
    <>
      <fieldset>
        <div className="medium_blk">
          <div className="cmn_bt_mrgn">
            {property_row?.screening === "N" ? (
              member_tu_verification_status === "manual" ? (
                <>
                  <p>
                    {" "}
                    At this time, you cannot send an offer on this property
                    because your identity has not been verified from the offer
                    you placed for{" "}
                    {manualOfferTenant?.property_row?.formatted_address}. Please
                    call (833) 458-6338 to complete your verification and
                    reference screening request ID{" "}
                    {manualOfferTenant?.screeningRequestId}. Once your identity
                    has been verified, you can return to place this offer.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    The landlord for this property does not require screenings
                    for their applicant(s). However, you may still opt to
                    purchase them. Once your identity is verified and the offer
                    is sent, you can view your screening results in your
                    offer(s). These results will not be shared with this
                    landlord or any other landlord who does not require
                    screenings.
                  </p>
                  <p>
                    Loftus waives any subsequent screening charges for any other
                    offers sent within thirty (30) days from the verification of
                    your identity, limited to five (5) offers per day.
                  </p>
                </>
              )
            ) : (
              <>
                {member_tu_verification_status === "manual" ? (
                  <>
                    <p>
                      At this time, you cannot send an offer on this property
                      because your identity has not been verified.Please call
                      (833) 458-6338 to complete your verification. Once your
                      identity has been verified, you can return to place this
                      offer.
                    </p>
                    <p>
                      Alternatively, you may proceed by completing a new
                      application. This will require payment for a new screening
                      request and any previous offers will be canceled. No
                      refunds for any prior screening requests will be
                      permitted.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      The landlord for this property requires a{" "}
                      {getPackageName(property_row?.screening)} screening for
                      their applicant(s). Once your identity is verified and the
                      offer is sent, you can view your screening results in your
                      offer(s). These results will not be shared with any
                      landlords who do not require screenings.
                    </p>

                    <p>
                      Loftus waives any subsequent screening charges for any
                      other offers sent within thirty (30) days from the
                      verification of your identity, limited to five (5) offers
                      per day.
                    </p>
                  </>
                )}
              </>
            )}
          </div>

          <div className="">
            <ul className="ofer_pkg_tbl">
              <li>
                <h5>Package</h5>
              </li>
              <li>
                <h5>Price</h5>
              </li>
              {property_row?.screening === "N" ? (
                <>
                  <li>
                    <div className="lblBtn">
                      <input
                        type="radio"
                        name="package"
                        value="N"
                        id="none"
                        {...register("package", { required: "Required" })}
                      />
                      <label htmlFor="none">None</label>
                    </div>
                  </li>

                  <li>
                    <label>
                      {isPackageUpgradedFlag !== undefined &&
                      isPackageUpgradedFlag !== true
                        ? "Waived"
                        : "$0"}
                    </label>
                  </li>
                </>
              ) : (
                ""
              )}

              {property_row?.screening === "CCEI" ||
              property_row?.screening === "N" ? (
                <>
                  <li>
                    <div className="lblBtn">
                      <input
                        type="radio"
                        name="package"
                        value="CCEI"
                        id="credit-criminal-evictions-id"
                        {...register("package", { required: "Required" })}
                      />
                      <label htmlFor="credit-criminal-evictions-id">
                        Credit & Criminal, Evictions, and ID
                      </label>
                    </div>
                  </li>
                  <li>
                    {/* <label>{isPackageUpgradedFlag !== undefined && isPackageUpgradedFlag !== true ? 'Waived' : <>{format_amount(site_package_cost)} <span>(Recommended)</span></>}</label> */}
                    <label>{format_amount(site_package_cost)}</label>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
            <ErrorMessage
              errors={errors}
              name="package"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
          <div className="cmnBtn text-center offering_send_btns">
            {offer_row === undefined ||
            offer_row === null ||
            offer_row === "" ? (
              <button
                type="button"
                className="webBtn backBtn"
                onClick={() => changeStep(step - 1)}
              >
                Previous
              </button>
            ) : (
              ""
            )}
            {member_tu_verification_status === "manual" &&
            watchAllFields?.package !== "N" ? (
              ""
            ) : !isValid ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  trigger();
                }}
              >
                Next
              </button>
            ) : watchAllFields?.package === "N" ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(6)}
                disabled={!isValid}
              >
                Next
              </button>
            ) : member?.user_verified === true ? (
              (package_upgrade !== null &&
                package_upgrade !== undefined &&
                package_upgrade !== true) ||
              (isPackageUpgraded(
                package_names,
                property_row,
                watchAllFields
              ) !== undefined &&
                isPackageUpgraded(
                  package_names,
                  property_row,
                  watchAllFields
                ) !== true) ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  disabled={
                    packageVerificationLoading ||
                    !isValid ||
                    isProcessing ||
                    isFormProcessing
                      ? "disabled"
                      : ""
                  }
                  onClick={() => check_renter_verification_status(true)}
                >
                  Next{" "}
                  {packageVerificationLoading ||
                  isProcessing ||
                  isFormProcessing ? (
                    <i className="spinner"></i>
                  ) : (
                    ""
                  )}
                </button>
              ) : isPackageUpgraded(
                  package_names,
                  property_row,
                  watchAllFields
                ) !== undefined &&
                isPackageUpgraded(
                  package_names,
                  property_row,
                  watchAllFields
                ) === true ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => check_renter_verification_status()}
                  disabled={
                    !isValid ||
                    packageVerificationLoading ||
                    isProcessing ||
                    isFormProcessing
                  }
                >
                  Next{" "}
                  {packageVerificationLoading ||
                  isProcessing ||
                  isFormProcessing ? (
                    <i className="spinner"></i>
                  ) : (
                    ""
                  )}
                </button>
              ) : package_verified === true ||
                package_verified === 1 ||
                package_verified === "true" ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => changeStep(6)}
                  disabled={!isValid}
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => changeStep(step + 1)}
                  disabled={!isValid}
                >
                  Next
                </button>
              )
            ) : (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(step + 1)}
                disabled={!isValid}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}
