import React, { useEffect } from "react";
import { useForm } from "react-hook-form"; // Import react-hook-form
import { ErrorMessage } from "@hookform/error-message"; // Import ErrorMessage component
import Select from "react-select";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import toast from "react-hot-toast";

export default function EmployeeForm({
  branches,
  handleSubmitForm,
  isFormProcessing,
  employee_row,
}) {
  const {
    register, // Destructure register from useForm
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  useEffect(() => {
    if (employee_row?.id > 0) {
      setValue("branch_id", employee_row?.branch_id);
      setValue("name", employee_row?.mem_fullname);
      setValue("email", employee_row?.mem_email);
      setValue("phone", employee_row?.mem_phone);
      setValue("permissions", employee_row?.permissions);
      setValue("branches", employee_row?.branches_arr);
    }
  }, [employee_row]);

  const onSubmit = (data) => {
    if (
      data?.permissions === false ||
      data?.permissions === "" ||
      data?.permissions === null ||
      data?.permissions === undefined
    ) {
      toast.error("Please select permissions to continue");
      return;
    }
    data = { ...data, branches: JSON.stringify(data?.branches) };
    // ; return
    handleSubmitForm(data);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="heighlight_fieldset">
          <div className="form_row row flex">
            <div className="col-xs-6">
              <h6>Name</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="txtBox"
                  {...register("name", {
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z ]*$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Email</h6>
              <div className="form_blk">
                {employee_row?.id > 0 ? (
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="txtBox"
                    readOnly
                    disabled={true}
                    defaultValue={employee_row?.mem_email}
                  />
                ) : (
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="txtBox"
                    {...register("email", {
                      required: "Required",
                      pattern: {
                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                        message: "Email format is not valid!",
                      },
                    })}
                  />
                )}

                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Phone</h6>
              <div className="form_blk">
                {employee_row?.id > 0 ? (
                  <InputMask
                    mask="+1 (999) 999-9999"
                    name="phone"
                    id="phone"
                    className="txtBox"
                    autoComplete="none"
                    {...register("phone", {
                      required: "Required",
                    })}
                    value={employee_row?.id > 0 ? employee_row?.mem_phone : ""}
                  />
                ) : (
                  <InputMask
                    mask="+1 (999) 999-9999"
                    name="phone"
                    id="phone"
                    className="txtBox"
                    autoComplete="none"
                    {...register("phone", {
                      required: "Required",
                    })}
                  />
                )}
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Branch</h6>
              <div className="form_blk">
                <Controller
                  {...register("branches")}
                  name="branches"
                  control={control}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={branches}
                        onChange={(selectedOptions) => {
                          // If isMulti is set, `selectedOptions` is an array of selected values
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );
                          onChange(selectedValues); // Update the form state with selected values
                        }}
                        isMulti
                        label="Select Branch"
                        // Ensure that the value prop is properly set if needed
                        value={branches.filter((option) =>
                          value?.includes(option?.value)
                        )}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="branches"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-12">
              <h5 className="user_dash_form_heading">Permissions</h5>
            </div>
            <div className="col-xs-12">
              <div className="form_blk">
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="permissions"
                    value="admin"
                    id="admin"
                    {...register("permissions")}
                  />
                  <label htmlFor="admin">Admin</label>
                </div>
              </div>
              <div className="form_blk">
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="permissions"
                    value="power"
                    id="power"
                    {...register("permissions")}
                  />
                  <label htmlFor="power">Power</label>
                </div>
              </div>
              <div className="form_blk">
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="permissions"
                    value="maintenance"
                    id="maintenance"
                    {...register("permissions")}
                  />
                  <label htmlFor="maintenance">Maintenance</label>
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name="permissions"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="cmnBtn text-center">
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing}
            >
              Submit {isFormProcessing ? <i className="spinner"></i> : ""}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
