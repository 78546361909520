import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_FORGOT_CONTENT,
    FETCH_FORGOT_CONTENT_SUCCESS,
    FETCH_FORGOT_CONTENT_FAILED,
    FORGOT_MESSAGE,
    FORGOT_MESSAGE_SUCCESS,
    FORGOT_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchForgot = () => (dispatch) => {
    dispatch({
        type: FETCH_FORGOT_CONTENT,
        payload: null
    });
    http
        .get("forgot-page")
        .then(({ data }) => {
            dispatch({
                type: FETCH_FORGOT_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_FORGOT_CONTENT_FAILED,
                payload: error
            });
        });
};
export const postForgot = (formData) => (dispatch) => {
    dispatch({
        type: FORGOT_MESSAGE,
        payload: null
    });
    http
        .post("forgot-password", helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: FORGOT_MESSAGE_SUCCESS,
                    payload: data
                });

            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: FORGOT_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: FORGOT_MESSAGE_FAILED,
                payload: error
            });
        });
};

