import React, { useState, useEffect, useRef } from "react";
import {
  format_date_time,
  getServerImage,
  moment_new_date,
} from "../../helpers/api";
import Logo from "./logo";
import Navigation from "./navigation";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NavigationSearch from "./navigation-search";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../common/LoadingScreen";
import LoggedNavigation from "./logged-navigation";
import { profileSettings } from "../../states/actions/profileSettings";
import {
  updateMessagesFromReceivedData,
  getUserMessages,
  updateUserArr,
  updateMessageSidebarStatus,
} from "../../states/actions/sendChat";

import io from "socket.io-client";
import { Toaster, toast } from "react-hot-toast";
// const socket = io(process.env.REACT_APP_SOCKET_URL);
const authToken = localStorage.getItem("authToken");
function Header({
  logged,
  setValue,
  register,
  searchProperties,
  handleSubmit,
}) {
  const audioRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const member = siteSettings?.member;
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const profileImage = useSelector(
    (state) => state.profileSettings.image.image_name
  );
  const unreadNotify = useSelector((state) => state.profileSettings.unread);
  const unseen_msgs_from_profile_api = useSelector(
    (state) => state.profileSettings.unseen_msgs
  );
  useEffect(() => {
    dispatch(profileSettings());
  }, []);
  let { chat_id } = useParams();
  useEffect(() => {
    // dispatch(getUserMessages({ chat_id: chat_id }))
  }, []);
  const location_path = location.pathname;
  const unread_msgs = useSelector((state) => state.sendChat.unread_msgs);
  const users = useSelector((state) => state.sendChat.users);
  const [topMessagesCount, setTopMessagesCount] = useState(0);
  useEffect(() => {
    if (unread_msgs !== null && location_path.split("/")[1] === "messages") {
      setTopMessagesCount(unread_msgs);
    } else {
      setTopMessagesCount(unseen_msgs_from_profile_api);
    }
  }, [unread_msgs, unseen_msgs_from_profile_api]);

  const navigate = useNavigate();
  const handleGetUserMessages = (chat_id) => {
    // searchParams.set("chat_id", chat_id)
    //navigate(`${searchParams.toString()}`);
    navigate(`/messages/${chat_id}`);
    dispatch(getUserMessages({ chat_id: chat_id }));
  };
  // useEffect(() => {
  //   socket.removeAllListeners("receive-message");
  //   socket.on("receive-message", (data) => {
  //     if (location_path.split("/")[1] === "messages") {
  //       if (users.find((item) => item?.chat_id === data?.convoId)) {
  //         dispatch(
  //           updateMessageSidebarStatus({
  //             chat_id: data?.convoId,
  //             unread: true,
  //             created_time: moment_new_date(data?.messageDate),
  //             msg: data?.message,
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           updateUserArr({
  //             chat_id: data?.convoId,
  //             user_dp: getServerImage("/storage/members/", data?.senderDp),
  //             user_name: data?.senderName,
  //             unread: true,
  //             msg: data?.message,
  //             created_time: moment_new_date(data?.messageDate),
  //           })
  //         );
  //       }
  //     }
  //     if (chat_id === data?.convoId) {
  //       socket.emit("updateMessageStatus", { messageId: data?.messageId });
  //       const received_data = {
  //         msg: data?.message,
  //         msg_type: "you",
  //         attachments: data?.file,
  //         user_dp: getServerImage("/storage/members/", data?.senderDp),
  //         user_name: data?.senderName,
  //         time_ago: format_date_time(data?.messageDate),
  //         convoId: data?.convoId,
  //       };
  //       dispatch(updateMessagesFromReceivedData(received_data));
  //       dispatch(
  //         updateMessageSidebarStatus({
  //           chat_id: data?.convoId,
  //           unread: true,
  //           created_time: moment_new_date(data?.messageDate),
  //         })
  //       );
  //     } else {
  //       if (audioRef.current) {
  //         audioRef.current.play();
  //       }
  //       setTopMessagesCount(parseInt(topMessagesCount) + 1);
  //       toast(
  //         (t) => (
  //           <div className="msg_contain_toaster relative">
  //             <div
  //               className="crosBtn"
  //               onClick={() => toast.dismiss(t.id)}
  //             ></div>
  //             <a
  //               href={"/messages/" + data?.convoId}
  //               className="flex msg_toaster"
  //               style={{ alignItems: "center", padding: "2px", gap: "10px" }}
  //             >
  //               <div
  //                 style={{
  //                   width: "60px",
  //                   height: "60px",
  //                   overflow: "hidden",
  //                   borderRadius: "75%",
  //                 }}
  //               >
  //                 <img
  //                   src={getServerImage("/storage/members/", data?.senderDp)}
  //                   style={{ height: "100%", objectFit: "cover" }}
  //                 />
  //               </div>
  //               <div style={{ padding: "2px", flex: 1 }}>
  //                 <p>
  //                   <strong>{data?.senderName}</strong> sent a new message
  //                 </p>
  //               </div>
  //             </a>
  //           </div>
  //         ),
  //         {
  //           duration: 3000,
  //           style: {
  //             background: "#deedfb", // Change the background color here
  //             color: "#000", // Change the text color if needed
  //           },
  //         }
  //       );
  //       return;
  //     }
  //   });
  // }, [chat_id]);
  // useEffect(() => {
  //   if (authToken !== null && authToken !== undefined && member?.id > 0) {
  //     const regData = { userId: member?.id };
  //     socket.emit("registerUser", regData);
  //   }
  // }, [member, authToken]);

  const current_page = location_path.split("/")[1];
  const [toggle, setToggle] = useState(false);
  const ToggleActive = () => {
    setToggle(!toggle);
  };
  const HideMenu = () => {
    setToggle(false);
  };
  if (isSiteSettingsLoading) return <LoadingScreen />;
  const notifications = siteSettings?.member?.notifications;
  return (
    <>
      <Toaster position="bottom-left" />
      <audio
        ref={audioRef}
        src="/images/bell.mp3"
        style={{ opacity: 0, visibility: "hidden" }}
      />
      {current_page === "verification" ? (
        ""
      ) : logged === true ? (
        <header
          className={current_page == "explore" ? "explore_header ease" : "ease"}
        >
          <div
            className={current_page == "explore" ? "contain_search" : "contain"}
          >
            {current_page == "explore" ? (
              <>
                <Logo
                  src={getServerImage(
                    "storage/images/",
                    siteSettings.site_logo
                  )}
                  name={siteSettings.site_name}
                />
                <div
                  className={!toggle ? "toggle" : "toggle active"}
                  onClick={() => ToggleActive(!toggle)}
                >
                  <span></span>
                </div>
                <NavigationSearch
                  active={toggle}
                  HideMenu={HideMenu}
                  logged={logged}
                  member={siteSettings.member}
                  profileImage={profileImage}
                  unreadNotify={unreadNotify}
                  register={register}
                  setValue={setValue}
                  searchProperties={searchProperties}
                  handleSubmit={handleSubmit}
                  exploreClass={true}
                  topMessagesCount={topMessagesCount}
                  handleGetUserMessages={handleGetUserMessages}
                />
              </>
            ) : (
              <>
                <Logo
                  src={getServerImage(
                    "storage/images/",
                    siteSettings.site_logo
                  )}
                  name={siteSettings.site_name}
                />
                <div
                  className={!toggle ? "toggle" : "toggle active"}
                  onClick={() => ToggleActive(!toggle)}
                >
                  <span></span>
                </div>

                <LoggedNavigation
                  active={toggle}
                  member={siteSettings.member}
                  profileImage={profileImage}
                  HideMenu={HideMenu}
                  unreadNotify={unreadNotify}
                  topMessagesCount={topMessagesCount}
                  handleGetUserMessages={handleGetUserMessages}
                />
              </>
            )}
          </div>
        </header>
      ) : current_page == "explore" ? (
        <header className="ease explore_header">
          <div className="contain_search">
            <Logo
              src={getServerImage("storage/images/", siteSettings.site_logo)}
              name={siteSettings.site_name}
            />
            <div
              className={!toggle ? "toggle" : "toggle active"}
              onClick={() => ToggleActive(!toggle)}
            >
              <span></span>
            </div>
            <NavigationSearch
              active={toggle}
              HideMenu={HideMenu}
              exploreClass={true}
            />
          </div>
        </header>
      ) : (
        <header className="ease">
          <div className="contain">
            <Logo
              src={getServerImage("storage/images/", siteSettings.site_logo)}
              name={siteSettings.site_name}
            />
            <div
              className={!toggle ? "toggle" : "toggle active"}
              onClick={() => ToggleActive(!toggle)}
            >
              <span></span>
            </div>
            <Navigation active={toggle} HideMenu={HideMenu} />
          </div>
        </header>
      )}
    </>
  );
}
export default Header;
