
export default function CardBrandLogo({ card_brand }) {
    return <>
        {
            card_brand === 'visa' ?
                <img src="/images/dashboard/card1.svg" />
                :
                card_brand === 'mastercard' ?
                    <img src="/images/dashboard/card2.svg" />
                    :
                    card_brand === 'amex' ?
                        <img src="/images/dashboard/card3.svg" />
                        :
                        card_brand === 'discover' ?
                            <img src="/images/dashboard/card4.png" />
                            :
                            card_brand === 'jcb' ?
                                <img src="/images/dashboard/card5.png" />
                                :
                                <img src="/images/dashboard/card6.png" />
        }
    </>;
}