import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSingleListing,
  postListing,
} from "../../../states/actions/fetchSingleListing";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";

import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import AddListingForm from "./sections/add-listing-form";
import {
  checkPattern,
  convertSimpleDateUsingMoment,
} from "../../../helpers/api";
export default function AddListing() {
  const dispatch = useDispatch();
  const { listing_id } = useParams();
  const [formStep, setFormStep] = useState(1);
  const [prices, setPrices] = useState({
    prices: [{ price: "", term: "" }],
    errors: [{ price: "", term: "" }],
  });
  const handleAddNewPrice = () => {
    setPrices({
      ...prices,
      prices: prices.prices.concat([{ price: "", term: "" }]),
    });
  };
  function handleRemovePrice(idx) {
    setPrices({
      ...prices,
      prices: prices.prices.filter((s, sidx) => idx !== sidx),
    });
    // setPrices({
    //     ...prices, prices: prices.prices.filter((s, sidx) => idx !== sidx), errors: prices.errors.filter((s, sidx) => idx !== sidx)
    // });
  }

  function handlePriceChange(idx, evt) {
    const newPrices = prices.prices.map((prices, sidx) => {
      if (idx !== sidx) return prices;
      return { ...prices, [evt.target.name]: evt.target.value };
    });

    setPrices({ ...prices, prices: newPrices });
  }
  const data = useSelector((state) => state.fetchSingleListing.content);
  const isLoading = useSelector((state) => state.fetchSingleListing.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleListing.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleListing(listing_id));
  }, []);
  useEffect(() => {
    if (
      data?.prices !== undefined &&
      data?.prices !== null &&
      data.prices?.length > 0
    ) {
      setPrices({ prices: data.prices });
    }
  }, [data]);
  useEffect(() => {}, [prices]);
  const changeStep = (step) => {
    setFormStep(step);
  };

  const handleSubmit = (data) => {
    const available = convertSimpleDateUsingMoment(data?.available);
    data = { ...data, available: available };
    // ;
    // return;
    if (
      data?.prices !== null &&
      data?.prices !== undefined &&
      data?.prices?.length > 0
    ) {
      for (let i = 0; i < parseInt(data?.prices?.length); i++) {
        if (
          data?.prices[i]?.price === "" ||
          data?.prices[i]?.price === null ||
          data?.prices[i]?.price === undefined
        ) {
          toast.error(
            "Price " + parseInt(i + 1) + " is required",
            TOAST_SETTINGS
          );
          return;
        }
        if (
          data?.prices[i]?.term === "" ||
          data?.prices[i]?.term === null ||
          data?.prices[i]?.term === undefined
        ) {
          toast.error(
            "Term " + parseInt(i + 1) + " is required",
            TOAST_SETTINGS
          );
          return;
        }
      }

      dispatch(postListing(data, listing_id));
    } else {
      toast.error("Please add at least one price!", TOAST_SETTINGS);
      return;
    }
  };

  useDocumentTitle(
    data != undefined &&
      data != null &&
      data.listing_row != undefined &&
      data.listing_row !== null
      ? "Edit Listing - Loftus"
      : "Add Listing - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  // const floor_plans = data.floor_plans;
  const branches = data.branches;
  const listing_row = data.listing_row;
  const properties = data.properties;
  // const states = data.states;
  //
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="add-listing" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    listing_row !== undefined &&
                    listing_row !== null &&
                    listing_row !== ""
                      ? "Edit Listing"
                      : "Add Listing"
                  }
                  parent_name="Listings"
                  parent_link="/listing"
                />

                <div className="page_body">
                  <div className="option_lbl medium_opt_lbl justify_center_lbl_cell">
                    <ul>
                      {/* <li className={formStep === 0 ? "active" : ""}>Step 1</li> */}
                      <li className={formStep === 1 ? "active" : ""}>Step 1</li>
                      <li className={formStep === 2 ? "active" : ""}>Step 2</li>
                    </ul>
                  </div>
                  <div className="dash_form_blk listing_add_c">
                    <AddListingForm
                      changeStep={changeStep}
                      step={formStep}
                      handleListing={handleSubmit}
                      isFormProcessing={isFormProcessing}
                      listing_row={listing_row}
                      branches={branches}
                      handleAddNewPrice={handleAddNewPrice}
                      prices={prices}
                      handleRemovePrice={handleRemovePrice}
                      handlePriceChange={handlePriceChange}
                      properties_arr={properties}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
