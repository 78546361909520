import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_USER_PROPERTY_CONTENT,
  FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS,
  FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED,
  SINGLE_USER_PROPERTY_MESSAGE,
  SINGLE_USER_PROPERTY_MESSAGE_SUCCESS,
  SINGLE_USER_PROPERTY_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchSingleUserProperty = (property_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_USER_PROPERTY_CONTENT,
    payload: null,
  });
  http
    .get("user-single-property/" + property_id)
    .then(({ data }) => {
      dispatch({
        type: FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postProperty = (formData, property_id) => (dispatch) => {
  let postUrl = "";

  if (property_id != undefined && property_id !== null) {
    postUrl = "edit-user-property/" + property_id;
  } else {
    postUrl = "add-user-property";
  }
  dispatch({
    type: SINGLE_USER_PROPERTY_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/properties");
        }, 2000);
        dispatch({
          type: SINGLE_USER_PROPERTY_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_USER_PROPERTY_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_USER_PROPERTY_MESSAGE_FAILED,
        payload: error,
      });
    });
};
