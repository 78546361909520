import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import PromotionSort from "./promotions/sort";
import PromotionBlk from "./promotions/blk";

import {
  fetchPromotions,
  deletePromotion,
  getFilteredPromotions,
} from "../../../states/actions/fetchSinglePromotions";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function Promotions() {
  useDocumentTitle("Promotions - Loftus");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSinglePromotions.content);
  const isLoading = useSelector(
    (state) => state.fetchSinglePromotions.isLoading
  );
  const isItemDeleted = useSelector(
    (state) => state.fetchSinglePromotions.isItemDeleted
  );
  const isFilterLoading = useSelector(
    (state) => state.fetchSinglePromotions.isFilterLoading
  );
  useEffect(() => {
    dispatch(fetchPromotions());
  }, []);
  const [promotionType, setPromotionType] = useState("active");
  useEffect(() => {
    if (data?.promotions?.length <= 0 && data?.expired_promotions?.length > 0) {
      setPromotionType("expired");
    }
  }, [data]);
  const handleDelete = (e, promotion_id) => {
    e.preventDefault();
    dispatch(deletePromotion(promotion_id));
  };
  const [filterData, setFilterData] = useState({
    branch_id: null,
  });
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
    };
    dispatch(getFilteredPromotions(formData));
  };
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const promotions = data?.promotions;
  const expired_promotions = data?.expired_promotions;

  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="promotions" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" ? (
                  <Breadcrumb
                    page_name="Promotions"
                    add_link="/add-promotion"
                  />
                ) : (
                  <Breadcrumb page_name="Promotions" />
                )}
                <div className="page_body">
                  <div className="listing_list_c">
                    <div className="outer_list_c">
                      <PromotionSort
                        branches={branches}
                        handleBranchChange={handleBranchChange}
                        promotionType={promotionType}
                        changeType={setPromotionType}
                        active_count={promotions?.length}
                        expired_count={expired_promotions?.length}
                      />
                      {isItemDeleted || isFilterLoading ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {promotionType === "active" ? (
                        <PromotionBlk
                          items={promotions}
                          handleDelete={handleDelete}
                          type="active"
                        />
                      ) : promotionType === "expired" ? (
                        <PromotionBlk
                          items={expired_promotions}
                          handleDelete={handleDelete}
                          type="expired"
                        />
                      ) : (
                        <div className="alert alert-danger">
                          No data found yet!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
