import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

import {
  fetchSaleEditOffer,
  saleOfferSave,
  updateSaleOffer,
} from "../../../states/actions/fetchSendOfferProperty";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import SaleSendOffer from "../send-offer/sale/sale-send-offer";

export default function EditSaleOffer() {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const { offer_id } = useParams();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSaleEditOffer.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);

  const isLoading = useSelector(
    (state) => state.fetchSendOfferProperty.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSendOfferProperty.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSaleEditOffer(offer_id));
  }, []);
  const [formStep, setFormStep] = useState(0);
  const changeStep = (step) => {
    setFormStep(step);
  };
  const [saleOfferCoverLetter, setSaleOfferCoverLetter] = useState({
    file: null,
    file_name: null,
    loading: false,
  });
  useEffect(() => {
    if (parseInt(data?.offer_row?.id) > 0) {
      setSaleOfferCoverLetter({
        ...saleOfferCoverLetter,
        file: data?.offer_row?.offer_letter_file,
        file_name: data?.offer_row?.offer_letter_file_name,
      });
      setValue(
        "target_closing_date",
        offer_row?.target_closing_date !== "" &&
          offer_row?.target_closing_date !== undefined &&
          offer_row?.target_closing_date !== null &&
          offer_row?.target_closing_date !== "TBD"
          ? new Date(offer_row?.target_closing_date_edit)
          : ""
      );
      setValue(
        "offer_response_deadline",
        offer_row?.offer_response_deadline !== null &&
          offer_row?.offer_response_deadline !== undefined &&
          offer_row?.offer_response_deadline !== "" &&
          offer_row?.offer_response_deadline !== "TBD"
          ? new Date(offer_row?.offer_response_deadline_edit)
          : ""
      );
    }
  }, [data?.offer_row]);
  const handleSaleSubmit = (data) => {
    let offer_response_deadline = data?.offer_response_deadline;
    let target_closing_date = data?.target_closing_date;
    let contingencies = data?.contingencies;
    let seller_concessions = data?.seller_concessions;

    if (
      offer_response_deadline === false ||
      offer_response_deadline === null ||
      offer_response_deadline === undefined ||
      offer_response_deadline === ""
    ) {
      offer_response_deadline = "";
    }
    if (
      target_closing_date === false ||
      target_closing_date === null ||
      target_closing_date === undefined ||
      target_closing_date === ""
    ) {
      target_closing_date = "";
    }
    if (
      data?.seller_concessions === false ||
      data?.seller_concessions === null ||
      data?.seller_concessions === undefined ||
      data?.seller_concessions === ""
    ) {
      seller_concessions = null;
    }
    if (
      data?.contingencies === false ||
      data?.contingencies === null ||
      data?.contingencies === undefined ||
      data?.contingencies === ""
    ) {
      contingencies = null;
    }
    let newData = {
      ...data,
      seller_concessions: seller_concessions,
      contingencies: contingencies,
      seller_concessions: seller_concessions,
      offer_response_deadline: offer_response_deadline,
      target_closing_date: target_closing_date,
    };
    dispatch(updateSaleOffer(newData, offer_id));
  };
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let property_row = data.property_row;
  let listing_row = data?.property_row?.listing_row;
  let user = data?.user;
  let offer_row = data?.offer_row;
  let member = data?.member;
  //
  return (
    <>
      <main index="">
        <section className="offer_send_sec">
          <ToastContainer />
          <div className="contain">
            <div className="offer_send_flex_outer">
              <SaleSendOffer
                step={formStep}
                changeStep={changeStep}
                property_row={property_row}
                user={user}
                listing_row={listing_row}
                member={member}
                register={register}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
                isValid={isValid}
                watchAllFields={watchAllFields}
                trigger={trigger}
                setSaleOfferCoverLetter={setSaleOfferCoverLetter}
                saleOfferCoverLetter={saleOfferCoverLetter}
                handleSaleSubmit={handleSaleSubmit}
                isFormProcessing={isFormProcessing}
                offer_row={offer_row}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
