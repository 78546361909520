import {
    FETCH_CONTACT_CONTENT,
    FETCH_CONTACT_CONTENT_SUCCESS,
    FETCH_CONTACT_CONTENT_FAILED,
    CONTACT_MESSAGE,
    CONTACT_MESSAGE_SUCCESS,
    CONTACT_MESSAGE_FAILED,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_CONTACT_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_CONTACT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_CONTACT_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case CONTACT_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case CONTACT_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case CONTACT_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
