import {
    FETCH_SINGLE_DOCUMENT_CONTENT,
    FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS,
    FETCH_SINGLE_DOCUMENT_CONTENT_FAILED,
    SINGLE_DOCUMENT_MESSAGE,
    SINGLE_DOCUMENT_MESSAGE_SUCCESS,
    SINGLE_DOCUMENT_MESSAGE_FAILED,
    FETCH_DOCUMENT_CONTENT,
    FETCH_DOCUMENT_CONTENT_FAILED,
    FETCH_DOCUMENT_CONTENT_SUCCESS,
    DOCUMENT_DELETE__MESSAGE,
    DOCUMENT_DELETE_MESSAGE_SUCCESS,
    DOCUMENT_DELETE__MESSAGE_FAILED,
    GET_FILTER_DOCUMENT,
    GET_FILTER_DOCUMENT_FAILED,
    GET_FILTER_DOCUMENT_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
    floor_plans: [],
    isFilterLoading: false,
    isItemDeleted: false,
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_FILTER_DOCUMENT:
            return {
                ...state,
                isFilterLoading: true,
            };
        case GET_FILTER_DOCUMENT_SUCCESS:
            return {
                ...state,
                isFilterLoading: false,
                content: payload,
            };

        case GET_FILTER_DOCUMENT_FAILED:
            return {
                ...state,
                isFilterLoading: false,
                error: payload,
            };
        case FETCH_DOCUMENT_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_DOCUMENT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_DOCUMENT_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case FETCH_SINGLE_DOCUMENT_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_SINGLE_DOCUMENT_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case SINGLE_DOCUMENT_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SINGLE_DOCUMENT_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case SINGLE_DOCUMENT_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload,
            };
        case DOCUMENT_DELETE__MESSAGE:
            return {
                ...state,
                isItemDeleted: true,
            };
        case DOCUMENT_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                isItemDeleted: false,
                emptyFormData: true,
            };
        case DOCUMENT_DELETE__MESSAGE_FAILED:
            return {
                ...state,
                isItemDeleted: false,
                error: payload,
            };
        default:
            return state;
    }
}
