import { useEffect } from "react";

const useDocumentTitle = (title, site_name) => {
    useEffect(() => {
        if (title) {
            if (site_name && site_name !== undefined) {
                document.title = title + " - " + site_name;
            }
            else {
                document.title = title;
            }

        } else {
            document.title = "Loading...";
        }
    }, [title]);
};

export default useDocumentTitle;
