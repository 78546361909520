import React, { useEffect } from "react";

import { fetchBlogDetail } from "../../states/actions/fetchBlogDetail";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";


import { useParams } from "react-router-dom";
import BlogDetailView from './section/blog-detail-view';

export default function BlogDetail() {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchBlogDetail.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchBlogDetail.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchBlogDetail(slug));
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;

    const blog = content;

    return (
        <>
            <BlogDetailView data={blog.posts} />

        </>
    )
}
