import { ErrorMessage } from "@hookform/error-message";
import { useSelector } from "react-redux";

export default function PaymentMethodBankStep({
  setFormStep,
  register,
  errors,
  payment_method_row,
  isProcessing,
}) {
  const isAddNewCardProcessing = useSelector(
    (state) => state.payments.isAddNewCardProcessing
  );

  return (
    <>
      <div className="row form_row">
        <div className="col-xs-12 info_heading_confirm">
          <h4>Add Bank Account</h4>
        </div>
        <div className="col-xs-6">
          <h6>Account Title</h6>
          <div className="form_blk">
            <input
              type="text"
              name=""
              class="txtBox"
              {...register("card_holder_name", {
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "Invalid value",
                },
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="card_holder_name"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Account Type</h6>
          <div className="form_blk">
            <select
              name="account_type"
              className="txtBox"
              {...register("account_type", {
                required: "Required",
              })}
              disabled={payment_method_row?.id > 0 ? true : false}
            >
              <option value="">Select Account Type</option>
              <option value="C">Checking Account</option>
              <option value="S">Savings Account</option>
            </select>
            <ErrorMessage
              errors={errors}
              name="account_type"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Account Number</h6>
          <div className="form_blk">
            <input
              type="text"
              name=""
              id=""
              className="txtBox"
              {...register("account_number", {
                required: "Required",
              })}
              disabled={payment_method_row?.id > 0 ? true : false}
            />
            <ErrorMessage
              errors={errors}
              name="account_number"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Routing Number</h6>
          <div className="form_blk">
            <input
              type="text"
              name=""
              id=""
              className="txtBox"
              {...register("routing_number", {
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="routing_number"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
      </div>
      <div
        className={
          payment_method_row?.id > 0
            ? "cmnBtn justify_btn_center"
            : "cmnBtn text-center"
        }
      >
        {payment_method_row?.id > 0 ? (
          ""
        ) : (
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => setFormStep(0)}
          >
            Previous
          </button>
        )}
        <button
          type="submit"
          class="webBtn colorBtn"
          disabled={isProcessing || isAddNewCardProcessing ? "disabled" : ""}
        >
          {payment_method_row?.id > 0 ? "Update" : "Submit"}
          {isProcessing || isAddNewCardProcessing ? (
            <i className="spinner"></i>
          ) : (
            ""
          )}
        </button>
      </div>
    </>
  );
}
