import React from 'react'
import ActivePropertyBlk from './ActivePropertyBlk';
import PendingPropertyBlk from './pendingPropertyBlk';

export default function PropertyTabs(props) {
    const { propertyType, active_properties, pending_properties, listView, handleCancelPropertyRequest, isPropertyCancelProcessing } = props;
    return (
        <>
            <div className="tab-content">
                {
                    propertyType === 'active' ?
                        <ActivePropertyBlk propertyType={propertyType} items={active_properties} listView={listView} />
                        :
                        (
                            propertyType === 'pending' ?


                                <PendingPropertyBlk propertyType={propertyType} items={pending_properties} listView={listView} handleCancelPropertyRequest={handleCancelPropertyRequest} isPropertyCancelProcessing={isPropertyCancelProcessing} />
                                :
                                ""
                        )
                }
            </div>
        </>
    )
}
