import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  fetchFrontendProperty,
  deleteProperty,
  deleteListing,
} from "../../states/actions/fetchFrontendProperty";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import CommercialProperty from "./single-property/commercial";
import NormalUserProperty from "./single-property/normal";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import { postData, projectUrl } from "../../helpers/api";
import Error from "./Error";
import UpdateRentListingPopup from "./dashboard/sections/updateRentListingPopup";
import ExtendRentListingPopup from "./dashboard/sections/extendRentListingPopup";

export default function SingleProperty() {
  const { slug } = useParams();
  const { listing_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchFrontendProperty.content);
  console.log(data);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchFrontendProperty.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const handleDelete = (data) => {
    dispatch(deleteProperty(data));
  };
  const handleListingDelete = (data) => {
    dispatch(deleteListing(data));
  };
  useEffect(() => {
    dispatch(fetchFrontendProperty(slug, listing_id));
  }, []);

  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const [reviewPopup, setReviewPopup] = useState({
    item: null,
    show: false,
  });
  const [reviewLoading, setLoading] = useState(false);
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  const showReviewPopup = (item) => {
    setReviewPopup({ ...reviewPopup, item: item, show: true });
    // alert(!popup);
  };
  const closeReviewPopup = () => {
    setReviewPopup({ ...reviewPopup, item: null, show: false });
  };
  const [extendListing, setExtendListing] = useState({
    item: null,
    show: false,
  });
  const [updateListing, setUpdateListing] = useState({
    item: null,
    show: false,
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [extendLoading, setExtendLoading] = useState(false);
  const handleUpdateListing = async (form_data, id) => {
    setUpdateLoading(true);
    await postData("api/update-rent-listing/" + id, form_data).then((data) => {
      setUpdateLoading(false);
      if (data?.status === 1) {
        toast.success(data.msg, TOAST_SETTINGS);
        setTimeout(() => {
          setUpdateListing({ ...updateListing, item: null, show: false });
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  };
  const handleExtendListing = async (form_data, id) => {
    setExtendLoading(true);
    await postData("api/extend-rent-listing/" + id, form_data).then((data) => {
      setExtendLoading(false);
      if (data?.status === 1) {
        toast.success(data.msg, TOAST_SETTINGS);
        setTimeout(() => {
          setExtendListing({ ...extendListing, item: null, show: false });
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  };
  const showUpdateListingPopup = (item) => {
    setUpdateListing({ ...updateListing, item: item, show: true });
  };
  const hideUpdateListingPopup = () => {
    setUpdateListing({ ...updateListing, item: null, show: false });
  };
  const showExtendListingPopup = (item) => {
    setExtendListing({ ...extendListing, item: item, show: true });
  };
  const hideExtendListingPopup = () => {
    setExtendListing({ ...extendListing, item: null, show: false });
  };

  const handleListingReview = async (form_data) => {
    if (
      data?.member !== undefined &&
      data?.member !== null &&
      data?.member?.id > 0
    ) {
      if (data?.member?.id !== data.property_row?.mem_id) {
        setLoading(true);
        await postData(
          "api/property-review-save/" + data.property_row?.listing?.id,
          form_data
        ).then((res) => {
          setLoading(false);
          if (res.status === 1) {
            toast.success(res.msg, TOAST_SETTINGS);

            setTimeout(() => {
              setReviewPopup({ ...reviewPopup, item: null, show: false });
            }, 2000);
          } else {
            toast.error(res.msg, TOAST_SETTINGS);
          }
        });
      } else {
        toast.error(
          "As an owner of property you can not submit review for this property!",
          TOAST_SETTINGS
        );
        return;
      }
    } else {
      toast.error("Please login to submit your review!", TOAST_SETTINGS);
      return;
    }
  };
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let property_row = data?.property_row;
  let open_houses = property_row?.listing_row?.open_houses_arr;
  let properties = data?.properties;
  let page_title = data?.page_title;
  let branch = data?.branch;
  let member = data?.member;
  let media = data?.property_row?.media;
  let walkscore = data?.property_row?.walkscore;
  let transitscore = data?.property_row?.transitscore;
  let top_listings = data?.top_listings;
  let property_url = "";
  if (property_row?.listing?.mem_type !== "member") {
    property_url = projectUrl("property/" + slug);
  } else {
    property_url = projectUrl(
      "property/" + slug + "/" + property_row?.listing?.listing_id
    );
  }
  return (
    // (data?.property_row?.listing !== undefined && data?.property_row?.listing !== null && data?.property_row?.listing !== '') ?
    <>
      {updateListing?.show === true &&
      updateListing?.item != undefined &&
      updateListing?.item != null &&
      updateListing?.item != "" ? (
        <UpdateRentListingPopup
          hideUpdateListingPopup={hideUpdateListingPopup}
          listingPopup={updateListing}
          handleUpdateListing={handleUpdateListing}
          updateLoading={updateLoading}
        />
      ) : (
        ""
      )}
      {extendListing?.show === true &&
      extendListing?.item != undefined &&
      extendListing?.item != null &&
      extendListing?.item != "" ? (
        <ExtendRentListingPopup
          hideUpdateListingPopup={hideExtendListingPopup}
          listingPopup={extendListing}
          siteSettings={siteSettings}
          extendLoading={extendLoading}
          handleExtendListing={handleExtendListing}
        />
      ) : (
        ""
      )}
      {property_row?.mem_type === "member" ? (
        // <CommercialProperty address={page_title} property={property_row} branch={branch} popup={popup} closePopup={closePopup} showPopup={showPopup} member={member} handleDelete={handleDelete} property_url={property_url} media={media} type="commercial" property_page={true} />
        // :
        property_row?.mem_type === "member" ? (
          <NormalUserProperty
            address={page_title}
            property={property_row}
            branch={branch}
            popup={popup}
            closePopup={closePopup}
            showPopup={showPopup}
            member={member}
            handleDelete={handleDelete}
            showReviewPopup={showReviewPopup}
            reviewPopup={reviewPopup}
            closeReviewPopup={closeReviewPopup}
            handleListingReview={handleListingReview}
            reviewLoading={reviewLoading}
            property_url={property_url}
            property_page={true}
            showUpdateListingPopup={showUpdateListingPopup}
            showExtendListingPopup={showExtendListingPopup}
            handleListingDelete={handleListingDelete}
            open_houses={open_houses}
            walkscore={walkscore}
            top_listings={top_listings}
          />
        ) : (
          ""
        )
      ) : (
        (window.location.href = "/404")
      )}
    </>
  );
}
