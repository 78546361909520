import React from "react";
import SingleMediaImage from "./single-image";
export default function MediaImageBlk({ images, path, handleRemoveImage }) {
  return (
    <>
      <div className="upLoadBlk txtBox">
        <div className="inside scrollbar">
          <ul className="imgLst flex mediaImgLst">
            {images != undefined && images !== null && images?.length > 0
              ? images?.map((image, index) => {
                  return (
                    <SingleMediaImage
                      key={index}
                      image={image}
                      path={path}
                      handleRemoveImage={handleRemoveImage}
                      index={index}
                    />
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    </>
  );
}
