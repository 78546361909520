import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_RESET_CONTENT,
    FETCH_RESET_CONTENT_SUCCESS,
    FETCH_RESET_CONTENT_FAILED,
    RESET_MESSAGE,
    RESET_MESSAGE_SUCCESS,
    RESET_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchResetPassword = (token) => (dispatch) => {
    dispatch({
        type: FETCH_RESET_CONTENT,
        payload: null
    });
    http
        .get("reset-page/" + token)
        .then(({ data }) => {
            if (data.status === 0) {
                window.location.href("/signup");
            }
            dispatch({
                type: FETCH_RESET_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_RESET_CONTENT_FAILED,
                payload: error
            });
        });
};
export const postReset = (formData, token) => (dispatch) => {
    dispatch({
        type: RESET_MESSAGE,
        payload: null
    });
    http
        .post("reset-password/" + token, helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: RESET_MESSAGE_SUCCESS,
                    payload: data
                });
                setTimeout(() => {
                    window.location.replace("/login");
                }, 3000);

            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: RESET_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: RESET_MESSAGE_FAILED,
                payload: error
            });
        });
};

