import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { NavLink } from "react-router-dom";
import SingleReview from "./single-review";

export default function PropertyReviews({ property, member, showReviewPopup }) {
  const [reply, setReply] = useState(false);
  const toggleReply = () => {
    setReply(!reply);
  };

  const changeRating = (newRating, name) => {};
  return (
    <>
      {property?.listing?.reviews_arr !== undefined &&
      property?.listing?.reviews_arr !== null &&
      property?.listing?.reviews_arr?.length > 0 ? (
        <>
          <div className="review_read_blk">
            <div className="review_heading">
              <ul className="total_rating space_between_flex">
                <li className="review_star">
                  <i className="fa fa-star"></i>
                  <span>
                    {" "}
                    5.0 (
                    {property?.listing?.reviews_arr !== undefined
                      ? property?.listing?.reviews_arr?.length
                      : 0}{" "}
                    Reviews)
                  </span>
                </li>
                {member !== undefined &&
                member !== null &&
                member?.id != property?.mem_id &&
                property?.listing?.listing_type === "rent" ? (
                  <li className="review_btn">
                    <NavLink
                      to="#!"
                      className="webBtn colorBtn"
                      onClick={() => showReviewPopup(property)}
                    >
                      Submit Review
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
              </ul>

              <ul className="all_rating">
                <li className="review_star">
                  <span>Accuracy</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
                <li className="review_star">
                  <span>Safety</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
                <li className="review_star">
                  <span>Experience</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
                <li className="review_star">
                  <span>Cleanliness</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
                <li className="review_star">
                  <span>Responsiveness</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
                <li className="review_star">
                  <span>Community</span>
                  {/* <div className="ratestars-1"></div> */}
                  <StarRatings
                    rating={5}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                  />
                </li>
              </ul>
            </div>
            {property?.listing?.reviews_arr !== undefined &&
            property?.listing?.reviews_arr !== null &&
            property?.listing?.reviews_arr?.length > 0 ? (
              <div className="review_blk_listing">
                <ul>
                  {property?.listing?.reviews_arr?.map((review, index) => {
                    return (
                      <SingleReview
                        review={review}
                        key={index}
                        reply={reply}
                        toggleReply={toggleReply}
                      />
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <hr />
        </>
      ) : (
        ""
      )}
    </>
  );
}
