import React from 'react'
import ExtraInfoBlk from '../../common/extraInfoBlk'

export default function ExtraInfoSection({ data }) {
    return (
        <>
            <section className="extra_info_sec">
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <div className="sec_heading">
                                <h2>{data.heading}</h2>
                            </div>
                        </div>
                        <div className="colR">
                            <div className="_inner">

                                {data.blocks.map((val, index) => {
                                    return (
                                        <ExtraInfoBlk data={val} key={index} />
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
