import React from "react";
import Image from "../../common/image";

export default function ServicesBanner({ data }) {
    return (
        <>
            <section className="services_banner">
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <div className="inner">
                                <div className="page_heading">
                                    <h1>{data.heading}</h1>
                                </div>
                                <p>{data.text}</p>
                            </div>
                        </div>
                        <div className="top_right_image">
                            <Image src={data.image} alt={data.heading} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
