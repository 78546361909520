import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_OFFER_PROPERTY_CONTENT,
  FETCH_OFFER_PROPERTY_CONTENT_SUCCESS,
  FETCH_OFFER_PROPERTY_CONTENT_FAILED,
  SEND_OFFER_MESSAGE,
  SEND_OFFER_MESSAGE_SUCCESS,
  SEND_OFFER_MESSAGE_FAILED,
  SEND_OFFER_PAYPAL_MESSAGE,
  SEND_OFFER_PAYPAL_MESSAGE_SUCCESS,
  SEND_OFFER_PAYPAL_MESSAGE_FAILED,
  SEND_OFFER_STRIPE_MESSAGE,
  SEND_OFFER_STRIPE_MESSAGE_SUCCESS,
  SEND_OFFER_STRIPE_MESSAGE_FAILED,
  FETCH_SALE_OFFER_PROPERTY_CONTENT,
  FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS,
  FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED,
} from "./actionTypes";

export const fetchSendOfferProperty =
  (property_type, slug, listing_id, offer_id) => (dispatch) => {
    let getUrl = "";
    // if (listing_id !== undefined && listing_id !== null && listing_id !== '') {
    //     if (offer_id !== undefined && offer_id !== null && offer_id !== '') {
    //         getUrl = 'invite-offer-property-details-page/' + slug + "/" + listing_id + "/" + offer_id;
    //     }
    //     else {
    //         getUrl = 'offer-normal-property-details-page/' + slug + "/" + listing_id;
    //     }

    // }
    // else if (offer_id !== undefined && offer_id !== null && offer_id !== '') {
    //     getUrl = 'invite-offer-property-details-page/' + slug + "/" + offer_id;
    // }
    // else {
    //     getUrl = 'offer-property-details-page/' + slug;;
    // }
    if (listing_id !== undefined && listing_id !== null && listing_id !== "") {
      if (offer_id !== undefined && offer_id !== null && offer_id !== "") {
        getUrl =
          "invite-offer-property-details-page/" +
          property_type +
          "/" +
          slug +
          "/" +
          listing_id +
          "/" +
          offer_id;
      } else {
        getUrl =
          "offer-normal-property-details-page/" +
          property_type +
          "/" +
          slug +
          "/" +
          listing_id;
      }
    } else if (offer_id !== undefined && offer_id !== null && offer_id !== "") {
      getUrl =
        "invite-offer-property-details-page/" +
        property_type +
        "/" +
        slug +
        "/" +
        offer_id;
    } else {
      getUrl = "offer-property-details-page/" + property_type + "/" + slug;
    }
    // ;
    dispatch({
      type: FETCH_OFFER_PROPERTY_CONTENT,
      payload: null,
    });
    http
      .get(getUrl)
      .then(({ data }) => {
        if (data?.status === 0) {
          window.location.replace("/");
        } else if (data?.offer_status === 1) {
          window.location.replace("/offers");
        }
        dispatch({
          type: FETCH_OFFER_PROPERTY_CONTENT_SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_OFFER_PROPERTY_CONTENT_FAILED,
          payload: error,
        });
      });
  };
export const fetchSaleEditOffer = (offer_id) => (dispatch) => {
  let getUrl = "fetch-edit-sale-offer/" + offer_id;
  // ƒ
  dispatch({
    type: FETCH_SALE_OFFER_PROPERTY_CONTENT,
    payload: null,
  });
  http
    .get(getUrl)
    .then(({ data }) => {
      if (data?.status === 0) {
        window.location.replace("/");
      }
      dispatch({
        type: FETCH_SALE_OFFER_PROPERTY_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SALE_OFFER_PROPERTY_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const sendOfferValidationRequest =
  (formData, slug, listing_id, property_type) => (dispatch) => {
    let postUrl =
      "offer-validation-request/" +
      slug +
      "/" +
      listing_id +
      "/" +
      property_type;
    //
    dispatch({
      type: SEND_OFFER_MESSAGE,
      payload: null,
    });
    http
      .post(postUrl, helpers.doObjToFormData(formData))
      .then(({ data }) => {
        //
        if (data.status) {
          // toast.success(
          //     `${data.msg}`,
          //     TOAST_SETTINGS
          // );

          dispatch({
            type: SEND_OFFER_MESSAGE_SUCCESS,
            payload: data,
          });
        } else {
          if (data?.manual_status !== 1) {
            toast.error(
              <Text string={data.msg} parse={true} />,
              TOAST_SETTINGS
            );
          }

          dispatch({
            type: SEND_OFFER_MESSAGE_FAILED,
            payload: data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SEND_OFFER_MESSAGE_FAILED,
          payload: error,
        });
      });
  };
export const postOfferPaypal =
  (property_type, formData, slug, listing_id, offer_id, verified = false) =>
    (dispatch) => {
      let postUrl = "";
      if (offer_id !== undefined && offer_id !== null && offer_id !== "") {
        if (verified === true) {
          postUrl =
            "invite-offer-verified-save/" +
            property_type +
            "/" +
            slug +
            "/" +
            listing_id +
            "/" +
            offer_id;
        } else if (
          formData?.package_upgrade_report === true &&
          verified === false
        ) {
          postUrl =
            "invite-offer-upgrade-save/" +
            property_type +
            "/" +
            slug +
            "/" +
            listing_id +
            "/" +
            offer_id;
        } else {
          postUrl =
            "invite-offer-paypal-save/" +
            property_type +
            "/" +
            slug +
            "/" +
            listing_id +
            "/" +
            offer_id;
        }
      } else if (
        formData?.package_upgrade_report === true &&
        verified === false
      ) {
        postUrl =
          "offer-upgrade-save/" + property_type + "/" + slug + "/" + listing_id;
      } else if (verified === true) {
        postUrl =
          "offer-verified-save/" + property_type + "/" + slug + "/" + listing_id;
      } else {
        postUrl =
          "offer-paypal-save/" + property_type + "/" + slug + "/" + listing_id;
      }
      // console.log(postUrl); return;

      let finalFormData = [];
      if (formData?.property_type === "company") {
        if (formData?.flexibile_lease_company === "No") {
          if (formData?.term !== undefined && formData?.term !== null) {
            let term_split = formData?.term.split("-");
            finalFormData = {
              ...formData,
              price: term_split[0],
              term: term_split[1],
            };
          } else {
            finalFormData = formData;
          }
        } else {
          finalFormData = formData;
        }
      } else {
        finalFormData = formData;
      }
      dispatch({
        type: SEND_OFFER_PAYPAL_MESSAGE,
        payload: null,
      });
      http
        .post(postUrl, helpers.doObjToFormData(finalFormData))
        .then(({ data }) => {
          if (data.status) {
            toast.success(`${data.msg}`, TOAST_SETTINGS);
            setTimeout(() => {
              window.location.replace("/offers");
            }, 2000);
            dispatch({
              type: SEND_OFFER_PAYPAL_MESSAGE_SUCCESS,
              payload: data,
            });
          } else {
            toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
            dispatch({
              type: SEND_OFFER_PAYPAL_MESSAGE_FAILED,
              payload: null,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: SEND_OFFER_PAYPAL_MESSAGE_FAILED,
            payload: error,
          });
        });
    };
export const saleOfferSave =
  (formData, slug, listing_id, offer_id) => (dispatch) => {
    let postUrl = "sale-offer-save/" + slug + "/" + listing_id;
    //
    dispatch({
      type: SEND_OFFER_STRIPE_MESSAGE,
      payload: null,
    });
    http
      .post(postUrl, helpers.doObjToFormData(formData))
      .then(({ data }) => {
        if (data.status) {
          toast.success(`${data.msg}`, TOAST_SETTINGS);
          setTimeout(() => {
            window.location.replace("/offers");
          }, 2000);
          dispatch({
            type: SEND_OFFER_STRIPE_MESSAGE_SUCCESS,
            payload: data,
          });
        } else {
          toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
          dispatch({
            type: SEND_OFFER_STRIPE_MESSAGE_FAILED,
            payload: null,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SEND_OFFER_STRIPE_MESSAGE_FAILED,
          payload: error,
        });
      });
  };
export const updateSaleOffer =
  (formData, offer_id, type = "sale") =>
    (dispatch) => {
      let postUrl = "sale-offer-update/" + offer_id;
      if (type === "rent") {
        postUrl = "rent-offer-update/" + offer_id;
      }
      let finalFormData = [];
      if (formData?.property_type === "company") {
        if (formData?.flexibile_lease_company === "No") {
          let term_split = formData?.term.split("-");
          finalFormData = {
            ...formData,
            price: term_split[0],
            term: term_split[1],
          };
        } else {
          finalFormData = formData;
        }
      } else {
        finalFormData = formData;
      }
      dispatch({
        type: SEND_OFFER_STRIPE_MESSAGE,
        payload: null,
      });
      http
        .post(postUrl, helpers.doObjToFormData(finalFormData))
        .then(({ data }) => {
          if (data.status) {
            toast.success(`${data.msg}`, TOAST_SETTINGS);
            setTimeout(() => {
              window.location.replace("/my-offer/" + offer_id);
            }, 2000);
            dispatch({
              type: SEND_OFFER_STRIPE_MESSAGE_SUCCESS,
              payload: data,
            });
          } else {
            toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
            dispatch({
              type: SEND_OFFER_STRIPE_MESSAGE_FAILED,
              payload: null,
            });
          }
        })
        .catch((error) => {
          dispatch({
            type: SEND_OFFER_STRIPE_MESSAGE_FAILED,
            payload: error,
          });
        });
    };
