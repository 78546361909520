import React, { useEffect } from "react";
import { getServerImage } from '../../helpers/api';

import { fetchFaq } from "../../states/actions/fetchFaq";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import FaqBanner from './section/faq-banner';
import FaqList from './section/faq-list';

export default function Faq() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchFaq.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchFaq.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchFaq());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const faq = content;

    return (
        <>
            <FaqBanner data={faq.intro} />
            <FaqList data={faq.faq_list} />
        </>
    )
}
