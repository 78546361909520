import React, { useState, useRef, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { getData } from "../../../helpers/api";
import { useNavigate, useLocation } from "react-router-dom";

export default function Search({ address, searchProperties, propertyType }) {
  const closeSuggessions = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [locations, setLocations] = useState({
    properties: [],
    states: [],
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const watchAllFields = watch();
  const onSubmit = (data) => {
    setLocations({ properties: [], states: [] });
    searchParams.set("address", watchAllFields?.address);
    navigate(`?${searchParams.toString()}`);

    if (
      closeSuggessions.current !== null &&
      closeSuggessions.current !== undefined &&
      closeSuggessions.current !== ""
    ) {
      closeSuggessions.current.style.display = "none";
    }
    setTimeout(() => {
      searchProperties(data?.address);
    }, 500);
  };
  useEffect(() => {}, [closeSuggessions, locations]);
  const handleClosePopup = (event) => {
    if (
      closeSuggessions.current &&
      !closeSuggessions.current.contains(event.target)
    ) {
      setLocations({ properties: [], states: [] });
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClosePopup);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClosePopup);
    };
  }, []);

  const handleInputChange = async (e) => {
    if (e.keyCode != 13) {
      let address = e.target.value;

      if (
        closeSuggessions.current !== null &&
        closeSuggessions.current !== undefined &&
        closeSuggessions.current !== ""
      ) {
        closeSuggessions.current.style.display = "block";
      }
      await getData("api/get-property-locations", address).then((data) => {
        setLocations({ properties: data?.properties, states: data?.states });
      });
    } else {
      setLocations({ properties: [], states: [] });

      setTimeout(() => {
        if (document.getElementById("closeSuggessions")) {
          document.getElementById("closeSuggessions").style.display = "none";
        }

        if (closeSuggessions.current) {
          closeSuggessions.current.style.display = "none";
        }
      }, 400);
    }
    // searchParams.set('address', address);
    // navigate(`?${searchParams.toString()}`);
  };
  const handleCancelPopup = () => {
    setLocations({ properties: [], states: [] });
  };

  return (
    <form
      action=""
      method="GET"
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="none"
    >
      <div className="relative">
        <input
          type="text"
          className="txtBox"
          id="search_address"
          placeholder="Address, City, ZIP Code, …."
          defaultValue={address}
          {...register("address")}
          onKeyUp={handleInputChange}
        />
        <button type="submit" name="" className="webBtn colorBtn">
          <i className="fi-search" />
        </button>
        {locations?.states?.length > 0 || locations?.properties?.length > 0 ? (
          <ul
            className="dropCnt dropLst active search_list"
            ref={closeSuggessions}
            id="closeSuggessions"
          >
            <div className="crosBtn" onClick={handleCancelPopup}></div>
            {locations?.states?.length > 0 ? (
              <div className="state_drop">
                <h5>States:</h5>
                {locations?.states?.map((state, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={
                          propertyType !== undefined &&
                          propertyType !== null &&
                          propertyType !== ""
                            ? "/explore?type=" +
                              propertyType +
                              "&&address=" +
                              state?.name
                            : "/explore?type=&&address=" + state?.name
                        }
                      >
                        {state?.name}, {state?.code}
                      </a>
                    </li>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            {locations?.properties?.length > 0 ? (
              <div className="prop_drop">
                <h5>Properties:</h5>
                {locations?.properties?.map((property, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={
                          propertyType !== undefined &&
                          propertyType !== null &&
                          propertyType !== ""
                            ? "/explore?type=" +
                              propertyType +
                              "&&address=" +
                              property?.address
                            : "/explore?type=&&address=" + property?.address
                        }
                      >
                        {property?.address}
                      </a>
                    </li>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}
      </div>
    </form>
  );
}
