import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { postData } from "../../../helpers/api";
import { ErrorMessage } from "@hookform/error-message";
import InputMask from "react-input-mask";
import OTPInput from "otp-input-react";
import Timer from "../../common/timer";
import ResendCode from "../dashboard-company/sections/resend-code";

export default function ChangePhone({
  handleSubmit,
  register,
  errors,
  handleHidePhoneNumber,
  phoneVerify,
  phoneStep,
  setPhoneStep,
  OTP,
  handleOTPChange,
  setPhoneVerify,
  setUserPhone,
}) {
  const [resendVerify, setResendVerify] = useState(null);
  const [resendOTPExpire, setOtpExpire] = useState(null);
  const sendOtoToPhone = (e) => {
    setPhoneVerify(true);
    e.preventDefault();
    if (OTP === "") {
      toast.error("OTP is required", TOAST_SETTINGS);
    } else {
      postData("api/send-otp-to-phone-verification", OTP).then((data) => {
        // postData("api/verified-phone-change-otp-check", OTP).then((data) => {

        if (data.status === 1) {
          setPhoneVerify(false);
          setOtpExpire(data.expired_date);
          // toast.success(data.msg,
          //     TOAST_SETTINGS
          // );
          setTimeout(() => {
            setPhoneStep(2);
            handleOTPChange("");
            // setUserPhone(false);
          }, 2000);
        } else {
          toast.error(data.msg, TOAST_SETTINGS);
          setPhoneVerify(false);
        }
      });
      // dispatch(postVerifyOtp(OTP));
    }
  };
  const handleVerifySubmit = (e) => {
    setPhoneVerify(true);
    e.preventDefault();
    if (OTP === "") {
      toast.error("OTP is required", TOAST_SETTINGS);
    } else {
      postData("api/verified-phone-change-otp-check", OTP).then((data) => {
        if (data.status === 1) {
          setPhoneVerify(false);
          toast.success(data.msg, TOAST_SETTINGS);
          handleOTPChange("");
          // reset()
          setTimeout(() => {
            window.location.replace("/profile-settings");
          }, 3000);
          setTimeout(() => {
            // setPhoneStep(0);
            setUserPhone(false);
            document.getElementById("phoneChange").value = "";
          }, 2000);
        } else {
          toast.error(data.msg, TOAST_SETTINGS);
          setPhoneVerify(false);
        }
      });
      // dispatch(postVerifyOtp(OTP));
    }
  };
  const onSubmit = (formValues) => {
    setPhoneVerify(true);
    var form_data = new FormData();
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }
    postData("api/change-verified-phone", form_data).then((data) => {
      if (data.status === 1) {
        setOtpExpire(data.expired_date);
        setPhoneVerify(false);
        handleOTPChange("");
        // toast.success(data.msg,
        //     TOAST_SETTINGS
        // );

        // reset()
        setTimeout(() => {
          setPhoneStep(1);
          // setUserPhone(false);
          document.getElementById("phoneChange").value = "";
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
        setPhoneVerify(false);
      }
    });
  };
  useEffect(() => {}, [resendOTPExpire]);
  async function resendOtpCode(e) {
    setResendVerify(true);
    var form_data = new FormData();
    form_data.append("phone", true);

    await postData("api/resend-otp-code-email", form_data).then((data) => {
      if (data.status === 1) {
        setOtpExpire(data.expired_date);
        setResendVerify(false);
        handleOTPChange("");
        toast.success(data.msg, TOAST_SETTINGS);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  }
  async function resendOtpCodePhone(e) {
    setResendVerify(true);
    var form_data = new FormData();
    form_data.append("phone", true);

    await postData("api/resend-otp-code-new-phone", form_data).then((data) => {
      if (data.status === 1) {
        setOtpExpire(data.expired_date);
        setResendVerify(false);
        handleOTPChange("");
        toast.success(data.msg, TOAST_SETTINGS);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  }
  return (
    <>
      <section
        className="popup small_popup"
        data-popup="verify-number"
        style={{ display: "block" }}
      >
        <div className="tableDv">
          <div className="tableCell">
            <ToastContainer />
            <div className="_inner">
              {phoneStep === 0 ? (
                <>
                  <form
                    action=""
                    method="POST"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="none"
                  >
                    <div className="txtGrp text-center">
                      <h4>Update Phone Number</h4>
                    </div>
                    <div className="txtGrp">
                      <h6>
                        Please provide the new phone number that you would like
                        to associate with your account.{" "}
                      </h6>
                      <InputMask
                        mask="+1 (999) 999-9999"
                        name="phone"
                        id="phoneChange"
                        className="txtBox"
                        autoComplete="none"
                        {...register("phone", {
                          required: "Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="phone"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>

                    <div className="text-center bTn">
                      <button
                        type="button"
                        className="webBtn blankBtn colorBtn"
                        onClick={handleHidePhoneNumber}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="webBtn colorBtn"
                        disabled={phoneVerify === true ? "disabled" : ""}
                      >
                        {phoneVerify === true ? (
                          <i className="spinner"></i>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </>
              ) : phoneStep === 1 || phoneStep === true ? (
                <>
                  <form action="" method="POST" onSubmit={sendOtoToPhone}>
                    <div className="txtGrp text-center">
                      <h4>A verification has been sent to your email</h4>
                    </div>
                    <div className="txtGrp">
                      <h6>
                        Please enter the code sent to your email to verify your
                        phone number update request
                      </h6>

                      <div className="otp_fields">
                        <OTPInput
                          value={OTP}
                          onChange={handleOTPChange}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          isInputNum
                        />
                      </div>
                    </div>
                    <div className="txtGrp">
                      {resendOTPExpire !== null ? (
                        <Timer deadline={resendOTPExpire} />
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="bTn text-center">
                      <button
                        type="button"
                        className="webBtn blankBtn colorBtn"
                        onClick={() => setPhoneStep(0)}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="webBtn colorBtn"
                        disabled={phoneVerify === true ? "disabled" : ""}
                      >
                        {phoneVerify === true ? (
                          <i className="spinner"></i>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                  <ResendCode
                    resendOtpCode={resendOtpCode}
                    phoneVerify={resendVerify}
                  />
                </>
              ) : phoneStep === 2 ? (
                <>
                  <form action="" method="POST" onSubmit={handleVerifySubmit}>
                    <div className="txtGrp text-center">
                      <h4>A verification has been sent to your phone number</h4>
                    </div>
                    <div className="txtGrp">
                      <h6>
                        Please enter the code below to verify your phone number
                      </h6>

                      <div className="otp_fields">
                        <OTPInput
                          value={OTP}
                          onChange={handleOTPChange}
                          autoFocus
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          isInputNum
                        />
                      </div>
                    </div>
                    <div className="txtGrp">
                      {resendOTPExpire !== null ? (
                        <Timer deadline={resendOTPExpire} />
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="bTn text-center">
                      <button
                        type="button"
                        className="webBtn blankBtn colorBtn"
                        onClick={() => setPhoneStep(0)}
                      >
                        Back
                      </button>
                      <button
                        type="submit"
                        className="webBtn colorBtn"
                        disabled={phoneVerify === true ? "disabled" : ""}
                      >
                        {phoneVerify === true ? (
                          <i className="spinner"></i>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                  <ResendCode
                    resendOtpCode={resendOtpCodePhone}
                    phoneVerify={resendVerify}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
