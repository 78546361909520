import React from "react";
import MyLeases from "./myLeases";
import PendingLeases from "./pendingLeases";
import LeaseSort from "./lease-sort";

export default function LeaseTabs({
  type,
  pending_landlord_leases,
  tenant_pending_leases,
  myLeaseType,
  setMyLeaseType,
  accepted_landlord_leases,
  tenant_accepted_leases,
  rejected_landlord_leases,
  tenant_rejected_leases,
  handleType,
  isFilterLoading,
  expired_landlord_leases,
  tenant_expired_leases,
}) {
  return (
    <>
      <div className="outer_list hide_sm_clmn whole_lst_lnk offers_outer_list">
        <div className="tab-content">
          <div id="pending_leases" className="tab-pane fade active in a">
            {isFilterLoading ? (
              <div className="loadingDisabled">
                <i className="spinner"></i>
              </div>
            ) : (
              ""
            )}
            {/* <LeaseSort type={type} myLeaseType={myLeaseType} setMyLeaseType={setMyLeaseType} pending_landlord_leases={pending_landlord_leases} tenant_pending_leases={tenant_pending_leases} accepted_landlord_leases={accepted_landlord_leases} tenant_accepted_leases={tenant_accepted_leases} rejected_landlord_leases={rejected_landlord_leases} tenant_rejected_leases={tenant_rejected_leases} handleType={handleType} /> */}
            {type === "landlord" ? (
              <>
                {myLeaseType === "pending" ? (
                  <PendingLeases leases={pending_landlord_leases} type={type} />
                ) : myLeaseType === "accepted" ? (
                  <PendingLeases
                    leases={accepted_landlord_leases}
                    type={type}
                  />
                ) : myLeaseType === "rejected" ? (
                  <PendingLeases
                    leases={rejected_landlord_leases}
                    type={type}
                  />
                ) : myLeaseType === "expired" ? (
                  <PendingLeases leases={expired_landlord_leases} type={type} />
                ) : (
                  ""
                )}
              </>
            ) : type === "tenant" ? (
              myLeaseType === "pending" ? (
                <PendingLeases leases={tenant_pending_leases} type={type} />
              ) : myLeaseType === "accepted" ? (
                <PendingLeases leases={tenant_accepted_leases} type={type} />
              ) : myLeaseType === "rejected" ? (
                <PendingLeases leases={tenant_rejected_leases} type={type} />
              ) : myLeaseType === "expired" ? (
                <PendingLeases leases={tenant_expired_leases} type={type} />
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
