import React, { useState } from "react";
import { TOAST_SETTINGS } from "../../../../../utils/siteSettings";
import { toast } from "react-toastify";
import {
  imageValidation,
  isCheckedFeature,
  projectImageUpload,
} from "../../../../../helpers/api";
import ImgGridRepeater from "../../../../common/imgGridRepeater";
import ImagesBlk from "../../../../common/ImagesBlk";
import Thumbnail from "../../../../common/thumbnail";

export default function PropertyStep4({
  step,
  changeStep,
  images,
  uploadPropertyImages,
  handleAddLabel,
  handleLabelChange,
  labels,
  handleRemoveLabelImage,
  imageThumbnail,
  setThumbnail,
  path,
  isFormProcessing,
  trigger,
  handleRemoveLabel,
  watchAllFields,
  features,
}) {
  function uploadMultipleImages(event, label) {
    uploadPropertyImages(event, label);
  }
  async function handleUploadThumbnail(e) {
    setThumbnail("loading.gif");
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
      toast.error(valid.error, TOAST_SETTINGS);
    } else {
      let image = await projectImageUpload(files);
      if (image.status === 1) {
        setThumbnail(image.image_name);
      } else {
        toast.error(valid.error, TOAST_SETTINGS);
      }
    }
  }
  function deleteThumbnail() {
    setThumbnail(null);
  }
  return (
    <>
      <fieldset>
        <div className="form_row row">
          <div className="col-xs-12">
            <div className="form_blk">
              {images != undefined && images.loading === true ? (
                <div className="loadingDisabled">
                  <i className="spinner"></i>
                </div>
              ) : (
                ""
              )}
              <div className="crud_image text-center crud_image_mini_heading">
                <h4 className="mrgn_auto">Default Image</h4>
                <Thumbnail
                  imageThumbnail={imageThumbnail}
                  deleteThumbnail={deleteThumbnail}
                  handleUploadThumbnail={handleUploadThumbnail}
                  path={path}
                />
              </div>

              <div className="gap_line_divide"></div>

              <ImagesBlk
                title="Kitchen"
                btn_name="Upload"
                handleRemoveLabelImage={handleRemoveLabelImage}
                uploadMultipleImages={uploadMultipleImages}
                images={images}
                label="kitchen"
                path={path}
              />

              <div className="gap_line_divide"></div>

              {parseInt(watchAllFields?.full_bathrooms) > 0 ? (
                <>
                  {Array.from(
                    Array(parseInt(watchAllFields?.full_bathrooms))
                  ).map((e, i) => (
                    <>
                      <ImagesBlk
                        title={
                          parseInt(watchAllFields?.full_bathrooms) > 1
                            ? "Full Bathrooms " + (parseInt(i) + 1)
                            : "Full Bathroom"
                        }
                        btn_name="Upload"
                        handleRemoveLabelImage={handleRemoveLabelImage}
                        uploadMultipleImages={uploadMultipleImages}
                        images={images}
                        label={"full_bathroom_" + (parseInt(i) + 1)}
                        path={path}
                      />
                      <div className="gap_line_divide"></div>
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
              {parseInt(watchAllFields?.half_bathrooms) > 0 ? (
                <>
                  {Array.from(
                    Array(parseInt(watchAllFields?.half_bathrooms))
                  ).map((e, i) => (
                    <>
                      <ImagesBlk
                        title={
                          parseInt(watchAllFields?.half_bathrooms) > 1
                            ? "Half Bathrooms " + (parseInt(i) + 1)
                            : "Half Bathroom"
                        }
                        btn_name="Upload"
                        handleRemoveLabelImage={handleRemoveLabelImage}
                        uploadMultipleImages={uploadMultipleImages}
                        images={images}
                        label={"half_bathroom_" + (parseInt(i) + 1)}
                        path={path}
                      />
                      <div className="gap_line_divide"></div>
                    </>
                  ))}
                </>
              ) : (
                ""
              )}
              {watchAllFields?.studio === "no" &&
              parseInt(watchAllFields?.bedrooms) > 0 ? (
                <>
                  {Array.from(Array(parseInt(watchAllFields?.bedrooms))).map(
                    (e, i) => (
                      <>
                        <ImagesBlk
                          title={
                            parseInt(watchAllFields?.bedrooms) > 1
                              ? "Bedrooms " + (parseInt(i) + 1)
                              : "Bedroom"
                          }
                          btn_name="Upload"
                          handleRemoveLabelImage={handleRemoveLabelImage}
                          uploadMultipleImages={uploadMultipleImages}
                          images={images}
                          label={"bedroom_" + (parseInt(i) + 1)}
                          path={path}
                        />
                        <div className="gap_line_divide"></div>
                      </>
                    )
                  )}
                </>
              ) : (
                ""
              )}

              {isCheckedFeature(features, watchAllFields?.features, "attic") ===
                true ||
              isCheckedFeature(features, watchAllFields?.features, "attic") ===
                1 ? (
                <>
                  <ImagesBlk
                    title="Attic"
                    btn_name="Upload"
                    handleRemoveLabelImage={handleRemoveLabelImage}
                    uploadMultipleImages={uploadMultipleImages}
                    images={images}
                    label="attic"
                    path={path}
                  />

                  <div className="gap_line_divide"></div>
                </>
              ) : (
                ""
              )}
              {isCheckedFeature(features, watchAllFields?.features, "den") ===
                true ||
              isCheckedFeature(features, watchAllFields?.features, "den") ===
                1 ? (
                <>
                  <ImagesBlk
                    title="Den"
                    btn_name="Upload"
                    handleRemoveLabelImage={handleRemoveLabelImage}
                    uploadMultipleImages={uploadMultipleImages}
                    images={images}
                    label="den"
                    path={path}
                  />

                  <div className="gap_line_divide"></div>
                </>
              ) : (
                ""
              )}
              {isCheckedFeature(
                features,
                watchAllFields?.features,
                "basement"
              ) === true ||
              isCheckedFeature(
                features,
                watchAllFields?.features,
                "basement"
              ) === 1 ? (
                <>
                  <ImagesBlk
                    title="Basement"
                    btn_name="Upload"
                    handleRemoveLabelImage={handleRemoveLabelImage}
                    uploadMultipleImages={uploadMultipleImages}
                    images={images}
                    label="basement"
                    path={path}
                  />

                  <div className="gap_line_divide"></div>
                </>
              ) : (
                ""
              )}
              <ImgGridRepeater
                title="Add Label"
                labels={labels}
                handleLabelChange={handleLabelChange}
                handleRemoveLabelImage={handleRemoveLabelImage}
                handleAddLabel={handleAddLabel}
                images={images}
                uploadMultipleImages={uploadMultipleImages}
                btn_name="Upload"
                path={path}
                handleRemoveLabel={handleRemoveLabel}
              />
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          <button
            type="submit"
            className="webBtn colorBtn"
            disabled={
              isFormProcessing === true ||
              (images !== undefined && images.loading === true)
                ? "disabled"
                : ""
            }
          >
            {isFormProcessing === true ||
            (images !== undefined && images.loading === true) ? (
              <i className="spinner"></i>
            ) : (
              "Finish"
            )}
          </button>
        </div>
      </fieldset>
    </>
  );
}
