import { format_amount, format_date } from "../../../../helpers/api"

export const ServiceTable = ({ service_payment_history }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Date </th>
                    <th>Type</th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    service_payment_history?.length > 0 ?
                        service_payment_history?.map((service_payment_history_row, index) => {
                            return (
                                <tr key={index}>
                                    <td><span className="mini-show-cell">Date</span>{format_date(service_payment_history_row?.created_at)}</td>
                                    <td><span className="mini-show-cell">Type</span>{service_payment_history_row?.description}</td>
                                    <td><span className="mini-show-cell">Amount</span>{format_amount(service_payment_history_row?.amount)}</td>
                                </tr>
                            )
                        })

                        :
                        <tr>
                            <td colSpan={3}>
                                <div className="alert alert-danger">No transactions found.</div>
                            </td>
                        </tr>
                }
            </tbody>
        </table>
    )
}
