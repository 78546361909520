import React from 'react'
import { getServerImage } from '../../../../../helpers/api';

export default function MediaThumbnail({ imageThumbnail, deleteThumbnail, handleUploadThumbnail, path }) {
    let thumbnail = null;
    function uploadThumbnail() {
        thumbnail.click();
    }
    return (
        <>
            <div className="crud_image text-center">
                <h4 className="mrgn_auto">Default Image</h4>
                <div className="default_image_property_pg">
                    <div className="image">
                        <img src={getServerImage(path, imageThumbnail != null ? imageThumbnail : '')} alt="" />
                        {
                            imageThumbnail != null ? <div className="closeBtn" onClick={deleteThumbnail}></div> : ''
                        }
                    </div>
                </div>
                <div className="image_upload_blk uploadImg default_btn_prop" data-upload="gallery_image" onClick={uploadThumbnail}>
                    <div className="small_upload_icon" >
                        <img src="/images/dashboard/icons/upload.png" alt="" />
                    </div>
                    <h3>Upload</h3>
                </div>
            </div>
            <input type="file" name="" id="" className="uploadFile" data-upload="gallery_image"


                onChange={handleUploadThumbnail}
                accept="image/*"
                ref={(file) => (thumbnail = file)} />
            <div className="gap_line_divide"></div>
        </>
    )
}
