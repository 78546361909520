import React, { useState } from "react";
import PropertyBlk from "../../../common/propertyBlk";
import Pagination from '../../../common/pagination';

export default function FavoriteBlk({ items }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    return (
        <>
            <div className="dash_property_listing grid_dash_property_listing">
                {
                    currentItems !== undefined && currentItems !== '' && currentItems !== null && currentItems?.length > 0 ?
                        <>
                            {

                                currentItems?.map((item, index) => {
                                    return (
                                        <PropertyBlk data={item} key={index} from_favorites_page={true} branch_id={item?.branch_id > 0 ? item?.branch_id : false} listing_expired={item?.listing_expired} />
                                    )
                                })
                            }

                        </>
                        :
                        <div className='alert alert-danger'>No favorite(s) added yet!</div>
                }
            </div>
            <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />
        </>
    );
}
