import {
    FETCH_LISTINGS_CONTENT,
    FETCH_LISTINGS_CONTENT_SUCCESS,
    FETCH_LISTINGS_CONTENT_FAILED,
    LISTINGS_DELETE__MESSAGE,
    LISTINGS_DELETE_MESSAGE_SUCCESS,
    LISTINGS_DELETE__MESSAGE_FAILED
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_LISTINGS_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_LISTINGS_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_LISTINGS_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case LISTINGS_DELETE__MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case LISTINGS_DELETE_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case LISTINGS_DELETE__MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
