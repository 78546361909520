import React from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { format_amount, postData } from "../../../../helpers/api";
import PayPal from "../../../common/paypal";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
export default function ExtendListingForm({
  handleSubmit,
  formStep,
  changeStep,
  register,
  watchAllFields,
  hideUpdateListingPopup,
  paymentMethod,
  choosePaymentMethod,
  checkoutError,
  getPaypalObject,
  cancelPaypalOrder,
  errorPaypalOrder,
  isProcessing,
  handleCardDetailsChange,
  errors,
  isValid,
  trigger,
  setProcessingTo,
  setCheckoutError,
  handleExtendListing,
  extendLoading,
  listingPopup,
  calculateListingFee,
  siteSettings,
  calculateListingProcessingFee,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const onSubmit = async (data) => {
    setProcessingTo(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement(CardElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        // billing_details: billingDetails
      });
      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      } else {
        var form_data = new FormData();
        for (var key in watchAllFields) {
          form_data.append(key, watchAllFields[key]);
        }
        form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
        form_data.append("amount", calculateListingFee());
        await postData("api/create-payment-intent", form_data).then((data) => {
          let client_secret = data.arr.client_secret;
          let client_secret_setup = data.arr.setup_client_secret;
          if (data.status === 1) {
            let card_result = stripe.confirmCardSetup(client_secret_setup, {
              payment_method: {
                card: cardElement,
                // billing_details: billingDetails,
              },
            });

            if (card_result.error) {
              setCheckoutError(card_result.error.message);
              setProcessingTo(false);
              return;
            } else {
              let paymentMethod = "";
              let setup_intent_id = "";
              card_result.then((response) => {
                if (response.error) {
                  setCheckoutError(response.error.message);
                  setProcessingTo(false);
                  return;
                } else {
                  paymentMethod = response.setupIntent.payment_method;
                  setup_intent_id = response.setupIntent.id;
                  chargePayment(
                    client_secret,
                    paymentMethodReq,
                    setup_intent_id,
                    paymentMethod,
                    data.arr.customer
                  );
                }
              });
            }
          }
        });
      }
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  const chargePayment = async (
    clientSecret,
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodSetup,
      setup_future_usage: "off_session",
    });
    if (result.error) {
      setCheckoutError(result.error.message);
      setProcessingTo(false);
      return;
    } else if ((result.status = "succeeded")) {
      let data = watchAllFields;
      let newData = {
        ...data,
        payment_intent: result.paymentIntent.id,
        payment_method_id: paymentMethodReq.paymentMethod.id,
        customer_id: customer_id,
        paymentMethod: paymentMethod,
        amount: calculateListingFee(),
      };

      handleExtendListing(newData, listingPopup?.item?.id);
      // stripePayment(newData);
      setProcessingTo(false);
    }
  };

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {formStep === 0 ? (
          <>
            <div className="form_row row justify_center_flex">
              <div className="col-xs-6">
                <h6>Extend by:</h6>
                <div className="form_blk deposit_form_blk">
                  <input
                    type="text"
                    name="expires_in"
                    id="expires_in"
                    className="txtBox"
                    {...register("expires_in", {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Invalid value",
                      },
                      required: "Required",
                      min: {
                        value: 1,
                        message: "Value must be greater than 0.",
                      },
                    })}
                  />
                  <span className="dollar_label">mth</span>
                  <ErrorMessage
                    errors={errors}
                    name="expires_in"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form_row row justify_center_flex">
              <div className="col-xs-6">
                <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
                  <li>
                    <em>Listing Fee</em>
                    <em>
                      {format_amount(
                        parseFloat(siteSettings?.site_listing_fee)
                      )}
                      /Monthly
                    </em>
                  </li>
                  <li>
                    <em>Total Listing Fee</em>
                    <em>
                      {watchAllFields?.expires_in !== undefined &&
                      watchAllFields?.expires_in !== ""
                        ? format_amount(
                            parseInt(watchAllFields?.expires_in) *
                              parseFloat(siteSettings?.site_listing_fee)
                          )
                        : "$0"}
                    </em>
                  </li>
                  <li>
                    <em>Processing Fee</em>
                    <em>{format_amount(calculateListingProcessingFee())}</em>
                  </li>
                  <li>
                    <strong>Total Due</strong>
                    <strong>
                      {watchAllFields?.expires_in !== undefined &&
                      watchAllFields?.expires_in !== ""
                        ? format_amount(
                            parseFloat(
                              parseInt(watchAllFields?.expires_in) *
                                parseFloat(siteSettings?.site_listing_fee)
                            ) + parseFloat(calculateListingProcessingFee())
                          )
                        : format_amount(calculateListingProcessingFee())}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="cmnBtn text-center bTn">
              <button
                type="button"
                className="webBtn backBtn"
                onClick={hideUpdateListingPopup}
              >
                Previous
              </button>
              {!isValid ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => {
                    trigger();
                  }}
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => changeStep(formStep + 1)}
                  disabled={!isValid}
                >
                  Next
                </button>
              )}
            </div>
          </>
        ) : formStep === 1 ? (
          <>
            <div className="form_row row justify_center_flex chk">
              <div className="col-xs-9">
                <div className="creditCard">
                  <div className="flex flex-2 headCredit cardSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card1"
                          tabindex="1"
                          name="card"
                          checked={
                            paymentMethod === "credit-card" ? true : false
                          }
                          onChange={() => choosePaymentMethod("credit-card")}
                        />
                        <label htmlFor="card1">Credit Card</label>
                      </div>
                    </div>
                    <div className="inner">
                      <ul className="text-right">
                        <li>
                          <img src="/images/dashboard/card1.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card2.svg" alt="" />
                        </li>
                        <li>
                          <img src="/images/dashboard/card3.svg" alt="" />
                        </li>
                      </ul>
                    </div>
                  </div>

                  {paymentMethod === "credit-card" ? (
                    <div className="flex flex-2 cardSec">
                      <div className="inner">
                        <CardElement
                          options={{
                            style: {
                              base: {
                                fontSize: "14px",
                                color: "#424770",
                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "#9e2146",
                              },
                            },
                          }}
                          onChange={handleCardDetailsChange}
                        />
                        {checkoutError && (
                          <div className="alert alert-danger">
                            {checkoutError}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="flex flex-2 headCredit paypalSecBar">
                    <div className="inner">
                      <div className="lblBtn text-left">
                        <input
                          type="radio"
                          id="card2"
                          tabindex="1"
                          name="card"
                          onChange={() => choosePaymentMethod("paypal")}
                          checked={paymentMethod === "paypal" ? true : false}
                        />
                        <label htmlFor="card2">Pay Pal</label>
                      </div>
                    </div>
                    <div className="inner other_pay">
                      <img src="/images/dashboard/paypal.svg" alt="" />
                    </div>
                  </div>
                  {paymentMethod === "paypal" ? (
                    <PayPal
                      price={calculateListingFee()}
                      getPaypalObject={getPaypalObject}
                      cancelPaypalOrder={cancelPaypalOrder}
                      errorPaypalOrder={errorPaypalOrder}
                      priceMonth={watchAllFields.expires_in}
                      description="Rent Listing Creation"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="cmnBtn text-center">
              <button
                type="button"
                className="webBtn backBtn"
                onClick={() => changeStep(formStep - 1)}
              >
                Previous
              </button>
              <button
                type="submit"
                className="webBtn colorBtn"
                disabled={isProcessing || extendLoading ? "disabled" : ""}
              >
                Submit
                {isProcessing || extendLoading ? (
                  <i className="spinner"></i>
                ) : (
                  ""
                )}
              </button>
            </div>
          </>
        ) : (
          ""
        )}
      </form>
    </>
  );
}
