import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_EXPENSE_CONTENT,
  FETCH_SINGLE_EXPENSE_CONTENT_SUCCESS,
  FETCH_SINGLE_EXPENSE_CONTENT_FAILED,
  SINGLE_EXPENSE_MESSAGE,
  SINGLE_EXPENSE_MESSAGE_SUCCESS,
  SINGLE_EXPENSE_MESSAGE_FAILED,
  FETCH_EXPENSE_CONTENT,
  FETCH_EXPENSE_CONTENT_FAILED,
  FETCH_EXPENSE_CONTENT_SUCCESS,
  EXPENSE_DELETE__MESSAGE,
  EXPENSE_DELETE_MESSAGE_SUCCESS,
  EXPENSE_DELETE__MESSAGE_FAILED,
  GET_FILTER_EXPENSE,
  GET_FILTER_EXPENSE_FAILED,
  GET_FILTER_EXPENSE_SUCCESS,
} from "./actionTypes";

export const fetchExpenses = () => (dispatch) => {
  dispatch({
    type: FETCH_EXPENSE_CONTENT,
    payload: null,
  });
  http
    .get("expenses")
    .then(({ data }) => {
      dispatch({
        type: FETCH_EXPENSE_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_EXPENSE_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleExpense = (expense_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_EXPENSE_CONTENT,
    payload: null,
  });
  http
    .get("single-expense/" + expense_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_EXPENSE_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_EXPENSE_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postExpense = (formData, expense_id) => (dispatch) => {
  //
  let postUrl = "";
  if (expense_id != undefined && expense_id !== null) {
    postUrl = "edit-expense/" + expense_id;
  } else {
    postUrl = "add-expense";
  }
  dispatch({
    type: SINGLE_EXPENSE_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/expenses");
        }, 2000);
        dispatch({
          type: SINGLE_EXPENSE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_EXPENSE_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_EXPENSE_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteExpense = (formData) => (dispatch) => {
  dispatch({
    type: EXPENSE_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-expense/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/expenses");
        }, 2000);
        dispatch({
          type: EXPENSE_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: EXPENSE_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: EXPENSE_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredExpenses = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_EXPENSE,
    payload: null,
  });
  http
    .post("expenses", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_EXPENSE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_EXPENSE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_EXPENSE_FAILED,
        payload: error,
      });
    });
};
