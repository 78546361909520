import React from "react";
import SearchFormFaq from "./search-form-faq";

export default function FaqBanner({ data }) {
    return (
        <>
            <section className="help_banner" style={{ backgroundImage: "url(" + data.image + ")" }}>
                <div className="contain">
                    <div className="page_heading text-center">
                        <h4>{data.mini_heading}</h4>
                        <h1>{data.heading}</h1>
                    </div>
                    <div className="cntnt support_banner">
                        <SearchFormFaq />
                    </div>
                </div>
            </section>
        </>
    )
}
