import React from "react";
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function Goals({ data }) {
    return (
        <>
           <section className="goals_sec">
                <div className="contain">
                    <OwlCarousel items={3}  className="owl-theme goal_slider"  loop>
                        {data?.map((val, index) => {
                            return(
                            <div className="item" key={index}>
                                <div className="inner">
                                    <h4>{val.heading}</h4>
                                    <p>{val.text}</p>
                                </div>
                            </div>
                            )
                        })}
                        
                    </OwlCarousel>  
                </div>
            </section>
        </>
    )
}
