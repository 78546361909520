import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import OTPInput, { ResendOTP } from "otp-input-react";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { fetchSignup, postVerifyOtp } from "../../../states/actions/fetchSignup";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import ResendCode from "../dashboard-company/sections/resend-code";
import InputMask from 'react-input-mask';
import { postData } from "../../../helpers/api";
import { ErrorMessage } from '@hookform/error-message';
import Timer from "../../common/timer";
import { NavLink } from 'react-router-dom';

export default function MemberVerification() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const [phoneVerify, setPhoneVerify] = useState(false);
    const [resendOTPExpire, setOtpExpire] = useState(null);
    const dispatch = useDispatch();
    const isFormProcessing = useSelector(
        (state) => state.fetchSignup.isFormProcessing
    );
    const emptyFormData = useSelector(
        (state) => state.fetchSignup.emptyFormData
    );
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    let isLoading = useSelector((state) => state.fetchSignup.isLoading);
    const [OTP, setOTP] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(false);
    const handleOTPChange = (code) => setOTP(code);
    const handleShowPhoneNumber = () => setPhoneNumber(true);
    const handleHidePhoneNumber = () => setPhoneNumber(false);
    const handleVerifySubmit = (e) => {
        e.preventDefault();
        if (OTP === '') {
            toast.error('OTP is required',
                TOAST_SETTINGS
            );
        }
        else {
            dispatch(postVerifyOtp(OTP));
        }
    };
    //resend otp code
    async function resendOtpCode(e) {
        setPhoneVerify(true);
        var form_data = new FormData();
        form_data.append('phone', true);

        await postData("api/resend-otp-code", form_data).then((data) => {
            setPhoneVerify(false);
            if (data.status === 1) {
                setOtpExpire(data.expired_date);

                toast.success(data.msg,
                    TOAST_SETTINGS
                );
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    //change phone number
    const onSubmit = formValues => {
        setPhoneVerify(true);
        var form_data = new FormData();
        for (var key in formValues) {
            form_data.append(key, formValues[key]);
        }

        postData("api/resend-phone-otp", form_data).then((data) => {

            if (data.status === 1) {
                isLoading = false;
                setPhoneVerify(false);
                setOtpExpire(data.expired_date);
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                setPhoneNumber(false)
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
            }
        });
    }
    useEffect(() => {
        dispatch(fetchSignup());
    }, []);
    const member = siteSettings?.member;
    useEffect(() => {
    }, [resendOTPExpire]);
    useDocumentTitle('Member Verification - Loftus');

    if (member?.mem_verified === 1 || member?.mem_phone_verified === 1) {
        return window.location.replace("/dashboard");
    }
    if (isLoading) return <LoadingScreen />;
    return (
        <>
            <section className="popup small_popup verify_phone_popup memberVerify" data-popup="verify-number">
                <div className="tableDv">
                    <div className="tableCell">
                        <ToastContainer />
                        <div className="_inner">
                            {
                                phoneNumber === false ?
                                    <>
                                        <form action="" method="POST" onSubmit={handleVerifySubmit}>
                                            <div className="txtGrp text-center">
                                                <h4>A verification has been sent to your phone number</h4>

                                            </div>
                                            <div className="txtGrp">
                                                <h6>Please enter the code below to verify your phone number</h6>

                                                <div className="otp_fields">
                                                    <OTPInput value={OTP} onChange={handleOTPChange} autoFocus OTPLength={6} otpType="number" disabled={false} isInputNum />
                                                </div>
                                            </div>
                                            <div className="txtGrp">
                                                {
                                                    resendOTPExpire !== null ?

                                                        <Timer deadline={resendOTPExpire} />
                                                        :
                                                        <Timer deadline={member?.otp_expire} />
                                                }
                                            </div>
                                            <div className="text-center">
                                                <button type="submit" className="webBtn colorBtn" disabled={(isFormProcessing === true) ? 'disabled' : ''}>{(isFormProcessing === true) ? <i className="spinner"></i> : 'Verify'}</button>
                                            </div>
                                        </form>

                                        <ResendCode handleShowPhoneNumber={handleShowPhoneNumber} resendOtpCode={resendOtpCode} phoneVerify={phoneVerify} />
                                        <div className="bTn text-center">
                                            <NavLink to="#!" className="webBtn blankBtn" onClick={handleShowPhoneNumber}>Change Phone Number</NavLink>
                                        </div>
                                    </>
                                    :
                                    <form action="" method="POST" onSubmit={handleSubmit(onSubmit)} autoComplete="none">
                                        <div className="txtGrp text-center">
                                            <h4>Change Phone Number</h4>
                                        </div>
                                        <div className="txtGrp">
                                            <h6>Please enter your new phone number. A one-time password will be sent for verification.</h6>
                                            <InputMask mask="+1 (999) 999-9999" name="phone" id="phone" className='txtBox' autoComplete="none"  {...register("phone", {
                                                required: "Required"
                                            })} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="phone"
                                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                            />
                                        </div>
                                        <div className="text-center bTn">
                                            <button type="button" className="webBtn blankBtn colorBtn" onClick={handleHidePhoneNumber}>Back</button>
                                            <button type="submit" className="webBtn colorBtn" disabled={(phoneVerify === true) ? 'disabled' : ''}>{(phoneVerify === true) ? <i className="spinner"></i> : 'Change'}</button>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
