import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ErrorMessage } from '@hookform/error-message';
import { toSluUrl } from '../../../../helpers/api';
let property_options = [
    {
        label: "Single Family",
        value: "Single Family"
    },
    {
        label: "Townhouse",
        value: "Townhouse"
    },
    {
        label: "Condo",
        value: "Condo"
    },
    {
        label: "Apartment",
        value: "Apartment"
    },
    {
        label: "Duplex",
        value: "Duplex"
    },
    {
        label: "Other",
        value: "Other"
    },
];
export default function CommercialFilterPopup({ trigger, register, errors, control, isValid, features, amenities, closePopup, saveSearch, closeFilter, watchAllFields, setValue }) {
    const handleDateChange = (date) => {
        setValue('available_date', date, { shouldValidate: true }); // Set the value of the 'fieldName' field
    };
    return (
        <>
            <section className="popup filter_popup filter_new_design" data-popup="filter">
                <div className="tableDv">
                    <div className="tableCell">
                        <div className="_inner">
                            <div className="crosBtn" onClick={closePopup}></div>
                            <div className="filter_inner">
                                <h2>Filters</h2>
                                <form method="post">
                                    {/* <div className="show_mobile_filter_explore">
                                        <div className="cmn_lnk_filter flex filt_divs">
                                            <div className="check_cmn_filter_btn">
                                                <div className="lblBtn">
                                                    <input type="radio" name="status" value="buy" />
                                                    <label for="">Buy</label>
                                                </div>
                                                <div className="lblBtn active">
                                                    <input type="radio" name="status" value="rent" checked />
                                                    <label for="">Rent</label>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Price</h3>
                                            <p>The average nightly price is $346</p>
                                            <input type="text" id="price" name="" value="" className="_price" />
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Bedrooms</h3>
                                            <div className="qtyBtn">
                                                <input type="button" value="-" className="qtyminus readBtn" />
                                                <input type="text" name="quantity" value="1" className="qty" />
                                                <input type="button" value="+" className="qtyplus readBtn" />
                                            </div>
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Bathrooms</h3>
                                            <div className="qtyBtn">
                                                <input type="button" value="-" className="qtyminus readBtn" />
                                                <input type="text" name="quantity" value="1" className="qty" />
                                                <input type="button" value="+" className="qtyplus readBtn" />
                                            </div>
                                        </div>

                                    </div> */}

                                    <div className="filt_divs">
                                        <h3>Listing Information</h3>
                                        <div className="row formRow">
                                            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="" value="" className="txtBox" placeholder="Year Built" {...register("year", {

                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Invalid value',
                                                        },
                                                        min: {
                                                            value: 1500,
                                                            message: "Year must be greater than 1499."
                                                        }


                                                    })} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="year"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}

                                                    />
                                                </div>
                                            </div> */}
                                            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp selectTxtGrp">
                                                    <Controller
                                                        {...register("property_options")}
                                                        name="property_options"
                                                        control={control}

                                                        render={({ field }) => (

                                                            <>
                                                                <Select options={property_options} {...field} label="Select Property Type" />
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            </div> */}
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <DatePicker
                                                        className="txtBox"
                                                        name="available_date"
                                                        selected={watchAllFields?.available_date} placeholderText="Move-in Date"
                                                        {...register('available_date')}

                                                        minDate={new Date()}
                                                        onChange={handleDateChange}
                                                        tabindex="2"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="sq_ft" defaultValue={watchAllFields?.sq_ft} className="txtBox" placeholder="Square Feet" {...register("sq_ft", {

                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Invalid value',
                                                        },
                                                        min: {
                                                            value: 1,
                                                            message: "Value must be greater than 0."
                                                        }


                                                    })} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="sq_ft"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="" value="" className="txtBox" placeholder="Lot Size" {...register("lot_size", {

                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Invalid value',
                                                        },
                                                        min: {
                                                            value: 1,
                                                            message: "Value must be greater than 0."
                                                        }


                                                    })} />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="lot_size"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                    />
                                                </div>
                                            </div> */}


                                        </div>
                                    </div>
                                    <div className="filt_divs">
                                        <h3>Laundry</h3>
                                        <div className="flex filter_property_type">
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" value="In Unit" id="_unit" {...register("laundry")} defaultChecked={watchAllFields?.laundry?.includes('In Unit')} />
                                                <label for="_unit">In Unit</label>
                                            </div>
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" value="Laundry Facility" id="_laundry" {...register("laundry")} defaultChecked={watchAllFields?.laundry?.includes('Laundry')} />
                                                <label for="_laundry">Laundry Facility</label>
                                            </div>
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" value="None" id="none" {...register("laundry")} defaultChecked={watchAllFields?.laundry?.includes('None')} />
                                                <label for="none">None</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="filt_divs">
                                        <h3>Amenities</h3>
                                        <div className="flex filter_property_type suitability_flex">

                                            {
                                                amenities && amenities?.length > 0 ?
                                                    amenities?.map((item, index) => {
                                                        return (

                                                            <div className="lblBtn" key={index}>
                                                                <div className="switchBtn">
                                                                    <input type="checkbox" name="amenities" defaultValue={item.id} id={toSluUrl(item.title)}  {...register("amenities")} />
                                                                </div>
                                                                <label htmlFor={toSluUrl(item.title)}>{item.title}</label>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div> */}
                                    <div className="filt_divs">
                                        <h3>Features</h3>
                                        <div className="flex filter_property_type suitability_flex">
                                            {
                                                features && features?.length > 0 ?
                                                    features?.map((item, index) => {
                                                        return (

                                                            <div className="lblBtn" key={index}>
                                                                <div className="switchBtn">
                                                                    <input type="checkbox" name="features" defaultValue={item.id} id={toSluUrl(item.title)}  {...register("features")} />
                                                                </div>
                                                                <label htmlFor={toSluUrl(item.title)}>{item.title}</label>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>

                                    <div className="filt_divs">
                                        <div className="filter_opt flex">
                                            <button type="submit" className="save_opt" onClick={() => saveSearch('popup')}>Save</button>
                                            <button type="button" className="clear_opt" onClick={() => closeFilter('popup', true)}>Clear All</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
