import React from "react";
import { NavLink } from "react-router-dom";

export default function MaintenanceLoadProfilePopup({ closePopup, popup }) {
  return popup ? (
    <>
      <section
        className="popup small_popup"
        data-popup="address-not-match"
        style={{ display: "block" }}
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner text-center">
              <div className="crosBtn" onClick={closePopup}></div>
              <h4>Do you want to create your service profile?</h4>
              <div className="btn_blk text-center gap-10 flex justify-center">
                <NavLink className="webBtn" to="/dashboard">
                  No
                </NavLink>
                <button
                  className="webBtn colorBtn"
                  onClick={closePopup}
                  type="button"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    ""
  );
}
