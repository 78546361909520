import {
  FETCH_SINGLE_MAINTENANCE_CONTENT,
  FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS,
  FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED,
  SINGLE_MAINTENANCE_MESSAGE,
  SINGLE_MAINTENANCE_MESSAGE_SUCCESS,
  SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP,
  SINGLE_MAINTENANCE_MESSAGE_FAILED,
  FETCH_MAINTENANCE_CONTENT,
  FETCH_MAINTENANCE_CONTENT_FAILED,
  FETCH_MAINTENANCE_CONTENT_SUCCESS,
  MAINTENANCE_DELETE__MESSAGE,
  MAINTENANCE_DELETE_MESSAGE_SUCCESS,
  MAINTENANCE_DELETE__MESSAGE_FAILED,
  GET_FILTER_MAINTENANCE,
  GET_FILTER_MAINTENANCE_FAILED,
  GET_FILTER_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE_STAFF_DETAILS,
  GET_MAINTENANCE_STAFF_DETAILS_FAILED,
  GET_MAINTENANCE_STAFF_DETAILS_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  floor_plans: [],
  isFilterLoading: false,
  closeEditPopup: { status: 0 },
  isStaffDetailsLoading: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTER_MAINTENANCE:
      return {
        ...state,
        isFilterLoading: true,
      };
    case GET_FILTER_MAINTENANCE_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload,
      };

    case GET_FILTER_MAINTENANCE_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload,
      };
    case GET_MAINTENANCE_STAFF_DETAILS:
      return {
        ...state,
        isStaffDetailsLoading: true,
      };
    case GET_MAINTENANCE_STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        isStaffDetailsLoading: false,
        content: payload,
      };

    case GET_MAINTENANCE_STAFF_DETAILS_FAILED:
      return {
        ...state,
        isStaffDetailsLoading: false,
        error: payload,
      };
    case FETCH_MAINTENANCE_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_MAINTENANCE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_MAINTENANCE_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SINGLE_MAINTENANCE_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SINGLE_MAINTENANCE_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case SINGLE_MAINTENANCE_MESSAGE_SUCCESS:
      if (payload?.status) {
        return {
          ...state,
          isFormProcessing: false,
          emptyFormData: true,
          closeEditPopup: {
            status: 1,
            properties: payload?.properties,
            maintenance_staff: payload?.maintenance_staff,
          },
        };
      } else {
        return {
          ...state,
          isFormProcessing: false,
          closeEditPopup: true,
        };
      }

    case SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP:
      return {
        ...state,
        closeEditPopup: { status: 0 },
      };
    case SINGLE_MAINTENANCE_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case MAINTENANCE_DELETE__MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case MAINTENANCE_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case MAINTENANCE_DELETE__MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    default:
      return state;
  }
}
