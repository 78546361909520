import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_EMPLOYEE_CONTENT,
  FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS,
  FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED,
  SINGLE_EMPLOYEE_MESSAGE,
  SINGLE_EMPLOYEE_MESSAGE_SUCCESS,
  SINGLE_EMPLOYEE_MESSAGE_FAILED,
  FETCH_EMPLOYEES_CONTENT,
  FETCH_EMPLOYEES_CONTENT_FAILED,
  FETCH_EMPLOYEES_CONTENT_SUCCESS,
  EMPLOYEE_DELETE__MESSAGE,
  EMPLOYEE_DELETE_MESSAGE_SUCCESS,
  EMPLOYEE_DELETE__MESSAGE_FAILED,
  GET_FILTER_EMPLOYEE,
  GET_FILTER_EMPLOYEE_FAILED,
  GET_FILTER_EMPLOYEE_SUCCESS,
} from "./actionTypes";

export const fetchEmployees = () => (dispatch) => {
  dispatch({
    type: FETCH_EMPLOYEES_CONTENT,
    payload: null,
  });
  http
    .get("employees")
    .then(({ data }) => {
      dispatch({
        type: FETCH_EMPLOYEES_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_EMPLOYEES_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleEmployee = (employee_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_EMPLOYEE_CONTENT,
    payload: null,
  });
  http
    .get("single-employee/" + employee_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postEmployee = (formData, employee_id) => (dispatch) => {
  //
  let postUrl = "";
  if (employee_id != undefined && employee_id !== null) {
    postUrl = "edit-employee/" + employee_id;
  } else {
    postUrl = "add-employee";
  }
  dispatch({
    type: SINGLE_EMPLOYEE_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/employee");
        }, 2000);
        dispatch({
          type: SINGLE_EMPLOYEE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_EMPLOYEE_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_EMPLOYEE_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteEmployee = (formData) => (dispatch) => {
  dispatch({
    type: EMPLOYEE_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-employee/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/employee");
        }, 2000);
        dispatch({
          type: EMPLOYEE_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: EMPLOYEE_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: EMPLOYEE_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredEmployees = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_EMPLOYEE,
    payload: null,
  });
  http
    .post("employees", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_EMPLOYEE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_EMPLOYEE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_EMPLOYEE_FAILED,
        payload: error,
      });
    });
};
