import React, { useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import MaintenanceRequestForm from "./maintenance-requests/form";
import { useParams } from "react-router-dom";

import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchSingleMaintenanceRequest,
  postMaintenanceRequest,
} from "../../../states/actions/fetchMaintenanceRequests";
import { consoleLog, sortArrayByKey } from "../../../helpers/api";
import { ToastContainer } from "react-toastify";
import Error from "../Error";

export default function AddMaintenanceRequest() {
  const { request_id, req_type } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchMaintenanceRequests.content);
  const isLoading = useSelector(
    (state) => state.fetchMaintenanceRequests.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceRequests.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleMaintenanceRequest(request_id, "no_type", req_type));
  }, []);
  const handleSaveRequest = (frmData) => {
    frmData = {
      ...frmData,
      property_id: frmData?.property_id?.value,
      req_type: req_type,
    };
    dispatch(postMaintenanceRequest(frmData, request_id));
  };
  useDocumentTitle(
    request_id != undefined && request_id != null && request_id !== ""
      ? "Edit Service Request - Loftus"
      : "Add Service Request - Loftus"
  );
  if (isLoading) return <LoadingScreen />;

  const properties = data?.properties;
  const maintenance_row = data?.maintenance_row;
  if (parseInt(data?.not_found) === 1) return <Error />;
  if (maintenance_row?.id > 0 && parseInt(maintenance_row?.is_tenant) !== 1)
    return <Error />;
  return (
    <>
      <ToastContainer />
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={
                request_id != undefined && request_id != null
                  ? "Edit Service Request "
                  : "Add Service Request"
              }
              parent_name="Service Requests"
              parent_link="/service-requests"
            />
            <div className="page_body">
              <div className="maintenance_form dash_form_blk">
                <MaintenanceRequestForm
                  properties={sortArrayByKey(properties)}
                  handleSaveRequest={handleSaveRequest}
                  isFormProcessing={isFormProcessing}
                  maintenance_row={maintenance_row}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
