import React, { useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import {
  fetchFloorPlans,
  deleteFloorPlan,
} from "../../../states/actions/fetchFloorPlans";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import FloorBlk from "./sections/floorBlk";
import LoadingScreen from "../../common/LoadingScreen";
import { getApiData } from "../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function FloorPlans() {
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };

  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchFloorPlans.content);
  const isLoading = useSelector((state) => state.fetchFloorPlans.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteFloorPlan(data));
  };

  useEffect(() => {
    dispatch(fetchFloorPlans());
    dispatch(fetchSiteSettings());
  }, []);
  const [floorPlansArray, setFloorPlansArray] = useState({
    arr: [],
    loading: true,
  });
  async function handleBranchChange(e) {
    let branch_id = e.target.value;
    await getApiData("api/get-branch-floor-plans", branch_id).then((data) => {
      setFloorPlansArray({
        ...floorPlansArray,
        arr: data?.floor_plans,
        loading: false,
      });
    });
    // setProperty(properties.properties);
  }
  useEffect(() => {
    if (data?.floor_plans !== undefined && data.floor_plans !== null) {
      setFloorPlansArray({
        ...floorPlansArray,
        arr: data?.floor_plans,
        loading: false,
      });
    }
  }, [data]);
  useDocumentTitle("Floor Plans - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const floor_plans = floorPlansArray?.arr;
  const branches = data.branches;
  //
  return (
    <>
      <div
        index
        className={
          popup.show === true &&
          popup.item != undefined &&
          popup.item != null &&
          popup.item != ""
            ? "main_dashboard commercial_dashboard_new floor_popup"
            : "main_dashboard commercial_dashboard_new"
        }
      >
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="floor-plans" />
              </div>
              <div className="colR">
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <Breadcrumb
                    page_name="Floor Plans"
                    add_link="/add-floor-plan"
                  />
                ) : (
                  <Breadcrumb page_name="Floor Plans" />
                )}

                <FloorBlk
                  floor_plans={floor_plans}
                  handleDelete={handleDelete}
                  popup={popup}
                  showPopup={showPopup}
                  closePopup={closePopup}
                  branches={branches}
                  handleBranchChange={handleBranchChange}
                  floorPlansArray={floorPlansArray}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
