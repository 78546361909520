import React from "react";
import PropertyInfo from "../property-info";
import { NavLink } from "react-router-dom";
import SaleStep1 from "./sale-step1";
import SaleStep2 from "./sale-step2";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
export default function SaleSendOffer({
  property_row,
  user,
  handleSubmit,
  offer_exist,
  step,
  register,
  errors,
  isValid,
  control,
  watchAllFields,
  trigger,
  changeStep,
  setSaleOfferCoverLetter,
  saleOfferCoverLetter,
  handleSaleSubmit,
  isFormProcessing,
  offer_row,
}) {
  const onSubmit = async (data) => {
    // if (saleOfferCoverLetter?.file !== null && saleOfferCoverLetter?.file_name !== null) {
    let newData = {
      ...data,
      offer_letter_file: saleOfferCoverLetter?.file,
      offer_letter_file_name: saleOfferCoverLetter?.file_name,
    };
    // ; return;
    handleSaleSubmit(newData);
    // }
    // else {
    //     toast.error('Please upload an offer letter first!',
    //         TOAST_SETTINGS
    //     );
    // }
  };
  return (
    <>
      <div className="flex divide_offer_flex send_offer_new_blk sale_form_offer">
        <PropertyInfo property_row={property_row} user={user} />
        <div className="inner_colR">
          <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            {offer_exist === true || offer_exist === 1 ? (
              <>
                <div className="alert alert-success">
                  You have already created an offer for this property!
                </div>
                <div className="bTn text-center">
                  <NavLink to="/offers" className="webBtn colorBtn">
                    Go to Offers Page
                  </NavLink>
                </div>
              </>
            ) : (
              <div className="cmn_bt_mrgn multip_step_form_offer">
                <div className="option_lbl small_opt_lbl justify_center_lbl_cell ">
                  <ul>
                    <li className={step === 0 ? "active" : ""}>Offering</li>
                    <li className={step === 1 ? "active" : ""}>Pre-Approval</li>
                  </ul>
                </div>
                {step === 0 ? (
                  <SaleStep1
                    register={register}
                    errors={errors}
                    isValid={isValid}
                    watchAllFields={watchAllFields}
                    control={control}
                    trigger={trigger}
                    changeStep={changeStep}
                    step={step}
                    offer_row={offer_row}
                  />
                ) : step === 1 ? (
                  <SaleStep2
                    register={register}
                    errors={errors}
                    isValid={isValid}
                    watchAllFields={watchAllFields}
                    control={control}
                    trigger={trigger}
                    changeStep={changeStep}
                    step={step}
                    setSaleOfferCoverLetter={setSaleOfferCoverLetter}
                    saleOfferCoverLetter={saleOfferCoverLetter}
                    isFormProcessing={isFormProcessing}
                    offer_row={offer_row}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
