import React from "react";

export default function SendOfferStep6({ step, changeStep, manualVerify }) {
  return (
    <>
      <fieldset>
        <div className="medium_blk">
          {/* <h4 className="ofer_heading">Status</h4> */}
          <div className="medium_blk text-center">
            {manualVerify !== false &&
            manualVerify?.request_status === "ManualVerificationRequired" ? (
              <div className="alert alert-danger">
                {manualVerify?.manual_msg}
              </div>
            ) : (
              // <p>With over 400,000 active listings, FindHouses has the largest inventory of apartments in the United States. Create leases, track payments, and handle Service Requests for your rental properties. Create leases, track payments, and handle Service Requests for your rental properties.</p>
              <p>
                Your identify has been verified! Please proceed to the following
                step.
              </p>
            )}
          </div>
          <div className="cmnBtn text-center offering_send_btns">
            <button
              type="button"
              className="webBtn backBtn"
              onClick={() => changeStep(step - 1)}
            >
              Previous
            </button>
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </fieldset>
    </>
  );
}
