import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getData, getSiteImages } from "../../../helpers/api";
export default function Verification() {
  const { token } = useParams();
  async function verifyEmail() {
    var form_data = new FormData();
    form_data.append("email", true);
    await getData("api/verification", token).then((data) => {
      if (data.status === 1) {
        window.location.replace("/dashboard");
      } else {
        window.location.replace("/dashboard");
      }
    });
  }
  useEffect(() => {
    verifyEmail();
  }, []);
  return (
    <>
      <div className="loading-wait">
        <img
          src={getSiteImages("images/loading-wait.gif")}
          alt="Loading...Please wait!!"
        />
      </div>
    </>
  );
}
