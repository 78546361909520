import React from "react";
import { NavLink } from "react-router-dom";
import { postChatMsg } from "../../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../../utils/siteSettings";
export default function OfferBlk({ item, mem_type = null }) {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.sendChat.isFormProcessing
  );

  const handleStartChat = (tenant_id) => {
    if (
      tenant_id !== undefined &&
      tenant_id !== null &&
      tenant_id !== "" &&
      tenant_id > 0
    ) {
      let frmData = { msg: "", property_mem_id: tenant_id };
      dispatch(postChatMsg(frmData, true));
    } else {
      toast.error(
        "Invalid User or user does not exit in our system!",
        TOAST_SETTINGS
      );
      return;
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="lst relative" data-offer="received-offer">
        <NavLink to={item?.link}></NavLink>
        <ul>
          <li>
            <div className="icoBlk">
              <div className="icon border_solid wide_mobile_img">
                <img src={item?.property_image} alt={item?.property_title} />

                {item?.type === "rent" ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item?.offer_card_status,
                    }}
                    className="cell_lbl"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="txt">
                <h5>{item?.property_title} </h5>
                <p>
                  <small
                    dangerouslySetInnerHTML={{ __html: item?.property_address }}
                  />
                </p>
                {mem_type !== "company" ? (
                  item?.type === "sale" ? (
                    <span className="badge for_sale badge_prp_label">sale</span>
                  ) : (
                    <span className="badge for_rent badge_prp_label">rent</span>
                  )
                ) : (
                  ""
                )}
                {item?.offer_details_status !== "declined" ? (
                  item?.type === "rent" ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item?.offer_card_status,
                      }}
                      className="hide_cell_hyp"
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                <div className="hide_desktop_col">
                  <div className="sep_hypn">
                    <span>
                      <em>
                        {item?.type === "sale"
                          ? "Response Deadline"
                          : "Move-in"}
                      </em>{" "}
                      {item?.move_in_date !== "TBD"
                        ? item?.move_in_date
                        : "N/A"}
                    </span>
                  </div>
                  <div className="sep_hypn">
                    <span>{item?.offering}</span>
                    <span>
                      {item?.term} <em>Months lease</em>
                    </span>
                  </div>
                  <ul className="tenants_dps_flex">
                    {item?.tenants?.length > 0
                      ? item?.tenants?.map((tenant, index) => {
                          return (
                            <li>
                              <div className="ico">
                                <img src={tenant?.thumb} alt={tenant?.name} />
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li className="property_title hide_cell_hyp">
            <h5 className="lease_heading_mini">Tenants</h5>
            <ul className="tenants_dps_flex">
              {item?.tenants?.length > 0
                ? item?.tenants?.map((tenant, index) => {
                    return (
                      <li>
                        <div className="inner">
                          <div className="mini_icon">
                            <img src={tenant?.thumb} alt={tenant?.name} />
                          </div>
                          <div className="bio_info">
                            <div className="name">
                              <h4>{tenant?.name}</h4>
                            </div>
                            {tenant?.current_tenant !== 1 ? (
                              <a
                                href="#!"
                                onClick={() => handleStartChat(tenant?.mem_id)}
                                disabled={isFormProcessing}
                                className="start_chat"
                              >
                                Start Chat
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="ico">
                          <img src={tenant?.thumb} alt={tenant?.name} />
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Offering</h5>
            <div className="price">{item?.offering}</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">
              {item?.type === "sale" ? "Down Payment" : "Term"}
            </h5>
            <div className="price">{item?.term}</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">
              {item?.type === "sale" ? "Response Deadline" : "Move-in Date"}
            </h5>
            <div className="date">
              {item?.move_in_date !== "TBD" ? item?.move_in_date : "N/A"}
            </div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Status</h5>
            <span className="badge green hide_cell_hyp">New</span>
          </li>
          <li className="bTn hide_cell_hyp">
            <NavLink to={item?.link} className="webBtn labelBtn blue-color">
              View
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}
