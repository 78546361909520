import React from "react";
import {
  format_amount,
  format_date,
  isSameMonth,
} from "../../../../helpers/api";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function LeaseAdditionalCosts({
  one_time_costs,
  recurring_costs,
  termination_costs = [],
  lease_row,
  setLeaseTerminate,
  setLeaseTerminateCancel,
  termination_date = null,
  type = null,
  mem_type = null,
  parking_costs = [],
  promo_code = false,
}) {
  return (
    <>
      <div className="gap_line_divide"></div>
      <div className="flex leases-flex-pay">
        {recurring_costs?.length > 0 ? (
          <div className="left_col">
            <div className="inner_inner">
              <h4 className="ofer_heading">Recurring Costs</h4>
              <ul className="total_count_price_listing full_wide_price_listing mini-release_width">
                {recurring_costs?.map((recurring_cost, index) => {
                  return (
                    <li key={index}>
                      <strong>{recurring_cost?.name}</strong>
                      {promo_code === true &&
                      (recurring_cost?.prevtCost ||
                        recurring_cost?.prev_cost) ? (
                        <em>
                          {recurring_cost?.prev_cost > 0 ? (
                            <>
                              {" "}
                              <del>
                                {format_amount(recurring_cost?.prev_cost)}
                              </del>
                              {format_amount(recurring_cost?.cost)}
                            </>
                          ) : (
                            <>
                              {" "}
                              <del>{format_amount(recurring_cost?.cost)}</del>
                              {format_amount(recurring_cost?.prevtCost)}
                            </>
                          )}
                        </em>
                      ) : (
                        <em>{format_amount(recurring_cost?.cost)}</em>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        {one_time_costs?.length > 0 ? (
          <div className="right_col">
            <div className="inner_inner">
              <h4 className="ofer_heading">One-Time Costs</h4>
              <ul className="total_count_price_listing full_wide_price_listing mini-release_width">
                {one_time_costs?.map((one_time_cost, index) => {
                  return (
                    <li key={index}>
                      <strong>{one_time_cost?.name}</strong>
                      {promo_code === true &&
                      (one_time_cost?.prevtCost || one_time_cost?.prev_cost) ? (
                        <em>
                          {one_time_cost?.prev_cost > 0 ? (
                            <>
                              <del>
                                {format_amount(one_time_cost?.prev_cost)}
                              </del>{" "}
                              {format_amount(one_time_cost?.cost)}
                            </>
                          ) : (
                            <>
                              <del>{format_amount(one_time_cost?.cost)}</del>{" "}
                              {format_amount(one_time_cost?.prevtCost)}
                            </>
                          )}
                        </em>
                      ) : (
                        <em>{format_amount(one_time_cost?.cost)}</em>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        {
          // mem_type === 'company' ?
          parking_costs?.length > 0 ? (
            <div className="right_col">
              <div className="inner_inner">
                <h4 className="ofer_heading">Parking Costs</h4>
                <ul className="total_count_price_listing full_wide_price_listing mini-release_width">
                  {parking_costs?.map((parking_cost, index) => {
                    return (
                      <li key={index}>
                        <strong>{parking_cost?.name}</strong>
                        {promo_code === true &&
                        (parking_cost?.prevtCost || parking_cost?.prev_cost) ? (
                          <em>
                            {parking_cost?.prev_cost > 0 ? (
                              <>
                                <del>
                                  {format_amount(parking_cost?.prev_cost)}
                                </del>
                                {format_amount(parking_cost?.cost)}
                              </>
                            ) : (
                              <>
                                {" "}
                                <del>{format_amount(parking_cost?.cost)}</del>
                                {format_amount(parking_cost?.prevtCost)}
                              </>
                            )}
                          </em>
                        ) : (
                          <em>{format_amount(parking_cost?.cost)}</em>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )
          // :
          // ""
        }
      </div>
      {(termination_date !== null &&
        termination_date !== undefined &&
        termination_date !== "") ||
      (lease_row?.termination_date !== null &&
        lease_row?.termination_date !== undefined &&
        lease_row?.termination_date !== "") ? (
        <>
          <div className="gap_line_divide"></div>
          <div className="flex leases-flex-pay termination_costs_flex">
            <div className="left_col">
              <div className="inner_inner">
                <div className="flex">
                  <h4 className="ofer_heading">
                    Terminate on{" "}
                    {termination_date !== null && termination_date !== undefined
                      ? format_date(termination_date)
                      : format_date(lease_row?.termination_date)}
                  </h4>
                  {(mem_type==='company' && (mem_permissions === "admin" ||
                  mem_permissions === "power")) || (mem_type==='member') ? (
                    parseInt(lease_row?.allow_terminate_edit_cancel) === 1 ? (
                      lease_row?.termination_date !== null &&
                      lease_row?.termination_date !== undefined &&
                      lease_row?.termination_date !== "null" &&
                      lease_row?.termination_date !== "" ? (
                        <div className="bTn">
                          <NavLink
                            to="#!"
                            className="webBtn smBtn"
                            onClick={() =>
                              setLeaseTerminate({ show: true, item: lease_row })
                            }
                          >
                            <i className="fa fa-pencil"></i>
                          </NavLink>
                          <NavLink
                            to="#!"
                            className="webBtn colorBtn smBtn"
                            onClick={() =>
                              setLeaseTerminateCancel({
                                show: true,
                                item: lease_row,
                              })
                            }
                          >
                            <i className="fa fa-times"></i>
                          </NavLink>
                        </div>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
                {termination_costs?.length > 0 ? (
                  <ul className="total_count_price_listing full_wide_price_listing flex">
                    {termination_costs?.map((termination_cost, index) => {
                      return (
                        <li key={index}>
                          <div className="inner">
                            <strong>{termination_cost?.name}</strong>
                            <em>{format_amount(termination_cost?.cost)}</em>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
