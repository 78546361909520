import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_CREDIT_CONTENT,
  FETCH_SINGLE_CREDIT_CONTENT_SUCCESS,
  FETCH_SINGLE_CREDIT_CONTENT_FAILED,
  SINGLE_CREDIT_MESSAGE,
  SINGLE_CREDIT_MESSAGE_SUCCESS,
  SINGLE_CREDIT_MESSAGE_FAILED,
  FETCH_CREDIT_CONTENT,
  FETCH_CREDIT_CONTENT_FAILED,
  FETCH_CREDIT_CONTENT_SUCCESS,
  CREDIT_DELETE__MESSAGE,
  CREDIT_DELETE_MESSAGE_SUCCESS,
  CREDIT_DELETE__MESSAGE_FAILED,
  GET_FILTER_CREDIT,
  GET_FILTER_CREDIT_FAILED,
  GET_FILTER_CREDIT_SUCCESS,
} from "./actionTypes";

export const fetchCredits = () => (dispatch) => {
  dispatch({
    type: FETCH_CREDIT_CONTENT,
    payload: null,
  });
  http
    .get("credits")
    .then(({ data }) => {
      dispatch({
        type: FETCH_CREDIT_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_CREDIT_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleCredit = (credit_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_CREDIT_CONTENT,
    payload: null,
  });
  http
    .get("single-credit/" + credit_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_CREDIT_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_CREDIT_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postCredit = (formData, credit_id) => (dispatch) => {
  //
  let postUrl = "";
  if (credit_id != undefined && credit_id !== null) {
    postUrl = "edit-credit/" + credit_id;
  } else {
    postUrl = "add-credit";
  }
  dispatch({
    type: SINGLE_CREDIT_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/credits");
        }, 2000);
        dispatch({
          type: SINGLE_CREDIT_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_CREDIT_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_CREDIT_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteCredit = (formData) => (dispatch) => {
  dispatch({
    type: CREDIT_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-credit/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/credits");
        }, 2000);
        dispatch({
          type: CREDIT_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: CREDIT_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CREDIT_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredCredits = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_CREDIT,
    payload: null,
  });
  http
    .post("credits", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_CREDIT_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_CREDIT_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_CREDIT_FAILED,
        payload: error,
      });
    });
};
