import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_LISTING_CONTENT,
  FETCH_SINGLE_LISTING_CONTENT_SUCCESS,
  FETCH_SINGLE_LISTING_CONTENT_FAILED,
  SINGLE_LISTING_MESSAGE,
  SINGLE_LISTING_MESSAGE_SUCCESS,
  SINGLE_LISTING_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchSingleListing = (listing_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_LISTING_CONTENT,
    payload: null,
  });
  http
    .get("single-listing/" + listing_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_LISTING_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_LISTING_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postListing = (formData, listing_id) => (dispatch) => {
  //
  let postUrl = "";
  if (listing_id != undefined && listing_id !== null) {
    postUrl = "edit-listing/" + listing_id;
  } else {
    postUrl = "add-listing";
  }
  dispatch({
    type: SINGLE_LISTING_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/listing");
        }, 2000);
        dispatch({
          type: SINGLE_LISTING_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_LISTING_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_LISTING_MESSAGE_FAILED,
        payload: error,
      });
    });
};
