import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import MediaBlk from "./sections/mediaBlk";
import MediaSort from "./mediaSort";

import { fetchMedia, deleteMedia } from "../../../states/actions/fetchMedia";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import MediaPopup from "./sections/media/media-popup";
import ListingFilter from "./sections/listingFilter";
import { getApiData } from "../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function Media() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchMedia.content);
  const isLoading = useSelector((state) => state.fetchMedia.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteMedia(data));
  };
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  const [mediaArray, setMediaArray] = useState({
    arr: [],
    loading: true,
  });
  async function handleBranchChange(e) {
    let branch_id = e.target.value;
    await getApiData("api/get-branch-media", branch_id).then((data) => {
      setMediaArray({ ...mediaArray, arr: data?.media, loading: false });
    });
    // setProperty(properties.properties);
  }
  useEffect(() => {
    if (data?.media !== undefined && data.media !== null) {
      setMediaArray({ ...mediaArray, arr: data?.media, loading: false });
    }
  }, [data]);
  useEffect(() => {
    dispatch(fetchMedia());
    dispatch(fetchSiteSettings());
  }, []);

  useDocumentTitle("Media - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const media = mediaArray?.arr;
  const branches = data.branches;
  return (
    <>
      <main index className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="media" />
              </div>
              <div className="colR">
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <Breadcrumb page_name="Media" add_link="/add-media" />
                ) : (
                  <Breadcrumb page_name="Media" />
                )}

                <div className="page_body">
                  <ListingFilter
                    branches={branches}
                    handleBranchChange={handleBranchChange}
                    is_property={true}
                  />
                  <MediaBlk
                    items={media}
                    handleDelete={handleDelete}
                    showPopup={showPopup}
                    closePopup={closePopup}
                    mediaArray={mediaArray}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {popup.show === true &&
        popup.item != undefined &&
        popup.item != null &&
        popup.item != "" ? (
          <MediaPopup closePopup={closePopup} item={popup.item} />
        ) : (
          ""
        )}
      </main>
    </>
  );
}
