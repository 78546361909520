import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getData, getSiteImages } from "../../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
export default function VerifiedEmailVerification() {
  const { token } = useParams();
  async function verifyEmail() {
    var form_data = new FormData();
    form_data.append("email", true);
    await getData("api/verified-email-verification", token).then((data) => {
      if (data.status === 1) {
        toast.success("Email is Changed successfully!", TOAST_SETTINGS);
        // reset()
        setTimeout(() => {
          window.location.replace("/profile-settings");
        }, 3000);
      } else {
        window.location.replace("/dashboard");
      }
    });
  }
  useEffect(() => {
    verifyEmail();
  }, []);
  return (
    <>
      <div className="loading-wait">
        <ToastContainer />
        <img
          src={getSiteImages("images/loading-wait.gif")}
          alt="Loading...Please wait!!"
        />
      </div>
    </>
  );
}
