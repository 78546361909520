import React, { useEffect, useState } from "react";

import {
  getFilteredPayments,
  getPayments,
} from "../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import PaymentPopup from "./payments/paymentPopup";
import PaymentTabs from "./payments/paymentTabs";
import LeasePayments from "./payments/lease-payments";
import Sidebar from "./sections/sidebar";
import { ServiceTable } from "./payments/service-table";

export default function Payments({ mem_type }) {
  const [paymentPopup, setPaymentPopup] = useState({
    item: null,
    show: false,
  });
  const closePopup = () => {
    setPaymentPopup({
      item: null,
      show: false,
    });
  };
  const [type, setPaymentType] = useState("leases");
  const [paymentStatus, setPaymentStatus] = useState("accepted");
  const [filterData, setFilterData] = useState({
    status: "accepted",
    role: mem_type == "company" ? "landlord_leases" : "leases",
    branch: "all",
  });
  const dispatch = useDispatch();
  const data = useSelector((state) => state.payments.content);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isLoading = useSelector((state) => state.payments.isLoading);
  const isFilterLoading = useSelector(
    (state) => state.payments.isFilterLoading
  );
  const payments = useSelector((state) => state.payments.payments_arr);
  const landlord_payments = useSelector(
    (state) => state.payments.landlord_payments
  );
  useEffect(() => {
    dispatch(
      getPayments({
        mem_type: null,
        status: filterData?.status,
        role: mem_type == "company" ? "landlord_leases" : filterData?.role,
      })
    );
  }, [mem_type]);

  const handlePaymentFilterChange = (e, type, value = "") => {
    if (type === "status") {
      setFilterData({ ...filterData, [type]: value });
    } else {
      setFilterData({ ...filterData, [type]: e.target.value });
    }

    const formData = {
      status: type === "status" ? value : filterData?.status,
      role: type === "role" ? e.target.value : filterData?.role,
      branch: type === "branch" ? e.target.value : filterData?.branch,
    };

    dispatch(getFilteredPayments(formData));
  };

  useEffect(() => {
    if (type === "services") {
    } else {
      if (
        parseInt(payments?.length) <= 0 &&
        parseInt(landlord_payments?.length) > 0
      ) {
        setPaymentType("landlord_leases");
      } else {
        if (filterData?.role === "leases") {
          setPaymentType("leases");
        }
      }
    }
  }, [payments]);
  useEffect(() => {
    if (mem_type == "company") {
      setPaymentType("landlord_leases");
    }
  }, [mem_type]);

  useDocumentTitle("Payments - Loftus");
  if (isLoading || !siteSettings) return <LoadingScreen />;
  // if (!siteSettings) return <LoadingScreen />;
  const service_payment_history = data?.service_payment_history;
  const branches = data?.branches;
  console.log(landlord_payments, payments, type);
  return (
    <>
      <main
        index
        className={
          mem_type === "company"
            ? "main_dashboard commercial_dashboard_new"
            : "main_dashboard"
        }
      >
        <section className="dashboard">
          <div className="contain">
            {mem_type === "company" ? (
              <div className="flex dash_intro_flex">
                <div className="colL">
                  <Sidebar active="payments" />
                </div>
                <div className="colR ">
                  <Breadcrumb page_name="Payments" />
                  <div className="page_body payment_history_dash payment_payment_new">
                    <PaymentTabs
                      handlePaymentFilterChange={handlePaymentFilterChange}
                      filterData={filterData}
                      type={type}
                      setPaymentType={setPaymentType}
                      landlord_leases_count={parseInt(
                        landlord_payments?.length
                      )}
                      tenant_leases_count={parseInt(payments?.length)}
                      paymentStatus={paymentStatus}
                      setPaymentStatus={setPaymentStatus}
                      mem_type={mem_type}
                      branches={branches}
                    />
                    <div className="tab-content">
                      {isFilterLoading ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {type === "services" ? (
                        <div
                          id="services"
                          className="tab-pane fade a active in"
                        >
                          <div className="blk payment_table blockLst cell_block_cell">
                            <ServiceTable
                              service_payment_history={service_payment_history}
                            />
                          </div>
                        </div>
                      ) : type === "leases" ? (
                        <LeasePayments
                          payments={payments}
                          setPaymentPopup={setPaymentPopup}
                        />
                      ) : type === "landlord_leases" ? (
                        <LeasePayments
                          payments={landlord_payments}
                          setPaymentPopup={setPaymentPopup}
                          isLandlord={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Breadcrumb page_name="Payments" />
                <div className="page_body payment_history_dash payment_payment_new">
                  <PaymentTabs
                    handlePaymentFilterChange={handlePaymentFilterChange}
                    filterData={filterData}
                    type={type}
                    setPaymentType={setPaymentType}
                    landlord_leases_count={parseInt(landlord_payments?.length)}
                    tenant_leases_count={parseInt(payments?.length)}
                    paymentStatus={paymentStatus}
                    setPaymentStatus={setPaymentStatus}
                    mem_type={mem_type}
                  />
                  <div className="tab-content">
                    {isFilterLoading ? (
                      <div className="loadingDisabled">
                        <i className="spinner"></i>
                      </div>
                    ) : (
                      ""
                    )}
                    {type === "services" ? (
                      <div id="services" className="tab-pane fade a active in">
                        <div className="blk payment_table blockLst cell_block_cell">
                          <ServiceTable
                            service_payment_history={service_payment_history}
                          />
                        </div>
                      </div>
                    ) : type === "leases" ? (
                      <LeasePayments
                        payments={payments}
                        setPaymentPopup={setPaymentPopup}
                      />
                    ) : type === "landlord_leases" ? (
                      <LeasePayments
                        payments={landlord_payments}
                        setPaymentPopup={setPaymentPopup}
                        isLandlord={true}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
        {paymentPopup?.item !== null && paymentPopup?.show === true ? (
          <PaymentPopup closePopup={closePopup} siteSettings />
        ) : (
          ""
        )}
      </main>
    </>
  );
}
