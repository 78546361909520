import React, { useState } from 'react'
import OfferBlk from './offerBlk';
import OfferSort from './sort';
import Pagination from '../../../../common/pagination';


export default function OfferTabGrids({ items, mem_type }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    return (
        <>
            <div id="received_offer" className="tab-pane fade active in a">

                {
                    items?.length > 0 && currentItems !== undefined && currentItems !== '' && currentItems !== null ?
                        <>
                            {

                                currentItems?.map((item, index) => {
                                    return (
                                        <OfferBlk item={item} key={index} mem_type={mem_type} />
                                    )
                                })
                            }

                        </>
                        :
                        <div className='alert alert-danger'>No offer(s) added yet!</div>
                }

                <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />

            </div>
        </>
    )
}
