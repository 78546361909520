import React, { useEffect, useStats } from 'react'
import Breadcrumb from './sections/breadcrumb';
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from '../../common/LoadingScreen';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { getApiData } from '../../../helpers/api';
import { updateNotificationStatus } from "../../../states/actions/profileSettings";
export default function Notification() {
    const dispatch = useDispatch();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    const isNotificationProcessing = useSelector(
        (state) => state.profileSettings.isNotificationProcessing
    );
    useEffect(() => {
        dispatch(updateNotificationStatus());
    }, []);
    useEffect(() => {
    }, [siteSettings]);
    useDocumentTitle('Notifications - Loftus');
    if (isSiteSettingsLoading || isNotificationProcessing) return <LoadingScreen />;
    const notifications = siteSettings?.member?.notifications?.content;
    return (
        <>
            <main index className="main_dashboard">
                <section className="dashboard">
                    <div className="contain">
                        <Breadcrumb page_name="Notifications" />
                        <div className="page_body">
                            <div className="notification_blk notiBlk">
                                <div className="flex">
                                    {
                                        notifications !== undefined && notifications !== null && notifications?.length > 0 ?
                                            notifications?.map((item, index) => {
                                                return (
                                                    <div className="col" key={index}>
                                                        <div className="inner">
                                                            <div className="ico"><img src={item?.thumb} alt={item?.name} /></div>
                                                            <div className="txt">
                                                                <p dangerouslySetInnerHTML={{ __html: item?.text }} />
                                                                <span className="time">{item?.time}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                            :
                                            <div className='alert alert-danger'>No notification(s) found!</div>
                                    }

                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    )
}
