import {
    FETCH_SINGLE_USER_PROPERTY_CONTENT,
    FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS,
    FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED,
    SINGLE_USER_PROPERTY_MESSAGE,
    SINGLE_USER_PROPERTY_MESSAGE_SUCCESS,
    SINGLE_USER_PROPERTY_MESSAGE_FAILED,

} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false,
    floor_plans: []
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_SINGLE_USER_PROPERTY_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_SINGLE_USER_PROPERTY_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_SINGLE_USER_PROPERTY_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };

        case SINGLE_USER_PROPERTY_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SINGLE_USER_PROPERTY_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
            };
        case SINGLE_USER_PROPERTY_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };

        default:
            return state;
    }
}
