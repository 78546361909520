import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import moment from "moment";
import { NavLink } from "react-router-dom";
export default function BillingStep2({
  changeStep,
  step,
  register,
  errors,
  control,
  isValid,
  watchAllFields,
  branches,
  handleBranchChange,
  properties,
  isPropertyLoading,
  trigger,
  billing_row,
  handleBranchPropertyChange,
  leases,
}) {
  const getNextMonthStartDate = () => {
    return moment().add(1, "months").startOf("month").toDate();
  };
  useEffect(() => {
    register("property_id", { required: "Required" });
  }, [register]);
  return (
    <fieldset>
      <div className="form_row row flex">
        <div className="col-xs-12">
          <h6>Select Branch</h6>
          <div className="form_blk">
            <Controller
              {...register("branch_id", { required: "Required" })}
              name="branch_id"
              control={control}
              render={({ field: { onChange, value, ref, name } }) => (
                <>
                  <Select
                    options={branches}
                    onChange={(val) => {
                      onChange(val.value);
                      handleBranchChange(val.value);
                    }}
                    label="Select Branch"
                    defaultValue={branches.filter(function (option) {
                      return option.value === value;
                    })}
                  />
                </>
              )}
            />
            <ErrorMessage
              errors={errors}
              name="branch_id"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <h6>Select Property</h6>
          <div className="form_blk">
            <select
              name="property_id"
              id="property_id"
              className="txtBox"
              onChange={handleBranchPropertyChange}
              // {...register("property_id", { required: "Required" })}
            >
              {properties != null && properties.length > 0 ? (
                <>
                  <option value="">Select Properties for Branch</option>
                  {properties?.map((property, index) => {
                    return (
                      <>
                        <option value={property.value} key={index} selected={watchAllFields?.property_id===property?.value ? true : false}>
                          {property.label}
                        </option>
                      </>
                    );
                  })}
                </>
              ) : (
                <option value="">No properties found</option>
              )}
            </select>
            <ErrorMessage
              errors={errors}
              name="property_id"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <h6>Select Lease</h6>
          <div className="form_blk">
            <select
              name="lease_id"
              id="lease_id"
              className="txtBox"
              {...register("lease_id", { required: "Required" })}
            >
              {leases != null && leases.length > 0 ? (
                <>
                  <option value="">Select leases for Property</option>
                  {leases?.map((lease, index) => {
                    return (
                      <>
                        <option value={lease?.value} key={index} selected={watchAllFields?.lease_id===lease?.value ? true : false}>
                          {lease?.label}
                        </option>
                      </>
                    );
                  })}
                </>
              ) : (
                <option value="">No leases found</option>
              )}
            </select>
            <ErrorMessage
              errors={errors}
              name="lease_id"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <h6>Select Cycle</h6>
          <div className="form_blk cycle_datepicker">
            <Controller
              control={control}
              name="cycle"
              {...register("cycle", { required: "Required" })}
              render={({ field }) => (
                <DatePicker
                  className="txtBox"
                  placeholderText="_ _ / _ _ / _ _ _ _"
                  onChange={(date) => field.onChange(date)}
                  selected={field.value}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  minDate={getNextMonthStartDate()}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="cycle"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
      </div>

      <div className="cmnBtn text-center">
        {billing_row?.id > 0 ? (
          <NavLink to="/billing" className="webBtn backBtn">
            Previous
          </NavLink>
        ) : (
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
        )}

        {watchAllFields?.branch_id === "" ||
            watchAllFields?.branch_id === null ||
            watchAllFields?.branch_id === undefined ||
            watchAllFields?.property_id === "" ||
            watchAllFields?.property_id === null ||
            watchAllFields?.property_id === undefined ||
            watchAllFields?.lease_id === "" ||
            watchAllFields?.lease_id === null ||
            watchAllFields?.lease_id === undefined ||
            watchAllFields?.cycle === "" ||
            watchAllFields?.cycle === null ||
            watchAllFields?.cycle === undefined ? (
          <button
            type="button"
            className="webBtn colorBtn nextBtn"
            onClick={() => {
              trigger();
            }}
            disabled={isPropertyLoading}
          >
            Next {isPropertyLoading ? <i className="spinner"></i> : ""}
          </button>
        ) : (
          <button
            type="button"
            className="webBtn colorBtn nextBtn"
            onClick={() => changeStep(step + 1)}
            disabled={isPropertyLoading}
          >
            Next {isPropertyLoading ? <i className="spinner"></i> : ""}
          </button>
        )}
      </div>
    </fieldset>
  );
}
