import React, { useEffect } from "react";

import { ToastContainer } from "react-toastify";
import {
  fetchBranches,
  deleteBranch,
} from "../../../states/actions/fetchBranches";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

import LoadingScreen from "../../common/LoadingScreen";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import BranchBlk from "./sections/branch-blk";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function Branches() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchBranches.content);
  const isLoading = useSelector((state) => state.fetchBranches.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteBranch(data));
  };

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchSiteSettings());
  }, []);
  useDocumentTitle("Branches - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const branches = data.branches;
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="branches" />
              </div>
              <div className="colR">
                {mem_permissions === "admin" && parseInt(super_admin) === 1 ? (
                  <Breadcrumb page_name="Branches" add_link="/add-branch" />
                ) : (
                  <Breadcrumb page_name="Branches" />
                )}
                <BranchBlk branches={branches} handleDelete={handleDelete} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
