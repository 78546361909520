import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  convertSimpleDateUsingMoment,
  getBranchProperties,
  getBranchPropertyLeases,
} from "../../../../helpers/api";
import toast, { Toaster } from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import moment from "moment";
export default function CreditForm({
  branches,
  isFormProcessing,
  handleCreditForm,
  credit_row,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const [isPropertyLoading, setPropertyloading] = useState(false);
  const [properties, setProperty] = useState(null);
  const [leases, setLeases] = useState(null);
  useEffect(() => {
    if (credit_row?.id > 0) {
      setValue(
        "cycle",
        credit_row?.cycle !== null &&
          credit_row?.cycle !== undefined &&
          credit_row?.cycle !== ""
          ? setDateToMonthYear(new Date(credit_row?.cycle))
          : new Date()
      );
      setValue("branch_id", credit_row?.branch_id);
      setValue("lease_id", credit_row?.lease_id);
      setValue("property_id", credit_row?.property_id);
      setValue("amount", credit_row?.amount);
      setValue("notes", credit_row?.notes);
      if (credit_row?.properties?.length > 0) {
        setProperty(credit_row?.properties);
      }
      if (credit_row?.leases?.length > 0) {
        setLeases(credit_row?.leases);
      }
    }
  }, [credit_row]);
  const setDateToMonthYear = (date) => {
    if (!date) return null; // Handle null or undefined date

    const year = date.getFullYear();
    const month = date.getMonth();

    // Set the date to the first day of the month to ensure consistency
    return new Date(year, month, 1);
  };

  async function handleBranchChange(value) {
    setPropertyloading(true);
    let properties = await getBranchProperties(value);
    setProperty(properties.properties);
    setPropertyloading(false);
  }
  async function handleBranchPropertyChange(e) {
    const property_id = e.target.value;
    setValue("property_id", property_id);
    if (parseInt(watchAllFields?.branch_id) > 0) {
      if (parseInt(property_id) > 0) {
        setPropertyloading(true);
        let leases_res = await getBranchPropertyLeases(
          watchAllFields?.branch_id,
          property_id
        );
        setPropertyloading(false);
        if (leases_res?.status) {
          setLeases(leases_res?.leases);
        }
      } else {
        toast.error("Property is invalid!");
        return;
      }
    } else {
      toast.error("Branch is invalid!");
      return;
    }
  }
  const onSubmit = (data) => {
    let newData = {
      ...data,
      cycle: convertSimpleDateUsingMoment(data?.cycle),
    };

    // ;
    handleCreditForm(newData);
  };
  const getNextMonthStartDate = () => {
    return moment().add(1, "months").startOf("month").toDate();
  };
  useEffect(() => {
    register("property_id", { required: "Required" });
  }, [register]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div class="form_row row flex">
            <div class="col-xs-6">
              <h6>Branch</h6>
              <div class="form_blk">
                <Controller
                  {...register("branch_id", { required: "Required" })}
                  name="branch_id"
                  control={control}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={branches}
                        onChange={(val) => {
                          onChange(val.value);
                          handleBranchChange(val.value);
                        }}
                        label="Select Branch"
                        value={branches.filter(function (option) {
                          return option.value === value;
                        })}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="branch_id"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-6">
              <h6>Property</h6>
              <div class="form_blk">
                <select
                  name="property_id"
                  id="property_id"
                  className="txtBox"
                  onChange={handleBranchPropertyChange}
                  // {...register("property_id", { required: "Required" })}
                >
                  {properties != null && properties.length > 0 ? (
                    <>
                      <option value="">Select Properties for Branch</option>
                      {properties?.map((property, index) => {
                        return (
                          <>
                            <option
                              value={property.value}
                              key={index}
                              selected={
                                watchAllFields?.property_id === property?.value
                                  ? true
                                  : false
                              }
                            >
                              {property.label}
                            </option>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <option value="">No properties found</option>
                  )}
                </select>
                <ErrorMessage
                  errors={errors}
                  name="property_id"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-6">
              <h6>Lease</h6>
              <div class="form_blk">
                <select
                  name="lease_id"
                  id="lease_id"
                  className="txtBox"
                  {...register("lease_id", { required: "Required" })}
                >
                  {leases != null && leases.length > 0 ? (
                    <>
                      <option value="">Select leases for Property</option>
                      {leases?.map((lease, index) => {
                        return (
                          <>
                            <option
                              value={lease?.value}
                              key={index}
                              selected={
                                watchAllFields?.lease_id === lease?.value
                                  ? true
                                  : false
                              }
                            >
                              {lease?.label}
                            </option>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <option value="">No leases found</option>
                  )}
                </select>
                <ErrorMessage
                  errors={errors}
                  name="lease_id"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-6">
              <h6>Cycle</h6>
              <div class="form_blk">
                <Controller
                  control={control}
                  name="cycle"
                  {...register("cycle", { required: "Required" })}
                  render={({ field }) => (
                    <DatePicker
                      className="txtBox"
                      placeholderText="_ _ / _ _ / _ _ _ _"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      minDate={getNextMonthStartDate()}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="cycle"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-6">
              <h6>Amount</h6>
              <div class="form_blk">
                <input
                  type="text"
                  class="txtBox"
                  {...register("amount", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                    min: {
                      value: 1,
                      message: "Value must be greater than 0.",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="amount"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-12">
              <h6>Description</h6>
              <div class="form_blk">
                <textarea
                  name=""
                  id=""
                  class="txtBox"
                  {...register("notes", { required: "Required" })}
                ></textarea>
                <ErrorMessage
                  errors={errors}
                  name="notes"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
          </div>

          <div class="cmnBtn text-center">
            <button type="button" class="webBtn">
              Cancel
            </button>
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing || isPropertyLoading}
            >
              Save{" "}
              {isFormProcessing || isPropertyLoading ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          </div>
        </fieldset>
      </form>
    </>
  );
}
