import { useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import BankBlk from "./payment-methods-sections/bank-blk";
import CreditCardBlk from "./payment-methods-sections/credit-card-blk";
import PaymentMethodTab from "./payment-methods-sections/tabs";
import { getPaymentMethods, deletePaymentMethod } from "../../../states/actions/payments";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from '../../common/LoadingScreen';
import { useEffect } from "react";
export default function PaymentMethods() {
    const data = useSelector((state) => state.payments.content);
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isLoading = useSelector((state) => state.payments.isLoading);
    const isPaymentMethodDeleted = useSelector((state) => state.payments.isPaymentMethodDeleted);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPaymentMethods());
    }, []);
    const [type, setType] = useState('bank');
    const changeType = (e, type) => {
        e.preventDefault();
        setType(type)
    }
    useDocumentTitle("Payment Methods - Loftus");
    if (isLoading || !siteSettings) return <LoadingScreen />;
    const credit_cards_arr = data?.payment_methods
    const bank_accounts_arr = data?.payment_methods_ach
    return (
        <main index className="main_dashboard">
            <section className="dashboard">
                <div className="contain">
                    <Breadcrumb page_name="Payment Methods" add_link="/add-payment-method" />
                    <div className="page_body bank_method_acc">
                        <PaymentMethodTab type={type} changeType={changeType} total_bank_account={bank_accounts_arr?.length} total_credit_cards={credit_cards_arr?.length} />
                        <div className="tab-content">
                            {
                                type === 'bank' ?

                                    <BankBlk items={bank_accounts_arr} deletePaymentMethod={deletePaymentMethod} isPaymentMethodDeleted={isPaymentMethodDeleted} />
                                    :
                                    type === 'card' ?
                                        <CreditCardBlk items={credit_cards_arr} deletePaymentMethod={deletePaymentMethod} isPaymentMethodDeleted={isPaymentMethodDeleted} />
                                        :
                                        ""
                            }
                        </div>

                    </div>

                </div>
            </section>
        </main>
    );
}