import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSingleMedia,
  postMedia,
} from "../../../states/actions/fetchSingleMedia";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";

import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import AddMediaForm from "./sections/add-media-form";
import { uploadImages } from "../../../helpers/api";

export default function AddMedia() {
  const dispatch = useDispatch();
  const { media_id } = useParams();
  const [imageThumbnail, setThumbnail] = useState(null);
  const [images, setImages] = useState({ loading: false, images: [] });
  useEffect(() => {}, [imageThumbnail]);
  useEffect(() => {}, [images]);
  const handleMedia = (data) => {
    dispatch(postMedia(data, media_id));
  };
  async function uploadMultipleImages(e) {
    setImages({ ...images, loading: true });
    let uploadedImages = await uploadImages(e, "media");
    {
      images !== undefined &&
      images !== null &&
      images !== "" &&
      images?.images?.length > 0
        ? setImages({
            ...images,
            images: images?.images.concat(uploadedImages),
            loading: false,
          })
        : setImages({ ...images, images: uploadedImages, loading: false });
    }
  }
  function handleRemoveImage(idx) {
    setImages({
      ...images,
      images: images?.images.filter((s, sidx) => idx !== sidx),
    });
  }
  const data = useSelector((state) => state.fetchSingleMedia.content);
  const isLoading = useSelector((state) => state.fetchSingleMedia.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleMedia.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleMedia(media_id));
  }, []);
  useDocumentTitle(
    data != undefined &&
      data != null &&
      data.media_row != undefined &&
      data.media_row !== null
      ? "Edit Media - Loftus"
      : "Add Media - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data.branches;
  const media_row = data.media_row;
  return (
    <>
      <main index className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="media" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    media_row !== undefined &&
                    media_row !== null &&
                    media_row !== ""
                      ? "Edit Media"
                      : "Add Media"
                  }
                  parent_name="Media"
                  parent_link="/media"
                />

                <div className="page_body">
                  <div className="dash_form_blk relative">
                    {images != undefined && images?.loading === true ? (
                      <div className="loadingDisabled">
                        <i className="spinner"></i>
                      </div>
                    ) : (
                      ""
                    )}
                    <AddMediaForm
                      setThumbnail={setThumbnail}
                      imageThumbnail={imageThumbnail}
                      path="storage/media/"
                      uploadMultipleImages={uploadMultipleImages}
                      images={images}
                      handleRemoveImage={handleRemoveImage}
                      branches={branches}
                      handleMedia={handleMedia}
                      isFormProcessing={isFormProcessing}
                      media_row={media_row}
                      setImages={setImages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
