import React from "react";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function ContactLst({ item, type, handleDelete }) {
  return (
    <>
      <div class="lst">
        <ul>
          <li>
            <h5 class="lease_heading_mini">Branch</h5>
            <div>{item?.branch_name}</div>
          </li>
          <li>
            <h5 class="lease_heading_mini">Modified</h5>
            <div>{item?.modified_date}</div>
          </li>
          {type === null &&
          (mem_permissions === "admin" || mem_permissions === "power") ? (
            <li class="bTn action_drop_lease">
              <div class="action_drop _dropDown">
                <div class="_dropBtn action_dots">
                  <img src="/images/dashboard/dots.png" alt="" />
                </div>
                <ul class="_dropCnt dropLst">
                  <li>
                    <NavLink to={"/edit-contact/" + item?.id}>Edit</NavLink>
                  </li>
                  <li>
                    <a
                      href="#!"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          handleDelete(e, item?.id);
                      }}
                    >
                      Delete
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
}
