import React, { useEffect, useState } from "react";
import PromotionStep1 from "./steps/step1";
import PromotionStep2 from "./steps/step2";
import { useForm } from "react-hook-form";
import {
  consoleLog,
  convertSimpleDateUsingMoment,
  datepickerDateGet,
  getBranchFloorPlanProperties,
  getBranchFloorPlans,
} from "../../../../helpers/api";
import { toast } from "react-toastify";

export default function AddPromotionForm({
  setFormStep,
  formStep,
  branches,
  handleSubmitForm,
  isFormProcessing,
  promotion_row,
  isPropertyExisted,
  isPropertyExistedLoading,
  getExistingPropertyPromotion,
  isPropertyExistedStatus,
  emptyStatusValue,
  expenses,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const [isPropertyLoading, setPropertyloading] = useState(false);
  const [properties, setProperty] = useState([]);
  const [floorPlans, setFloorPlans] = useState([]);
  useEffect(() => {
    if (promotion_row?.id > 0) {
      setProperty(promotion_row?.properties_arr);
      setFloorPlans(promotion_row?.floor_plans);
      setValue("branch_id", promotion_row?.branch_id);
      setValue("floor_plan", promotion_row?.floor_plans);
      setValue("property", promotion_row?.properties_arr);
      setValue("description", promotion_row?.description);
      setValue("apply_all", promotion_row?.apply_all);
      setValue(
        "start_date",
        promotion_row?.start_date !== null &&
          promotion_row?.start_date !== undefined &&
          promotion_row?.start_date !== ""
          ? datepickerDateGet(promotion_row?.start_date)
          : ""
      );
      setValue(
        "end_date",
        promotion_row?.end_date !== null &&
          promotion_row?.end_date !== undefined &&
          promotion_row?.end_date !== ""
          ? datepickerDateGet(promotion_row?.end_date)
          : ""
      );
      if (
        promotion_row?.prices !== undefined &&
        promotion_row?.prices !== null &&
        promotion_row?.prices?.length > 0
      ) {
        const modifiedData = promotion_row?.prices?.map((item) => ({
          randomKey: item?.randomKey,
          expense: item?.expense,
          type: item?.type,
          discount: item?.discount,
          cycle: item?.cycle,
          expense_type: item?.expense_type,
          allcycle: item?.allcycle,
        }));
        setPrices({
          prices: modifiedData,
        });
      }
    }
  }, [promotion_row]);
  async function handleBranchChange(value) {
    setPropertyloading(true);
    setProperty([]);
    let floor_plans = await getBranchFloorPlans(value);
    setFloorPlans(floor_plans.floor_plans);
    setProperty(floor_plans?.properties);

    setPropertyloading(false);
  }
  async function handleBranchFloorPlanChange(floorPlan) {
    const floor_plan = floorPlan;
    if (parseInt(watchAllFields?.branch_id) > 0) {
      // if (floor_plan?.length > 0) {
      setPropertyloading(true);
      let properties_res = await getBranchFloorPlanProperties(
        watchAllFields?.branch_id,
        floor_plan
      );
      setPropertyloading(false);
      setProperty(properties_res?.properties);
      if (
        floorPlan?.length <= 0 ||
        floorPlan === undefined ||
        floorPlan === null ||
        floorPlan === ""
      ) {
        setValue("property", properties_res?.properties);
        setValue("floor_plan", floorPlan);
      }
    } else {
      toast.error("Branch is invalid!");
      return;
    }
  }

  const onSubmit = (frmData) => {
    // console.log(prices?.prices); return;
    let property = frmData?.property;
    if (
      frmData?.property === undefined ||
      frmData?.property === null ||
      frmData?.property === "" ||
      frmData?.property?.length <= 0
    ) {
      property = properties;
    } else {
      property = frmData?.property;
    }
    const updatedPrices = prices?.prices.map((price) => {
      if (!price.expense) {
        price.expense = "rent"; // Defaulting to "rent" as the first option
      }
      if (!price.cycle) {
        price.cycle = []; // Defaulting to "1st" as the first option
      }
      if (!price.type) {
        price.type = "amount"; // Defaulting to "1st" as the first option
      }
      return price;
    });
    const expenseSet = new Set();
    for (const price of updatedPrices) {
      if (expenseSet.has(price.expense)) {
        console.error(`Duplicate expense found: ${price.expense}`);
        toast.error(`Error: Duplicate expense found: ${price.expense}`);
        return;
        return;
      }
      expenseSet.add(price.expense);
    }
    setPrices({ ...prices, prices: updatedPrices });
    let newData = {
      ...frmData,
      prices: JSON.stringify(prices.prices),
      property: JSON.stringify(property),
      floor_plan: JSON.stringify(frmData?.floor_plan),
      start_date: convertSimpleDateUsingMoment(frmData?.start_date),
      end_date: convertSimpleDateUsingMoment(frmData?.end_date),
    };

    handleSubmitForm(newData);
  };

  const [prices, setPrices] = useState({
    prices: [],
  });
  const handleAddNewPrice = () => {
    setPrices({
      ...prices,
      prices: prices.prices.concat([
        {
          randomKey: Math.random(),
          expense: "",
          type: "",
          discount: "",
          cycle: [],
          expense_type: "",
          allcycle: "",
        },
      ]),
    });
  };
  function handleRemovePrice(idx) {
    setPrices({
      ...prices,
      prices: prices.prices.filter((s, sidx) => idx !== sidx),
    });
  }
  function handlePriceChange(idx, evt, price_type = "") {
    let value = "";
    let name = "";
    if (price_type === "cycle") {
      value = evt;
      name = "cycle";
    } else {
      value = evt.target.value;
      name = evt.target.name;
    }

    let newPrices = prices.prices.map((prices, sidx) => {
      if (idx !== sidx) return prices;
      return { ...prices, [name]: value };
    });
    if (name === "expense") {
      let expense_type = "";
      if (name !== "rent") {
        if (value?.includes(":")) {
          const price_values = value?.split(":");
          expense_type = price_values[1];
          // if (expense_type === "one_time") {
          value = price_values[0];
          // }
        }
      } else if (name === "rent") {
        expense_type = "rent";
      }
      newPrices = prices.prices.map((prices, sidx) => {
        if (idx !== sidx) return prices;
        return { ...prices, [name]: value, expense_type: expense_type };
      });
    }

    setPrices({ ...prices, prices: newPrices });
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {formStep === 0 ? (
          <PromotionStep1
            setFormStep={setFormStep}
            formStep={formStep}
            branches={branches}
            register={register}
            trigger={trigger}
            errors={errors}
            control={control}
            watchAllFields={watchAllFields}
            isValid={isValid}
            handleBranchChange={handleBranchChange}
            floorPlans={floorPlans}
            isPropertyLoading={isPropertyLoading}
            handleBranchFloorPlanChange={handleBranchFloorPlanChange}
            properties={properties}
            setValue={setValue}
            isPropertyExistedLoading={isPropertyExistedLoading}
            isPropertyExisted={isPropertyExisted}
            getExistingPropertyPromotion={getExistingPropertyPromotion}
            promotion_row={promotion_row}
            isPropertyExistedStatus={isPropertyExistedStatus}
            emptyStatusValue={emptyStatusValue}
          />
        ) : formStep === 1 ? (
          <PromotionStep2
            setFormStep={setFormStep}
            formStep={formStep}
            isFormProcessing={isFormProcessing}
            handleAddNewPrice={handleAddNewPrice}
            prices={prices}
            handleRemovePrice={handleRemovePrice}
            handlePriceChange={handlePriceChange}
            expenses={expenses}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
}
