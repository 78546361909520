import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchUserSingleOffer,
  landlordOfferAction,
} from "../../../states/actions/fetchUserSingleOffer";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "./sections/breadcrumb";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import PropertyInfo from "./offers/property-info";
import OfferDetailsRight from "./offers/offer-details";
import { ToastContainer } from "react-toastify";
import Sidebar from "../dashboard-company/sections/sidebar";

export default function ReceivedOfferDetails({ mem_type }) {
  const dispatch = useDispatch();
  const { offer_id } = useParams();
  const [reportPopUp, setReportPopup] = useState(null);
  const showPopup = (content, status_flag) => {
    if (status_flag === true) {
      setReportPopup(content);
    }
  };
  const closePopup = () => {
    setReportPopup(null);
  };
  const handleLandlordOfferAction = (data) => {
    dispatch(landlordOfferAction(data, offer_id));
  };
  const data = useSelector((state) => state.fetchUserSingleOffer.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchUserSingleOffer.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchUserSingleOffer.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchUserSingleOffer(offer_id, true));
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  let property_row = data.property_row;

  let user = data?.user;
  let offer_row = data?.offer_row;
  let listing_row = data?.listing_row;
  let member = data?.member;
  let is_landlord = member?.id === listing_row?.mem_id ? true : false;
  return (
    <>
      {mem_type === "company" ? (
        <div index="" className="main_dashboard commercial_dashboard_new">
          <section className="dashboard">
            <div className="contain">
              <div className="flex dash_intro_flex">
                <div className="colL">
                  <Sidebar active="offers" />
                </div>
                <div className="colR">
                  <Breadcrumb
                    page_name={"Received Offer: " + offer_row?.offer_num}
                    parent_name="Offers"
                    parent_link="/offers"
                  />
                  <div className="offer_dash_page leases_page_dash rental-offer-page-dt">
                    <div className="outer_list hide_sm_clmn whole_lst_lnk">
                      <div className="tab-content relative">
                        <div className="offer_dash_detail_page">
                          <div
                            class={
                              listing_row?.listing_type === "sale"
                                ? "sale_offer_flex flex"
                                : "flex"
                            }
                          >
                            <PropertyInfo
                              property_row={property_row}
                              user={user}
                              received={true}
                              branch_id={
                                property_row?.mem_type === "company"
                                  ? property_row?.branch_row?.id
                                  : false
                              }
                            />
                            <OfferDetailsRight
                              offer_row={offer_row}
                              received={true}
                              listing_row={listing_row}
                              showPopup={showPopup}
                              handleLandlordOfferAction={
                                handleLandlordOfferAction
                              }
                              isFormProcessing={isFormProcessing}
                              is_landlord={is_landlord}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <main index className="main_dashboard">
          <section className="dashboard">
            <div className="contain">
              <ToastContainer />
              <Breadcrumb
                page_name={"Received Offer: " + offer_row?.offer_num}
                parent_name="Offers"
                parent_link="/offers"
              />
              <div className="page_body">
                <div className="offer_dash_detail_page">
                  <div
                    class={
                      listing_row?.listing_type === "sale"
                        ? "sale_offer_flex flex"
                        : "flex"
                    }
                  >
                    <PropertyInfo
                      property_row={property_row}
                      user={user}
                      received={true}
                      branch_id={
                        property_row?.mem_type === "company"
                          ? property_row?.branch_row?.id
                          : false
                      }
                    />
                    <OfferDetailsRight
                      offer_row={offer_row}
                      received={true}
                      listing_row={listing_row}
                      showPopup={showPopup}
                      handleLandlordOfferAction={handleLandlordOfferAction}
                      isFormProcessing={isFormProcessing}
                      is_landlord={is_landlord}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {reportPopUp !== null &&
          reportPopUp !== undefined &&
          reportPopUp !== "" ? (
            <section
              className="popup popup_for_lease"
              data-popup="choose-lease-property"
            >
              <div className="tableDv">
                <div className="tableCell">
                  <div className="_inner">
                    <div className="crosBtn" onClick={() => closePopup()}></div>
                    <div dangerouslySetInnerHTML={{ __html: reportPopUp }} />
                  </div>
                </div>
              </div>
            </section>
          ) : (
            ""
          )}
        </main>
      )}
    </>
  );
}
