import React, { useState } from "react";
import MediaLst from "./mediaLst";
import Pagination from "../../../common/pagination";
import { getSiteImages } from "../../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function MediaBlk({
  items,
  handleDelete,
  showPopup,
  mediaArray,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div className="blk blockLst user_dash_tbl cell_block_cell">
        <table>
          <thead>
            <tr>
              <th>Branch</th>
              <th>Images</th>
              {(mem_permissions === "admin" || mem_permissions === "power") ? (
                <th className="text-right hide_cell_hyp">Actions</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {mediaArray?.loading === true ? (
              <div className="relative">
                <div id="loading" className="listingsLoading static">
                  {" "}
                  <img
                    src={getSiteImages("images/loading.gif")}
                    alt="Loading"
                  />
                </div>
              </div>
            ) : currentItems !== undefined &&
              currentItems !== "" &&
              currentItems !== null &&
              currentItems?.length > 0 ? (
              <>
                {currentItems?.map((item, index) => {
                  return (
                    <MediaLst
                      item={item}
                      key={index}
                      deleteItem={handleDelete}
                      showPopup={showPopup}
                    />
                  );
                })}
              </>
            ) : (
              <tr>
                <td colSpan="3">
                  <div className="alert alert-danger">
                    No media(s) added yet!
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        paginate={paginate}
        itemsPerPage={itemsPerPage}
        totalItems={items?.length}
        currentPage={currentPage}
      />
    </>
  );
}
