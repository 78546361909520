import React, { useEffect } from "react";
import LoginForm from "./section/login-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { ToastContainer } from "react-toastify";

import {
  fetchLogin,
  postLogin,
  googlePostLogin,
} from "../../states/actions/fetchLogin";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import { consoleLog } from "../../helpers/api";

export default function Login() {
  const dispatch = useDispatch();
  const handleLogin = (formData) => {
    let newFormData = { ...formData, type: "normal" };
    dispatch(postLogin(newFormData));
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchLogin.isFormProcessing
  );
  const isGoogleProcessing = useSelector(
    (state) => state.fetchLogin.isGoogleProcessing
  );
  const emptyFormData = useSelector((state) => state.fetchLogin.emptyFormData);
  const data = useSelector((state) => state.fetchLogin.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector((state) => state.fetchLogin.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const onSuccess = (response) => {
    let formData = { ...response, type: "google" };
    dispatch(googlePostLogin(formData));
  };
  const onFailure = (response) => {
    consoleLog("FAILED", response);
    // if (response?.error) {
    //     toast.error("Login Error >> " + response?.error + " >> " + response?.details,
    //         TOAST_SETTINGS
    //     );
    // }
  };
  useEffect(() => {
    dispatch(fetchLogin());
    dispatch(fetchSiteSettings());
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let content = data.content;
  const intro = content;

  return (
    <>
      <section className="logon">
        <div className="contain">
          <div className="log_blk">
            <ToastContainer />
            <LoginForm
              data={intro}
              handleLogin={handleLogin}
              isFormProcessing={isFormProcessing}
              emptyFormData={emptyFormData}
              onSuccess={onSuccess}
              onFailure={onFailure}
              isGoogleProcessing={isGoogleProcessing}
            />
            <div className="haveAccount text-center">
              <span>Don’t have an account?</span>{" "}
              <Link to="/signup">Sign up</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
