import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";

import {
  getServerImage,
  imageValidation,
  projectImageUpload,
} from "../../../../helpers/api";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import http from "../../../../helpers/http";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";
import { NavLink } from "react-router-dom";

export default function AccountInfo({
  member,
  maintenance_profile_row,
  states,
  bank_accounts,
}) {
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const watchAllFields = watch();
  useEffect(() => {
    if (maintenance_profile_row?.id > 0) {
      setValue("fname", maintenance_profile_row?.fname);
      setValue("lname", maintenance_profile_row?.lname);
      setValue("email", maintenance_profile_row?.email);
      setValue("description", maintenance_profile_row?.description);
      setValue(
        "bank_account",
        maintenance_profile_row?.bank_account_encoded_id
      );
      setValue(
        "hourly_rate",
        maintenance_profile_row?.hourly_rate
          ? maintenance_profile_row?.hourly_rate
          : ""
      );
      setValue("phone", maintenance_profile_row?.phone);
      setThumbnail(maintenance_profile_row?.image);
      if (maintenance_profile_row?.trade_categories_arr?.length > 0) {
        setValue(
          "trade_categories",
          maintenance_profile_row?.trade_categories_arr
        );
      }
      if (maintenance_profile_row?.user_service_areas?.length > 0) {
        setCityState(maintenance_profile_row?.user_service_areas);
      }
      if (maintenance_profile_row?.citiesByState?.length > 0) {
        setCitiesByState(maintenance_profile_row?.citiesByState);
      }
    }
  }, [maintenance_profile_row]);

  let logo = null;
  function uploadLogo() {
    logo.click();
  }
  const [imageThumbnail, setThumbnail] = useState(null);
  const handleUpload = async (e) => {
    setThumbnail("loading.gif");
    const files = e.target.files[0];
    let valid = imageValidation(files);
    if (valid.error) {
      toast.error(valid.error);
    } else {
      let image = await projectImageUpload(files, "members");
      if (image.status === 1) {
        setThumbnail(image.image_name);
      } else {
        toast.error(valid.error);
      }
    }
  };
  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      image: imageThumbnail ? imageThumbnail : "",
      trade_categories: JSON.stringify(frmData?.trade_categories),
      city_states: JSON.stringify(city_states),
      bank_account: frmData?.bank_account?.value
        ? frmData?.bank_account?.value
        : frmData?.bank_account,
    };
    setIsFormProcessing(true);
    http
      .post("update-maintenance-profile", helpers.doObjToFormData(frmData))
      .then(({ data }) => {
        setIsFormProcessing(false);
        if (data.status) {
          toast.success(data?.msg);
        } else {
          toast.error(<Text string={data.msg} parse={true} />);
        }
      })
      .catch((error) => {
        setIsFormProcessing(false);
        helpers.consoleLog(error);
        // toast.error(error);
        return;
      });
  };

  const [city_states, setCityState] = useState([]);
  const [citiesByState, setCitiesByState] = useState({});
  const [isCityLoading, setIsCityLoading] = useState(false);
  const addFormBlock = () => {
    setCityState([...city_states, { state: null, city: "" }]);
  };
  const removeFormBlock = (index) => {
    const updatedcity_states = city_states.filter((_, i) => i !== index);
    setCityState(updatedcity_states);
  };

  const updateFormBlock = (index, key, value) => {
    if (key === "state") {
      // Clear current city options for the specific row and reset the selected city
      setCitiesByState((prev) => ({
        ...prev,
        [index]: [], // Reset city dropdown options
      }));

      // Clear the selected city when the state changes
      const updatedcity_states = city_states.map((block, i) => {
        if (i === index) {
          return { ...block, [key]: value, city: null }; // Reset city value
        }
        return block;
      });
      setCityState(updatedcity_states);
    } else {
      // Handle other form fields
      const updatedcity_states = city_states.map((block, i) => {
        if (i === index) {
          return { ...block, [key]: value };
        }
        return block;
      });
      setCityState(updatedcity_states);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await http.post(
        `get-state-cities/${stateId}`,
        helpers.doObjToFormData({ state: stateId })
      );
      // if (response.data.status) {
      return response.data.cities;
      // } else {
      //   toast.error(<Text string={response.data.msg} parse={true} />);
      //   return [];
      // }
    } catch (error) {
      helpers.consoleLog(error);
      return [];
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset>
        {bank_accounts?.length <= 0 ? (
          <div className="alert alert-danger bankalertFlex">
            A bank account is required to create a service profile.
            <NavLink to="/add-payment-method" className="webBtn">
              <i className="fa fa-plus"></i> Bank Account
            </NavLink>
          </div>
        ) : (
          ""
        )}
        <div className="setting_blk">
          <div className="upLoadDp">
            <div className="ico">
              {imageThumbnail === "loading.gif" ? (
                <img src="/images/loading.gif" alt="" />
              ) : (
                <img
                  src={getServerImage(
                    "storage/members/",
                    imageThumbnail != null ? imageThumbnail : ""
                  )}
                  alt=""
                />
              )}
            </div>
            <div className="text-center">
              <button
                type="button"
                className="webBtn smBtn uploadImg"
                data-upload="dp_image"
                data-text="Change Photo"
                onClick={uploadLogo}
              >
                Upload Photo
              </button>
              <input
                type="file"
                name=""
                id=""
                className="uploadFile"
                data-upload="dp_image"
                accept="image/*"
                ref={(file) => (logo = file)}
                onChange={handleUpload}
              />
            </div>
            <div className="noHats text-center">(Please upload your photo)</div>
          </div>
          <hr />

          <div className="row form_row new_field_new">
            <div className="col-xs-6">
              <h6>First Name</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  className="txtBox"
                  defaultValue={member.mem_fname}
                  {...register("fname", {
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z ]*$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="fname"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Last Name</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  className="txtBox"
                  defaultValue={member.mem_lname}
                  {...register("lname", {
                    pattern: {
                      value: /^[a-zA-Z][a-zA-Z ]*$/,
                      message: "Invalid value",
                    },
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="lname"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Phone Number</h6>
              <div className="form_blk">
                <InputMask
                  mask="+1 (999) 999-9999"
                  name="phone"
                  id="phone"
                  className="txtBox"
                  autoComplete="none"
                  {...register("phone", {
                    required: "Required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Email Address</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="txtBox"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                      message: "Email format is not valid!",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>

            <div className="col-xs-6">
              <h6>Hourly Rate</h6>
              <div className="form_blk">
                <input
                  type="text"
                  name="hourly-rate"
                  id="hourly-rate"
                  className="txtBox"
                  {...register("hourly_rate", {
                    required: "Required",
                  })}
                />
                <span className="dollar_label">$</span>
                <ErrorMessage
                  errors={errors}
                  name="hourly_rate"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Bank Account</h6>
              <div className="form_blk ach_acc_wrap">
                <Controller
                  name="bank_account"
                  control={control}
                  {...register("bank_account", { required: "Required" })}
                  defaultValue={watchAllFields?.bank_account}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <Select
                      options={bank_accounts}
                      onChange={(val) => {
                        onChange(val?.value); // Update the field value when the selection changes
                      }}
                      value={bank_accounts.find(
                        (option) => option.value === value
                      )} // Match the selected value with the options
                      label="Select Bank Account"
                    />
                  )}
                />

                <ErrorMessage
                  errors={errors}
                  name="bank_account"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>

            <div className="col-xs-12">
              <h6>Description</h6>
              <div className="form_blk">
                <textarea
                  name="description"
                  id="description"
                  className="txtBox"
                  {...register("description", {
                    required: "Required",
                  })}
                ></textarea>
                <ErrorMessage
                  errors={errors}
                  name="description"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
                {/* <div className="bTn text-right pt_2"><button className=" colorBtn webBtn mini_web_btn" type="button"><i className="fa fa-plus"></i></button></div> */}
              </div>
            </div>

            <div className="col-xs-12">
              <div className="maintenance_new_clr">
                <h5>Services</h5>
                <div className="form_blk">
                  <div className="form_row row flex">
                    {[
                      { id: "handyman", label: "Handyman" },
                      { id: "electrician", label: "Electrician" },
                      { id: "plumber", label: "Plumber" },
                      { id: "hvac", label: "HVAC" },
                      { id: "painter", label: "Painter" },
                      { id: "locksmith", label: "Locksmith" },
                      { id: "landscaper", label: "Landscaper" },
                      { id: "pest-control", label: "Pest Control" },
                    ].map((trade, index) => (
                      <div className="col-xs-4" key={index}>
                        <div className="form_blk">
                          <div className="lblBtn">
                            <input
                              type="checkbox"
                              name="trade_categories"
                              value={trade.id}
                              id={trade.id}
                              {...register("trade_categories", {
                                required: "Required",
                              })}
                            />
                            <label htmlFor={trade.id}>{trade.label}</label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="trade_categories"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="maintenance_new_clr">
                <h5>Service Area</h5>
                {city_states.map((block, index) => (
                  <div className="new_blk_cost_add new_clr" key={index}>
                    <div className="inner_blk flex">
                      <div
                        className="closeBtn"
                        onClick={() => removeFormBlock(index)}
                      ></div>
                      <div className="inner_colL">
                        <div>
                          <Controller
                            name={`city_states[${index}].state`}
                            control={control}
                            defaultValue={block?.state}
                            rules={{ required: "Required" }}
                            render={({
                              field: { onChange, value, ref, name },
                            }) => (
                              <Select
                                options={states}
                                onChange={(val) => {
                                  onChange(val);
                                  updateFormBlock(index, "state", val);
                                }}
                                value={block?.state}
                                label="Select State"
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`city_states[${index}].state`}
                            render={({ message }) => (
                              <p className="error">
                                <i className="fi-warning"></i> {message}
                              </p>
                            )}
                          />
                        </div>
                      </div>
                      <div className="inner_colR">
                        <Controller
            name={`city_states[${index}].city`}
            control={control}
            defaultValue={block?.city}
            rules={{ required: "Required" }}
            render={({ field: { onChange, value, ref, name } }) => (
              <input
                type="text"
                onChange={(e) => {
                  onChange(e.target.value);
                  updateFormBlock(index, "city", e.target.value);
                }}
                value={block?.city || ""}
                placeholder="Enter City"
                className="input txtBox"
              />
            )}
          />
                          <ErrorMessage
                            errors={errors}
                            name={`city_states[${index}].city`}
                            render={({ message }) => (
                              <p className="error">
                                <i className="fi-warning"></i> {message}
                              </p>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                ))}

                <div className="bTn text-center pt_2">
                  <button
                    className=" colorBtn webBtn mini_web_btn"
                    type="button"
                    onClick={addFormBlock}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="webBtn"
              disabled={
                isFormProcessing ||
                imageThumbnail === "loading.gif" ||
                isCityLoading
              }
            >
              {maintenance_profile_row?.id > 0 ? "Update" : "Submit"}{" "}
              {isFormProcessing ||
              imageThumbnail === "loading.gif" ||
              isCityLoading ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}
