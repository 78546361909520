import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSingleFloorPlan,
  postFloorData,
} from "../../../states/actions/fetchSingleFloorPlan";
import { useSelector, useDispatch } from "react-redux";
// import { fetchSiteSettings } from '../../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import AddFloorBlk from "./sections/AddFloorBlk";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import Sidebar from "./sections/sidebar";
import { ToastContainer } from "react-toastify";
import {
  deleteFromObject,
  imageValidation,
  uploadImages,
} from "../../../helpers/api";

export default function AddFloorPlan() {
  const dispatch = useDispatch();
  const [imageThumbnail, setThumbnail] = useState(null);
  const { floor_id } = useParams();
  const [formStep, setFormStep] = useState(0);

  const [labels, setLabels] = useState({
    labels: [],
  });
  const data = useSelector((state) => state.fetchSingleFloorPlan.content);
  const isLoading = useSelector(
    (state) => state.fetchSingleFloorPlan.isLoading
  );
  const [images, setImages] = useState({ loading: false });
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleFloorPlan.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleFloorPlan(floor_id));
  }, []);
  useEffect(() => {
    setImages(data.floor_row?.all_images);
    setLabels({
      labels:
        data.floor_row?.all_labels !== undefined
          ? data.floor_row?.all_labels
          : [],
    });
    setThumbnail(data.floor_row?.imageThumbnail);
  }, [data]);

  async function uploadFloorImages(e, label) {
    setImages({ ...images, loading: true });
    let uploadedImages = await uploadImages(e);
    {
      images !== undefined &&
      images !== null &&
      images !== "" &&
      label !== undefined &&
      label !== null &&
      label !== "" &&
      images[label] !== undefined &&
      images[label] !== null
        ? setImages({
            ...images,
            [label]: images[label].concat(uploadedImages),
            loading: false,
          })
        : setImages({ ...images, [label]: uploadedImages, loading: false });
    }
    // setImages({ ...images, [label]: uploadedImages, loading: false });
  }

  const changeStep = (step) => {
    setFormStep(step);
  };
  useEffect(() => {}, [images]);
  useEffect(() => {}, [labels]);
  useEffect(() => {}, [formStep]);
  const handleAddLabel = () => {
    setLabels({ ...labels, labels: labels.labels.concat([{ label: "" }]) });
  };
  function handleRemoveLabel(idx, label) {
    let newImages = deleteFromObject(label?.label, images);
    setLabels({
      ...labels,
      labels: labels.labels.filter((s, sidx) => idx !== sidx),
    });
    setImages(newImages);
  }
  function handleRemoveLabelImage(idx, label) {
    setImages({
      ...images,
      [label]: images[label].filter((s, sidx) => idx !== sidx),
    });
  }
  const handleLabelChange = (idx, evt) => {
    const newLabels = labels.labels.map((label, sidx) => {
      if (idx !== sidx) return label;
      return { ...label, label: evt.target.value };
    });
    setLabels({ ...labels, labels: newLabels });
  };

  const handleSubmit = (data) => {
    dispatch(postFloorData(data, floor_id));
  };

  useDocumentTitle(
    data != undefined &&
      data != null &&
      data.floor_row != undefined &&
      data.floor_row !== null
      ? "Edit Floor Plan - Loftus"
      : "Add Floor Plan - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const amenities = data.amenities;
  const branches = data.branches;
  const floor_plan_row = data.floor_row;
  const features = data?.features;
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="floor-plans" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    floor_plan_row !== "" &&
                    floor_plan_row !== null &&
                    floor_plan_row !== undefined
                      ? "Edit Floor Plan"
                      : "Add Floor Plan"
                  }
                  parent_name="Floor Plans"
                  parent_link="/floor-plans"
                />
                <AddFloorBlk
                  changeStep={changeStep}
                  step={formStep}
                  branches={branches}
                  amenities={amenities}
                  images={images}
                  uploadFloorImages={uploadFloorImages}
                  handleAddLabel={handleAddLabel}
                  handleLabelChange={handleLabelChange}
                  labels={labels}
                  handleRemoveLabelImage={handleRemoveLabelImage}
                  imageThumbnail={imageThumbnail}
                  setThumbnail={setThumbnail}
                  isFormProcessing={isFormProcessing}
                  handleFloorSubmit={handleSubmit}
                  floor_plan_row={floor_plan_row}
                  features={features}
                  handleRemoveLabel={handleRemoveLabel}
                  setImages={setImages}
                  setLabels={setLabels}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
