import React from "react";
import Breadcrumb from "./sections/breadcrumb";
import { ToastContainer } from "react-toastify";
import ProfileTopBar from "./sections/profile-topbar";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { updateMemberQuestions } from "../../../states/actions/profileSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import QuestionsForm from "./sections/questions-form";
export default function SecurityQuestions() {
  const handleQuestionForm = (data) => {
    dispatch(updateMemberQuestions(data));
  };
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.profileSettings.isFormProcessing
  );
  const questionAdded = useSelector(
    (state) => state.profileSettings.questionAdded
  );
  useDocumentTitle("Security Questions - Loftus");
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isSiteSettingsLoading) return <LoadingScreen />;
  const security_questions = siteSettings?.member?.security_questions;
  return (
    <>
      <div index="" className="main_dashboard">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb page_name="Security Questions" />
            <div className="page_body account_settings_flex">
              <ProfileTopBar isActive="security-questions" />
              <div className="dash_form_blk">
                <QuestionsForm
                  handleQuestionForm={handleQuestionForm}
                  isFormProcessing={isFormProcessing}
                  security_questions={security_questions}
                  questionAdded={questionAdded}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
