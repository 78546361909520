import React, { useEffect } from "react";
import { getServerImage } from '../../helpers/api';

import { fetchContact, postContact } from "../../states/actions/fetchContact";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import ContactIntro from './section/contac-intro';

export default function Contact() {
    const dispatch = useDispatch();
    const handleContact = (formData) => {
        dispatch(postContact(formData));
    };
    const isFormProcessing = useSelector(
        (state) => state.fetchContact.isFormProcessing
    );
    const emptyFormData = useSelector(
        (state) => state.fetchContact.emptyFormData
    );
    const data = useSelector((state) => state.fetchContact.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchContact.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchContact());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const contact = content;

    return (
        <>
            <ContactIntro data={contact.intro} handleContact={handleContact} isFormProcessing={isFormProcessing} emptyFormData={emptyFormData} />
        </>
    )
}
