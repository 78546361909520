import React from "react";

export default function BillingSort({ branches, handleBranchChange, properties, billingType, changeType, pending_count, posted_count }) {
  return <>
    <div className="flterFlexListing flex">
      <ul className="nav nav-tabs">
        {
          pending_count > 0 ?
            <li className={billingType === "pending" ? 'active' : ''}><a className="a" data-toggle="tab" href="#" onClick={() => changeType('pending')}>
              Pending <span>({pending_count !== undefined && pending_count > 0 ? pending_count : 0})</span></a>
            </li>
            :
            ""
        }
        {
          posted_count > 0 ?

            <li className={billingType === "posted" ? 'active' : ''}><a className='' data-toggle="tab" href="#" onClick={() => changeType('posted')}>
              Posted <span>({posted_count !== undefined && posted_count > 0 ? posted_count : 0})</span></a>
            </li>
            :
            ""
        }
      </ul>
      {/* {
        pending_count > 0 || posted_count > 0 ? */}
      <div className="filter_status_offer cell_status_offer_cell">
        <div className="col_ofer_filter">
          <div className="sorted_heading">
            <span>Branch :</span>
          </div>
          <div className="sorted_drop_down">
            <select name="branch_id" id="branch_id" className="txtBox" onChange={(e) => handleBranchChange(e, 'branch_id')}>
              <option value="all">All</option>
              {branches?.map((branch, index) => {
                return <option value={branch?.value}>{branch?.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="col_ofer_filter">
          <div className="sorted_heading">
            <span>Property :</span>
          </div>
          <div className="sorted_drop_down">
            <select name="property_id" id="property_id" className="txtBox" onChange={(e) => handleBranchChange(e, 'property_id')}>
              <option value="all">All</option>
              {properties?.map((property, index) => {
                return <option value={property?.value}>{property?.label}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      {/* :
          ""
      } */}
    </div>

  </>;
}
