import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ToastContainer } from "react-toastify";
import MaintenanceForm from "../maintenance_team/form";

export default function InvitePopup({closeInvitePopup,properties}) {
    
  return (
    <>
      <section className="popup invite_popups_all small_popup">
        <ToastContainer />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={closeInvitePopup}></div>
              <MaintenanceForm properties={properties} is_staff_edit_popup={false} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
