import React from "react";

export default function HouseOperations({ branch_contact_row }) {
  return branch_contact_row?.id > 0 &&
    (branch_contact_row?.open_hours?.length > 0 ||
      branch_contact_row?.concierge_hours?.length > 0) ? (
    <>
      {/* {branch_contact_row?.open_hours?.length > 0 &&
      branch_contact_row?.concierge_hours?.length > 0 ? (
        <h3 className="detail_heading">Hours of Operation</h3>
      ) : (
        <h3 className="detail_heading text-center">Hours of Operation</h3>
      )} */}

      <div className="search_expense_com search_expense_com_main">
        {branch_contact_row?.open_hours?.length > 0 ? (
          <div className="col colL">
            <div className="flex contact_operation_flex">
              <h5>Office Hours</h5>
              <p>{branch_contact_row?.office_email}</p>
            </div>

            <>
              {/* <h5>Office Hours</h5> */}
              <div className="inner">
                {branch_contact_row?.open_hours?.map((open_hour, index) => {
                  return (
                    <div className="balance_lst" key={index}>
                      <div>{open_hour?.day}</div>
                      <div>
                        {open_hour?.start_time} - {open_hour?.end_time}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          </div>
        ) : (
          ""
        )}
        {branch_contact_row?.concierge_hours?.length > 0 ? (
          <div className="col colL">
            <div className="flex contact_operation_flex">
              <h5>Concierge Hours</h5>
              <p>{branch_contact_row?.concierge_email}</p>
            </div>
            <>
              {/* <h5>Concierge Hours</h5> */}
              <div className="inner">
                {branch_contact_row?.concierge_hours?.map(
                  (concierge_hour, index) => {
                    return (
                      <div className="balance_lst" key={index}>
                        <div>{concierge_hour?.day}</div>
                        <div>
                          {concierge_hour?.start_time} -{" "}
                          {concierge_hour?.end_time}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          </div>
        ) : (
          ""
        )}
      </div>
      <hr />
    </>
  ) : (
    ""
  );
}
