import React, { useState } from 'react'

export default function SchoolSummary() {
    
    return (
        <>
        <h3 className="detail_heading">Schools</h3>
        <p className='mini_text'>Great Schools Summary Rating</p>
        <div className='outer_great_school equal_top'>
            <ul>
                <li>
                    <h4>6/10</h4>
                    <div className='ps_number'>
                        <h5>PS 212</h5>
                        <p>Public, PreK-5 • Serves this home • 0.0mi</p>
                    </div>
                </li>
                <li>
                    <h4>7/10</h4>
                    <div className='ps_number'>
                        <h5>IS 145 Joseph Pulitzer</h5>
                        <p>Public, 6-8 • Serves this home • 0.1mi</p>
                    </div>
                </li>
                <li>
                    <h4>3/10</h4>
                    <div className='ps_number'>
                        <h5>Newtown High School</h5>
                        <p>Public, 9-12 • Serves this home • 1.0mi</p>
                    </div>
                </li>
            </ul>
        </div>
        </>
    )
}
