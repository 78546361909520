import React from 'react'
import Image from '../../common/image';

export default function HowItWorks({ data }) {
    return (
        <>
            <section className="how-it-work">
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <div className="sec_heading">
                                <h2>{data.heading}</h2>

                                <a href="#!" className="cstm_more_read popBtn" data-popup="video"><span>{data.video_text}</span> <i className="fi-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="colR">
                            {data.blocks?.map((val, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <div className="big_step">{index + 1}</div>
                                        <div className="image">
                                            <Image src={val.icon} alt={val.heading} />
                                        </div>
                                        <h4>{val.heading}</h4>
                                        <p>{val.text}</p>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
