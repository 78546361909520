import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  GET_LEASES,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAILED,
  GET_PROPERTY_OFFERS,
  GET_PROPERTY_OFFERS_FAILED,
  GET_PROPERTY_OFFERS_SUCCESS,
  GET_PROPERTY_OFFER_DATA,
  GET_PROPERTY_OFFER_DATA_SUCCESS,
  GET_PROPERTY_OFFER_DATA_FAILED,
  CREATE_LEASE,
  CREATE_LEASE_FAILED,
  CREATE_LEASE_SUCCESS,
  FETCH_LEASE_DETAILS,
  FETCH_LEASE_DETAILS_SUCCESS,
  FETCH_LEASE_DETAILS_FAILED,
  LEASE_ACTION_TENANT,
  LEASE_ACTION_TENANT_SUCCESS,
  LEASE_ACTION_TENANT_FAILED,
  GET_PROPERTY_OFFERS_EMPTY,
  GET_FILTER_LEASES,
  GET_FILTER_LEASES_SUCCESS,
  GET_FILTER_LEASES_FAILED,
  GET_BRANCH_PROPERTIES,
  GET_BRANCH_PROPERTIES_FAILED,
  GET_BRANCH_PROPERTIES_SUCCESS,
  TERMINATE_LEASE,
  TERMINATE_LEASE_SUCCESS,
  TERMINATE_LEASE_FAILED,
  TERMINATE_LEASE_CANCEL,
  TERMINATE_LEASE_CANCEL_FAILED,
  TERMINATE_LEASE_CANCEL_SUCCESS,
  WITHDRAW_LEASE,
  WITHDRAW_LEASE_SUCCESS,
  WITHDRAW_LEASE_FAILED,
} from "./actionTypes";

export const getLeases = (formData) => (dispatch) => {
  dispatch({
    type: GET_LEASES,
    payload: null,
  });
  http
    .post("my-leases", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_LEASES_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_LEASES_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_LEASES_FAILED,
        payload: error,
      });
    });
};
export const getFilteredLeases = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_LEASES,
    payload: null,
  });
  http
    .post("my-leases", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_LEASES_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_LEASES_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_LEASES_FAILED,
        payload: error,
      });
    });
};
export const emptyPropertyOffersArr = () => (dispatch) => {
  dispatch({
    type: GET_PROPERTY_OFFERS_EMPTY,
  });
};
export const getPropertyOffers = (formData) => (dispatch) => {
  let postUrl = "my-property-offers";
  if (formData?.type === "branch") {
    postUrl = "my-branch-property-offers";
  }
  dispatch({
    type: GET_PROPERTY_OFFERS,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_PROPERTY_OFFERS_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_PROPERTY_OFFERS_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PROPERTY_OFFERS_FAILED,
        payload: error,
      });
    });
};
export const getBranchProperties = (formData) => (dispatch) => {
  dispatch({
    type: GET_BRANCH_PROPERTIES,
    payload: null,
  });
  http
    .post("branch-properties", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_BRANCH_PROPERTIES_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_BRANCH_PROPERTIES_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_BRANCH_PROPERTIES_FAILED,
        payload: error,
      });
    });
};
export const getPropertyAndOfferData = (formData) => (dispatch) => {
  dispatch({
    type: GET_PROPERTY_OFFER_DATA,
    payload: null,
  });
  http
    .post("property-offer-data", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_PROPERTY_OFFER_DATA_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_PROPERTY_OFFER_DATA_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_PROPERTY_OFFER_DATA_FAILED,
        payload: error,
      });
    });
};
export const createPropertyLease = (formData, lease_id) => (dispatch) => {
  let post_url = "create-lease";
  if (lease_id !== undefined && lease_id !== null && lease_id !== "") {
    post_url = "update-lease/" + lease_id;
  }
  dispatch({
    type: CREATE_LEASE,
    payload: null,
  });
  http
    .post(post_url, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: CREATE_LEASE_SUCCESS,
          payload: data,
        });
        setTimeout(() => {
          window.location.replace("/leases");
        }, 2000);
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: CREATE_LEASE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CREATE_LEASE_FAILED,
        payload: error,
      });
    });
};
export const tenantLeaseAction = (formData) => (dispatch) => {
  dispatch({
    type: LEASE_ACTION_TENANT,
    payload: null,
  });
  http
    .post("tenant-lease-action", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/leases");
        }, 2000);
        dispatch({
          type: LEASE_ACTION_TENANT_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: LEASE_ACTION_TENANT_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CREATE_LEASE_FAILED,
        payload: error,
      });
    });
};
export const saveTerminateLease = (formData) => (dispatch) => {
  dispatch({
    type: TERMINATE_LEASE,
    payload: null,
  });
  http
    .post("terminate-lease", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        dispatch({
          type: TERMINATE_LEASE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: TERMINATE_LEASE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TERMINATE_LEASE_FAILED,
        payload: error,
      });
    });
};
export const withdrawLease = (formData) => (dispatch) => {
  dispatch({
    type: WITHDRAW_LEASE,
    payload: null,
  });
  http
    .post("withdraw-lease", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        setTimeout(() => {
          setTimeout(() => {
            window.location.replace("/leases");
          }, 2000);
        }, 2000);
        dispatch({
          type: WITHDRAW_LEASE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: WITHDRAW_LEASE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: WITHDRAW_LEASE_FAILED,
        payload: error,
      });
    });
};
export const cancelTerminateLease = (formData) => (dispatch) => {
  dispatch({
    type: TERMINATE_LEASE_CANCEL,
    payload: null,
  });
  http
    .post("terminate-lease-cancel", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        dispatch({
          type: TERMINATE_LEASE_CANCEL_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: TERMINATE_LEASE_CANCEL_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: TERMINATE_LEASE_CANCEL_FAILED,
        payload: error,
      });
    });
};
export const fetchLeaseDetails =
  (lease_id, tenant_lease = false) =>
    (dispatch) => {
      let getUrl = "my-leases-details/" + lease_id;
      if (tenant_lease) {
        getUrl = "tenant-leases-details/" + lease_id;
      }
      dispatch({
        type: FETCH_LEASE_DETAILS,
        payload: null,
      });
      http
        .get(getUrl)
        .then(({ data }) => {
          if (data?.status === 1) {
            dispatch({
              type: FETCH_LEASE_DETAILS_SUCCESS,
              payload: data,
            });
          } else {
            // setTimeout(() => {
            //     window.location.replace("/leases");
            // }, 2000);
          }
        })
        .catch((error) => {
          dispatch({
            type: FETCH_LEASE_DETAILS_FAILED,
            payload: error,
          });
        });
    };
