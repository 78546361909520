import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import MaintenanceReviewReply from "./maintenanceReviewReply";
export default function ReviewSingle({
  item,
  profile_id,
  setReviewsArr,
  maintenance_user,
  reply,
  setReply
}) {
  
  const toggleReply = (review_id) => {
    setReply(reply!==null ? reply===review_id ? null : review_id : review_id);
  };
  const closeReply = () => {
    setReply(null);
  };
  return (
    <li>
      <div className="image_icon">
        <img src={item?.user_dp} alt={item?.user_name} />
      </div>
      <div className="client_info flex">
        <div className="intro_review_left">
          <h4>{item?.user_name}</h4>
          <h6>{item?.time}</h6>
        </div>
        <div className="rating_stars_right">
          <StarRatings
            rating={item?.ratings}
            starDimension="15px"
            starSpacing="1px"
            starRatedColor="orange"
          />
        </div>
      </div>
      <div className="_comment">
        <p>{item?.review}</p>
      </div>
      {maintenance_user || item?.replies?.length >= 0 ? (
        <div className="like_reply flex">
          <div className="_col">
            <ul className="flex">
              {/* <li>
              <a href="">
                <i className="fi-thumbs-up"></i>
              </a>
            </li>
            <li>
              <a href="">
                <i className="fi-thumbs-down"></i>
              </a>
            </li> */}
              {item?.replies?.length <= 0 && maintenance_user ? (
                <li>
                  <a
                    href="#!"
                    onClick={() => toggleReply(item?.id)}
                    className="reply_link"
                  >
                    Reply
                  </a>
                </li>
              ) : (
                item?.replies?.map((reply, r_index) => {
                  return (
                    <li key={r_index} className="reply">
                      <div className="image_icon">
                        <img src={reply?.user_dp} alt={reply?.user_name} />
                      </div>
                      <div className="client_info flex">
                        <div className="intro_review_left">
                          <h4>{reply?.user_name}</h4>
                          <h6>{reply?.time}</h6>
                        </div>
                      </div>
                      <div className="_comment">
                        <p>{reply?.reply}</p>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}
      {reply === item?.id ? (
        <MaintenanceReviewReply
          review_id={reply}
          profile_id={profile_id}
          setReviewsArr={setReviewsArr}
          closeReply={closeReply}
        />
      ) : (
        ""
      )}
    </li>
  );
}
