import React, { useState, useEffect } from "react";

import "react-datepicker/dist/react-datepicker.css";
import Breadcrumb from './sections/breadcrumb'
import ProfileTopBar from './sections/profile-topbar'
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { imageValidation } from '../../../helpers/api';
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { profileSettings, updateProfileImage, updateProfileData } from "../../../states/actions/profileSettings";
import ProfileForm from './sections/profile-form';

export default function ProfileSettings() {

    const handleProfile = data => {
        dispatch(updateProfileData(data))
    }
    const dispatch = useDispatch();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    const handleUpload = (e) => {
        const files = e.target.files[0];
        let valid = imageValidation(files);
        if (valid.error) {
            toast.error(valid.error,
                TOAST_SETTINGS
            );
        }
        else {
            dispatch(updateProfileImage(files));
        }
    };
    const isFormImageProcessing = useSelector(
        (state) => state.profileSettings.isFormImageProcessing
    );
    const isFormProcessing = useSelector(
        (state) => state.profileSettings.isFormProcessing
    );
    const profileImage = useSelector(
        (state) => state.profileSettings.image.image_name
    );
    useEffect(() => {
        dispatch(profileSettings());
    }, []);


    useDocumentTitle('Account Details - Loftus');
    if (isSiteSettingsLoading) return <LoadingScreen />;
    const member = siteSettings?.member;
    const countries = siteSettings.countries;
    const states = siteSettings.states;
    if (member?.mem_verified === 0) {
        return window.location.replace("/dashboard");
    }

    return (
        <>
            <div index="" className="main_dashboard">
                <ToastContainer />
                <section className="dashboard">
                    <div className="contain">
                        <Breadcrumb page_name="Account Details" />
                        <div className="page_body account_settings_flex">
                            <ProfileTopBar isActive="profile-settings" />
                            <div className="dash_form_blk">
                                <ProfileForm handleProfile={handleProfile} handleUpload={handleUpload} isFormImageProcessing={isFormImageProcessing} isFormProcessing={isFormProcessing} profileImage={profileImage} member={member} countries={countries} states={states} />
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}
