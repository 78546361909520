import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import {
  fetchUserPropertyDetails,
  deleteProperty,
} from "../../../states/actions/fetchUserPropertyDetails";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import { postData, projectUrl } from "../../../helpers/api";
import Error from "../Error";
import NormalUserProperty from "../single-property/normal";

export default function SingleUserProperty() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchUserPropertyDetails.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchUserPropertyDetails.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteProperty(data));
  };
  useEffect(() => {
    dispatch(fetchUserPropertyDetails(slug));
  }, []);

  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const [reviewPopup, setReviewPopup] = useState({
    item: null,
    show: false,
  });
  const [reviewLoading, setLoading] = useState(false);
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  const showReviewPopup = (item) => {
    setReviewPopup({ ...reviewPopup, item: item, show: true });
    // alert(!popup);
  };
  const closeReviewPopup = () => {
    setReviewPopup({ ...reviewPopup, item: null, show: false });
  };
  const property_url = projectUrl("/property/" + slug);
  const handleListingReview = async (form_data) => {
    if (
      data?.member !== undefined &&
      data?.member !== null &&
      data?.member?.id > 0
    ) {
      if (data?.member?.id !== data.property_row?.mem_id) {
        setLoading(true);
        await postData(
          "api/property-review-save/" + data.property_row?.listing?.id,
          form_data
        ).then((res) => {
          setLoading(false);
          if (res.status === 1) {
            toast.success(res.msg, TOAST_SETTINGS);

            setTimeout(() => {
              setReviewPopup({ ...reviewPopup, item: null, show: false });
            }, 2000);
          } else {
            toast.error(res.msg, TOAST_SETTINGS);
          }
        });
      } else {
        toast.error(
          "As an owner of property you can not submit review for this property!",
          TOAST_SETTINGS
        );
        return;
      }
    } else {
      toast.error("Please login to submit your review!", TOAST_SETTINGS);
      return;
    }
  };
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let property_row = data.property_row;
  let page_title = data.page_title;
  let branch = data.branch;
  let member = data?.member;
  let walkscore = data?.property_row?.walkscore;
  let transitscore = data?.property_row?.transitscore;
  let media = data.property_row?.media;
  let properties = data?.properties;
  return (
    <>
      <NormalUserProperty
        address={page_title}
        property={property_row}
        branch={branch}
        popup={popup}
        closePopup={closePopup}
        showPopup={showPopup}
        member={member}
        handleDelete={handleDelete}
        showReviewPopup={showReviewPopup}
        reviewPopup={reviewPopup}
        closeReviewPopup={closeReviewPopup}
        handleListingReview={handleListingReview}
        reviewLoading={reviewLoading}
        property_url={property_url}
        property_page={true}
        owner={true}
        properties={properties}
        walkscore={walkscore}
      />
    </>
  );
}
