import React from "react";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lightgallery-bundle.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgZoom from "lightgallery/plugins/zoom";
import PopupImage from "../../dashboard-company/sections/popupImage";
import FloorPopup from "../../dashboard-company/sections/floorPopup";
import { getServerImage } from "../../../../helpers/api";
import MediaPopup from "../../dashboard-company/sections/media/media-popup";
export default function PropertyGallery({
  property,
  images,
  popup,
  closePopup,
  showPopup,
  thumbnail,
  path = "storage/floor_plans/",
  type,
}) {
  return (
    <>
      <div className="result_gallery">
        <div className="imgGallery flex">
          <LightGallery
            speed={500}
            plugins={[lgThumbnail, lgZoom, lgFullscreen]}
            // showZoomInOutIcons={true}
            thumbnail={true}
          >
            <>
              {thumbnail !== undefined &&
              thumbnail !== "" &&
              thumbnail !== null ? (
                <PopupImage
                  image={getServerImage(path, thumbnail)}
                  className="col"
                />
              ) : (
                ""
              )}
              {images?.map((image, index) => {
                return (
                  <PopupImage
                    image={image?.image}
                    key={index}
                    className="col"
                  />
                );
              })}
            </>
          </LightGallery>
        </div>
        {images?.length > 0 ? (
          <div className="bTn text-center">
            <a
              href="javascript:void(0)"
              className="webBtn full_pop_btn"
              data-popup="gallery-pop"
              onClick={() => showPopup(property)}
            >
              <i className="fa fa-th"></i> Images
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
      {popup.show === true &&
      popup.item != undefined &&
      popup.item != null &&
      popup.item != "" ? (
        type === "commercial" ? (
          popup?.item?.media !== undefined &&
          popup?.item?.media !== null &&
          popup?.item?.media !== "" ? (
            <MediaPopup closePopup={closePopup} item={popup?.item?.media} />
          ) : (
            <FloorPopup
              title={popup?.item?.popup_title}
              address={popup?.item?.address}
              images={popup.item.all_images}
              closePopup={closePopup}
              property={
                property?.property !== undefined ? property?.property : property
              }
              floor_plan_item={true}
            />
          )
        ) : (
          <FloorPopup
            title={popup?.item?.popup_title}
            address={popup?.item?.popup_address}
            images={popup.item.all_images}
            closePopup={closePopup}
            property={
              property?.property !== undefined ? property?.property : property
            }
          />
        )
      ) : (
        ""
      )}
    </>
  );
}
