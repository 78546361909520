import React, { useState, useRef, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { GoogleLogin } from "react-google-login";
export default function SignupForm({
  data,
  handleSignup,
  isFormProcessing,
  emptyFormData,
  handleVerifyOtp,
  onSuccess,
  onFailure,
}) {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    handleSignup(data);
  };

  return (
    <>
      <form
        action=""
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="none"
      >
        <h3>{data.heading}</h3>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            First Name
          </label>
          <input
            type="text"
            name="fname"
            id="fname"
            className="txtBox"
            {...register("fname", {
              pattern: {
                value: /^[a-zA-Z][a-zA-Z ]*$/,
                message: "Invalid value",
              },
              required: "Required",
            })}
            autoComplete="none"
          />
          <ErrorMessage
            errors={errors}
            name="fname"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Last Name
          </label>
          <input
            type="text"
            name="lname"
            id="lname"
            className="txtBox"
            {...register("lname", {
              pattern: {
                value: /^[a-zA-Z][a-zA-Z ]*$/,
                message: "Invalid value",
              },
              required: "Required",
            })}
            autoComplete="none"
          />
          <ErrorMessage
            errors={errors}
            name="lname"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Email Address
          </label>
          <input
            type="text"
            name="email"
            id="email"
            className="txtBox"
            {...register("email", {
              required: "Required",
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                message: "Email format is not valid!",
              },
            })}
            autoComplete="off"
            autoSave="none"
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp">
          <label htmlFor="" className="move">
            Phone Number
          </label>
          {/* <input type="text" name="phone" id="phone" className='txtBox'  {...register("phone", {
            required: "Required"
          })} /> */}
          <InputMask
            mask="+1 (999) 999-9999"
            name="phone"
            id="phone"
            className="txtBox"
            autoComplete="none"
            {...register("phone", {
              required: "Required",
            })}
          />
          {/* <input type="text" name="phone" id="phone" className='txtBox'  {...register("phone", {
            required: "Required", pattern: {
              value: /^[0-9-]+$/,
              message: "Phone format is not valid!"
            }
          })} /> */}
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp pasDv">
          <label htmlFor="" className="move">
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="txtBox"
            {...register("password", {
              required: "Required",
              pattern: {
                value:
                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                message:
                  "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit.",
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp pasDv">
          <label htmlFor="" className="move">
            Confirm Password
          </label>
          <input
            type="password"
            name="confirm_password"
            id="confirm_password"
            className="txtBox"
            {...register("confirm_password", {
              required: "Required",
              validate: (val) => {
                if (watch("password") != val) {
                  return "Your passwords do no match";
                }
              },
            })}
          />
          <ErrorMessage
            errors={errors}
            name="confirm_password"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="txtGrp flex">
          <div className="lblBtn">
            <input
              type="checkbox"
              name="confirm"
              id="confirm"
              {...register("confirm", { required: "Required" })}
            />
            <label htmlFor="confirm">
              By signing up, I agree to Loftus'
              <Link to="/terms-conditions" target="_blank">
                Terms & Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacy-policy" target="_blank">
                Privacy Policy.
              </Link>
            </label>
          </div>
          <ErrorMessage
            errors={errors}
            name="confirm"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="bTn text-center">
          <button
            type="submit"
            className="webBtn blockBtn icoBtn popBtn"
            data-popup="show-phone"
            disabled={isFormProcessing === true ? "disabled" : ""}
          >
            {isFormProcessing === true ? (
              <i className="spinner"></i>
            ) : (
              "Sign up"
            )}
          </button>
        </div>

        <div className="oRLine">
          <span>OR</span>
        </div>
        <div className="socialBtn flex">
          {/* <button type="button" className="gmBtn"><Image src={data.google_img} alt="Sign in with Google" /> Sign in with Google</button> */}
          <GoogleLogin
            clientId="424406682782-ermmvl6s2uc4fnuif45gf3commgkbnuo.apps.googleusercontent.com"
            buttonText="Sign up with Google"
            className="gmBtn"
            onSuccess={onSuccess}
            onFailure={onFailure}
            // disabled={(isGoogleProcessing === true) ? 'disabled' : ''}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </form>
    </>
  );
}
