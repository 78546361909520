import React from "react";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function MediaLst({ item, showPopup, deleteItem }) {
  return (
    <>
      <tr>
        <td>
          <span className="mini-show-cell">Branch</span>
          {item?.branch}
        </td>
        <td>
          <span className="mini-show-cell">Images</span>
          <div className=" bTn">
            <a
              href="javascript:void(0)"
              className="small_web_btn full_pop_btn"
              data-popup="gallery-pop"
              onClick={() => showPopup(item)}
            >
              <i className="fa fa-th"></i> View Gallery
            </a>
          </div>
        </td>
        {mem_permissions === "admin" || mem_permissions === "power" ? (
          <td className="flex_action_user_dash hide_cell_hyp">
            <div className="action_drop _dropDown">
              <div className="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul className="_dropCnt dropLst">
                <li>
                  <NavLink to={"/edit-media/" + item?.id}>Edit</NavLink>
                </li>
                <li>
                  <NavLink
                    to="#!"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        deleteItem(item.id);
                    }}
                    onclick="return confirm('Are you sure?');"
                    className=""
                  >
                    Delete
                  </NavLink>
                </li>
              </ul>
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
    </>
  );
}
