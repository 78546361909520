import React, { useState, useEffect, useRef } from "react";
import AdvanceFilter from "../pages/popups/advance-filter";
import { useNavigate, useLocation } from "react-router-dom";
import MultiRangeSlider from "../common/multirangeslider/Index";
import { checkPattern, convertToDashesDate } from "../../helpers/api";
import { useForm } from "react-hook-form";
import RangeSlider from "../common/multirangeslider/range";
export default function Filters(props) {
  const bathRef = useRef(null);
  const bedRef = useRef(null);
  const propRef = useRef(null);
  const priceRef = useRef(null);
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let address =
    searchParams.get("address") !== null ? searchParams.get("address") : "";
  let amenities =
    searchParams.get("amenities") !== null &&
    searchParams.get("amenities")?.length > 0
      ? searchParams.get("amenities").split(",")
      : [];
  let features =
    searchParams.get("features") !== null &&
    searchParams.get("features")?.length > 0
      ? searchParams.get("features").split(",")
      : [];
  let mgt_type =
    searchParams.get("mgt_type") !== null &&
    searchParams.get("mgt_type")?.length > 0
      ? searchParams.get("mgt_type").split(",")
      : ["landlord", "multifamily"];
  let year = searchParams.get("year") !== null ? searchParams.get("year") : "";

  let property_type =
    searchParams.get("property_type") !== null &&
    searchParams.get("property_type")?.length > 0
      ? searchParams.get("property_type").split(",")
      : [];
  let available_date =
    searchParams.get("available_date") !== null
      ? new Date(searchParams.get("available_date"))
      : null;
  let sq_ft = searchParams.get("sq_ft");
  let lot_size =
    searchParams.get("lot_size") !== null ? searchParams.get("lot_size") : "";
  let laundry =
    searchParams.get("laundry") !== null ? searchParams.get("laundry") : "";
  let min_beds =
    searchParams.get("min_beds") !== null ? searchParams.get("min_beds") : 0;
  let max_beds =
    searchParams.get("max_beds") !== null ? searchParams.get("max_beds") : 10;
  let min_baths =
    searchParams.get("min_baths") !== null ? searchParams.get("min_baths") : 0;
  let max_baths =
    searchParams.get("max_baths") !== null ? searchParams.get("max_baths") : 10;
  let sort_by =
    searchParams.get("sort_by") !== null ? searchParams.get("sort_by") : "asc";
  let sort_by_field =
    searchParams.get("sort_by_field") !== null
      ? searchParams.get("sort_by_field")
      : "listing_price";
  let min_price =
    searchParams.get("min_price") !== null ? searchParams.get("min_price") : 0;
  let max_price =
    searchParams.get("max_price") !== null
      ? searchParams.get("max_price")
      : searchParams.get("type") === "rent"
      ? 25000
      : 10000000;
  const {
    ToggleListView,
    ToggleGridView,
    total,
    type,
    searchProperties,
    amenities_arr,
    features_arr,
    listView,
    clearAddress,
    is_header_search,
    map_bounds_redux,
    updateLeafLetMapBounds,
    setIsMobSearch,
    isMobSearch,
  } = props;
  useEffect(() => {
    setValue("min_price", min_price);
    setValue("sort_by", sort_by);
    setValue("sort_by_field", sort_by_field);
    setValue("max_price", max_price);
    setValue("address", address);
    setValue("amenities", amenities);
    setValue("features", features);
    setValue("mgt_type", mgt_type);
    setValue("year", year);
    setValue("available_date", available_date);
    setValue("sq_ft", sq_ft);
    setValue("lot_size", lot_size);
    setValue("laundry", laundry);
    setValue("min_beds", min_beds);
    setValue("max_beds", max_beds);
    setValue("min_baths", min_baths);
    setValue("max_baths", max_baths);
    setValue("property_type", property_type);
    setValue("type", type);
  }, []);
  useEffect(() => {
    if (clearAddress && is_header_search === null) {
      setValue("address", "");
    }
  }, [clearAddress]);

  const [filter, setFilter] = useState({
    beds: false,
    property: false,
    baths: false,
    type: type,
    price: false,
  });
  const handleClosePopup = (event) => {
    if (
      !bedRef.current ||
      bedRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (
      !bathRef.current ||
      bathRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (
      !propRef.current ||
      propRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (
      !priceRef.current ||
      priceRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (event.target.classList[0] !== "_dropBtn") {
      setFilter({
        ...filter,
        beds: false,
        baths: false,
        property: false,
        price: false,
      });
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClosePopup);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClosePopup);
    };
  }, []);
  const handleFilter = (f) => {
    if (f == "beds") {
      setFilter({
        ...filter,
        [f]: !filter?.[f],
        baths: false,
        property: false,
        price: false,
      });
    }
    if (f == "baths") {
      setFilter({
        ...filter,
        [f]: !filter?.[f],
        beds: false,
        property: false,
        price: false,
      });
    }
    if (f == "property") {
      setFilter({
        ...filter,
        [f]: !filter?.[f],
        beds: false,
        baths: false,
        price: false,
      });
    }
    if (f == "price") {
      setFilter({
        ...filter,
        [f]: !filter?.[f],
        beds: false,
        baths: false,
        property: false,
      });
    }
  };
  // const handlePriceChange = (name, value, second_field_name, isPattern = false) => {

  //     if (isPattern) {
  //         const onlyNumbers = /^[0-9\b]+$/;
  //         if (checkPattern(value, onlyNumbers)) {
  //             setValue(name, value)
  //         }
  //     }
  //     else {
  //         setValue([name], parseFloat(value))
  //     }
  //     // searchParams.set(name, value);
  //     // searchParams.set([second_field_name], watchAllFields?.[second_field_name]);
  //     // navigate(`?${searchParams.toString()}`);
  // }
  const handlePriceChange = (prices, key) => {
    if (key === "prices") {
      setValue("min_price", parseFloat(prices[0]));
      setValue("max_price", parseFloat(prices[1]));
    }
    if (key === "beds") {
      setValue("min_beds", parseFloat(prices[0]));
      setValue("max_beds", parseFloat(prices[1]));
    }
    if (key === "baths") {
      setValue("min_baths", parseFloat(prices[0]));
      setValue("max_baths", parseFloat(prices[1]));
    }
  };
  const handleSortBy = () => {
    if (watchAllFields?.sort_by === "asc") {
      setValue("sort_by", "desc");
      saveSearch("sort_by", "desc");
    } else {
      setValue("sort_by", "asc");
      saveSearch("sort_by", "asc");
    }
  };
  const closeFilter = (type = "", value_to_set = "") => {
    if (type !== "" && value_to_set !== "") {
      // setFilter({ ...filter, [type]: false })
      if (type == "price") {
        setValue("min_price", value_to_set);
        setValue(
          "max_price",
          watchAllFields?.type === "buy" ||
            watchAllFields?.type === undefined ||
            watchAllFields?.type === null ||
            watchAllFields?.type === ""
            ? 10000000
            : watchAllFields?.type === "rent"
            ? 25000
            : 10000000
        );
        searchParams.delete(["min_price"]);
        searchParams.delete(["max_price"]);
      }
      if (type == "baths") {
        setValue("min_baths", value_to_set);
        setValue("max_baths", 10);
        searchParams.delete(["min_baths"]);
        searchParams.delete(["max_baths"]);
      }
      if (type == "beds") {
        setValue("min_beds", value_to_set);
        setValue("max_beds", 10);
        searchParams.delete(["min_beds"]);
        searchParams.delete(["max_beds"]);
      }
      if (type == "property") {
        setValue("property_type", value_to_set);
        searchParams.delete(["property_type"]);
      }
      if (type === "popup" && value_to_set === true) {
        setValue("year", null);
        setValue("available_date", null);
        setValue("sq_ft", null);
        setValue("lot_size", null);
        setValue("laundry", null);
        setValue("amenities", []);
        setValue("features", []);
        setValue("mgt_type", ["landlord", "multifamily"]);
        let deleteItems = [
          "year",
          "available_date",
          "sq_ft",
          "lot_size",
          "laundry",
          "amenities",
          "features",
          "mgt_type",
        ];
        deleteItems.forEach((item) => {
          searchParams.delete(item);
        });
      }
      navigate(`?${searchParams.toString()}`);
    }
    // setPopup(false);
    // searchProperties(watchAllFields)
  };

  const [popup, setPopup] = useState(false);
  const showPopup = () => {
    setPopup(!popup);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup(false);
  };
  const handleTypeChange = (e, type, value) => {
    e.preventDefault();
    updateLeafLetMapBounds();
    setValue(type, value);
    searchParams.set(type, value);

    watchAllFields.type = value;
    if (value === "rent") {
      if (parseFloat(max_price) > 25000) {
        watchAllFields.max_price = 25000;
        setValue("max_price", 25000);
        if (
          searchParams.get("max_price") !== null &&
          searchParams.get("max_price") !== undefined &&
          searchParams.get("max_price") !== ""
        ) {
          searchParams.set("max_price", 25000);
        }
      }
    } else if (value === "buy") {
      if (parseFloat(max_price) === 25000) {
        watchAllFields.max_price = 10000000;
        setValue("max_price", 10000000);
        if (
          searchParams.get("max_price") !== null &&
          searchParams.get("max_price") !== undefined &&
          searchParams.get("max_price") !== ""
        ) {
          searchParams.set("max_price", 10000000);
        }
      }
      // setValue('mgt_type', ["landlord", "multifamily"])
      // searchParams.set('mgt_type', ["landlord", "multifamily"])
      setValue("available_date", null);
      // searchParams.delete("mgt_type")
      searchParams.delete("available_date");
    }
    navigate(`?${searchParams.toString()}`);
    // setTimeout(() => {
    // executeApiCall(["landlord", "multifamily"], null)
    executeApiCall(false, null);
    // }, 1000);
  };
  const handleSort = (e, type, value) => {
    e.preventDefault();
    setValue(type, value);
    executeApiCall();
  };

  const executeApiCall = (mgt_type = false, available_date = false) => {
    if (address !== "" && address !== null && address !== undefined) {
      watchAllFields.address = address;
      watchAllFields.filter_with_boundary = true;
    } else {
      watchAllFields.filter_with_boundary = null;
    }
    if (mgt_type !== false && available_date !== false) {
      let newFilterData = {
        ...watchAllFields,
        available_date: null,
        mgt_type: mgt_type,
        address: address,
        is_header_search: is_header_search,
      };
      setTimeout(() => {
        searchProperties(newFilterData);
      }, 500);
    } else if (
      watchAllFields?.available_date !== null &&
      watchAllFields?.available_date !== "null" &&
      watchAllFields?.available_date !== undefined
    ) {
      let available_date = convertToDashesDate(watchAllFields?.available_date);
      let newFilterData = {
        ...watchAllFields,
        available_date: available_date,
        address: address,
        is_header_search: is_header_search,
      };
      setTimeout(() => {
        searchProperties(newFilterData);
      }, 500);
    } else {
      let newFilterData = {
        ...watchAllFields,
        address: address,
        is_header_search: is_header_search,
      };

      setTimeout(() => {
        searchProperties(newFilterData);
      }, 500);
    }
  };

  const saveSearch = (type = "", value = "") => {
    if (address !== "" && address !== null && address !== undefined) {
      watchAllFields.address = address;
      watchAllFields.filter_with_boundary = true;
    }
    Object.entries(watchAllFields)?.map(([key, value]) =>
      key === "mgt_type"
        ? Array.isArray(value) && value?.length > 0
          ? searchParams.set(key, value)
          : searchParams.delete(key)
        : Array.isArray(value)
        ? value?.length > 0
          ? searchParams.set(key, value)
          : searchParams.delete(key)
        : value !== undefined &&
          value !== null &&
          value !== "" &&
          value !== false
        ? searchParams.set(key, value)
        : searchParams.delete(key)
    );
    navigate(`?${searchParams.toString()}`);
    setFilter({
      ...filter,
      price: false,
      beds: false,
      baths: false,
      property: false,
    });
    setPopup(false);
    executeApiCall();
  };

  return (
    <>
      <div className="filter_nav">
        <div className="contain">
          <div className="filters flex filter_inner cell_inner_filters">
            <div className="filter_col relative">
              <div className="cmn_lnk_filter flex">
                <div className="check_cmn_filter_btn">
                  <div
                    className={
                      watchAllFields?.type === "buy"
                        ? "lblBtn active"
                        : "lblBtn"
                    }
                    onClick={(e) => handleTypeChange(e, "type", "buy")}
                  >
                    <input
                      type="radio"
                      name="status"
                      vllue="buy"
                      className="hidden"
                      id="buy"
                      defaultChecked={
                        watchAllFields?.type === "buy" ? true : false
                      }
                    />
                    <label htmlFor="buy">Buy</label>
                  </div>
                  <div
                    className={
                      watchAllFields?.type === "rent"
                        ? "lblBtn active"
                        : "lblBtn"
                    }
                    onClick={(e) => handleTypeChange(e, "type", "rent")}
                  >
                    <input
                      type="radio"
                      name="status"
                      value="rent"
                      className="hidden"
                      defaultChecked={
                        watchAllFields?.type === "rent" ? true : false
                      }
                      id="rent"
                    />
                    <label htmlFor="rent">Rent</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_col relative _dropDown">
              <button
                className="_dropBtn cmn_filter_btn"
                onClick={() => handleFilter("price")}
              >
                Price
              </button>
              <div
                className={
                  filter.price
                    ? "_dropCnt left_95 active bedsPopup"
                    : "_dropCnt left_95 bedsPopup"
                }
                ref={priceRef}
              >
                <div className="inner-drop">
                  {watchAllFields?.min_price !== undefined &&
                  watchAllFields?.max_price !== undefined ? (
                    watchAllFields?.type === "buy" ||
                    watchAllFields?.type === undefined ||
                    watchAllFields?.type === null ||
                    watchAllFields?.type === "" ? (
                      <RangeSlider
                        min={watchAllFields?.min_price}
                        max={watchAllFields?.max_price}
                        start={0}
                        end={10000000}
                        is_price={true}
                        icon={"$"}
                        register={register}
                        handleChange={handlePriceChange}
                        key_name="prices"
                      />
                    ) : (
                      <>
                        <RangeSlider
                          min={watchAllFields?.min_price}
                          max={watchAllFields?.max_price}
                          start={0}
                          end={25000}
                          is_price={true}
                          icon={"$"}
                          register={register}
                          handleChange={handlePriceChange}
                          key_name="prices"
                        />
                      </>
                    )
                  ) : (
                    ""
                  )}
                  <div className="filter_opt flex">
                    <button
                      className="save_opt"
                      onClick={() => saveSearch("price")}
                    >
                      Save
                    </button>
                    <button
                      className="clear_opt"
                      onClick={() => closeFilter("price", 0)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_col relative _dropDown">
              <button
                className="_dropBtn cmn_filter_btn"
                onClick={() => handleFilter("beds")}
              >
                Beds
              </button>
              <div
                className={
                  filter.beds
                    ? "_dropCnt active bedsPopup"
                    : "_dropCnt bedsPopup"
                }
                ref={bedRef}
              >
                <div className="inner-drop">
                  {watchAllFields?.min_beds !== undefined &&
                  watchAllFields?.max_beds !== undefined ? (
                    <RangeSlider
                      min={watchAllFields?.min_beds}
                      max={watchAllFields?.max_beds}
                      register={register}
                      handleChange={handlePriceChange}
                      key_name="beds"
                    />
                  ) : (
                    ""
                  )}

                  <div className="filter_opt flex">
                    <button
                      className="save_opt"
                      onClick={() => saveSearch("beds")}
                    >
                      Save
                    </button>
                    <button
                      className="clear_opt"
                      onClick={() => closeFilter("beds", 0)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_col relative _dropDown">
              <button
                className="_dropBtn cmn_filter_btn"
                onClick={() => handleFilter("baths")}
              >
                Baths
              </button>
              <div
                className={
                  filter.baths
                    ? "_dropCnt active bedsPopup"
                    : "_dropCnt bedsPopup"
                }
                ref={bathRef}
              >
                <div className="inner-drop">
                  {watchAllFields?.min_baths !== undefined &&
                  watchAllFields?.max_baths !== undefined ? (
                    <RangeSlider
                      min={watchAllFields?.min_baths}
                      max={watchAllFields?.max_baths}
                      register={register}
                      handleChange={handlePriceChange}
                      key_name="baths"
                    />
                  ) : (
                    ""
                  )}
                  <div className="filter_opt flex">
                    <button
                      className="save_opt"
                      onClick={() => saveSearch("baths")}
                    >
                      Save
                    </button>
                    <button
                      className="clear_opt"
                      onClick={() => closeFilter("baths", 0)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_col relative _dropDown">
              <button
                className="_dropBtn cmn_filter_btn"
                onClick={() => handleFilter("property")}
              >
                Property Type
              </button>
              <div
                className={filter.property ? "_dropCnt active" : "_dropCnt"}
                ref={propRef}
              >
                <div className="inner-drop">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Single Family"
                      id="Single Family"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Single Family"
                      )}
                    />
                    <label htmlFor="Single Family">Single Family</label>
                  </div>
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Townhouse"
                      id="Townhouse"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Townhouse"
                      )}
                    />
                    <label htmlFor="Townhouse">Townhouse</label>
                  </div>
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Condo"
                      id="Condo"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Condo"
                      )}
                    />
                    <label htmlFor="Condo">Condo</label>
                  </div>
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Apartment"
                      id="Apartment"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Apartment"
                      )}
                    />
                    <label htmlFor="Apartment">Apartment</label>
                  </div>
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Duplex"
                      id="Duplex"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Duplex"
                      )}
                    />
                    <label htmlFor="Duplex">Duplex</label>
                  </div>
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="property_type"
                      defaultValue="Other"
                      id="Other"
                      {...register("property_type")}
                      defaultChecked={watchAllFields?.property_type?.includes(
                        "Other"
                      )}
                    />
                    <label htmlFor="Other">Other</label>
                  </div>
                  <div className="filter_opt flex">
                    <button
                      className="save_opt"
                      onClick={() => saveSearch("property")}
                    >
                      Save
                    </button>
                    <button
                      className="clear_opt"
                      onClick={() => closeFilter("property", [])}
                    >
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter_col more_filters">
              <button
                className="cmn_filter_btn flex popBtn"
                data-popup="filter"
                onClick={showPopup}
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="presentation"
                  focusable="false"
                  style={{
                    display: "block",
                    height: "16px",
                    width: "16px",
                    fill: "currentcolor",
                  }}
                >
                  <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                </svg>
                <span>Filters</span>
              </button>
            </div>
          </div>
          <div className="result_header flex cell_result_header">
            <div className="count_result">
              <h6>{total} Search results</h6>
            </div>
            <div className="sorted_filter flex">
              <div className="sorted_heading">
                <i className="fa fa-align-left" />
                <span>Sort by :</span>
              </div>
              <div className="sorted_drop_down">
                <select
                  name="sort_by_field"
                  id="sort_by_field"
                  className="txtBox"
                  onChange={(e) =>
                    handleSort(e, "sort_by_field", e.target.value)
                  }
                >
                  <option value="listing_price">Price</option>
                  <option value="total_offers">Offers</option>
                  {/* <option value="">Views</option> */}
                  <option value="listing_days">Days Listed</option>
                  <option value="listing_beds">Bedrooms</option>
                  <option value="listing_baths">Bathrooms</option>
                  <option value="listing_sf">Square Feet</option>
                </select>
              </div>
              <div className="ascending_filter">
                <button className="change-view-btn lde" onClick={handleSortBy}>
                  <img
                    src={
                      watchAllFields?.sort_by === "desc"
                        ? "/images/sort-descending.png"
                        : "/images/sort-ascending.png"
                    }
                    alt=""
                  />
                </button>
              </div>
              <div
                className="cell_show_map"
                onClick={() => setIsMobSearch(!isMobSearch)}
              >
                <button className="change-view-btn">
                  <i className="fa fa-map" />
                </button>
              </div>
              <div className="sorting-options">
                <button
                  className={
                    listView === "list"
                      ? "change-view-btn lde active-view-btn"
                      : "change-view-btn lde"
                  }
                  onClick={ToggleListView}
                >
                  <i className="fa fa-th-list" />
                </button>
                <button
                  className={
                    listView === null
                      ? "change-view-btn active-view-btn"
                      : "change-view-btn"
                  }
                  onClick={ToggleGridView}
                >
                  <i className="fa fa-th-large" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup == true ? (
        <AdvanceFilter
          closePopup={closePopup}
          filter={watchAllFields}
          amenities_arr={amenities_arr}
          features_arr={features_arr}
          saveSearch={saveSearch}
          register={register}
          setValue={setValue}
          closeFilter={closeFilter}
          errors={errors}
          popup={popup}
        />
      ) : (
        ""
      )}
    </>
  );
}
