import axios from "axios";
import * as paths from "../constants/paths";
const token = localStorage.getItem("authToken");
export default axios.create({
  baseURL: paths.API_BASE_URL,
  headers: {
    "Content-type": "application/json",
    "accept": "application/json",
    Authorization: token,
  },
});
