import http from "../../helpers/http";

import {
    FETCH_USER_OFFERS_CONTENT,
    FETCH_USER_OFFERS_CONTENT_SUCCESS,
    FETCH_USER_OFFERS_CONTENT_FAILED,
} from "./actionTypes";

export const fetchUserOffers = () => (dispatch) => {
    dispatch({
        type: FETCH_USER_OFFERS_CONTENT,
        payload: null
    });
    http
        .get("user-my-offers")
        .then(({ data }) => {
            dispatch({
                type: FETCH_USER_OFFERS_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_USER_OFFERS_CONTENT_FAILED,
                payload: error
            });
        });
};


