import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector, useDispatch } from "react-redux";
import {
  saveCard,
  cardSavedStatusChange,
} from "../../../../states/actions/payments";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
export default function AddNewAchBankAccount({
  setAddNewAchBankAccount,
  member,
}) {
  const dispatch = useDispatch();
  const isAddNewCardProcessing = useSelector(
    (state) => state.payments.isAddNewCardProcessing
  );
  const isCardSaved = useSelector((state) => state.payments.isCardSaved);
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  useEffect(() => {
    if (isCardSaved) {
      setAddNewAchBankAccount(false);
      dispatch(cardSavedStatusChange());
    }
  }, [isCardSaved]);
  const onSubmit = (frmData) => {
    const newData = {
      payment_method: "ach",
      merchant: {
        merchant_id: "0D9FE4A011",
        api_key: "1462A44AD7584501B19FD9F5",
      },
      request: "createCustomerAndAccount",
      content: {
        site_id: "40613C28FACD",
        customer: {
          merch_cust_id: "LL-" + member?.id,
          first_name: member?.mem_fname,
          last_name: member?.mem_lname,
        },
        account: {
          account_number: frmData?.account_number,
          routing_number: frmData?.routing_number,
          account_type: frmData?.account_type,
          customer_id: "",
          nickname: frmData?.card_holder_name,
        },
      },
    };
    //
    dispatch(saveCard(frmData, true));
  };
  return (
    <section className="popup small_popup" data-popup="create-new-listing">
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <ToastContainer />
            <div
              className="crosBtn"
              onClick={() => setAddNewAchBankAccount(false)}
            ></div>
            <h4>Add Bank Account</h4>
            <div className="row formRow">
              <div className="col-md-12">
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <div class="flex flex-2 cardSec">
                    <div class="inner">
                      <input
                        type="text"
                        name=""
                        placeholder="Account Number"
                        class="txtBox"
                        {...register("account_number", {
                          required: "Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="account_number"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div class="inner">
                      <input
                        type="text"
                        name=""
                        placeholder="Routing Number"
                        class="txtBox"
                        {...register("routing_number", {
                          required: "Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="routing_number"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div class="inner">
                      <input
                        type="text"
                        name=""
                        placeholder="Nickname"
                        class="txtBox"
                        {...register("card_holder_name", {
                          required: "Required",
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="card_holder_name"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                    <div class="inner">
                      <select
                        name="account_type"
                        className="txtBox"
                        {...register("account_type", {
                          required: "Required",
                        })}
                      >
                        <option value="">Select Account Type</option>
                        <option value="C">Checking Account</option>
                        <option value="S">Savings Account</option>
                      </select>
                      <ErrorMessage
                        errors={errors}
                        name="account_type"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div class="bTn  text-center">
                    <button
                      type="submit"
                      class="webBtn colorBtn"
                      disabled={isAddNewCardProcessing ? "disabled" : ""}
                    >
                      Save{" "}
                      {isAddNewCardProcessing ? (
                        <i className="spinner"></i>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
