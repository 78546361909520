import React, { useEffect, useState } from "react";
import BillingStep1 from "./form-steps/step1";
import BillingStep2 from "./form-steps/step2";
import BillingStep3 from "./form-steps/step3";
import { useForm } from "react-hook-form";
import {
  convertSimpleDateUsingMoment,
  getBranchProperties,
  getBranchPropertyLeases,
} from "../../../../helpers/api";
import toast, { Toaster } from "react-hot-toast";
export default function BillingForm({
  changeStep,
  step,
  handleAddNewPrice,
  prices,
  handleRemovePrice,
  handlePriceChange,
  branches,
  handleBilling,
  isFormProcessing,
  billing_row,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const [isPropertyLoading, setPropertyloading] = useState(false);
  const [properties, setProperty] = useState(null);
  const [leases, setLeases] = useState(null);
  const [billingAddType, setBillingAddType] = useState("single");

  useEffect(() => {
    if (billing_row?.id > 0) {
      setValue(
        "cycle",
        billing_row?.cycle !== null &&
          billing_row?.cycle !== undefined &&
          billing_row?.cycle !== ""
          ? setDateToMonthYear(new Date(billing_row?.cycle))
          : new Date()
      );
      setValue("branch_id", billing_row?.branch_id);
      setValue("property_id", billing_row?.property_id);
      setValue("lease_id", billing_row?.lease_id);
      if (billing_row?.properties?.length > 0) {
        setProperty(billing_row?.properties);
      }
      if (billing_row?.leases?.length > 0) {
        setLeases(billing_row?.leases);
      }
    }
  }, [billing_row]);
  const setDateToMonthYear = (date) => {
    if (!date) return null; // Handle null or undefined date

    const year = date.getFullYear();
    const month = date.getMonth();

    // Set the date to the first day of the month to ensure consistency
    return new Date(year, month, 1);
  };

  async function handleBranchChange(value) {
    setPropertyloading(true);
    let properties = await getBranchProperties(value);
    setProperty(properties.properties);
    setPropertyloading(false);
  }
  async function handleBranchPropertyChange(e) {
    const property_id = e.target.value;
    setValue("property_id", property_id);
    if (parseInt(watchAllFields?.branch_id) > 0) {
      if (parseInt(property_id) > 0) {
        setPropertyloading(true);
        let leases_res = await getBranchPropertyLeases(
          watchAllFields?.branch_id,
          property_id
        );
        setPropertyloading(false);
        if (leases_res?.status) {
          setLeases(leases_res?.leases);
        }
      } else {
        toast.error("Property is invalid!");
        return;
      }
    } else {
      toast.error("Branch is invalid!");
      return;
    }
  }
  const onSubmit = (data) => {
    let newData = data;
    if (
      prices?.prices !== null &&
      prices?.prices !== undefined &&
      prices?.prices !== "" &&
      prices?.prices?.length > 0
    ) {
      newData = {
        ...data,
        prices: prices.prices,
        cycle: convertSimpleDateUsingMoment(data?.cycle),
      };
    }

    // ;
    handleBilling(newData);
  };
  return (
    <>
      <Toaster position="bottom-left" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <BillingStep1
            changeStep={changeStep}
            step={step}
            setBillingAddType={setBillingAddType}
            billingAddType={billingAddType}
          />
        ) : step === 1 ? (
          <BillingStep2
            changeStep={changeStep}
            step={step}
            register={register}
            trigger={trigger}
            errors={errors}
            control={control}
            watchAllFields={watchAllFields}
            isValid={isValid}
            branches={branches}
            handleBranchChange={handleBranchChange}
            handleBranchPropertyChange={handleBranchPropertyChange}
            properties={properties}
            isPropertyLoading={isPropertyLoading}
            billing_row={billing_row}
            leases={leases}
          />
        ) : step === 2 ? (
          <BillingStep3
            changeStep={changeStep}
            step={step}
            prices={prices}
            handleAddNewPrice={handleAddNewPrice}
            handleRemovePrice={handleRemovePrice}
            handlePriceChange={handlePriceChange}
            isFormProcessing={isFormProcessing}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
}
