import React from 'react'
import LeaseSort from './lease-sort';
import LeaseBlk from './leaseBlk';

export default function PendingLeases({ leases, type }) {
    return (
        <>
            {
                leases?.length > 0 ?
                    leases?.map((lease, index) => {
                        return (
                            <LeaseBlk lease={lease} key={index} />
                        )
                    })
                    :
                    <div className='alert alert-danger'>No available leases.</div>
            }
        </>
    )
}
