import React, { useState, useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import ListingTabs from "./sections/listingTabs";
import TabsMenu from "./sections/tabs-menu";

import { ToastContainer } from "react-toastify";
import {
  fetchUserListings,
  deleteUserListings,
} from "../../../states/actions/fetchUserListings";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import PropertySort from "./sections/propertySort";
import ListingSort from "./sections/listingSort";

export default function UserListings() {
  const [listView, setListView] = useState("grid");
  const ToggleView = (type) => {
    setListView(type);
  };

  const [listingType, setListingType] = useState("active");
  const changeType = (type) => {
    setListingType(type);
  };
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchUserListings.content);
  const isLoading = useSelector((state) => state.fetchUserListings.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  const handleDelete = (data) => {
    dispatch(deleteUserListings(data));
  };

  useEffect(() => {
    dispatch(fetchUserListings());
    dispatch(fetchSiteSettings());
  }, []);

  useDocumentTitle("Listings", "Loftus");
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const properties = data?.properties;
  const active_listings = data?.listings;
  const expired_listings = data?.expired_listings;
  const rent_listing_count = data?.rent_listing_count;
  const sale_listing_count = data?.sale_listing_count;
  //
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name="Listings"
              popup_show={true}
              popup_name="create-new-listing"
              properties={properties}
              sale_listing_count={sale_listing_count}
              rent_listing_count={rent_listing_count}
            />
            <div className="page_body">
              <div className="listing_tabs flex">
                <TabsMenu
                  type={listingType}
                  handleType={changeType}
                  active_count={active_listings?.length}
                  pending_count={expired_listings?.length}
                  active_name="Active"
                  pending_name="Expired"
                />
                {/* <ListingSort listView={listView} ToggleView={ToggleView} /> */}
              </div>
              <ListingTabs
                type={listingType}
                active={active_listings}
                expired={expired_listings}
                listView={listView}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
