import React from 'react'
import Image from './image';
export default function LocationBlk({ data }) {
    return (
        <>
            <div className="col">
                <div className="inner">
                    <a href="search.php" className="big_link"></a>
                    <div className="image">
                        <Image src={data.image} alt={data.heading} />
                    </div>
                    <div className="cntnt">
                        <h2>{data.count}</h2>
                        <p>{data.heading}</p>
                        <span className="color_line"></span>
                        {
                            data.state !== undefined && data.state !== 'N/A' && data.state !== null && data.state !== '' ?

                                <h4>{data.state}</h4>
                                :
                                ""
                        }
                        <p>{data.city}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
