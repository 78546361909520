import {
  FETCH_SEARCH_PROPERTIES_CONTENT,
  FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS,
  FETCH_SEARCH_PROPERTIES_CONTENT_FAILED,
  CHANGE_IS_HEADER_SEARCH_VALUE,
  CHANGE_MAP_BOUNDS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  properties: [],
  isCompleted: false,
  is_header_search: null,
  map_bounds: null,
  map_bounds_zoom: null,
  filter_with_boundary: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SEARCH_PROPERTIES_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
        properties: [],
        isCompleted: false,
      };
    case FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
        properties: payload?.properties,
        is_header_search: payload?.is_header_search,
        filter_with_boundary: payload?.filter_with_boundary,
        isCompleted: true,
      };
    case CHANGE_IS_HEADER_SEARCH_VALUE:
      return {
        ...state,
        is_header_search: payload,
      };
    case CHANGE_MAP_BOUNDS:
      return {
        ...state,
        map_bounds: payload?.bounds,
        map_bounds_zoom: payload?.zoom,
      };
    case FETCH_SEARCH_PROPERTIES_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
        properties: [],
        isCompleted: false,
      };

    default:
      return state;
  }
}
