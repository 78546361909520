import React, { useState } from "react";
import BranchFilter from "./branch-filter";
import BranchLst from "./branch-lst";
import Pagination from "../../../common/pagination";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");
export default function BranchBlk({ branches, handleDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = branches.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div className="page_body">
        {/* <BranchFilter /> */}
        <div className="blk blockLst user_dash_tbl cell_block_cell">
          <table>
            <thead>
              <tr>
                <th className="hide_cell_hyp">Branch</th>
                <th>Address</th>
                <th>Users</th>
                {mem_permissions === "admin" && parseInt(super_admin) === 1 ? (
                  <th className="text-right hide_cell_hyp">Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems?.map((item, index) => {
                  return (
                    <BranchLst
                      branch={item}
                      key={index}
                      handleDelete={handleDelete}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className="alert alert-danger">
                      No branch(s) found!
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={branches.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
