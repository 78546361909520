import React from 'react'
import Image from './image';


export default function ExtraInfoBlk({ data }) {
    return (
        <>
            <div className="col" key={data.id}>
                <div className="inner">
                    <div className="image">
                        <Image src={data.image} alt={data.heading} />
                    </div>
                    <div className="cntnt">
                        <h4>{data.heading}</h4>
                        <p>{data.text}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
