import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_USER_SINGLE_OFFER_CONTENT,
  FETCH_USER_SINGLE_OFFER_CONTENT_SUCCESS,
  FETCH_USER_SINGLE_OFFER_CONTENT_FAILED,
  USER_SINGLE_OFFERS_DELETE_MESSAGE,
  USER_SINGLE_OFFERS_DELETE_MESSAGE_FAILED,
  USER_SINGLE_OFFERS_DELETE_MESSAGE_SUCCESS,
  USER_SINGLE_OFFERS_DECLINE_MESSAGE,
  USER_SINGLE_OFFERS_DECLINE_MESSAGE_FAILED,
  USER_SINGLE_OFFERS_DECLINE_MESSAGE_SUCCESS,
  USER_SINGLE_OFFERS_ACTION_MESSAGE,
  USER_SINGLE_OFFERS_ACTION_MESSAGE_FAILED,
  USER_SINGLE_OFFERS_ACTION_MESSAGE_SUCCESS,
} from "./actionTypes";

export const fetchUserSingleOffer =
  (offer_id, received = false) =>
  (dispatch) => {
    let getUrl = "";
    if (received === true) {
      getUrl = "user-received-offer/" + offer_id;
    } else {
      getUrl = "user-my-offer/" + offer_id;
    }
    dispatch({
      type: FETCH_USER_SINGLE_OFFER_CONTENT,
      payload: null,
    });
    http
      .get(getUrl)
      .then(({ data }) => {
        if (data?.status === 1) {
          dispatch({
            type: FETCH_USER_SINGLE_OFFER_CONTENT_SUCCESS,
            payload: data,
          });
        } else {
          setTimeout(() => {
            window.location.replace("/offers");
          }, 2000);
        }
      })
      .catch((error) => {
        dispatch({
          type: FETCH_USER_SINGLE_OFFER_CONTENT_FAILED,
          payload: error,
        });
      });
  };
export const deleteOffer = (formData) => (dispatch) => {
  dispatch({
    type: USER_SINGLE_OFFERS_DELETE_MESSAGE,
    payload: null,
  });
  http
    .post("delete-offer/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/offers");
        }, 2000);
        dispatch({
          type: USER_SINGLE_OFFERS_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: USER_SINGLE_OFFERS_DELETE_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_SINGLE_OFFERS_DELETE_MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const deleteOfferDecline = (offer_id) => (dispatch) => {
  dispatch({
    type: USER_SINGLE_OFFERS_DECLINE_MESSAGE,
    payload: null,
  });
  http
    .post("delete-offer-tenant/" + offer_id, helpers.doObjToFormData(offer_id))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/offers");
        }, 2000);
        dispatch({
          type: USER_SINGLE_OFFERS_DECLINE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: USER_SINGLE_OFFERS_DECLINE_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_SINGLE_OFFERS_DECLINE_MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const landlordOfferAction = (formData, offer_id) => (dispatch) => {
  dispatch({
    type: USER_SINGLE_OFFERS_ACTION_MESSAGE,
    payload: null,
  });
  let newFrmData = { status: formData };
  http
    .post(
      "landlord-offer-action/" + offer_id,
      helpers.doObjToFormData(newFrmData)
    )
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        dispatch({
          type: USER_SINGLE_OFFERS_ACTION_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: USER_SINGLE_OFFERS_ACTION_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: USER_SINGLE_OFFERS_ACTION_MESSAGE_FAILED,
        payload: error,
      });
    });
};
