import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import BillingForm from "./billing/billingForm";

import {
  fetchSingleBilling,
  postBilling,
} from "../../../states/actions/fetchSingleBilling";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function AddBilling() {
  const { billing_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleBilling.content);
  const isLoading = useSelector((state) => state.fetchSingleBilling.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleBilling.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleBilling(billing_id));
  }, []);
  const [formStep, setFormStep] = useState(0);
  const [prices, setPrices] = useState({
    prices: [],
  });
  const handleAddNewPrice = () => {
    setPrices({
      ...prices,
      prices: prices.prices.concat([
        { randomKey: Math.random(), name: "", cost: "" },
      ]),
    });
  };
  function handleRemovePrice(idx) {
    setPrices({
      ...prices,
      prices: prices.prices.filter((s, sidx) => idx !== sidx),
    });
  }
  useEffect(() => {
    if (
      data?.billing_row?.prices !== undefined &&
      data?.billing_row?.prices !== null &&
      data?.billing_row?.prices?.length > 0
    ) {
      setPrices({ prices: data?.billing_row.prices });
    }
    if (data?.billing_row?.id > 0) {
      setFormStep(1);
    }
  }, [data?.billing_row]);

  function handlePriceChange(idx, evt) {
    const newPrices = prices.prices.map((prices, sidx) => {
      if (idx !== sidx) return prices;
      return { ...prices, [evt.target.name]: evt.target.value };
    });

    setPrices({ ...prices, prices: newPrices });
  }

  const changeStep = (step) => {
    setFormStep(step);
  };
  const handleBilling = (frmData) => {
    // ; return;
    if (
      frmData?.prices !== null &&
      frmData?.prices !== undefined &&
      frmData?.prices?.length > 0
    ) {
      for (let i = 0; i < parseInt(frmData?.prices?.length); i++) {
        if (
          frmData?.prices[i]?.name === "" ||
          frmData?.prices[i]?.name === null ||
          frmData?.prices[i]?.name === undefined
        ) {
          toast.error("Expense Name " + parseInt(i + 1) + " is required");
          return;
        }
        if (
          frmData?.prices[i]?.cost === "" ||
          frmData?.prices[i]?.cost === null ||
          frmData?.prices[i]?.cost === undefined
        ) {
          toast.error("Expense Cost " + parseInt(i + 1) + " is required");
          return;
        }
      }
      dispatch(postBilling(frmData, billing_id));
    } else {
      toast.error("Please add at least one price!");
      return;
    }
  };
  useEffect(() => {
    if (billing_id > 0 && data?.billing_row === null) {
      window.location.href = "/billing";
    }
  }, [billing_id, data?.billing_row]);

  useDocumentTitle(
    data?.billing_row?.id > 0 ? "Edit Billing - Loftus" : "Add Billing - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  // const floor_plans = data.floor_plans;
  const branches = data?.branches;
  const billing_row = data?.billing_row;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="billing" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    billing_row?.id > 0 ? "Edit Billing" : "Add Billing"
                  }
                  parent_name="Billing"
                  parent_link="/billing"
                />

                <div className="page_body">
                  <div className="option_lbl medium_opt_lbl justify_center_lbl_cell">
                    <ul>
                      <li className={formStep === 0 ? "active" : ""}>Step 1</li>
                      <li className={formStep === 1 ? "active" : ""}>Step 2</li>
                      <li className={formStep === 2 ? "active" : ""}>Step 3</li>
                    </ul>
                  </div>
                  <div className="dash_form_blk listing_add_c">
                    <BillingForm
                      step={formStep}
                      changeStep={changeStep}
                      handleAddNewPrice={handleAddNewPrice}
                      prices={prices}
                      handleRemovePrice={handleRemovePrice}
                      handlePriceChange={handlePriceChange}
                      branches={branches}
                      handleBilling={handleBilling}
                      isFormProcessing={isFormProcessing}
                      billing_row={billing_row}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
