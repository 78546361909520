import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchDocuments,
  deleteDocument,
  fetchSingleDocument,
  postDocument,
  getFilteredDocuments,
} from "../../../states/actions/fetchSingleDocuments";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
import DocumentSortTab from "./documents/sort-tabs";
import DocumentBlk from "./documents/blk";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function Documents() {
  const [documentType, setDocumentType] = useState("active");
  useDocumentTitle("Documents - Loftus");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleDocuments.content);
  const isLoading = useSelector(
    (state) => state.fetchSingleDocuments.isLoading
  );
  const isItemDeleted = useSelector(
    (state) => state.fetchSingleDocuments.isItemDeleted
  );
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleDocuments.isFilterLoading
  );
  useEffect(() => {
    dispatch(fetchDocuments());
  }, []);
  useEffect(() => {
    if (data?.active_documents?.length > 0) {
      setDocumentType("active");
    } else if (data?.expired_documents?.length > 0) {
      setDocumentType("expired");
    }
  }, [data]);
  const handleDelete = (e, expense_id) => {
    e.preventDefault();
    dispatch(deleteDocument(expense_id));
  };
  const [filterData, setFilterData] = useState({
    branch_id: null,
  });
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
    };
    dispatch(getFilteredDocuments(formData));
  };
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const active_documents = data?.active_documents;
  const expired_documents = data?.expired_documents;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="documents" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" ? (
                  <Breadcrumb page_name="Documents" add_link="/add-document" />
                ) : (
                  <Breadcrumb page_name="Documents" />
                )}
                <div className="page_body">
                  {isItemDeleted || isFilterLoading ? (
                    <div className="loadingDisabled">
                      <i className="spinner"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <DocumentSortTab
                    setDocumentType={setDocumentType}
                    documentType={documentType}
                    active_count={active_documents?.length}
                    expired_count={expired_documents?.length}
                    handleBranchChange={handleBranchChange}
                    branches={branches}
                  />
                  {documentType === "active" ? (
                    <DocumentBlk
                      active_documents={active_documents}
                      handleDelete={handleDelete}
                    />
                  ) : documentType === "expired" ? (
                    <DocumentBlk
                      active_documents={expired_documents}
                      type="expired"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
