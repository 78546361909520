import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
export default function ContactForm({ handleContact, isFormProcessing, emptyFormData }) {

  const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
  const onSubmit = data => {
    handleContact(data);
  }

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Name <sup>*</sup></h6>
            <div className="form_blk">
              <input type="text" name="name" id="name" className='txtBox' placeholder="eg: John Wick" {...register("name", { required: "Required" })} />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Phone <sup>*</sup></h6>
            <div className="form_blk">
              <input type="text" name="phone" id="phone" className='txtBox' placeholder="eg: 559-579-8833"{...register("phone", {
                required: "Required", pattern: {
                  value: /^[0-9-]+$/,
                  message: "Phone format is not valid!"
                }
              })} />
              <ErrorMessage
                errors={errors}
                name="phone"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Subject <sup>*</sup></h6>
            <div className="form_blk">
              <input type="text" name="subject" id="subject" className='txtBox' placeholder="eg: Lorem Ipsum" {...register("subject", { required: "Required" })} />
              <ErrorMessage
                errors={errors}
                name="subject"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Email Address <sup>*</sup></h6>
            <div className="form_blk">
              <input type="text" name="email" id="email" className='txtBox' placeholder="eg: abc@email.com" {...register("email", {
                required: "Required", pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                  message: "Email format is not valid!"
                }
              })} />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Description</h6>
            <div className="form_blk">
              <textarea name="comments" id="comments" className='txtBox' placeholder="Type something here" {...register("comments", { required: "Required" })}></textarea>
              <ErrorMessage
                errors={errors}
                name="comments"
                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
              />
            </div>
          </div>
        </div>
        <div className="btn_blk form_btn text-center"><button type="submit" className="webBtn colorBtn" disabled={(isFormProcessing === true) ? 'disabled' : ''}>{(isFormProcessing === true) ? <i className="spinner"></i> : 'Submit'}</button></div>
      </form>
    </>
  )
}
