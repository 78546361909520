import React from 'react'
import { getServerImage } from '../../helpers/api';

export default function Images({ images, handleRemoveLabelImage, label = '', path, delete_label }) {

    return (
        <>
            {
                images != '' && images != undefined && images != null ?

                    <div className="upLoadBlk txtBox">
                        <div className="inside scrollbar">
                            <ul className="imgLst flex">

                                {images[label]?.map((image, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="image"><img src={getServerImage(path, image.file_name)} alt="" /><div className="closeBtn" onClick={() => handleRemoveLabelImage(index, label)}></div></div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    :
                    ""
            }
        </>
    )
}
