import React from "react";
import { NavLink } from "react-router-dom";

export default function PendingProperty({
  item,
  handleCancelPropertyRequest,
  isPropertyCancelProcessing,
}) {
  return (
    <>
      <div className="col">
        <div className="inner">
          <div className="image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="cntnt">
            <h3>{item.title}</h3>
            <ul className="flex">
              {item?.studio === "no" ? (
                <li>
                  <span>{item.beds + " Bed"} </span>
                </li>
              ) : (
                <li>
                  <span>Studio</span>
                </li>
              )}
              <li>
                <span>{item.baths + " Bath"}</span>
              </li>
              <li>
                <span>{item.sf}</span>
              </li>
            </ul>
            <ul className="flex">
              <li>
                <span dangerouslySetInnerHTML={{ __html: item.address }} />
              </li>
            </ul>
            <div className="bTn text-center">
              <NavLink
                to="#!"
                className="webBtn"
                onClick={() => handleCancelPropertyRequest(item?.request_id)}
                disabled={isPropertyCancelProcessing ? true : false}
              >
                Cancel Request{" "}
                {isPropertyCancelProcessing === true ? (
                  <i className="spinner"></i>
                ) : (
                  ""
                )}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
