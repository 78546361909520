import React, { useEffect, useState } from "react";
import MaintenanceLoadProfilePopup from "./maintenance_profile/popup";
import Breadcrumb from "./sections/breadcrumb";
import AccountInfo from "./maintenance_profile/account-info";
import MaintenanceProfileProperties from "./maintenance_profile/properties";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { profileSettings } from "../../../states/actions/profileSettings";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import ReviewsMaintenance from "./maintenance_profile/reviews-maintenance";

export default function MaintenanceProfile() {
  const member_row = useSelector((state) => state.profileSettings.verify);

  const isLoading = useSelector((state) => state.profileSettings.isLoading);
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  useEffect(() => {
    dispatch(profileSettings());
  }, []);

  const [tabs, setTabs] = useState(1);
  const [popup, setPopup] = useState(true);
  const showPopup = () => {
    setPopup(true);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup(false);
  };
  const [reviewsArr, setReviewsArr] = useState([]);
  const [total_ratings, setTotalRatings] = useState(0);
  useEffect(() => {
    if (member_row?.settings?.member?.maintenance_profile_row?.id) {
      setReviewsArr(
        member_row?.settings?.member?.maintenance_profile_row?.reviews
          ? member_row?.settings?.member?.maintenance_profile_row?.reviews
          : []
      );
      setTotalRatings(
        member_row?.settings?.member?.maintenance_profile_row?.total_ratings
          ? member_row?.settings?.member?.maintenance_profile_row?.total_ratings
          : 0
      );
    }
  }, [member_row?.settings?.member?.maintenance_profile_row]);
  useDocumentTitle("Service Profile - Loftus");
  const member = member_row?.settings?.member;
  if (isLoading) return <LoadingScreen />;
  const maintenance_profile_row = member?.maintenance_profile_row;
  const states = siteSettings?.states;
  const bank_accounts = member?.bank_accounts;
  console.log(maintenance_profile_row);
  return (
    <>
      {maintenance_profile_row === null ||
      maintenance_profile_row === undefined ||
      maintenance_profile_row?.id === undefined ||
      maintenance_profile_row?.id === null ? (
        <MaintenanceLoadProfilePopup closePopup={closePopup} popup={popup} />
      ) : (
        ""
      )}
      <main index class="main_dashboard">
        <section class="dashboard">
          <div class="contain">
            <Breadcrumb page_name="Service Profile" />
            <div class="page_body account_settings_flex">
              <div class="dash_form_blk">
                <div className="main_mentenance_blk">
                  <div className="option_lbl  profileTabs">
                    <ul>
                      <li className="active">
                        <a
                          href="#!"
                          onClick={() => setTabs(1)}
                          className={tabs === 1 ? "webBtn" : "webBtn colorBtn"}
                        >
                          Account Info
                        </a>
                      </li>
                      <li className="active">
                        <a
                          href="#!"
                          onClick={() => setTabs(2)}
                          className={tabs === 2 ? "webBtn" : "webBtn colorBtn"}
                        >
                          Reviews
                        </a>
                      </li>
                      <li>
                        <a
                          href="/service-profile-properties"
                          className={"webBtn"}
                        >
                          Properties
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dash_form_blk">
                    {tabs === 1 ? (
                      <AccountInfo
                        member={member}
                        maintenance_profile_row={maintenance_profile_row}
                        states={states}
                        bank_accounts={bank_accounts}
                      />
                    ) : tabs === 2 ? (
                      <ReviewsMaintenance
                        item={maintenance_profile_row}
                        setReviewsArr={setReviewsArr}
                        reviewsArr={reviewsArr}
                        total_ratings={total_ratings}
                        isReviewPosted={true}
                        maintenance_user={true}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
