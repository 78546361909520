import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSingleProperty,
  postProperty,
} from "../../../states/actions/fetchSingleProperty";
import { useSelector, useDispatch } from "react-redux";
// import { fetchSiteSettings } from '../../../states/actions/fetchSiteSettings';
import LoadingScreen from "../../common/LoadingScreen";

import AddPropertyForm from "./sections/add-property-form";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { ToastContainer } from "react-toastify";
let addressLoading = false;
export default function AddProperty() {
  const dispatch = useDispatch();
  const { property_id } = useParams();
  const [formStep, setFormStep] = useState(1);
  const [popup, setPopup] = useState(false);
  const [verifyAddressFile, setVerifyAddressFile] = useState({
    file: null,
    file_name: null,
    loading: false,
  });
  const showPopup = () => {
    // setPopup(true);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup(false);
    setVerifyAddressFile({
      ...verifyAddressFile,
      file: null,
      file_name: null,
      loading: false,
    });
  };
  const data = useSelector((state) => state.fetchSingleProperty.content);
  const isLoading = useSelector((state) => state.fetchSingleProperty.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleProperty.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleProperty(property_id));
  }, []);

  const changeStep = (step) => {
    setFormStep(step);
  };

  const handleSubmit = (data) => {
    dispatch(postProperty(data, property_id));
  };

  useDocumentTitle(
    data != undefined &&
      data != null &&
      data.property_row != undefined &&
      data.property_row !== null
      ? "Edit Property - Loftus"
      : "Add Property - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const floor_plans = data.floor_plans;
  const branches = data.branches;
  const property_row = data.property_row;
  const countries = data.countries;
  const states = data.states;
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="add-property" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    data != undefined &&
                    data != null &&
                    data.property_row != undefined &&
                    data.property_row !== null
                      ? "Edit Property"
                      : "Add Property"
                  }
                  parent_name="Properties"
                  parent_link="/properties"
                />

                <div className="page_body">
                  {/* <div className="option_lbl small_opt_lbl justify_center_lbl_cell">
                    <ul>
                      <li className={formStep === 0 ? "active" : ""}>Step 1</li>
                      <li className={formStep === 1 ? "active" : ""}>Step 1</li>
                    </ul>
                  </div> */}
                  <div className="dash_form_blk property_form_commercial">
                    <AddPropertyForm
                      changeStep={changeStep}
                      step={formStep}
                      floor_plans={floor_plans}
                      branches={branches}
                      property_row={property_row}
                      countries={countries}
                      states={states}
                      handleProperty={handleSubmit}
                      isFormProcessing={isFormProcessing}
                      addressLoading={addressLoading}
                      showPopup={showPopup}
                      closePopup={closePopup}
                      popup={popup}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
