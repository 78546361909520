import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_MAINTENANCE_CONTENT,
  FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS,
  FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED,
  SINGLE_MAINTENANCE_MESSAGE,
  SINGLE_MAINTENANCE_MESSAGE_SUCCESS,
  SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP,
  SINGLE_MAINTENANCE_MESSAGE_FAILED,
  FETCH_MAINTENANCE_CONTENT,
  FETCH_MAINTENANCE_CONTENT_FAILED,
  FETCH_MAINTENANCE_CONTENT_SUCCESS,
  MAINTENANCE_DELETE__MESSAGE,
  MAINTENANCE_DELETE_MESSAGE_SUCCESS,
  MAINTENANCE_DELETE__MESSAGE_FAILED,
  GET_FILTER_MAINTENANCE,
  GET_FILTER_MAINTENANCE_FAILED,
  GET_FILTER_MAINTENANCE_SUCCESS,
  GET_MAINTENANCE_STAFF_DETAILS,
  GET_MAINTENANCE_STAFF_DETAILS_FAILED,
  GET_MAINTENANCE_STAFF_DETAILS_SUCCESS,
} from "./actionTypes";
export const updateCloseEditPopupState = (newValue) => (dispatch) => {
  dispatch({
    type: SINGLE_MAINTENANCE_MESSAGE_SUCCESS_CLOSE_POPUP,
    payload: newValue,
  });
};
export const fetchMaintenanceStaff = () => (dispatch) => {
  dispatch({
    type: FETCH_MAINTENANCE_CONTENT,
    payload: null,
  });
  http
    .get("maintenance-staff")
    .then(({ data }) => {
      dispatch({
        type: FETCH_MAINTENANCE_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_MAINTENANCE_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchMaintenanceStaffDetails = (staff_id) => (dispatch) => {
  dispatch({
    type: GET_MAINTENANCE_STAFF_DETAILS,
    payload: null,
  });
  http
    .get("maintenance-staff-details/" + staff_id)
    .then(({ data }) => {
      dispatch({
        type: GET_MAINTENANCE_STAFF_DETAILS_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_MAINTENANCE_STAFF_DETAILS_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleMaintenanceStaff = (person_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_MAINTENANCE_CONTENT,
    payload: null,
  });
  http
    .get("single-maintenance-staff/" + person_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_MAINTENANCE_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_MAINTENANCE_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postStaffPerson = (formData, person_id) => (dispatch) => {
  //
  let postUrl = "";
  if (person_id != undefined && person_id !== null) {
    postUrl = "edit-maintenance-staff/" + person_id;
  } else {
    postUrl = "add-maintenance-staff";
  }
  dispatch({
    type: SINGLE_MAINTENANCE_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data);
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        if (formData?.is_staff_edit_popup !== true) {
          setTimeout(() => {
            window.location.replace("/service-staff");
          }, 2000);
        }

        dispatch({
          type: SINGLE_MAINTENANCE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_MAINTENANCE_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_MAINTENANCE_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deletePerson = (formData) => (dispatch) => {
  dispatch({
    type: MAINTENANCE_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post(
      "delete-maintenance-staff/" + formData,
      helpers.doObjToFormData(formData)
    )
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/service-staff");
        }, 2000);
        dispatch({
          type: MAINTENANCE_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: MAINTENANCE_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MAINTENANCE_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredPersons = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_MAINTENANCE,
    payload: null,
  });
  http
    .post("maintenance-staff", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_MAINTENANCE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_MAINTENANCE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_MAINTENANCE_FAILED,
        payload: error,
      });
    });
};
