import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_USER_LISTINGS_CONTENT,
    FETCH_USER_LISTINGS_CONTENT_SUCCESS,
    FETCH_USER_LISTINGS_CONTENT_FAILED,
    USER_LISTINGS_DELETE__MESSAGE,
    USER_LISTINGS_DELETE_MESSAGE_SUCCESS,
    USER_LISTINGS_DELETE__MESSAGE_FAILED
} from "./actionTypes";

export const fetchUserListings = () => (dispatch) => {
    dispatch({
        type: FETCH_USER_LISTINGS_CONTENT,
        payload: null
    });
    http
        .get("user-listings")
        .then(({ data }) => {
            dispatch({
                type: FETCH_USER_LISTINGS_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_USER_LISTINGS_CONTENT_FAILED,
                payload: error
            });
        });
};


export const deleteUserListings = (formData) => (dispatch) => {
    dispatch({
        type: USER_LISTINGS_DELETE__MESSAGE,
        payload: null
    });
    http
        .post('delete-user-listing/' + formData, helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.replace("/listing");
                }, 2000);
                dispatch({
                    type: USER_LISTINGS_DELETE_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: USER_LISTINGS_DELETE__MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: USER_LISTINGS_DELETE__MESSAGE_FAILED,
                payload: error
            });
        });
};