import { format_amount } from "../../../../helpers/api";
import Text from "../../../common/Text";

export default function PaymentPropertyBlk({ lease_row }) {
    return (
        <div className="colL">
            <div class="blk blockLst cell_block_cell">
                <div class="flex pay_ment_new_pop">
                    <div class="pay_address flex">
                        <div class="pro_img_pop">
                            <img src={lease_row?.imageThumbnail} alt={lease_row?.title} />
                        </div>
                        <div class="pay_cntnt_pop_new">
                            <p>{lease_row?.title}</p>
                            <p><Text string={lease_row?.address} /></p>

                            <div><h5 class="lease_heading_mini">Balance</h5><strong>{format_amount(lease_row?.available_balance)}</strong></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
}