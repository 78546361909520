import React from 'react'
import ReportBlk from './reportBlk'
import { postChatMsg } from "../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_SETTINGS } from '../../../../utils/siteSettings';

export default function TenantBlk({ tenant, showPopup, listing_type, is_landlord, listing_row, offer_listing_type = false }) {
    const dispatch = useDispatch();
    const isFormProcessing = useSelector((state) => state.sendChat.isFormProcessing);

    const handleStartChat = tenant_id => {
        if (tenant_id !== undefined && tenant_id !== null && tenant_id !== '' && tenant_id > 0) {
            let frmData = { msg: "", property_mem_id: tenant_id }
            dispatch(postChatMsg(frmData, true));
        }
        else {
            toast.error('Invalid User or user does not exit in our system!',
                TOAST_SETTINGS
            ); return;
        }

    }
    return (
        <>
            <ToastContainer />
            <div className="sender_offer_col">
                <div class={tenant?.current === 1 ? "inner offer_blk_border active relative" : "inner offer_blk_border relative"}>
                    <div className="mini_icon">
                        <img src={tenant?.thumb} alt={tenant?.name} />
                    </div>
                    <div className="bio_info">
                        <div className="name">
                            <h4>{tenant?.name}</h4>
                        </div>
                        {
                            offer_listing_type !== false && offer_listing_type === 'sale' ?
                                is_landlord === true ?
                                    <a href="/" className="start_chat">Start Chat</a>
                                    :
                                    ""
                                :
                                tenant?.package !== 'N' ?
                                    is_landlord !== true ?
                                        <div className="address">
                                            <span dangerouslySetInnerHTML={{ __html: tenant?.status }} />
                                        </div>
                                        :
                                        is_landlord === true && tenant?.paid === 1 ?
                                            <span className="badge green">Complete</span>
                                            :
                                            ""
                                    :
                                    tenant?.paid === 1 ?
                                        <span className="badge green">Complete</span>
                                        :
                                        tenant?.paid === 0 ?
                                            <span className="badge green">Incomplete</span>
                                            :
                                            ""
                        }
                    </div>
                    {
                        tenant?.current !== 1 && listing_type === 'rent' ?

                            <div div className="chat_mini_icon">
                                <a href="#!" onClick={() => handleStartChat(tenant?.mem_id)} disabled={isFormProcessing}>
                                    <i className="fa fa-comments"></i>
                                </a>
                            </div>
                            :
                            ""
                    }
                    {
                        listing_type === 'rent' ?

                            <div className="check_bg_ur">

                                <div className="flex potential_flex">

                                    {
                                        listing_row?.screening !== 'N' ?
                                            tenant?.reports?.length > 0 ?
                                                tenant?.reports?.map((report) => {
                                                    return (
                                                        <ReportBlk report={report} showPopup={showPopup} />
                                                    )
                                                })
                                                :

                                                tenant?.package !== 'N' && listing_row?.screening !== 'N' ?
                                                    <>
                                                        <div className="col">
                                                            <div className="inner">
                                                                <div className="image_sm">
                                                                    <img src="/images/icons/credit.png" alt="" />
                                                                </div>
                                                                <h5>Credit</h5>
                                                                <span className="badge yellow"> Pending</span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="inner">
                                                                <div className="image_sm">
                                                                    <img src="/images/icons/criminal.png" alt="" />
                                                                </div>
                                                                <h5>Criminal</h5>
                                                                <span className="badge yellow"> Pending</span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="inner">
                                                                <div className="image_sm">
                                                                    <img src="/images/icons/eviction.png" alt="" />
                                                                </div>
                                                                <h5>Eviction Proceedings</h5>
                                                                <span className="badge yellow"> Pending</span>
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <div className="inner">
                                                                <div className="image_sm">
                                                                    <img src="/images/icons/id.png" alt="" />
                                                                </div>
                                                                <h5>ID</h5>
                                                                <span className="badge yellow"> Pending</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    ""
                                            :
                                            listing_row?.screening === 'N' && tenant?.package !== 'N' && tenant?.current === 1 ?
                                                tenant?.reports?.length > 0 ?
                                                    tenant?.reports?.map((report) => {
                                                        return (
                                                            <ReportBlk report={report} showPopup={showPopup} />
                                                        )
                                                    })
                                                    :
                                                    ""
                                                :
                                                ""
                                    }

                                </div>
                            </div>
                            :
                            ""
                    }
                </div>
            </div >
        </>
    )
}
