import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { postChatMsg, updateIsSendStatus } from "../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";

export default function PropertyChat({ ToggleViewMsg, property_mem_id }) {
    const dispatch = useDispatch();
    const isFormProcessing = useSelector((state) => state.sendChat.isFormProcessing);
    const isSend = useSelector((state) => state.sendChat.isSend);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = data => {
        if (property_mem_id !== null && property_mem_id !== undefined && property_mem_id > 0) {
            let frmData = { ...data, property_mem_id: property_mem_id }
            dispatch(postChatMsg(frmData));
        }
        else {
            toast.error('Invalid User or user does not exit in our system!',
                TOAST_SETTINGS
            ); return;
        }

    }
    useEffect(() => {
        if (isSend) {
            ToggleViewMsg()
            dispatch(updateIsSendStatus(false))
        }
    }, [isSend]);
    return <>
        <ToastContainer />
        <section className="popup small_popup send_msg active" data-popup="send-message">
            <div className="tableDv">
                <div className="tableCell">
                    <div className="_inner">
                        <div className="crosBtn" onClick={ToggleViewMsg} />
                        <h4>Send Message</h4>
                        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                            <div className="txtGrp">
                                <textarea name id className="txtBox" placeholder="write your message here"  {...register("msg", { required: "Required" })} />
                                <ErrorMessage
                                    errors={errors}
                                    name="msg"
                                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="webBtn colorBtn" disabled={isFormProcessing}>Send {isFormProcessing ? <i className="spinner"></i> : ""}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
}
