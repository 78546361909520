import React, { useState, useEffect, useRef } from "react";
import Filters from "../includes/filters";
import ExploreGrid from "./section/explore-grid";
import MapSearch from "./section/map-search";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSearchProperties,
  updateISHeaderSearch,
  updateMapBounds,
} from "../../states/actions/fetchSearchProperties";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import { postData } from "../../helpers/api";
import LeafLetMapTest from "./section/leaf-map-test";
const urlParams = new URLSearchParams(window.location.search);
const address = urlParams.get("address");
const type = urlParams.get("type");
let apiExecuted = false;
const currentPage = 1;
const isMarkerHovered = false;
export default function Explore() {
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let url_property_types =
    searchParams.get("property_type") !== null &&
    searchParams.get("property_type")?.length > 0
      ? searchParams.get("property_type").split(",")
      : [];
  let address = searchParams.get("address");
  let type = searchParams.get("type");
  let min_beds =
    searchParams.get("min_beds") !== null ? searchParams.get("min_beds") : 0;
  let max_beds =
    searchParams.get("max_beds") !== null ? searchParams.get("max_beds") : 10;
  let min_baths =
    searchParams.get("min_baths") !== null ? searchParams.get("min_baths") : 0;
  let max_baths =
    searchParams.get("max_baths") !== null ? searchParams.get("max_baths") : 10;
  let min_price =
    searchParams.get("min_price") !== null ? searchParams.get("min_price") : 0;
  let max_price =
    searchParams.get("max_price") !== null
      ? searchParams.get("max_price")
      : 10000000;
  let amenities =
    searchParams.get("amenities") !== null &&
    searchParams.get("amenities")?.length > 0
      ? searchParams.get("amenities").split(",")
      : [];
  let features =
    searchParams.get("features") !== null &&
    searchParams.get("features")?.length > 0
      ? searchParams.get("features").split(",")
      : [];
  let mgt_type =
    searchParams.get("mgt_type") !== null &&
    searchParams.get("mgt_type")?.length > 0
      ? searchParams.get("mgt_type").split(",")
      : ["landlord", "multifamily"];
  let year = searchParams.get("year");
  let available_date = searchParams.get("available_date");
  let sq_ft = searchParams.get("sq_ft");
  let lot_size = searchParams.get("lot_size");
  let laundry = searchParams.get("laundry");
  let sort_by =
    searchParams.get("sort_by") !== null ? searchParams.get("sort_by") : "asc";
  let sort_by_field =
    searchParams.get("sort_by_field") !== null
      ? searchParams.get("sort_by_field")
      : "listing_price";
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSearchProperties.content);
  const propertiesRows = useSelector(
    (state) => state.fetchSearchProperties.properties
  );
  let is_header_search = useSelector(
    (state) => state.fetchSearchProperties.is_header_search
  );
  let filter_with_boundary = useSelector(
    (state) => state.fetchSearchProperties.filter_with_boundary
  );
  const isLoading = useSelector(
    (state) => state.fetchSearchProperties.isLoading
  );
  const map_new_filter_bounds = useSelector(
    (state) => state.fetchSearchProperties.map_bounds
  );
  const map_bounds_zoom = useSelector(
    (state) => state.fetchSearchProperties.map_bounds_zoom
  );
  // const [isMarkerHovered, setIsMarkerHovered] = useState(false);
  const handleMarkerHover = (id) => {
    // setIsMarkerHovered(id);
  };

  const handleMarkerLeave = (id) => {
    // setIsMarkerHovered(false);
  };
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  useEffect(() => {
    dispatch(
      fetchSearchProperties(
        {
          type: type,
          address: address,
          property_type:
            url_property_types !== null && url_property_types?.length > 0
              ? url_property_types
              : [],
          min_beds: min_beds,
          max_beds: max_beds,
          min_baths: min_baths,
          max_baths: max_baths,
          min_price: min_price,
          max_price: max_price,
          amenities:
            amenities !== null && amenities?.length > 0 ? amenities : [],
          features: features !== null && features?.length > 0 ? features : [],
          mgt_type:
            mgt_type !== null && mgt_type?.length > 0
              ? mgt_type
              : ["landlord", "multifamily"],
          year: year,
          available_date: available_date,
          sq_ft: sq_ft,
          lot_size: lot_size,
          laundry: laundry,
          sort_by: sort_by,
          sort_by_field: sort_by_field,
        },
        currentPage
      )
    );
  }, []);
  const [listView, setListView] = useState(null);

  const ToggleListView = () => {
    setListView("list");
  };
  const ToggleGridView = () => {
    setListView(null);
  };
  const [propertyArr, setPropertiesArr] = useState({
    loading: true,
    items: [],
    isRadius: false,
    is_header_search: null,
  });
  const [mapBounds, setMapBounds] = useState(false);
  useEffect(() => {
    setPropertiesArr({
      ...propertyArr,
      items: propertiesRows,
      loading: false,
      boundaryData: data?.boundaries,
      geojson: data?.geojson,
      is_header_search: is_header_search,
    });
    // if (!isFilterSearch) {
    // SetGridProperties([])
    // }
    if (propertiesRows?.length <= 0) {
      SetGridProperties([]);
    }
    if (is_header_search) {
      setClearAddress(false);
    }
  }, [propertiesRows, is_header_search]);
  const [clearLoading, setClearLoading] = useState(false);
  const [clearAddress, setClearAddress] = useState(false);

  const handleRadiusPropertiesOnDrag = async (formData, clear = false) => {
    setPropertiesArr({ ...propertyArr, loading: true });
    let is_header_search_value = is_header_search;
    if (clear === true) {
      setClearAddress(true);
      dispatch(updateISHeaderSearch(null));
      is_header_search_value = null;
      searchParams.delete("address");
      navigate(`?${searchParams.toString()}`);
      document.getElementById("search_address").value = "";
      setClearLoading(true);
    }
    let newData = {
      ...formData,
      type: type,
      address: "",
      property_type:
        url_property_types !== null && url_property_types?.length > 0
          ? url_property_types
          : [],
      min_beds: min_beds,
      max_beds: max_beds,
      min_baths: min_baths,
      max_baths: max_baths,
      min_price: min_price,
      max_price: max_price,
      amenities: amenities !== null && amenities?.length > 0 ? amenities : [],
      features: features !== null && features?.length > 0 ? features : [],
      mgt_type:
        mgt_type !== null && mgt_type?.length > 0
          ? mgt_type
          : ["landlord", "multifamily"],
      year: year,
      available_date: available_date,
      sq_ft: sq_ft,
      lot_size: lot_size,
      laundry: laundry,
      radius_search: true,
      is_header_search: is_header_search_value,
    };

    await postData("api/search-properties/" + currentPage, newData).then(
      (res) => {
        // if (res?.properties?.length > 0) {
        if (clear === true) {
          setPropertiesArr({
            ...propertyArr,
            items: res?.properties,
            loading: false,
            isRadius: true,
            boundaryData: [],
            geojson: [],
            is_header_search: null,
          });
          SetGridProperties(res?.properties);
        } else {
          setPropertiesArr({
            ...propertyArr,
            items: res?.properties,
            loading: false,
            isRadius: true,
            boundaryData: res?.boundaries,
            geojson: res?.geojson,
            is_header_search: res?.is_header_search,
          });
          SetGridProperties(res?.properties);
        }

        if (clear === true) {
          setClearLoading(false);
        }
        // }
      }
    );
  };
  const [gridProperties, SetGridProperties] = useState([]);
  const [currentMapBounds, SetCurrentMapBounds] = useState(false);
  const [mapCurrentZoom, setMapCurrentZoom] = useState(false);

  const handleRadiusPropertiesOnDraggingNew = async (
    formData,
    clear = false
  ) => {
    if (clear === true) {
      setClearLoading(true);
    }
    setPropertiesArr({ ...propertyArr, loading: true });
    let newData = {
      ...formData,
      type: type,
      address: address,
      property_type:
        url_property_types !== null && url_property_types?.length > 0
          ? url_property_types
          : [],
      min_beds: min_beds,
      max_beds: max_beds,
      min_baths: min_baths,
      max_baths: max_baths,
      min_price: min_price,
      max_price: max_price,
      amenities: amenities !== null && amenities?.length > 0 ? amenities : [],
      features: features !== null && features?.length > 0 ? features : [],
      mgt_type:
        mgt_type !== null && mgt_type?.length > 0
          ? mgt_type
          : ["landlord", "multifamily"],
      year: year,
      available_date: available_date,
      sq_ft: sq_ft,
      lot_size: lot_size,
      laundry: laundry,
    };
    //return newData;
    const response = await postData(
      "api/search-properties/" + currentPage,
      newData
    );
    setPropertiesArr({
      ...propertyArr,
      items: response?.properties,
      loading: false,
      isRadius: true,
    });
    if (clear === true) {
      setPropertiesArr({
        ...propertyArr,
        items: response?.properties,
        loading: false,
        isRadius: true,
        boundaryData: [],
        geojson: [],
      });
    } else {
      setPropertiesArr({
        ...propertyArr,
        items: response?.properties,
        loading: false,
        isRadius: true,
        boundaryData: response?.boundaries,
        geojson: response?.geojson,
      });
    }
    return response;
  };
  const [isMobSearch, setIsMobSearch] = useState(false);

  const searchProperties = (frmData) => {
    // setIsFilterSearch(true)
    // let newFrmData = { ...frmData, ...mapBounds }
    dispatch(fetchSearchProperties(frmData, currentPage));
  };
  const updateLeafLetMapBounds = () => {
    if (mapRef && mapRef?.current) {
      let bounds = mapRef.current.getBounds();
      let zoom = mapRef.current.getZoom();
      dispatch(updateMapBounds({ bounds: bounds, zoom, zoom }));
    }
    // setIsFilterSearch(true)
    // let newFrmData = { ...frmData, ...mapBounds }
  };

  useDocumentTitle(
    address !== "" && address !== undefined && address !== null
      ? "Explore Properties for: " + address + "- Loftus"
      : "Explore Properties - Loftus"
  );
  if (isSiteSettingsLoading) return <LoadingScreen />;
  const properties = propertyArr?.items;
  const dragLoading = propertyArr?.loading;
  const boundaryData = propertyArr?.boundaryData;
  const geojson = propertyArr?.geojson;
  const map_center = data?.map_center;
  const bounding_box = data?.bounding_box;
  const map_boundary_coordinates = data?.map_boundary_coordinates;
  const amenities_arr = data?.amenities;
  const features_arr = data?.features;
  return (
    <>
      <section
        className={
          isMobSearch ? "search_result_page active" : "search_result_page"
        }
      >
        <Filters
          ToggleListView={ToggleListView}
          ToggleGridView={ToggleGridView}
          total={gridProperties?.length}
          type={type}
          searchProperties={searchProperties}
          amenities_arr={amenities_arr}
          features_arr={features_arr}
          listView={listView}
          clearAddress={clearAddress}
          is_header_search={propertyArr?.is_header_search}
          updateLeafLetMapBounds={updateLeafLetMapBounds}
          setIsMobSearch={setIsMobSearch}
          isMobSearch={isMobSearch}
        />
        <div className="flex listing_relative">
          <ExploreGrid
            data={gridProperties}
            listView={listView}
            isLoading={isLoading}
            isMarkerHovered={isMarkerHovered}
            handleMarkerHover={handleMarkerHover}
            handleMarkerLeave={handleMarkerLeave}
            dragLoading={dragLoading}
            properties={properties}
          />
          {/* <LeafLetMapTest data={properties} /> */}

          {clearLoading ? (
            <div className="colR">
              <div className="outer_map">
                <div className="map">
                  <div id="map_canvas">
                    <div id="leadingMainContainer">
                      <div className="loading-container">
                        <div className="loading"></div>
                        <div id="loading-text">loading</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <MapSearch
              markers={properties}
              boundaryData={boundaryData}
              map_center={map_center}
              bounding_box={bounding_box}
              handleRadiusPropertiesOnDrag={handleRadiusPropertiesOnDrag}
              map_boundary_coordinates={map_boundary_coordinates}
              isMarkerHovered={isMarkerHovered}
              handleRadiusPropertiesOnDraggingNew={
                handleRadiusPropertiesOnDraggingNew
              }
              geojson={geojson}
              setMapBounds={setMapBounds}
              SetGridProperties={SetGridProperties}
              SetCurrentMapBounds={SetCurrentMapBounds}
              currentMapBounds={currentMapBounds}
              is_header_search={propertyArr?.is_header_search}
              setMapCurrentZoom={setMapCurrentZoom}
              mapCurrentZoom={mapCurrentZoom}
              filter_with_boundary={filter_with_boundary}
              updateLeafLetMapBounds={updateLeafLetMapBounds}
              map_new_filter_bounds={map_new_filter_bounds}
              mapRef={mapRef}
              map_bounds_zoom={map_bounds_zoom}
            />
          )}
        </div>
      </section>
    </>
  );
}
