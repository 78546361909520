import React from "react";
import { Link } from "react-router-dom";
import Image from "../../common/image";
export default function PopularPost({ data }) {
    return (
        <>
            <div className="blog_side_blk">
                <h4>{data.sub_heading}</h4>
                <ul>
                    {data.popular_list.map((val, index) => {
                        return (
                            <li key={index}>
                                <Link to={val.link} className="small_image">
                                    <Image src={val.image} alt={val.title} />
                                </ Link>
                                <div className="txt">
                                    <h5><Link to={val.link}>{val.title}</ Link></h5>
                                    <div className="blog_date">{val.date}</div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}
