import React from 'react'
import TenantBlk from './tenantBlk'

export default function OfferTenants({ tenants, showPopup, listing_type, listingSelectedPackage, is_landlord, listing_row, offer_listing_type }) {
    return (
        <>
            {tenants?.map((tenant) => {
                return (
                    <TenantBlk tenant={tenant} showPopup={showPopup} listing_type={listing_type} listingSelectedPackage={listingSelectedPackage} is_landlord={is_landlord} listing_row={listing_row} offer_listing_type={offer_listing_type} />
                )
            })}
        </>
    )
}
