import React from "react";

export default function PromotionSort({ branches, handleBranchChange, changeType, promotionType, active_count, expired_count }) {
    return <>
        <div className="flterFlexListing flex">
            <ul className="nav nav-tabs">
                {
                    active_count > 0 ?
                        <li className={promotionType === "active" ? 'active' : ''}><a className="a" data-toggle="tab" href="#" onClick={() => changeType('active')}>
                            Active <span>({active_count !== undefined && active_count > 0 ? active_count : 0})</span></a>
                        </li>
                        :
                        ""
                }
                {
                    expired_count > 0 ?

                        <li className={promotionType === "expired" ? 'active' : ''}><a className='' data-toggle="tab" href="#" onClick={() => changeType('expired')}>
                            Expired <span>({expired_count !== undefined && expired_count > 0 ? expired_count : 0})</span></a>
                        </li>
                        :
                        ""
                }
            </ul>
            <div className="filter_status_offer cell_status_offer_cell">
                <div className="col_ofer_filter">
                    <div className="sorted_heading">
                        <span>Branch :</span>
                    </div>
                    <div className="sorted_drop_down">
                        <select name="branch_id" id="branch_id" className="txtBox" onChange={(e) => handleBranchChange(e, 'branch_id')}>
                            <option value="all">All</option>
                            {branches?.map((branch, index) => {
                                return <option value={branch?.value} key={index}>{branch?.label}</option>;
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
