import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const RangeSlider = ({
  min,
  max,
  icon,
  is_price,
  register,
  handleChange,
  key_name,
  start = 0,
  end = 10,
}) => {
  const [values, setValues] = useState([parseFloat(min), parseFloat(max)]);
  useEffect(() => {
    setValues([parseFloat(min), parseFloat(max)]);
  }, [parseFloat(min), parseFloat(max)]);

  return (
    <div className="range-slider">
      <Slider
        range
        min={start}
        max={end}
        value={values}
        onChange={(e) => handleChange(e, key_name)}
        step={1}
      />
      <div className="range-labels">
        {is_price === true ? (
          <div className="range-input">
            <div className="txtGrp">
              <input
                type="number"
                className="txtBox"
                defaultValue={parseFloat(values[0])}
                {...register("min_price")}
                onChange={(e) =>
                  handleChange([e.target.value, values[1]], key_name)
                }
              />
              {icon !== false ? <span className="rangeIcon">{icon}</span> : ""}
            </div>
            <div className="txtGrp">
              <input
                type="number"
                className="txtBox"
                defaultValue={parseFloat(values[1])}
                {...register("max_price")}
                onChange={(e) =>
                  handleChange([values[0], e.target.value], key_name)
                }
              />
              {icon !== false ? <span className="rangeIcon">{icon}</span> : ""}
            </div>
          </div>
        ) : (
          <>
            <label>{key_name === "beds" && min === 0 ? "Studio" : min}</label>
            <label>{max}</label>
          </>
        )}
      </div>
    </div>
  );
};

export default RangeSlider;
