import React from "react";
import CreditLst from "./lst";

export default function CreditBlk({ credits, handleDelete, type = "pending" }) {
  return (
    <>
      {credits?.length > 0 ? (
        credits?.map((credit_row, index) => {
          return (
            <CreditLst
              credit_row={credit_row}
              key={index}
              handleDelete={handleDelete}
              type={type}
            />
          );
        })
      ) : (
        <div className="alert alert-danger">No item(s) added yet!</div>
      )}
    </>
  );
}
