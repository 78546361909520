import React from "react";
import { format_amount } from "../../../../helpers/api";

export default function ExpenseCostBlk({ costs, title }) {
    return <>
        {
            costs?.length > 0 ?
                <div className="col colL">
                    <h5>{title}</h5>
                    <div className="inner">
                        {
                            costs?.map((cost, index) => {
                                return (
                                    <div className="balance_lst" key={index}>
                                        <div>{cost?.name}</div>
                                        <div>{format_amount(cost?.cost)}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                :
                ""
        }
    </>;
}
