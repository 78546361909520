import React, { useState, useEffect } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import LeaseTabsMenu from "./sections/lease-tabs-menu";
import LeaseTabs from "./sections/leasesTabs";

import { getLeases, getFilteredLeases } from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import Sidebar from "./sections/sidebar";
export default function Leases({ mem_type }) {
  const mem_permissions = localStorage.getItem("mem_permissions");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.leases.content);
  const {
    count_landlord_leases,
    count_tenant_leases,
    properties,
    landlord_all_properties,
    pending_landlord_leases,
    tenant_pending_leases,
    accepted_landlord_leases,
    tenant_accepted_leases,
    rejected_landlord_leases,
    tenant_rejected_leases,
    tenant_properties,
    branches,
    expired_landlord_leases,
    tenant_expired_leases,
    lease_tab_type,
    tenant_properties_arr,
    lease_main_type,
    lease_sub_tab_type,
  } = data;
  const isLoading = useSelector((state) => state.leases.isLeaseLoading);
  const isFilterLoading = useSelector((state) => state.leases.isFilterLoading);
  useEffect(() => {
    dispatch(getLeases({ mem_type: mem_type }));
  }, []);
  const [propertyFilter, setPropertyFilter] = useState(null);
  const [type, setLeaseType] = useState("landlord");
  const [leaseStatus, setLeaseStatus] = useState("active");
  const [myLeaseType, setMyLeaseType] = useState("pending");

  useEffect(() => {
    setLeaseType(lease_main_type);

    setMyLeaseType(lease_sub_tab_type);
  }, [lease_main_type, lease_sub_tab_type]);
  useEffect(() => {}, [type, myLeaseType]);

  const changeType = (type) => {
    setLeaseType(type);
  };
  const handleFilterLease = (value, typeSelected) => {
    let frmData = null;
    if (typeSelected === "type") {
      changeType(value);
      setPropertyFilter(null);
      frmData = {
        type: value,
        status: leaseStatus,
        property: null,
        lease_tab_type: null,
        mem_type: mem_type,
      };
    }
    if (typeSelected === "status") {
      frmData = {
        status: value,
        type: null,
        property: null,
        lease_tab_type: myLeaseType,
        mem_type: mem_type,
      };
    }
    if (typeSelected === "lease_tab_type") {
      frmData = {
        status: leaseStatus,
        type: type,
        property: propertyFilter,
        lease_tab_type: value,
        mem_type: mem_type,
      };
    }
    if (typeSelected === "property") {
      setPropertyFilter(value);
      frmData = {
        status: leaseStatus,
        type: type,
        property: value,
        lease_tab_type: myLeaseType,
        mem_type: mem_type,
      };
    }
    if (typeSelected === "branch") {
      setPropertyFilter(value);
      frmData = {
        status: leaseStatus,
        type: type,
        branch: value,
        lease_tab_type: myLeaseType,
        mem_type: mem_type,
      };
    }
    dispatch(getFilteredLeases(frmData));
  };
  useDocumentTitle("Leases - Loftus");
  if (isLoading) return <LoadingScreen />;
  console.log(data);
  return (
    <>
      <div
        index=""
        className={
          mem_type === "company"
            ? "main_dashboard commercial_dashboard_new"
            : "main_dashboard"
        }
      >
        <section className="dashboard leases_dashboard">
          <div className="contain">
            {mem_type === "company" ? (
              <div className="flex dash_intro_flex">
                <div className="colL">
                  <Sidebar active="leases" />
                </div>
                <div className="colR ">
                  <Breadcrumb
                    page_name="Leases"
                    popup_show={true}
                    popup_name="choose-lease-property"
                    leases_properties={landlord_all_properties}
                    mem_type={mem_type}
                    branches={branches}
                    mem_permissions={mem_permissions}
                  />
                  <div className="page_body">
                    <div className="offer_dash_page leases_page_dash">
                      <LeaseTabsMenu
                        type={type}
                        myLeaseType={myLeaseType}
                        setMyLeaseType={setMyLeaseType}
                        pending_landlord_leases={pending_landlord_leases}
                        tenant_pending_leases={tenant_pending_leases}
                        accepted_landlord_leases={accepted_landlord_leases}
                        tenant_accepted_leases={tenant_accepted_leases}
                        rejected_landlord_leases={rejected_landlord_leases}
                        tenant_rejected_leases={tenant_rejected_leases}
                        handleType={changeType}
                        handleFilterLease={handleFilterLease}
                        setPropertyFilter={setPropertyFilter}
                        propertyFilter={propertyFilter}
                        properties={properties}
                        branches={branches}
                        tenant_properties={tenant_properties}
                        expired_landlord_leases={expired_landlord_leases}
                        tenant_expired_leases={tenant_expired_leases}
                        count_landlord_leases={count_landlord_leases}
                        count_tenant_leases={count_tenant_leases}
                        tenant_properties_arr={tenant_properties_arr}
                        leases_filter="company"
                      />
                      <LeaseTabs
                        type={type}
                        pending_landlord_leases={pending_landlord_leases}
                        tenant_pending_leases={tenant_pending_leases}
                        setMyLeaseType={setMyLeaseType}
                        myLeaseType={myLeaseType}
                        accepted_landlord_leases={accepted_landlord_leases}
                        tenant_accepted_leases={tenant_accepted_leases}
                        rejected_landlord_leases={rejected_landlord_leases}
                        tenant_rejected_leases={tenant_rejected_leases}
                        handleType={changeType}
                        isFilterLoading={isFilterLoading}
                        leaseStatus={leaseStatus}
                        setLeaseStatus={setLeaseStatus}
                        expired_landlord_leases={expired_landlord_leases}
                        tenant_expired_leases={tenant_expired_leases}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <Breadcrumb
                  page_name="Leases"
                  popup_show={true}
                  popup_name="choose-lease-property"
                  leases_properties={landlord_all_properties}
                  mem_type={mem_type}
                  branches={branches}
                />
                <div className="page_body">
                  <div className="offer_dash_page leases_page_dash">
                    <LeaseTabsMenu
                      type={type}
                      myLeaseType={myLeaseType}
                      setMyLeaseType={setMyLeaseType}
                      pending_landlord_leases={pending_landlord_leases}
                      tenant_pending_leases={tenant_pending_leases}
                      accepted_landlord_leases={accepted_landlord_leases}
                      tenant_accepted_leases={tenant_accepted_leases}
                      rejected_landlord_leases={rejected_landlord_leases}
                      tenant_rejected_leases={tenant_rejected_leases}
                      handleType={changeType}
                      handleFilterLease={handleFilterLease}
                      setPropertyFilter={setPropertyFilter}
                      propertyFilter={propertyFilter}
                      properties={properties}
                      tenant_properties={tenant_properties}
                      expired_landlord_leases={expired_landlord_leases}
                      tenant_expired_leases={tenant_expired_leases}
                      count_landlord_leases={count_landlord_leases}
                      count_tenant_leases={count_tenant_leases}
                      tenant_properties_arr={tenant_properties_arr}
                    />
                    <LeaseTabs
                      type={type}
                      pending_landlord_leases={pending_landlord_leases}
                      tenant_pending_leases={tenant_pending_leases}
                      setMyLeaseType={setMyLeaseType}
                      myLeaseType={myLeaseType}
                      accepted_landlord_leases={accepted_landlord_leases}
                      tenant_accepted_leases={tenant_accepted_leases}
                      rejected_landlord_leases={rejected_landlord_leases}
                      tenant_rejected_leases={tenant_rejected_leases}
                      handleType={changeType}
                      isFilterLoading={isFilterLoading}
                      expired_landlord_leases={expired_landlord_leases}
                      tenant_expired_leases={tenant_expired_leases}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
