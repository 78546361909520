import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import { removeArrayItem } from "../../../../helpers/api";
import { NavLink } from "react-router-dom";
let question1_options = [
  {
    id: 1,
    label: "What is the name of a college you applied to but didn't attend?",
    value: "What is the name of a college you applied to but didn't attend?",
  },
  {
    id: 2,
    label: "In what city or town was your first job?",
    value: "In what city or town was your first job?",
  },
  {
    id: 3,
    label: "What was your favorite place to visit as a child?",
    value: "What was your favorite place to visit as a child?",
  },
  {
    id: 4,
    label: "Who is your favorite actor, musician, or artist?",
    value: "Who is your favorite actor, musician, or artist?",
  },
  {
    id: 5,
    label: "What is the name of your favorite pet?",
    value: "What is the name of your favorite pet?",
  },
  {
    id: 6,
    label: "In what city were you born?",
    value: "In what city were you born?",
  },
];
let question2_options = [
  {
    id: 1,
    label: "What was the make and model of your first car?",
    value: "What was the make and model of your first car?",
  },
  {
    id: 2,
    label: "What is the name of the first undergraduate college you attended?",
    value: "What is the name of the first undergraduate college you attended?",
  },
  {
    id: 3,
    label: "What year did you graduate from High School?",
    value: "What year did you graduate from High School?",
  },
  {
    id: 4,
    label: "What was the last name of your third grade teacher?",
    value: "What was the last name of your third grade teacher?",
  },
  {
    id: 5,
    label: "In what city did you meet your spouse?",
    value: "In what city did you meet your spouse?",
  },
  {
    id: 6,
    label: "What was your favorite sport in high school?",
    value: "What was your favorite sport in high school?",
  },
];
let question3_options = [
  {
    id: 1,
    label: "What is your oldest sibling’s middle name?",
    value: "What is your oldest sibling’s middle name?",
  },
  {
    id: 2,
    label: "What is the name of your first school?",
    value: "What is the name of your first school?",
  },
  {
    id: 3,
    label: "What is your favorite movie?",
    value: "What is your favorite movie?",
  },
  {
    id: 4,
    label: "What is your mother's maiden name?",
    value: "What is your mother's maiden name?",
  },
  {
    id: 5,
    label: "In what city or town did your mother and father meet?",
    value: "In what city or town did your mother and father meet?",
  },
  {
    id: 6,
    label: "Who was your childhood hero?",
    value: "Who was your childhood hero?",
  },
];

export default function QuestionsForm({
  handleQuestionForm,
  isFormProcessing,
  security_questions,
  questionAdded,
}) {
  const selectInputRef = useRef();
  const [questions, setQuestions] = useState({
    question1: security_questions?.question1,
    question2: security_questions?.question2,
    question3: security_questions?.question3,
    answer1: security_questions?.answer1,
    answer2: security_questions?.answer2,
    answer3: security_questions?.answer3,
  });
  const [changeQuestion, setChangeQuestions] = useState(false);
  const [sQuestion, setSecurityQuestion] = useState(
    Array.isArray(security_questions) ? "" : security_questions
  );
  const [qAdded, setQuestionAdded] = useState(questionAdded);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const handleChangeQuestion = () => {
    setChangeQuestions(true);
    // setSecurityQuestion('');
    setQuestionAdded(false);
  };
  useEffect(() => {
    if (
      questions?.question1 !== undefined &&
      questions?.question2 !== undefined &&
      questions?.question3 !== undefined
    ) {
      setValue("question1", questions?.question1);
      setValue("question2", questions?.question2);
      setValue("question3", questions?.question3);
    }
  }, [questions]);

  useEffect(() => {
    if (questionAdded === true) {
      setQuestionAdded(true);
    }
  }, [questionAdded]);
  useEffect(() => {}, [qAdded]);
  const handleChange = (question) => {
    if (question === 1) {
      document.getElementById("answer1").value = "";
      setValue("answer1", "");
    } else if (question === 2) {
      document.getElementById("answer2").value = "";
      setValue("answer2", "");
    } else if (question === 3) {
      document.getElementById("answer3").value = "";
      setValue("answer3", "");
    }
  };
  const onSubmit = (data) => {
    // ; return;
    if (sQuestion === "" || qAdded === false) {
      handleQuestionForm(data);
    }
  };

  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div className="setting_blk">
            {sQuestion !== "" ? (
              <div className="settings_header questions_header">
                {/* <h4>Security Questions</h4> */}
                <div className="bTn">
                  <NavLink
                    to="#!"
                    className="webBtn smBtn"
                    onClick={handleChangeQuestion}
                  >
                    Change Questions
                  </NavLink>
                </div>
              </div>
            ) : (
              ""
            )}

            {changeQuestion === true ? (
              <>
                <div className="row form_row changePasswordBorder">
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <div className="form_blk">
                        <Controller
                          {...register("question1", { required: "Required" })}
                          name="question1"
                          control={control}
                          defaultValue={questions?.question1}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <Select
                              options={question1_options}
                              label="Select Question"
                              onChange={(val) => {
                                onChange(val.value);
                                handleChange(1);
                              }}
                              value={question1_options.find(
                                (c) => c.value === value
                              )}
                              // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="question1"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        name="answer1"
                        id="answer1"
                        className="txtBox"
                        {...register("answer1", { required: "Required" })}
                        defaultValue="XXXXXXX"
                      />
                      {/* <input type="text" name='answer1' id='answer1' className="txtBox" {...register("answer1", { required: "Required" })} defaultValue={(sQuestion !== '' || qAdded === true) ? 'XXXXXXX' : ''} disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false} /> */}
                      <ErrorMessage
                        errors={errors}
                        name="answer1"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <Controller
                        {...register("question2", { required: "Required" })}
                        name="question2"
                        control={control}
                        defaultValue={questions?.question2}
                        ref={selectInputRef}
                        render={({ field: { onChange, value, ref, name } }) => (
                          <Select
                            options={question2_options}
                            label="Select Question"
                            onChange={(val) => {
                              onChange(val.value);
                              handleChange(2);
                            }}
                            value={question2_options.find(
                              (c) => c.value === value
                            )}
                            // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="question2"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        name="answer2"
                        id="answer2"
                        className="txtBox"
                        {...register("answer2", { required: "Required" })}
                        defaultValue="XXXXXXX"
                        // disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="answer2"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <Controller
                        {...register("question3", { required: "Required" })}
                        name="question3"
                        control={control}
                        defaultValue={questions?.question3}
                        render={({ field: { onChange, value, ref, name } }) => (
                          <Select
                            options={question3_options}
                            label="Select Question"
                            onChange={(val) => {
                              onChange(val.value);
                              handleChange(3);
                            }}
                            value={question3_options.find(
                              (c) => c.value === value
                            )}
                            // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="question3"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        id="answer3"
                        namee="answer3"
                        className="txtBox"
                        {...register("answer3", { required: "Required" })}
                        defaultValue="XXXXXXX"
                        // disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="answer3"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="borderForm">
                      <h6>Confirm Your password</h6>
                      <div className="form_blk">
                        <div className="form_blk">
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="txtBox"
                            {...register("password", { required: "Required" })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="password"
                            render={({ message }) => (
                              <p className="error">
                                <i className="fi-warning"></i> {message}
                              </p>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : security_questions === undefined ||
              security_questions?.length <= 0 ? (
              <>
                <div className="row form_row changePasswordBorder">
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <div className="form_blk">
                        <Controller
                          {...register("question1", { required: "Required" })}
                          name="question1"
                          control={control}
                          defaultValue={questions?.question1}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <Select
                              options={question1_options}
                              label="Select Question"
                              onChange={(val) => {
                                onChange(val.value);
                                handleChange(1);
                              }}
                              value={question1_options.find(
                                (c) => c.value === value
                              )}
                              // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="question1"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        name="answer1"
                        id="answer1"
                        className="txtBox"
                        {...register("answer1", { required: "Required" })}
                      />
                      {/* <input type="text" name='answer1' id='answer1' className="txtBox" {...register("answer1", { required: "Required" })} defaultValue={(sQuestion !== '' || qAdded === true) ? 'XXXXXXX' : ''} disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false} /> */}
                      <ErrorMessage
                        errors={errors}
                        name="answer1"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <Controller
                        {...register("question2", { required: "Required" })}
                        name="question2"
                        control={control}
                        defaultValue={questions?.question2}
                        ref={selectInputRef}
                        render={({ field: { onChange, value, ref, name } }) => (
                          <Select
                            options={question2_options}
                            label="Select Question"
                            onChange={(val) => {
                              onChange(val.value);
                              handleChange(2);
                            }}
                            value={question2_options.find(
                              (c) => c.value === value
                            )}
                            // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="question2"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        name="answer2"
                        id="answer2"
                        className="txtBox"
                        {...register("answer2", { required: "Required" })}
                        // disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="answer2"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Select Question</h6>
                    <div className="form_blk">
                      <Controller
                        {...register("question3", { required: "Required" })}
                        name="question3"
                        control={control}
                        defaultValue={questions?.question3}
                        render={({ field: { onChange, value, ref, name } }) => (
                          <Select
                            options={question3_options}
                            label="Select Question"
                            onChange={(val) => {
                              onChange(val.value);
                              handleChange(3);
                            }}
                            value={question3_options.find(
                              (c) => c.value === value
                            )}
                            // isDisabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="question3"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <h6>Answer</h6>
                    <div className="form_blk">
                      <input
                        type="text"
                        id="answer3"
                        name="answer3"
                        className="txtBox"
                        {...register("answer3", { required: "Required" })}
                        // disabled={(sQuestion !== '' || qAdded === true) ? 'disabled' : false}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="answer3"
                        render={({ message }) => (
                          <p className="error">
                            <i className="fi-warning"></i> {message}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className="col-xs-12">
                                                    <div className='borderForm'>
                                                        <h6>Confirm Your password</h6>
                                                        <div className="form_blk">
                                                            <div className="form_blk">
                                                                <input type="password" name='password' id='password' className="txtBox" {...register("password", { required: "Required" })} />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="password"
                                                                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                </div>
              </>
            ) : (
              <div className="row form_row">
                <div className="col-xs-12">
                  <h6>Select Question</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {questions?.question1}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <h6>Answer</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {/* {questions?.answer1} */}XXXXXXX
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <h6>Select Question</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {questions?.question2}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <h6>Answer</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {/* {questions?.answer2} */}XXXXXXX
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <h6>Select Question</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {questions?.question3}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12">
                  <h6>Answer</h6>
                  <div className="form_blk">
                    <div className="form_blk static_form_blk">
                      <div className="txtBox" disabled>
                        {/* {questions?.answer3} */}XXXXXXX
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {Array.isArray(security_questions) === false ? (
              changeQuestion === false ? (
                ""
              ) : (
                <div className="cmnBtn text-center">
                  <button
                    type="submit"
                    className="webBtn colorBtn"
                    disabled={isFormProcessing === true ? "disabled" : ""}
                  >
                    {isFormProcessing === true ? (
                      <i className="spinner"></i>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              )
            ) : qAdded === true ? (
              ""
            ) : (
              <div className="cmnBtn text-center">
                <button
                  type="submit"
                  className="webBtn colorBtn"
                  disabled={isFormProcessing === true ? "disabled" : ""}
                >
                  {isFormProcessing === true ? (
                    <i className="spinner"></i>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            )}
          </div>
        </fieldset>
      </form>
    </>
  );
}
