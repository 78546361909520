import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toSluUrl } from '../../../helpers/api';
import { ErrorMessage } from '@hookform/error-message';
export default function AdvanceFilter(props) {
    const filtersRef = useRef(null);
    const { filter, closePopup, features_arr, amenities_arr, saveSearch, register, setValue, closeFilter, errors } = props;
    const [isLandlord, setIsLandlord] = useState(false);
    const [isMultiFamilu, setIsMultiFamilu] = useState(false);
    useEffect(() => {
        if (filter?.mgt_type?.includes('landlord')) {
            setIsLandlord(true)
        }
        if (filter?.mgt_type?.includes('multifamily')) {
            setIsMultiFamilu(true)
        }
    }, [filter?.mgt_type]);
    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!event.target.closest('._inner') && !event.target.closest('.more_filters')) {
                closePopup();
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    function convertStringArrayToIntegers(items) {
        let renderedItems = [];
        if (items?.length > 0) {

            for (let i = 0; i < items.length; i++) {
                renderedItems.push(parseInt(items[i]));
            }

        }
        return renderedItems;
    }
    const handleDateChange = (date) => {
        setValue('available_date', date, { shouldValidate: true }); // Set the value of the 'fieldName' field
    };
    return (
        <>
            <section className="popup filter_popup" data-popup="filter" style={{ display: "block" }} ref={filtersRef}>
                <div className="tableDv">
                    <div className="tableCell">
                        <div className="_inner">
                            <div className="crosBtn" onClick={() => closePopup()} />
                            <div className="filter_inner">
                                <h2>Filters</h2>
                                <form action method>
                                    <div className="show_mobile_filter_explore">
                                        <div className="cmn_lnk_filter flex filt_divs">
                                            <div className="check_cmn_filter_btn">
                                                <div className="lblBtn">
                                                    <input type="radio" name="status" value="buy" className="hidden" />
                                                    <label htmlFor>Buy</label>
                                                </div>
                                                <div className="lblBtn active">
                                                    <input type="radio" name="status" value="rent" className="hidden" defaultChecked />
                                                    <label htmlFor>Rent</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Price</h3>
                                            <p>The average nightly price is $346</p>
                                            <input type="range" id="price" name value className="_price" />
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Bedrooms</h3>
                                            <div className="qtyBtn">
                                                <input type="button" value="-" className="qtyminus readBtn" />
                                                <input type="text" name="quantity" value={1} className="qty" />
                                                <input type="button" value="+" className="qtyplus readBtn" />
                                            </div>
                                        </div>
                                        <div className="filt_divs">
                                            <h3>Bathrooms</h3>
                                            <div className="qtyBtn">
                                                <input type="button" value="-" className="qtyminus readBtn" />
                                                <input type="text" name="quantity" value={1} className="qty" />
                                                <input type="button" value="+" className="qtyplus readBtn" />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="filt_divs">
                                        <h3>Listing Information</h3>
                                        <div className="row formRow">
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="year" defaultValue={filter?.year !== null ? filter?.year : ''} className="txtBox" placeholder="Year Built"  {...register('year', {

                                                        pattern: {
                                                            value: /^[0-9\b]+$/,
                                                            message: 'Please enter only numeric values',
                                                        },
                                                    })} tabindex="1" />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="year"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                filter?.type === 'rent' ?

                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                        <div className="txtGrp">
                                                            <DatePicker
                                                                className="txtBox"
                                                                name="available_date"
                                                                selected={filter?.available_date} placeholderText="Move-in Date"
                                                                {...register('available_date')}

                                                                minDate={new Date()}
                                                                onChange={handleDateChange}
                                                                tabindex="2"
                                                            />
                                                            {/* <input type="text" name="available_date" value="" className="txtBox datepicker" placeholder="Available Date" /> */}
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                            }
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="sq_ft" defaultValue={filter?.sq_ft} className="txtBox" placeholder="Square Feet"   {...register('sq_ft', {

                                                        pattern: {
                                                            value: /^[0-9\b]+$/,
                                                            message: 'Please enter only numeric values',
                                                        },
                                                    })} tabindex="3" />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="sq_ft"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xx-4">
                                                <div className="txtGrp">
                                                    <input type="text" name="lot_size" className="txtBox" defaultValue={filter?.lot_size} placeholder="Lot Size"   {...register('lot_size', {

                                                        pattern: {
                                                            value: /^[0-9\b]+$/,
                                                            message: 'Please enter only numeric values',
                                                        },
                                                    })} tabindex="4" />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="lot_size"
                                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filt_divs">
                                        <h3>Laundry</h3>
                                        <div className="flex filter_property_type">
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" defaultValue="In Unit" id="_unit" {...register("laundry")} defaultChecked={filter?.laundry?.includes('In Unit')} tabindex="5" />
                                                <label htmlFor="_unit">In Unit</label>
                                            </div>
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" defaultValue="Laundry Facility" id="_laundry" {...register("laundry")} defaultChecked={filter?.laundry?.includes('Laundry')} tabindex="6" />
                                                <label htmlFor="_laundry">Laundry Facility</label>
                                            </div>
                                            <div className="lblBtn">
                                                <input type="radio" name="laundry" defaultValue="None" id="none" {...register("laundry")} defaultChecked={filter?.laundry?.includes('None')} tabindex="7" />
                                                <label htmlFor="none">None</label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        filter?.type === 'rent' ?
                                            <div className="filt_divs">
                                                <h3>Management Type</h3>
                                                <div className="flex filter_property_type suitability_flex">

                                                    <>
                                                        <div className="lblBtn">
                                                            <div className="switchBtn">
                                                                <input type="checkbox" name="mgt_type" defaultValue='landlord' id='landlord'
                                                                    {...register("mgt_type")} defaultChecked={isLandlord} />
                                                            </div>
                                                            <label htmlFor="landlord">Landlord</label>
                                                        </div>
                                                        <div className="lblBtn">
                                                            <div className="switchBtn">
                                                                <input type="checkbox" name="mgt_type" defaultValue='multifamily' id='multifamily' defaultChecked={isMultiFamilu} {...register("mgt_type")} />
                                                            </div>
                                                            <label htmlFor="multifamily">Multifamily</label>
                                                        </div>
                                                    </>


                                                </div>
                                            </div>
                                            :
                                            ""
                                    }
                                    <div className="filt_divs">
                                        <h3>Amenities</h3>
                                        <div className="flex filter_property_type suitability_flex">
                                            {
                                                amenities_arr && amenities_arr?.length > 0 ?
                                                    amenities_arr?.map((item, index) => {
                                                        return (
                                                            <div className="lblBtn" key={index}>
                                                                <div className="switchBtn">
                                                                    <input type="checkbox" name="amenities" defaultValue={item.id} id={toSluUrl(item.title)} {...register("amenities")} defaultChecked={filter?.amenities?.includes(item?.id)} />
                                                                </div>
                                                                <label htmlFor={toSluUrl(item.title)}>{item.title}</label>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }
                                        </div>
                                    </div>
                                    <div className="filt_divs">
                                        <h3>Features</h3>
                                        <div className="flex filter_property_type suitability_flex">
                                            {
                                                features_arr && features_arr?.length > 0 ?
                                                    features_arr?.map((item, index) => {
                                                        return (
                                                            <div className="lblBtn" key={index}>
                                                                <div className="switchBtn">
                                                                    <input type="checkbox" name="features" defaultValue={item.id} id={toSluUrl(item.title)} {...register("features")} defaultChecked={filter?.features?.includes(item?.id)} />
                                                                </div>
                                                                <label htmlFor={toSluUrl(item.title)}>{item.title}</label>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    ""
                                            }

                                        </div>
                                    </div>
                                    <div className="filt_divs">
                                        <div className="filter_opt flex">
                                            <button type="button" className="save_opt" onClick={() => saveSearch('popup')}>Save</button>
                                            <button type="button" className="clear_opt" onClick={() => closeFilter('popup', true)}>Clear All</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
