import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { convertSimpleDateUsingMoment } from "../../../../../helpers/api";
import moment from "moment";
export default function PromotionStep1({
  setFormStep,
  formStep,
  branches,
  register,
  errors,
  control,
  isValid,
  watchAllFields,
  trigger,
  floorPlans,
  handleBranchChange,
  isPropertyLoading,
  handleBranchFloorPlanChange,
  properties,
  setValue,
  isPropertyExisted,
  isPropertyExistedLoading,
  getExistingPropertyPromotion,
  promotion_row,
  isPropertyExistedStatus,
  emptyStatusValue,
}) {
  const dispatch = useDispatch();
  const handleNextStep = (e) => {
    e.preventDefault();
    if (
      watchAllFields?.end_date &&
      watchAllFields?.start_date &&
      moment(watchAllFields?.end_date) < moment(watchAllFields?.start_date)
    ) {
      toast.error("End date should be greater than start date");
      return;
    }
    let property = watchAllFields?.property;
    if (
      watchAllFields?.property === undefined ||
      watchAllFields?.property === null ||
      watchAllFields?.property === "" ||
      watchAllFields?.property?.length <= 0
    ) {
      property = properties;
    }
    const newData = {
      ...watchAllFields,
      property: JSON.stringify(property),
      start_date: convertSimpleDateUsingMoment(watchAllFields?.start_date),
      end_date: convertSimpleDateUsingMoment(watchAllFields?.end_date),
      promotion_id: promotion_row?.id > 0 ? promotion_row?.id : 0,
    };
    // ;
    dispatch(getExistingPropertyPromotion(newData));
  };
  useEffect(() => {
    if (isPropertyExistedStatus === 1) {
      dispatch(emptyStatusValue());
      setFormStep(formStep + 1);
    }
  }, [isPropertyExistedStatus]);
  useEffect(() => {
    if (watchAllFields?.apply_all === "Yes") {
      setValue("property", []);
    }
  }, [watchAllFields?.apply_all]);

  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Filter by Branch</h6>
            <div className="form_blk">
              <Controller
                {...register("branch_id", { required: "Required" })}
                name="branch_id"
                control={control}
                defaultValue={watchAllFields?.branch_id}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={branches}
                      onChange={(val) => {
                        onChange(val.value);
                        handleBranchChange(val.value);
                      }}
                      label="Select Branch"
                      value={branches.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="branch_id"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Filter by Floorplan</h6>
            <div className="form_blk">
              <Controller
                {...register("floor_plan")}
                name="floor_plan"
                id="floor_plan"
                className=""
                control={control}
                defaultValue={watchAllFields?.floor_plan}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={floorPlans}
                      onChange={(val) => {
                        onChange(val.value);
                        setValue("floor_plan", val);
                        handleBranchFloorPlanChange(val);
                      }}
                      isMulti
                      label="Select Floor Plan"
                      value={floorPlans?.filter(function (option) {
                        return value?.some(
                          (selected) => selected.value === option.value
                        );
                      })}
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div class="col-xs-6">
            <h6>Apply to all properties?</h6>
            <ul class="total_count_price_listing full_wide_price_listing rent_listing_package justify-left">
              <li>
                <div class="lblBtn">
                  <input
                    type="radio"
                    name="apply_all"
                    id="No"
                    value="No"
                    defaultChecked={
                      watchAllFields?.apply_all === "No" ? true : false
                    }
                    {...register("apply_all", { required: "Required" })}
                  />
                  <label htmlFor="No">No</label>
                </div>
              </li>
              <li>
                <div class="lblBtn">
                  <input
                    type="radio"
                    name="apply_all"
                    id="Yes"
                    value="Yes"
                    {...register("apply_all", { required: "Required" })}
                    defaultChecked={
                      watchAllFields?.apply_all === "Yes" ? true : false
                    }
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-xs-6">
            <h6>Select Properties</h6>
            <div className="form_blk">
              <Controller
                {...register("property")}
                name="property"
                id="property"
                className=""
                control={control}
                defaultValue={watchAllFields?.property}
                disabled={watchAllFields?.apply_all === "Yes" ? true : false}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={properties}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      isMulti
                      label="Select Property"
                      value={properties?.filter(function (option) {
                        return value?.some(
                          (selected) => selected.value === option.value
                        );
                      })}
                      isDisabled={
                        watchAllFields?.apply_all === "Yes" ? true : false
                      }
                    />
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Start</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="start_date"
                {...register("start_date", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>End</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="end_date"
                {...register("end_date", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="end_date"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Description</h6>
            <div className="form_blk">
              <textarea
                name=""
                id=""
                className="txtBox"
                {...register("description", { required: "Required" })}
              ></textarea>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>

        <div className="cmnBtn text-center">
          <NavLink to="/promotions" className="webBtn">
            Cancel
          </NavLink>
          {watchAllFields?.branch_id === "" ||
          watchAllFields?.branch_id === null ||
          watchAllFields?.branch_id === undefined ||
          watchAllFields?.start_date === "" ||
          watchAllFields?.start_date === null ||
          watchAllFields?.start_date === undefined ||
          watchAllFields?.end_date === "" ||
          watchAllFields?.end_date === null ||
          watchAllFields?.end_date === undefined ||
          watchAllFields?.description === "" ||
          watchAllFields?.description === null ||
          watchAllFields?.description === undefined ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
              disabled={isPropertyLoading}
            >
              Next {isPropertyLoading ? <i className="spinner"></i> : ""}
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={handleNextStep}
              disabled={isPropertyLoading || isPropertyExistedLoading}
            >
              Next{" "}
              {isPropertyLoading || isPropertyExistedLoading ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
