import React from "react";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function EmployeeLst({ item, handleDelete }) {
  return (
    <tr>
      <td class="hide_cell_hyp">
        <span class="mini-show-cell">Branch</span>
        {item?.branch_name}
      </td>
      <td>
        <span class="mini-show-cell">Name</span>
        {item?.name}
      </td>
      <td>
        <span class="mini-show-cell">Email</span>
        {item?.email}
      </td>
      <td>
        <span class="mini-show-cell">Phone</span>
        {item?.phone}
      </td>
      {mem_permissions === "admin" ? (
        <td class="flex_action_user_dash hide_cell_hyp">
          {/* {item?.allow_edit === 1 ? ( */}
          <div class="action_drop _dropDown">
            <div class="_dropBtn action_dots">
              <img src="/images/dashboard/dots.png" alt="" />
            </div>
            <ul class="_dropCnt dropLst">
              <li>
                <NavLink to={"/edit-employee/" + item?.id}>Edit</NavLink>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you wish to delete this item?"
                      )
                    )
                      handleDelete(e, item?.id);
                  }}
                >
                  Delete
                </a>
              </li>
            </ul>
          </div>
          {/* ) : (
            ""
          )} */}
        </td>
      ) : (
        ""
      )}
    </tr>
  );
}
