import {
  FETCH_SINGLE_PROMOTION_CONTENT,
  FETCH_SINGLE_PROMOTION_CONTENT_SUCCESS,
  FETCH_SINGLE_PROMOTION_CONTENT_FAILED,
  SINGLE_PROMOTION_MESSAGE,
  SINGLE_PROMOTION_MESSAGE_SUCCESS,
  SINGLE_PROMOTION_MESSAGE_FAILED,
  FETCH_PROMOTION_CONTENT,
  FETCH_PROMOTION_CONTENT_FAILED,
  FETCH_PROMOTION_CONTENT_SUCCESS,
  PROMOTION_DELETE__MESSAGE,
  PROMOTION_DELETE_MESSAGE_SUCCESS,
  PROMOTION_DELETE__MESSAGE_FAILED,
  GET_FILTER_PROMOTION,
  GET_FILTER_PROMOTION_FAILED,
  GET_FILTER_PROMOTION_SUCCESS,
  FIND_EXISTING_PROMOTION,
  FIND_EXISTING_PROMOTION_SUCCESS,
  FIND_EXISTING_PROMOTION_FAILED,
  EMPTY_STATUS_VALUE
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  floor_plans: [],
  isFilterLoading: false,
  isItemDeleted: false,
  isPropertyExisted: {},
  isPropertyExistedLoading: false,
  expenses: [],
  isPropertyExistedStatus: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case EMPTY_STATUS_VALUE:
      return {
        ...state,
        isPropertyExistedStatus: null
      };
    case FIND_EXISTING_PROMOTION:
      return {
        ...state,
        isPropertyExistedLoading: true,
        isPropertyExistedStatus: null,
        isPropertyExisted: {},
        expenses: []
      };
    case FIND_EXISTING_PROMOTION_SUCCESS:
      return {
        ...state,
        isPropertyExistedLoading: false,
        isPropertyExisted: payload,
        expenses: payload?.expenses,
        isPropertyExistedStatus: payload?.status,
      };

    case FIND_EXISTING_PROMOTION_FAILED:
      return {
        ...state,
        isPropertyExistedLoading: false,
        error: payload,
      };
    case GET_FILTER_PROMOTION:
      return {
        ...state,
        isFilterLoading: true,
      };
    case GET_FILTER_PROMOTION_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload,
      };

    case GET_FILTER_PROMOTION_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload,
      };
    case FETCH_PROMOTION_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_PROMOTION_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_PROMOTION_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SINGLE_PROMOTION_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SINGLE_PROMOTION_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_SINGLE_PROMOTION_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SINGLE_PROMOTION_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case SINGLE_PROMOTION_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case SINGLE_PROMOTION_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case PROMOTION_DELETE__MESSAGE:
      return {
        ...state,
        isItemDeleted: true,
      };
    case PROMOTION_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isItemDeleted: false,
        emptyFormData: true,
      };
    case PROMOTION_DELETE__MESSAGE_FAILED:
      return {
        ...state,
        isItemDeleted: false,
        error: payload,
      };
    default:
      return state;
  }
}
