import React from 'react'

export default function MultiFiles({ name, label, uploadMultipleImages }) {
    let mul_images = [];
    function uploadImages(label) {
        mul_images[label].click();
    }
    return (
        <>
            <div className="outer_image_blk_col full_wide_uploade_blk">
                <div className="image_blk_col">
                    <div className="image_upload_blk uploadImg" data-upload="gallery_image" onClick={() => uploadImages(label)}>
                        <div className="small_upload_icon" >
                            <img src="/images/dashboard/icons/upload.png" alt="" />
                        </div>
                        <h3>{name}</h3>
                    </div>
                </div>
            </div>
            <input type="file" name="" id="" className="uploadFile" data-upload="gallery_image"


                onChange={(e) => uploadMultipleImages(e, label)}
                accept="image/*"
                ref={(file) => (mul_images[label] = file)} multiple />
        </>
    )
}
