import React from "react";
import { Link } from "react-router-dom";

export default function BlogCategory({ data }) {
    return (
        <>
            <div className="ctgryBlk">
                <h4>{data.sub_heading}</h4>
                <ul className="ctgryLst">

                    {data.cate_lst.map((val, index) => {
                        return (
                            <li key={index}><Link to={val.link}>{val.label}</ Link></li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}
