export default function OfferBlk({
  item,
  selectedOffer,
  handleOfferSelection,
}) {
  console.log(selectedOffer);

  const isActive = selectedOffer?.some((offer) => {
    console.log(
      "Checking offer:",
      offer?.encoded_id,
      "with item:",
      item?.encoded_id
    );
    return offer?.encoded_id === item?.encoded_id;
  });

  return (
    <>
      <li
        key={item?.id}
        onClick={() => handleOfferSelection(item)}
        className={isActive ? "active" : ""}
      >
        <div className="flex">
          <div className="col_mini_col">
            <h5 className="lease_heading_mini">Offer ID</h5>
            <div>FV{item?.id}</div>
          </div>
          <div className="col_mini_col">
            <h5 className="lease_heading_mini">Tenants</h5>
            <ul className="tenants_dps_flex">
              {item?.tenants?.length > 0
                ? item?.tenants?.map((tenant, index) => (
                    <li key={index}>
                      <div className="inner">
                        <div className="mini_icon">
                          <img src={tenant?.thumb} alt={tenant?.name} />
                        </div>
                        <div className="bio_info">
                          <div className="name">
                            <h4>{tenant?.name}</h4>
                          </div>
                          <a href="chat.php" className="start_chat">
                            Start Chat
                          </a>
                        </div>
                      </div>
                      <div className="ico">
                        <img src={tenant?.thumb} alt={tenant?.name} />
                      </div>
                    </li>
                  ))
                : ""}
            </ul>
          </div>
          <div className="col_mini_col">
            <h5 className="lease_heading_mini">Price</h5>
            <div className="price">{item?.offering}</div>
          </div>
          <div className="col_mini_col">
            <h5 className="lease_heading_mini">Term</h5>
            <div>{item?.term}</div>
          </div>
          <div className="col_mini_col">
            <h5 className="lease_heading_mini">Move-in Date</h5>
            <div>{item?.move_in_date}</div>
          </div>
        </div>
      </li>
    </>
  );
}
