import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
  GeoJSON,
  useMapEvents,
  ZoomControl,
  useMap,
} from "react-leaflet";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
// const position = [38.89511, -77.03637]
export default function LeafMap({
  markers,
  boundaryData,
  map_center,
  handleRadiusPropertiesOnDrag,
  isMarkerHovered,
  handleRadiusPropertiesOnDraggingNew,
  setMapBounds,
  SetGridProperties,
  SetCurrentMapBounds,
  currentMapBounds,
  geojson,
  is_header_search,
  setMapCurrentZoom,
  mapCurrentZoom,
  filter_with_boundary,
  mapRef,
  map_new_filter_bounds,
  map_bounds_zoom,
}) {
  useEffect(() => {
    if (parseInt(is_header_search) > 0) {
      if (parseInt(filter_with_boundary) > 0) {
      } else {
        SetCurrentMapBounds(false);
        setMapCurrentZoom(false);
      }
    }
  }, [is_header_search]);
  // const mapRef = useRef(null);
  const markersRef = useRef([]);
  const polygonRef = useRef([]);
  const [refVisible, setRefVisible] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [boundaryPolygon, setBoundaryPolygon] = useState([]);
  const [position, setPosition] = useState([39.381266, -97.922211]);
  const [mapCenter, setMapCenter] = useState([39.381266, -97.922211]);
  const [mapZoomLevel, setMapZoomLevel] = useState(0);

  // const [zoom, setZoom] = useState(4);
  useEffect(() => {
    if (map_center?.length > 0) {
      setPosition(map_center);
    }
  }, [map_center]);
  useEffect(() => {
    setCoordinates(
      markers?.map((marker) => [marker?.latitude, marker?.longitude])
    );
  }, [markers]);
  useEffect(() => {
    setBoundaryPolygon(boundaryData);
  }, [boundaryData]);
  useEffect(() => {}, [mapCenter, mapZoomLevel]);

  const getPolygonBounds = (polygons) => {
    let bounds = L.latLngBounds([]);
    polygons.forEach((polygon) => {
      const geoJsonLayer = L.geoJSON(polygon?.geometry);
      bounds.extend(geoJsonLayer.getBounds());
    });
    return bounds;
  };
  useEffect(() => {
    if (markers?.length <= 0) {
      removeMarkersAndClusters();
      if (mapRef.current && boundaryPolygon && boundaryPolygon.length > 0) {
        const bounds = getPolygonBounds(boundaryPolygon);
        if (
          getGeoJsonBounds() &&
          map_new_filter_bounds !== null &&
          parseInt(filter_with_boundary) > 0
        ) {
          const options = {
            padding: [50, 50], // Adjust padding as needed
            maxZoom: map_bounds_zoom, // Maximum desired zoom level
            animate: false,
          };
          mapRef.current.fitBounds(map_new_filter_bounds);
        } else {
          mapRef.current.fitBounds(bounds);
        }

        mapRef.current.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            mapRef.current.removeLayer(layer);
          }
          if (clusterGroup) {
            clusterGroup.clearLayers();
          }
        });
        markersRef.current.forEach((marker) =>
          mapRef.current.removeLayer(marker)
        );
      } else {
        if (
          mapRef.current &&
          parseInt(filter_with_boundary) <= 0 &&
          map_new_filter_bounds !== null &&
          parseInt(filter_with_boundary) > 0
        ) {
          const options = {
            padding: [50, 50], // Adjust padding as needed
            maxZoom: map_bounds_zoom, // Maximum desired zoom level
            animate: false,
          };
          mapRef.current.fitBounds(map_new_filter_bounds);
          markersRef.current.forEach((marker) =>
            mapRef.current.removeLayer(marker)
          );
        } else {
          if (
            mapRef.current &&
            currentMapBounds != false &&
            boundaryPolygon?.length <= 0
          ) {
            mapRef.current.fitBounds(currentMapBounds, { animate: false });
          }
          markersRef.current.forEach((marker) =>
            mapRef.current.removeLayer(marker)
          );
        }
      }
    }
  }, [boundaryPolygon]);
  const boundaryStyle = {
    // fillColor: '#00acfc', // Change to the desired boundary fill color
    color: "#00acfc", // Change to the desired boundary border color
    weight: 2, // Change to the desired boundary border weight
    opacity: 1, // Change to the desired boundary border opacity
  };

  const clearBoundary = () => {
    const mapEle = mapRef.current;
    const mapBounds = mapEle.getBounds();
    const mapNorth = mapBounds.getNorthEast();
    const mapSouth = mapBounds.getSouthWest();
    if (
      mapNorth?.lat !== undefined &&
      mapNorth?.lng !== undefined &&
      mapSouth?.lat !== undefined &&
      mapSouth?.lng !== undefined
    ) {
      let newData = {
        swLat: mapSouth?.lat,
        swLng: mapSouth?.lng,
        neLat: mapNorth?.lat,
        neLng: mapNorth?.lng,
      };
      //
      storeMapBounds(mapRef.current.getBounds());
      setMapCurrentZoom(mapRef.current.getZoom());
      handleRadiusPropertiesOnDrag(newData, true);
      setBoundaryPolygon([]);
    }
  };

  const handleMapReady = (map) => {
    mapRef.current = map;
  };
  const getPropertiesRequest = async (north, south, zoom = false) => {
    if (
      north?.lat !== undefined &&
      north?.lng !== undefined &&
      south?.lat !== undefined &&
      south?.lng !== undefined
    ) {
      let newData = {
        swLat: south?.lat,
        swLng: south?.lng,
        neLat: north?.lat,
        neLng: north?.lng,
      };
      const response = await handleRadiusPropertiesOnDraggingNew(newData);
      createMarkers(response?.properties, zoom);
    }
  };
  function MyZoomControl() {
    const map = useMap();

    const handleZoomIn = async () => {
      map.setZoom(map.getZoom() + 1);
      setTimeout(async () => {
        setMapCenter(map.getCenter());
        setMapZoomLevel(map.getZoom());
        const bounds = map.getBounds();
        const north = bounds.getNorthEast();
        const south = bounds.getSouthWest();
        getPropertiesRequest(north, south, map.getZoom());
      }, 500);
    };

    const handleZoomOut = async () => {
      map.setZoom(map.getZoom() - 1);
      setTimeout(async () => {
        setMapCenter(map.getCenter());
        setMapZoomLevel(map.getZoom());
        const bounds = map.getBounds();
        const north = bounds.getNorthEast();
        const south = bounds.getSouthWest();

        getPropertiesRequest(north, south, map.getZoom());
      }, 500);
    };

    return (
      <div className="leaflet-bottom leaflet-right">
        <div className="leaflet-control leaflet-bar">
          <a
            className="leaflet-control-zoom-in"
            onClick={handleZoomIn}
            role="button"
            title="Zoom In"
            tabIndex="0"
          >
            +
          </a>
          <a
            className="leaflet-control-zoom-out"
            onClick={handleZoomOut}
            role="button"
            title="Zoom Out"
            tabIndex="-1"
          >
            -
          </a>
        </div>
      </div>
    );
  }
  function storeMapBounds(bounds) {
    const north = bounds.getNorthEast();
    const south = bounds.getSouthWest();
    if (
      north?.lat !== undefined &&
      north?.lng !== undefined &&
      south?.lat !== undefined &&
      south?.lng !== undefined
    ) {
      SetCurrentMapBounds(bounds);
    }
  }
  function MyComponent() {
    const map = useMapEvents({
      dragend: async (e) => {
        // if (boundaryPolygon?.length <= 0) {
        // clearClusterGroup();
        // const dragLatLng = e.target.getCenter();
        // setMapCenter(e.target.getCenter());
        // setMapZoomLevel(map.getZoom());
        // const bounds = e.target.getBounds();
        // const north = bounds.getNorthEast();
        // const south = bounds.getSouthWest();
        // getPropertiesRequest(north, south, false)

        // }
        storeMapBounds(mapRef.current.getBounds());
        showGridProperties();
        setMapCurrentZoom(mapRef.current.getZoom());
      },
      zoomend: (e) => {
        showGridProperties();
        storeMapBounds(mapRef.current.getBounds());
        setMapCurrentZoom(e.target.getZoom());
      },
    });
    return null;
  }

  const showGridProperties = () => {
    const properties = [];
    markers?.map((marker) => {
      if (
        mapRef.current
          .getBounds()
          .contains(L.latLng(marker.latitude, marker.longitude))
      ) {
        properties.push(marker);
      }
    });
    SetGridProperties(properties);
  };

  const removeMarkersAndClusters = () => {
    // Remove all markers from the map
    if (markersRef.current && mapRef.current) {
      markersRef.current.forEach((marker) =>
        mapRef.current.removeLayer(marker)
      );
    }
    if (clusterGroup) {
      const clusterLayer = document.querySelector(".leaflet-marker-pane");
      clusterGroup.clearLayers();
      if (clusterLayer) {
        clusterLayer.innerHTML = "";
      }
    }
    // Remove the marker cluster layer from the map
  };
  const [clusterGroup, setClusterGroup] = useState(null);
  const createMarkers = (markersArr, zoomLevel = false) => {
    if (clusterGroup) {
      removeMarkersAndClusters();

      // Loop through your marker data and create new markers
      // For example:
      if (zoomLevel !== false && zoomLevel >= 16) {
        markersArr?.forEach((marker) => {
          const markerPosition = [marker.latitude, marker.longitude];

          const markerOptions = {
            icon: L.divIcon({
              // className: isMarkerHovered === marker?.id ? 'marker-hovered' : 'marker-not-hovered',
              html: `<div class="marker-price ${marker?.listing_type} ${
                isMarkerHovered === marker?.id
                  ? "marker-hovered"
                  : "marker-not-hovered"
              }">${marker?.price_with_symbol}</div>`,
            }),
          };
          const leafletMarker = L.marker(markerPosition, markerOptions);
          leafletMarker.bindPopup(marker?.html_content).addTo(mapRef.current);
          markersRef.current.push(leafletMarker);
        });
      } else {
        markersArr?.forEach((marker) => {
          const markerPosition = [marker.latitude, marker.longitude];
          const markerOptions = {
            icon: L.divIcon({
              // className: isMarkerHovered === marker?.id ? 'marker-hovered' : 'marker-not-hovered',
              html: `<div class="marker-price ${marker?.listing_type} ${
                isMarkerHovered === marker?.id
                  ? "marker-hovered"
                  : "marker-not-hovered"
              }">${marker?.price_with_symbol}</div>`,
            }),
          };
          const leafletMarker = L.marker(markerPosition, markerOptions);
          leafletMarker.bindPopup(marker?.html_content);
          markersRef.current.push(leafletMarker);
          clusterGroup.addLayer(leafletMarker);
        });
        const map = clusterGroup.addTo(mapRef.current);

        return () => {
          map.remove();
        };
      }
    }
  };
  useEffect(() => {
    if (!refVisible) {
      return;
    }

    if (markers?.length > 0) {
      removeMarkersAndClusters();
      let mapZoom = mapRef?.current?.getZoom();
      const clusterIconCreateFunction = function (cluster) {
        const count = cluster.getChildCount();
        const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
        const className = `marker-cluser-parent marker-cluster-${size}`;
        return L.divIcon({ html: `<div class="${className}">${count}</div>` });
      };

      const markersLayer = L.markerClusterGroup({
        iconCreateFunction: clusterIconCreateFunction,
      });

      setClusterGroup(markersLayer);
      markers?.forEach((marker) => {
        const markerPosition = [marker.latitude, marker.longitude];
        const markerOptions = {
          icon: L.divIcon({
            // className: isMarkerHovered === marker?.id ? 'marker-hovered' : 'marker-not-hovered',
            html: `<div class="marker-price ${marker?.listing_type} ${
              isMarkerHovered === marker?.id
                ? "marker-hovered"
                : "marker-not-hovered"
            }">${marker?.price_with_symbol}</div>`,
          }),
        };
        const leafletMarker = L.marker(markerPosition, markerOptions);
        leafletMarker.bindPopup(marker?.html_content);
        markersRef.current.push(leafletMarker);
        markersLayer.addLayer(leafletMarker);
      });
      const map = markersLayer.addTo(mapRef.current);
      markersLayer.on("clusterclick", handleClusterClick);
      mapRef.current.on("moveend", handleMoveEnd);
      if (currentMapBounds != false && boundaryPolygon?.length <= 0) {
        mapRef.current.fitBounds(currentMapBounds, { animate: false });
      }
      if (
        getGeoJsonBounds() &&
        currentMapBounds !== false &&
        parseInt(is_header_search) > 0
      ) {
        mapRef.current.fitBounds(currentMapBounds, { animate: false });
      }
      if (
        getGeoJsonBounds() &&
        map_new_filter_bounds !== null &&
        parseInt(filter_with_boundary) > 0
      ) {
        const options = {
          padding: [50, 50], // Adjust padding as needed
          maxZoom: map_bounds_zoom, // Maximum desired zoom level
          animate: false,
        };
        mapRef.current.fitBounds(map_new_filter_bounds, { animate: false });
      } else {
        if (mapCurrentZoom !== false) {
          mapRef.current.setZoom(parseInt(mapCurrentZoom));
        }
      }

      setTimeout(() => {
        showGridProperties();
      }, 500);
      return () => {
        map.remove();
      };
    }
    // detected rendering
  }, [refVisible]);
  const getGeoJsonBounds = () => {
    let bounds = null;
    if (
      mapRef.current !== null &&
      mapRef.current !== undefined &&
      boundaryPolygon?.length > 0
    ) {
      boundaryPolygon.forEach((feature) => {
        const featureBounds = L.geoJSON(feature.geometry).getBounds();
        if (featureBounds && bounds === null) {
          bounds = featureBounds;
        } else if (featureBounds) {
          bounds.extend(featureBounds);
        }
      });
      const mapBounds = mapRef.current.getBounds();
      return bounds.contains(mapBounds);
    }
    return false;
  };

  const handleMoveEnd = () => {
    // const bounds = mapRef.current.getBounds();
    // const north = bounds.getNorthEast();
    // const south = bounds.getSouthWest();
    // getPropertiesRequest(north, south, false)
  };

  const handleClusterClick = async (event) => {
    // setTimeout(() => {
    //     const bounds = mapRef.current.getBounds();
    //     const north = bounds.getNorthEast();
    //     const south = bounds.getSouthWest();
    //     getPropertiesRequest(north, south, false)
    // }, 500);
    storeMapBounds(mapRef.current.getBounds());
    const properties = [];
    markers?.map((marker) => {
      if (
        mapRef.current
          .getBounds()
          .contains(L.latLng(marker.latitude, marker.longitude))
      ) {
        properties.push(marker);
      }
    });
    SetGridProperties(properties);
  };

  return (
    <>
      {coordinates?.length > 0 ? (
        <MapContainer
          ref={(el) => {
            mapRef.current = el;
            setRefVisible(!!el);
          }}
          // center={position}
          scrollWheelZoom={true}
          bounds={coordinates}
          boundsOptions={{ padding: [1, 1] }}
          whenReady={handleMapReady}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MyComponent />
          {boundaryPolygon?.map((boundary) => (
            <GeoJSON
              key={boundary?.id}
              data={boundary?.geometry}
              style={boundaryStyle}
            />
          ))}
          {/* <MyZoomControl /> */}
          {boundaryPolygon?.length > 0 ? (
            <div className="clear_boundary">
              <button
                className="webBtn smBtn blankBtn borderBtn"
                type="button"
                onClick={clearBoundary}
              >
                Clear Boundary
              </button>
            </div>
          ) : (
            ""
          )}
        </MapContainer>
      ) : markers?.length <= 0 ? (
        <MapContainer
          ref={(el) => {
            mapRef.current = el;
            setRefVisible(!!el);
          }}
          center={position}
          scrollWheelZoom={true}
          zoom={5}
          // bounds={coordinates}
          boundsOptions={{ padding: [1, 1] }}
          whenReady={handleMapReady}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {boundaryPolygon?.map((boundary) => (
            <GeoJSON
              key={boundary?.id}
              data={boundary?.geometry}
              style={boundaryStyle}
            />
          ))}
          {/* <MyZoomControl /> */}
          {boundaryPolygon?.length > 0 ? (
            <div className="clear_boundary">
              <button
                className="webBtn smBtn blankBtn borderBtn"
                type="button"
                onClick={clearBoundary}
              >
                Clear Boundary
              </button>
            </div>
          ) : (
            ""
          )}
          <MyComponent />
        </MapContainer>
      ) : (
        ""
      )}
    </>
  );
}
