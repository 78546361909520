import React, { useState } from 'react'

export default function AroundHome({ property_score, property_address }) {
    return (
        <>
            <h3 className="detail_heading">Around this home</h3>
            <p className='mini_text'>Transportation near {property_address}</p>
            <div className='outer_around_home equal_top'>
                {
                    property_score?.map((property_score_row, index) => {
                        return (
                            <ul>
                                <li>
                                    <div className="left_li flex">
                                        <div className="img_icon">
                                            <img src="/images/walking.svg" alt={property_score_row?.walkscore_description} />
                                        </div>
                                        <div className="score">
                                            <p className='number_score'><span className='color'>{property_score_row?.walkscore}</span></p>
                                            <p>Walk Score®</p>
                                        </div>
                                    </div>
                                    <div className="right_li">
                                        <p>{property_score_row?.walkscore_description}</p>
                                    </div>
                                </li>
                                {
                                    property_score_row?.bikescore !== null && property_score_row?.bikescore !== undefined && property_score_row?.bikescore !== '' ?
                                        <li>
                                            <div className="left_li flex">
                                                <div className="img_icon">
                                                    <img src='/images/bike.svg' alt='' />
                                                </div>
                                                <div className="score">
                                                    <p className='number_score'><span className='color'>{property_score_row?.bikescore}</span></p>
                                                    <p>Bike Score®</p>
                                                </div>
                                            </div>
                                            <div className="right_li">
                                                <p>{property_score_row?.bikescore_description}</p>
                                            </div>

                                        </li>
                                        :
                                        ""
                                }
                                {
                                    property_score_row?.transitscore !== null && property_score_row?.transitscore !== undefined && property_score_row?.transitscore !== '' ?
                                        <li>
                                            <div className="left_li flex">
                                                <div className="img_icon">
                                                    <img src='/images/train.svg' alt='' />
                                                </div>
                                                <div className="score">
                                                    <p className='number_score'><span className='color'>{property_score_row?.transitscore}</span></p>
                                                    <p>Transit Score®</p>
                                                </div>
                                            </div>
                                            <div className="right_li">
                                                <p>{property_score_row?.transitscore_description}</p>
                                            </div>

                                        </li>
                                        :
                                        ""
                                }
                                {/* <li>
                        <div className="left_li flex">
                            <div className="img_icon">
                                <img src='/images/train.svg' alt='' />
                            </div>
                            <div className="score">
                                <p className='number_score'><span className='color'>84</span><span>/</span><sub>100</sub></p>
                                <p>Transit Score®</p>
                            </div>
                        </div>
                        <div className="right_li">
                            <p>Very bikeable</p>
                        </div>

                    </li> */}

                            </ul>
                        )
                    })
                }

            </div>
        </>
    )
}
