import React, { useEffect, useState } from "react";
import MaintenanceLoadProfilePopup from "./maintenance_profile/popup";
import Breadcrumb from "./sections/breadcrumb";
import AccountInfo from "./maintenance_profile/account-info";
import MaintenanceProfileProperties from "./maintenance_profile/properties";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { profileSettings } from "../../../states/actions/profileSettings";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import ReviewsMaintenance from "./maintenance_profile/reviews-maintenance";

export default function MaintenanceProfilePropertiesPage() {
  const member_row = useSelector((state) => state.profileSettings.verify);

  const isLoading = useSelector((state) => state.profileSettings.isLoading);
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  useEffect(() => {
    dispatch(profileSettings());
  }, []);

  useDocumentTitle("Service Profile Properties - Loftus");
  const member = member_row?.settings?.member;
  if (isLoading) return <LoadingScreen />;
  const user_staff_properties = member?.user_staff_properties;
  return (
    <>
      <main index class="main_dashboard">
        <section class="dashboard">
          <div class="contain">
            <Breadcrumb page_name="Service Profile" />
            <div class="page_body account_settings_flex">
              <div class="dash_form_blk">
                <div className="main_mentenance_blk">
                  <div className="option_lbl  profileTabs">
                    <ul>
                      <li className="active">
                        <a href="/service-profile" className={"webBtn"}>
                          Account Info
                        </a>
                      </li>
                      <li className="active">
                        <a href="/service-profile" className={"webBtn"}>
                          Reviews
                        </a>
                      </li>
                      <li>
                        <a
                          href="//service-profile-properties"
                          className={"webBtn colorBtn"}
                        >
                          Properties
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dash_form_blk">
                    <MaintenanceProfileProperties
                      user_staff_properties={user_staff_properties}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
