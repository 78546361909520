import {
  FETCH_SINGLE_BILLING_CONTENT,
  FETCH_SINGLE_BILLING_CONTENT_SUCCESS,
  FETCH_SINGLE_BILLING_CONTENT_FAILED,
  SINGLE_BILLING_MESSAGE,
  SINGLE_BILLING_MESSAGE_SUCCESS,
  SINGLE_BILLING_MESSAGE_FAILED,
  FETCH_BILLING_CONTENT,
  FETCH_BILLING_CONTENT_FAILED,
  FETCH_BILLING_CONTENT_SUCCESS,
  BILLING_DELETE__MESSAGE,
  BILLING_DELETE_MESSAGE_SUCCESS,
  BILLING_DELETE__MESSAGE_FAILED,
  GET_FILTER_BILLING,
  GET_FILTER_BILLING_FAILED,
  GET_FILTER_BILLING_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  floor_plans: [],
  isFilterLoading: false
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTER_BILLING:
      return {
        ...state,
        isFilterLoading: true
      };
    case GET_FILTER_BILLING_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload
      };

    case GET_FILTER_BILLING_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload
      };
    case FETCH_BILLING_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_BILLING_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_BILLING_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SINGLE_BILLING_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SINGLE_BILLING_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_SINGLE_BILLING_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SINGLE_BILLING_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case SINGLE_BILLING_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case SINGLE_BILLING_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case BILLING_DELETE__MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case BILLING_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true
      };
    case BILLING_DELETE__MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload
      };
    default:
      return state;
  }
}
