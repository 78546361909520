import React from 'react'

export default function OfferTabs({ type, handleType, active_count = '', pending_count = '', active_name = "Received Offers", pending_name = "Sent Offers", my_properties, mem_type }) {
    return (
        <>
            {/* <ul className="nav nav-tabs">
                {
                    mem_type === 'member' ?

                        <li class={type == 'my_offers' ? 'active' : ""}>
                            <a className="b" data-toggle="tab" href="javascript:void(0)" onClick={() => handleType('my_offers')}>
                                {pending_name} <span>{pending_count ? "(" + pending_count + ")" : ""}</span></a>
                        </li>
                        :
                        ""
                }
                <li class={type == 'received' ? 'active' : ""}>
                    <a className="a" data-toggle="tab" href="javascript:void(0)" onClick={() => handleType('received')}>
                        {active_name} <span>{active_count ? "(" + active_count + ")" : ""}</span></a>
                </li>


            </ul> */}

        </>
    )
}
