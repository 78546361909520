import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import Sidebar from "./sections/sidebar";
import BillingSort from "./billing/sort";
import BillingLst from "./billing/biilingLst";

import {
  fetchBilling,
  deleteBilling,
  getFilteredBilling,
} from "../../../states/actions/fetchSingleBilling";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { format_amount } from "../../../helpers/api";
import { ToastContainer } from "react-toastify";
import BillingBlk from "./billing/billingBlk";
const mem_permissions = localStorage.getItem("mem_permissions");
export default function Billing() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleBilling.content);
  const isLoading = useSelector((state) => state.fetchSingleBilling.isLoading);
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleBilling.isFilterLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleBilling.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const [billingPopup, setBillingPopup] = useState({
    item: null,
    show: false,
  });
  useEffect(() => {
    dispatch(fetchBilling());
  }, []);
  const handleDelete = (e, billing_id) => {
    e.preventDefault();
    dispatch(deleteBilling(billing_id));
  };

  const [filterData, setFilterData] = useState({
    branch_id: null,
    property_id: null,
  });
  const [billingType, setBillingType] = useState("pending");
  useEffect(() => {
    if (data?.billing?.length <= 0 && data?.posted_billing?.length > 0) {
      setBillingType("posted");
    } else if (data?.posted_billing?.length <= 0) {
      setBillingType("pending");
    } else if (data?.billing?.length <= 0) {
      setBillingType("posted");
    }
  }, [data]);

  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
      property_id:
        type === "property_id" ? e.target.value : filterData?.property_id,
    };
    dispatch(getFilteredBilling(formData));
  };
  useDocumentTitle("Billing - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const billing = data?.billing;
  const posted_billing = data?.posted_billing;
  const properties = data?.properties;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="billing" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" ? (
                  <Breadcrumb page_name="Billing" add_link="/add-billing" />
                ) : (
                  <Breadcrumb page_name="Billing" />
                )}

                <div className="page_body">
                  <div className="listing_list_c">
                    <div className="outer_list_c">
                      <BillingSort
                        branches={branches}
                        handleBranchChange={handleBranchChange}
                        properties={properties}
                        billingType={billingType}
                        changeType={setBillingType}
                        pending_count={billing?.length}
                        posted_count={posted_billing?.length}
                      />
                      {isFilterLoading ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {billingType === "pending" ? (
                        <BillingBlk
                          billing={billing}
                          setBillingPopup={setBillingPopup}
                          handleDelete={handleDelete}
                          mem_permissions={mem_permissions}
                        />
                      ) : billingType === "posted" ? (
                        <BillingBlk
                          billing={posted_billing}
                          setBillingPopup={setBillingPopup}
                          handleDelete={handleDelete}
                          type="posted"
                          mem_permissions={mem_permissions}
                        />
                      ) : posted_billing?.length <= 0 &&
                        billing?.length <= 0 ? (
                        <div className="alert alert-danger">
                          No content added yet!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {billingPopup?.show === true && billingPopup?.show === true ? (
        <section
          className="popup popup_for_lease"
          data-popup="choose-lease-property"
        >
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                {/* <Toaster position="top-center" /> */}
                <div
                  className="crosBtn"
                  onClick={() => setBillingPopup({ item: null, show: false })}
                ></div>
                <h4>Billing Details:</h4>
                {/* <ul class="flex offer_cstm_ul">
                  <li>
                    <strong>Branch</strong>
                    <em>{billingPopup?.item?.branch_name}</em>
                  </li>
                  <li>
                    <strong>Address</strong>
                    <em>
                      <Text string={billingPopup?.item?.property_address} />
                    </em>
                  </li>
                  <li>
                    <strong>Cycle</strong>
                    <em>{billingPopup?.item?.cycle}</em>
                  </li>
                </ul> */}
                <div className=" billing_cost_flex">
                  <ul class="">
                    {billingPopup?.item?.prices?.length > 0
                      ? billingPopup?.item?.prices?.map(
                          (billing_price, price_index) => {
                            return (
                              <li>
                                <div className="_li_inner">
                                  <span>{billing_price?.name}</span>
                                  <em>{format_amount(billing_price?.cost)}</em>
                                </div>
                              </li>
                            );
                          }
                        )
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
