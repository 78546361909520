import React from "react";
import { NavLink } from "react-router-dom";
import BulkUpload from "./bulk-upload";

export default function BillingStep1({
  changeStep,
  step,
  setBillingAddType,
  billingAddType,
}) {
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <div className="lblBtn">
              <input
                type="radio"
                className="hidden"
                id="single"
                name="list-type"
                value="single"
                onChange={() => setBillingAddType("single")}
                defaultChecked={billingAddType === "single" ? true : false}
              />
              <label
                for="single"
                className={
                  billingAddType === "single"
                    ? "dim_btn color_dim active"
                    : "dim_btn"
                }
                onClick={() => setBillingAddType("single")}
              >
                Single
              </label>
            </div>
          </div>
          <div className="col-xs-6 cell_mrgn_top">
            <div className="lblBtn">
              <input
                type="radio"
                className="hidden"
                id="bulk"
                name="list-type"
                value="bulk"
                onChange={() => setBillingAddType("bulk")}
                defaultChecked={billingAddType === "bulk" ? true : false}
              />
              <label
                for="bulk"
                className={
                  billingAddType === "bulk"
                    ? "dim_btn color_dim active"
                    : "dim_btn"
                }
                onClick={() => setBillingAddType("bulk")}
              >
                Bulk
              </label>
            </div>
          </div>
          {billingAddType === "bulk" ? <BulkUpload /> : ""}
        </div>
        {billingAddType === "single" ? (
          <div className="cmnBtn text-center">
            <NavLink to="/billing" className="webBtn cancleBtn">
              Cancel
            </NavLink>
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
            >
              Next
            </button>
          </div>
        ) : (
          ""
        )}
      </fieldset>
    </>
  );
}
