import React, { useEffect } from "react";
import {
  format_amount,
  format_date_simple,
  getServerImage,
  convertToSlashesDate,
} from "../../../../helpers/api";
import LeaseDetailsSection from "./lease-details";
import LeaseTenantsSection from "./lease-tenants-section";
import ActiveProperty from "../../dashboard/sections/activeProperty";
import LeaseAdditionalCosts from "../lease-details/lease-costs";

export default function LeaseStep2({
  leasePdf,
  setFormStep,
  watchAllFields,
  tenants,
  costsArr,
  isLeaseFormLoading,
  property,
  mem_type,
  promotion_row,
}) {
  return (
    <>
      <div className="offer_dash_detail_page">
        <div className="flex">
          <div className="colL">
            <div className="property_card propert_list grid_dash_property_listing dash_property_listing lease_property_details">
              <ActiveProperty item={property} leases_page={true} />
            </div>
            <div className="gap_line_divide"></div>
            {leasePdf?.file !== null && leasePdf?.loading !== true ? (
              <div className="bTn justify_center_flex">
                <a
                  href={getServerImage("storage/attachments/", leasePdf?.file)}
                  className="webBtn colorBtn"
                  target="_blank"
                  download
                >
                  <img src="/images/document.png" alt="" /> Lease Document
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
          <fieldset className="colR createLeaseDetails">
            <LeaseDetailsSection lease={watchAllFields} />
            <LeaseTenantsSection tenants={tenants?.tenants} />
            <LeaseAdditionalCosts
              one_time_costs={costsArr?.one_time_costs}
              recurring_costs={costsArr?.recurring_costs}
              parking_costs={costsArr?.parking_costs}
              mem_type={mem_type}
              promo_code={watchAllFields?.promo_code}
            />

            <div className="cmnBtn offer_cstm_ul">
              <button
                type="button"
                className="webBtn"
                onClick={() => setFormStep(1)}
              >
                Back
              </button>
              <button
                type="submit"
                className="webBtn colorBtn"
                disabled={leasePdf?.loading || isLeaseFormLoading}
              >
                Submit{" "}
                {leasePdf?.loading || isLeaseFormLoading ? (
                  <i className="spinner"></i>
                ) : (
                  ""
                )}
              </button>
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
}
