import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_PROFILE_SETTINGS,
  FETCH_PROFILE_SETTINGS_SUCCESS,
  FETCH_PROFILE_SETTINGS_FAILED,
  PROFILE_IMAGE_SETTINGS,
  PROFILE_IMAGE_SETTINGS_SUCCESS,
  PROFILE_IMAGE_SETTINGS_FAILED,
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_SUCCESS,
  PROFILE_SETTINGS_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  MEMBER_QUESTION,
  MEMBER_QUESTION_SUCCESS,
  MEMBER_QUESTION_FAILED,
  FETCH_NOTIFICATION,
  FETCH_NOTIFICATION_SUCCESS,
  FETCH_NOTIFICATION_FAILED,
} from "./actionTypes";

export const profileSettings = () => (dispatch) => {
  dispatch({
    type: FETCH_PROFILE_SETTINGS,
    payload: null,
  });
  http
    .get("site-settings")
    .then(({ data }) => {
      //
      if (data?.settings?.member === null && data?.not_logged !== true) {
        localStorage.clear();
        window.location.replace("/login");
      }
      dispatch({
        type: FETCH_PROFILE_SETTINGS_SUCCESS,
        payload: data,
        notification: data?.settings?.member?.notifications,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PROFILE_SETTINGS_FAILED,
        payload: error,
      });
    });
};

export const updateNotificationStatus = () => (dispatch) => {
  const fd = new FormData();
  fd.append("status", 1);
  dispatch({
    type: FETCH_NOTIFICATION,
    payload: null,
  });
  http
    .post("updateNotificationStatus", fd)
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: FETCH_NOTIFICATION_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: FETCH_NOTIFICATION_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_NOTIFICATION_FAILED,
        payload: error,
      });
    });
};
export const updateProfileImage = (formData) => (dispatch) => {
  const fd = new FormData();
  fd.append("image", formData);
  dispatch({
    type: PROFILE_IMAGE_SETTINGS,
    payload: null,
  });
  http
    .post("save-image", fd)
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: PROFILE_IMAGE_SETTINGS_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: PROFILE_IMAGE_SETTINGS_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: PROFILE_IMAGE_SETTINGS_FAILED,
        payload: error,
      });
    });
};
export const updateProfileData = (formData) => (dispatch) => {
  dispatch({
    type: PROFILE_SETTINGS,
    payload: null,
  });
  http
    .post("update-profile", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        dispatch({
          type: PROFILE_SETTINGS_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: PROFILE_SETTINGS_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: PROFILE_SETTINGS_FAILED,
        payload: error,
      });
    });
};
export const updatePassword = (formData) => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD,
    payload: null,
  });
  http
    .post("update-password", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: CHANGE_PASSWORD_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CHANGE_PASSWORD_FAILED,
        payload: error,
      });
    });
};
export const updateMemberQuestions = (formData) => (dispatch) => {
  dispatch({
    type: MEMBER_QUESTION,
    payload: null,
  });
  http
    .post("update-security-question", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        dispatch({
          type: MEMBER_QUESTION_SUCCESS,
          payload: data,
        });
        setTimeout(() => {
          window.location.replace("/security-questions");
        }, 2000);
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: MEMBER_QUESTION_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MEMBER_QUESTION_FAILED,
        payload: error,
      });
    });
};
