import {
  FETCH_SINGLE_EMPLOYEE_CONTENT,
  FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS,
  FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED,
  SINGLE_EMPLOYEE_MESSAGE,
  SINGLE_EMPLOYEE_MESSAGE_SUCCESS,
  SINGLE_EMPLOYEE_MESSAGE_FAILED,
  FETCH_EMPLOYEES_CONTENT,
  FETCH_EMPLOYEES_CONTENT_FAILED,
  FETCH_EMPLOYEES_CONTENT_SUCCESS,
  EMPLOYEE_DELETE__MESSAGE,
  EMPLOYEE_DELETE_MESSAGE_SUCCESS,
  EMPLOYEE_DELETE__MESSAGE_FAILED,
  GET_FILTER_EMPLOYEE,
  GET_FILTER_EMPLOYEE_FAILED,
  GET_FILTER_EMPLOYEE_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  floor_plans: [],
  isFilterLoading: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTER_EMPLOYEE:
      return {
        ...state,
        isFilterLoading: true,
      };
    case GET_FILTER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload,
      };

    case GET_FILTER_EMPLOYEE_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload,
      };
    case FETCH_EMPLOYEES_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_EMPLOYEES_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_EMPLOYEES_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SINGLE_EMPLOYEE_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SINGLE_EMPLOYEE_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_SINGLE_EMPLOYEE_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SINGLE_EMPLOYEE_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case SINGLE_EMPLOYEE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case SINGLE_EMPLOYEE_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case EMPLOYEE_DELETE__MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case EMPLOYEE_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case EMPLOYEE_DELETE__MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    default:
      return state;
  }
}
