import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT,
    FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_SUCCESS,
    FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_FAILED,
    LISTINGS_DELETE__MESSAGE,
    LISTINGS_DELETE_MESSAGE_SUCCESS,
    LISTINGS_DELETE__MESSAGE_FAILED

} from "./actionTypes";



export const fetchCommercialListingDetails = (slug) => (dispatch) => {
    dispatch({
        type: FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT,
        payload: null
    });
    http
        .get("commercial-listing-details-page/" + slug)
        .then(({ data }) => {
            dispatch({
                type: FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_COMMERCIAL_LISTING_DETAILS_CONTENT_FAILED,
                payload: error
            });
        });
};
export const deleteListing = (formData) => (dispatch) => {
    dispatch({
        type: LISTINGS_DELETE__MESSAGE,
        payload: null
    });
    http
        .post('delete-listing/' + formData, helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.replace("/listing");
                }, 2000);
                dispatch({
                    type: LISTINGS_DELETE_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: LISTINGS_DELETE__MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: LISTINGS_DELETE__MESSAGE_FAILED,
                payload: error
            });
        });
};
