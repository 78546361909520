import React from "react";

export default function ExpenseStep2({ setFormStep, formStep, costsArr,
    handleCostChange,
    handleRemoveCost,
    handleAddCosts }) {
    return <>
        <fieldset>
            <div className="info_heading_confirm">
                <h4>Add One-Time Expenses</h4>
            </div>
            {costsArr?.one_time_costs?.map((one_time_cost, index) => {
                return (
                    <div className="new_blk_cost_add" key={one_time_cost?.randomKey}>
                        <div className="inner_blk">
                            <div className="closeBtn" onClick={(e) =>
                                handleRemoveCost(index, e, "one_time_costs")
                            }></div>
                            <div className="form_row row flex">
                                <div className="col-xs-6">
                                    <h6>Expense Name</h6>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="txtBox"
                                            placeholder="Expense Name"
                                            onChange={(e) =>
                                                handleCostChange(index, e, "one_time_costs")
                                            }
                                            defaultValue={one_time_cost.name}
                                        />
                                    </div>
                                </div>

                                <div className="col-xs-6 cell_mrgn_top">
                                    <h6>Cost</h6>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="cost"
                                            id=""
                                            className="txtBox"
                                            placeholder="Cost"
                                            onChange={(e) =>
                                                handleCostChange(index, e, "one_time_costs")
                                            }
                                            defaultValue={one_time_cost.cost}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className="bTn lbl_align_center">
                <button className=" colorBtn webBtn mini_web_btn" type="button" onClick={() => handleAddCosts("one_time_costs")}>
                    <i className="fa fa-plus"></i>
                </button>
            </div>
            <div className="cmnBtn text-center">
                <button type="button" className="webBtn backBtn" onClick={() => setFormStep(formStep - 1)}>Previous</button>
                <button type="button" className="webBtn colorBtn nextBtn" onClick={() => setFormStep(formStep + 1)}>Next</button>
            </div>
        </fieldset>
    </>;
}
