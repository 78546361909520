import React, { useEffect, useState } from "react";
import SignupForm from "./section/signup-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { ToastContainer } from "react-toastify";

import {
  fetchSignup,
  postSignup,
  postVerifyOtp,
} from "../../states/actions/fetchSignup";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import SignupFormGoogle from "./section/signup-form-google";
import { consoleLog } from "../../helpers/api";

export default function Signup() {
  const [signUpStep, setSignupStep] = useState("signup");
  const [googleValues, setGoogleValues] = useState(null);
  const dispatch = useDispatch();
  const handleSignup = (formData) => {
    let newFormData = "";
    if (googleValues !== null && googleValues?.googleId !== "") {
      newFormData = {
        ...formData,
        type: "google",
        googleId: googleValues?.googleId,
        user_image: googleValues?.profileObj?.imageUrl,
      };
    } else {
      newFormData = {
        ...formData,
        type: "normal",
        googleId: "",
        user_image: "",
      };
    }
    dispatch(postSignup(newFormData));
  };
  useEffect(() => {}, [googleValues]);
  const onSuccess = (response) => {
    let formData = { ...response, type: "google" };
    setGoogleValues(formData);
    setSignupStep("google");
  };
  const onFailure = (response) => {
    consoleLog("FAILED", response);
    // if (response?.error) {
    //     toast.error("Login Error >> " + response?.error + " >> " + response?.details,
    //         TOAST_SETTINGS
    //     );
    // }
  };
  const handleVerifyOtp = (formData) => {
    dispatch(postVerifyOtp(formData));
  };
  const isFormProcessing = useSelector(
    (state) => state.fetchSignup.isFormProcessing
  );
  const emptyFormData = useSelector((state) => state.fetchSignup.emptyFormData);
  const data = useSelector((state) => state.fetchSignup.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector((state) => state.fetchSignup.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );

  useEffect(() => {
    dispatch(fetchSignup());
    dispatch(fetchSiteSettings());
  }, []);

  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  let content = data.content;
  const intro = content;

  return (
    <>
      <section className="logon">
        <ToastContainer />
        <div className="contain">
          <div className="log_blk">
            {signUpStep === "google" ? (
              <SignupFormGoogle
                data={intro}
                handleSignup={handleSignup}
                isFormProcessing={isFormProcessing}
                emptyFormData={emptyFormData}
                handleVerifyOtp={handleVerifyOtp}
                onSuccess={onSuccess}
                onFailure={onFailure}
                googleValues={googleValues}
              />
            ) : (
              <SignupForm
                data={intro}
                handleSignup={handleSignup}
                isFormProcessing={isFormProcessing}
                emptyFormData={emptyFormData}
                handleVerifyOtp={handleVerifyOtp}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            )}
            <div className="haveAccount text-center">
              <span>Already have an account?</span>{" "}
              <Link to="/login">Sign in</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
