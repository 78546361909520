import React from "react";
import GetFileIconByFileExtension from "../../../common/get-file-icon-by-extension";
import { format_date_time } from "../../../../helpers/api";

export default function ChatMsgBlk({ type, attachment = false, message }) {
  return (
    <>
      <div class={type === "you" ? "buble you" : "buble me"}>
        <div className="ico">
          <img src={message?.user_dp} alt={message?.user_name} />
        </div>
        <div className="txt">
          <div className="cntnt">
            <p>{message?.msg}</p>
            {/* {
                        message?.attachments?.length > 0 ?
                            <div className="flex_col_chat imgGallery">
                                <a href="/images/property/1.jpg" data-thumb="/images/property/1.jpg" className="col">
                                    <img src="/images/property/1.jpg" />
                                </a>
                                <a href="/images/property/1_1.jpg" data-thumb="/images/property/1_1.jpg" className="col">
                                    <img src="/images/property/1_1.jpg" />
                                </a>
                                <a href="/images/property/1_2.jpg" data-thumb="/images/property/1_2.jpg" className="col">
                                    <img src="/images/property/1_2.jpg" />
                                </a>
                            </div>
                            :
                            ""
                    } */}
            {message?.attachments?.length > 0 ? (
              <ul className="attachment_files">
                {message?.attachments?.map((attachment, index) => {
                  return (
                    <li key={index} data={attachment?.image_name}>
                      <GetFileIconByFileExtension
                        file={attachment?.file_name}
                        display_name={attachment?.image_name}
                      />
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
          <div className="time">{format_date_time(message?.time_ago)}</div>
        </div>
      </div>
    </>
  );
}
