import { useForm } from "react-hook-form";
import { useRef, useState } from "react";
import TerminationStep1 from "./termination_step1";
import TerminationStep2 from "./termination_step2";
import {
  datepickerDateGet,
  format_date_simple,
  moment_new_date,
} from "../../../../helpers/api";
import { useSelector, useDispatch } from "react-redux";
import { saveTerminateLease } from "../../../../states/actions/leases";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

export default function LeaseDetailsTerminateLeasePopup({
  lease_row,
  setLeaseTerminate,
  leaseTerminateItem,
}) {
  const disptach = useDispatch();
  const isTerminateFormLoading = useSelector(
    (state) => state.leases.isTerminateFormLoading
  );
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const [costs, setCosts] = useState({
    costs: [],
  });
  const [terminateEndDate, setTerminateEndDate] = useState(null);

  const handleAddCosts = (frmData) => {
    setCosts({
      ...costs,
      costs: costs?.costs.concat([
        {
          randomKey: Math.random(),
          name: frmData?.name,
          email: frmData?.email,
        },
      ]),
    });
  };
  const isTenantRemoved = useRef(null);
  function handleRemoveCost(idx, e) {
    e.preventDefault();
    // Create a new array excluding the element at the specified index
    const updatedCosts = [
      ...costs.costs.slice(0, idx),
      ...costs.costs.slice(idx + 1),
    ];
    // Update the state with the new array
    setCosts((prevCosts) => ({
      ...prevCosts,
      costs: updatedCosts,
    }));
  }
  useEffect(() => {
    if (
      leaseTerminateItem !== null &&
      leaseTerminateItem !== undefined &&
      leaseTerminateItem !== "" &&
      leaseTerminateItem?.termination_costs?.length > 0
    ) {
      const updatedTerminationCosts = leaseTerminateItem?.termination_costs.map(
        (cost) => ({
          ...cost,
          randomKey: Math.random(), // Generating a random key
        })
      );
      setCosts({
        ...costs,
        costs: updatedTerminationCosts,
      });
      setValue(
        "termination_date",
        leaseTerminateItem !== null &&
          leaseTerminateItem !== undefined &&
          leaseTerminateItem !== ""
          ? datepickerDateGet(leaseTerminateItem?.termination_date)
          : ""
      );
    }
  }, [leaseTerminateItem]);
  useEffect(() => {
    if (lease_row?.mnth_mnth !== "Yes" && lease_row?.mnth_mnth !== "yes") {
      setTerminateEndDate(datepickerDateGet(lease_row?.end_date));
    }
  }, [lease_row]);

  const handleCostChange = (idx, evt) => {
    const newCosts = costs.costs.map((cost, sidx) => {
      if (idx !== sidx) return cost;
      return { ...cost, [evt.target.name]: evt.target.value };
    });
    setCosts({ ...costs, costs: newCosts });
  };
  const onSubmit = (frmData) => {
    const newData = {
      ...frmData,
      termination_costs: JSON.stringify(costs?.costs),
      termination_date: format_date_simple(
        frmData?.termination_date,
        "YYYY/MM/DD"
      ),
      lease_id: lease_row?.id,
    };
    disptach(saveTerminateLease(newData));
  };
  const [formStep, setFormStep] = useState(1);
  return (
    <section className="popup update_popup termination_popup">
      <ToastContainer />
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <div
              className="crosBtn"
              onClick={() => setLeaseTerminate({ show: false, item: null })}
            ></div>
            <h4>Terminate Lease</h4>
            <div className="option_lbl sp_lbl">
              <ul>
                <li className={formStep === 1 ? "active" : ""}>Fee & Date</li>
                <li className={formStep === 2 ? "active" : ""}>Confirm</li>
              </ul>
            </div>
            <div className="dash_form_blk">
              <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                {formStep === 1 ? (
                  <TerminationStep1
                    control={control}
                    errors={errors}
                    register={register}
                    handleAddCosts={handleAddCosts}
                    handleRemoveCost={handleRemoveCost}
                    handleCostChange={handleCostChange}
                    costs={costs}
                    setFormStep={setFormStep}
                    trigger={trigger}
                    isValid={isValid}
                    leaseTerminateItem={leaseTerminateItem}
                    watchAllFields={watchAllFields}
                    terminateEndDate={terminateEndDate}
                  />
                ) : formStep === 2 ? (
                  <TerminationStep2
                    costs={costs}
                    setFormStep={setFormStep}
                    watchAllFields={watchAllFields}
                    isTerminateFormLoading={isTerminateFormLoading}
                    leaseTerminateItem={leaseTerminateItem}
                  />
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
