import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import ListingPrices from './prices';

export default function PropertySingleListing({ listing, branch_row, address, closePopup, showPopup }) {
    const [toggle, setToggle] = useState(null);
    const showToggle = (e, item) => {
        e.preventDefault()
        setToggle(toggle === null ? item : null);
    }
    const [pricePopup, setPricePopup] = useState(null);
    const showPricePopup = (e, item) => {
        e.preventDefault()
        setPricePopup(item);
        // alert(!popup);
    }
    const closePricePopup = (e) => {
        e.preventDefault()
        setPricePopup(null);

    }
    return (
        <>
            <div className="search_toggle_com invoice_toggle_outer">
                {


                    <div className="invoice_toggle active">
                        <ul>
                            <li>
                                <h5 className="lease_heading_mini">Size</h5>
                                <div className="specific_flex_span">
                                    <span>{branch_row.lot_size}</span>
                                    {/* <span>1 Bath</span> */}
                                    {/* <span>1000 SF</span> */}
                                </div>
                            </li>
                            <li>
                                <h5 className="lease_heading_mini">Price</h5>
                                <div>{listing?.price}</div>
                            </li>
                            <li className="hide_cell_hyp">
                                <h5 className="lease_heading_mini">Available</h5>
                                <div>{listing.available}</div>
                            </li>
                            <li className="hide_cell_hyp">
                                <h5 className="lease_heading_mini">Floorplan</h5>
                                <div>{listing.floor_plan?.floor_plan}</div>
                            </li>
                        </ul>

                        <div className="invoice_bdy" style={{ display: "block" }}>
                            <div className="header_commercial_search_pg">
                                <div className="address_commercial">
                                    {address}
                                </div>

                                <div className="right_info cstm_mini_search_vertical flex">
                                    <div className="bTn">
                                        <NavLink to="#!" className="mini_btn cstm_mini_search" onClick={() => showPopup(listing)}>
                                            <span>Images</span>
                                        </NavLink>

                                        <NavLink to="#!" className="mini_btn cstm_mini_search" onClick={(e) => showPricePopup(e, listing)}><span>Price</span>
                                        </NavLink>
                                        <a href="" className="mini_btn cstm_mini_search">
                                            <span>Offer</span>
                                        </a>

                                    </div>
                                </div>
                            </div>
                            <div className="gap_line_divide"></div>
                            <h3 className="detail_heading">Property Details</h3>
                            <div className="main_feature_list">
                                <ul className="flex feature_lising">
                                    <li className="show_cell_floor_only">
                                        <h6>Available</h6>
                                        <p>{listing?.available}</p>
                                    </li>
                                    <li>
                                        <h6>Laundry</h6>
                                        <p>{listing?.floor_plan?.laundry}</p>
                                    </li>

                                    <li>
                                        <h6>Flooring</h6>
                                        <p>{listing?.floor_plan?.flooring}</p>
                                    </li>

                                    <li>
                                        <h6>Countertop Type</h6>
                                        <p>{listing?.floor_plan?.counter_top}</p>
                                    </li>
                                    <li>
                                        <h6>Air Conditioning</h6>
                                        <p>{listing?.floor_plan?.air_conditioning}</p>
                                    </li>
                                    <li>
                                        <h6>Heating</h6>
                                        <p>{listing?.floor_plan?.heating}</p>
                                    </li>
                                    <li className="show_cell_floor_only">
                                        <h6>Floor Plan</h6>
                                        <p>{listing?.floor_plan?.floor_plan}</p>
                                    </li>

                                </ul>
                            </div>

                            <hr />
                            {
                                listing?.floor_plan.features != undefined && listing?.floor_plan?.features != '' && listing?.floor_plan?.features?.length > 0 ?
                                    <>
                                        <h3 className="detail_heading">Features</h3>
                                        <ul className="flex icon_listing">

                                            {
                                                listing?.floor_plan?.features?.map((feature, index) => {
                                                    return (
                                                        <li><span>{feature?.name}</span></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </>
                                    :
                                    ""
                            }


                        </div>

                    </div>


                }
                {
                    pricePopup !== null ?
                        <ListingPrices closePricePopup={closePricePopup} pricePopup={pricePopup} />
                        :
                        ""
                }


            </div>
        </>
    )
}
