import React, { useState } from 'react'
import ListingLst from './listingLst'
import Pagination from '../../../common/pagination';

export default function ExpiredListings({ type, items, handleDelete }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    return (
        <>
            <div id="expired" class={type === 'pending' ? "tab-pane fade a active in" : "tab-pane"}>

                <div className="dash_property_listing  grid_dash_property_listing">
                    {
                        currentItems !== undefined && currentItems !== '' && currentItems !== null && currentItems?.length > 0 ?
                            <>
                                {

                                    currentItems?.map((item, index) => {
                                        return (
                                            <ListingLst item={item} key={index} deleteItem={handleDelete} />
                                        )
                                    })
                                }

                            </>
                            :
                            <div className='alert alert-danger'>No listing(s) are  expired yet!</div>
                    }

                </div>
                <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />
            </div>
        </>
    )
}
