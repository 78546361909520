import React from "react";
import Faq from 'react-faq-component';

export default function FaqList({ data }) {
    return (
        <>
            <section className="faq_banner">
                <div className="contain">
                    {data?.map((val, index) => {
                        return(
                            <div className="outer_faq" key={index}>
                                <div className="faqLst">
                                    <Faq data={val}/>
                                </div>
                            </div>
                        )
                    })}
                    
                    
                </div>
            </section>
        </>
    )
}
