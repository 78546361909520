import { useState } from "react";
import CreditCardLst from "./credit-card-lst";
import Pagination from "../../../common/pagination";

export default function CreditCardBlk({ items, deletePaymentMethod, isPaymentMethodDeleted }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3)
    }
    return (
        <div id="bank" className="tab-pane fade a active in">
            <div className="outer_list bank_show_listing">
                {
                    currentItems && currentItems?.length > 0 ?
                        currentItems?.map((item, index) => {
                            return (
                                <CreditCardLst item={item} key={index} deletePaymentMethod={deletePaymentMethod} isPaymentMethodDeleted={isPaymentMethodDeleted} />
                            )
                        })
                        :
                        <div className="alert alert-danger">No card(s) are added yet!</div>
                }
            </div>
            <Pagination paginate={paginate} itemsPerPage={itemsPerPage} totalItems={items?.length} currentPage={currentPage} />
        </div>
    );
}