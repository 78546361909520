import React from "react";
import { getServerImage } from "../../../../../helpers/api";

export default function SingleMediaImage({
  image,
  path,
  handleRemoveImage,
  index,
}) {
  return (
    <>
      <li>
        <div className="image">
          <img src={getServerImage(path, image?.file_name)} alt="" />
          <div
            className="closeBtn"
            onClick={() => handleRemoveImage(index)}
          ></div>
        </div>
      </li>
    </>
  );
}
