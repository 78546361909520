import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "./sections/sidebar";
import CreditSort from "./credits/sort";
import CreditBlk from "./credits/blk";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchCredits,
  deleteCredit,
  getFilteredCredits,
} from "../../../states/actions/fetchSingleCredits";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function Credits() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleCredits?.content);
  const isLoading = useSelector((state) => state.fetchSingleCredits?.isLoading);
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleCredits?.isFilterLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleCredits?.isFormProcessing
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings?.isLoading
  );
  useEffect(() => {
    dispatch(fetchCredits());
  }, []);
  const handleDelete = (e, credit_id) => {
    e.preventDefault();
    dispatch(deleteCredit(credit_id));
  };

  const [filterData, setFilterData] = useState({
    branch_id: null,
    property_id: null,
  });
  const [creditType, setCreditType] = useState("pending");
  useEffect(() => {
    if (data?.credits?.length <= 0 && data?.posted_credits?.length > 0) {
      setCreditType("posted");
    } else if (data?.posted_credits?.length <= 0) {
      setCreditType("pending");
    } else if (data?.credits?.length <= 0) {
      setCreditType("posted");
    }
  }, [data]);
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
      property_id:
        type === "property_id" ? e.target.value : filterData?.property_id,
    };
    dispatch(getFilteredCredits(formData));
  };
  useDocumentTitle("Credits - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const credits = data?.credits;
  const posted_credits = data?.posted_credits;
  const properties = data?.properties;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="credits" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" ? (
                  <Breadcrumb page_name="Credits" add_link="/add-credit" />
                ) : (
                  <Breadcrumb page_name="Credits" />
                )}

                <div className="page_body">
                  <div className="listing_list_c">
                    <div className="outer_list_c">
                      <CreditSort
                        branches={branches}
                        handleBranchChange={handleBranchChange}
                        properties={properties}
                        creditType={creditType}
                        changeType={setCreditType}
                        pending_count={credits?.length}
                        posted_count={posted_credits?.length}
                      />
                      {isFilterLoading ? (
                        <div className="loadingDisabled">
                          <i className="spinner"></i>
                        </div>
                      ) : (
                        ""
                      )}
                      {creditType === "pending" ? (
                        <CreditBlk
                          credits={credits}
                          handleDelete={handleDelete}
                        />
                      ) : creditType === "posted" ? (
                        <CreditBlk
                          credits={posted_credits}
                          handleDelete={handleDelete}
                          type="posted"
                        />
                      ) : posted_credits?.length <= 0 &&
                        credits?.length <= 0 ? (
                        <div className="alert alert-danger">
                          No content added yet!
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
