import React, { useEffect, useState, useRef } from "react";
import { consoleLog, format_amount } from "../../../../helpers/api";
import { useForm } from "react-hook-form";
import Calculator from "./loan-calculator";

export default function PaymentEstimate({ listing_row }) {
  const listing_purchase_price = parseFloat(listing_row?.price);
  const { register, handleSubmit, setValue, watch } = useForm();
  const paymentData = watch();
  const [downPayment, setDownPayment] = useState(false);
  const [homePrice, setHomePrice] = useState(false);
  const [loanPrice, setLoanPrice] = useState(false);

  const downPaymentRef = useRef(null);
  const paymentAreaRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        downPaymentRef.current &&
        !downPaymentRef.current.contains(event.target) &&
        !(
          paymentAreaRef.current &&
          paymentAreaRef.current.contains(event.target)
        )
      ) {
        setDownPayment(false); // Hide Down Payment section if click is outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [downPaymentRef]);
  const homePriceRef = useRef(null);
  const homePricePaymentAreaRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        homePriceRef.current &&
        !homePriceRef.current.contains(event.target) &&
        !(
          homePricePaymentAreaRef.current &&
          homePricePaymentAreaRef.current.contains(event.target)
        )
      ) {
        setHomePrice(false); // Hide Down Payment section if click is outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [homePriceRef]);
  const loanPriceRef = useRef(null);
  const loanPricePaymentAreaRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        loanPriceRef.current &&
        !loanPriceRef.current.contains(event.target) &&
        !(
          loanPricePaymentAreaRef.current &&
          loanPricePaymentAreaRef.current.contains(event.target)
        )
      ) {
        setLoanPrice(false); // Hide Down Payment section if click is outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [loanPriceRef]);

  useEffect(() => {
    setValue("interest_rate", 5); // Set initial value for interest_rate_per_period
    setValue("interest_rate_per_period", parseFloat(5) / 12); // Set initial value for interest_rate_per_period
    setValue("years_maturity", 30); // Set initial value for years_maturity
    setValue("compound_monthly", 12); // Set initial value for compound_monthly
    setValue("down_payment_percent", 20); // Set initial value for down_payment_percent
    setValue("home_price", listing_purchase_price); // Set initial value for down_payment_percent
    const down_payment =
      parseFloat(parseFloat(20) / 100) * parseFloat(listing_purchase_price);
    setValue("down_payment", parseInt(down_payment));

    calculateExpression({
      interest_rate_per_period: parseFloat(5) / 12,
      years_maturity: 30,
      compound_monthly: 12,
      down_payment_percent: 20,
      down_payment: down_payment,
      home_price: listing_purchase_price,
    });
  }, []);

  const calculateExpression = (data) => {
    const downPayment = parseFloat(data?.down_payment) || 0;
    const down_payment_percent = parseFloat(data?.down_payment_percent) || 0;
    const interestRatePerPeriod =
      parseFloat(data?.interest_rate_per_period) || 0;
    const yearsMaturity = parseFloat(data?.years_maturity) || 0;
    const compoundMonthly = parseFloat(data?.compound_monthly) || 0;
    const homePrice = parseFloat(data?.home_price) || 0;
    const hoaFee = parseInt(listing_row?.monthly_hoa_fee) || 0;
    const condoFee = parseInt(listing_row?.monthly_condo_fee) || 0;
    const principalInterest =
      (homePrice - downPayment) /
      (1 / (interestRatePerPeriod / 100) -
        1 /
          ((interestRatePerPeriod / 100) *
            Math.pow(
              1 + interestRatePerPeriod / 100,
              yearsMaturity * compoundMonthly
            )));
    setValue("principal_interest", parseInt(principalInterest));

    const loanPrice = homePrice - downPayment;
    setValue("loan_price", loanPrice);

    let mortgageInsurance = 0;
    if (down_payment_percent < 20) {
      mortgageInsurance = (loanPrice * 0.0075) / 12 || 0;
    }
    setValue("mortgage_insurance", parseInt(mortgageInsurance));

    const propertyTaxVal = (homePrice * 0.01) / 12 || 0;
    setValue("property_tax", parseInt(propertyTaxVal));

    const homeownersInsurance = (loanPrice * 0.01) / 12 || 0;
    setValue("homeowners_insurance", parseInt(homeownersInsurance));

    // Calculate total estimate (sum of all values)
    const totalEstimate =
      parseInt(principalInterest) +
      parseInt(mortgageInsurance) +
      parseInt(homeownersInsurance) +
      parseInt(propertyTaxVal) +
      hoaFee +
      condoFee;

    setValue("total_estimate", parseInt(totalEstimate));

    // Calculate percentages for each category
    const calculatePercentage = (value) =>
      (parseInt(value) / totalEstimate) * 100;

    let principalAndInterestPercentage = calculatePercentage(principalInterest);
    let hoaDuesPercentage = calculatePercentage(hoaFee);
    let condoDuesPercentage = calculatePercentage(condoFee);
    let propertyTaxesPercentage = calculatePercentage(propertyTaxVal);
    let homeownersInsurancePercentage =
      calculatePercentage(homeownersInsurance);
    let mortgageInsurancePercentage = calculatePercentage(mortgageInsurance);

    // Ensure the sum of all percentages is exactly 100%
    let totalPercentage =
      principalAndInterestPercentage +
      hoaDuesPercentage +
      condoDuesPercentage +
      propertyTaxesPercentage +
      homeownersInsurancePercentage +
      mortgageInsurancePercentage;
    // Adjust if total percentage exceeds 100%
    if (totalPercentage > 100) {
      let excess = totalPercentage - 100;
      // Adjust the category with the largest percentage proportionally
      let maxPercentage = Math.max(
        principalAndInterestPercentage,
        hoaDuesPercentage,
        condoDuesPercentage,
        propertyTaxesPercentage,
        homeownersInsurancePercentage,
        mortgageInsurancePercentage
      );
      if (maxPercentage === principalAndInterestPercentage) {
        principalAndInterestPercentage -= excess;
      } else if (maxPercentage === hoaDuesPercentage) {
        hoaDuesPercentage -= excess;
      } else if (maxPercentage === condoDuesPercentage) {
        condoDuesPercentage -= excess;
      } else if (maxPercentage === propertyTaxesPercentage) {
        propertyTaxesPercentage -= excess;
      } else if (maxPercentage === homeownersInsurancePercentage) {
        homeownersInsurancePercentage -= excess;
      } else if (maxPercentage === mortgageInsurancePercentage) {
        mortgageInsurancePercentage -= excess;
      }
    }

    // Set percentage values for UI display
    setValue(
      "principalAndInterestPercentage",
      parseFloat(principalAndInterestPercentage)
    );
    setValue("hoaDuesPercentage", parseFloat(hoaDuesPercentage));
    setValue("condoDuesPercentage", parseFloat(condoDuesPercentage));
    setValue("propertyTaxesPercentage", parseFloat(propertyTaxesPercentage));
    setValue(
      "homeownersInsurancePercentage",
      parseFloat(homeownersInsurancePercentage)
    );
    setValue(
      "mortgageInsurancePercentage",
      parseFloat(mortgageInsurancePercentage)
    );
  };

  const onSubmit = (data) => {
    calculateExpression(data);
  };

  const handleInputChange = (e, field_name = "") => {
    let name = "";
    let value = "";
    if (field_name !== "") {
      name = field_name;
      value = e.target.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setValue(
      name,
      value !== "" && value !== null && value !== undefined && value >= 0
        ? parseFloat(value)
        : ""
    );
    if (name === "down_payment_percent") {
      let down_payment_percent = value !== "" ? parseFloat(value) : 0;
      let down_payment_val =
        parseFloat(parseFloat(down_payment_percent) / 100) *
        parseFloat(paymentData?.home_price);
      setValue("down_payment", parseInt(down_payment_val));
      let paymentDataObj = {
        ...paymentData,
        down_payment: down_payment_val,
        down_payment_percent: value,
      };
      calculateExpression(paymentDataObj);
    } else if (name === "down_payment") {
      let down_payment = value !== "" ? parseFloat(value) : 0;
      let down_payment_percent =
        parseFloat(parseFloat(down_payment) / paymentData?.home_price) * 100;
      setValue("down_payment_percent", parseInt(down_payment_percent));
      let paymentDataObj = {
        ...paymentData,
        down_payment: down_payment,
        down_payment_percent: down_payment_percent,
      };
      calculateExpression(paymentDataObj);
    } else if (name === "home_price") {
      let home_price =
        value !== "" && value !== null && value !== undefined && value > 0
          ? parseFloat(value)
          : 0;

      let property_tax = (parseFloat(home_price) * 1) / 12;
      setValue("property_tax", parseInt(property_tax));
      let down_payment =
        parseFloat(parseFloat(paymentData?.down_payment_percent) / 100) *
        parseFloat(home_price);
      setValue("down_payment", parseInt(down_payment));
      let down_payment_percent =
        parseFloat(parseFloat(down_payment) / paymentData?.home_price) * 100;
      // setValue("down_payment_percent", parseInt(down_payment_percent));
      let paymentDataObj = {
        ...paymentData,
        home_price: parseFloat(home_price),
        down_payment: down_payment,
      };
      calculateExpression(paymentDataObj);
    } else if (name === "interest_rate") {
      let interest_rate = value !== "" ? parseFloat(value) : 0;

      setValue("interest_rate", parseFloat(interest_rate));
      setValue("interest_rate_per_period", parseFloat(interest_rate) / 12);

      let paymentDataObj = {
        ...paymentData,
        interest_rate: parseFloat(interest_rate),
        interest_rate_per_period: parseFloat(interest_rate) / 12,
      };
      calculateExpression(paymentDataObj);
    } else {
      value = value !== "" ? parseFloat(value) : 0;
      let paymentDataObj = { ...paymentData, [name]: value };
      calculateExpression(paymentDataObj);
    }
  };
  useEffect(() => {
    // console.log(paymentData);
  }, [paymentData]);
  return (
    <>
      <h3 className="detail_heading">Payment Estimate</h3>
      <div className="top_head">
        <h5>{format_amount(paymentData?.total_estimate)}/month</h5>
      </div>
      <div className="flex payment_estimate_flex">
        <div className="colL">
          <div className="color_progress">
            <span
              className="light_blue"
              style={{
                width: paymentData?.principalAndInterestPercentage + "%",
              }}
            ></span>
            <span
              className="green"
              style={{
                width: paymentData?.mortgageInsurancePercentage + "%",
              }}
            ></span>
            <span
              className="dark_blue"
              style={{
                width: paymentData?.propertyTaxesPercentage + "%",
              }}
            ></span>
            {parseFloat(listing_row?.hoa_fee) > 0 ? (
              <span
                className="orange"
                style={{
                  width: paymentData?.hoaDuesPercentage + "%",
                }}
              ></span>
            ) : (
              ""
            )}
            {parseFloat(listing_row?.condo_fee) > 0 ? (
              <span
                className="pink"
                style={{
                  width: paymentData?.condoDuesPercentage + "%",
                }}
              ></span>
            ) : (
              ""
            )}
            <span
              className="yellow"
              style={{
                width: paymentData?.homeownersInsurancePercentage + "%",
              }}
            ></span>
          </div>
          <ul>
            <li>
              <div className="left_left">
                <span className="light_blue"></span>
                <p>Principal and interest</p>
              </div>
              <p>
                <strong>
                  {format_amount(paymentData?.principal_interest)}
                </strong>
              </p>
            </li>
            {parseFloat(listing_row?.hoa_fee) > 0 ? (
              <li>
                <div className="left_left">
                  <span className="orange"></span>
                  <p>HOA dues</p>
                </div>
                <p>
                  <strong>{format_amount(listing_row?.monthly_hoa_fee)}</strong>
                </p>
              </li>
            ) : (
              ""
            )}
            {parseFloat(listing_row?.condo_fee) > 0 ? (
              <li>
                <div className="left_left">
                  <span className="pink"></span>
                  <p>Condo dues</p>
                </div>
                <p>
                  <strong>
                    {format_amount(listing_row?.monthly_condo_fee)}
                  </strong>
                </p>
              </li>
            ) : (
              ""
            )}
            <li>
              <div className="left_left">
                <span className="dark_blue"></span>
                <p>Property taxes</p>
              </div>
              <p>
                <strong>{format_amount(paymentData?.property_tax)}</strong>
              </p>
            </li>
            <li>
              <div className="left_left">
                <span className="yellow"></span>
                <p>Homeowners' insurance</p>
              </div>
              <p>
                <strong>
                  {format_amount(paymentData?.homeowners_insurance)}
                </strong>
              </p>
            </li>
            <li>
              <div className="left_left">
                <span className="green"></span>
                <p>Mortgage Insurance</p>
              </div>
              <p>
                <strong>
                  {format_amount(paymentData?.mortgage_insurance)}
                </strong>
              </p>
            </li>
          </ul>
        </div>
        <div className="colR">
          <div className="type_payment_lbl">
            <div className="block_1 relative">
              <div
                className={
                  downPayment
                    ? "inner_type_payment_lbl active"
                    : "inner_type_payment_lbl"
                }
                onClick={() => setDownPayment(!downPayment)}
                ref={downPaymentRef}
              >
                <p>Down payment</p>
                <h5>
                  {paymentData?.down_payment_percent}% (
                  {format_amount(paymentData?.down_payment)})
                </h5>
              </div>

              <section
                className={downPayment ? "payment_area active" : "payment_area"}
                ref={paymentAreaRef}
              >
                <div className="inner">
                  {/* <div className="payment_heading">
                      <h5>
                        <strong>Down Payment</strong>
                      </h5>
                    </div> */}
                  <div className="payment_content">
                    <div className="bar">
                      <input
                        type="range"
                        className="form-range"
                        id="down_payment"
                        {...register("down_payment", {
                          value: paymentData.down_payment,
                        })}
                        min={0}
                        max={paymentData?.home_price}
                        onChange={(e) => handleInputChange(e, "down_payment")}
                        name="down_payment"
                      />
                    </div>

                    <div className="flex">
                      <label htmlFor="">Cash</label>
                      <div className="input_1 relative">
                        <input
                          type="number"
                          placeholder="7585"
                          className="input_num txtBox"
                          value={paymentData.down_payment}
                          onChange={handleInputChange}
                          name="down_payment"
                        />
                        <span>$</span>
                      </div>
                      <label htmlFor="">Percent</label>
                      <div className="input_2 relative">
                        <input
                          type="number"
                          placeholder="15"
                          className="input_num txtBox"
                          value={paymentData.down_payment_percent}
                          onChange={handleInputChange}
                          name="down_payment_percent"
                        />
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="block_1 relative">
              <div
                className={
                  homePrice
                    ? "inner_type_payment_lbl active"
                    : "inner_type_payment_lbl"
                }
                onClick={() => setHomePrice(!homePrice)}
                ref={homePriceRef}
              >
                <p>Home price</p>
                <h5>{format_amount(paymentData?.home_price)}</h5>
              </div>

              <section
                className={homePrice ? "payment_area active" : "payment_area"}
                ref={homePricePaymentAreaRef}
              >
                <div className="inner">
                  {/* <div className="payment_heading">
                    <h5>
                      <strong>Home price</strong>
                    </h5>
                  </div> */}
                  <div className="payment_content">
                    <div className="bar">
                      <input
                        type="range"
                        className="form-range"
                        id="home_price"
                        {...register("home_price", {
                          value: paymentData.home_price,
                        })}
                        min={listing_purchase_price}
                        max={10000000}
                        onChange={(e) => handleInputChange(e, "home_price")}
                        name="home_price"
                      />
                    </div>
                    <div className="flex">
                      <div className="input_3 relative">
                        <input
                          type="number"
                          placeholder="7585"
                          className="input_num txtBox"
                          name="home_price"
                          onChange={handleInputChange}
                          value={paymentData.home_price}
                        />
                        <span>$</span>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="block_1 relative">
              <div
                className={
                  loanPrice
                    ? "inner_type_payment_lbl active"
                    : "inner_type_payment_lbl"
                }
                onClick={() => setLoanPrice(!loanPrice)}
                ref={loanPriceRef}
              >
                <p>Loan details</p>
                <h5>
                  {paymentData?.years_maturity}-yr, {paymentData?.interest_rate}
                  %
                </h5>
              </div>

              <section
                className={loanPrice ? "payment_area active" : "payment_area"}
                ref={loanPricePaymentAreaRef}
              >
                <div className="inner">
                  {/* <div className="payment_heading">
                    <h5>
                      <strong>Interest rate</strong>
                    </h5>
                  </div> */}
                  <div className="payment_content">
                    <div className="bar">
                      <input
                        type="range"
                        className="form-range"
                        id="interest_rate"
                        {...register("interest_rate", {
                          value: paymentData.interest_rate,
                        })}
                        min={0}
                        max={100}
                        onChange={(e) => handleInputChange(e, "interest_rate")}
                        name="interest_rate"
                      />
                    </div>
                    <div className="flex">
                      <div className="input_3 relative">
                        <input
                          type="number"
                          placeholder="75.7"
                          className="input_num txtBox"
                          name="interest_rate"
                          value={paymentData?.interest_rate}
                          onChange={handleInputChange}
                        />
                        <span>%</span>
                      </div>
                    </div>

                    <div className="payment_head">
                      <h5>
                        <strong>Loan Term</strong>
                      </h5>
                      <div className="radios">
                        <div className="lblBtn">
                          <div className="switchBtn">
                            <input
                              type="radio"
                              name="years_maturity"
                              value="10"
                              onChange={handleInputChange}
                              defaultChecked={
                                paymentData?.years_maturity == "10"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <label htmlFor="10">10-year</label>
                        </div>
                        <div className="lblBtn">
                          <div className="switchBtn">
                            <input
                              type="radio"
                              name="years_maturity"
                              value="15"
                              onChange={handleInputChange}
                              defaultChecked={
                                paymentData?.years_maturity == "15"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <label htmlFor="15">15-year</label>
                        </div>
                        <div className="lblBtn">
                          <div className="switchBtn">
                            <input
                              type="radio"
                              name="years_maturity"
                              value="20"
                              onChange={handleInputChange}
                              defaultChecked={
                                paymentData?.years_maturity == "20"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <label htmlFor="20">20-year</label>
                        </div>

                        <div className="lblBtn">
                          <div className="switchBtn">
                            <input
                              type="radio"
                              name="years_maturity"
                              value="30"
                              onChange={handleInputChange}
                              checked={
                                parseInt(paymentData?.years_maturity) === 30
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <label htmlFor="30">30-year</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
