import React, { useEffect } from "react";
import FloorStep1 from "./floor-steps/step1";
import FloorStep2 from "./floor-steps/step2";
import FloorStep3 from "./floor-steps/step3";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import { isCheckedFeature } from "../../../../helpers/api";

export default function AddFloorBlk({
  changeStep,
  step,
  branches,
  amenities,
  images,
  uploadFloorImages,
  handleAddLabel,
  handleLabelChange,
  labels,
  handleRemoveLabelImage,
  setThumbnail,
  imageThumbnail,
  handleFloorSubmit,
  floor_plan_row,
  features,
  handleRemoveLabel,
  isFormProcessing,
  setImages,
  setLabels,
}) {
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  let features_ar = features;
  const onSubmit = (data) => {
    if (images !== undefined && images !== null && images !== "") {
      if (images?.kitchen === undefined || images?.kitchen?.length <= 0) {
        toast.error(
          "Please upload at least one image for the kitchen label",
          TOAST_SETTINGS
        );
        return;
      }

      if (parseInt(watchAllFields?.full_bathrooms) > 0) {
        for (
          let full_bathroom = 1;
          full_bathroom <= parseInt(watchAllFields?.full_bathrooms);
          full_bathroom++
        ) {
          if (
            images?.["full_bathroom_" + full_bathroom] === undefined ||
            images?.["full_bathroom_" + full_bathroom]?.length <= 0
          ) {
            let f_count =
              parseInt(watchAllFields?.full_bathrooms) > 1 ? full_bathroom : "";
            toast.error(
              "Please upload at least one image for the Full Bathroom " +
                f_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }
      if (parseInt(watchAllFields?.half_bathrooms) > 0) {
        for (
          let half_bathroom = 1;
          half_bathroom <= parseInt(watchAllFields?.half_bathrooms);
          half_bathroom++
        ) {
          if (
            images?.["half_bathroom_" + half_bathroom] === undefined ||
            images?.["half_bathroom_" + half_bathroom]?.length <= 0
          ) {
            let h_count =
              parseInt(watchAllFields?.half_bathrooms) > 1 ? half_bathroom : "";
            toast.error(
              "Please upload at least one image for the Half Bathroom " +
                h_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }
      if (
        parseInt(watchAllFields?.bedrooms) > 0 &&
        watchAllFields?.studio === "no"
      ) {
        for (let bed = 1; bed <= parseInt(watchAllFields?.bedrooms); bed++) {
          if (
            images?.["bedroom_" + bed] === undefined ||
            images?.["bedroom_" + bed]?.length <= 0
          ) {
            let b_count = parseInt(watchAllFields?.bedrooms) > 1 ? bed : "";
            toast.error(
              "Please upload at least one image for the Bedroom " +
                b_count +
                " label",
              TOAST_SETTINGS
            );
            return;
          }
        }
      }

      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "den") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "den") === 1
      ) {
        if (images?.den === undefined || images?.den?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Den label",
            TOAST_SETTINGS
          );
          return;
        }
      }
      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "basement") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "basement") ===
          1
      ) {
        if (images?.basement === undefined || images?.basement?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Basement label",
            TOAST_SETTINGS
          );
          return;
        }
      }
      if (
        isCheckedFeature(features_ar, watchAllFields?.features, "attic") ===
          true ||
        isCheckedFeature(features_ar, watchAllFields?.features, "attic") === 1
      ) {
        if (images?.attic === undefined || images?.attic?.length <= 0) {
          toast.error(
            "Please upload at least one image for the Attic label",
            TOAST_SETTINGS
          );
          return;
        }
      }
      let breakCondition = false;
      if (
        labels?.labels !== undefined &&
        labels?.labels !== null &&
        labels?.labels?.length > 0
      ) {
        labels.labels.map((label, sidx) => {
          if (
            label?.label !== undefined &&
            label?.label !== null &&
            label?.label !== ""
          ) {
            if (
              images?.[label?.label] === undefined ||
              images?.[label?.label] === null ||
              images?.[label?.label]?.length <= 0
            ) {
              breakCondition = true;
              return toast.error(
                "Please upload at least one image for the " +
                  (label?.label).toUpperCase() +
                  " label",
                TOAST_SETTINGS
              );
            }
          }
        });
      }
      if (breakCondition) {
        return;
      }
      let features = null;
      if (
        data?.features !== undefined &&
        data?.features !== null &&
        data?.features !== "" &&
        data?.features?.length > 0
      ) {
        features = data?.features;
      }
      const images_array = JSON.stringify(images);
      let newData = {
        ...data,
        images: images_array,
        imageThumbnail: imageThumbnail,
        features: features,
        features: features,
      };
      // ;
      handleFloorSubmit(newData);
    } else if (images === undefined || images === null || images === "") {
      toast.error("Please upload images under images labels! ", TOAST_SETTINGS);
    }
  };

  useEffect(() => {
    setImages(floor_plan_row?.all_images);
    setLabels({
      labels:
        floor_plan_row?.all_labels !== undefined
          ? floor_plan_row?.all_labels
          : [],
    });
    // setThumbnail(floor_plan_row?.imageThumbnail)
    setValue(
      "studio",
      floor_plan_row?.studio !== undefined &&
        floor_plan_row?.studio !== null &&
        floor_plan_row?.studio !== ""
        ? floor_plan_row?.studio
        : "no"
    );
    setValue("branch", floor_plan_row?.branch);
    setValue("floor_plan", floor_plan_row?.floor_plan);
    setValue("air_conditioning", floor_plan_row?.air_conditioning);
    setValue("heating", floor_plan_row?.heating);
    setValue("flooring", floor_plan_row?.flooring);
    setValue("counter_top", floor_plan_row?.counter_top);
    setValue("laundry", floor_plan_row?.laundry);
    setValue("features", floor_plan_row?.features);
  }, [floor_plan_row]);
  //
  return (
    <>
      <div className="page_body">
        <div className="option_lbl medium_opt_lbl justify_center_lbl_cell">
          <ul>
            <li className={step === 0 ? "active" : ""}>Step 1</li>
            <li className={step === 1 ? "active" : ""}>Step 2</li>
            <li className={step === 2 ? "active" : ""}>Step 3</li>
          </ul>
        </div>
        <div className="dash_form_blk">
          <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
            {step === 0 ? (
              <FloorStep1
                changeStep={changeStep}
                step={step}
                branches={branches}
                control={control}
                register={register}
                isValid={isValid}
                errors={errors}
                floor_plan_row={floor_plan_row}
                trigger={trigger}
                watchAllFields={watchAllFields}
              />
            ) : step === 1 ? (
              <FloorStep2
                changeStep={changeStep}
                step={step}
                features={features}
                register={register}
                isValid={isValid}
                errors={errors}
                floor_plan_row={floor_plan_row}
                trigger={trigger}
                control={control}
                watchAllFields={watchAllFields}
              />
            ) : step === 2 ? (
              <FloorStep3
                changeStep={changeStep}
                step={step}
                images={images}
                uploadFloorImages={uploadFloorImages}
                handleAddLabel={handleAddLabel}
                handleLabelChange={handleLabelChange}
                labels={labels}
                handleRemoveLabelImage={handleRemoveLabelImage}
                setThumbnail={setThumbnail}
                imageThumbnail={imageThumbnail}
                floor_plan_row={floor_plan_row}
                path="storage/floor_plans/"
                trigger={trigger}
                watchAllFields={watchAllFields}
                features={features}
                handleRemoveLabel={handleRemoveLabel}
                isFormProcessing={isFormProcessing}
              />
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </>
  );
}
