import React from "react";
import { format_amount } from "../../../../helpers/api";

export default function ListingPrices({ closePricePopup, pricePopup }) {
  return (
    <>
      <section className="popup small_popup" data-popup="offer-view">
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div
                className="crosBtn"
                onClick={(e) => closePricePopup(e)}
              ></div>
              {/* <h4>All Prices</h4> */}
              <div className="blk blockLst cell_block_cell pricesBlock">
                <table>
                  <thead>
                    <tr>
                      <th>Price</th>
                      <th>Term</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pricePopup?.prices_terms !== null &&
                    pricePopup?.prices_terms !== undefined &&
                    pricePopup?.prices_terms?.length > 0 ? (
                      pricePopup?.prices_terms?.map((prices_term, index) => {
                        return (
                          <tr>
                            <td>{format_amount(prices_term?.price)}</td>
                            <td>{prices_term?.term}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="2">
                          <div className="alert alert-danger">
                            No prices found!
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
