import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tiles from "../../common/tiles";
import UserCard from "../../common/user-card";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { profileSettings } from "../../../states/actions/profileSettings";
import LoadingScreen from "../../common/LoadingScreen";
import { NavLink } from "react-router-dom";
import Logo from "../../includes/logo";
import { getServerImage, postData } from "../../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { ErrorMessage } from "@hookform/error-message";
export default function Dashboard() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const [userEmail, setUserEmail] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const handleShowEmail = () => setEmailChange(true);
  const handleHideEmail = () => setEmailChange(false);
  const member_row = useSelector((state) => state.profileSettings.verify);

  const isLoading = useSelector((state) => state.profileSettings.isLoading);
  const dispatch = useDispatch();
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  async function resendEmail(e) {
    setEmailVerify(true);
    var form_data = new FormData();
    form_data.append("email", true);
    await postData("api/resend-email", form_data).then((data) => {
      if (data.status === 1) {
        setEmailVerify(false);
        toast.success(data.msg, TOAST_SETTINGS);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  }
  const onSubmit = (formValues) => {
    setEmailVerify(true);
    var form_data = new FormData();
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }
    postData("api/change-email", form_data).then((data) => {
      if (data.status === 1) {
        setEmailVerify(false);
        toast.success(data.msg, TOAST_SETTINGS);
        reset();
        setTimeout(() => {
          setEmailChange(false);
          setUserEmail(formValues?.email);
          document.getElementById("email").value = "";
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
        setEmailVerify(false);
      }
    });
  };
  useDocumentTitle("Dashboard - Loftus");
  useEffect(() => {
    dispatch(profileSettings());
  }, []);
  useEffect(() => {
    if (
      member_row?.settings?.member?.mem_email_verified !== 1 &&
      member_row?.settings?.member?.mem_email_verified !== true &&
      member_row?.settings?.member?.mem_email_verified !== "1"
    ) {
      setUserEmail(member_row?.settings?.member?.mem_email);
    }
  }, [member_row]);

  if (isLoading) return <LoadingScreen />;
  const member = member_row.settings.member;
  const dashboard_tiles = [
    {
      id: 1,
      image: "/images/dashboard/icons/fav_fav.svg",
      title: "Favorites",
      count: member?.total_favorites,
      link: "/favorites",
    },
    {
      id: 2,
      image: "/images/dashboard/icons/payment_payment.svg",
      title: "Payments",
      count:
        parseInt(member?.payments_count) +
        parseInt(member?.payments_landlord_count),
      link: "/payments",
    },
    {
      id: 3,
      image: "/images/dashboard/icons/property_property.svg",
      title: "Properties",
      count: member?.properties_count,
      link: "/properties",
    },
    {
      id: 4,
      image: "/images/dashboard/icons/listing_listing.svg",
      title: "Listings",
      count: member?.listings_count,
      link: "/listing",
    },
    {
      id: 5,
      image: "/images/dashboard/icons/offer_offer.svg",
      title: "Offers",
      count: member?.offers_count,
      link: "/offers",
    },
    {
      id: 6,
      image: "/images/dashboard/icons/lease_lease.png",
      title: "Leases",
      count:
        parseInt(member?.leases_count) + parseInt(member?.leases_sent_count),
      link: "/leases",
    },
    {
      id: 7,
      image: "/images/dashboard/icons/service_service.svg",
      title: "Service Staff",
      count: parseInt(member?.staff_count),
      link: "/service-staff",
    },
    {
      id: 8,
      image: "/images/dashboard/icons/request_request.svg",
      title: "Service Requests",
      count: parseInt(member?.maintainence_requests_count),
      link: "/service-requests",
    },
  ];

  // userVerification(member)
  return (
    <div
      index=""
      className={
        member?.mem_email_verified === 0
          ? "main_dashboard emailDashboard"
          : "main_dashboard"
      }
    >
      <ToastContainer />
      <section className="dashboard">
        <div className="contain">
          {member?.mem_verified === 0 ? (
            <div className="memVerification">
              {member?.mem_phone_verified === 0 ? (
                <div className="alert alert-danger">
                  Your phone number has not been verified. Please click here to
                  verify.{" "}
                  <NavLink to="/member-verification" className="webBtn smBtn">
                    Verify Phone Number
                  </NavLink>
                </div>
              ) : (
                ""
              )}
              {member?.mem_email_verified === 0 ? (
                emailChange === false ? (
                  <div id="email_box">
                    <div className="contain">
                      <div className="inner">
                        <Logo
                          src={getServerImage(
                            "storage/images/",
                            siteSettings?.site_logo
                          )}
                          name={siteSettings?.site_name}
                        />
                        <div className="content">
                          <p>
                            You are almost there! We sent an email to{" "}
                            <a href={"mailto:" + userEmail} className="strong">
                              {userEmail}
                            </a>
                          </p>
                          <p>
                            Just click on the link in that email to complete
                            your sign-up. If you don't see it, you may need to{" "}
                            <strong>check your spam</strong> folder.{" "}
                          </p>
                          <p>Still can't find the email?</p>
                          <div className="bTn text-center">
                            <NavLink
                              to="#!"
                              className="webBtn"
                              onClick={handleShowEmail}
                            >
                              Change Email
                            </NavLink>
                            <NavLink
                              to="#!"
                              className="webBtn colorBtn"
                              onClick={resendEmail}
                              disabled={emailVerify === true ? "disabled" : ""}
                            >
                              {emailVerify === true ? (
                                <>
                                  <i className="spinner"></i>
                                  <label>Sending...</label>
                                </>
                              ) : (
                                "Resend Email"
                              )}
                            </NavLink>
                          </div>
                          <p>
                            Need Help?{" "}
                            <NavLink to="/contact" className="lnk">
                              Contact Us
                            </NavLink>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <section
                    className="popup small_popup verify_phone_popup memberVerify emailVerify"
                    data-popup="verify-number"
                  >
                    <div className="tableDv">
                      <div className="tableCell">
                        <ToastContainer />
                        <div className="_inner">
                          <form
                            action=""
                            method="POST"
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="none"
                            id="resetEmailForm"
                          >
                            <div className="txtGrp text-center">
                              <h4>Update Email Address</h4>
                            </div>
                            <div className="txtGrp">
                              <h6>
                                Please enter your new email address. A link will
                                be sent for verification.
                              </h6>
                              <input
                                type="text"
                                name="email"
                                id="email"
                                className="txtBox"
                                placeholder="Email Address"
                                {...register("email", {
                                  required: "Required",
                                  pattern: {
                                    value:
                                      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                    message: "Email format is not valid!",
                                  },
                                })}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => (
                                  <p className="error">
                                    <i className="fi-warning"></i> {message}
                                  </p>
                                )}
                              />
                            </div>
                            <div className="text-center bTn">
                              <button
                                type="button"
                                className="webBtn blankBtn colorBtn"
                                onClick={handleHideEmail}
                              >
                                Back
                              </button>
                              <button
                                type="submit"
                                className="webBtn colorBtn"
                                disabled={
                                  emailVerify === true ? "disabled" : ""
                                }
                              >
                                {emailVerify === true ? (
                                  <i className="spinner"></i>
                                ) : (
                                  "Change"
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="change_layout">
              <div className="colL">
                <UserCard member={member} />
              </div>
              <div className="colR">
                <Tiles data={dashboard_tiles} />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
