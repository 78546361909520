import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Newsletter from "./newsletter";
import SocialLinks from "./social-links";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../common/LoadingScreen";
import { postNewsletter, fetchNewsletter } from "../../states/actions/fetchNewsletter";
import { ToastContainer } from "react-toastify";

export default function Footer() {

    const dispatch = useDispatch();
    const handleNewsletter = (formData) => {
        dispatch(postNewsletter(formData));
    };
    const isFormProcessing = useSelector(
        (state) => state.fetchNewsletter.isFormProcessing
    );
    const emptyFormData = useSelector(
        (state) => state.fetchNewsletter.emptyFormData
    );
    const settings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    useEffect(() => {
        dispatch(fetchNewsletter());
    }, []);
    const location = useLocation();
    const location_path = location.pathname;
    const current_page = location_path.split('/')[1];
    const pages = ['', 'about', 'mission', 'partner', 'services', 'blog', 'blog-detail', 'category', 'faq', 'privacy-policy', 'terms-conditions', 'contact', 'login', 'signup', 'reset-password', 'forgot-password', 'property'];
    const d = new Date();
    let year = d.getFullYear();
    if (isSiteSettingsLoading) return <LoadingScreen />;
    const data = {
        block_01: {
            title: "ADVERTISERS",
            list: [
                {
                    id: 1,
                    text: "Advertise",
                    link: "/",
                },
                {
                    id: 2,
                    text: "Add a Property",
                    link: "/",
                },
                {
                    id: 3,
                    text: "Digital Feeds Program",
                    link: "/",
                },
                {
                    id: 4,
                    text: "Customer Portal",
                    link: "/",
                },
                {
                    id: 5,
                    text: "Community Voice",
                    link: "/",
                },
                {
                    id: 6,
                    text: "Rental Manager",
                    link: "/",
                },
            ],
        },
        block_02: {
            title: "THE MARKETPLACE",
            list: [
                {
                    id: 1,
                    text: "WestsideRentals.com",
                    link: "/",
                },
                {
                    id: 2,
                    text: "LoopNet.com",
                    link: "/",
                },
                {
                    id: 3,
                    text: "Cityfeet.com",
                    link: "/",
                },
                {
                    id: 4,
                    text: "Showcase.com",
                    link: "/",
                },
                {
                    id: 5,
                    text: "Realla.co.uk",
                    link: "/",
                },
                {
                    id: 6,
                    text: "Realla.ca",
                    link: "/",
                },
            ],
        },

        block_03: {
            title: "NEIGHBORHOODS",
            list: [
                {
                    id: 1,
                    text: "Las Colinas",
                    link: "/",
                },
                {
                    id: 2,
                    text: "East Village",
                    link: "/",
                },
                {
                    id: 3,
                    text: "Hyde Park",
                    link: "/",
                },
                {
                    id: 4,
                    text: "Uptown Minneapolis",
                    link: "/",
                },
                {
                    id: 5,
                    text: "Downtown Austin",
                    link: "/",
                },
                {
                    id: 6,
                    text: "Lincoln Park",
                    link: "/",
                },
            ],
        },
        block_04: {
            title: "ABOUT US",
            list: [
                {
                    id: 1,
                    text: "About Us",
                    link: "/about",
                },
                {
                    id: 2,
                    text: "Contact Us",
                    link: "/contact",
                },
                {
                    id: 3,
                    text: "Blog",
                    link: "/blog",
                },
                {
                    id: 4,
                    text: "Sitemap",
                    link: "/",
                },
                {
                    id: 5,
                    text: "FAQs",
                    link: "/faq",
                },
            ],
        },
        block_05: {
            list: [
                {
                    id: 1,
                    text: "Privacy Policy",
                    link: "/privacy-policy",
                },
                {
                    id: 2,
                    text: "Terms & Conditions",
                    link: "/terms-conditions",
                },

            ],
        },
        social_links: [
            {
                id: 1,
                image: '/images/social-instagram.svg',
                link: settings.site_instagram
            },
            {
                id: 2,
                link: settings.site_facebook,
                image: '/images/social-facebook.svg'
            },
            {
                id: 3,
                link: settings.site_discord,
                image: '/images/social-discord.svg'
            },
            {
                id: 4,
                link: settings.site_twitter,
                image: '/images/social-twitter.svg'
            },
            {
                id: 4,
                image: '/images/social-email.svg',
                link: 'mailto:' + settings.site_email
            },
        ],
        newsletter_heading: "Join our mailing list",
        follow_heading: "Follow us",
        copyright: settings.site_copyright,
        site_name: settings.site_name,
    };
    return (
        <>
            {(pages.includes(current_page)) ?

                <footer>
                    <div className="contain">
                        <div className="flexRow flex">
                            <div className="col">
                                <h5>{data.block_01.title}</h5>
                                <ul className="lst">
                                    {data.block_01.list?.map((val) => {
                                        return (
                                            <li key={val.id}>
                                                <Link to={val.link}>{val.text}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="col">
                                <h5>{data.block_02.title}</h5>
                                <ul className="lst">
                                    {data.block_02.list?.map((val) => {
                                        return (
                                            <li key={val.id}>
                                                <a href={val.link}>{val.text}</a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="col">
                                <h5>{data.block_03.title}</h5>
                                <ul className="lst">
                                    {data.block_03.list?.map((val) => {
                                        return (
                                            <li key={val.id}>
                                                <Link to={val.link}>{val.text}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <div className="col">
                                <h5>{data.block_04.title}</h5>
                                <ul className="lst">
                                    {data.block_04.list?.map((val) => {
                                        return (
                                            <li key={val.id}>
                                                <Link to={val.link}>{val.text}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>

                            <div className="col">
                                <h5>{data.newsletter_heading}</h5>
                                <Newsletter handleNewsletter={handleNewsletter} isFormProcessing={isFormProcessing} emptyFormData={emptyFormData} />
                                <ToastContainer />
                                <h5>{data.follow_heading}</h5>
                                <SocialLinks data={data.social_links} />
                            </div>
                        </div>
                    </div>
                    <div className="copyright relative">
                        <div className="contain-fluid">
                            <div className="inner">
                                <p>Copyright © {year} <Link to="/">{data.site_name}</Link>. {data.copyright}</p>
                                <ul className="smLst flex">
                                    {data.block_05.list?.map((val) => {
                                        return (
                                            <li key={val.id}>
                                                <Link to={val.link}>{val.text}</Link>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                :
                ""
            }
        </>
    )
}
