import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { useSelector } from "react-redux";
import { postData } from "../../../helpers/api";
import { ErrorMessage } from "@hookform/error-message";

export default function EmailVerification() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({ mode: "onChange" });
  const [emailVerify, setEmailVerify] = useState(false);
  const [emailChange, setEmailChange] = useState(false);
  const handleShowEmail = () => setEmailChange(true);
  const handleHideEmail = () => setEmailChange(false);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  async function resendEmail(e) {
    setEmailVerify(true);
    var form_data = new FormData();
    form_data.append("email", true);
    await postData("api/resend-email", form_data).then((data) => {
      if (data.status === 1) {
        setEmailVerify(false);
        toast.success(data.msg, TOAST_SETTINGS);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  }
  const onSubmit = (formValues) => {
    setEmailVerify(true);
    var form_data = new FormData();
    for (var key in formValues) {
      form_data.append(key, formValues[key]);
    }
    postData("api/change-email", form_data).then((data) => {
      if (data.status === 1) {
        setEmailVerify(false);
        toast.success(data.msg, TOAST_SETTINGS);
        reset();
        setTimeout(() => {
          setEmailChange(false);
          document.getElementById("email").value = "";
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
        setEmailVerify(false);
      }
    });
  };
  const member = siteSettings?.member;
  useDocumentTitle("Email Verification - Loftus");
  if (member?.mem_verified === 1 || member?.mem_email_verified === 1) {
    return window.location.replace("/dashboard");
  }
  if (isSiteSettingsLoading) return <LoadingScreen />;
  return (
    <>
      {emailChange === false ? (
        <section className="email_verification">
          <ToastContainer />
          <div className="contain">
            <>
              <h3>Hello {member?.mem_fname}, Welcome to our website</h3>
              <div className="inner">
                <div className="_header">
                  <h3>Email Verification</h3>
                </div>
                <div className="_content">
                  <p>
                    We've sent you an email to the address given for
                    verification. Please check your email to verify and activate
                    your account.
                  </p>
                  <h5>Didn't receive email?</h5>
                  <div className="bTn">
                    <NavLink
                      to="#!"
                      className="webBtn smBtn"
                      disabled={emailVerify === true ? "disabled" : ""}
                      onClick={resendEmail}
                    >
                      {emailVerify === true ? (
                        <>
                          <i className="spinner"></i>
                          <label>Sending...</label>
                        </>
                      ) : (
                        "Resend Email"
                      )}
                    </NavLink>
                    <NavLink
                      to="#!"
                      className="webBtn smBtn blankBtn"
                      onClick={handleShowEmail}
                    >
                      Change Email
                    </NavLink>
                  </div>
                </div>
              </div>
            </>
          </div>
        </section>
      ) : (
        <section
          className="popup small_popup verify_phone_popup memberVerify"
          data-popup="verify-number"
        >
          <div className="tableDv">
            <div className="tableCell">
              <ToastContainer />
              <div className="_inner">
                <form
                  action=""
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="none"
                  id="resetEmailForm"
                >
                  <div className="txtGrp text-center">
                    <h4>Update Email Address</h4>
                  </div>
                  <div className="txtGrp">
                    <h6>
                      Please enter your new email address. A link will be sent
                      for verification.
                    </h6>
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="txtBox"
                      placeholder="Email Address"
                      {...register("email", {
                        required: "Required",
                        pattern: {
                          value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                          message: "Email format is not valid!",
                        },
                      })}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => (
                        <p className="error">
                          <i className="fi-warning"></i> {message}
                        </p>
                      )}
                    />
                  </div>
                  <div className="text-center bTn">
                    <button
                      type="button"
                      className="webBtn blankBtn colorBtn"
                      onClick={handleHideEmail}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="webBtn colorBtn"
                      disabled={emailVerify === true ? "disabled" : ""}
                    >
                      {emailVerify === true ? (
                        <i className="spinner"></i>
                      ) : (
                        "Change"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
