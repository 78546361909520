let base_url = "";
let hostname = window.location.hostname;
if (hostname === "localhost") {
  // base_url = "http://127.0.0.1:8000/";
  base_url = process.env.REACT_APP_API_URL;
} else {
  base_url = process.env.REACT_APP_API_URL;
}
module.exports = {
  API_BASE_URL: `${base_url}api/`,
  API_UPLOADS_URL: `${base_url}storage/`,
  API_ASSETS_IMAGES_URL: `${base_url}assets/images/`,
  API_CMS_BG_IMAGES_URL: `${base_url}storage/images/`,
};
