import React from "react";
import {
  fileValidation,
  getServerImage,
  upload_file,
} from "../../../../helpers/api";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
export default function SaleStep2({
  step,
  changeStep,
  register,
  errors,
  isValid,
  control,
  watchAllFields,
  trigger,
  listing_row,
  property_row,
  setSaleOfferCoverLetter,
  saleOfferCoverLetter,
  isFormProcessing,
}) {
  let offer_letter_file = null;
  function uploadLogo() {
    offer_letter_file.click();
  }
  async function handleUpload(e) {
    setSaleOfferCoverLetter({ ...saleOfferCoverLetter, loading: true });
    const files = e.target.files[0];
    let valid = fileValidation(files);
    if (valid.error) {
      toast.error(valid.error, TOAST_SETTINGS);
    } else {
      let file = await upload_file(files);
      if (file?.status === 1) {
        document.getElementById("offer_letter").value = "";
        setSaleOfferCoverLetter({
          ...saleOfferCoverLetter,
          file: file?.file_name,
          file_name: files?.name,
          loading: false,
        });
      } else {
        setSaleOfferCoverLetter({ ...saleOfferCoverLetter, loading: false });
        toast.error(file?.msg, TOAST_SETTINGS);
      }
    }
  }
  function deleteCover() {
    setSaleOfferCoverLetter({
      ...saleOfferCoverLetter,
      file: null,
      file_name: null,
      loading: false,
    });
  }
  return (
    <>
      <fieldset style={{ display: "block" }}>
        <div className="medium_blk apply_form">
          {/* <h4 className="ofer_heading">Offering</h4> */}
          <div className="form_row row relative text-center">
            {saleOfferCoverLetter?.loading === true ? (
              <div className="loadingDisabled">
                <i className="spinner"></i>
              </div>
            ) : (
              ""
            )}
            <div className="col-sm-12">
              <label>Please upload your pre-approval document </label>
              <div className="upload_blk" onClick={uploadLogo}>
                <img src="/images/upload.png" />
                {/* <span>Upload your Cover letter</span> */}
              </div>
            </div>
            <input
              type="file"
              name="offer_letter"
              id="offer_letter"
              className="uploadFile"
              onChange={handleUpload}
              ref={(file) => (offer_letter_file = file)}
            />
            <div className="col-md-12">
              {/* <div className="sale_upload_file relative">
                            <a href={getServerImage('storage/attachments/', saleOfferCoverLetter?.file)}>
                                <i className="fa fa-file-o"></i>
                                <span>{saleOfferCoverLetter?.file_name}</span>
                            </a>
                        </div> */}
              {saleOfferCoverLetter?.file !== undefined &&
              saleOfferCoverLetter?.file !== null &&
              saleOfferCoverLetter?.file !== "" &&
              saleOfferCoverLetter?.file !== "null" ? (
                <div className="flex file_preview sale_upload_file">
                  <div className="sm_icon">
                    <img src="/images/document.png" alt="" />
                  </div>
                  <div className="_cnt">
                    <h5>{saleOfferCoverLetter?.file_name}</h5>
                  </div>
                  <div className="flex sale_offer_actions">
                    <a
                      href={getServerImage(
                        "storage/attachments/",
                        saleOfferCoverLetter?.file
                      )}
                      target="_blank"
                      className=""
                    >
                      <i className="fi-eye"></i>{" "}
                    </a>
                    <a href="#!" className="danger" onClick={deleteCover}>
                      <i className="fi-trash"></i>{" "}
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className="col-xs-4"></div> */}
          </div>

          <div className="cmnBtn text-center offering_send_btns">
            <button
              type="button"
              className="webBtn backBtn"
              onClick={() => changeStep(step - 1)}
            >
              Previous
            </button>
            {!isValid ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  trigger();
                }}
              >
                Submit
              </button>
            ) : (
              <button
                type="submit"
                className="webBtn colorBtn nextBtn"
                disabled={
                  !isValid || saleOfferCoverLetter?.loading || isFormProcessing
                }
              >
                Submit
                {saleOfferCoverLetter?.loading === true || isFormProcessing ? (
                  <i className="spinner"></i>
                ) : (
                  ""
                )}
              </button>
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}
