import React from "react";
import {
  format_amount,
  format_date,
  format_date_simple,
  getServerImage,
} from "../../../../helpers/api";
import { NavLink } from "react-router-dom";
import { postChatMsg } from "../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
import Text from "../../../common/Text";
export default function LeaseBlk({ lease }) {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.sendChat.isFormProcessing
  );

  const handleStartChat = (tenant_id) => {
    if (
      tenant_id !== undefined &&
      tenant_id !== null &&
      tenant_id !== "" &&
      tenant_id > 0
    ) {
      let frmData = { msg: "", property_mem_id: tenant_id };
      dispatch(postChatMsg(frmData, true));
    } else {
      toast.error(
        "Invalid User or user does not exit in our system!",
        TOAST_SETTINGS
      );
      return;
    }
  };
  return (
    <>
      <div className="lst" data-offer="received-leases">
        <ul>
          <li>
            <div className="icoBlk">
              <div className="icon border_solid wide_mobile_img">
                <img
                  src={getServerImage(
                    lease?.image_type === "media"
                      ? "storage/media/"
                      : "storage/floor_plans/",
                    lease?.imageThumbnail
                  )}
                  alt="Tickets"
                />
                {/* {
                                    lease?.pending_leaes > 0 ?
                                        <span className="badge yellow cell_lbl">Pending</span>
                                        :
                                        <span className="badge green cell_lbl">Completed</span>
                                } */}
              </div>
              <div className="txt">
                <h5>{lease?.title}</h5>
                <p>
                  <small>
                    <Text string={lease?.address} />
                  </small>
                </p>
                {
                  lease?.status === "rejected" ? (
                    <span className="badge red hide_cell_hyp">Declined</span>
                  ) : parseInt(lease?.expired) === 1 ||
                    parseInt(lease?.is_expired) === 1 ? (
                    <span className="badge red hide_cell_hyp">Expired</span>
                  ) : lease?.status === "accepted" ? (
                    <span className="badge green hide_cell_hyp">Active</span>
                  ) : (
                    <span className="badge yellow hide_cell_hyp">Pending</span>
                  )
                  // <span className="badge yellow hide_cell_hyp">Pending</span>
                }
                <div className="hide_desktop_col">
                  <div className="sep_hypn">
                    {parseInt(lease?.expired) === 1 ||
                    parseInt(lease?.is_expired) === 1 ? (
                      <span>{format_date_simple(lease?.end_date)}</span>
                    ) : lease?.status === "rejected" ? (
                      <span>NA</span>
                    ) : (
                      <span>
                        {lease?.expires_after}
                        {lease?.is_expired ? (
                          <em>Expired</em>
                        ) : (
                          <em>Expires</em>
                        )}
                      </span>
                    )}
                  </div>
                  <div className="sep_hypn">
                    <span>{format_amount(lease?.rent_amount)}</span>
                    <span>
                      {lease?.total_lease_time} <em>lease</em>
                    </span>
                  </div>
                  <ul className="tenants_dps_flex">
                    {lease?.tenants?.length > 0
                      ? lease?.tenants?.map((tenant, index) => {
                          return (
                            <li key={index}>
                              <div className="ico">
                                <img
                                  src={getServerImage(
                                    "storage/members/",
                                    tenant?.thumb
                                  )}
                                  alt={tenant?.name}
                                />
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li className="property_title hide_cell_hyp">
            <h5 className="lease_heading_mini">Tenants</h5>
            <ul className="tenants_dps_flex">
              {lease?.tenants?.length > 0
                ? lease?.tenants?.map((tenant, index) => {
                    return (
                      <li key={index}>
                        <div className="inner">
                          <div className="mini_icon">
                            <img
                              src={getServerImage(
                                "storage/members/",
                                tenant?.thumb
                              )}
                              alt={tenant?.name}
                            />
                          </div>
                          <div className="bio_info">
                            <div className="name">
                              <h4>{tenant?.name}</h4>
                            </div>
                            {tenant?.current_tenant !== 1 ? (
                              <a
                                href="#!"
                                className="start_chat"
                                onClick={() => handleStartChat(tenant?.mem_id)}
                                disabled={isFormProcessing}
                              >
                                Start Chat
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="ico">
                          <img
                            src={getServerImage(
                              "storage/members/",
                              tenant?.thumb
                            )}
                            alt={tenant?.name}
                          />
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Price</h5>
            <div className="price">{format_amount(lease?.rent_amount)}</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Term</h5>
            <div className="date">{lease?.total_lease_time} mth</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">
              {parseInt(lease?.expired) === 1 ||
              parseInt(lease?.is_expired) === 1
                ? "Expired"
                : "Expires"}
            </h5>
            {parseInt(lease?.expired) === 1 ? (
              <div>{format_date_simple(lease?.end_date)}</div>
            ) : lease?.status === "rejected" ? (
              <div>NA</div>
            ) : (
              <div>
                {lease?.expires_after}{" "}
                {lease?.expires_after !== "NA-mtm" ? "days" : ""}
              </div>
            )}
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Status</h5>
            {
              lease?.status === "rejected" ? (
                <span className="badge red">Declined</span>
              ) : parseInt(lease?.expired) === 1 ||
                parseInt(lease?.is_expired) === 1 ? (
                <span className="badge red">Expired</span>
              ) : lease?.status === "accepted" ? (
                <span className="badge green">Active</span>
              ) : (
                ""
              )
              // <span className="badge yellow hide_cell_hyp">Pending</span>
            }
          </li>
          <li className="bTn action_drop_lease hide_cell_hyp">
            <div className="action_drop _dropDown">
              <div className="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul className="_dropCnt dropLst">
                <li>
                  <NavLink to={"/my-lease-details/" + lease?.encoded_id}>
                    View
                  </NavLink>
                </li>
                //{" "}
                <li>
                  //{" "}
                  <a
                    href="?"
                    onclick="return confirm('Are you sure?');"
                    className=""
                  >
                    Delete
                  </a>
                </li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </li>
        </ul>
        {lease?.tenant_lease ? (
          <NavLink to={"/my-lease-details/" + lease?.encoded_id}></NavLink>
        ) : (
          <NavLink to={"/lease-details/" + lease?.encoded_id}></NavLink>
        )}
      </div>
    </>
  );
}
