import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  fetchCommercialListingDetails,
  deleteListing,
} from "../../../states/actions/fetchCommercialListingDetails";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { format_amount, postData, projectUrl } from "../../../helpers/api";
import PropertyHeader from "../single-property/section/header";
import PropertyGallery from "../single-property/section/gallery";
import PropertyDetails from "../single-property/section/details";
import PropertyLocation from "../single-property/section/locations";
import PropertySingleListing from "../single-property/section/singleListing";
import HouseOperations from "../single-property/section/house-operations";
import PropertyReviews from "../single-property/section/reviews";
export default function CommercialListingDetails() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.fetchCommercialListingDetails.content
  );
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchCommercialListingDetails.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteListing(data));
  };
  const [reviewPopup, setReviewPopup] = useState({
    item: null,
    show: false,
  });
  const [reviewLoading, setLoading] = useState(false);
  const showReviewPopup = (item) => {
    setReviewPopup({ ...reviewPopup, item: item, show: true });
    // alert(!popup);
  };
  const closeReviewPopup = () => {
    setReviewPopup({ ...reviewPopup, item: null, show: false });
  };
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  useEffect(() => {
    dispatch(fetchCommercialListingDetails(slug));
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const listing = data.listing_row;

  let media = listing?.media;
  const property_url = projectUrl("property/" + listing?.property?.slug);
  return (
    <>
      {listing === undefined ? (window.location.href = "/listing") : ""}
      <ToastContainer />
      <div
        class={
          popup.show === true &&
          popup.item != undefined &&
          popup.item != null &&
          popup.item != ""
            ? "floor_popup main_dashboard"
            : "main_dashboard"
        }
      >
        <section className="property_detail_main_sr">
          <div className="contain">
            <div className="page_body">
              <PropertyHeader
                address={listing?.address}
                floor_plan_name={listing?.floor_plan_name}
                listing={true}
                listing_row={listing}
                handleListingDelete={handleDelete}
                property={listing?.property}
                item={listing?.property}
                property_url={property_url}
                commercial_listing={true}
                member={member}
              />
              <div className="view_property_flex flex">
                <div className="view_property_gallery colL">
                  <div className="search-detail-grid">
                    <PropertyGallery
                      images={
                        media !== undefined &&
                        media !== null &&
                        media !== "" &&
                        media?.media_images !== undefined &&
                        media?.media_images !== null &&
                        media?.media_images !== "" &&
                        media?.media_images?.length > 0
                          ? media?.media_images
                          : listing?.images
                      }
                      popup={popup}
                      closePopup={closePopup}
                      showPopup={showPopup}
                      property={listing}
                      thumbnail={
                        media !== undefined &&
                        media !== null &&
                        media !== "" &&
                        media?.imageThumbnail !== undefined &&
                        media?.imageThumbnail !== null &&
                        media?.imageThumbnail !== "" > 0
                          ? media?.imageThumbnail
                          : ""
                      }
                      path={
                        media !== undefined && media !== null && media !== ""
                          ? "storage/media/"
                          : "storage/floor_plans/"
                      }
                      type="commercial"
                    />
                  </div>
                </div>
                <div className="colR">
                  <div className="result_detail_blk">
                    <PropertyDetails
                      property={listing?.property}
                      listing={true}
                      listing_row={listing}
                    />
                    <div className="gap_line_divide"></div>
                    <PropertySingleListing
                      listing={listing}
                      branch_row={listing?.branch_row}
                      address={listing?.address}
                      closePopup={closePopup}
                      showPopup={showPopup}
                    />
                    <hr />
                    <HouseOperations />
                    <hr />
                    <PropertyReviews
                      listing={true}
                      property={listing?.property}
                      member={member}
                      showReviewPopup={showReviewPopup}
                    />
                    <div className="gap_line_divide"></div>
                    {listing?.property?.formatted_address !== "" &&
                    listing?.property?.formatted_address !== null &&
                    listing?.property?.formatted_address !== undefined ? (
                      <PropertyLocation
                        address={listing?.property?.formatted_address}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
