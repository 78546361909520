import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { checkBranchTitleAvailability } from "../../../../../helpers/api";

export default function BranchStep1({
  branch_row,
  register,
  changeStep,
  step,
  errors,
  isValid,
  trigger,
  states,
  control,
  watchAllFields,
}) {
  const [titleAvailable, setTitleAvailability] = useState({
    loading: false,
    title: false,
    msg: "",
  });
  useEffect(() => {}, [titleAvailable]);
  async function checkTitleAvailability(form_data, property_id) {
    setTitleAvailability({ loading: true, title: false });
    let titleAvailableApi = await checkBranchTitleAvailability(
      form_data,
      property_id
    );

    if (titleAvailableApi?.status === 1) {
      setTitleAvailability({
        ...titleAvailable,
        title: true,
        loading: false,
        msg: "",
      });
      changeStep(step + 1);
    } else {
      setTitleAvailability({
        ...titleAvailable,
        title: false,
        msg: titleAvailableApi?.msg,
        loading: false,
      });
    }
  }
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Branch</h6>
            <div className="form_blk">
              <input
                type="text"
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row.name
                    : ""
                }
                className="txtBox"
                {...register("name", { required: "Required" })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
              {watchAllFields?.title !== "" &&
              titleAvailable?.title !== true &&
              titleAvailable?.msg !== "" ? (
                <p className="error">{titleAvailable?.msg}</p>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <div className="col-xs-12">
                        <h6>Address</h6>
                        <div className="form_blk">
                            <input type="text" name="address" id="address" className="txtBox" {...register("address", { required: "Required" })} defaultValue={branch_row != undefined && branch_row != null && branch_row !== '' ? branch_row.address : ''} />
                            <ErrorMessage
                                errors={errors}
                                name="address"
                                render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                            />
                        </div>
                    </div> */}
          <div className="clearfix" style={{ width: "50%" }}></div>
          <div className="col-xs-6">
            <h6>Address Line 1</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line1"
                id="address_line1"
                className="txtBox"
                {...register("address_line1", { required: "Required" })}
                defaultValue={branch_row?.address_line1}
                disabled={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="address_line1"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Address Line 2</h6>
            <div className="form_blk">
              <input
                type="text"
                name="address_line2"
                id="address_line2"
                className="txtBox"
                {...register("address_line2")}
                defaultValue={branch_row?.address_line2}
                disabled={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? true
                    : false
                }
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>City</h6>
            <div className="form_blk">
              <input
                type="text"
                name="city"
                id="city"
                className="txtBox"
                {...register("city", { required: "Required" })}
                defaultValue={branch_row?.city}
                disabled={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="city"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>State</h6>
            <div className="form_blk">
              <Controller
                {...register("state", { required: "Required" })}
                name="state"
                control={control}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row.state
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={states}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      isDisabled={
                        branch_row != undefined &&
                        branch_row != null &&
                        branch_row !== ""
                          ? true
                          : false
                      }
                      label="Select State"
                      defaultValue={states.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="state"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>ZIP Code</h6>
            <div className="form_blk">
              <input
                type="text"
                name="zip_code"
                id="zip_code"
                className="txtBox"
                {...register("zip_code", {
                  required: "Required",
                  minLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                  maxLength: {
                    value: 5,
                    message: "Zip code should be  equal to 5 digits",
                  },
                })}
                defaultValue={branch_row?.zip_code}
                disabled={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? true
                    : false
                }
              />
              <ErrorMessage
                errors={errors}
                name="zip_code"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <NavLink to="/branches" className="webBtn backBtn">
            Cancel
          </NavLink>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : titleAvailable?.title !== true &&
            branch_row?.name !== watchAllFields?.name ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() =>
                checkTitleAvailability(watchAllFields, branch_row?.id)
              }
              disabled={titleAvailable?.loading === true ? true : false}
            >
              Next{" "}
              {titleAvailable?.loading === true ? (
                <i className="spinner"></i>
              ) : (
                ""
              )}
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
