import React from "react";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Pickers from "../../../common/pickers";
export default function RentalListingStep1({
  step,
  changeStep,
  register,
  control,
  errors,
  isValid,
  trigger,
}) {
  return (
    <>
      <fieldset>
        <div className="form_row row">
          <div className="col-xs-6">
            <h6>Available Date</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="available_date"
                // defaultValue=""

                {...register("available_date", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="available_date"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Offer Deadline</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="offer_deadline"
                // defaultValue=""

                {...register("offer_deadline")}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date(Date.now() + 86400000)}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="offer_deadline"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="col-xs-6">
            <h6>Lease Term</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="lease_term"
                id="lease_term"
                className="txtBox"
                {...register("lease_term", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
              />
              <span className="dollar_label">mth</span>
              <ErrorMessage
                errors={errors}
                name="lease_term"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Rent</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="rent_price"
                id="rent_price"
                className="txtBox"
                {...register("rent_price", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="rent_price"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="clearfix"></div>
          <div className="col-xs-6">
            <h6>Deposit</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="deposit_type"
                id="deposit_type"
                className="txtBox"
                {...register("deposit_type", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                })}
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="deposit_type"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          {/* <div className="col-xs-6">
                        <div className="form_blk flex check_flex_box">
                            <div className="lblBtn">
                                <input type="checkbox" name="flexible_lease" value="Yes" id="flexible_lease" {...register("flexible_lease")} />
                                <label htmlFor="flexible_lease">Flexible lease term</label>
                            </div>
                        </div>
                    </div> */}
          <div className="clearfix"></div>
          <div className="col-xs-6">
            <div className="form_blk flex check_flex_box">
              <div className="lblBtn">
                <input
                  type="checkbox"
                  name="entire_home"
                  value="Yes"
                  id="entire_home"
                  {...register("entire_home")}
                />
                <label htmlFor="entire_home">Entire home</label>
              </div>
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <NavLink to="/listing" className="webBtn backBtn">
            Previous
          </NavLink>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
