import React from "react";
import Heading from "../../common/heading";
import SearchForm from "./search-form";

export default function Banner({ data }) {
    return (
        <>
            <section className="banner" style={{ backgroundImage: "url(" + data.image + ")" }}>
                <div className="contain">
                    <div className="cntnt text-center">
                        <Heading size={1} text={data.heading} />
                        <p>{data.text}</p>
                    </div>
                    <div className="banner-form">
                        <SearchForm text={data.search_text} />

                    </div>
                </div>
            </section>
        </>
    )
}
