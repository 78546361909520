import React from 'react'
import { getServerImage, getSiteImages } from '../../helpers/api';
import ImagesBlk from './ImagesBlk';
import MultiFiles from './MultiFile';
import Images from './images';

export default function ImgGridRepeater({ title, labels, handleLabelChange, handleRemoveLabelImage, handleAddLabel, images, uploadMultipleImages, btn_name, path, handleRemoveLabel }) {
  return (
    <>

      {
        labels != undefined && labels != null ?
          <>
            {labels.labels?.map((label, index) => {
              return <>
                <div className="crud_image add_more_curd" key={index}>
                  <div className='crosBtn' onClick={() => handleRemoveLabel(index, label)}></div>
                  <div className="inner_curd">
                    <h6>{title}</h6>
                    <div className="formBlk">
                      <input type="text" defaultValue={label.label} className="txtBox" onChange={(e) => handleLabelChange(index, e)} />
                    </div>
                  </div>
                  <MultiFiles name={btn_name} label={label.label} uploadMultipleImages={uploadMultipleImages} />

                  {
                    label.label.length > 2 ?
                      <>
                        <Images images={images} label={label.label} handleRemoveLabelImage={handleRemoveLabelImage} path={path} />
                      </>
                      :
                      ""
                  }
                </div>
                <div className="gap_line_divide"></div>
              </>
            })}


          </>
          :
          ""
      }
      <div className="add_more_prop_imgs">
        <div className="bTn">
          <a href="javascript:void(0)" className="webBtn mini_web_btn" onClick={handleAddLabel}><i className="fa fa-plus"></i></a>
        </div>
      </div>
    </>
  )
}
