import React from 'react'
import Image from '../../common/image';
import { Link } from 'react-router-dom';

export default function InfoSection({ data }) {
    return (
        <>
            <section className="info_sec">
                <div className="contain">
                    <div className="text-center cntnt">
                        <div className="sec_heading">
                            <h2>{data.heading}</h2>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="col">
                            <div className="inner">
                                <div className="image">
                                    <Image src={data.block1.image} alt={data.block1.heading} />
                                </div>
                                <div className="cntnt">
                                    <h4>{data.block1.heading}</h4>
                                    <p>{data.block1.text}</p>
                                    {
                                        (data.block1.link_url != '') && (data.block1.link_text != '') ?
                                            <Link to={data.block1.link_url} className="cstm_more_read"><span>{data.block1.link_text}</span> <i className="fi-arrow-right"></i></Link>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="inner">
                                <div className="image">
                                    <Image src={data.block2.image} alt={data.block2.heading} />
                                </div>
                                <div className="cntnt">
                                    <h4>{data.block2.heading}</h4>
                                    <p>{data.block2.text}</p>
                                    {
                                        (data.block2.link_url != '') && (data.block2.link_text != '') ?
                                            <Link to={data.block2.link_url} className="cstm_more_read"><span>{data.block2.link_text}</span> <i className="fi-arrow-right"></i></Link>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="inner">
                                <div className="image">
                                    <Image src={data.block3.image} alt={data.block3.heading} />
                                </div>
                                <div className="cntnt">
                                    <h4>{data.block3.heading}</h4>
                                    <p>{data.block3.text}</p>
                                    <div className="bTn">
                                        <a href={data.block3.app.link1} target="_blank">
                                            <Image src={data.block3.app.image1} alt={data.block3.app.link1} />
                                        </a>
                                        <a href={data.block3.app.link2} target="_blank">
                                            <Image src={data.block3.app.image2} alt={data.block3.app.link2} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
