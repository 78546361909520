import React from 'react'
import ActiveListings from './activeListing'
import ExpiredListings from './expiredListing'

export default function ListingTabs({ type, active, expired, listView }) {
    return (
        <>
            <div className="tab-content">
                {
                    type === 'active' ?
                        <ActiveListings type={type} items={active} listView={listView} />
                        :
                        (
                            type === 'pending' ?
                                <ExpiredListings type={type} items={expired} listView={listView} />
                                :
                                ""
                        )
                }
            </div>
        </>
    )
}
