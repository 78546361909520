import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_BILLING_CONTENT,
  FETCH_SINGLE_BILLING_CONTENT_SUCCESS,
  FETCH_SINGLE_BILLING_CONTENT_FAILED,
  SINGLE_BILLING_MESSAGE,
  SINGLE_BILLING_MESSAGE_SUCCESS,
  SINGLE_BILLING_MESSAGE_FAILED,
  FETCH_BILLING_CONTENT,
  FETCH_BILLING_CONTENT_FAILED,
  FETCH_BILLING_CONTENT_SUCCESS,
  BILLING_DELETE__MESSAGE,
  BILLING_DELETE_MESSAGE_SUCCESS,
  BILLING_DELETE__MESSAGE_FAILED,
  GET_FILTER_BILLING,
  GET_FILTER_BILLING_FAILED,
  GET_FILTER_BILLING_SUCCESS,
} from "./actionTypes";
export const fetchBilling = () => (dispatch) => {
  dispatch({
    type: FETCH_BILLING_CONTENT,
    payload: null,
  });
  http
    .get("billing")
    .then(({ data }) => {
      dispatch({
        type: FETCH_BILLING_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_BILLING_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleBilling = (billing_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_BILLING_CONTENT,
    payload: null,
  });
  http
    .get("single-billing/" + billing_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_BILLING_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_BILLING_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postBilling = (formData, billing_id) => (dispatch) => {
  //
  let postUrl = "";
  if (billing_id != undefined && billing_id !== null) {
    postUrl = "edit-billing/" + billing_id;
  } else {
    postUrl = "add-billing";
  }
  dispatch({
    type: SINGLE_BILLING_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/billing");
        }, 2000);
        dispatch({
          type: SINGLE_BILLING_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_BILLING_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_BILLING_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteBilling = (formData) => (dispatch) => {
  dispatch({
    type: BILLING_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-billing/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/billing");
        }, 2000);
        dispatch({
          type: BILLING_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: BILLING_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: BILLING_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredBilling = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_BILLING,
    payload: null,
  });
  http
    .post("billing", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_BILLING_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_BILLING_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_BILLING_FAILED,
        payload: error,
      });
    });
};
