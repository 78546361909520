import React from "react";
import { getServerImage } from "../../helpers/api";

export default function Thumbnail({
  imageThumbnail,
  deleteThumbnail,
  handleUploadThumbnail,
  path,
  page = "",
}) {
  let thumbnail = null;
  function uploadThumbnail() {
    thumbnail.click();
  }
  return (
    <>
      {page === "staff" ? (
        <div class="upLoadDp staff_dp_blk">
          <div class="ico">
            {
              imageThumbnail === 'loading.gif' ?
                <img
                  src='/images/loading.gif'
                  alt=""
                />
                :
                <img
                  src={getServerImage(
                    path,
                    imageThumbnail != null ? imageThumbnail : ""
                  )}
                  alt=""
                />
            }

            {imageThumbnail != null ? (
              <div className="closeBtn" onClick={deleteThumbnail}></div>
            ) : (
              ""
            )}
          </div>
          <div class="text-center">
            <button
              type="button"
              class="webBtn smBtn uploadImg"
              data-upload="dp_image"
              data-text="Change Photo"
              onClick={uploadThumbnail}
            >
              Upload Photo
            </button>
            <input
              type="file"
              name=""
              id=""
              class="uploadFile"
              data-upload="dp_image"
            />
          </div>
          <div class="noHats text-center">(Please upload photo)</div>
        </div>
      ) : (
        <>
          <div className="default_image_property_pg">
            <div className="image">
              <img
                src={getServerImage(
                  path,
                  imageThumbnail != null ? imageThumbnail : ""
                )}
                alt=""
              />
              {imageThumbnail != null ? (
                <div className="closeBtn" onClick={deleteThumbnail}></div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className="image_upload_blk uploadImg default_btn_prop"
            data-upload="gallery_image"
            onClick={uploadThumbnail}
          >
            <div className="small_upload_icon">
              <img src="/images/dashboard/icons/upload.png" alt="" />
            </div>
            <h3>Upload</h3>
          </div>
        </>
      )}
      <input
        type="file"
        name=""
        id=""
        className="uploadFile"
        data-upload="gallery_image"
        onChange={handleUploadThumbnail}
        accept="image/*"
        ref={(file) => (thumbnail = file)}
      />
    </>
  );
}
