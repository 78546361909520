import React from 'react'
import LocationBlk from '../../common/locationBlk'

export default function Locations({ data }) {
    return (
        <>
            <section className="destination">
                <div className="contain">
                    <div className="sec_heading text-center">
                        <h2>{data.heading}</h2>
                    </div>
                    <div className="flex">
                        {data.blocks?.map((val, index) => {
                            return (
                                <LocationBlk data={val} key={index} />
                            )
                        })}

                    </div>
                </div>
            </section>
        </>
    )
}
