import React from "react";
// import { Link } from "react-router-dom";

function SocialLinks({ data }) {
    return (
        <>
            <ul className="social flex">
                {data?.map((val, index) => {
                    return (
                        <li key={index}>
                            {
                                val.link != '' && val.link != undefined && val.link != null ?
                                    <a href={val.link} target="_blank" rel="noreferrer">
                                        <img src={val.image} alt={val.link} />
                                    </a>
                                    :
                                    ""
                            }
                        </li>
                    );
                })}

            </ul>
        </>
    );
}

export default SocialLinks;
