import { combineReducers } from "redux";
import fetchSiteSettings from "./fetchSiteSettings";
import fetchUserVerify from "./fetchUserVerify";
import fetchHome from "./fetchHome";
import fetchAbout from "./fetchAbout";
import fetchServices from "./fetchServices";
import fetchPartners from "./fetchPartners";
import fetchMission from "./fetchMission";
import fetchContact from "./fetchContact";
import fetchFaq from "./fetchFaq";
import fetchPrivacy from "./fetchPrivacy";
import fetchTerms from "./fetchTerms";
import fetchBlog from "./fetchBlog";
import fetchBlogDetail from "./fetchBlogDetail";
import fetchCategoryBlog from "./fetchCategoryBlog";
import fetchNewsletter from "./fetchNewsletter";
import fetchSignup from "./fetchSignup";
import fetchLogin from "./fetchLogin";
import fetchForgot from "./fetchForgot";
import fetchResetPassword from "./fetchResetPassword";
import fetchBranch from "./fetchBranch";
import fetchBranches from "./fetchBranches";
import fetchSingleFloorPlan from "./fetchSingleFloorPlan";
import fetchFloorPlans from "./fetchFloorPlans";
import fetchSingleProperty from "./fetchSingleProperty";
import fetchProperties from "./fetchProperties";
import fetchSingleListing from "./fetchSingleListing";
import fetchListings from "./fetchListings";
import fetchFrontendProperty from "./fetchFrontendProperty";
import fetchUserPropertyDetails from "./fetchUserPropertyDetails";
import profileSettings from "./profileSettings";
import fetchSingleUserProperty from "./fetchSingleUserProperty";
import fetchUserProperties from "./fetchUserProperties";
import fetchUserListings from "./fetchUserListings";
import fetchSingleUserListing from "./fetchSingleUserListing";
import fetchListingDetails from "./fetchListingDetails";
import fetchSendOfferProperty from "./fetchSendOfferProperty";
import fetchSaleEditOffer from "./fetchSendOfferProperty";
import fetchUserOffers from "./fetchUserOffers";
import fetchUserSingleOffer from "./fetchUserSingleOffer";
import fetchSingleMedia from "./fetchSingleMedia";
import fetchMedia from "./fetchMedia";
import fetchCommercialListingDetails from "./fetchCommercialListingDetails";
import fetchCommercialFrontendProperty from "./fetchCommercialFrontendProperty";
import fetchSearchProperties from "./fetchSearchProperties";
import fetchUserFavorites from "./fetchUserFavorites";
import sendChat from "./sendChat";
import leases from "./leases";
import payments from "./payments";
import fetchSingleBilling from "./fetchSingleBilling";
import fetchSingleCredits from "./fetchSingleCredits";
import fetchSingleExpenses from "./fetchSingleExpenses";
import fetchSinglePromotions from "./fetchSinglePromotions";
import fetchSingleDocuments from "./fetchSingleDocuments";
import fetchSingleContacts from "./fetchSingleContacts";
import fetchSingleEmployees from "./fetchSingleEmployees";
import fetchMaintenanceStaff from "./fetchMaintenanceStaff";
import fetchMaintenanceRequests from "./fetchMaintenanceRequests";

export default combineReducers({
  fetchSiteSettings,
  fetchUserVerify,
  fetchHome,
  fetchAbout,
  fetchServices,
  fetchPartners,
  fetchMission,
  fetchContact,
  fetchFaq,
  fetchPrivacy,
  fetchTerms,
  fetchBlog,
  fetchBlogDetail,
  fetchCategoryBlog,
  fetchNewsletter,
  fetchSignup,
  fetchLogin,
  fetchForgot,
  fetchResetPassword,
  fetchBranch,
  fetchBranches,
  fetchSingleFloorPlan,
  fetchFloorPlans,
  fetchSingleProperty,
  fetchProperties,
  fetchSingleListing,
  fetchListings,
  fetchFrontendProperty,
  fetchUserPropertyDetails,
  profileSettings,
  fetchSingleUserProperty,
  fetchUserProperties,
  fetchUserListings,
  fetchSingleUserListing,
  fetchListingDetails,
  fetchSendOfferProperty,
  fetchSaleEditOffer,
  fetchUserOffers,
  fetchUserSingleOffer,
  fetchSingleMedia,
  fetchMedia,
  fetchCommercialListingDetails,
  fetchCommercialFrontendProperty,
  fetchSearchProperties,
  fetchUserFavorites,
  sendChat,
  leases,
  payments,
  fetchSingleBilling,
  fetchSingleCredits,
  fetchSingleExpenses,
  fetchSinglePromotions,
  fetchSingleDocuments,
  fetchSingleContacts,
  fetchSingleEmployees,
  fetchMaintenanceStaff,
  fetchMaintenanceRequests
});
