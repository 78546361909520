import React from "react";
import BillingLst from "./biilingLst";

export default function BillingBlk({
  billing,
  setBillingPopup,
  handleDelete,
  type = "pending",
  mem_permissions,
}) {
  return (
    <>
      {billing?.length > 0 ? (
        billing?.map((billing_row, index) => {
          return (
            <BillingLst
              billing_row={billing_row}
              key={index}
              setBillingPopup={setBillingPopup}
              handleDelete={handleDelete}
              type={type}
              mem_permissions={mem_permissions}
            />
          );
        })
      ) : (
        <div className="alert alert-danger">No item(s) added yet!</div>
      )}
    </>
  );
}
