import {
  GET_LEASES,
  GET_LEASES_SUCCESS,
  GET_LEASES_FAILED,
  GET_PROPERTY_OFFERS,
  GET_PROPERTY_OFFERS_FAILED,
  GET_PROPERTY_OFFERS_SUCCESS,
  GET_PROPERTY_OFFER_DATA,
  GET_PROPERTY_OFFER_DATA_SUCCESS,
  GET_PROPERTY_OFFER_DATA_FAILED,
  CREATE_LEASE,
  CREATE_LEASE_FAILED,
  CREATE_LEASE_SUCCESS,
  FETCH_LEASE_DETAILS,
  FETCH_LEASE_DETAILS_SUCCESS,
  FETCH_LEASE_DETAILS_FAILED,
  LEASE_ACTION_TENANT,
  LEASE_ACTION_TENANT_SUCCESS,
  LEASE_ACTION_TENANT_FAILED,
  GET_PROPERTY_OFFERS_EMPTY,
  GET_FILTER_LEASES,
  GET_FILTER_LEASES_SUCCESS,
  GET_FILTER_LEASES_FAILED,
  GET_BRANCH_PROPERTIES,
  GET_BRANCH_PROPERTIES_FAILED,
  GET_BRANCH_PROPERTIES_SUCCESS,
  TERMINATE_LEASE,
  TERMINATE_LEASE_SUCCESS,
  TERMINATE_LEASE_FAILED,
  TERMINATE_LEASE_CANCEL,
  TERMINATE_LEASE_CANCEL_FAILED,
  TERMINATE_LEASE_CANCEL_SUCCESS,
  WITHDRAW_LEASE,
  WITHDRAW_LEASE_SUCCESS,
  WITHDRAW_LEASE_FAILED
} from "../actions/actionTypes";
const initialState = {
  isFormProcessing: false,
  isLoading: false,
  content: {},
  isOfferLoading: false,
  offers: [],
  isPropertyOfferoading: false,
  isLeaseFormLoading: false,
  isDetailsLoading: false,
  lease_details_content: {},
  lease_status: null,
  isLeaseActionFormLoading: false,
  listing_row: null,
  isFilterLoading: false,
  isLeaseLoading: false,
  tenant_action_date: null,
  branch_properties: [],
  isBranchLoading: false,
  isTerminateFormLoading: false,
  isTerminateCancelFormLoading: false,
  isWithdrawLease: false
};

export default function (
  state = initialState,
  { type, payload, notification }
) {
  switch (type) {
    case LEASE_ACTION_TENANT:
      return {
        ...state,
        isLeaseActionFormLoading: true
      };
    case LEASE_ACTION_TENANT_SUCCESS:
      return {
        ...state,
        isLeaseActionFormLoading: false,
        lease_status: payload?.lease_status
      };
    case LEASE_ACTION_TENANT_FAILED:
      return {
        ...state,
        isLeaseActionFormLoading: false,
        error: payload
      };
    case TERMINATE_LEASE:
      return {
        ...state,
        isTerminateFormLoading: true
      };
    case TERMINATE_LEASE_SUCCESS:
      return {
        ...state,
        isTerminateFormLoading: false
      };
    case TERMINATE_LEASE_FAILED:
      return {
        ...state,
        isTerminateFormLoading: false,
        error: payload
      };
    case WITHDRAW_LEASE:
      return {
        ...state,
        isWithdrawLease: true
      };
    case WITHDRAW_LEASE_SUCCESS:
      return {
        ...state,
        isWithdrawLease: false
      };
    case WITHDRAW_LEASE_FAILED:
      return {
        ...state,
        isWithdrawLease: false,
        error: payload
      };
    case TERMINATE_LEASE_CANCEL:
      return {
        ...state,
        isTerminateCancelFormLoading: true
      };
    case TERMINATE_LEASE_CANCEL_SUCCESS:
      return {
        ...state,
        isTerminateCancelFormLoading: false
      };
    case TERMINATE_LEASE_CANCEL_FAILED:
      return {
        ...state,
        isTerminateCancelFormLoading: false,
        error: payload
      };
    case FETCH_LEASE_DETAILS:
      return {
        ...state,
        isDetailsLoading: true,
        lease_details_content: {}
      };
    case FETCH_LEASE_DETAILS_SUCCESS:
      return {
        ...state,
        isDetailsLoading: false,
        lease_details_content: payload,
        lease_status: payload?.lease_status,
        tenant_action_date: payload?.tenant_action_date
      };
    case FETCH_LEASE_DETAILS_FAILED:
      return {
        ...state,
        isDetailsLoading: false,
        error: payload
      };
    case GET_LEASES:
      return {
        ...state,
        isLeaseLoading: true
      };
    case GET_LEASES_SUCCESS:
      return {
        ...state,
        isLeaseLoading: false,
        content: payload
      };

    case GET_LEASES_FAILED:
      return {
        ...state,
        isLeaseLoading: false,
        error: payload
      };
    case GET_FILTER_LEASES:
      return {
        ...state,
        isFilterLoading: true
      };
    case GET_FILTER_LEASES_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload
      };

    case GET_FILTER_LEASES_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload
      };
    case GET_PROPERTY_OFFERS:
      return {
        ...state,
        isOfferLoading: true
      };
    case GET_PROPERTY_OFFERS_EMPTY:
      return {
        ...state,
        offers: [],
        listing_row: null
      };
    case GET_PROPERTY_OFFERS_SUCCESS:
      return {
        ...state,
        isOfferLoading: false,
        offers: payload?.received_offers,
        listing_row: payload?.listing_row
      };
    case GET_BRANCH_PROPERTIES:
      return {
        ...state,
        isBranchLoading: true
      };
    case GET_BRANCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        isBranchLoading: false,
        branch_properties: payload?.branch_properties
      };

    case GET_BRANCH_PROPERTIES_FAILED:
      return {
        ...state,
        isBranchLoading: false,
        error: payload
      };
    case GET_PROPERTY_OFFER_DATA:
      return {
        ...state,
        isPropertyOfferoading: true
      };
    case GET_PROPERTY_OFFER_DATA_SUCCESS:
      return {
        ...state,
        isPropertyOfferoading: false,
        content: payload
      };

    case GET_PROPERTY_OFFER_DATA_FAILED:
      return {
        ...state,
        isPropertyOfferoading: false,
        error: payload
      };
    case CREATE_LEASE:
      return {
        ...state,
        isLeaseFormLoading: true
      };
    case CREATE_LEASE_SUCCESS:
      return {
        ...state,
        isLeaseFormLoading: false
      };

    case CREATE_LEASE_FAILED:
      return {
        ...state,
        isLeaseFormLoading: false,
        error: payload
      };
    default:
      return state;
  }
}
