import React from "react";
import moment from "moment";

import UploadLeasePdf from "./upload-pdf";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker from "react-datepicker";
import { Toaster, toast } from "react-hot-toast";
import { consoleLog, getServerImage } from "../../../../helpers/api";
import Text from "../../../common/Text";
export default function LeaseStep1({
  setLeasePdf,
  leasePdf,
  property,
  offer,
  control,
  register,
  errors,
  setValue,
  trigger,
  isValid,
  setFormStep,
  costsArr,
  handleCostChange,
  handleRemoveCost,
  handleAddCosts,
  tenants,
  togglePopup,
  handleRemoveTenant,
  watchAllFields,
  lease_row,
  update_lease = false,
  property_obj,
  mem_type,
  promotion_row,
  setCosts,
}) {
  const handleStartDateChange = (date) => {
    setValue("start_date", date);
    if (watchAllFields?.end_date && watchAllFields?.end_date < date) {
      setValue("end_date", null);
      toast.error("End date should be after start date");
      return;
    }
  };
  const convetStringToArray = (str) => {
    if (str.includes(",")) {
      return str.split(",");
    } else {
      return [];
    }
  };
  const handleStepChange = (e) => {
    e.preventDefault();
    if (promotion_row?.id > 0) {
      if (watchAllFields?.promo_code) {
        const rentObject = promotion_row?.prices?.find(
          (item) => item.expense.trim().toLowerCase() === "rent"
        );
        if (rentObject?.id > 0) {
          let rent_discount = 0;
          if (rentObject?.type === "percent") {
            rent_discount =
              (parseFloat(rentObject?.discount) / 100) *
              parseFloat(watchAllFields?.rent_amount);
            rent_discount =
              parseFloat(watchAllFields?.rent_amount) - rent_discount;
          }
          if (rentObject?.type === "amount") {
            rent_discount =
              parseFloat(watchAllFields?.rent_amount) -
              parseFloat(rentObject.discount);
          }
          setValue("rent_discount", rent_discount);
        }
        consoleLog(promotion_row?.prices, "prices");
        promotion_row?.prices.forEach((item) => {
          Object.keys(costsArr).forEach((category) => {
            setCosts((prevState) => ({
              ...prevState,
              [category]: prevState[category].map((expenseItem) => {
                const expenseLowerCase = expenseItem.name.trim().toLowerCase();
                const itemLowerCase = item.expense.trim().toLowerCase();
                const expense_cycles =
                  item.cycle !== "" ? convetStringToArray(item?.cycle) : [];

                if (itemLowerCase === expenseLowerCase) {
                  let discount = 0;
                  if (item.type === "percent") {
                    discount =
                      (parseFloat(item.discount) / 100) *
                      parseFloat(expenseItem.cost);
                    discount = parseFloat(expenseItem.cost) - discount;
                  } else if (item.type === "amount") {
                    discount =
                      parseFloat(expenseItem.cost) - parseFloat(item.discount);
                  }
                  if (
                    item?.cycle !== "" &&
                    item?.cycle !== null &&
                    item?.cycle !== undefined &&
                    item?.allcycle !== "yes" &&
                    item?.expense_type !== "one_time"
                  ) {
                    if (expense_cycles?.includes("first")) {
                      // console.log("first and applied discount", expenseItem);
                      return {
                        ...expenseItem,
                        prevtCost: discount,
                        discount_type: item?.type,
                        discount_value: item?.discount,
                      };
                    } else {
                      return {
                        ...expenseItem,
                        prevtCost: 0,
                        discount_type: item?.type,
                        discount_value: 0,
                      };
                    }
                  } else {
                    // console.log("applied discount", expenseItem);
                    return {
                      ...expenseItem,
                      prevtCost: discount,
                      discount_type: item?.type,
                      discount_value: item?.discount,
                    };
                  }
                }
                return expenseItem;
              }),
            }));
          });
        });

        setFormStep(2);
      } else {
        setFormStep(2);
      }
    } else {
      setFormStep(2);
    }
  };
  const handleEndDateChange = (date) => {
    if (watchAllFields?.start_date && date < watchAllFields?.start_date) {
      toast.error("End date should be after start date");
      return;
    } else {
      setValue("end_date", date);
    }
  };
  return (
    <>
      <Toaster position="top-center" />
      <fieldset>
        <div className="form_row row">
          <div className="col-xs-12 heading_fieldset lease_header_address">
            <div className="flex">
              <div className="colL">
                <h4>Property Address</h4>
                <p>
                  <Text string={property_obj?.address_single_line} />
                </p>
              </div>
              {promotion_row?.id > 0 ? (
                <div className="colR">
                  <div class="inner flex">
                    <h5>
                      Do you want to apply{" "}
                      <strong>{promotion_row?.promo_code}</strong> to this
                      lease?
                    </h5>
                    <div class="switchBtn">
                      <input
                        type="checkbox"
                        defaultChecked={false}
                        {...register("promo_code")}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <UploadLeasePdf setLeasePdf={setLeasePdf} leasePdf={leasePdf} />
          <div className="col-xs-12">
            <div className="form_blk">
              <div className="lblBtn">
                <input
                  type="checkbox"
                  id="mnth-mnth"
                  {...register("mnth_mnth")}
                  defaultChecked={
                    lease_row?.id > 0 && lease_row?.mnth_mnth === "Yes"
                      ? true
                      : false
                  }
                />
                <label for="mnth-mnth">Continue Month-to-Month</label>
                <div class="info lease_month_info">
                  <strong></strong>
                  <div class="infoIn ckEditor">
                    <p>
                      The payments for the lease will begin on the specified
                      lease start date but will not end on the specified lease
                      end date. Instead, the tenant will pay rent every month
                      until either the tenant or the landlord provides written
                      notice to the other party to terminate the lease.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <div className="lblBtn">
              <h6>Payment Start Date</h6>
              <div class="info lease_month_info">
                <strong></strong>
                <div class="infoIn ckEditor">
                  <p>
                    This date will determine the first month’s prorated amount
                    the user will be charged for their monthly costs within
                    Loftus. This date does not necessarily have to reflect the
                    lease’s start date. Aside from the last month’s payment,
                    subsequent payments will reflect the full monthly costs
                    populated in this form.
                  </p>
                </div>
              </div>
            </div>
            <div className="form_blk">
              <Controller
                control={control}
                name="start_date"
                defaultValue={
                  lease_row?.start_date !== null &&
                  lease_row?.start_date !== undefined &&
                  lease_row?.start_date !== ""
                    ? new Date(lease_row?.start_date)
                    : ""
                }
                {...register("start_date", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => handleStartDateChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="start_date"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          {
            // watchAllFields?.mnth_mnth !== true ?
            <div className="col-xs-6">
              <h6>Lease End Date</h6>
              <div className="form_blk">
                <Controller
                  control={control}
                  name="end_date"
                  defaultValue={
                    lease_row?.end_date !== null &&
                    lease_row?.end_date !== undefined &&
                    lease_row?.end_date !== ""
                      ? new Date(lease_row?.end_date)
                      : ""
                  }
                  {...register("end_date", { required: "Required" })}
                  render={({ field }) => (
                    <DatePicker
                      className="txtBox"
                      placeholderText="_ _ / _ _ / _ _ _ _"
                      onChange={(date) => handleEndDateChange(date)}
                      selected={field.value}
                      minDate={new Date()}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="end_date"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            // :
            // ""
          }
          <div className="clearfix"></div>
          <div className="col-xs-6">
            <h6>Rent Amount</h6>
            <div className="form_blk">
              <input
                type="text"
                name="rent_amount"
                id="rent_amount"
                className="txtBox"
                {...register("rent_amount", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  lease_row?.rent_amount !== undefined &&
                  lease_row?.rent_amount !== null &&
                  lease_row?.rent_amount !== ""
                    ? parseFloat(lease_row?.rent_amount)
                    : null
                }
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="rent_amount"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Security Deposit</h6>
            <div className="form_blk">
              <input
                type="text"
                name="security_deposit"
                id="security_deposit"
                className="txtBox"
                {...register("security_deposit", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  lease_row?.security_deposit !== undefined &&
                  lease_row?.security_deposit !== null &&
                  lease_row?.security_deposit !== ""
                    ? parseFloat(lease_row?.security_deposit)
                    : null
                }
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="security_deposit"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          {/* <div className="col-xs-6">
                    <h6>Rent Due Date</h6>
                    <div className="form_blk">
                        <Controller
                            control={control}
                            name="rent_due_date"
                            defaultValue={watchAllFields?.rent_due_date !== null && watchAllFields?.rent_due_date !== undefined && watchAllFields?.rent_due_date !== '' ? new Date(watchAllFields?.rent_due_date) : ""}

                            {...register("rent_due_date", { required: "Required" })}
                            render={({ field }) => (
                                <DatePicker
                                    className="txtBox"
                                    placeholderText="_ _ / _ _ / _ _ _ _"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    minDate={new Date()}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="rent_due_date"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div> */}
          {/* <div className="col-xs-6">
                    <h6>Rent Grace Period</h6>
                    <div className="form_blk">
                        <input type="text" name="" id="" className="txtBox"{...register("rent_grace_period", {

                            pattern: {
                                value: /^[0-9]+$/,
                                message: 'Invalid value',
                            },
                            required: "Required",
                            min: {
                                value: 1,
                                message: "Value must be greater than 0."
                            }


                        })} defaultValue={lease_row?.rent_grace_period !== undefined && lease_row?.rent_grace_period !== null && lease_row?.rent_grace_period !== '' ? parseFloat(lease_row?.rent_grace_period) : null} />
                        <span className='dollar_label'>days</span>
                        <ErrorMessage
                            errors={errors}
                            name="rent_grace_period"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div> */}
          <div className="col-xs-6">
            <div className="lblBtn">
              <h6>Non-Sufficient Funds Fee</h6>
              <div class="info lease_month_info">
                <strong></strong>
                <div class="infoIn ckEditor">
                  <p>
                    Non-sufficient funds fee will be applied to each payment
                    that is not received.
                  </p>
                </div>
              </div>
            </div>
            <div className="form_blk">
              <input
                type="text"
                name="non_sufficient_funds"
                id="late_fee"
                className="txtBox"
                {...register("non_sufficient_funds", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  lease_row?.non_sufficient_funds !== undefined &&
                  lease_row?.non_sufficient_funds !== null &&
                  lease_row?.non_sufficient_funds !== ""
                    ? parseFloat(lease_row?.non_sufficient_funds)
                    : null
                }
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="non_sufficient_funds"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          {/* <div className="col-xs-6">
                    <h6>Late Fee Type</h6>
                    <div className="form_blk">
                        <Controller
                            {...register("late_fee_type", { required: "Required" })}
                            name="late_fee_type"
                            id="late_fee_type"
                            control={control}
                            defaultValue={lease_row?.late_fee_type}
                            render={({ field: { onChange, value, ref, name } }) => (
                                <>
                                    <Select
                                        options={late_fee_options}
                                        onChange={val => {
                                            onChange(val.value);
                                            setValue("state_label", val.label);
                                        }}
                                        value={late_fee_options?.filter(function (option) {
                                            return option.value === value;
                                        })}
                                        label="Select Fee Type"
                                    />
                                </>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="late_fee_type"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div> */}
          <div className="col-xs-6">
            <div className="lblBtn">
              <h6>Late Fee</h6>
              <div class="info lease_month_info">
                <strong></strong>
                <div class="infoIn ckEditor">
                  <p>
                    Loftus allows a 5-day grace period for monthly payments.
                    Late fees will be applied if no payments are initiated
                    within the grace period or if an initiated payment within
                    the grace period is not received.
                  </p>
                </div>
              </div>
            </div>
            <div className="form_blk">
              <input
                type="text"
                name="late_fee"
                id="late_fee"
                className="txtBox"
                {...register("late_fee", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  lease_row?.late_fee !== undefined &&
                  lease_row?.late_fee !== null &&
                  lease_row?.late_fee !== ""
                    ? parseFloat(lease_row?.late_fee)
                    : null
                }
              />
              <span className="dollar_label">$</span>
              <ErrorMessage
                errors={errors}
                name="late_fee"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          <div className="clearfix"></div>
          <div className="col-xs-12 heading_fieldset lease_tenants_creat_box">
            <div className="gap_line_divide"></div>
            <h4>Tenant(s):</h4>

            <div className="sender_bio flex">
              {tenants?.tenants?.map((tenant, index) => {
                return (
                  <div className="sender_offer_col" key={index}>
                    <div className="inner offer_blk_border relative">
                      <div
                        className="crosBtn"
                        onClick={(e) => handleRemoveTenant(index, e)}
                      ></div>
                      <div className="mini_icon">
                        <img
                          src={
                            tenant?.thumb !== undefined &&
                            tenant?.thumb !== null &&
                            tenant?.thumb !== ""
                              ? tenant?.thumb
                              : getServerImage("storage/members/", "")
                          }
                          alt={tenant?.name}
                        />
                      </div>
                      <div className="bio_info">
                        <div className="name">
                          <h4>{tenant?.name ? tenant?.name : tenant?.email}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="gap_line_divide"></div>
            <div className="add_more_tenats mrgn_auto" onClick={togglePopup}>
              <i className="fa fa-plus"></i>
            </div>
          </div>
          <div className="col-xs-12 signature_cost">
            <div className="signature_cost_flex">
              <div className="colL">
                <div className="_inner">
                  <h4>Recurring Costs</h4>
                  {costsArr?.recurring_costs?.map((recurring_cost, index) => {
                    return (
                      <div
                        className="new_blk_cost_add"
                        key={recurring_cost?.randomKey}
                      >
                        <div className="inner_blk flex">
                          <div
                            className="closeBtn"
                            onClick={(e) => handleRemoveCost(index, e)}
                          ></div>
                          <div className="inner_colL">
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="txtBox"
                                placeholder="Expense Name"
                                onChange={(e) => handleCostChange(index, e)}
                                defaultValue={recurring_cost.name}
                              />
                            </div>
                          </div>
                          <div className="inner_colR">
                            <div className="relative">
                              <input
                                type="text"
                                name="cost"
                                id=""
                                className="txtBox"
                                placeholder="Cost"
                                onChange={(e) => handleCostChange(index, e)}
                                defaultValue={recurring_cost.cost}
                              />
                              <span className="dollar_label">$</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="bTn lbl_align_center">
                    <button
                      className=" colorBtn webBtn mini_web_btn"
                      type="button"
                      onClick={() => handleAddCosts("recurring_costs")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 signature_cost">
            <div className="gap_line_divide"></div>
            <div className="signature_cost_flex">
              <div className="colL">
                <div className="_inner">
                  <h4>One-Time Costs</h4>
                  {costsArr?.one_time_costs?.map((one_time_cost, index) => {
                    return (
                      <div
                        className="new_blk_cost_add"
                        key={one_time_cost?.randomKey}
                      >
                        <div className="inner_blk flex">
                          <div
                            className="closeBtn"
                            onClick={(e) =>
                              handleRemoveCost(index, e, "one_time_costs")
                            }
                          ></div>
                          <div className="inner_colL">
                            <div className="">
                              <input
                                type="text"
                                name="name"
                                id="name"
                                className="txtBox"
                                placeholder="Expense Name"
                                onChange={(e) =>
                                  handleCostChange(index, e, "one_time_costs")
                                }
                                defaultValue={one_time_cost.name}
                              />
                            </div>
                          </div>
                          <div className="inner_colR">
                            <div className="relative">
                              <input
                                type="text"
                                name="cost"
                                id=""
                                className="txtBox"
                                placeholder="Cost"
                                onChange={(e) =>
                                  handleCostChange(index, e, "one_time_costs")
                                }
                                defaultValue={one_time_cost.cost}
                              />
                              <span className="dollar_label">$</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="bTn lbl_align_center">
                    <button
                      className=" colorBtn webBtn mini_web_btn"
                      type="button"
                      onClick={() => handleAddCosts("one_time_costs")}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {mem_type === "company" ? (
            <div className="col-xs-12 signature_cost">
              <div className="gap_line_divide"></div>
              <div className="signature_cost_flex">
                <div className="colL">
                  <div className="_inner">
                    <h4>Parking Costs</h4>
                    {costsArr?.parking_costs?.map((parking_cost, index) => {
                      return (
                        <div
                          className="new_blk_cost_add"
                          key={parking_cost?.randomKey}
                        >
                          <div className="inner_blk flex">
                            <div
                              className="closeBtn"
                              onClick={(e) =>
                                handleRemoveCost(index, e, "parking_costs")
                              }
                            ></div>
                            <div className="inner_colL">
                              <div className="">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="txtBox"
                                  placeholder="Expense Name"
                                  onChange={(e) =>
                                    handleCostChange(index, e, "parking_costs")
                                  }
                                  defaultValue={parking_cost.name}
                                />
                              </div>
                            </div>
                            <div className="inner_colR">
                              <div className="relative">
                                <input
                                  type="text"
                                  name="cost"
                                  id=""
                                  className="txtBox"
                                  placeholder="Cost"
                                  onChange={(e) =>
                                    handleCostChange(index, e, "parking_costs")
                                  }
                                  defaultValue={parking_cost.cost}
                                />
                                <span className="dollar_label">$</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className="bTn lbl_align_center">
                      <button
                        className=" colorBtn webBtn mini_web_btn"
                        type="button"
                        onClick={() => handleAddCosts("parking_costs")}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="cmnBtn text-center lbl_align_center">
          {update_lease === true ? (
            watchAllFields?.start_date === "" ||
            watchAllFields?.start_date === null ||
            watchAllFields?.start_date === undefined ||
            watchAllFields?.end_date === "" ||
            watchAllFields?.end_date === null ||
            watchAllFields?.end_date === undefined ||
            watchAllFields?.rent_amount === "" ||
            watchAllFields?.rent_amount === null ||
            watchAllFields?.rent_amount === undefined ||
            watchAllFields?.security_deposit === "" ||
            watchAllFields?.security_deposit === null ||
            watchAllFields?.security_deposit === undefined ||
            watchAllFields?.non_sufficient_funds === "" ||
            watchAllFields?.non_sufficient_funds === null ||
            watchAllFields?.non_sufficient_funds === undefined ||
            watchAllFields?.late_fee === "" ||
            watchAllFields?.late_fee === null ||
            watchAllFields?.late_fee === undefined ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  trigger();
                }}
              >
                Confirm
              </button>
            ) : leasePdf?.file !== null && leasePdf?.loading !== true ? (
              tenants?.tenants?.length <= 0 ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => {
                    toast.error("Please add at least one tenant to continue.");
                  }}
                >
                  Confirm
                </button>
              ) : watchAllFields?.end_date &&
                watchAllFields?.start_date &&
                moment(watchAllFields?.end_date) <
                  moment(watchAllFields?.start_date) ? (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={() => {
                    toast.error("End date should be after start date");
                  }}
                >
                  Confirm
                </button>
              ) : (
                <button
                  type="button"
                  className="webBtn colorBtn nextBtn"
                  onClick={handleStepChange}
                >
                  Confirm
                </button>
              )
            ) : (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  toast.error("Please upload PDF to continue!");
                }}
              >
                Confirm
              </button>
            )
          ) : !isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Confirm
            </button>
          ) : leasePdf?.file !== null && leasePdf?.loading !== true ? (
            tenants?.tenants?.length <= 0 ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  toast.error("Please add at least one tenant to continue.");
                }}
              >
                Confirm
              </button>
            ) : (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={handleStepChange}
              >
                Confirm
              </button>
            )
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                toast.error("Please upload PDF to continue!");
              }}
            >
              Confirm
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
