import React from "react";

export default function DocumentSortTab({
  setDocumentType,
  documentType,
  active_count,
  branches,
  expired_count,
  handleBranchChange,
}) {
  const handleChangeTab = (e, type) => {
    e.preventDefault();
    setDocumentType(type);
  };
  return (
    <>
      <div className="flterFlexListing flex">
        <ul className="nav nav-tabs expense-tabs">
          {active_count > 0 ? (
            <li className={documentType === "active" ? "active" : ""}>
              <a
                className="a"
                data-toggle="tab"
                href="#"
                onClick={(e) => handleChangeTab(e, "active")}
              >
                Active <span>({active_count})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {expired_count > 0 ? (
            <li className={documentType === "expired" ? "active" : ""}>
              <a
                className="b"
                data-toggle="tab"
                href="#"
                onClick={(e) => handleChangeTab(e, "expired")}
              >
                Expired <span>({expired_count})</span>
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
        <div className="filter_status_offer cell_status_offer_cell">
          <div className="col_ofer_filter">
            <div className="sorted_heading">
              <span>Branch :</span>
            </div>
            <div className="sorted_drop_down">
              <select
                name="branch_id"
                id="branch_id"
                className="txtBox"
                onChange={(e) => handleBranchChange(e, "branch_id")}
              >
                <option value="all">All</option>
                {branches?.map((branch, index) => {
                  return <option value={branch?.value}>{branch?.label}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
