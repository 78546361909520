import React, { useState } from "react";
import http from "../../../../helpers/http";
import toast, { Toaster } from "react-hot-toast";
import * as helpers from "../../../../helpers/api";
import { useNavigate } from "react-router-dom";
import Text from "../../../common/Text";

export default function CompleteLandlordPopup({
  setCompletedLandlordPopup,
  maintenance_row,
  request_id,
}) {
  const navigate = useNavigate();
  const [isCompleteLoading, setTsCompleteLoading] = useState(false);
  const handleMarkComplete = (e) => {
    e.preventDefault();
    setTsCompleteLoading(true);
    http
      .post(
        "complete-staff-maintenance-request",
        helpers.doObjToFormData({
          request_id: request_id,
        })
      )
      .then(({ data }) => {
        setCompletedLandlordPopup(null);
        setTsCompleteLoading(false);
        if (data.status) {
          toast.success(data?.msg);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(<Text string={data.msg} parse={true} />);
        }
      })
      .catch((error) => {
        setTsCompleteLoading(false);
        toast.error(error);
        return;
      });
  };
  return (
    <>
      <section className="popup small_popup" data-popup="staff">
        <Toaster />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div
                className="crosBtn"
                onClick={() => setCompletedLandlordPopup(null)}
              ></div>
              <h4>Confirmation</h4>
              <p>
                Please confirm whether the task is completed or additional time
                is required.
              </p>
              <div className="text-center bTn">
                <button
                  type="button"
                  className="webBtn"
                  onClick={() => setCompletedLandlordPopup(null)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="webBtn colorBtn"
                  disabled={isCompleteLoading}
                  onClick={handleMarkComplete}
                >
                  Confirm
                  {isCompleteLoading ? <i className="spinner"></i> : ""}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
