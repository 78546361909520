import React from "react";
import Image from "../../common/image";
import { Link } from 'react-router-dom';

export default function PartnerBenefit({ data }) {
    return (
        <>
            <section className="partner_sec">
                <div className="contain">
                    <div className="part_cntnt text-center">
                        <p>{data.cntnt}</p>
                        <br />
                        <div className="sec_heading">
                            <h2>{data.main_heading}</h2>
                        </div>
                    </div>
                    <div className="flex">
                        {data.benefit_loop.map((val, index) => {
                            return (
                                <div className="_col" key={index}>
                                    <div className="inner">
                                        <div className="colL">
                                            <div className="company_logo">
                                                <Image src={val.image} alt={val.heading} />
                                            </div>
                                        </div>
                                        <div className="colR">
                                            <h4>{val.heading}</h4>
                                            <p>{val.text}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="bTn">
                        <Link className="webBtn" to={data.btn_link}>{data.btn_text}</Link>
                    </div>
                </div>
            </section>
        </>
    )
}
