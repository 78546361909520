import React from "react";
import { sortArrayByKey } from "../../../../../helpers/api";

export default function OfferSort({
  my_properties,
  received_properties,
  handlePropertyChange,
  receivedOfferType,
  changeReceivedType,
  pending_count,
  accepted_count,
  rejected_count,
  type,
  incomplete_count = 0,
  total_landlord_count,
  total_tenant_count,
  handleType,
  offerState,
  mem_type = "member",
}) {
  my_properties = sortArrayByKey(my_properties);
  received_properties = sortArrayByKey(received_properties);

  return (
    <>
      <div className="flterFlexListing flex">
        {type === "received" ? (
          <ul className="nav nav-tabs">
            {pending_count > 0 ? (
              <li class={receivedOfferType === "pending" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("pending")}>
                  Pending <span>({pending_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {accepted_count > 0 ? (
              <li class={receivedOfferType === "accepted" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("accepted")}>
                  Accepted <span>({accepted_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {rejected_count > 0 ? (
              <li class={receivedOfferType === "rejected" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("rejected")}>
                  Declined <span>({rejected_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        ) : type === "my_offers" ? (
          <ul className="nav nav-tabs">
            {incomplete_count > 0 ? (
              <li class={receivedOfferType === "incomplete" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("incomplete")}>
                  Incomplete <span>({incomplete_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {pending_count > 0 ? (
              <li class={receivedOfferType === "pending" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("pending")}>
                  Pending <span>({pending_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {accepted_count > 0 ? (
              <li class={receivedOfferType === "accepted" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("accepted")}>
                  Accepted <span>({accepted_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
            {rejected_count > 0 ? (
              <li class={receivedOfferType === "rejected" ? "active" : ""}>
                <a href="#!" onClick={() => changeReceivedType("rejected")}>
                  Declined <span>({rejected_count})</span>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        ) : (
          ""
        )}

        <div className="filter_status_offer">
          {mem_type === "member" ? (
            <>
              <div className="col_ofer_filter">
                <div className="sorted_heading">
                  <i className="fa fa-align-left"></i>
                  <span>Role :</span>
                </div>
                <div className="sorted_drop_down">
                  <select
                    name="role"
                    id=""
                    className="txtBox"
                    onChange={(e) => handlePropertyChange(e, "role")}
                  >
                    <>
                      <option
                        value="received"
                        selected={type === "received" ? true : false}
                      >
                        Received
                      </option>
                      <option
                        value="my_offers"
                        selected={type === "my_offers" ? true : false}
                      >
                        Sent
                      </option>
                    </>
                  </select>
                </div>
              </div>
              {/* ) : (
            ""
          )} */}
              <div className="col_ofer_filter">
                <div className="sorted_heading">
                  <span>Type :</span>
                </div>
                <div className="sorted_drop_down">
                  {
                    // type === "my_offers" ? (
                    <select
                      name="listing_type"
                      id="listing_type"
                      className="txtBox"
                      onChange={(e) => handlePropertyChange(e, "listing_type")}
                    >
                      <option value="all">All</option>
                      <option value="rent">Rent</option>
                      <option value="sale">Buy</option>
                    </select>
                    // ) : (
                    //   <select
                    //     name="re_listing_type"
                    //     id="re_listing_type"
                    //     className="txtBox"
                    //     onChange={(e) => handlePropertyChange(e, "re_listing_type")}
                    //   >
                    //     <option value="all">All</option>
                    //     <option value="rent">Rent</option>
                    //     <option value="sale">Buy</option>
                    //   </select>
                    // )
                  }
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {/* <div className="col_ofer_filter">
                        <div className="sorted_heading">
                            <i className="fa fa-align-left"></i>
                            <span>Status :</span>
                        </div>
                        <div className="sorted_drop_down">
                            <select name="offer_status" id="offer_status" className="txtBox" onChange={(e) => handlePropertyChange(e, 'offer_status')}>
                                <option value="all">All</option>
                                <option value="complete">Complete</option>
                                <option value="pending">Pending</option>
                                <option value="accepted">Accepted</option>
                                <option value="rejected">Declined</option>
                            </select>
                        </div>
                    </div> */}

          <div className="col_ofer_filter">
            <div className="sorted_heading">
              <span>Property :</span>
            </div>
            <div className="sorted_drop_down">
              {type === "my_offers" ? (
                <select
                  name="my_property_id"
                  id="my_property_id"
                  className="txtBox"
                  onChange={(e) => handlePropertyChange(e, "my_property_id")}
                >
                  <option
                    value="all"
                    selected={offerState?.property_id === "all" ? true : false}
                  >
                    All
                  </option>
                  {received_properties !== undefined &&
                  received_properties !== null &&
                  received_properties !== "" &&
                  received_properties?.length > 0 ? (
                    received_properties?.map((property, index) => {
                      return (
                        <option value={property?.id} key={index}>
                          {property?.title}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Properties found!</option>
                  )}
                </select>
              ) : (
                <select
                  name="re_property_id"
                  id="re_property_id"
                  className="txtBox"
                  onChange={(e) => handlePropertyChange(e, "re_property_id")}
                >
                  <option
                    value="all"
                    selected={offerState?.property_id === "all" ? true : false}
                  >
                    All
                  </option>
                  {my_properties !== undefined &&
                  my_properties !== null &&
                  my_properties !== "" &&
                  my_properties?.length > 0 ? (
                    my_properties?.map((property, index) => {
                      return (
                        <option value={property?.id} key={index}>
                          {property?.title}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">No Properties found!</option>
                  )}
                </select>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
