import React from "react";
import PropertyBlk from "../../../common/propertyBlk";
import { postChatMsg } from "../../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../utils/siteSettings";
export default function PropertyInfo({
  property_row,
  user,
  received = 0,
  tenant = false,
  branch_id = false,
}) {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.sendChat.isFormProcessing
  );

  const handleStartChat = (user_id) => {
    if (
      user_id !== undefined &&
      user_id !== null &&
      user_id !== "" &&
      user_id > 0
    ) {
      let frmData = { msg: "", property_mem_id: user_id };
      dispatch(postChatMsg(frmData, true));
    } else {
      toast.error(
        "Invalid User or user does not exit in our system!",
        TOAST_SETTINGS
      );
      return;
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="colL">
        {/* {
                    received === 0 ? */}

        <div className="landlord_card_offer_dash cmn_bt_mrgn">
          <div className="main_heading">
            <h3>Landlord's Information</h3>
          </div>
          <div className="flex">
            <div className="image_icon">
              <img src={user?.image} alt={user?.name} />
            </div>
            <div className="txt">
              <h5>{user?.name}</h5>
              {tenant === true ? (
                <a
                  href="#!"
                  className="start_chat"
                  onClick={() => handleStartChat(user?.mem_id)}
                  disabled={isFormProcessing}
                >
                  Start Chat
                </a>
              ) : (
                // <p>{user?.address}</p>
                ""
              )}
            </div>
          </div>
        </div>
        {/* :
                        ''
                } */}
        <div className="property_card propert_list">
          <PropertyBlk data={property_row} branch_id={branch_id} />
        </div>
      </div>
    </>
  );
}
