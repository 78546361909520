import React from "react";
import Image from '../../common/image';
import BlogCategory from "./blog-category";
import PopularPost from "./popular-post";
import SocialLinks from "../../includes/social-links";

export default function BlogDetailView({ data }) {
    return (
        <>
            <section className="blog_page">
                <div className="contain">
                    <div className="flex blog_flex">
                        <div className="colL">
                            <div className="blog_detail_blk">
                                <div className="image">
                                    <Image src={data.image} alt={data.title} />
                                </div>
                                <div className="imp_info flex">
                                    <div className="ctgry">{data.category}</div>
                                    <span>|</span>
                                    <div className="blog_date">{data.category}</div>
                                </div>
                                <div className="ckEditor">
                                    <h2>{data.title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: data.blog_text }} />
                                </div>

                            </div>
                            <div className="share_opt">
                                <h4>Share on</h4>
                                <SocialLinks data={data.social_links} />
                            </div>
                        </div>
                        <div className="colR">
                            <BlogCategory data={data.categories_list} />
                            <PopularPost data={data.popular_posts} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
