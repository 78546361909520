import React from 'react'
import { ErrorMessage } from '@hookform/error-message';
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import { postData } from '../../../helpers/api';

export default function ChangeEmail({ handleSubmit, register, errors, handleHideEmail, emailVerify, setEmailVerify, setUserEmail, reset }) {
    const hideEmailPopup = () => {
        document.getElementById("emailChange").value = ''
        handleHideEmail();

    }
    const onSubmit = formValues => {
        setEmailVerify(true);
        var form_data = new FormData();
        for (var key in formValues) {
            form_data.append(key, formValues[key]);
        }
        postData("api/change-verified-email", form_data).then((data) => {
            if (data.status === 1) {
                setEmailVerify(false);
                toast.success(data.msg,
                    TOAST_SETTINGS
                );
                // reset()
                setTimeout(() => {
                    setUserEmail(false);
                    document.getElementById("emailChange").value = ''

                }, 2000);
            }
            else {
                toast.error(data.msg,
                    TOAST_SETTINGS
                );
                setEmailVerify(false)
            }
        });
    }
    return (
        <>
            <section className="popup small_popup" data-popup="verify-number" style={{ display: "block" }}>
                <div className="tableDv">
                    <div className="tableCell">
                        <ToastContainer />
                        <div className="_inner">
                            <form action="" method="POST" onSubmit={handleSubmit(onSubmit)} autoComplete="none" id='resetEmailForm'>
                                <div className="txtGrp text-center">
                                    <h4>Update Email Address</h4>
                                </div>
                                <div className="txtGrp">
                                    <h6>Please provide the new email address that you would like to associate with your account. </h6>
                                    <input type="text" name="email" id="emailChange" className='txtBox' placeholder='Email Address' {...register("email", {
                                        required: "Required", pattern: {
                                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                            message: "Email format is not valid!"
                                        }
                                    })} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                    />
                                </div>
                                <div className="text-center bTn">
                                    <button type="button" className="webBtn blankBtn colorBtn" onClick={hideEmailPopup}>Cancel</button>
                                    <button type="submit" className="webBtn colorBtn" disabled={(emailVerify === true) ? 'disabled' : ''}>{(emailVerify === true) ? <i className="spinner"></i> : 'Submit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
