import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Search from "../pages/section/search-nav";
import LoggedMenu from "./loggedMenu";
import { useParams } from "react-router-dom";
import { fetchSearchProperties } from "../../states/actions/fetchSearchProperties";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
const urlParams = new URLSearchParams(window.location.search);

export default function NavigationSearch({
  active,
  logged,
  member,
  profileImage,
  HideMenu,
  unreadNotify,
  exploreClass,
}) {
  const [show, setShow] = useState(false);
  const SubNav = () => {
    setShow(!show);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let url_property_types =
    searchParams.get("property_type") !== null &&
    searchParams.get("property_type")?.length > 0
      ? searchParams.get("property_type").split(",")
      : [];
  let address = searchParams.get("address");
  let type = searchParams.get("type");
  let min_beds =
    searchParams.get("min_beds") !== null ? searchParams.get("min_beds") : 0;
  let max_beds =
    searchParams.get("max_beds") !== null ? searchParams.get("max_beds") : 10;
  let min_baths =
    searchParams.get("min_baths") !== null ? searchParams.get("min_baths") : 0;
  let max_baths =
    searchParams.get("max_baths") !== null ? searchParams.get("max_baths") : 10;
  let min_price =
    searchParams.get("min_price") !== null ? searchParams.get("min_price") : 0;
  let max_price =
    searchParams.get("max_price") !== null
      ? searchParams.get("max_price")
      : type === "rent"
      ? 25000
      : 10000000;
  let amenities =
    searchParams.get("amenities") !== null &&
    searchParams.get("amenities")?.length > 0
      ? searchParams.get("amenities").split(",")
      : [];
  let features =
    searchParams.get("features") !== null &&
    searchParams.get("features")?.length > 0
      ? searchParams.get("features").split(",")
      : [];
  let year = searchParams.get("year");
  let available_date = searchParams.get("available_date");
  let sq_ft = searchParams.get("sq_ft");
  let lot_size = searchParams.get("lot_size");
  let laundry = searchParams.get("laundry");
  let sort_by =
    searchParams.get("sort_by") !== null ? searchParams.get("sort_by") : "asc";
  let sort_by_field =
    searchParams.get("sort_by_field") !== null
      ? searchParams.get("sort_by_field")
      : "listing_price";
  let mgt_type =
    searchParams.get("mgt_type") !== null &&
    searchParams.get("mgt_type")?.length > 0
      ? searchParams.get("mgt_type").split(",")
      : ["landlord", "multifamily"];
  const dispatch = useDispatch();
  const searchProperties = (address_query) => {
    let newData = {
      address: address_query,
      filter_with_boundary: null,
      is_header_search: true,
      type: type,
      property_type:
        url_property_types !== null && url_property_types?.length > 0
          ? url_property_types
          : [],
      min_beds: min_beds,
      max_beds: max_beds,
      min_baths: min_baths,
      max_baths: max_baths,
      min_price: min_price,
      max_price: max_price,
      amenities: amenities !== null && amenities?.length > 0 ? amenities : [],
      features: features !== null && features?.length > 0 ? features : [],
      year: year,
      available_date: available_date,
      sq_ft: sq_ft,
      lot_size: lot_size,
      laundry: laundry,
      sort_by: sort_by,
      sort_by_field: sort_by_field,
      mgt_type:
        mgt_type !== null && mgt_type?.length > 0
          ? mgt_type
          : ["landlord", "multifamily"],
    };
    dispatch(fetchSearchProperties(newData, 1));
  };
  return (
    <>
      <div className="left_nav nav_right">
        <ul id="nav" nav="true" className={active ? "active" : ""}>
          {member?.id > 0 && member?.mem_type === "member" ? (
            <>
              <li className="">
                <NavLink
                  to="/favorites"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Favorites
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/listing"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Listings
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/offers"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Offers
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/service-requests"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Service Requests
                </NavLink>
              </li>
            </>
          ) : (
            <>
              <li className="">
                <a
                  href="/explore"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => HideMenu()}
                >
                  Explore
                </a>
              </li>
              <li className="">
                <NavLink
                  to="/services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => HideMenu()}
                >
                  Services
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/partner"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => HideMenu()}
                >
                  Partners
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to="/mission"
                  className={({ isActive }) => (isActive ? "active" : "")}
                  onClick={() => HideMenu()}
                >
                  Mission
                </NavLink>
              </li>
              <li>
                <ul id="cta">
                  <li>
                    <NavLink
                      to="/"
                      className={({ isActive }) => (isActive ? "active " : "")}
                      onClick={() => HideMenu()}
                    >
                      <em>Post Property</em>
                    </NavLink>
                  </li>
                  <li className="">
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        isActive ? "active webBtn mdBtn" : "webBtn mdBtn"
                      }
                      onClick={() => HideMenu()}
                    >
                      Login | Register
                    </NavLink>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="search_nav cell_search_nav">
        <Search
          address={address}
          searchProperties={searchProperties}
          propertyType={type}
        />
      </div>
      {logged === true ? (
        <LoggedMenu
          unreadNotify={unreadNotify}
          HideMenu={HideMenu}
          profileImage={profileImage}
          member={member}
          exploreClass={exploreClass}
        />
      ) : (
        <nav className="ease">
          <ul id="cta">
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active loged_blk" : "loged_blk"
                }
                onClick={() => HideMenu()}
              >
                <em>Post Property</em>
              </NavLink>
            </li>
            <li className="">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "active webBtn mdBtn" : "webBtn mdBtn"
                }
                onClick={() => HideMenu()}
              >
                Login | Register
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
      <div className="clearfix"></div>
    </>
  );
}
