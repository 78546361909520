import React, { useState } from "react";
import PromotionLst from "./lst";
import Pagination from "../../../common/pagination";
import { consoleLog } from "../../../../helpers/api";

export default function PromotionBlk({ items, handleDelete, type }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);

    //get Current posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
    // const paginate = (pageNumber) => setCurrentPage(pageNumber);
    function paginate(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 3);
    }
    return <>
        {
            currentItems?.length > 0 ?
                currentItems?.map((promotion, index) => {
                    return (
                        <PromotionLst promotion={promotion} key={index} handleDelete={handleDelete} type={type} />
                    )
                })
                :
                <div className="alert alert-danger">No promotions found!</div>
        }
        <Pagination
            paginate={paginate}
            itemsPerPage={itemsPerPage}
            totalItems={items?.length}
            currentPage={currentPage}
        />

    </>;
}
