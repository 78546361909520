import React from 'react'

import Breadcrumb from './sections/breadcrumb'
import { ToastContainer } from "react-toastify";
import ProfileTopBar from './sections/profile-topbar'
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { profileSettings, updatePassword } from "../../../states/actions/profileSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import PasswordForm from './sections/password-form';
export default function ChangePassword() {

    const handlePasswordChange = data => {
        dispatch(updatePassword(data))
    }
    const dispatch = useDispatch();
    const siteSettings = useSelector(
        (state) => state.fetchSiteSettings.siteSettings
    );
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );
    const isFormProcessing = useSelector(
        (state) => state.profileSettings.isFormProcessing
    );
    useDocumentTitle('Change Password - Loftus');

    const member = siteSettings?.member;
    if (member?.mem_verified === 0) {
        return window.location.replace("/dashboard");
    }
    if (isSiteSettingsLoading) return <LoadingScreen />;
    return (
        <>
            <div index="" className="main_dashboard">
                <ToastContainer />
                <section className="dashboard">
                    <div className="contain">
                        <Breadcrumb page_name="Change Password" />
                        <div className="page_body account_settings_flex">
                            <ProfileTopBar isActive="change-password" />
                            <div className="dash_form_blk">
                                <PasswordForm handlePasswordChange={handlePasswordChange} isFormProcessing={isFormProcessing} />
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </>
    )
}
