import React, { useState, useEffect } from "react";
import Breadcrumb from "./sections/breadcrumb";
import PropertySort from "./sections/propertySort";
import PropertyTabs from "./sections/propertyTab";
import TabsMenu from "./sections/tabs-menu";

import { ToastContainer } from "react-toastify";
import {
  fetchUserProperties,
  deleteUserProperty,
  cancelPropertyRequest,
} from "../../../states/actions/fetchUserProperties";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";

export default function UserProperties() {
  const [listView, setListView] = useState(null);
  const ToggleListView = () => {
    setListView("list");
  };
  const ToggleGridView = () => {
    setListView(null);
  };
  const [propertyType, setPropertyType] = useState("active");
  const changeType = (type) => {
    setPropertyType(type);
  };
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchUserProperties.content);
  const isPropertyCancelProcessing = useSelector(
    (state) => state.fetchUserProperties.isPropertyCancelProcessing
  );
  const isLoading = useSelector((state) => state.fetchUserProperties.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteUserProperty(data));
  };

  useEffect(() => {
    dispatch(fetchUserProperties());
    dispatch(fetchSiteSettings());
  }, []);
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );

  useDocumentTitle("Properties - Loftus");
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const activeProperties = data?.properties;
  const pendingProperties = data?.pending_properties;

  const handleCancelPropertyRequest = (request_id) => {
    dispatch(cancelPropertyRequest(request_id));
  };

  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <ToastContainer />
            <Breadcrumb
              page_name="Properties"
              add_link="/add-property"
              anchor={true}
            />
            <div className="page_body">
              <div className="listing_tabs flex">
                <TabsMenu
                  type={propertyType}
                  handleType={changeType}
                  active_count={activeProperties?.length}
                  pending_count={pendingProperties?.length}
                />
                <PropertySort
                  listView={listView}
                  ToggleListView={ToggleListView}
                  ToggleGridView={ToggleGridView}
                />
              </div>
              <PropertyTabs
                listView={listView}
                propertyType={propertyType}
                active_properties={activeProperties}
                pending_properties={pendingProperties}
                handleCancelPropertyRequest={handleCancelPropertyRequest}
                isPropertyCancelProcessing={isPropertyCancelProcessing}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
