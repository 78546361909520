import React from "react";

export default function ExpenseFilters({ branches, handleBranchChange }) {
    return <>
        <div className="filter_status_offer cell_status_offer_cell">
            <div className="col_ofer_filter">
                <div className="sorted_heading">
                    <span>Branch :</span>
                </div>
                <div className="sorted_drop_down">
                    <select name="branch_id" id="branch_id" className="txtBox" onChange={(e) => handleBranchChange(e, 'branch_id')}>
                        <option value="all">All</option>
                        {branches?.map((branch, index) => {
                            return <option value={branch?.value} key={index}>{branch?.label}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
    </>;
}
