import {
    FETCH_PROFILE_SETTINGS,
    FETCH_PROFILE_SETTINGS_SUCCESS,
    FETCH_PROFILE_SETTINGS_FAILED,
    PROFILE_IMAGE_SETTINGS,
    PROFILE_IMAGE_SETTINGS_SUCCESS,
    PROFILE_IMAGE_SETTINGS_FAILED,
    PROFILE_SETTINGS,
    PROFILE_SETTINGS_SUCCESS,
    PROFILE_SETTINGS_FAILED,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILED,
    MEMBER_QUESTION,
    MEMBER_QUESTION_SUCCESS,
    MEMBER_QUESTION_FAILED,
    FETCH_NOTIFICATION,
    FETCH_NOTIFICATION_SUCCESS,
    FETCH_NOTIFICATION_FAILED
} from "../actions/actionTypes";
const authToken = localStorage.getItem("authToken");
const initialState = {
    isLoading: true,
    verify: null,
    error: false,
    isFormProcessing: false,
    isFormImageProcessing: false,
    isNotificationProcessing: false,
    image: "",
    questionAdded: false,
    unread: ""
};

export default function (state = initialState, { type, payload, notification }) {

    switch (type) {
        case FETCH_PROFILE_SETTINGS:
            return {
                ...state,
                isLoading: true,
                verify: null,
            };
        case FETCH_PROFILE_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                verify: payload,
                unread: notification,
                unseen_msgs: payload?.member?.unseen_msgs
            };

        case FETCH_PROFILE_SETTINGS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case PROFILE_IMAGE_SETTINGS:
            return {
                ...state,
                isFormImageProcessing: true,
            };
        case PROFILE_IMAGE_SETTINGS_SUCCESS:
            return {
                ...state,
                isFormImageProcessing: false,
                image: payload
            };
        case PROFILE_IMAGE_SETTINGS_FAILED:
            return {
                ...state,
                isFormImageProcessing: false,
                error: payload
            };
        case FETCH_NOTIFICATION:
            return {
                ...state,
                isNotificationProcessing: true,
            };
        case FETCH_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isNotificationProcessing: false,
                unread: payload
            };
        case FETCH_NOTIFICATION_FAILED:
            return {
                ...state,
                isNotificationProcessing: false,
                error: payload
            };
        case PROFILE_SETTINGS:
            return {
                ...state,
                isFormProcessing: true,
            };
        case PROFILE_SETTINGS_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                image: payload,
            };
        case PROFILE_SETTINGS_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                isFormProcessing: true,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                image: payload
            };
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        case MEMBER_QUESTION:
            return {
                ...state,
                isFormProcessing: true,
            };
        case MEMBER_QUESTION_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                questionAdded: true,
                image: payload
            };
        case MEMBER_QUESTION_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
