import React from "react";

export default function BillingStep3({
  changeStep,
  step,
  prices,
  handleAddNewPrice,
  handleRemovePrice,
  handlePriceChange,
  isFormProcessing,
}) {
  return (
    <fieldset>
      {/* <div className="form_row row flex">
        <div className="col-xs-6">
          <h6>Expense Name</h6>
          <div className="form_blk">
            <input type="text" name="" id="" className="txtBox" />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Cost</h6>
          <div className="form_blk">
            <input type="text" name="" id="" className="txtBox" />
          </div>
        </div>
      </div> */}
      {prices != undefined && prices !== null && prices?.prices?.length > 0
        ? prices.prices?.map((price, index) => {
            return (
              <>
                {index !== 0 ? (
                  <div className="new_blk_cost_add" key={price?.randomKey}>
                    <div className="inner_blk">
                      <div
                        className="closeBtn"
                        onClick={() => handleRemovePrice(index)}
                      ></div>
                      <div className="form_row row flex">
                        <div className="col-xs-6">
                          <h6>Expense Name</h6>
                          <div className="">
                            <input
                              type="text"
                              name="name"
                              id=""
                              className="txtBox"
                              onChange={(e) => handlePriceChange(index, e)}
                              defaultValue={price?.name}
                            />
                          </div>
                        </div>

                        <div className="col-xs-6 cell_mrgn_top">
                          <h6>Cost</h6>
                          <div className="">
                            <input
                              type="text"
                              name="cost"
                              id=""
                              className="txtBox"
                              onChange={(e) => handlePriceChange(index, e)}
                              defaultValue={price?.cost}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="new_blk_cost_add" key={price?.randomKey}>
                    <div className="inner_blk">
                      <div
                        className="closeBtn"
                        onClick={() => handleRemovePrice(index)}
                      ></div>
                      <div className="form_row row flex">
                        <div className="col-xs-6">
                          <h6>Expense Name</h6>
                          <div className="">
                            <input
                              type="text"
                              name="name"
                              id=""
                              className="txtBox"
                              onChange={(e) => handlePriceChange(index, e)}
                              defaultValue={price?.name}
                            />
                          </div>
                        </div>

                        <div className="col-xs-6 cell_mrgn_top">
                          <h6>Cost</h6>
                          <div className="">
                            <input
                              type="text"
                              name="cost"
                              id=""
                              className="txtBox"
                              onChange={(e) => handlePriceChange(index, e)}
                              defaultValue={price?.cost}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
          })
        : ""}

      <div className="bTn lbl_align_center">
        <button
          className=" colorBtn webBtn mini_web_btn"
          type="button"
          onClick={handleAddNewPrice}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
      <div className="cmnBtn text-center">
        <button
          type="button"
          className="webBtn backBtn"
          onClick={() => changeStep(step - 1)}
        >
          Previous
        </button>
        <button
          type="submit"
          className="webBtn colorBtn"
          disabled={isFormProcessing}
        >
          Save {isFormProcessing ? <i className="spinner"></i> : ""}
        </button>
      </div>
    </fieldset>
  );
}
