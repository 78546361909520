import React, { useState } from "react";
import ActiveProperty from "./activeProperty";
import Pagination from "../../../common/pagination";
import { useLocation, useNavigate } from "react-router-dom";

export default function ActivePropertyBlk(props) {
  const { propertyType, handleDelete, items, listView } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const createLease = (property) => {
    const newData = { property: property };
    const encodedObject = encodeURIComponent(JSON.stringify(newData));
    searchParams.set("lease_data", encodedObject);
    navigate(`/create-lease?${searchParams.toString()}`);
  };
  return (
    <>
      <div
        id="active"
        class={
          propertyType === "active" ? "tab-pane fade a active in" : "tab-pane"
        }
      >
        <div
          className={
            listView
              ? "dash_property_listing list_dash_property_listing"
              : "dash_property_listing grid_dash_property_listing"
          }
        >
          {currentItems !== undefined &&
          currentItems !== "" &&
          currentItems !== null &&
          currentItems?.length > 0 ? (
            <>
              {currentItems?.map((item, index) => {
                return (
                  <ActiveProperty
                    item={item}
                    key={index}
                    deleteItem={handleDelete}
                    lease_properties={items}
                    createLease={createLease}
                  />
                );
              })}
            </>
          ) : (
            <div className="alert alert-danger">No property(s) added yet!</div>
          )}
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={items?.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
