import React, { useState } from 'react'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function OpenHouses({ open_houses }) {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
    };
    return (
        <div className='open_house_slider'>
            <Slider {...settings}>
                {
                    open_houses?.map((open_house, index) => {
                        return (
                            <div className='item' key={index}>
                                <div className='flex'>
                                    <div className='open_day'>
                                        <div className='inner'>
                                            <span>{open_house?.day_name}</span>
                                            <em>{open_house?.day_number}</em>
                                            <span>{open_house?.month_name}</span>
                                        </div>
                                    </div>
                                    <div className='open_time'>
                                        <div className='open_time_outer'>
                                            <div className='open_time_inner'>{open_house?.start_time}</div>
                                        </div>
                                        <div className='mid_to'>To</div>
                                        <div className='open_time_outer'>
                                            <div className='open_time_inner'>{open_house?.end_time}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </Slider>

        </div>
    )
}
