import React, { useState } from "react";
import PendingProperty from "./pendingProperty";
import Pagination from "../../../common/pagination";

export default function PendingPropertyBlk(props) {
  const {
    propertyType,
    items,
    handleDelete,
    listView,
    handleCancelPropertyRequest,
    isPropertyCancelProcessing,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }

  return (
    <>
      <div
        id="pending"
        class={
          propertyType === "pending" ? "tab-pane fade a active in" : "tab-pane"
        }
      >
        <div
          className={
            listView
              ? "dash_property_listing list_dash_property_listing"
              : "dash_property_listing grid_dash_property_listing"
          }
        >
          {currentItems !== undefined &&
          currentItems !== "" &&
          currentItems !== null &&
          currentItems?.length > 0 ? (
            <>
              {currentItems?.map((item, index) => {
                return (
                  <PendingProperty
                    item={item}
                    key={index}
                    deleteItem={handleDelete}
                    handleCancelPropertyRequest={handleCancelPropertyRequest}
                    isPropertyCancelProcessing={isPropertyCancelProcessing}
                  />
                );
              })}
            </>
          ) : (
            <div className="alert alert-danger">
              No pending property(s) found yet!
            </div>
          )}
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={items?.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
