import React from "react";
import ListingFilter from "./listingFilter";

export default function CommercialListingSort({
  listingType,
  changeType,
  active_count,
  expired_count,
  branches,
  handleBranchChange,
}) {
  return (
    <>
      <div className=" flterFlexListing flex">
        <ul className="nav nav-tabs">
          <li className={listingType === "active" ? "active" : ""}>
            <a
              className="a"
              data-toggle="tab"
              href="#"
              onClick={() => changeType("active")}
            >
              Active{" "}
              <span>
                (
                {active_count !== undefined && active_count > 0
                  ? active_count
                  : 0}
                )
              </span>
            </a>
          </li>
          <li className={listingType === "expired" ? "active" : ""}>
            <a
              className=""
              data-toggle="tab"
              href="#"
              onClick={() => changeType("expired")}
            >
              Expired{" "}
              <span>
                (
                {expired_count !== undefined && expired_count > 0
                  ? expired_count
                  : 0}
                )
              </span>
            </a>
          </li>
        </ul>
        {listingType === "active" ? (
          <ListingFilter
            branches={branches}
            handleBranchChange={handleBranchChange}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
