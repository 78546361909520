import React, { useEffect, useState } from "react";
import { fileValidation, getServerImage, upload_file } from "../../../../helpers/api";
import { toast } from "react-hot-toast";

export default function UploadLeasePdf({ setLeasePdf, leasePdf, type = null }) {
    const [fileSize, setFileSize] = useState(null);
    const formatFileSize = (bytes) => {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;

        if (bytes < kilobyte) {
            return `${bytes} B`;
        } else if (bytes < megabyte) {
            return `${(bytes / kilobyte).toFixed(2)} KB`;
        } else {
            return `${(bytes / megabyte).toFixed(2)} MB`;
        }
    };
    useEffect(() => {
        if (leasePdf?.file !== null && leasePdf?.loading !== true) {
            const fetchFileSize = async () => {
                try {
                    const fileUrl = getServerImage('storage/attachments/', leasePdf?.file);
                    const response = await fetch(fileUrl);
                    if (response.ok) {
                        const contentLength = response.headers.get('content-length');
                        // contentLength is in bytes, you can convert it to KB or MB if needed
                        setFileSize(contentLength);
                    } else {
                        console.error('Error fetching file size:', response.status);
                    }
                } catch (error) {
                    console.error('Error fetching file size:', error);
                }
            };

            fetchFileSize();
        }

    }, [leasePdf]);
    let pdf_file = null;
    function uploadLogo() {
        pdf_file.click();
    }
    async function handleUpload(e) {
        setLeasePdf({ ...leasePdf, loading: true });
        const files = e.target.files[0];
        let valid = fileValidation(files);
        if (valid.error) {
            toast.error(valid.error);
        }
        else {
            let file = await upload_file(files);
            if (file?.status === 1) {
                document.getElementById('upload_lease_document').value = "";
                setLeasePdf({ ...leasePdf, file: file?.file_name, file_name: files?.name, loading: false });
            }
            else {
                setLeasePdf({ ...leasePdf, loading: false });
                toast.error(file?.msg);
            }
        }
    };
    const handleRemovePdf = (e) => {
        e.preventDefault();
        setLeasePdf({ ...leasePdf, loading: false, file: null, file_name: null })
    }
    return <>
        {
            type === 'document' ?
                <div class="col-xs-12">
                    <div class="gap_line_divide"></div>
                    {
                        leasePdf?.file === null || leasePdf?.file === undefined || leasePdf?.file === '' ?
                            <div class="form_blk">
                                <div class="crud_image text-center">
                                    <div class="image_upload_blk uploadImg default_btn_prop" data-upload="gallery_image" onClick={uploadLogo} disabled={leasePdf?.loading}>

                                        <div class="small_upload_icon" >
                                            <img src="/images/dashboard/icons/upload.png" alt="" />
                                        </div>
                                        {
                                            leasePdf?.loading ?
                                                <h3>Uploading...Please wait...!</h3>
                                                :
                                                <h3>Upload Document</h3>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {
                        leasePdf?.file !== null && leasePdf?.loading !== true ?
                            <div className="flex file_preview relative file_preview_center">
                                <div className="crosBtn" onClick={handleRemovePdf}></div>
                                <div className="sm_icon">
                                    <img src="/images/document.png" alt="" />
                                </div>
                                <div className="_cnt">
                                    <h5>{leasePdf?.file_name}</h5>
                                    {
                                        fileSize !== null && fileSize !== undefined && fileSize !== '' ?

                                            <h6>{formatFileSize(fileSize)}</h6>
                                            :
                                            ""
                                    }
                                </div>
                                <a href={getServerImage('storage/attachments/', leasePdf?.file)} target="_blank"></a>
                            </div>
                            :
                            ""
                    }
                    <input type="file" className="uploadFile" data-upload="pfd_file" id="upload_lease_document" onChange={handleUpload}

                        ref={(file) => (pdf_file = file)} accept="application/pdf" />
                </div>
                :

                <div className="col-xs-12 bottom_line_divide">
                    {
                        leasePdf?.file === null || leasePdf?.file === undefined || leasePdf?.file === '' ?
                            <div className="bTn justify_center_flex">
                                <button className="webBtn uploadImg upload_file_btn" data-upload="pfd_file" type="button" onClick={uploadLogo} disabled={leasePdf?.loading}>
                                    <img src="/images/dashboard/icons/upload.png" alt="" />
                                    {
                                        leasePdf?.loading ?
                                            <span>Uploading...Please wait...!</span>
                                            :
                                            <span>Upload PDF</span>
                                    }

                                </button>
                            </div>
                            :
                            ""
                    }
                    {
                        leasePdf?.file !== null && leasePdf?.loading !== true ?
                            <div className="flex file_preview relative file_preview_center">
                                <div className="crosBtn" onClick={handleRemovePdf}></div>
                                <div className="sm_icon">
                                    <img src="/images/document.png" alt="" />
                                </div>
                                <div className="_cnt">
                                    <h5>{leasePdf?.file_name}</h5>
                                    {
                                        fileSize !== null && fileSize !== undefined && fileSize !== '' ?

                                            <h6>{formatFileSize(fileSize)}</h6>
                                            :
                                            ""
                                    }
                                </div>
                                <a href={getServerImage('storage/attachments/', leasePdf?.file)} target="_blank"></a>
                            </div>
                            :
                            ""
                    }
                    <div className="gap_line_divide"></div>
                    <input type="file" className="uploadFile" data-upload="pfd_file" id="upload_lease_document" onChange={handleUpload}

                        ref={(file) => (pdf_file = file)} accept="application/pdf" />

                </div>
        }
    </>;
}
