import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import Sidebar from "./sections/sidebar";

import {
  fetchEmployees,
  deleteEmployee,
  getFilteredEmployees,
} from "../../../states/actions/fetchSingleEmployees";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import ContactSort from "./contacts/sort";
import EmployeeBlk from "./employees/blk";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function Employees() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleEmployees.content);
  const isLoading = useSelector(
    (state) => state.fetchSingleEmployees.isLoading
  );
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleEmployees.isFilterLoading
  );
  const isItemDeleted = useSelector(
    (state) => state.fetchSingleEmployees.isItemDeleted
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  useEffect(() => {
    dispatch(fetchEmployees());
  }, []);
  const handleDelete = (e, contact_id) => {
    e.preventDefault();
    dispatch(deleteEmployee(contact_id));
  };
  const [filterData, setFilterData] = useState({
    branch_id: null,
  });
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
    };
    dispatch(getFilteredEmployees(formData));
  };

  useDocumentTitle("Employees - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const employees = data?.employees;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="employee" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" ? (
                  <Breadcrumb page_name="Employees" add_link="/add-employee" />
                ) : (
                  <Breadcrumb page_name="Employees" />
                )}

                <div className="page_body">
                  <div class="listing_list_c">
                    {isItemDeleted || isFilterLoading ? (
                      <div className="loadingDisabled">
                        <i className="spinner"></i>
                      </div>
                    ) : (
                      ""
                    )}
                    <ContactSort
                      handleBranchChange={handleBranchChange}
                      branches={branches}
                    />
                    <EmployeeBlk
                      items={employees}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
