import React, { useEffect } from "react";
import ExpensesStep1 from "./step1";
import ExpenseStep2 from "./step2";
import ExpenseStep3 from "./step3";
import ExpenseStep4 from "./step4";
import { useForm } from "react-hook-form";
import { convertSimpleDateUsingMoment } from "../../../../../helpers/api";
export default function AddExpensesForm({ setFormStep, formStep, branches, costsArr,
    handleCostChange,
    handleRemoveCost,
    handleAddCosts, handleCreditForm, isFormProcessing, expense_row, setCosts }) {
    const {
        register,
        trigger,
        setValue,
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
    } = useForm({ mode: "all" });
    const watchAllFields = watch();
    const setDateToMonthYear = (date) => {
        if (!date) return null; // Handle null or undefined date

        const year = date.getFullYear();
        const month = date.getMonth();

        // Set the date to the first day of the month to ensure consistency
        return new Date(year, month, 1);
    };
    useEffect(() => {
        if (expense_row?.id > 0) {
            setValue("branch_id", expense_row?.branch_id);
            setValue(
                "effective",
                expense_row?.effective !== null &&
                    expense_row?.effective !== undefined &&
                    expense_row?.effective !== ""
                    ? setDateToMonthYear(new Date(expense_row?.effective))
                    : new Date()
            );
            let updatedRecurringCosts = [];
            if (expense_row?.recurring_costs?.length > 0) {
                updatedRecurringCosts = expense_row?.recurring_costs.map(cost => ({
                    ...cost,
                    randomKey: Math.random(), // Generating a random key
                }));
            }
            let updatedOneTimeCosts = [];
            if (expense_row?.one_time_costs?.length > 0) {
                updatedOneTimeCosts = expense_row?.one_time_costs.map(cost => ({
                    ...cost,
                    randomKey: Math.random(), // Generating a random key
                }));
            }
            let updatedParkingCosts = [];
            if (expense_row?.parking_costs?.length > 0) {
                updatedParkingCosts = expense_row?.parking_costs.map(cost => ({
                    ...cost,
                    randomKey: Math.random(), // Generating a random key
                }));
            }

            setCosts({
                ...costsArr, recurring_costs: updatedRecurringCosts, one_time_costs: updatedOneTimeCosts, parking_costs: updatedParkingCosts
            });
        }
    }, [expense_row]);

    const onSubmit = (frmData) => {
        let newData = {
            ...frmData,
            effective: convertSimpleDateUsingMoment(frmData?.effective),
            one_time_costs: JSON.stringify(costsArr?.one_time_costs),
            recurring_costs: JSON.stringify(costsArr?.recurring_costs),
            parking_costs: JSON.stringify(costsArr?.parking_costs),
        };
        handleCreditForm(newData)
    }
    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            {
                formStep === 0 ?
                    <ExpensesStep1 setFormStep={setFormStep} formStep={formStep} register={register}
                        trigger={trigger}
                        errors={errors}
                        control={control}
                        watchAllFields={watchAllFields}
                        isValid={isValid}
                        branches={branches} />
                    :
                    formStep === 1 ?
                        <ExpenseStep2 setFormStep={setFormStep} formStep={formStep} handleAddCosts={handleAddCosts}
                            handleRemoveCost={handleRemoveCost}
                            handleCostChange={handleCostChange}
                            costsArr={costsArr} />
                        :
                        formStep === 2 ?
                            <ExpenseStep3 setFormStep={setFormStep} formStep={formStep} handleAddCosts={handleAddCosts}
                                handleRemoveCost={handleRemoveCost}
                                handleCostChange={handleCostChange}
                                costsArr={costsArr} />
                            :
                            formStep === 3 ?
                                <ExpenseStep4 setFormStep={setFormStep} formStep={formStep} handleAddCosts={handleAddCosts}
                                    handleRemoveCost={handleRemoveCost}
                                    handleCostChange={handleCostChange}
                                    costsArr={costsArr} isFormProcessing={isFormProcessing} />
                                :
                                ""
            }
        </form>
    </>;
}
