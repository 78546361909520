import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { uploadMultiFiles } from "../../../../helpers/api";
import GetFileIconByFileExtension from "../../../common/get-file-icon-by-extension";
import { ToastContainer } from "react-toastify";

export default function ChatWriteForm({
  handleSendMessage,
  conversation_id,
  member,
  attachments,
  setAttachments,
  chat_id,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => {
    let frmData = {
      conversationId: parseInt(conversation_id),
      senderId: member?.id,
      message: data?.msg,
      file:
        attachments?.files !== undefined && attachments?.files !== null
          ? attachments?.files
          : [],
    };
    handleSendMessage(frmData);
    if (document.getElementById(chat_id)) {
      document.getElementById(chat_id).textContent = data?.msg;
    }
  };
  let attachment_file = null;
  function triggerAttachmentInput() {
    attachment_file.click();
  }
  const uploadMultipleImages = async (e) => {
    setAttachments({ ...attachments, loading: true });
    let uploadedImages = await uploadMultiFiles(e, "attachments");
    {
      attachments !== undefined &&
      attachments !== null &&
      attachments !== "" &&
      attachments?.files?.length > 0
        ? setAttachments({
            ...attachments,
            files: attachments?.files.concat(uploadedImages),
            loading: false,
          })
        : setAttachments({
            ...attachments,
            files: uploadedImages,
            loading: false,
          });
    }
    document.getElementById("chat_attachments").value = "";
  };
  function handleRemoveImage(idx) {
    setAttachments({
      ...attachments,
      files: attachments?.files.filter((s, sidx) => idx !== sidx),
    });
  }
  return (
    <>
      <div className="write">
        {attachments?.loading ? (
          <div className="loading_attachment">
            <div className="progress progress-striped active">
              <div
                role="progressbar "
                style={{ width: "100%" }}
                className="progress-bar progress-bar-info"
              >
                <span></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {attachments?.files?.length > 0 ? (
          <ul className="attachment_files">
            {attachments?.files?.map((attachment, index) => {
              return (
                <li key={index} data={attachment?.image_name}>
                  <span
                    className="crosBtn"
                    onClick={() => handleRemoveImage(index)}
                  ></span>
                  <GetFileIconByFileExtension
                    file={attachment?.file_name}
                    display_name={attachment?.image_name}
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
        <ToastContainer />
        <form
          className="relative"
          action=""
          method="POST"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ErrorMessage
            errors={errors}
            name="msg"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
          <div className="btm">
            <button
              type="button"
              className="webBtn smBtn labelBtn arrowBtn upBtn"
              title="Upload Files"
              onClick={triggerAttachmentInput}
            >
              <img src="/images/dashboard/clip.png" alt="" />
            </button>
            <textarea
              className="txtBox"
              placeholder="Type a message"
              id="msg"
              {...register("msg", { required: "Required" })}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  onSubmit({ msg: e.target.value });
                }
              }}
            ></textarea>
            <button
              type="submit"
              className="webBtn smBtn icoBtn"
              disabled={attachments?.loading}
            >
              <img src="/images/dashboard/paper-plane.svg" alt="" />{" "}
              {attachments?.loading ? <i className="spinner"></i> : ""}
            </button>
            <input
              type="file"
              name=""
              id="chat_attachments"
              className="uploadFile"
              data-upload="gallery_image"
              onChange={(e) => uploadMultipleImages(e)}
              ref={(file) => (attachment_file = file)}
              multiple
            />
          </div>
        </form>
      </div>
    </>
  );
}
