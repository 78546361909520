import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
export default function ForgotPasswordForm({ data, handleForgot, isFormProcessing, emptyFormData }) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = data => {
        handleForgot(data);
    }
    return (
        <>
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                <h3>{data.heading}</h3>
                <p>{data.text}</p>
                <div className="txtGrp">
                    <label htmlFor="" className="move">Email Address</label>
                    <input type="text" name="email" id="email" className='txtBox' {...register("email", {
                        required: "Required", pattern: {
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                            message: "Email format is not valid!"
                        }
                    })} />
                    <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                </div>
                <div className="bTn text-center">
                    <button type="submit" className="webBtn blockBtn icoBtn" disabled={(isFormProcessing === true) ? 'disabled' : ''}>{(isFormProcessing === true) ? <i className="spinner"></i> : 'Reset Password'}  <i className="spinner hidden" /></button>
                </div>
            </form>
        </>
    )
}
