import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import http from "../../../../helpers/http";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";
import { NavLink, useNavigate } from "react-router-dom";

export default function MaintenanceProfileProperties({
  user_staff_properties,
}) {
  const navigate = useNavigate();
  const [staffPropertiesArr, setStaffPropertiesArr] = useState([]);
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [IsPopup, setIsPopup] = useState(null);

  useEffect(() => {
    setStaffPropertiesArr(user_staff_properties);
  }, [user_staff_properties]);

  const handleDeleteRequest = (e, request_id) => {
    e.preventDefault();
    if (request_id > 0) {
      setIsFormProcessing(true);
      http
        .post(
          "staff-delete-maintenance-request/" + request_id,
          helpers.doObjToFormData({ request_id: request_id })
        )
        .then(({ data }) => {
          setIsFormProcessing(false);
          if (data.status) {
            toast.success(data?.msg);
            setIsPopup(null);
            setStaffPropertiesArr(data?.propertiesArr);
          } else {
            toast.error(<Text string={data.msg} parse={true} />);
          }
        })
        .catch((error) => {
          setIsFormProcessing(false);
          helpers.consoleLog(error);
          // toast.error(error);
          return;
        });
    } else {
      toast.error("Invalid request");
    }
  };
  const handleAcceptRequest = (e, request_id) => {
    e.preventDefault();
    if (request_id > 0) {
      setIsFormProcessing(true);
      http
        .post(
          "staff-accept-maintenance-staff-request/" + request_id,
          helpers.doObjToFormData({ request_id: request_id })
        )
        .then(({ data }) => {
          setIsFormProcessing(false);
          if (data.status) {
            toast.success(data?.msg);
            setStaffPropertiesArr(data?.propertiesArr);
          } else {
            toast.error(<Text string={data.msg} parse={true} />);
          }
        })
        .catch((error) => {
          setIsFormProcessing(false);
          helpers.consoleLog(error);
          // toast.error(error);
          return;
        });
    } else {
      toast.error("Invalid request");
    }
  };
  return (
    <form>
      <fieldset className="propertiesFieldset">
        <div className="setting_blk propertiesTab">
          <div className="maintenance_profile_flex flex">
            {staffPropertiesArr?.length > 0
              ? staffPropertiesArr?.map((user_staff_property, index) => {
                  return (
                    <div className="col" key={index}>
                      <div className="inner">
                        <a href="" className="image">
                          <img
                            src={user_staff_property?.property_thumbnail}
                            alt={user_staff_property?.property_title}
                          />
                          {user_staff_property?.status === "pending" ? (
                            <>
                              <div className="show_lbl_overlay">Pending</div>
                              <div className="hr_rate">
                                {helpers.format_amount(
                                  user_staff_property?.offering_rate
                                )}
                                /hr
                              </div>
                            </>
                          ) : (
                            <div className="hr_rate">
                              {helpers.format_amount(
                                user_staff_property?.offering_rate
                              )}
                              /hr
                            </div>
                          )}
                        </a>
                        <div className="cntnt">
                          <h3>{user_staff_property?.property_title}</h3>
                          <ul className="flex">
                            <li>
                              <span>
                                <Text
                                  string={user_staff_property?.property_address}
                                />
                              </span>
                            </li>
                          </ul>
                          <p>
                            <strong>Landlord</strong>{" "}
                            {user_staff_property?.owner_name}
                          </p>
                          {user_staff_property?.status === "pending" ? (
                            <div className="btn_blk justify_btn_center center_important">
                              <button
                                className="btn-act accept"
                                disabled={isFormProcessing}
                                onClick={(e) =>
                                  handleAcceptRequest(
                                    e,
                                    user_staff_property?.staff_property_id
                                  )
                                }
                              >
                                <img src="images/CheckCircle.png" />{" "}
                                {isFormProcessing ? (
                                  <i className="spinner"></i>
                                ) : (
                                  ""
                                )}
                              </button>
                              <button
                                className="btn-act deny"
                                type="button"
                                onClick={() => setIsPopup(user_staff_property)}
                              >
                                <img src="images/cross.png" />
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </fieldset>
      {IsPopup !== null && IsPopup?.staff_property_id > 0 ? (
        <div index className="popup small_popup">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div className="crosBtn" onClick={() => setIsPopup(null)}></div>
                <h4>Are you sure you want to reject this request?</h4>
                <div className="bTn text-center">
                  <NavLink
                    to="#!"
                    className="webBtn colorBtn"
                    onClick={(e) =>
                      handleDeleteRequest(e, IsPopup?.staff_property_id)
                    }
                    disabled={isFormProcessing}
                  >
                    Yes {isFormProcessing ? <i className="spinner"></i> : ""}
                  </NavLink>

                  <NavLink
                    to="#!"
                    className="webBtn"
                    onClick={() => setIsPopup(null)}
                  >
                    No
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </form>
  );
}
