import React from "react";
import Image from '../../common/image';
import { Link } from "react-router-dom";
export default function Overview({ data }) {
    return (
        <>
            <section className="overview" style={{ backgroundImage: "url(" + data.image + ")" }}>
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <div className="sec_heading">
                                <div dangerouslySetInnerHTML={{ __html: data.text }} />
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: data.text1 }} />
                            <div className="bTn">
                                <Link to={data.btn_text_link} className="webBtn">{data.btn_text}</ Link>
                            </div>
                        </div>
                        <div className="colR">
                            <div className="flex">
                                {data.estimate.map((val, index) => {
                                    return (
                                        <div className="col" key={index}>
                                            <div className="inner">
                                                <div className="small_image">
                                                    <Image src={val.icon} alt={val.label} />
                                                </div>
                                                <h1>{val.count_number}</h1>
                                                <p>{val.label}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
