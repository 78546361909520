import React from "react";
import { replaceSpaceWith20 } from "../../../../helpers/api";

export default function PropertyLocation({ address }) {
  return (
    <>
      <h3 className="detail_heading">Location</h3>
      <div className="outer_map_detail">
        <div id="map_canvas">
          <iframe
            src={
              "https://www.google.com/maps?q=" +
              replaceSpaceWith20(address) +
              "&output=embed"
            }
            width="100%"
            height="100%"
            style={{ border: "0" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}
