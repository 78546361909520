import React, { useState } from "react";
import {
  format_amount,
  format_date,
  format_number,
  getServerImage,
} from "../../../../helpers/api";
import OfferTenants from "./tenants";
import { NavLink } from "react-router-dom";

export default function OfferDetailsRight({
  offer_row,
  received = 0,
  showPopup,
  listing_row = "",
  handleOfferDelete,
  current_offer_owner,
  handleLandlordOfferAction,
  offer_id,
  isFormProcessing,
  is_not_tenant_offer_completed,
  property_row,
  handleOfferTenantDecline,
  is_landlord,
}) {
  const [offerNote, setOfferNote] = useState(false);
  const [popup, setPopup] = useState({
    show: false,
    type: null,
    delete_tenant: false,
  });
  const handlePopupAction = (type, delete_tenant = false) => {
    if (delete_tenant === true) {
      setPopup({ ...popup, show: true, type: "tenant", delete_tenant: true });
    } else {
      setPopup({ ...popup, show: true, type: type, delete_tenant: false });
    }
  };
  return (
    <>
      <div className="colR">
        {listing_row?.mem_type === "member" &&
        listing_row?.listing_type === "rent" &&
        (received === true || received === 1) ? (
          <>
            <h4 className="ofer_heading">Asking</h4>
            <ul className="flex offer_cstm_ul">
              <li>
                <strong>Price</strong>
                <em>{format_amount(listing_row?.rent_price)}</em>
              </li>
              <li>
                <strong>Term</strong>
                <em>{format_number(listing_row?.lease_term, 0)} mth</em>
              </li>
              <li>
                <strong>Available Date</strong>
                <em>{listing_row?.available_date}</em>
              </li>
            </ul>
            <div className="gap_line_divide"></div>
          </>
        ) : (
          ""
        )}
        <div className="sale_colL">
          <h4 className="ofer_heading">Offering</h4>
          <ul className="flex offer_cstm_ul">
            <li>
              <strong>Price</strong>
              <em>{format_amount(offer_row?.price)}</em>
            </li>
            {listing_row?.listing_type === "rent" ? (
              <>
                <li>
                  <strong>Term</strong>
                  <em>{format_number(offer_row?.term, 0)} mth</em>
                </li>
                <li>
                  <strong>Move-in Date</strong>
                  <em>{offer_row?.move_in_date}</em>
                </li>
              </>
            ) : listing_row?.listing_type === "sale" ? (
              <>
                {offer_row?.down_payment ? (
                  <li>
                    <strong>Down Payment</strong>
                    <em>{format_amount(offer_row?.down_payment)}</em>
                  </li>
                ) : (
                  ""
                )}
                {offer_row?.earnest_money ? (
                  <li>
                    <strong>Earnest Money</strong>
                    <em>{format_amount(offer_row?.earnest_money)}</em>
                  </li>
                ) : (
                  ""
                )}
                {offer_row?.total_closing_cost_amount ? (
                  <li>
                    <strong>Closing Costs Paid by Seller</strong>
                    <em>
                      {format_amount(offer_row?.total_closing_cost_amount)}
                    </em>
                  </li>
                ) : (
                  ""
                )}
                {offer_row?.target_closing_date &&
                offer_row?.target_closing_date !== "TBD" ? (
                  <li>
                    <strong>Closing Date</strong>
                    <em>{offer_row?.target_closing_date}</em>
                  </li>
                ) : (
                  ""
                )}
                {offer_row?.offer_response_deadline &&
                offer_row?.offer_response_deadline !== "TBD" ? (
                  <li>
                    <strong>Offer Response Deadline</strong>
                    <em>{offer_row?.offer_response_deadline}</em>
                  </li>
                ) : (
                  ""
                )}

                {/* {
                                            offer_row?.offer_note ?
                                                <li>
                                                    <strong>Offer Note</strong>
                                                    <em>{offer_row?.offer_note}</em>
                                                </li>
                                                :
                                                ""
                                        } */}
              </>
            ) : (
              ""
            )}
          </ul>

          {offer_row?.contingencies ? (
            <>
              <ul className="sale_list_design flex offer_cstm_ul">
                <li>
                  <h6>Buyer Contingencies</h6>
                </li>
                {offer_row?.contingencies.split(",")?.map((contingency) => {
                  return (
                    <li>
                      <em>{contingency ? contingency : "N/A"}</em>
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            ""
          )}
          {offer_row?.seller_concessions ? (
            <ul className="sale_list_design flex offer_cstm_ul">
              <li>
                <h6>Seller Concessions</h6>
              </li>
              {offer_row?.seller_concessions
                .split(",")
                ?.map((seller_concession) => {
                  return (
                    <li>
                      <em>{seller_concession ? seller_concession : "N/A"}</em>
                    </li>
                  );
                })}
            </ul>
          ) : (
            ""
          )}
          {offer_row?.offer_details_status !== "declined" ? (
            offer_row?.offer_status === "pending" &&
            listing_row?.listing_type === "sale" ? (
              received === true || received === 1 ? (
                <div className="bTn sale_offer_btn">
                  {popup?.show === false && isFormProcessing === true ? (
                    <div className="loadingDisabled">
                      <i className="spinner"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <NavLink
                    to="#!"
                    className="webBtn colorBtn"
                    onClick={() => handlePopupAction("accepted")}
                    disabled={isFormProcessing}
                  >
                    Accept{" "}
                  </NavLink>
                  <NavLink
                    to="#!"
                    className="webBtn"
                    onClick={() => handlePopupAction("rejected")}
                    disabled={isFormProcessing}
                  >
                    Decline{" "}
                  </NavLink>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {offer_row?.offer_details_status !== "declined" ? (
            offer_row?.offer_status === "pending" ? (
              received !== true && received !== 1 ? (
                listing_row?.listing_type === "sale" ? (
                  <div className="bTn sale_offer_btn">
                    <NavLink
                      to={"/edit-sale-offer/" + offer_id}
                      className="webBtn colorBtn"
                    >
                      Edit
                    </NavLink>
                    <NavLink
                      to="#!"
                      className="webBtn"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          handleOfferDelete(offer_row?.id);
                      }}
                    >
                      Delete
                    </NavLink>
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="sale_colR">
          <>
            <h4 className="ofer_heading">
              {listing_row?.listing_type === "rent"
                ? "Tenants"
                : listing_row?.listing_type === "sale"
                ? "Buyer Information"
                : ""}
            </h4>
            <div className="sender_bio flex">
              <OfferTenants
                tenants={offer_row?.tenants_rows}
                showPopup={showPopup}
                listing_type={listing_row?.listing_type}
                listingSelectedPackage={listing_row?.screening}
                is_landlord={is_landlord}
                listing_row={listing_row}
                offer_listing_type={listing_row?.listing_type}
              />
            </div>
          </>
          {offer_row?.offer_letter_file !== "" &&
          offer_row?.offer_letter_file !== undefined &&
          offer_row?.offer_letter_file !== null &&
          offer_row?.offer_letter_file !== "null" ? (
            <div className="cover_letter_parent cover_letter_details">
              <h4 className="ofer_heading">Pre-Approval Letter</h4>
              <div className="flex file_preview sale_upload_file sale_offer_details_upload_file">
                <div className="sm_icon">
                  <img src="/images/document.png" alt="" />
                </div>
                <div className="_cnt">
                  <h5>{offer_row?.offer_letter_file_name}</h5>
                </div>
              </div>
              <a
                href={getServerImage(
                  "storage/attachments/",
                  offer_row?.offer_letter_file
                )}
                target="_blank"
              ></a>
            </div>
          ) : (
            ""
          )}
          {offer_row?.offer_note ? (
            <div className="cover_letter_parent offer_note">
              <h4 className="ofer_heading">Offer Note</h4>
              <div
                className={
                  offer_row?.offer_note?.length > 200
                    ? offerNote === true
                      ? "flex file_preview sale_upload_file sale_offer_note active"
                      : "flex file_preview sale_upload_file sale_offer_note"
                    : "flex file_preview sale_upload_file"
                }
                onClick={() => setOfferNote(!offerNote)}
              >
                <p>{offer_row?.offer_note}</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        {offer_row?.offer_details_status !== "declined" ? (
          offer_row?.offer_status === "pending" &&
          listing_row?.listing_type === "rent" ? (
            is_not_tenant_offer_completed !== undefined &&
            is_not_tenant_offer_completed === 1 ? (
              <div className="bTn offer_cstm_ul offer_action_btn relative sale_offer_action_btn">
                {property_row?.mem_type === "company" ? (
                  <NavLink
                    to={
                      "/send-offer-commercial/" +
                      property_row?.branch_row?.slug +
                      "/" +
                      listing_row?.encoded_id +
                      "/" +
                      offer_row?.encoded_id
                    }
                    className="webBtn colorBtn"
                  >
                    Accept{" "}
                  </NavLink>
                ) : (
                  <NavLink
                    to={
                      "/send-offer/" +
                      property_row?.slug +
                      "/" +
                      listing_row?.encoded_id +
                      "/" +
                      offer_row?.encoded_id
                    }
                    className="webBtn colorBtn"
                  >
                    Accept{" "}
                  </NavLink>
                )}

                <NavLink
                  to="#!"
                  className="webBtn"
                  onClick={() => handlePopupAction("rejected", true)}
                  disabled={isFormProcessing}
                >
                  Decline{" "}
                </NavLink>
              </div>
            ) : received === true || received === 1 ? (
              <div className="bTn offer_cstm_ul offer_action_btn relative sale_offer_action_btn">
                {popup?.show === false && isFormProcessing === true ? (
                  <div className="loadingDisabled">
                    <i className="spinner"></i>
                  </div>
                ) : (
                  ""
                )}
                <NavLink
                  to="#!"
                  className="webBtn colorBtn"
                  onClick={() => handlePopupAction("accepted")}
                  disabled={isFormProcessing}
                >
                  Accept{" "}
                </NavLink>
                <NavLink
                  to="#!"
                  className="webBtn"
                  onClick={() => handlePopupAction("rejected")}
                  disabled={isFormProcessing}
                >
                  Decline{" "}
                </NavLink>
              </div>
            ) : current_offer_owner === 1 &&
              listing_row?.listing_type === "rent" ? (
              <div className="bTn offer_cstm_ul offer_action_btn sale_offer_action_btn">
                {listing_row?.listing_type === "sale" ? (
                  <NavLink
                    to={"/edit-sale-offer/" + offer_id}
                    className="webBtn colorBtn"
                  >
                    Edit
                  </NavLink>
                ) : (
                  <NavLink
                    to={"/edit-rent-offer/" + offer_id}
                    className="webBtn colorBtn"
                  >
                    Edit
                  </NavLink>
                )}

                <NavLink
                  to="#!"
                  className="webBtn"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you wish to delete this item?"
                      )
                    )
                      handleOfferDelete(offer_row?.id);
                  }}
                >
                  Delete
                </NavLink>
              </div>
            ) : (
              ""
            )
          ) : offer_row?.offer_status === "accepted" ? (
            <div className="alert alert-success sale_offer_action_btn">
              Offer was accepted on {format_date(offer_row?.offer_status_date)}
            </div>
          ) : offer_row?.offer_status === "rejected" ? (
            <div className="alert alert-danger sale_offer_action_btn">
              Offer was declined on {format_date(offer_row?.offer_status_date)}
            </div>
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {popup?.show === true &&
        popup?.type !== null &&
        popup?.type !== undefined &&
        popup?.type !== "" ? (
          <div index className="popup small_popup">
            <div className="tableDv">
              <div className="tableCell">
                <div className="_inner">
                  <div
                    className="crosBtn"
                    onClick={() => setPopup({ show: false, type: null })}
                  ></div>
                  <h4>
                    Are you sure you want to{" "}
                    {popup?.type === "accepted" ? "accept" : "decline"} this
                    offer?
                  </h4>
                  <div className="bTn text-center">
                    {popup?.delete_tenant === true ? (
                      <NavLink
                        to="#!"
                        className="webBtn"
                        onClick={() => handleOfferTenantDecline(offer_row?.id)}
                        disabled={isFormProcessing}
                      >
                        Yes{" "}
                        {isFormProcessing ? <i className="spinner"></i> : ""}
                      </NavLink>
                    ) : (
                      <NavLink
                        to="#!"
                        className="webBtn"
                        onClick={() => handleLandlordOfferAction(popup?.type)}
                        disabled={isFormProcessing}
                      >
                        Yes{" "}
                        {isFormProcessing ? <i className="spinner"></i> : ""}
                      </NavLink>
                    )}
                    <NavLink
                      to="#!"
                      className="webBtn"
                      onClick={() => setPopup({ show: false, type: null })}
                    >
                      No
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
