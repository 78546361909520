import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ErrorMessage } from '@hookform/error-message';
import { convertToSlashesDate, format_date_simple, next_month_date } from "../../../../helpers/api";
import LeaseAdditionalCosts from "./lease-costs";

export default function TerminationStep2({ costs, isValid, setFormStep, watchAllFields, isTerminateFormLoading }) {
    return (
        <div className="offer_dash_detail_page">
            <div className="flex">


                <fieldset className="colR createLeaseDetails">
                    <ul className="flex offer_cstm_ul">
                        <li>
                            <strong>Lease Termination Date</strong>
                            <em>{convertToSlashesDate(watchAllFields?.termination_date)}</em>
                        </li>
                    </ul>
                    <LeaseAdditionalCosts termination_costs={costs?.costs} termination_date={watchAllFields?.termination_date} />
                    <div className="note_text">
                        <h6>NOTE: You will be allowed to edit or cancel the termination of this lease until the prior month's last calendar day of the selected termination month. </h6>
                    </div>
                    <div className="cmnBtn">

                        <button type="button" className="webBtn" onClick={() => setFormStep(1)}>Back</button>
                        <button type="submit" className="webBtn colorBtn" disabled={isTerminateFormLoading}>Submit {isTerminateFormLoading ? <i className="spinner"></i> : ""}</button>
                    </div>
                </fieldset>
            </div>
        </div>
    );
}