import React, { useState } from "react";
import Pagination from "../../../common/pagination";
import PropertyFilter from "./propertyFilter";
import PropertyLst from "./propertyLst";
import ListingFilter from "./listingFilter";
import { getSiteImages } from "../../../../helpers/api";

export default function PropertiesBlk({
  properties,
  handleDelete,
  branches,
  handleBranchChange,
  propertiesArr,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = properties.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div className="page_body">
        <ListingFilter
          branches={branches}
          handleBranchChange={handleBranchChange}
          is_property={true}
          properties={properties}
        />
        <div className="listing_list_c  listing_list_c_credit_com">
          {propertiesArr?.loading === true ? (
            <div className="relative">
              <div id="loading" className="listingsLoading static">
                {" "}
                <img src={getSiteImages("images/loading.gif")} alt="Loading" />
              </div>
            </div>
          ) : currentItems !== undefined &&
            currentItems !== "" &&
            currentItems !== null &&
            currentItems?.length > 0 ? (
            <>
              <div className="outer_list_c">
                {currentItems?.map((property, index) => {
                  return (
                    <PropertyLst
                      property={property}
                      key={index}
                      handleDelete={handleDelete}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="alert alert-danger">
              no properties are added yet!
            </div>
          )}
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={properties.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
