import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

export default function Navigation({ active, HideMenu }) {
    const [show, setShow] = useState(false);
    const SubNav = () => {
        setShow(!show);
    };
    return (
        <>
            <div className="left_nav nav_right">
                <ul id="nav" nav="true" className={active ? "active" : ""}>
                    <li className="">
                        <a href="/explore" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => HideMenu()}>
                            Explore
                        </a>
                    </li>
                    <li className="">
                        <NavLink to="/services" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => HideMenu()}>
                            Services
                        </NavLink>
                    </li>
                    <li className="">
                        <NavLink to="/partner" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => HideMenu()}>
                            Partners
                        </NavLink>
                    </li>
                    <li className="">
                        <NavLink to="/mission" className={({ isActive }) => (isActive ? "active" : "")} onClick={() => HideMenu()}>
                            Mission
                        </NavLink>
                    </li>
                    <li>
                        <ul id="cta">
                            <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? "active " : "")} onClick={() => HideMenu()}>
                                    <em>Post Property</em>
                                </NavLink>
                            </li>
                            <li className="">
                                <NavLink to="/login" className={({ isActive }) => (isActive ? "active webBtn mdBtn" : "webBtn mdBtn")} onClick={() => HideMenu()}>
                                    Login | Register
                                </NavLink>
                            </li>
                        </ul>
                    </li>



                </ul>
            </div>

            <nav className="ease">

                <ul id="cta">
                    <li>
                        <NavLink to="/" className={({ isActive }) => (isActive ? "active loged_blk" : "loged_blk")} onClick={() => HideMenu()}>
                            <em>Post Property</em>
                        </NavLink>
                    </li>
                    <li className="">
                        <NavLink to="/login" className={({ isActive }) => (isActive ? "active webBtn mdBtn" : "webBtn mdBtn")} onClick={() => HideMenu()}>
                            Login | Register
                        </NavLink>
                    </li>
                </ul>

            </nav>
            <div className="clearfix"></div>


        </>
    )
}
