import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { postChatMsg } from "../../../../states/actions/sendChat";
import toast from "react-hot-toast";

export default function StartChat({
  tenant_id,
  is_icon = false,
  is_static = false,
  is_staff_page = false,
}) {
  const dispatch = useDispatch();
  const isFormProcessing = useSelector(
    (state) => state.sendChat.isFormProcessing
  );
  const handleStartChat = (tenant_id) => {
    if (
      tenant_id !== undefined &&
      tenant_id !== null &&
      tenant_id !== "" &&
      tenant_id > 0
    ) {
      let frmData = { msg: "", property_mem_id: tenant_id };
      dispatch(postChatMsg(frmData, true));
    } else {
      toast.error("Invalid User or user does not exit in our system!");
      return;
    }
  };
  return (
    <>
      {is_icon ? (
        <div
          div
          className={
            is_static ? "chat_mini_icon position-static" : "chat_mini_icon"
          }
        >
          <a
            href="#!"
            className={is_static ? "start_chat m-auto" : "start_chat"}
            onClick={() => handleStartChat(tenant_id)}
            disabled={isFormProcessing}
          >
            <i className="fa fa-comments"></i>
          </a>
        </div>
      ) : is_staff_page ? (
        <a
          href="#!"
          className="colorBtn webBtn mini_web_btn"
          onClick={() => handleStartChat(tenant_id)}
          disabled={isFormProcessing}
        >
          <img src="/images/chat-icon.svg" alt="" />
        </a>
      ) : (
        <a
          href="#!"
          className="start_chat"
          onClick={() => handleStartChat(tenant_id)}
          disabled={isFormProcessing}
        >
          Start Chat
        </a>
      )}
    </>
  );
}
