import React from 'react'
import MediaImageBlk from './ImageBlk';
import MediaImagesInput from './imagesInput';


export default function MediaImages({ uploadMultipleImages, images, path, handleRemoveImage }) {

    return (
        <>

            <div className="crud_image">
                <h4>Upload Images</h4>
                <MediaImageBlk images={images?.images} path={path} handleRemoveImage={handleRemoveImage} />
                <MediaImagesInput uploadMultipleImages={uploadMultipleImages} />
            </div>
        </>
    )
}
