import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ToastContainer } from "react-toastify";
import http from "../../../../helpers/http";
import toast, { Toaster } from "react-hot-toast";
import * as helpers from "../../../../helpers/api";
import Text from "../../../common/Text";

export default function ReviewPopup({
  closeReviewPopup,
  item,
  setReviewsArr,
  reviewsArr,
  setReviewPosted,
  setTotalRatings,
  setAverageReviewsCount,
}) {
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const watchAllFields = watch();
  const onSubmit = async (data) => {
    data = { ...data, m_p_id: item?.profile_id };

    setIsFormProcessing(true);
    try {
      const response = await http.post(
        `save-maintenance-review`,
        helpers.doObjToFormData(data)
      );
      setIsFormProcessing(false);
      console.log(response?.data);
      if (response.data.status) {
        // toast.success(response?.data?.msg);
        closeReviewPopup();
        setReviewsArr(response?.data?.reviews);
        setReviewPosted(true);
        setTotalRatings(response?.data?.total_ratings);
        setAverageReviewsCount(response?.data?.average_ratings_count);
      } else {
        toast.error(<Text string={response.data.msg} parse={true} />);
        return [];
      }
    } catch (error) {
      setIsFormProcessing(false);
      helpers.consoleLog(error);
      return [];
    }
  };
  const changeRating = (newRating, name) => {
    setValue(name, newRating);
  };
  return (
    <>
      <section className="popup review_popup" data-popup="send-message">
        <ToastContainer />
        <Toaster position="bottom-right" />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={closeReviewPopup}></div>
              <h3 className="text-center">Write your review</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Punctuality</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.punctuality !== undefined
                        ? watchAllFields?.punctuality
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("punctuality", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="punctuality"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Communication</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.communication !== undefined
                        ? watchAllFields?.communication
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("communication", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="communication"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Quality</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.quality !== undefined
                        ? watchAllFields?.quality
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("quality", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="quality"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Efficiency</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.efficiency !== undefined
                        ? watchAllFields?.efficiency
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("efficiency", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="efficiency"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Overall Experience</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.overall_experience !== undefined
                        ? watchAllFields?.overall_experience
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("overall_experience", {
                      required: "Required",
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="overall_experience"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp">
                  <textarea
                    name="review"
                    id=""
                    className="txtBox"
                    placeholder="write a review here"
                    {...register("review", {
                      required: "Required",
                    })}
                  ></textarea>
                  <ErrorMessage
                    errors={errors}
                    name="review"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="text-center bTn">
                  <button
                    type="button"
                    className="webBtn"
                    onClick={closeReviewPopup}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="webBtn colorBtn"
                    disabled={isFormProcessing}
                  >
                    Submit {isFormProcessing ? <i className="spinner"></i> : ""}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
