import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';



export default function Newsletter({ handleNewsletter, isFormProcessing, emptyFormData }) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = data => {
        handleNewsletter(data);
    }
    return (
        <>

            <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                <label htmlFor="email">Stay up to date with the latest news and deals!</label>
                <div className="txtGrp relative">
                    <label htmlFor='' className="move">@ your email address</label>
                    <input type="text" name="email" id="email" className='txtBox' {...register("email", {
                        required: "Required", pattern: {
                            value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                            message: "Email format is not valid!"
                        }
                    })} />
                    <button type="submit" disabled={(isFormProcessing === true) ? 'disabled' : ''}> {(isFormProcessing === true) ? <i className="spinner"></i> : <i className="fi-arrow-right fi-2x"></i>}</button>
                    <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                </div>

            </form>
        </>
    )
}
