import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import ReviewsMaintenance from "./reviews-maintenance";
import { NavLink } from "react-router-dom";
import { format_amount, sortArrayByKey } from "../../../../helpers/api";
import MaintenanceForm from "../maintenance_team/form";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import PayForm from "./pay-form";
export default function DetailPopup({
  closeDetailPopup,
  item,
  is_add_staff_page = false,
  staff_properties,
  setMaintenanceStaffArray = false,
  openDetailPopup = false,
  detailPopupItem = null,
  handleDelete,
  isFormProcessing = false,
  bank_accounts = [],
}) {
  const [editPopup, setEditPopup] = useState(null);

  const [isDeletePopup, setIsDeletePopup] = useState(false);
  const [reviewsArr, setReviewsArr] = useState([]);
  const [assignedProperties, setAssignedProperties] = useState([]);
  const [total_ratings, setTotalRatings] = useState(0);
  const [isReviewPosted, setReviewPosted] = useState(false);

  useEffect(() => {
    if (item?.profile_id) {
      setReviewsArr(item?.reviews ? item?.reviews : []);
      setTotalRatings(item?.total_ratings ? item?.total_ratings : 0);
      setReviewPosted(parseInt(item?.is_review_posted) > 0 ? true : false);
    }
  }, [item]);
  const closeEditPopup = useSelector(
    (state) => state.fetchMaintenanceStaff.closeEditPopup
  );
  useEffect(() => {
    if (closeEditPopup?.status === 1) {
      setAssignedProperties(closeEditPopup?.properties);
      if (setMaintenanceStaffArray !== false) {
        setMaintenanceStaffArray(closeEditPopup?.maintenance_staff);
      }
      if (openDetailPopup !== false) {
        openDetailPopup(
          closeEditPopup?.maintenance_staff?.find(
            (item) => item.id === detailPopupItem?.id
          )
        );
      }

      setEditPopup(null);
    }
  }, [closeEditPopup]);
  useEffect(() => {
    setAssignedProperties(item?.properties);
  }, [item?.properties]);
  return (
    <>
      <section className="popup invite_popups_all detail_pop_outer">
        <ToastContainer />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={closeDetailPopup}></div>
              <h4>Account Information</h4>
              <div className="form_info_popup">
                <div className="image_icon">
                  <img src={item?.image} alt={item?.name} />
                </div>
                <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell detail_pop_cntnt">
                  {item?.name ? (
                    <li>
                      <strong>Name</strong>
                      <em>{item?.name}</em>
                    </li>
                  ) : (
                    ""
                  )}
                  {item?.phone ? (
                    <li>
                      <strong>Phone Number</strong>
                      <em>{item?.phone}</em>
                    </li>
                  ) : (
                    ""
                  )}
                  <li>
                    <strong>Email Address</strong>
                    <em>{item?.email}</em>
                  </li>
                  {item?.offering_rate ? (
                    <li>
                      <strong>Offering Rate</strong>
                      <em>{format_amount(item?.offering_rate)}</em>
                    </li>
                  ) : (
                    ""
                  )}
                  {item?.description ? (
                    <li>
                      <strong>Description</strong>
                      <em>{item?.description}</em>
                    </li>
                  ) : (
                    ""
                  )}
                  {item?.trade_categories_text ? (
                    <li>
                      <strong>Services</strong>
                      <em>{item?.trade_categories_text}</em>
                    </li>
                  ) : (
                    ""
                  )}
                  {item?.service_areas_text ? (
                    <li>
                      <strong>Service Areas</strong>
                      <div>
                        {item?.service_state_cities &&
                        Object.keys(item.service_state_cities).length > 0 ? (
                          Object.keys(item.service_state_cities).map(
                            (state, index) => (
                              <>
                                <em key={index}>
                                  {state}:{" "}
                                  {item.service_state_cities[state]?.join(", ")}
                                </em>
                                <br />
                              </>
                            )
                          )
                        ) : (
                          <em>No service areas available.</em>
                        )}
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
                <div class="gap_line_divide"></div>
                {assignedProperties?.length > 0 ? (
                  <>
                    <h5>Assigned Properties</h5>
                    <ul className="property_new_lst_new">
                      {assignedProperties?.map((property, index) => {
                        return (
                          <li key={index}>
                            <div
                              className={
                                property?.status === "pending"
                                  ? "inner pending"
                                  : "inner"
                              }
                            >
                              {property?.title}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  ""
                )}
                {is_add_staff_page === false ? (
                  <div className="bTn text-center top_gap_common">
                    <NavLink
                      to={"#!"}
                      onClick={() => setEditPopup(item)}
                      className="webBtn colorBtn"
                    >
                      <span>
                        <i className="fa fa-pencil"></i>
                      </span>
                      <span>Edit</span>
                    </NavLink>
                    <a
                      href="javascript:void(0)"
                      className="webBtn"
                      onClick={() => setIsDeletePopup(true)}
                    >
                      <span>
                        <i className="fa fa-trash"></i>
                      </span>
                      <span>Delete</span>
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div class="gap_line_divide"></div>
              {parseInt(item?.maintenance_profile) !== 0 &&
              parseInt(item?.new_user) !== 1 &&
              item?.staff_maintenance_requests_completed > 0 ? (
                <>
                  <div class="gap_line_divide"></div>
                  <h4>Reviews</h4>
                  <ReviewsMaintenance
                    item={item}
                    is_add_staff_page={is_add_staff_page}
                    setReviewsArr={setReviewsArr}
                    reviewsArr={reviewsArr}
                    setReviewPosted={setReviewPosted}
                    total_ratings={total_ratings}
                    setTotalRatings={setTotalRatings}
                    isReviewPosted={isReviewPosted}
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
      {editPopup?.id > 0 ? (
        <section className="popup small-popup" data-popup="create-new-staff">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <div
                  className="crosBtn"
                  onClick={() => setEditPopup(null)}
                ></div>
                <h3>Edit Staff</h3>
                <MaintenanceForm
                  // properties={staff_properties}
                  properties={sortArrayByKey(staff_properties)}
                  staff_row={editPopup}
                  person_id={editPopup?.id}
                  setEditPopup={setEditPopup}
                  is_staff_edit_popup={true}
                />
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {isDeletePopup ? (
        <section className="popup small-popup" data-popup="create-new-staff">
          <div className="tableDv">
            <div className="tableCell">
              <div className="_inner">
                <ToastContainer />
                <div
                  className="crosBtn"
                  onClick={() => setIsDeletePopup(false)}
                ></div>
                <h3>Are you sure you want to remove this staff member?</h3>
                <div className="bTn sale_offer_btn">
                  {isFormProcessing === true ? (
                    <div className="loadingDisabled">
                      <i className="spinner"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <NavLink
                    to="#!"
                    className="webBtn colorBtn"
                    onClick={(e) => handleDelete(e, detailPopupItem?.id)}
                    disabled={isFormProcessing}
                  >
                    Yes {isFormProcessing ? <i className="spinner"></i> : ""}
                  </NavLink>
                  <NavLink
                    to="#!"
                    className="webBtn"
                    onClick={() => setIsDeletePopup(false)}
                    disabled={isFormProcessing}
                  >
                    No
                  </NavLink>
                </div>
                {/* hello */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
}
