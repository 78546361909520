import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import PropertiesBlk from "./sections/propertiesBlk";
import Sidebar from "./sections/sidebar";

import { ToastContainer } from "react-toastify";
import {
  fetchProperties,
  deleteProperty,
} from "../../../states/actions/fetchProperties";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { getApiData } from "../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function Properties() {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.fetchProperties.content);
  const isLoading = useSelector((state) => state.fetchProperties.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteProperty(data));
  };

  useEffect(() => {
    dispatch(fetchProperties());
    dispatch(fetchSiteSettings());
  }, []);
  const [propertiesArr, setPropertiesArr] = useState({
    arr: [],
    loading: true,
  });
  async function handleBranchChange(e) {
    let branch_id = e.target.value;
    await getApiData("api/get-branch-properties", branch_id).then((data) => {
      setPropertiesArr({
        ...propertiesArr,
        arr: data?.properties,
        loading: false,
      });
    });
    // setProperty(properties.properties);
  }
  useEffect(() => {
    if (data?.properties !== undefined && data.properties !== null) {
      setPropertiesArr({
        ...propertiesArr,
        arr: data?.properties,
        loading: false,
      });
    }
  }, [data]);
  useDocumentTitle("Properties - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const properties = propertiesArr?.arr;
  const branches = data?.branches;
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="properties" />
              </div>
              <div className="colR">
                {(mem_permissions === "admin" || mem_permissions === "power") ? (
                  <Breadcrumb page_name="Properties" add_link="/add-property" />
                ) : (
                  <Breadcrumb page_name="Properties" />
                )}

                <PropertiesBlk
                  properties={properties}
                  handleDelete={handleDelete}
                  branches={branches}
                  propertiesArr={propertiesArr}
                  handleBranchChange={handleBranchChange}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
