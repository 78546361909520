import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ErrorMessage } from '@hookform/error-message';
import { next_month_date } from "../../../../helpers/api";
import { useEffect } from "react";

export default function TerminationStep1({ control, register, errors, costs, handleAddCosts, handleCostChange, handleRemoveCost, trigger, isValid, setFormStep, leaseTerminateItem, watchAllFields, terminateEndDate }) {
    useEffect(() => {

    }, [costs?.costs])
    return (
        <fieldset>
            <div className="form_row row">
                <div className="col-xs-6">
                    <h6>Lease Termination Date</h6>
                    <div className="form_blk">
                        <Controller
                            control={control}
                            name="termination_date"
                            defaultValue={leaseTerminateItem !== null && leaseTerminateItem !== undefined && leaseTerminateItem !== '' ? new Date(leaseTerminateItem?.termination_date) : ""}

                            {...register("termination_date", { required: "Required" })}
                            render={({ field }) => (
                                <DatePicker
                                    className="txtBox"
                                    placeholderText="_ _ / _ _ / _ _ _ _"
                                    onChange={(date) => field.onChange(date)}
                                    selected={field.value}
                                    minDate={next_month_date()}
                                    maxDate={terminateEndDate ? terminateEndDate : ""}
                                />
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="termination_date"
                            render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                        />
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-xs-12 signature_cost">
                    <div className="signature_cost_flex">
                        <div className="colL">
                            <div className="_inner">
                                <h6>Termination Fee</h6>
                                {
                                    costs?.costs?.map((cost, index) => {
                                        return (
                                            <div className="new_blk_cost_add" key={cost?.randomKey}>
                                                <div className="inner_blk flex">
                                                    <div className="closeBtn" onClick={(e) => handleRemoveCost(index, e)}></div>
                                                    <div className="inner_colL">
                                                        <div className="">
                                                            <input type="text" name="name" id="name" className="txtBox" placeholder="Expense Name" onChange={(e) => handleCostChange(index, e)} defaultValue={cost?.name} />
                                                        </div>
                                                    </div>
                                                    <div className="inner_colR">
                                                        <div className="relative">
                                                            <input type="text" name="cost" id="" className="txtBox" placeholder="Cost" onChange={(e) => handleCostChange(index, e)} defaultValue={cost?.cost} />
                                                            <span className='dollar_label'>$</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className="bTn lbl_align_center">
                                    <button className=" colorBtn webBtn mini_web_btn" type="button" onClick={() => handleAddCosts('recurring_costs')}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="cmnBtn text-center lbl_align_center">
                        {


                            watchAllFields?.termination_date === null || watchAllFields?.termination_date === undefined || watchAllFields === '' ?
                                <button type="button" className="webBtn colorBtn nextBtn" onClick={() => {
                                    trigger();
                                }} >Next</button>
                                :

                                <button type="button" className="webBtn colorBtn nextBtn" onClick={() => setFormStep(2)}>Next</button>
                        }

                    </div>
                </div>

            </div>
        </fieldset>
    );
}