import React, { useEffect, useState } from "react";
import StarRatings from "react-star-ratings";
import { NavLink } from "react-router-dom";
import ReviewSingle from "./review-single";
import ReviewPopup from "./review-popup";

export default function ReviewsMaintenance({
  item,
  is_add_staff_page,
  setReviewsArr,
  reviewsArr,
  setReviewPosted,
  total_ratings,
  setTotalRatings,
  isReviewPosted,
  maintenance_user = false,
}) {
  const [reviewPopup, setReviewPopup] = useState(false);
  const [reply, setReply] = useState(null);
  const [averageReviewsCount, setAverageReviewsCount] = useState({
    total_punctuality: 0,
    total_communication: 0,
    total_quality: 0,
    total_efficiency: 0,
    total_overall_experience: 0,
  });

  const closeReviewPopup = () => {
    setReviewPopup(false);
  };
  const openReviewPopup = () => {
    setReviewPopup(!reviewPopup);
  };
  useEffect(() => {
    setAverageReviewsCount({
      total_punctuality: item?.total_punctuality ? item?.total_punctuality : 0,
      total_communication: item?.total_communication
        ? item?.total_communication
        : 0,
      total_quality: item?.total_quality ? item?.total_quality : 0,
      total_efficiency: item?.total_efficiency ? item?.total_efficiency : 0,
      total_overall_experience: item?.total_overall_experience
        ? item?.total_overall_experience
        : 0,
    });
  }, [item]);

  return (
    <>
      <div className="review_read_blk new_clr">
        <div className="review_heading">
          {/* {reviewsArr?.length <= 0 ? ( */}
          <ul className="total_rating space_between_flex">
            <li className="review_star">
              <i className="fa fa-star"></i>
              <span>
                {" "}
                {total_ratings} ( {reviewsArr?.length} Reviews)
              </span>
            </li>
            {!isReviewPosted &&
            is_add_staff_page === false &&
            item?.staff_maintenance_requests_completed > 0 ? (
              <li className="review_btn">
                <NavLink
                  to="#!"
                  className="webBtn colorBtn"
                  onClick={() => openReviewPopup()}
                >
                  Submit Review
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
          {/* ) : (
            ""
          )} */}
          {reviewsArr?.length > 0 ? (
            <ul className="all_rating">
              <li className="review_star">
                <span>Punctuality</span>
                <StarRatings
                  rating={
                    averageReviewsCount?.total_punctuality
                      ? averageReviewsCount?.total_punctuality
                      : 0
                  }
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="orange"
                />
              </li>
              <li className="review_star">
                <span>Communication</span>
                <StarRatings
                  rating={
                    averageReviewsCount?.total_communication
                      ? averageReviewsCount?.total_communication
                      : 0
                  }
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="orange"
                />
              </li>
              <li className="review_star">
                <span>Quality</span>
                <StarRatings
                  rating={
                    averageReviewsCount?.total_quality
                      ? averageReviewsCount?.total_quality
                      : 0
                  }
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="orange"
                />
              </li>
              <li className="review_star">
                <span>Efficiency</span>
                <StarRatings
                  rating={
                    averageReviewsCount?.total_efficiency
                      ? averageReviewsCount?.total_efficiency
                      : 0
                  }
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="orange"
                />
              </li>
              <li className="review_star">
                <span>Overall Experience</span>
                <StarRatings
                  rating={
                    averageReviewsCount?.total_overall_experience
                      ? averageReviewsCount?.total_overall_experience
                      : 0
                  }
                  starDimension="15px"
                  starSpacing="1px"
                  starRatedColor="orange"
                />
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>

        <div className="review_blk_listing">
          <ul>
            {reviewsArr?.map((review, index) => {
              return (
                <ReviewSingle
                  item={review}
                  key={index}
                  profile_id={item?.profile_id}
                  setReviewsArr={setReviewsArr}
                  maintenance_user={maintenance_user}
                  reply={reply}
                  setReply={setReply}
                />
              );
            })}
          </ul>
        </div>
      </div>

      {reviewPopup ? (
        <ReviewPopup
          closeReviewPopup={closeReviewPopup}
          item={item}
          setReviewsArr={setReviewsArr}
          reviewsArr={reviewsArr}
          setReviewPosted={setReviewPosted}
          setTotalRatings={setTotalRatings}
          setAverageReviewsCount={setAverageReviewsCount}
        />
      ) : (
        ""
      )}
    </>
  );
}
