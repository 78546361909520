import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import DigitalTimePicker from "../../dashboard/rent-listing-steps/digital-timepicker";
export default function ContactsForm({
  branches,
  handleContactForm,
  isFormProcessing,
  contact_row,
}) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const [hoursOfOperation, setHoursOfOperation] = useState({
    officeHours: [
      // { randomKey: Math.random(), start_time: "", end_time: "", day: "" }, // Initial office hours row
    ],
    conciergeHours: [
      // { randomKey: Math.random(), start_time: "", end_time: "", day: "" }, // Initial concierge hours row
    ],
  });
  const handleAddNewHours = (type) => {
    const newRow = {
      randomKey: Math.random(),
      start: "",
      end: "",
      day: "",
    };
    setHoursOfOperation((prevState) => ({
      ...prevState,
      [type]: [...prevState[type], newRow],
    }));
  };
  const handleInputChange = (type, randomKey, event, input_name = null) => {
    let name = "";
    let value = "";
    if (input_name !== null) {
      name = input_name;
      value = event;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    setHoursOfOperation((prevState) => ({
      ...prevState,
      [type]: prevState[type].map((hour) => {
        if (hour.randomKey === randomKey) {
          return {
            ...hour,
            [name]: value,
          };
        }
        return hour;
      }),
    }));
  };
  const handleRemoveHours = (type, randomKey) => {
    setHoursOfOperation((prevState) => ({
      ...prevState,
      [type]: prevState[type].filter((hour) => hour.randomKey !== randomKey),
    }));
  };
  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      operation_hours: JSON.stringify(hoursOfOperation.officeHours),
      concierge_hours: JSON.stringify(hoursOfOperation.conciergeHours),
    };
    handleContactForm(frmData);
  };
  useEffect(() => {
    if (contact_row?.id > 0) {
      setValue("branch_id", contact_row?.branch_id);
      setValue("office_email", contact_row?.office_email);
      setValue("concierge_email", contact_row?.concierge_email);
      if (contact_row?.open_hours?.length > 0) {
        setHoursOfOperation((prevState) => ({
          ...prevState,
          officeHours: contact_row?.open_hours,
        }));
      }
      if (contact_row?.concierge_hours?.length > 0) {
        setHoursOfOperation((prevState) => ({
          ...prevState,
          conciergeHours: contact_row?.concierge_hours,
        }));
      }
    }
  }, [contact_row]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="heighlight_fieldset">
          <div class="form_row row flex">
            <div class="col-xs-6">
              <h6>Branch</h6>
              <div class="form_blk">
                <Controller
                  {...register("branch_id", { required: "Required" })}
                  name="branch_id"
                  control={control}
                  render={({ field: { onChange, value, ref, name } }) => (
                    <>
                      <Select
                        options={branches}
                        onChange={(val) => {
                          onChange(val.value);
                        }}
                        label="Select Branch"
                        value={branches.filter(function (option) {
                          return option.value === value;
                        })}
                      />
                    </>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="branch_id"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div class="col-xs-6">
              <h6></h6>
            </div>
            <div className="clearfix"></div>
            <div class="col-xs-6">
              <h6>Office Email Address</h6>
              <div class="form_blk">
                <input
                  type="text"
                  class="txtBox"
                  {...register("office_email", { required: "Required" })}
                />
                <ErrorMessage
                  errors={errors}
                  name="office_email"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
              <div class="gap_line_divide"></div>
              <div class="info_heading_confirm_com">
                <h5>Office Hours of Operation</h5>
              </div>
              <div class="form_blk contactFormBlk">
                {hoursOfOperation.officeHours.map((officeHour) => (
                  <div
                    key={officeHour.randomKey}
                    className="flex contacts_flex_com relative"
                  >
                    <div
                      className="crosBtn"
                      onClick={(e) =>
                        handleRemoveHours("officeHours", officeHour?.randomKey)
                      }
                    ></div>
                    <div className="week_col">
                      <input
                        type="text"
                        name="day"
                        className="txtBox"
                        placeholder="Day"
                        value={officeHour.day}
                        onChange={(e) =>
                          handleInputChange(
                            "officeHours",
                            officeHour.randomKey,
                            e
                          )
                        }
                      />
                    </div>
                    <div className="week_start_col">
                      <div>
                        <DigitalTimePicker
                          onChange={(e) =>
                            handleInputChange(
                              "officeHours",
                              officeHour.randomKey,
                              e,
                              "start_time"
                            )
                          }
                          value={officeHour?.start_time}
                          placeholder="Start Time"
                        />
                      </div>
                    </div>
                    <div className="week_end_col">
                      <div>
                        <DigitalTimePicker
                          onChange={(e) =>
                            handleInputChange(
                              "officeHours",
                              officeHour.randomKey,
                              e,
                              "end_time"
                            )
                          }
                          value={officeHour?.end_time}
                          placeholder="End Time"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="bTn lbl_align_center">
                  <button
                    className=" colorBtn webBtn mini_web_btn"
                    type="button"
                    onClick={() => handleAddNewHours("officeHours")}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xs-6">
              <h6>Concierge Email Address</h6>
              <div class="form_blk">
                <input
                  type="text"
                  name=""
                  id=""
                  class="txtBox"
                  {...register("concierge_email", { required: "Required" })}
                />
                <ErrorMessage
                  errors={errors}
                  name="concierge_email"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
              <div class="gap_line_divide"></div>
              <div class="info_heading_confirm_com">
                <h5>Concierge Hours of Operation</h5>
              </div>
              <div class="form_blk contactFormBlk">
                {hoursOfOperation.conciergeHours.map((conciergeHour) => (
                  <div
                    key={conciergeHour.randomKey}
                    className="flex contacts_flex_com relative"
                  >
                    <div
                      className="crosBtn"
                      onClick={(e) =>
                        handleRemoveHours(
                          "conciergeHours",
                          conciergeHour?.randomKey
                        )
                      }
                    ></div>
                    <div className="week_col">
                      <input
                        type="text"
                        name="day"
                        className="txtBox"
                        placeholder="Day"
                        value={conciergeHour.day}
                        onChange={(e) =>
                          handleInputChange(
                            "conciergeHours",
                            conciergeHour.randomKey,
                            e
                          )
                        }
                      />
                    </div>
                    <div className="week_start_col">
                      <div>
                        <DigitalTimePicker
                          onChange={(e) =>
                            handleInputChange(
                              "conciergeHours",
                              conciergeHour.randomKey,
                              e,
                              "start_time"
                            )
                          }
                          value={conciergeHour?.start_time}
                          placeholder="Start Time"
                        />
                      </div>
                    </div>
                    <div className="week_end_col">
                      <div>
                        <DigitalTimePicker
                          onChange={(e) =>
                            handleInputChange(
                              "conciergeHours",
                              conciergeHour.randomKey,
                              e,
                              "end_time"
                            )
                          }
                          value={conciergeHour?.end_time}
                          placeholder="End Time"
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="bTn lbl_align_center">
                  <button
                    className=" colorBtn webBtn mini_web_btn"
                    type="button"
                    onClick={() => handleAddNewHours("conciergeHours")}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cmnBtn text-center">
            <button
              type="submit"
              class="webBtn colorBtn"
              disabled={isFormProcessing}
            >
              Submit {isFormProcessing ? <i className="spinner"></i> : ""}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
