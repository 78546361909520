import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import MultiRangeSlider from '../../../common/multirangeslider/Index';
import { checkPattern, convertToDashesDate } from "../../../../helpers/api";
import { useForm } from "react-hook-form";
import CommercialFilterPopup from "./commercial-filter-popup";
import RangeSlider from "../../../common/multirangeslider/range";
export default function CommercialFilter({
  amenities_arr,
  features_arr,
  searchListings,
}) {
  const bathRef = useRef(null);
  const bedRef = useRef(null);
  const priceRef = useRef(null);
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let min_price =
    searchParams.get("min_price") !== null ? searchParams.get("min_price") : 0;
  let max_price =
    searchParams.get("max_price") !== null
      ? searchParams.get("max_price")
      : 1000000000;
  let features =
    searchParams.get("features") !== null &&
    searchParams.get("features")?.length > 0
      ? searchParams.get("features").split(",")
      : [];
  let available_date =
    searchParams.get("available_date") !== null
      ? new Date(searchParams.get("available_date"))
      : null;
  let sq_ft = searchParams.get("sq_ft");
  let laundry =
    searchParams.get("laundry") !== null ? searchParams.get("laundry") : "";
  let min_beds =
    searchParams.get("min_beds") !== null ? searchParams.get("min_beds") : 0;
  let max_beds =
    searchParams.get("max_beds") !== null ? searchParams.get("max_beds") : 10;
  let min_baths =
    searchParams.get("min_baths") !== null ? searchParams.get("min_baths") : 0;
  let max_baths =
    searchParams.get("max_baths") !== null ? searchParams.get("max_baths") : 10;
  useEffect(() => {
    setValue("min_price", min_price);

    setValue("max_price", max_price);
    setValue("features", features);
    setValue("available_date", available_date);
    setValue("sq_ft", sq_ft);
    setValue("laundry", laundry);
    setValue("min_beds", min_beds);
    setValue("max_beds", max_beds);
    setValue("min_baths", min_baths);
    setValue("max_baths", max_baths);
  }, []);
  const handlePriceChange = (prices, key) => {
    if (key === "prices") {
      setValue("min_price", parseFloat(prices[0]));
      setValue("max_price", parseFloat(prices[1]));
    }
    if (key === "beds") {
      setValue("min_beds", parseFloat(prices[0]));
      setValue("max_beds", parseFloat(prices[1]));
    }
    if (key === "baths") {
      setValue("min_baths", parseFloat(prices[0]));
      setValue("max_baths", parseFloat(prices[1]));
    }
  };
  const [filter, setFilter] = useState({
    beds: false,
    baths: false,
    price: false,
  });
  const handleFilter = (f) => {
    if (f == "beds") {
      setFilter({ ...filter, [f]: !filter?.[f], baths: false, price: false });
    }
    if (f == "baths") {
      setFilter({ ...filter, [f]: !filter?.[f], beds: false, price: false });
    }
    if (f == "price") {
      setFilter({ ...filter, [f]: !filter?.[f], beds: false, baths: false });
    }
  };
  const handleClosePopup = (event) => {
    if (
      !bedRef.current ||
      bedRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (
      !bathRef.current ||
      bathRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (
      !priceRef.current ||
      priceRef.current.compareDocumentPosition(event.target) &
        Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      // Click occurred inside the popup or its children, do nothing
      return;
    }
    if (event.target.classList[0] !== "_dropBtn") {
      setFilter({
        ...filter,
        beds: false,
        baths: false,
        property: false,
        price: false,
      });
    }
  };
  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClosePopup);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClosePopup);
    };
  }, []);
  const closeFilter = (type = "", value_to_set = "") => {
    if (type !== "" && value_to_set !== "") {
      // setFilter({ ...filter, [type]: false })
      if (type == "price") {
        setValue("min_price", value_to_set);
        setValue("max_price", 25000);
        searchParams.delete(["min_price"]);
        searchParams.delete(["max_price"]);
      }
      if (type == "baths") {
        setValue("min_baths", value_to_set);
        setValue("max_baths", 10);
        searchParams.delete(["min_baths"]);
        searchParams.delete(["max_baths"]);
      }
      if (type == "beds") {
        setValue("min_beds", value_to_set);
        setValue("max_beds", 10);
        searchParams.delete(["min_beds"]);
        searchParams.delete(["max_beds"]);
      }
      if (type === "popup" && value_to_set === true) {
        setValue("available_date", null);
        setValue("sq_ft", null);
        setValue("laundry", null);
        setValue("features", []);
        let deleteItems = [
          "year",
          "available_date",
          "sq_ft",
          "lot_size",
          "laundry",
          "features",
        ];
        deleteItems.forEach((item) => {
          searchParams.delete(item);
        });
      }
      navigate(`?${searchParams.toString()}`);
    }
    // setPopup(false);
    // searchProperties(watchAllFields)
  };
  const [popup, setPopup] = useState(false);
  const showPopup = () => {
    setPopup(!popup);
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup(false);
  };
  const saveSearch = (type = "", value = "") => {
    let listing_type =
      watchAllFields?.type !== null && watchAllFields?.type !== undefined
        ? watchAllFields?.type
        : null;
    let sort_by_field =
      watchAllFields?.sort_by_field !== null &&
      watchAllFields?.sort_by_field !== undefined
        ? watchAllFields?.sort_by_field
        : null;
    let sort_by =
      watchAllFields?.sort_by !== null && watchAllFields?.sort_by !== undefined
        ? watchAllFields?.sort_by
        : null;
    if (
      type !== "" &&
      type !== undefined &&
      type !== null &&
      type !== false &&
      value !== "" &&
      value !== null &&
      value !== undefined
    ) {
      setValue(type, value);
      if (type == "type") {
        listing_type = value;
      }
      if (type == "sort_by_field") {
        sort_by_field = value;
      }
      if (type == "sort_by") {
        sort_by = value;
      }
    }
    let newWatchAllFields = {
      ...watchAllFields,
      sort_by_field: sort_by_field,
      sort_by: sort_by,
    };
    Object.entries(newWatchAllFields)?.map(([key, value]) =>
      Array.isArray(value)
        ? value?.length > 0
          ? searchParams.set(key, value)
          : searchParams.delete(key)
        : value !== undefined &&
          value !== null &&
          value !== "" &&
          value !== false
        ? searchParams.set(key, value)
        : searchParams.delete(key)
    );
    navigate(`?${searchParams.toString()}`);
    if (type === "popup") {
      setPopup(false);
    } else if (type !== "" && value === "") {
      setFilter({ ...filter, [type]: false });
    }

    if (
      watchAllFields?.available_date !== null &&
      watchAllFields?.available_date !== "null" &&
      watchAllFields?.available_date !== undefined
    ) {
      let available_date = convertToDashesDate(watchAllFields?.available_date);
      let newFilterData = {
        ...watchAllFields,
        available_date: available_date,
        type: listing_type,
        sort_by_field: sort_by_field,
        sort_by: sort_by,
      };
      searchListings(newFilterData);
    } else {
      let newFilterData = {
        ...watchAllFields,
        type: listing_type,
        sort_by_field: sort_by_field,
        sort_by: sort_by,
      };
      searchListings(newFilterData);
    }
  };
  return (
    <>
      <div className="filters flex filter_inner">
        <div className="filter_col relative _dropDown">
          <a
            href="javascript:void(0)"
            className="_dropBtn cmn_filter_btn"
            onClick={() => handleFilter("price")}
          >
            Price
          </a>
          <div
            className={
              filter.price ? "_dropCnt active bedsPopup" : "_dropCnt bedsPopup"
            }
            ref={priceRef}
          >
            <div className="inner-drop">
              {/* <MultiRangeSlider
                                min={0}
                                max={1000000000}
                                onChange={({ min, max }) =>
                                }
                                min_name="min_price"
                                max_name="max_price"
                                is_input_display={true}
                                icon={"$"}
                                handleChange={handlePriceChange}
                                explorePrice={watchAllFields}
                                register={register}
                                branch_details_page={true}
                                detail_page_min_price={watchAllFields?.min_price}
                                detail_page_max_price={watchAllFields?.max_price}
                            /> */}
              {watchAllFields?.min_price !== undefined &&
              watchAllFields?.max_price !== undefined ? (
                <RangeSlider
                  min={watchAllFields?.min_price}
                  max={watchAllFields?.max_price}
                  start={0}
                  end={25000}
                  is_price={true}
                  icon={"$"}
                  register={register}
                  handleChange={handlePriceChange}
                  key_name="prices"
                />
              ) : (
                ""
              )}

              <div className="filter_opt flex">
                <button
                  className="save_opt"
                  onClick={() => saveSearch("price")}
                >
                  Save
                </button>
                <button
                  className="clear_opt"
                  onClick={() => closeFilter("price", 0)}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="filter_col relative _dropDown">
          <a
            href="javascript:void(0)"
            className="_dropBtn cmn_filter_btn"
            onClick={() => handleFilter("beds")}
          >
            Beds
          </a>
          <div
            className={filter.beds === true ? "_dropCnt active" : "_dropCnt"}
            ref={bedRef}
          >
            <div className="inner-drop">
              {watchAllFields?.min_beds !== undefined &&
              watchAllFields?.max_beds !== undefined ? (
                <RangeSlider
                  min={watchAllFields?.min_beds}
                  max={watchAllFields?.max_beds}
                  register={register}
                  handleChange={handlePriceChange}
                  key_name="beds"
                />
              ) : (
                ""
              )}

              <div className="filter_opt flex">
                <button className="save_opt" onClick={() => saveSearch("beds")}>
                  Save
                </button>
                <button
                  className="clear_opt"
                  onClick={() => closeFilter("beds", 0)}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="filter_col relative _dropDown">
          <a
            href="javascript:void(0)"
            className="_dropBtn cmn_filter_btn"
            onClick={() => handleFilter("baths")}
          >
            Baths
          </a>
          <div
            className={filter.baths === true ? "_dropCnt active" : "_dropCnt"}
            ref={bathRef}
          >
            <div className="inner-drop">
              {watchAllFields?.min_baths !== undefined &&
              watchAllFields?.max_baths !== undefined ? (
                <RangeSlider
                  min={watchAllFields?.min_baths}
                  max={watchAllFields?.max_baths}
                  register={register}
                  handleChange={handlePriceChange}
                  key_name="baths"
                />
              ) : (
                ""
              )}
              <div className="filter_opt flex">
                <button
                  className="save_opt"
                  onClick={() => saveSearch("baths")}
                >
                  Save
                </button>
                <button
                  className="clear_opt"
                  onClick={() => closeFilter("baths", 0)}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="filter_col more_filters">
          <a
            href="javascript:void(0)"
            className="cmn_filter_btn flex popBtn"
            data-popup="filter"
            onClick={showPopup}
          >
            <svg
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              style={{
                display: "block",
                height: "16px",
                width: "16px",
                fill: "currentcolor",
              }}
            >
              <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
            </svg>
            <span>Filters</span>
          </a>
        </div>
      </div>
      <div className="filter_col more_filters hide_dektop_dektop new_filter_property">
        <a
          href="javascript:void(0)"
          className="cmn_filter_btn flex popBtn"
          data-popup="filter"
          onClick={showPopup}
        >
          <svg
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            focusable="false"
            style={{
              display: "block",
              height: "16px",
              width: "16px",
              fill: "currentcolor",
            }}
          >
            <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
          </svg>
          <span>Filters</span>
        </a>
      </div>
      {popup === true ? (
        <CommercialFilterPopup
          register={register}
          control={control}
          trigger={trigger}
          isValid={isValid}
          errors={errors}
          amenities={amenities_arr}
          features={features_arr}
          handleSubmit={handleSubmit}
          closePopup={closePopup}
          saveSearch={saveSearch}
          closeFilter={closeFilter}
          watchAllFields={watchAllFields}
          setValue={setValue}
        />
      ) : (
        ""
      )}
    </>
  );
}
