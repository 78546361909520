import {
    FETCH_SINGLE_FLOOR_PLAN_CONTENT,
    FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS,
    FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED,
    SINGLE_FLOOR_PLAN_MESSAGE,
    SINGLE_FLOOR_PLAN_MESSAGE_SUCCESS,
    SINGLE_FLOOR_PLAN_MESSAGE_FAILED,
    UPLOAD_FLOOR_MESSAGE,
    UPLOAD_FLOOR_MESSAGE_SUCCESS,
    UPLOAD_FLOOR_MESSAGE_FAILED

} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_SINGLE_FLOOR_PLAN_CONTENT:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        case SINGLE_FLOOR_PLAN_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case SINGLE_FLOOR_PLAN_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case SINGLE_FLOOR_PLAN_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        case UPLOAD_FLOOR_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case UPLOAD_FLOOR_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true,
                image: payload
            };
        case UPLOAD_FLOOR_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };

        default:
            return state;
    }
}
