import React, { useState, useEffect } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";

import {
  getPropertyAndOfferData,
  createPropertyLease,
  fetchLeaseDetails,
} from "../../../states/actions/leases";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import LeaseStep1 from "./create-lease-sections/step1";
import LeaseStep2 from "./create-lease-sections/step2";
import Error from "../Error";
import toast from "react-hot-toast";
import {
  convertToDashesDate,
  datepickerDateGet,
  format_date_simple,
  moment_new_date,
} from "../../../helpers/api";
import AddNewTenant from "./create-lease-sections/addNewTenant";
import { ToastContainer } from "react-toastify";
import moment from "moment";
export default function UpdateLease({ mem_type }) {
  const { lease_id } = useParams();

  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const watchAllFields = watch();
  const [leasePdf, setLeasePdf] = useState({
    loading: false,
    file: null,
    file_name: null,
  });
  const [tenants, setTenants] = useState({
    tenants: [],
  });
  const [formStep, setFormStep] = useState(1);
  const [costsArr, setCosts] = useState({
    recurring_costs: [],
    one_time_costs: [],
  });
  const handleAddCosts = (cost_type = "recurring_costs") => {
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    }
  };

  function handleRemoveCost(idx, e, cost_type = "recurring_costs") {
    e.preventDefault();
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs
          .slice(0, idx)
          .concat(
            costsArr.recurring_costs.slice(
              idx + 1,
              costsArr.recurring_costs.length
            )
          ),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs
          .slice(0, idx)
          .concat(
            costsArr.parking_costs.slice(idx + 1, costsArr.parking_costs.length)
          ),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs
          .slice(0, idx)
          .concat(
            costsArr.one_time_costs.slice(
              idx + 1,
              costsArr.one_time_costs.length
            )
          ),
      });
    }
  }
  const handleCostChange = (idx, evt, type = "recurring_costs") => {
    if (type === "recurring_costs") {
      const newCosts = costsArr.recurring_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, recurring_costs: newCosts });
    } else if (type === "parking_costs") {
      const newCosts = costsArr.parking_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, parking_costs: newCosts });
    } else {
      const newCosts = costsArr.one_time_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, one_time_costs: newCosts });
    }
  };

  const [popup, setPopup] = useState(false);
  const togglePopup = (e) => {
    e.preventDefault();
    setPopup(!popup);
  };
  function emailExists(emailToCheck) {
    return tenants?.tenants.some((obj) => obj.email === emailToCheck);
  }
  const addNewTenant = (frmData) => {
    const exists = emailExists(frmData?.email);
    if (exists) {
      toast.error(frmData?.email + " already exists!");
      return;
    }
    setTenants({
      ...tenants,
      tenants: tenants.tenants.concat([
        { name: frmData?.name, email: frmData?.email },
      ]),
    });
    document.getElementById("tenant_name").value = "";
    document.getElementById("tenant_email").value = "";
    setPopup(false);
  };
  function handleRemoveTenant(idx, e) {
    e.preventDefault();
    setTenants({
      ...tenants,
      tenants: tenants.tenants
        .slice(0, idx)
        .concat(tenants.tenants.slice(idx + 1, tenants.tenants.length)),
    });
  }
  const onSubmit = (frmData) => {
    if (leasePdf?.file === null) {
      toast.error("Please upload PDF to continue!");
      return;
    }
    if (
      frmData?.end_date &&
      frmData?.start_date &&
      moment(frmData?.end_date) < moment(frmData?.start_date)
    ) {
      toast.error("End date should be greater than start date");
      return;
    }
    // if (costsArr?.one_time_costs.length <= 0) {
    //     toast.error("Please add at-least one item for One-Time Costs to continue!"); return
    // }
    // if (costsArr?.recurring_costs?.length <= 0) {
    //     toast.error("Please add at-least one item for Recurring Costs to continue!"); return
    // }
    if (tenants?.tenants?.length <= 0) {
      toast.error("Please add at least one tenant to continue.");
      return;
    }
    const newData = {
      ...frmData,
      property: lease_row?.property,
      listing_id:
        lease_row?.listing_id !== undefined &&
        lease_row?.listing_id !== null &&
        lease_row?.listing_id !== ""
          ? lease_row?.listing_id
          : null,
      attachment: leasePdf?.file,
      tenants: JSON.stringify(tenants?.tenants),
      one_time_costs: JSON.stringify(costsArr?.one_time_costs),
      recurring_costs: JSON.stringify(costsArr?.recurring_costs),
      parking_costs: JSON.stringify(costsArr?.parking_costs),
      start_date: convertToDashesDate(frmData?.start_date, "YYYY/MM/DD"),
      end_date: convertToDashesDate(frmData?.end_date, "YYYY/MM/DD"),
      rent_due_date: convertToDashesDate(frmData?.rent_due_date, "YYYY/MM/DD"),
    };
    // ; return;
    dispatch(createPropertyLease(newData, lease_id));
  };

  const dispatch = useDispatch();
  const data = useSelector((state) => state.leases.lease_details_content);
  const { lease_row, member, property_obj } = data;
  const isLoading = useSelector((state) => state.leases.isDetailsLoading);
  const isLeaseFormLoading = useSelector(
    (state) => state.leases.isLeaseFormLoading
  );
  useEffect(() => {
    dispatch(fetchLeaseDetails(lease_id));
  }, []);
  useEffect(() => {
    if (lease_row?.tenants?.length > 0) {
      setTenants({ ...tenants, tenants: lease_row?.tenants });
    }
  }, [lease_row]);
  useEffect(() => {
    setValue(
      "start_date",
      lease_row?.start_date !== null &&
        lease_row?.start_date !== undefined &&
        lease_row?.start_date !== ""
        ? datepickerDateGet(lease_row?.start_date)
        : ""
    );
    setValue(
      "end_date",
      lease_row?.end_date !== null &&
        lease_row?.end_date !== undefined &&
        lease_row?.end_date !== ""
        ? datepickerDateGet(lease_row?.end_date)
        : ""
    );
    // setValue("rent_due_date", lease_row?.rent_due_date !== null && lease_row?.rent_due_date !== undefined && lease_row?.rent_due_date !== '' ? new Date(lease_row?.rent_due_date) : "")
    // setValue("rent_amount", lease_row?.rent_amount !== undefined && lease_row?.rent_amount !== null ? parseFloat(lease_row?.rent_amount) : null)
    // setValue("security_deposit", parseFloat(lease_row?.security_deposit))
    setValue(
      "late_fee_type",
      lease_row?.late_fee_type !== null &&
        lease_row?.late_fee_type !== undefined
        ? lease_row?.late_fee_type
        : ""
    );
    // setValue("late_fee", parseFloat(lease_row?.late_fee))
    // setValue("non_sufficient_funds", parseFloat(lease_row?.non_sufficient_funds))
    // setValue("rent_grace_period", parseFloat(lease_row?.rent_grace_period))
  }, [lease_row]);
  // useEffect(() => {
  //     // if (lease_row?.one_time_costs?.length > 0) {
  //     setCosts({ ...costsArr, one_time_costs: lease_row?.one_time_costs });
  //     // }
  // }, [lease_row]);
  // useEffect(() => {
  //   // if (lease_row?.recurring_costs?.length > 0) {
  //   setCosts({
  //     ...costsArr,
  //     recurring_costs: lease_row?.recurring_costs,
  //     one_time_costs: lease_row?.one_time_costs
  //   });
  //   // }
  // }, [lease_row]);
  useEffect(() => {
    if (lease_row !== null && lease_row !== undefined && lease_row !== "") {
      let updatedRecurringCosts = [];
      if (lease_row?.recurring_costs?.length > 0) {
        updatedRecurringCosts = lease_row?.recurring_costs.map((cost) => ({
          ...cost,
          randomKey: Math.random(), // Generating a random key
        }));
      }
      let updatedOneTimeCosts = [];
      if (lease_row?.one_time_costs?.length > 0) {
        updatedOneTimeCosts = lease_row?.one_time_costs.map((cost) => ({
          ...cost,
          randomKey: Math.random(), // Generating a random key
        }));
      }
      let updatedParkingCosts = [];
      if (lease_row?.parking_costs?.length > 0) {
        updatedParkingCosts = lease_row?.parking_costs.map((cost) => ({
          ...cost,
          randomKey: Math.random(), // Generating a random key
        }));
      }

      setCosts({
        ...costsArr,
        recurring_costs: updatedRecurringCosts,
        one_time_costs: updatedOneTimeCosts,
        parking_costs: updatedParkingCosts,
      });
    }
  }, [lease_row]);
  useEffect(() => {
    if (lease_row?.attachment) {
      setLeasePdf({
        ...leasePdf,
        file: lease_row?.attachment,
        file_name: "Download Attachment",
        loading: false,
      });
    }
  }, [lease_row]);

  useDocumentTitle("Lease# " + lease_id + " - Loftus");
  if (isLoading) return <LoadingScreen />;
  if (
    lease_row === undefined ||
    lease_row === null ||
    lease_row?.id <= 0 ||
    lease_row === ""
  ) {
    return <Error />;
  }
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <Breadcrumb
              page_name={"Update Lease# " + lease_id}
              parent_name="Leases"
              parent_link="/leases"
            />
            <div className="page_body">
              <div className="dash_form_blk">
                <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                  {formStep === 1 ? (
                    <LeaseStep1
                      register={register}
                      errors={errors}
                      property={lease_row?.property_row}
                      offer={null}
                      setValue={setValue}
                      setLeasePdf={setLeasePdf}
                      leasePdf={leasePdf}
                      control={control}
                      setFormStep={setFormStep}
                      trigger={trigger}
                      isValid={isValid}
                      handleAddCosts={handleAddCosts}
                      handleRemoveCost={handleRemoveCost}
                      handleCostChange={handleCostChange}
                      costsArr={costsArr}
                      tenants={tenants}
                      togglePopup={togglePopup}
                      handleRemoveTenant={handleRemoveTenant}
                      watchAllFields={watchAllFields}
                      lease_row={lease_row}
                      update_lease={true}
                      property_obj={property_obj}
                      mem_type={mem_type}
                    />
                  ) : formStep === 2 ? (
                    <LeaseStep2
                      leasePdf={leasePdf}
                      setFormStep={setFormStep}
                      watchAllFields={watchAllFields}
                      tenants={tenants}
                      costsArr={costsArr}
                      isLeaseFormLoading={isLeaseFormLoading}
                      property={property_obj}
                      mem_type={mem_type}
                    />
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </section>
        {popup === true ? (
          <AddNewTenant
            setPopup={setPopup}
            popup={popup}
            addNewTenant={addNewTenant}
          />
        ) : (
          ""
        )}
      </main>
    </>
  );
}
