import { React } from "react";
import { getSiteImages } from '../../helpers/api';

export default function LoadingScreen({ isLoading }) {
    return (
        <>
            <div id="loading"> <img src={getSiteImages('images/loading.gif')} alt="Loading" /></div>
        </>
    );
}
