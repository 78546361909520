import React, { useState } from "react";
import ExpenseCostBlk from "./expense-cost-blk";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function ExpenseLst({
  item,
  deleteExpense,
  expense_type = false,
}) {
  const [toggleItem, setToggleItem] = useState(false);
  return (
    <>
      <div className="invoice_toggle">
        <ul onClick={() => setToggleItem(!toggleItem)}>
          <li>
            <h5 className="lease_heading_mini">Branch</h5>
            <div>{item?.branch_name}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Effective</h5>
            <div>{item?.effective}</div>
          </li>
          {expense_type === "expired" ? (
            <li>
              <h5 className="lease_heading_mini">Expired</h5>
              <div>{item?.expired ? item?.expired : "N/A"}</div>
            </li>
          ) : (
            ""
          )}
        </ul>
        {toggleItem ? (
          <div className={toggleItem ? "invoice_bdy active" : "invoice_bdy"}>
            <div className="expenses_flex">
              <ExpenseCostBlk
                costs={item?.one_time_costs}
                title="One-Time Expenses"
              />
              <ExpenseCostBlk
                costs={item?.recurring_costs}
                title="Recurring Expenses"
              />
              <ExpenseCostBlk
                costs={item?.parking_costs}
                title="Parking Expenses"
              />
            </div>
            {mem_permissions === "admin" ? (
              <>
                <div className="gap_line_divide"></div>
                <div className="bTn text-right">
                  {expense_type === "pending" ? (
                    <NavLink
                      to={"/edit-expense/" + item?.id}
                      className="webBtn colorBtn"
                    >
                      Edit
                    </NavLink>
                  ) : (
                    ""
                  )}
                  {expense_type === "pending" || expense_type === "active" ? (
                    <a
                      href="#!"
                      className="webBtn"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          deleteExpense(e, item?.id);
                      }}
                    >
                      Delete
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
