import React from 'react'
import { NavLink } from 'react-router-dom';

export default function ProfileTopBar({ isActive }) {
    return (
        <>
            <div className="option_lbl  profileTabs">
                <ul>
                    <li className="active">
                        <NavLink to="/profile-settings" className={isActive === 'profile-settings' ? 'webBtn colorBtn' : 'webBtn'}>Account Details</NavLink>
                    </li>
                    <li>
                        <NavLink to="/change-password" className={isActive === 'change-password' ? 'webBtn colorBtn' : 'webBtn'}>Change Password</NavLink>
                    </li>
                    <li>
                        <NavLink to="/security-questions" className={isActive === 'security-questions' ? 'webBtn colorBtn' : 'webBtn'}>Security Questions</NavLink>
                    </li>
                </ul>
            </div>
        </>
    )
}
