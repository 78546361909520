import React, { useEffect, useState } from "react";
export default function ChatSidebar({
  users,
  member,
  chat_id,
  handleGetUserMessages,
  setUsers,
  allUsers,
}) {
  const handleSearch = (e) => {
    const results = users.filter((user) =>
      user?.user_name?.toLowerCase()?.includes(e.target.value?.toLowerCase())
    );
    if (results?.length > 0) {
      setUsers(results);
    } else {
      setUsers(allUsers);
    }
  };
  // useEffect(() => {
  //     const newUsers = users?.sort((a, b) => {
  //         const dateA = new Date(a.created_time);
  //         const dateB = new Date(b.created_time);
  //         return dateB - dateA;
  //     });
  //     setUsers(newUsers)
  // }, [users]);

  return (
    <>
      {
        // usersArr?.length > 0 ?

        <div className="barBlk relative">
          <div className="srch relative">
            <input
              type="text"
              className="txtBox"
              placeholder="Search contact"
              onKeyDown={handleSearch}
            />
            <button type="button">
              <img src="/images/dashboard/search.svg" alt="" />
            </button>
          </div>
          <ul className="frnds scrollbar">
            {users?.map((user, index) => {
              return (
                <li
                  data-chat="person1"
                  className={
                    chat_id === user?.chat_id
                      ? "active"
                      : user?.unread === true
                      ? "unread"
                      : ""
                  }
                  key={index}
                  onClick={() => handleGetUserMessages(user?.chat_id)}
                >
                  <div className="inner sms">
                    <div className="ico">
                      <img src={user?.user_dp} alt="" />
                    </div>
                    <div className="txt">
                      <h5>{user?.user_name}</h5>
                      <p id={user?.chat_id}>{user?.msg}</p>
                    </div>
                    {/* <div className="time_msg_ago">
                                            {user?.time}
                                        </div> */}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        // :
        // ""
      }
    </>
  );
}
