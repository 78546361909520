import {
    FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT,
    FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS,
    FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED,
    SEARCH_COMMERCIAL_LISTINGS,
    SEARCH_COMMERCIAL_LISTINGS_SUCCESS,
    SEARCH_COMMERCIAL_LISTINGS_FAILED
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    isSearchLoading: true,
    content: {},
    error: false,
    listings: []
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT:
            return {
                ...state,
                isLoading: true,
                isSearchLoading: true,
                content: {},
            };
        case FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSearchLoading: false,
                content: payload,
                listings: payload?.listings
            };
        case FETCH_COMMERCIAL_FRONTEND_PROPERTY_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false,
                isSearchLoading: false,
                error: payload,
            };
        case SEARCH_COMMERCIAL_LISTINGS:
            return {
                ...state,
                isSearchLoading: true,
            };
        case SEARCH_COMMERCIAL_LISTINGS_SUCCESS:
            return {
                ...state,
                isSearchLoading: false,
                listings: payload?.listings
            };
        case SEARCH_COMMERCIAL_LISTINGS_FAILED:
            return {
                ...state,
                isSearchLoading: false,
                error: payload,
            };


        default:
            return state;
    }
}
