import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchSingleExpense,
  postExpense,
} from "../../../states/actions/fetchSingleExpenses";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddExpensesForm from "./expenses/form/form";

export default function AddExpenses() {
  const [formStep, setFormStep] = useState(0);
  const [costsArr, setCosts] = useState({
    recurring_costs: [],
    one_time_costs: [],
    parking_costs: [],
  });
  const handleAddCosts = (cost_type = "recurring_costs") => {
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs.concat([
          { randomKey: Math.random(), name: "", cost: "" },
        ]),
      });
    }
  };

  function handleRemoveCost(idx, e, cost_type = "recurring_costs") {
    e.preventDefault();
    if (cost_type === "recurring_costs") {
      setCosts({
        ...costsArr,
        recurring_costs: costsArr.recurring_costs
          .slice(0, idx)
          .concat(
            costsArr.recurring_costs.slice(
              idx + 1,
              costsArr.recurring_costs.length
            )
          ),
      });
    } else if (cost_type === "parking_costs") {
      setCosts({
        ...costsArr,
        parking_costs: costsArr.parking_costs
          .slice(0, idx)
          .concat(
            costsArr.parking_costs.slice(idx + 1, costsArr.parking_costs.length)
          ),
      });
    } else {
      setCosts({
        ...costsArr,
        one_time_costs: costsArr.one_time_costs
          .slice(0, idx)
          .concat(
            costsArr.one_time_costs.slice(
              idx + 1,
              costsArr.one_time_costs.length
            )
          ),
      });
    }
  }
  const handleCostChange = (idx, evt, type = "recurring_costs") => {
    if (type === "recurring_costs") {
      const newCosts = costsArr.recurring_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, recurring_costs: newCosts });
    } else if (type === "parking_costs") {
      const newCosts = costsArr.parking_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, parking_costs: newCosts });
    } else {
      const newCosts = costsArr.one_time_costs.map((cost, sidx) => {
        if (idx !== sidx) return cost;
        // return { ...tenant, [evt.target.name]: evt.target.value };
        if (evt.target.name === "email") {
          const inputValue = evt.target.value;
          const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
          evt.target.value = cleanValue;
          return { ...cost, [evt.target.name]: evt.target.value };
        } else {
          return { ...cost, [evt.target.name]: evt.target.value };
        }
      });
      setCosts({ ...costsArr, one_time_costs: newCosts });
    }
  };
  const { expense_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleExpenses.content);
  const isLoading = useSelector((state) => state.fetchSingleExpenses.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleExpenses.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleExpense(expense_id));
  }, []);
  const handleCreditForm = (frmData) => {
    // ; return;
    dispatch(postExpense(frmData, expense_id));
  };
  useEffect(() => {
    if (expense_id > 0 && data?.expense_row === null) {
      window.location.href = "/expenses";
    }
  }, [expense_id, data?.expense_row]);

  useDocumentTitle(
    data?.expense_row?.id > 0 ? "Edit Expense - Loftus" : "Add Expense - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const expense_row = data?.expense_row;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="expenses" />
              </div>
              <div className="colR ">
                <Breadcrumb
                  page_name={expense_id > 0 ? "Edit Expense" : "Add Expense"}
                  parent_name="Expenses"
                  parent_link="/expenses"
                />
                <div className="page_body">
                  <div className="option_lbl">
                    <ul>
                      <li className={formStep === 0 ? "active" : ""}>Step 1</li>
                      <li className={formStep === 1 ? "active" : ""}>Step 2</li>
                      <li className={formStep === 2 ? "active" : ""}>Step 3</li>
                      <li className={formStep === 3 ? "active" : ""}>Step 4</li>
                    </ul>
                  </div>
                  <div className="dash_form_blk">
                    <AddExpensesForm
                      setFormStep={setFormStep}
                      formStep={formStep}
                      branches={branches}
                      handleAddCosts={handleAddCosts}
                      handleRemoveCost={handleRemoveCost}
                      handleCostChange={handleCostChange}
                      costsArr={costsArr}
                      handleCreditForm={handleCreditForm}
                      isFormProcessing={isFormProcessing}
                      expense_row={expense_row}
                      setCosts={setCosts}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
