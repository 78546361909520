import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ToastContainer } from "react-toastify";

export default function ReviewPopup({
  item,
  closeReviewPopup,
  handleListingReview,
  reviewLoading,
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const onSubmit = (form_data) => {
    //
    handleListingReview(form_data);
  };
  const changeRating = (newRating, name) => {
    setValue(name, newRating);
  };
  return (
    <>
      <section className="popup review_popup" data-popup="send-message">
        <ToastContainer />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={closeReviewPopup}></div>
              <h3>Write your review</h3>
              <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Accuracy</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.accuracy !== undefined
                        ? watchAllFields?.accuracy
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("accuracy", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="accuracy"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Safety</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.safety !== undefined
                        ? watchAllFields?.safety
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("safety", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="safety"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Experience</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.experience !== undefined
                        ? watchAllFields?.experience
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("experience", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="experience"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Cleanliness</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.cleanliness !== undefined
                        ? watchAllFields?.cleanliness
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("cleanliness", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="cleanliness"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Responsiveness</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.responsiveness !== undefined
                        ? watchAllFields?.responsiveness
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("responsiveness", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="responsiveness"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp flex ratings_wrap">
                  <h5>Community</h5>
                  <StarRatings
                    rating={
                      watchAllFields?.community !== undefined
                        ? watchAllFields?.community
                        : 0
                    }
                    changeRating={changeRating}
                    starDimension="15px"
                    starSpacing="1px"
                    starRatedColor="orange"
                    {...register("community", { required: "Required" })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="community"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="txtGrp">
                  <textarea
                    name="review"
                    id=""
                    className="txtBox"
                    placeholder="write a review here"
                    {...register("review", { required: "Required" })}
                  ></textarea>
                  <ErrorMessage
                    errors={errors}
                    name="review"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
                <div className="text-center bTn">
                  <button
                    type="button"
                    className="webBtn"
                    onClick={closeReviewPopup}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="webBtn colorBtn"
                    disabled={reviewLoading ? true : false}
                  >
                    Submit {reviewLoading ? <i className="spinner"></i> : ""}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
