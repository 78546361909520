import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchSingleEmployee,
  postEmployee,
} from "../../../states/actions/fetchSingleEmployees";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ContactsForm from "./contacts/form";
import EmployeeForm from "./employees/form";
export default function AddEmployee() {
  const { employee_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleEmployees.content);
  const isLoading = useSelector(
    (state) => state.fetchSingleEmployees.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleEmployees.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleEmployee(employee_id));
  }, []);
  const handleSubmitForm = (frmData) => {
    // ; return;
    dispatch(postEmployee(frmData, employee_id));
  };
  useEffect(() => {
    if (employee_id > 0 && data?.employee_row === null) {
      window.location.href = "/employee";
    }
  }, [employee_id, data?.employee_row]);

  useDocumentTitle(
    data?.employee_row?.id > 0
      ? "Edit Employee - Loftus"
      : "Add Employee - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const employee_row = data?.employee_row;
  // ;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="employee" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    employee_row?.id > 0 ? "Edit Employee" : "Add Employee"
                  }
                  parent_name="Employees"
                  parent_link="/employee"
                />
                <div className="page_body">
                  <div className="dash_form_blk cmn_user_form">
                    <EmployeeForm
                      branches={branches}
                      handleSubmitForm={handleSubmitForm}
                      isFormProcessing={isFormProcessing}
                      employee_row={employee_row}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
