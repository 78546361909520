import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Tiles({ data }) {

    return (
        <>
            <div className="tile_flex flex">
                {data?.map((val, index) => {
                    return (
                        <div className="col" key={index}>
                            <NavLink to={val.link} className="inner">
                                <div className="tile_cntnt">
                                    <h4>{val.title}</h4>
                                    <h2>{val.count}</h2>
                                </div>
                                <div className="tile_icon">
                                    <img src={val.image} alt={val.title} />
                                </div>
                            </NavLink>
                        </div>
                    );

                })}


            </div>
        </>
    )
}
