import React from "react";
import Image from '../../common/image';
import { Link } from "react-router-dom";
import BlogCategory from "./blog-category";
import PopularPost from "./popular-post";

export default function BlogGrid({ data }) {
    return (
        <>
            <section className="blog_page">
                <div className="contain">
                    {
                        data.featured_posts != '' && data.featured_posts != undefined && data.featured_posts != null ?
                            <div className="featured_posts">
                                <div className="flex">
                                    {data.featured_posts?.map((val, index) => {
                                        return (
                                            <div className="col_feature" key={index}>
                                                <div className="inner">
                                                    <Link to={val.link} className="image_feature">
                                                        <Image src={val.image} alt={val.title} />
                                                    </Link>
                                                    <div className="txt">
                                                        <div className="ctgry">{val.category}</div>
                                                        <h4><Link to={val.link}>{val.title}</Link></h4>
                                                        <div className="blog_date">{val.date}</div>
                                                        <p>{val.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            ""
                    }
                    <div className="flex blog_flex">
                        <div className="colL">
                            <div className="inner_blog">
                                {data.all_posts?.map((val, index) => {
                                    return (
                                        <div className="col" key={index}>
                                            <div className="inner">
                                                <Link to={val.link} className="image">
                                                    <Image src={val.image} alt={val.title} />
                                                </Link>
                                                <div className="txt">
                                                    <div className="ctgry">{val.category}</div>
                                                    <h5><Link to={val.link}>{val.title}</ Link></h5>
                                                    <div className="blog_date">{val.date}</div>
                                                    <p>{val.text}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="colR">
                            {
                                data.categories_list != undefined && data.categories_list != null && data.categories_list != '' ?

                                    <BlogCategory data={data.categories_list} />
                                    :
                                    ""
                            }
                            {
                                data.popular_posts != undefined && data.popular_posts != null && data.popular_posts != '' ?

                                    <PopularPost data={data.popular_posts} />
                                    :
                                    ""
                            }

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
