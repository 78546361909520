import React from 'react'

export default function ReportBlk({ report, showPopup }) {
    return (
        <>
            {
                report?.type.toLowerCase() === 'credit' ?

                    <div className="col" onClick={() => showPopup(report?.report_html, report?.status_flag)}>
                        <div className="inner">
                            <div className="image_sm">
                                <img src="/images/icons/credit.png" alt="" />
                            </div>
                            <h5>Credit</h5>
                            <span dangerouslySetInnerHTML={{ __html: report?.status }} />
                        </div>
                    </div>
                    :
                    (
                        report?.type.toLowerCase() === 'criminal' ?

                            <div className="col" onClick={() => showPopup(report?.report_html, report?.status_flag)}>
                                <div className="inner">
                                    <div className="image_sm">
                                        <img src="/images/icons/criminal.png" alt="" />
                                    </div>
                                    <h5>Criminal</h5>
                                    <span dangerouslySetInnerHTML={{ __html: report?.status }} />
                                </div>
                            </div>
                            :
                            (
                                report?.type.toLowerCase() === 'eviction' ?

                                    <div className="col" onClick={() => showPopup(report?.report_html, report?.status_flag)}>
                                        <div className="inner">
                                            <div className="image_sm">
                                                <img src="/images/icons/eviction.png" alt="" />
                                            </div>
                                            <h5>Eviction Proceedings</h5>
                                            <span dangerouslySetInnerHTML={{ __html: report?.status }} />
                                        </div>
                                    </div>
                                    :
                                    (
                                        report?.type.toLowerCase() === 'idreport' ?

                                            <div className="col" onClick={() => showPopup(report?.report_html, report?.status_flag)}>
                                                <div className="inner">
                                                    <div className="image_sm">
                                                        <img src="/images/icons/id.png" alt="" />
                                                    </div>
                                                    <h5>ID</h5>
                                                    <span dangerouslySetInnerHTML={{ __html: report?.status }} />
                                                </div>
                                            </div>
                                            :
                                            ""
                                    )))
            }
        </>
    )
}
