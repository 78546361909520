import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT,
  FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS,
  FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED,
  SINGLE_MAINTENANCE_REQUESTS_MESSAGE,
  SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS,
  SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED,
  FETCH_MAINTENANCE_REQUESTS_CONTENT,
  FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED,
  FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS,
  MAINTENANCE_REQUESTS_DELETE__MESSAGE,
  MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS,
  MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED,
  GET_FILTER_MAINTENANCE_REQUESTS,
  GET_FILTER_MAINTENANCE_REQUESTS_FAILED,
  GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS,
} from "./actionTypes";

export const fetchMaintenanceRequests = () => (dispatch) => {
  dispatch({
    type: FETCH_MAINTENANCE_REQUESTS_CONTENT,
    payload: null,
  });
  http
    .get("maintenance-requests")
    .then(({ data }) => {
      dispatch({
        type: FETCH_MAINTENANCE_REQUESTS_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_MAINTENANCE_REQUESTS_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleMaintenanceRequest =
  (request_id, type = "", req_type = "") =>
    (dispatch) => {
      dispatch({
        type: FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT,
        payload: null,
      });
      let apiUrl = "single-maintenance-request/" + request_id + "/" + type;
      if (req_type !== null && req_type != "") {
        apiUrl =
          "single-maintenance-request/" +
          request_id +
          "/" +
          req_type +
          "/" +
          type;
      }
      http
        .get(apiUrl)
        .then(({ data }) => {
          if(data?.not_found===1){
            window.location.href="/service-requests"
          }
          dispatch({
            type: FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_SUCCESS,
            payload: data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FETCH_SINGLE_MAINTENANCE_REQUEST_CONTENT_FAILED,
            payload: error,
          });
        });
    };

export const postMaintenanceRequest = (formData, request_id) => (dispatch) => {
  //
  let postUrl = "";
  if (request_id != undefined && request_id !== null) {
    postUrl = "edit-maintenance-request/" + request_id;
  } else {
    postUrl = "add-maintenance-request";
  }
  dispatch({
    type: SINGLE_MAINTENANCE_REQUESTS_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          if (data?.req_type && data?.encoded_id) {
            window.location.replace("/view-service-request/" + data?.encoded_id + "/" + data?.req_type);
          }
          else {
            window.location.replace("/service-requests");
          }

        }, 2000);
        dispatch({
          type: SINGLE_MAINTENANCE_REQUESTS_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_MAINTENANCE_REQUESTS_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteRequest = (formData) => (dispatch) => {
  dispatch({
    type: MAINTENANCE_REQUESTS_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post(
      "delete-maintenance-request/" + formData,
      helpers.doObjToFormData(formData)
    )
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/service-requests");
        }, 2000);
        dispatch({
          type: MAINTENANCE_REQUESTS_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: MAINTENANCE_REQUESTS_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredMaintenanceRequests = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_MAINTENANCE_REQUESTS,
    payload: null,
  });
  http
    .post("sort-maintenance-requests", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      console.log(data,'filters')
      if (data.status) {
        dispatch({
          type: GET_FILTER_MAINTENANCE_REQUESTS_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_MAINTENANCE_REQUESTS_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_MAINTENANCE_REQUESTS_FAILED,
        payload: error,
      });
    });
};
