import React from 'react'
import { getSiteImages } from '../../helpers/api';
import Images from './images';
import MultiFiles from './MultiFile';

export default function ImagesBlk({ title, btn_name, images, handleRemoveLabelImage, uploadMultipleImages, label, path }) {
    return (
        <>
            <div className="crud_image">
                {title != '' ? <h4>{title}</h4> : ''}


                <Images images={images} label={label} handleRemoveLabelImage={handleRemoveLabelImage} path={path} />
                <MultiFiles name={btn_name} label={label} uploadMultipleImages={uploadMultipleImages} />
            </div>
        </>
    )
}
