import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_DOCUMENT_CONTENT,
  FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS,
  FETCH_SINGLE_DOCUMENT_CONTENT_FAILED,
  SINGLE_DOCUMENT_MESSAGE,
  SINGLE_DOCUMENT_MESSAGE_SUCCESS,
  SINGLE_DOCUMENT_MESSAGE_FAILED,
  FETCH_DOCUMENT_CONTENT,
  FETCH_DOCUMENT_CONTENT_FAILED,
  FETCH_DOCUMENT_CONTENT_SUCCESS,
  DOCUMENT_DELETE__MESSAGE,
  DOCUMENT_DELETE_MESSAGE_SUCCESS,
  DOCUMENT_DELETE__MESSAGE_FAILED,
  GET_FILTER_DOCUMENT,
  GET_FILTER_DOCUMENT_FAILED,
  GET_FILTER_DOCUMENT_SUCCESS,
} from "./actionTypes";

export const fetchDocuments = () => (dispatch) => {
  dispatch({
    type: FETCH_DOCUMENT_CONTENT,
    payload: null,
  });
  http
    .get("documents")
    .then(({ data }) => {
      dispatch({
        type: FETCH_DOCUMENT_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_DOCUMENT_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const fetchSingleDocument = (DOCUMENT_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_DOCUMENT_CONTENT,
    payload: null,
  });
  http
    .get("single-document/" + DOCUMENT_id)
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_SINGLE_DOCUMENT_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_DOCUMENT_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postDocument = (formData, DOCUMENT_id) => (dispatch) => {
  //
  let postUrl = "";
  if (DOCUMENT_id != undefined && DOCUMENT_id !== null) {
    postUrl = "edit-document/" + DOCUMENT_id;
  } else {
    postUrl = "add-document";
  }
  dispatch({
    type: SINGLE_DOCUMENT_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/documents");
        }, 2000);
        dispatch({
          type: SINGLE_DOCUMENT_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SINGLE_DOCUMENT_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SINGLE_DOCUMENT_MESSAGE_FAILED,
        payload: error,
      });
    });
};

export const deleteDocument = (formData) => (dispatch) => {
  dispatch({
    type: DOCUMENT_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-document/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/documents");
        }, 2000);
        dispatch({
          type: DOCUMENT_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: DOCUMENT_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: DOCUMENT_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const getFilteredDocuments = (formData) => (dispatch) => {
  dispatch({
    type: GET_FILTER_DOCUMENT,
    payload: null,
  });
  http
    .post("documents", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        dispatch({
          type: GET_FILTER_DOCUMENT_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);

        dispatch({
          type: GET_FILTER_DOCUMENT_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GET_FILTER_DOCUMENT_FAILED,
        payload: error,
      });
    });
};
