import React from 'react'
import StarRatings from 'react-star-ratings';
export default function SingleReview({ reply, review, toggleReply }) {
    return (
        <li>
            <div className="image_icon">
                <img src={review?.member_image} alt={review?.member_name} />
            </div>
            <div className="client_info flex">
                <div className="intro_review_left">
                    <h4>{review?.member_name} <img src="/images/check_sign.png" alt="" className="home_tenant_check" /></h4>
                    <h6>{review?.time}</h6>
                </div>
                <div className="rating_stars_right">
                    {/* <div className="ratestars"></div> */}
                    <StarRatings
                        rating={review?.ratings}
                        starDimension="15px"
                        starSpacing="1px"
                        starRatedColor="orange"
                    />
                </div>
            </div>
            <div className="_comment">
                <p>{review?.review}</p>
            </div>
            <div className='like_reply flex'>
                <div className='_col'>
                    <ul className='flex'>
                        <li>
                            <a href=''>
                                <i className="fi-thumbs-up"></i>
                            </a>
                        </li>
                        <li>
                            <a href=''>
                                <i className="fi-thumbs-down"></i>
                            </a>
                        </li>
                        <li>
                            <a href='#!' onClick={toggleReply} className="reply_link">
                                Reply
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='_col'>
                    <a className='webBtn' href=''>Report</a>
                </div>
            </div>
            {
                reply === true ?
                    <div className='replyWrap'>
                        <form action="">
                            <div className="txtGrp">
                                <textarea name="" id="" className="txtBox" placeholder="write your message here"></textarea>
                            </div>
                            <div className="bTn">
                                <button type="submit" className="webBtn colorBtn">Send</button>
                            </div>
                        </form>
                    </div>
                    :
                    ""
            }
        </li>
    )
}
