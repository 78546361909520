import React from "react";
import { NavLink } from "react-router-dom";

export default function ActiveProperty({
  item,
  deleteItem,
  lease_tenant_page = false,
  createLease,
  leases_page = false,
}) {
  return (
    <>
      <div className="col">
        <div className="inner">
          {leases_page !== true ? (
            <div className="action_drop _dropDown">
              <div className="_dropBtn action_dots action_plus">
                <img src="/images/dashboard/plus.png" alt="" />
              </div>
              <ul className="_dropCnt dropLst">
                <li>
                  <a href="#!" onClick={() => createLease(item?.encoded_id)}>
                    + Lease
                  </a>
                </li>
                <li>
                  <NavLink to={"/sale-listing/" + item?.slug}>
                    + Sale listing
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/rent-listing/" + item?.slug}>
                    + Rental listing
                  </NavLink>
                </li>
                {/* <li>
                                        <a href="?">+ Delegate Person</a>
                                    </li> */}
                <li>
                  <NavLink to="/add-service-person">+ Service Staff</NavLink>
                </li>
                {/* <li>
                                <NavLink to={"/edit-property/" + item.id}><i className='fi-pencil'></i> Edit</NavLink>
                            </li> */}
              </ul>
            </div>
          ) : (
            ""
          )}
          <div className="image">
            <img src={item.image} alt={item.title} />
          </div>
          <div className="cntnt">
            <h3>{item.title}</h3>
            <ul className="flex">
              {item?.studio === "no" ? (
                <li>
                  <span>{item.beds + " Bed"} </span>
                </li>
              ) : (
                <li>
                  <span>Studio</span>
                </li>
              )}
              <li>
                <span>{item.baths + " Bath"}</span>
              </li>
              <li>
                <span>{item.sf}</span>
              </li>
            </ul>
            <ul className="flex">
              <li>
                <span dangerouslySetInnerHTML={{ __html: item.address }} />
              </li>
            </ul>
          </div>
          {!lease_tenant_page &&
          item?.slug !== undefined &&
          item?.slug !== null &&
          item?.slug !== "" ? (
            <NavLink
              NavLink
              to={"/user-property/" + item.slug}
              className="link"
            ></NavLink>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
