module.exports = {
    TOAST_SETTINGS: {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        newestOnTop: true
    }
};
