import React from "react";
import { getServerImage } from "../../helpers/api";

export default function GetFileIconByFileExtension({ file, display_name }) {
    const getFileExtension = (filename) => {
        const parts = filename?.split('.');
        return parts[parts.length - 1]?.toLowerCase();
    }
    const extension = getFileExtension(file);
    return <>
        {
            ['jpg', 'jpeg', 'png', 'gif'].includes(extension) ?
                <a className="images_contain" href={getServerImage("storage/attachments/", file)} target="_blank" download>
                    <div className="image">
                        <img src={getServerImage("storage/attachments/", file)} />
                    </div>
                    {/* <a href={getServerImage("storage/attachments/", file)} className="download_btn" target="_blank" download>Download</a> */}
                </a>
                :
                extension === 'doc' || extension === 'docx' ?
                    <a className="attachment_document flex" href={getServerImage("storage/attachments/", file)} target="_blank" download>
                        <div className="icon">
                            <img src="/images/attachment-document.png" alt="attachment document" />

                        </div>
                        <span>{display_name}</span>
                    </a>
                    :
                    extension === 'pdf' ?
                        <a className="attachment_document flex" href={getServerImage("storage/attachments/", file)} target="_blank" download>
                            <div className="icon">
                                <img src="/images/pdf.png" alt="attachment document" />

                            </div>
                            <span>{display_name}</span>
                        </a>
                        :
                        extension === 'xls' || extension === 'xlsx' || extension === 'csv' ?
                            <a className="attachment_document flex" href={getServerImage("storage/attachments/", file)} target="_blank" download>
                                <div className="icon">
                                    <img src="/images/xls.png" alt="attachment document" />

                                </div>
                                <span>{display_name}</span>
                            </a>
                            :
                            <a className="attachment_document flex" href={getServerImage("storage/attachments/", file)} target="_blank" download>
                                <div className="icon">
                                    <img src="/images/attachment-document.png" alt="attachment document" />

                                </div>
                                <span>{display_name}</span>
                            </a>
        }
    </>;
}
