import React from "react";

export default function AllBanner({ data }) {
    return (
        <>
            <section className="partner_banner" style={{ backgroundImage: "url(" + data.image + ")" }}>
                <div className="contain">
                    <div className="page_heading text-center">
                        <h1>{data.heading}</h1>
                    </div>
                    
                </div>
            </section>
        </>
    )
}
