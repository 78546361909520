import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
    FETCH_SINGLE_PROPERTY_CONTENT,
    FETCH_SINGLE_PROPERTY_CONTENT_SUCCESS,
    FETCH_SINGLE_PROPERTY_CONTENT_FAILED,
    SINGLE_PROPERTY_MESSAGE,
    SINGLE_PROPERTY_MESSAGE_SUCCESS,
    SINGLE_PROPERTY_MESSAGE_FAILED,
    FETCH_PROPERTY_FLOOR_CONTENT,
    FETCH_PROPERTY_FLOOR_CONTENT_SUCCESS,
    FETCH_PROPERTY_FLOOR_CONTENT_FAILED


} from "./actionTypes";


export const fetchSingleProperty = (property_id) => (dispatch) => {
    dispatch({
        type: FETCH_SINGLE_PROPERTY_CONTENT,
        payload: null
    });
    http
        .get("single-property/" + property_id)
        .then(({ data }) => {
            dispatch({
                type: FETCH_SINGLE_PROPERTY_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_SINGLE_PROPERTY_CONTENT_FAILED,
                payload: error
            });
        });
};
export const fetchBranchFloorPlans = (branch_id) => (dispatch) => {
    dispatch({
        type: FETCH_PROPERTY_FLOOR_CONTENT,
        payload: null
    });
    http
        .post("get-floor-plans/" + branch_id)
        .then(({ data }) => {
            dispatch({
                type: FETCH_PROPERTY_FLOOR_CONTENT_SUCCESS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_PROPERTY_FLOOR_CONTENT_FAILED,
                payload: error
            });
        });
};

export const postProperty = (formData, floor_id) => (dispatch) => {
    let postUrl = '';
    if (floor_id != undefined && floor_id !== null) {
        postUrl = 'edit-property/' + floor_id;
    }
    else {
        postUrl = 'add-property';
    }
    dispatch({
        type: SINGLE_PROPERTY_MESSAGE,
        payload: null
    });
    http
        .post(postUrl, helpers.doObjToFormData(formData))
        .then(({ data }) => {
            if (data.status) {
                toast.success(
                    `${data.msg}`,
                    TOAST_SETTINGS
                );
                setTimeout(() => {
                    window.location.replace("/properties");
                }, 2000);
                dispatch({
                    type: SINGLE_PROPERTY_MESSAGE_SUCCESS,
                    payload: data
                });
            } else {
                toast.error(
                    <Text string={data.msg} parse={true} />,
                    TOAST_SETTINGS
                );
                dispatch({
                    type: SINGLE_PROPERTY_MESSAGE_FAILED,
                    payload: null
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: SINGLE_PROPERTY_MESSAGE_FAILED,
                payload: error
            });
        });
};

