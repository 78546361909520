import React from "react";
import DocumentLst from "./lst";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function DocumentBlk({
  active_documents,
  handleDelete,
  type = null,
}) {
  return (
    <>
      <div class="blk blockLst user_dash_tbl cell_block_cell">
        <table>
          <thead>
            <tr>
              <th class="hide_cell_hyp">Branch</th>
              <th>Document Name</th>
              <th>Modified</th>
              <th>Document</th>
              {mem_permissions === "admin" ? (
                <th class="text-right hide_cell_hyp">Actions</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {active_documents?.length > 0 ? (
              active_documents?.map((active_document, index) => {
                return (
                  <DocumentLst
                    active_document={active_document}
                    index={index}
                    handleDelete={handleDelete}
                    type={type}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={mem_permissions === "admin" ? 5 : 4}>
                  <div className="alert alert-danger">No data found!</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
