import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { getData } from '../../../helpers/api';
export default function SearchForm({ text }) {
    const [propertyType, setPropertyType] = useState("buy");
    const [locations, setLocations] = useState({
        properties: [],
        states: [],
    });
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({ mode: 'onChange' });

    const onSubmit = data => {
        window.location.href = "/explore?type=" + propertyType + "&&address=" + data?.address
    }
    const handleInputChange = async (e) => {
        let address = e.target.value;
        await getData("api/get-property-locations", address).then((data) => {
            setLocations({ properties: data?.properties, states: data?.states })
        });

    }
    const handleCancelPopup = () => {
        setLocations({ properties: [], states: [] })

    }
    return (
        <form action="" onSubmit={handleSubmit(onSubmit)} method="get" autoComplete="off">
            <div className="flex search_flex">
                <div className="col">
                    <div className="mini_tabs">
                        <div className={propertyType === 'buy' ? "lblBtn buyBtn active" : "lblBtn"} onClick={() => setPropertyType('buy')}>
                            <input type="radio" name="type" value="buy" className="hidden" checked={propertyType === 'buy' ? true : false} />
                            <label htmlFor="">Buy</label>
                        </div>
                    </div>
                    <div className="mini_tabs">
                        <div className={propertyType === 'rent' ? "lblBtn rentBtn active" : "lblBtn"} onClick={() => setPropertyType('rent')}>
                            <input type="radio" name="type" value="rent" className="hidden" checked={propertyType === 'rent' ? true : false} />
                            <label htmlFor="">Rent</label>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="txtGrp">
                        <input type="text" name='address' className="txtBox" placeholder={text} {...register("address")}
                            onKeyUp={handleInputChange}
                        />
                        {
                            locations?.states?.length > 0 || locations?.properties?.length > 0 ?

                                <ul className="dropCnt dropLst active search_list">
                                    <div className="crosBtn" onClick={handleCancelPopup}></div>
                                    {
                                        locations?.states?.length > 0 ?
                                            <div className="state_drop">
                                                <h5>States:</h5>
                                                {
                                                    locations?.states?.map((state, index) => {
                                                        return (
                                                            <li key={index}><a href={"/explore?type=" + propertyType + "&&address=" + state?.name}>{state?.name}, {state?.code}</a></li>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            ""
                                    }
                                    {
                                        locations?.properties?.length > 0 ?
                                            <div className="prop_drop">
                                                <h5>Properties:</h5>
                                                {
                                                    locations?.properties?.map((property, index) => {
                                                        return (
                                                            <li key={index}><a href={"/explore?type=" + propertyType + "&&address=" + property?.address}>{property?.address}</a></li>
                                                        )
                                                    })
                                                }

                                            </div>
                                            :
                                            ""
                                    }

                                </ul>
                                :
                                ""
                        }
                    </div>
                </div>

                <div className="col_last">
                    <button type="submit" name="" className="webBtn colorBtn"><i className="fi-search"></i></button>
                </div>
            </div>
        </form>
    )
}
