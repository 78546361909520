import React from "react";
import { format_amount, getServerImage } from "../../../../helpers/api";
import Text from "../../../common/Text";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");
export default function BillingLst({
  billing_row,
  setBillingPopup,
  handleDelete,
  type,
  mem_permissions,
}) {
  return (
    <>
      <div className="lst">
        <ul>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Branch</h5>
            <div>{billing_row?.branch_name}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Address</h5>
            <div>
              <Text string={billing_row?.property_address} />
            </div>
          </li>
          <li class="hide_cell_hyp">
            <h5 class="lease_heading_mini">Tenant</h5>
            <ul class="tenants_dps_flex credits_flex_com">
              {billing_row?.tenants?.length > 0
                ? billing_row?.tenants?.map((tenant, index) => {
                    return (
                      <li key={index}>
                        <div className="inner">
                          <div className="mini_icon">
                            <img
                              src={getServerImage(
                                "storage/members/",
                                tenant?.thumb
                              )}
                              alt={tenant?.name}
                            />
                          </div>
                          <div className="bio_info">
                            <div className="name">
                              <h4>{tenant?.name}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="ico">
                          <img
                            src={getServerImage(
                              "storage/members/",
                              tenant?.thumb
                            )}
                            alt={tenant?.name}
                          />
                        </div>
                      </li>
                    );
                  })
                : ""}
            </ul>
          </li>
          <li>
            <h5 className="lease_heading_mini">Expenses</h5>
            <div>{format_amount(billing_row?.total)}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Cycle</h5>
            <div>{billing_row?.cycle}</div>
          </li>
          {mem_permissions === "admin" || mem_permissions === "power" ? (
            <li className="bTn action_drop_lease">
              <div className="action_drop _dropDown">
                <div className="_dropBtn action_dots">
                  <img src="/images/dashboard/dots.png" alt="" />
                </div>
                <ul className="_dropCnt dropLst">
                  <li>
                    <a
                      href="#!"
                      onClick={() =>
                        setBillingPopup({ item: billing_row, show: true })
                      }
                    >
                      View
                    </a>
                  </li>
                  {type === "pending" ? (
                    <>
                      {mem_permissions === "admin" ? (
                        <>
                          <li>
                            <NavLink to={"/edit-billing/" + billing_row?.id}>
                              Edit
                            </NavLink>
                          </li>

                          <li>
                            <NavLink
                              to="#!"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you wish to delete this item?"
                                  )
                                )
                                  handleDelete(e, billing_row?.id);
                              }}
                              className=""
                            >
                              Delete
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
}
