import React, { useState } from "react";
import Pagination from "../../../common/pagination";
import ListingFilter from "./listingFilter";
import ListingLst from "./ListingLst";
import { getSiteImages } from "../../../../helpers/api";

export default function ListingBlk({
  items,
  handleDelete,
  branches,
  handleBranchChange,
  listings,
  listingType,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div className="page_body listing_body">
        <div className="listing_list_c  listing_list_c_credit_com listing_page_wrapper">
          {/* {
                        listingType === 'active' ?

                            <ListingFilter branches={branches} handleBranchChange={handleBranchChange} />
                            :
                            ""
                    } */}
          {listings?.loading === true && listingType === "active" ? (
            <div className="relative">
              <div id="loading" className="listingsLoading">
                {" "}
                <img src={getSiteImages("images/loading.gif")} alt="Loading" />
              </div>
            </div>
          ) : currentItems !== undefined &&
            currentItems !== "" &&
            currentItems !== null &&
            currentItems?.length > 0 ? (
            <>
              <div
                className={
                  listingType === "expired"
                    ? "outer_list_c expired_outer_list_c"
                    : "outer_list_c"
                }
              >
                {currentItems?.map((item, index) => {
                  return (
                    <ListingLst
                      item={item}
                      key={index}
                      deleteItem={handleDelete}
                      listingType={listingType}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="alert alert-danger">No listing(s) added yet!</div>
          )}
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={items?.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
