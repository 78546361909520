import React from "react";
import { NavLink } from "react-router-dom";
import { format_number } from "../../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function PropertyLst({ property, handleDelete }) {
  const deleteItem = (property_id) => {
    handleDelete(property_id);
  };
  return (
    <>
      <div className="lst floorBlkTbl">
        <NavLink
          to={"/commercial-property/" + property.slug}
          className="overlay_cell_lnk_all"
        ></NavLink>
        <ul>
          <li>
            <h5 className="lease_heading_mini">Branch</h5>
            <div>{property.branch}</div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Address</h5>
            {/* <div>{property.address}</div> */}
            <div dangerouslySetInnerHTML={{ __html: property.address }} />
          </li>
          <li>
            <h5 className="lease_heading_mini">Floor Plan</h5>
            <div>{property.floor_plan}</div>
          </li>
          <li className="property_size_li">
            <h5 className="lease_heading_mini">Size</h5>
            <div>
              <div className="specific_flex_span">
                <span>
                  {property?.studio === "yes"
                    ? "Studio"
                    : parseInt(property?.bedrooms) + " Bed"}
                </span>
                {
                  <span>
                    {parseInt(property?.full_bathrooms) +
                      parseInt(property?.half_bathrooms)}{" "}
                    Bath
                  </span>
                }
                {<span>{format_number(parseInt(property?.sq_feet))} SF</span>}
              </div>
            </div>
          </li>
          {mem_permissions === "admin" || mem_permissions === "power" ? (
            <li className="bTn action_drop_lease">
              <div className="action_drop _dropDown">
                <div className="_dropBtn action_dots">
                  <img src="/images/dashboard/dots.png" alt="" />
                </div>
                <ul className="_dropCnt dropLst">
                  {/* <li>
                                    <NavLink to={"/property/" + property.slug}>View</NavLink>
                                </li> */}
                  <li>
                    <NavLink to={"/edit-property/" + property.id}>Edit</NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#!"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you wish to delete this item?"
                          )
                        )
                          deleteItem(property.id);
                      }}
                      className=""
                    >
                      Delete
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    </>
  );
}
