import React from "react";
import Image from '../../common/image';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonial({ data }) {
    return (
        <>
            <section className="testimonial_sec">
                <div className="left_side_image">
                    <Image src={data.image} alt={data.heading} />
                </div>
                <div className="contain">
                    <div className="cntnt">
                        <div className="sec_heading">
                            <h2>{data.heading}</h2>
                        </div>
                        <OwlCarousel items={1}  className="owl-theme testimonials"  loop>
                            {data.testmonial.map((val, index) => {
                                return(
                                    <div className="item" key={index}>
                                        <div className="inner">
                                            <div className="user_image">
                                                <Image src={val.client_dp} alt={val.client_name} />
                                            </div>
                                            <div className="testi_cntnt">
                                                <h4>{val.client_name}</h4>
                                                <h6>{val.location}</h6>
                                                <div className="rating_star">
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                    <i className="fa fa-star" />
                                                </div>
                                                <div className="quote_per">
                                                    <p>{val.quote}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                    </div>
                </div>
            </section>
        </>
    )
}
