import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getServerImage, short_text } from "../../helpers/api";
const mem_type = localStorage.getItem("mem_type");
const mem_permissions = localStorage.getItem("mem_permissions");
export default function LoggedMenu({
  member,
  profileImage,
  HideMenu,
  unreadNotify,
  exploreClass = false,
  topMessagesCount,
  handleGetUserMessages,
}) {
  const [topMessages, setTopMessages] = useState(member?.top_msgs);
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState(false);
  const SubNav = () => {
    setShow(!show);
  };
  const UserMenu = () => {
    setMenu(!menu);
    setNotification(false);
    setMessage(false);
  };
  const UserHideMenu = () => {
    setMenu(false);
    setNotification(false);
    setMessage(false);
    HideMenu();
  };
  const showNotifications = () => {
    setNotification(!notification);
    setMenu(false);
    setMessage(false);
  };
  const showMessages = () => {
    setMessage(!message);
    setMenu(false);
    setNotification(false);
  };
  const Logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  return (
    <>
      <nav className={exploreClass !== false ? "ease explore_nav" : "ease"}>
        <ul id="iconBtn">
          <li id="chatBtn" className="_dropDown">
            <div className="iconBtn _dropBtn" onClick={showMessages}>
              {parseInt(topMessagesCount) > 0 ? (
                <span className="active" id="msg_unread"></span>
              ) : (
                ""
              )}

              <img src="/images/dashboard/icon-envelope.svg" alt="" />
            </div>
            {message === true ? (
              <div className="_dropCnt lg_drop notification_listing">
                <ul className="dropLst">
                  <li>
                    <div className="notify_header">
                      <h5>You have new messages </h5>
                    </div>
                  </li>
                  {topMessages?.length > 0
                    ? topMessages?.map((new_msg, index) => {
                        return (
                          <li key={index}>
                            <a
                              href="#!"
                              className="inner"
                              onClick={() =>
                                handleGetUserMessages(new_msg?.chat_id)
                              }
                            >
                              <div className="user_sm_icon">
                                <img
                                  src={new_msg?.user_dp}
                                  alt={new_msg?.user_name}
                                />
                              </div>
                              <div className="notify_cntnt">
                                <p>
                                  <strong>{new_msg?.user_name}</strong>{" "}
                                  {short_text(new_msg?.msg, 15)}
                                </p>
                                <div className="time_ago">
                                  {new_msg?.time_ago}
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      })
                    : ""}

                  <li>
                    <div
                      className="notify_footer"
                      onClick={() => handleGetUserMessages(null)}
                    >
                      <a href="/messages">View all messages</a>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </li>
          <li id="likeBtn" className="_dropDown">
            <div className="iconBtn _dropBtn" onClick={showNotifications}>
              {unreadNotify?.unread > 0 ? <span className="active"></span> : ""}

              <img
                src="/images/dashboard/icon-bell.svg"
                alt=""
                className={unreadNotify?.unread > 0 ? "ring_bell" : ""}
              />
            </div>
            {notification === true ? (
              <div className="_dropCnt lg_drop notification_listing">
                <ul className="dropLst">
                  <li>
                    <div className="notify_header">
                      <h5>
                        You have{" "}
                        {unreadNotify?.unread > 0
                          ? unreadNotify?.unread
                          : "No new "}{" "}
                        notification{unreadNotify?.unread > 1 ? "s" : ""}
                      </h5>
                    </div>
                  </li>
                  <li>
                    <NavLink to="" className="inner">
                      <div className="user_sm_icon color_icon_notify">
                        <img src="/images/dashboard/envelope.svg" alt="" />
                      </div>
                      <div className="notify_cntnt">
                        <p>
                          <strong>2 new Messages</strong>
                        </p>
                        <div className="time_ago">15:50 PM</div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="" className="inner">
                      <div className="user_sm_icon color_icon_notify">
                        <img src="/images/dashboard/comment-alt.svg" alt="" />
                      </div>
                      <div className="notify_cntnt">
                        <p>
                          <strong>3 new Comments</strong>
                        </p>
                        <div className="time_ago">10:00 AM</div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="" className="inner">
                      <div className="user_sm_icon color_icon_notify">
                        <img src="/images/dashboard/exclamation.svg" alt="" />
                      </div>
                      <div className="notify_cntnt">
                        <p>
                          <strong>Application Error</strong>
                        </p>
                        <div className="time_ago">12:00 AM</div>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <div className="notify_footer">
                      <NavLink to="/notification">
                        View all notifications
                      </NavLink>
                    </div>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </li>
        </ul>

        <div className="proIco _dropDown">
          <div className="ico _dropBtn" onClick={UserMenu}>
            <img
              src={getServerImage(
                "storage/members/",
                profileImage != "" &&
                  profileImage != undefined &&
                  profileImage != null
                  ? profileImage
                  : member?.mem_image
              )}
              alt={
                mem_type === "company"
                  ? member.mem_fullname
                  : member.mem_fname + " " + member.mem_lname
              }
            />
          </div>
          {menu === true ? (
            <div className="proDrop _dropCnt">
              <ul className="dropLst">
                {mem_type && mem_type === "member" ? (
                  <>
                    <li>
                      <div className="user_header">
                        <h5>{member.mem_fname + " " + member.mem_lname}</h5>
                        <p>{member.mem_bio}</p>
                      </div>
                    </li>
                    <li>
                      <NavLink to="/dashboard" onClick={() => UserHideMenu()}>
                        <div className="small_icon">
                          <img src="/images/dashboard/dashboard.svg" alt="" />
                        </div>
                        <span>Dashboard</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/favorites" onClick={() => UserHideMenu()}>
                        <div className="small_icon">
                          <img src="/images/dashboard/heart.svg" alt="" />
                        </div>
                        <span>Favorites</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/profile-settings"
                        onClick={() => UserHideMenu()}
                      >
                        <div className="small_icon">
                          <img src="/images/dashboard/settings.svg" alt="" />
                        </div>
                        <span>Profile</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/service-profile"
                        onClick={() => UserHideMenu()}
                      >
                        <div className="small_icon">
                          <img
                            src="/images/dashboard/service_profile.png"
                            alt=""
                          />
                        </div>
                        <span>Service Profile</span>
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink
                        to="/service-staff"
                        onClick={() => UserHideMenu()}
                      >
                        <div className="small_icon">
                          <img src="/images/dashboard/document.svg" alt="" />
                        </div>
                        <span>Service Staff</span>
                      </NavLink>
                    </li> */}
                    <li>
                      <NavLink
                        to="/payment-methods"
                        onClick={() => UserHideMenu()}
                      >
                        <div className="small_icon">
                          <img src="/images/dashboard/bank.svg" alt="" />
                        </div>
                        <span>Payment Methods</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/analytics" onClick={() => UserHideMenu()}>
                        <div className="small_icon">
                          <img
                            src="/images/dashboard/chart-histogram.svg"
                            alt=""
                          />
                        </div>
                        <span>Analytics</span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <div
                        className="user_header"
                        onClick={() => UserHideMenu()}
                      >
                        <h5>{member.mem_fullname}</h5>
                        <p>{member.mem_business}</p>
                      </div>
                    </li>
                    {mem_permissions === "admin" ||
                    mem_permissions === "power" ? (
                      <li>
                        <NavLink to="/dashboard" onClick={() => UserHideMenu()}>
                          <div className="small_icon">
                            <img src="/images/dashboard/dashboard.svg" alt="" />
                          </div>
                          <span>Dashboard</span>
                        </NavLink>
                      </li>
                    ) : mem_permissions === "maintenance" ? (
                      <li>
                        <NavLink
                          to="/service-requests"
                          onClick={() => UserHideMenu()}
                        >
                          <div className="small_icon">
                            <img src="/images/dashboard/dashboard.svg" alt="" />
                          </div>
                          <span>Service Requests</span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                )}
                <li>
                  <NavLink to="#!" onClick={Logout}>
                    <div className="small_icon" onClick={() => UserHideMenu()}>
                      <img src="/images/dashboard/sign-out-alt.svg" alt="" />
                    </div>
                    <span>Logout</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </nav>
    </>
  );
}
