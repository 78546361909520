import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { toSluUrl } from "../../../../../helpers/api";

export default function BranchStep2({
  isFormProcessing,
  changeStep,
  step,
  register,
  errors,
  branch_row,
  property_options,
  house_style_options,
  amenities,
  control,
  trigger,
  isValid,
  watchAllFields,
}) {
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-4">
            <h6>Year Built</h6>
            <div className="form_blk">
              <input
                type="text"
                className="txtBox"
                {...register("year", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1500,
                    message: "Year must be greater than 1499.",
                  },
                })}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row.year
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="year"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Property Type</h6>
            <div className="form_blk">
              <Controller
                {...register("property_type", { required: "Required" })}
                name="property_type"
                control={control}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row?.property_type
                    : ""
                }
                render={({ field }) => (
                  <>
                    <Select
                      options={property_options}
                      {...field}
                      label="Select Property Type"
                    />
                  </>
                )}
              />

              <ErrorMessage
                errors={errors}
                name="property_type"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>House Style</h6>
            <div className="form_blk">
              <Controller
                {...register("house_style", { required: "Required" })}
                name="house_style"
                control={control}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row.house_style
                    : ""
                }
                render={({ field }) => (
                  <>
                    <Select
                      options={house_style_options}
                      {...field}
                      label="Select House Style"
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="house_style"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Nearest Metro</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="nearest_metro"
                id=""
                className="txtBox"
                {...register("nearest_metro", {
                  pattern: {
                    value: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
                    message: "Invalid value",
                  },
                  // required: "Required",
                  min: {
                    value: 0,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== ""
                    ? branch_row.nearest_metro
                    : ""
                }
              />
              <span className="dollar_label">mi</span>
              <ErrorMessage
                errors={errors}
                name="nearest_metro"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Lot Size</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="lot_size"
                id=""
                className="txtBox"
                {...register("lot_size", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  // required: "Required",
                  // min: {
                  //     value: 0,
                  //     message: "Value must be greater than 0."
                  // },
                })}
                defaultValue={
                  branch_row != undefined &&
                  branch_row != null &&
                  branch_row !== "" &&
                  branch_row?.lot_size > 0
                    ? branch_row.lot_size
                    : ""
                }
              />
              <span className="dollar_label">SF</span>
              <ErrorMessage
                errors={errors}
                name="lot_size"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Description</h6>
            <div className="form_blk">
              <textarea
                name="description"
                id="description"
                className="txtBox"
                {...register("description", { required: "Required" })}
              >
                {branch_row?.description}
              </textarea>
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <h6>Flexible Lease Term</h6>
            <ul className="total_count_price_listing full_wide_price_listing rent_listing_package justify-left">
              <li>
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="flexible_lease"
                    defaultValue="No"
                    id="No"
                    {...register("flexible_lease", { required: "Required" })}
                    defaultChecked={
                      branch_row != undefined &&
                      branch_row != null &&
                      branch_row !== "" &&
                      branch_row?.flexible_lease === "No"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="No">No</label>
                </div>
              </li>
              <li>
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="flexible_lease"
                    defaultValue="Yes"
                    id="Yes"
                    {...register("flexible_lease", { required: "Required" })}
                    defaultChecked={
                      branch_row != undefined &&
                      branch_row != null &&
                      branch_row !== "" &&
                      branch_row?.flexible_lease === "Yes"
                        ? true
                        : false
                    }
                  />
                  <label htmlFor="Yes">Yes</label>
                </div>
              </li>
            </ul>
            <ErrorMessage
              errors={errors}
              name="flexible_lease"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="amenities_blk_pro">
          <h5>
            <span>Amenities</span>
          </h5>
          <div className="form_row row flex">
            {amenities && amenities.length > 0
              ? amenities?.map((item, index) => {
                  return (
                    <div className="col-xs-4" key={index}>
                      <div className="form_blk">
                        <div className="lblBtn">
                          <input
                            type="checkbox"
                            name="amenities"
                            defaultValue={item.id}
                            id={toSluUrl(item.title)}
                            {...register("amenities")}
                            defaultChecked={
                              branch_row != undefined &&
                              branch_row != null &&
                              branch_row !== ""
                                ? watchAllFields?.amenities.includes(item.id)
                                : false
                            }
                          />
                          <label htmlFor={toSluUrl(item.title)}>
                            {item.title}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
            <ErrorMessage
              errors={errors}
              name="amenities"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>

          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Submit
            </button>
          ) : (
            <button
              type="submit"
              className="webBtn colorBtn"
              disabled={isFormProcessing === true ? "disabled" : ""}
            >
              {isFormProcessing === true ? (
                <i className="spinner"></i>
              ) : (
                "Submit"
              )}
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
