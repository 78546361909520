import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import {
  datepickerDateGet,
  format_amount,
  hasDatePassed,
  moment_new_date,
} from "../../../../helpers/api";
import { Controller } from "react-hook-form";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import toast, { Toaster } from "react-hot-toast";
import DigitalTimePicker from "../rent-listing-steps/digital-timepicker";
import moment from "moment";
import InputMask from "react-input-mask";
export default function UpdateRentListingPopup({
  hideUpdateListingPopup,
  listingPopup,
  handleUpdateListing,
  updateLoading,
}) {
  let hoa_fee_frequency_options = [
    {
      label: "Not Applicable",
      value: "Not Applicable",
    },
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Biweekly",
      value: "Biweekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Quarterly",
      value: "Quarterly",
    },
    {
      label: "Semiannually",
      value: "Semiannually",
    },
    {
      label: "Yearly",
      value: "Yearly",
    },
  ];
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  useEffect(() => {
    setValue("listing_type", listingPopup?.item?.listing_type);
    if (listingPopup?.item?.listing_type === "rent") {
      setValue(
        "available_date",
        datepickerDateGet(listingPopup?.item?.available_date)
      );
    }
    if (listingPopup?.item?.listing_type === "sale") {
      setValue("hoa_fee_freq", listingPopup?.item?.hoa_fee_freq);
      setValue("condo_fee_freq", listingPopup?.item?.condo_fee_freq);
    }
  }, [listingPopup]);
  const [openHouses, setOpenHouses] = useState({
    openHouses: [],
  });
  const onSubmit = (form_data) => {
    // ; return;
    let availabledateVal = new Date(form_data?.available_date);
    let available_date =
      parseInt(availabledateVal.getFullYear()) +
      "-" +
      parseInt(parseInt(availabledateVal.getMonth()) + 1) +
      "-" +
      availabledateVal.getDate();
    let newData = {
      ...form_data,
      available_date: available_date,
      open_houses: JSON.stringify(openHouses?.openHouses),
    };
    handleUpdateListing(newData, listingPopup?.item?.id);
  };

  useEffect(() => {
    if (
      listingPopup?.item !== undefined &&
      listingPopup?.item !== null &&
      listingPopup?.item !== "" &&
      listingPopup?.item?.open_houses_arr !== undefined
    ) {
      setOpenHouses({
        openHouses: listingPopup?.item?.open_houses_arr,
      });
    }
  }, [listingPopup?.item]);
  const isDateValid = (dateString) => {
    // // Assuming your date format is MM-DD-YYYY
    const enteredDate = moment(dateString).format("YYYY-MM-DD");
    return hasDatePassed(enteredDate);
  };
  const watchAllFields = watch();
  const checkHouseValidation = () => {
    // if (openHouses?.openHouses?.length > 0) {
    let validationError = 0;
    let dateValidationError = 0;
    openHouses?.openHouses.forEach((openHouse) => {
      if (
        openHouse?.date === undefined ||
        openHouse?.date === null ||
        openHouse?.date === "" ||
        openHouse?.start_time === undefined ||
        openHouse?.start_time === null ||
        openHouse?.start_time === "" ||
        openHouse?.end_time === undefined ||
        openHouse?.end_time === null ||
        openHouse?.end_time === ""
      ) {
        validationError = 1;
        return;
      }
      if (
        openHouse?.date !== undefined &&
        openHouse?.date !== null &&
        openHouse?.date !== "" &&
        isDateValid(openHouse?.date)
      ) {
        dateValidationError = 1; // or return { ...open_house } to keep the previous date
      }
    });

    if (validationError) {
      toast.error("Please fill all fields!");
      return;
    } else if (dateValidationError) {
      toast.error("Please write future dates for open houses!");
      return;
    } else {
      const formData = {
        ...watchAllFields,
        open_houses: JSON.stringify(openHouses?.openHouses),
      };
      onSubmit(formData);
    }

    // }
    // else {
    //     toast.error("Please add at-least one open house here to continue!"); return;
    // }
  };
  const handleAddNewHouse = () => {
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses.concat([
        { date: "", start_time: "", end_time: "" },
      ]),
    });
  };
  function handleRemoveHouse(idx, e) {
    e.preventDefault();
    setOpenHouses({
      ...openHouses,
      openHouses: openHouses.openHouses
        .slice(0, idx)
        .concat(
          openHouses.openHouses.slice(idx + 1, openHouses.openHouses.length)
        ),
    });
  }
  const handleHouseChange = (idx, evt, type = null) => {
    const newHouses = openHouses.openHouses.map((open_house, sidx) => {
      if (idx !== sidx) return open_house;
      return { ...open_house, [type]: evt };
    });
    setOpenHouses({ ...openHouses, openHouses: newHouses });
  };
  return (
    <>
      <section
        class={
          listingPopup?.item?.listing_type === "rent"
            ? "popup update_popup"
            : "popup update_popup sale_update_popup"
        }
        data-popup="create-new-listing"
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <Toaster position="bottom-right" />
              <div className="crosBtn" onClick={hideUpdateListingPopup}></div>
              {/* <h4>Update {listingPopup?.item?.listing_type} Listing:</h4> */}
              <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                {listingPopup?.item?.listing_type === "rent" ? (
                  <div className="form_row row">
                    <div className="col-xs-6">
                      <h6>Available Date</h6>
                      <div className="form_blk">
                        <Controller
                          control={control}
                          name="available_date"
                          defaultValue={datepickerDateGet(
                            listingPopup?.item?.available_date
                          )}
                          {...register("available_date", {
                            required: "Required",
                          })}
                          render={({ field }) => (
                            <DatePicker
                              className="txtBox"
                              placeholderText="_ _ / _ _ / _ _ _ _"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              minDate={new Date()}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="available_date"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Offer Deadline</h6>
                      <div className="form_blk">
                        <Controller
                          control={control}
                          name="offer_deadline"
                          defaultValue={
                            listingPopup?.item?.offer_deadline !== null &&
                            listingPopup?.item?.offer_deadline !== undefined &&
                            listingPopup?.item?.offer_deadline !== "" &&
                            listingPopup?.item?.offer_deadline !== "0000-00-00"
                              ? datepickerDateGet(
                                  listingPopup?.item?.offer_deadline
                                )
                              : ""
                          }
                          {...register("offer_deadline")}
                          render={({ field }) => (
                            <DatePicker
                              className="txtBox"
                              placeholderText="_ _ / _ _ / _ _ _ _"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              minDate={new Date()}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="offer_deadline"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>Lease Term</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="lease_term"
                          id="lease_term"
                          className="txtBox"
                          {...register("lease_term", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                            min: {
                              value: 1,
                              message: "Value must be greater than 0.",
                            },
                          })}
                          defaultValue={listingPopup?.item?.lease_term}
                        />
                        <span className="dollar_label">mth</span>
                        <ErrorMessage
                          errors={errors}
                          name="lease_term"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-xs-6">
                      <h6>Rent</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="rent_price"
                          id="rent_price"
                          className="txtBox"
                          {...register("rent_price", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                            min: {
                              value: 1,
                              message: "Value must be greater than 0.",
                            },
                          })}
                          defaultValue={listingPopup?.item?.rent_price}
                        />
                        <span className="dollar_label">$</span>
                        <ErrorMessage
                          errors={errors}
                          name="rent_price"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>Deposit</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="deposit_type"
                          id="deposit_type"
                          className="txtBox"
                          {...register("deposit_type", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                          defaultValue={listingPopup?.item?.deposit_type}
                        />
                        <span className="dollar_label">$</span>
                        <ErrorMessage
                          errors={errors}
                          name="deposit_type"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <div className="form_blk flex check_flex_box">
                        <div className="lblBtn">
                          <input
                            type="checkbox"
                            name="entire_home"
                            value="Yes"
                            id="entire_home"
                            {...register("entire_home")}
                            defaultChecked={
                              listingPopup?.item?.entire_home !== "" &&
                              listingPopup?.item?.entire_home !== null &&
                              listingPopup?.item?.entire_home !== undefined &&
                              listingPopup?.item?.entire_home === "Yes"
                                ? true
                                : false
                            }
                          />
                          <label htmlFor="entire_home">Entire home</label>
                        </div>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-12">
                      <h4 className="ofer_heading">Open House Dates:</h4>
                      {openHouses?.openHouses?.length > 0
                        ? openHouses?.openHouses?.map((openHouse, index) => {
                            return (
                              <div
                                className="form_row row add_tenants"
                                key={index}
                              >
                                <div
                                  className="closeBtn"
                                  onClick={(e) => handleRemoveHouse(index, e)}
                                ></div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DatePicker
                                      className="txtBox"
                                      placeholderText="_ _ / _ _ / _ _ _ _"
                                      onChange={(date) => {
                                        handleHouseChange(index, date, "date");
                                      }}
                                      selected={
                                        openHouse?.date !== "" &&
                                        openHouse?.date !== null &&
                                        openHouse?.date !== undefined
                                          ? datepickerDateGet(openHouse?.date)
                                          : null
                                      }
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DigitalTimePicker
                                      onChange={(e) =>
                                        handleHouseChange(
                                          index,
                                          e,
                                          "start_time"
                                        )
                                      }
                                      value={openHouse?.start_time}
                                      placeholder="Select Start Time"
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DigitalTimePicker
                                      onChange={(e) =>
                                        handleHouseChange(index, e, "end_time")
                                      }
                                      value={openHouse?.end_time}
                                      placeholder="Select End Time"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                      <div
                        className="add_more_tenats auto_plus_mrgn"
                        onClick={handleAddNewHouse}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form_row row flex">
                    <div className="col-xs-6">
                      <h6>Price</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="price"
                          id="price"
                          className="txtBox"
                          {...register("price", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                          defaultValue={listingPopup?.item?.price}
                        />
                        <span className="dollar_label">$</span>
                        <ErrorMessage
                          errors={errors}
                          name="price"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Offer Deadline</h6>
                      <div className="form_blk">
                        <Controller
                          control={control}
                          name="offer_deadline"
                          defaultValue={
                            listingPopup?.item?.offer_deadline !== null &&
                            listingPopup?.item?.offer_deadline !== undefined &&
                            listingPopup?.item?.offer_deadline !== "" &&
                            listingPopup?.item?.offer_deadline !== "0000-00-00"
                              ? datepickerDateGet(
                                  listingPopup?.item?.offer_deadline
                                )
                              : ""
                          }
                          {...register("offer_deadline")}
                          render={({ field }) => (
                            <DatePicker
                              className="txtBox"
                              placeholderText="_ _ / _ _ / _ _ _ _"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              minDate={new Date()}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="offer_deadline"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-6">
                      <h6>HOA Fee</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="hoa_fee"
                          id="hoa_fee"
                          className="txtBox"
                          {...register("hoa_fee", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                          defaultValue={listingPopup?.item?.hoa_fee}
                        />
                        <span className="dollar_label">$</span>
                        <ErrorMessage
                          errors={errors}
                          name="hoa_fee"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>HOA Fee Frequency</h6>
                      <div className="form_blk selectTxtGrp">
                        <Controller
                          {...register("hoa_fee_freq", {
                            required: "Required",
                          })}
                          name="hoa_fee_freq"
                          control={control}
                          defaultValue={listingPopup?.item?.hoa_fee_freq}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <>
                              <Select
                                options={hoa_fee_frequency_options}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                                label="Select"
                                value={hoa_fee_frequency_options.find(
                                  (c) => c.value === value
                                )}
                              />
                            </>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="hoa_fee_freq"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Condo Fee</h6>
                      <div className="form_blk deposit_form_blk">
                        <input
                          type="text"
                          name="condo_fee"
                          id="condo_fee"
                          className="txtBox"
                          {...register("condo_fee", {
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Invalid value",
                            },
                            required: "Required",
                          })}
                          defaultValue={listingPopup?.item?.condo_fee}
                        />
                        <span className="dollar_label">$</span>
                        <ErrorMessage
                          errors={errors}
                          name="condo_fee"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-xs-6">
                      <h6>Condo Fee Frequency</h6>
                      <div className="form_blk selectTxtGrp">
                        <Controller
                          {...register("condo_fee_freq", {
                            required: "Required",
                          })}
                          name="condo_fee_freq"
                          control={control}
                          defaultValue={listingPopup?.item?.condo_fee_freq}
                          render={({
                            field: { onChange, value, ref, name },
                          }) => (
                            <>
                              <Select
                                options={hoa_fee_frequency_options}
                                onChange={(val) => {
                                  onChange(val.value);
                                }}
                                label="Select"
                                value={hoa_fee_frequency_options.find(
                                  (c) => c.value === value
                                )}
                              />
                            </>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="condo_fee_freq"
                          render={({ message }) => (
                            <p className="error">
                              <i className="fi-warning"></i> {message}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-12">
                      <h4 className="ofer_heading">Open House Dates:</h4>
                      {openHouses?.openHouses?.length > 0
                        ? openHouses?.openHouses?.map((openHouse, index) => {
                            return (
                              <div
                                className="form_row row add_tenants"
                                key={index}
                              >
                                <div
                                  className="closeBtn"
                                  onClick={(e) => handleRemoveHouse(index, e)}
                                ></div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DatePicker
                                      className="txtBox"
                                      placeholderText="_ _ / _ _ / _ _ _ _"
                                      onChange={(date) => {
                                        handleHouseChange(index, date, "date");
                                      }}
                                      selected={
                                        openHouse?.date !== "" &&
                                        openHouse?.date !== null &&
                                        openHouse?.date !== undefined
                                          ? datepickerDateGet(openHouse?.date)
                                          : null
                                      }
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DigitalTimePicker
                                      onChange={(e) =>
                                        handleHouseChange(
                                          index,
                                          e,
                                          "start_time"
                                        )
                                      }
                                      value={openHouse?.start_time}
                                      placeholder="Select Start Time"
                                    />
                                  </div>
                                </div>
                                <div className="col-xs-4">
                                  <div className="form_blk">
                                    <DigitalTimePicker
                                      onChange={(e) =>
                                        handleHouseChange(index, e, "end_time")
                                      }
                                      value={openHouse?.end_time}
                                      placeholder="Select End Time"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                      <div
                        className="add_more_tenats auto_plus_mrgn"
                        onClick={handleAddNewHouse}
                      >
                        <i className="fa fa-plus"></i>
                      </div>
                    </div>
                  </div>
                )}
                <div className="cmnBtn text-center bTn">
                  {listingPopup?.item?.listing_type === "rent" ? (
                    watchAllFields?.available_date === "" ||
                    watchAllFields?.available_date === undefined ||
                    watchAllFields?.available_date === null ||
                    watchAllFields?.lease_term === "" ||
                    watchAllFields?.lease_term === undefined ||
                    watchAllFields?.lease_term === null ||
                    watchAllFields?.rent_price === "" ||
                    watchAllFields?.rent_price === undefined ||
                    watchAllFields?.rent_price === null ||
                    watchAllFields?.deposit_type === "" ||
                    watchAllFields?.deposit_type === undefined ||
                    watchAllFields?.deposit_type === null ? (
                      <button
                        type="button"
                        className="webBtn colorBtn nextBtn"
                        onClick={() => {
                          trigger();
                        }}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={checkHouseValidation}
                        className="webBtn"
                        disabled={updateLoading ? true : false}
                      >
                        Update
                        {updateLoading ? <i className="spinner"></i> : ""}
                      </button>
                    )
                  ) : (
                    <button
                      type="submit"
                      className="webBtn"
                      disabled={updateLoading ? true : false}
                    >
                      Update {updateLoading ? <i className="spinner"></i> : ""}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
