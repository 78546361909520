import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SINGLE_FLOOR_PLAN_CONTENT,
  FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS,
  FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED,
  SINGLE_FLOOR_PLAN_MESSAGE,
  SINGLE_FLOOR_PLAN_MESSAGE_SUCCESS,
  SINGLE_FLOOR_PLAN_MESSAGE_FAILED,
  UPLOAD_FLOOR_MESSAGE,
  UPLOAD_FLOOR_MESSAGE_SUCCESS,
  UPLOAD_FLOOR_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchSingleFloorPlan = (floor_id) => (dispatch) => {
  dispatch({
    type: FETCH_SINGLE_FLOOR_PLAN_CONTENT,
    payload: null,
  });
  http
    .get("floor-plan/" + floor_id)
    .then(({ data }) => {
      dispatch({
        type: FETCH_SINGLE_FLOOR_PLAN_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SINGLE_FLOOR_PLAN_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const postFloorData = (formData, floor_id) => (dispatch) => {
  let postUrl = "";
  if (floor_id != undefined && floor_id !== null) {
    postUrl = "edit-floor-plan/" + floor_id;
  } else {
    postUrl = "add-floor-plan";
  }
  dispatch({
    type: UPLOAD_FLOOR_MESSAGE,
    payload: null,
  });
  http
    .post(postUrl, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/floor-plans");
        }, 2000);
        dispatch({
          type: UPLOAD_FLOOR_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: UPLOAD_FLOOR_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_FLOOR_MESSAGE_FAILED,
        payload: error,
      });
    });
};
