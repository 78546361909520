import React from "react";
import { NavLink } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

export default function ListingStep3({
  step,
  changeStep,
  prices,
  handleAddNewPrice,
  handleRemovePrice,
  handlePriceChange,
  isFormProcessing,
  register,
  errors,
  control,
  isValid,
}) {
  return (
    <>
      <fieldset>
        <div className="listing_c_flex_c">
          <div className=" form_row row flex ">
            <div className="col-xs-12">
              <div className="listing_c_flex flex head_flex">
                <div className="col">
                  <h6>Price</h6>
                </div>
                <div className="col">
                  <h6>Term</h6>
                </div>
              </div>
              {prices != undefined &&
              prices !== null &&
              prices?.prices?.length > 0
                ? prices.prices?.map((price, index) => {
                    return (
                      <>
                        {index !== 0 ? (
                          <div className="add_more_term_c">
                            <div
                              className="closeBtn"
                              onClick={() => handleRemovePrice(index)}
                            ></div>
                            <div className="listing_c_flex flex">
                              <div className="col">
                                <div className="form_blk">
                                  <input
                                    type="text"
                                    className="txtBox"
                                    name="price"
                                    onChange={(e) =>
                                      handlePriceChange(index, e)
                                    }
                                    defaultValue={price.price}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="form_blk">
                                  <input
                                    type="text"
                                    className="txtBox"
                                    name="term"
                                    onChange={(e) =>
                                      handlePriceChange(index, e)
                                    }
                                    defaultValue={price.term}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="listing_c_flex flex">
                            <div className="col">
                              <div className="form_blk">
                                <input
                                  type="text"
                                  className="txtBox"
                                  name="price"
                                  onChange={(e) => handlePriceChange(index, e)}
                                  defaultValue={price.price}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="form_blk">
                                <input
                                  type="text"
                                  className="txtBox"
                                  name="term"
                                  onChange={(e) => handlePriceChange(index, e)}
                                  defaultValue={price.term}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                : ""}
              <div className="gap_line_divide"></div>
              <div className="bTn justify_center_flex">
                <NavLink
                  to="#!"
                  className="webBtn mini_web_btn"
                  onClick={handleAddNewPrice}
                >
                  <i className="fa fa-plus"></i>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          <button
            type="submit"
            className="webBtn colorBtn"
            disabled={isFormProcessing === true ? "disabled" : ""}
          >
            {isFormProcessing === true ? <i className="spinner"></i> : "Save"}
          </button>
        </div>
      </fieldset>
    </>
  );
}
