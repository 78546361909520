import React, { useState } from "react";
import FloorPopup from "./floorPopup";
import { NavLink } from "react-router-dom";
import { format_number } from "../../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function FloorLst({
  floor_plan,
  handleDelete,
  popup,
  showPopup,
  closePopup,
}) {
  const deleteItem = (floor_id) => {
    handleDelete(floor_id);
  };

  return (
    <>
      <tr>
        <td className="hide_cell_hyp">
          {" "}
          <span className="mini-show-cell">Branch</span>
          {floor_plan.branch}
        </td>

        <td>
          <span className="mini-show-cell">Floor Plan</span>
          {floor_plan.floor_plan}
        </td>
        <td>
          <span className="mini-show-cell">Size</span>
          <div className="specific_flex_span">
            <span>
              {floor_plan?.studio === "yes"
                ? "Studio"
                : parseInt(floor_plan?.bedrooms) + " Bed"}
            </span>
            {
              // floor_plan?.full_bathrooms && floor_plan?.half_bathrooms ?

              <span>
                {parseInt(floor_plan?.full_bathrooms) +
                  parseInt(floor_plan?.half_bathrooms)}{" "}
                Bath
              </span>
              // :
              // ""
            }
            {
              // floor_plan?.sq_feet ?

              <span>{format_number(parseInt(floor_plan?.sq_feet))} SF</span>
              // :
              // ""
            }
          </div>
        </td>
        <td>
          <span className="mini-show-cell">Images</span>
          <div className="bTn">
            <a
              href="javascript:void(0)"
              className="small_web_btn full_pop_btn"
              data-popup="gallery-pop"
              onClick={() => showPopup(floor_plan)}
            >
              <i className="fa fa-th"></i> Gallery
            </a>
          </div>
        </td>
        {mem_permissions === "admin" || mem_permissions === "power" ? (
          <td className="flex_action_user_dash hide_cell_hyp">
            <div className="action_drop _dropDown">
              <div className="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul className="_dropCnt dropLst">
                <li>
                  <NavLink to={"/edit-floor-plan/" + floor_plan.id}>
                    Edit
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="#!"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        )
                      )
                        deleteItem(floor_plan.id);
                    }}
                    className=""
                  >
                    Delete
                  </NavLink>
                </li>
              </ul>
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
      {popup.show === true &&
      popup.item != undefined &&
      popup.item != null &&
      popup.item != "" ? (
        <FloorPopup
          title={popup.item.floor_plan}
          address={popup.item.address}
          images={popup.item.images}
          closePopup={closePopup}
          floor_plan={popup.item}
          floor_plan_item={true}
        />
      ) : (
        ""
      )}
    </>
  );
}
