import React from 'react'
import { NavLink } from 'react-router-dom';

export default function PropertyStep1({ step, handleTypeChange, property_type, changeStep, property_row }) {
    return <>
        <fieldset>

            <div className="form_row row flex property_form_commercial_flex radioBtns">
                <div className="col-xs-6">
                    <div className="lblBtn">
                        <input type="radio" id="single" name="list-type" value="single" checked={property_type === 'single' ? "checked" : ""} onChange={() => handleTypeChange('single')} />
                        <label htmlFor="single" className={property_type === 'single' ? "dim_btn active color_dim" : "dim_btn"}>Single</label>
                    </div>
                </div>
                <div className="col-xs-6">
                    <div className="lblBtn">
                        <input type="radio" id="bulk" name="list-type" value="bulk" checked={property_type === 'bulk' ? "checked" : ""} onChange={() => handleTypeChange('bulk')} />
                        <label htmlFor="bulk" className={property_type === 'bulk' ? "dim_btn active color_dim" : "dim_btn"}>Bulk</label>
                    </div>
                </div>
                <div className="col-xs-12">
                    <div className="gap_line_divide hide_cell_divide_line"></div>
                    <div className="image_upload_blk uploadImg default_btn_prop" data-upload="gallery_image">
                        <div className="small_upload_icon" >
                            <img src="/images/dashboard/icons/upload.png" alt="" />
                        </div>
                        <h3>Upload</h3>
                    </div>
                </div>
            </div>
            <div className="cmnBtn text-center">
                <NavLink to="/properties" className="webBtn cancleBtn">Cancel</NavLink>
                <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)}>Next</button>
            </div>
        </fieldset>
    </>
}
