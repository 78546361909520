import React from 'react'
import { Link } from "react-router-dom";

export default function Logo({ src, name }) {
    return (
        <>
            <div className="logo">
                <Link to="/">
                    <img src={src} alt={name} />
                </Link>
            </div>
        </>
    )
}
