import React from 'react'

export default function PropertySort(props) {
    const {ToggleListView , ToggleGridView , listView} = props;
    return (
        <>
            <div className="sorted_filter flex cell_status_offer_cell_end">
                {/* <div className="sorted_heading">
                    <i className="fa fa-align-left"></i>
                    <span>Sort by :</span>
                </div> */}
                <div className="sorting-options">
                    <a href="javascript:void(0)" className={(listView == "list") ? "change-view-btn lde active-view-btn" : "change-view-btn lde"} onClick={ToggleListView}><i className="fa fa-th-list"></i></a>
                    <a href="javascript:void(0)" className={(listView == "list") ? "change-view-btn" : "change-view-btn active-view-btn"} onClick={ToggleGridView}><i className="fa fa-th-large"></i></a>
                </div>
            </div>
        </>
    )
}
