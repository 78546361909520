import React, { useEffect } from "react";
import { getServerImage } from '../../helpers/api';

import { fetchBlog } from "../../states/actions/fetchBlog";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import BlogGrid from './section/blog-grid';
export default function Blog() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchBlog.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchBlog.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchBlog());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const blog = content;

    return (
        <>
            <BlogGrid data={blog.posts} />

        </>
    )
}
