import React from "react";
import PropertyGallery from "./section/gallery";
import PropertyHeader from "./section/header";
import PropertyDetails from "./section/details";
import PropertyReviews from "./section/reviews";
import PropertyLocation from "./section/locations";
import ReviewPopup from "./section/reviewPopup";
import ListingLogs from "./section/logs";
import PropertyBlk from "../../common/propertyBlk";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function NormalUserProperty({
  address,
  property,
  branch,
  popup,
  showPopup,
  closePopup,
  member,
  handleDelete,
  showReviewPopup,
  reviewPopup,
  closeReviewPopup,
  handleListingReview,
  reviewLoading,
  property_url,
  property_page = false,
  owner = false,
  showUpdateListingPopup,
  showExtendListingPopup,
  handleListingDelete,
  properties,
  open_houses,
  walkscore,
  top_listings,
}) {
  const settings = {
    autoplay: false,
    dots: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(top_listings);
  return (
    <>
      <div
        index=""
        className={
          popup.show === true &&
          popup.item != undefined &&
          popup.item != null &&
          popup.item != ""
            ? "floor_popup"
            : ""
        }
      >
        <section className="property_detail_main_sr">
          <div className="contain">
            <PropertyHeader
              address={address}
              floor_plan_name={property?.title}
              item={property}
              member={member}
              handleDelete={handleDelete}
              property_url={property_url}
              property={property}
              owner={owner}
              showUpdateListingPopup={showUpdateListingPopup}
              showExtendListingPopup={showExtendListingPopup}
              handleListingDelete={handleListingDelete}
              listing_row={property?.listing}
              properties={properties}
            />
            <div className="search-detail-grid">
              <PropertyGallery
                images={property?.images}
                popup={popup}
                closePopup={closePopup}
                showPopup={showPopup}
                property={property}
                thumbnail={property?.imageThumbnail}
              />
              <div className="result_detail_blk">
                <PropertyDetails
                  property={property}
                  listing_row={property?.listing}
                  member={member}
                  property_page={property_page}
                  owner={owner}
                  open_houses={open_houses}
                  walkscore={walkscore}
                  property_score={property?.property_score}
                />

                <PropertyReviews
                  listing={true}
                  property={property}
                  member={member}
                  showReviewPopup={showReviewPopup}
                />
                {property?.log_history !== undefined &&
                property?.log_history !== null &&
                property?.log_history !== "" &&
                property?.log_history?.length > 0 ? (
                  <>
                    <ListingLogs
                      logs={property?.logs}
                      extensions={property?.extensions}
                      shiftings={property?.shiftings}
                      type={property?.listing?.listing_type}
                      owner={owner}
                      log_history={property?.log_history}
                    />
                    <hr />
                  </>
                ) : (
                  ""
                )}
                {property?.formatted_address !== "" &&
                property?.formatted_address !== null &&
                property?.formatted_address !== undefined ? (
                  <>
                    <PropertyLocation address={property?.formatted_address} />
                  </>
                ) : (
                  ""
                )}
                {top_listings?.length > 0 ? (
                  <>
                    <div className="gap_line_divide"></div>
                    <hr />
                    <div className="gap_line_divide"></div>
                    <h3 className="detail_heading">Recommendations</h3>
                    <div className="flex propert_list grid_view recommendation_slider">
                      <Slider {...settings}>
                        {top_listings?.map((top_listing, index) => {
                          return <PropertyBlk data={top_listing} key={index} />;
                        })}
                      </Slider>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {reviewPopup?.show === true &&
        reviewPopup?.item !== undefined &&
        reviewPopup?.item?.id > 0 ? (
          <ReviewPopup
            closeReviewPopup={closeReviewPopup}
            item={reviewPopup?.item}
            member={member}
            handleListingReview={handleListingReview}
            reviewLoading={reviewLoading}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
