import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { useForm } from "react-hook-form";
import UploadLeasePdf from "../create-lease-sections/upload-pdf";
import toast from "react-hot-toast";

export default function DocumentForm({ handleDocumentForm, isFormProcessing, branches, document_row }) {
    const {
        register,
        trigger,
        setValue,
        handleSubmit,
        control,
        watch,
        formState: { errors, isValid },
    } = useForm({ mode: "all" });
    useEffect(() => {
        if (document_row?.id > 0) {
            setValue('branch_id', document_row?.branch_id)
            setValue('name', document_row?.name)
            setDocumentFile({ ...documentFile, file: document_row?.file, file_name: document_row?.file_name })
        }
    }, [document_row]);

    const [documentFile, setDocumentFile] = useState({
        loading: false,
        file: null,
        file_name: null
    });
    const onSubmit = (frmData) => {
        if (documentFile?.file === null) {
            toast.error("Please upload PDF to continue!");
            return;
        }
        frmData = { ...frmData, file: documentFile?.file, file_name: documentFile?.file_name }
        handleDocumentForm(frmData)
    }
    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div class="heighlight_fieldset">
                <div class="form_row row flex">
                    <div class="col-xs-6">
                        <h6>Branch</h6>
                        <div class="form_blk">
                            <Controller
                                {...register("branch_id", { required: "Required" })}
                                name="branch_id"
                                control={control}
                                render={({ field: { onChange, value, ref, name } }) => (
                                    <>
                                        <Select
                                            options={branches}
                                            onChange={(val) => {
                                                onChange(val.value);
                                            }}
                                            label="Select Branch"
                                            value={branches.filter(function (option) {
                                                return option.value === value;
                                            })}
                                        />
                                    </>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="branch_id"
                                render={({ message }) => (
                                    <p className="error">
                                        <i className="fi-warning"></i> {message}
                                    </p>
                                )}
                            />
                        </div>
                    </div>
                    <div class="col-xs-6 cell_mrgn_top">
                        <h6>Document Name</h6>
                        <div class="form_blk">
                            <input type="text" name="" id="" class="txtBox" {...register("name", { required: "Required" })} />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => (
                                    <p className="error">
                                        <i className="fi-warning"></i> {message}
                                    </p>
                                )}
                            />
                        </div>
                    </div>

                    <UploadLeasePdf setLeasePdf={setDocumentFile} leasePdf={documentFile} type="document" />
                </div>
                <div class="cmnBtn text-center">
                    <button type="submit" class="webBtn colorBtn" disabled={isFormProcessing || documentFile?.loading}>Submit {isFormProcessing || documentFile?.loading ? <i className="spinner"></i> : ""}</button>
                </div>
            </div>
        </form>
    </>;
}
