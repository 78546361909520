import React from "react";
import PaymentBlk from "./paymentBlk";


export default function LeasePayments({ payments, setPaymentPopup, isLandlord }) {
    return <>
        <div id="leases" className="tab-pane fade a active in">

            <div className="blk payment_table blockLst cell_block_cell">
                <table>
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th className="hide_cell_hyp">Tenant</th>
                            <th>Balance</th>
                            <th width="10%">Due Date</th>
                            {/* <th></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            payments !== undefined && payments?.length > 0 ?
                                payments?.map((payment, index) => {
                                    return (
                                        <PaymentBlk setPaymentPopup={setPaymentPopup} payment={payment} key={index} isLandlord={isLandlord} />
                                    )
                                })
                                :
                                <td colSpan="4">
                                    <div className="alert alert-danger">No leases found.</div>
                                </td>
                        }


                    </tbody>
                </table>
            </div>
        </div>
    </>
}
