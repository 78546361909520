import { useDispatch } from "react-redux";
import { getMonthName } from "../../../../helpers/api";
import CardBrandLogo from "./card-brand-logo";

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function CreditCardLst({
  item,
  deletePaymentMethod,
  isPaymentMethodDeleted,
}) {
  const dispatch = useDispatch();
  const onDelete = (payment_method_id) => {
    dispatch(
      deletePaymentMethod({
        payment_method_id: payment_method_id,
      })
    );
  };
  const [popup, setPopup] = useState({
    show: false,
    payment_method_id: null,
  });
  return (
    <div className="lst">
      <ul>
        <li>
          <h6>Name On Card</h6>
          <p>{item?.card_holder_name}</p>
        </li>
        <li>
          <h6>Card Number</h6>
          <div className="card_brand">
            <CardBrandLogo card_brand={item?.card_brand} />
            <p>******{item?.card_number}</p>
          </div>
        </li>
        <li>
          <h6>Month</h6>
          <p>{getMonthName(item?.card_exp_month)}</p>
        </li>
        <li>
          <h6>Year</h6>
          <p>{item?.card_exp_year}</p>
        </li>
        {/* <li>
                    <h6>Status</h6>
                    <span className="badge green">Default</span>
                </li> */}
        <li className="bTn">
          <NavLink
            to={"/edit-card-details/" + item?.encoded_id}
            className="webBtn labelBtn blue-color"
          >
            Edit
          </NavLink>
          <a
            href="javascript:void(0)"
            onclick="return confirm('Are you sure?');"
            className="webBtn labelBtn red-color"
            onClick={() =>
              setPopup({ show: true, payment_method_id: item?.encoded_id })
            }
          >
            Delete
          </a>
        </li>
      </ul>
      {popup?.show === true &&
      popup?.payment_method_id !== null &&
      popup?.payment_method_id !== undefined &&
      popup?.payment_method_id !== "" ? (
        <div index className="popup small_popup">
          <div className="tableDv">
            <div className="tableCell">
              <ToastContainer />
              <div className="_inner">
                <div
                  className="crosBtn"
                  onClick={() =>
                    setPopup({ show: false, payment_method_id: null })
                  }
                ></div>
                <h4>Are you sure you want to delete this?</h4>
                <div className="bTn text-center">
                  <NavLink
                    to="#!"
                    className="webBtn"
                    onClick={() => onDelete(popup?.payment_method_id)}
                    disabled={isPaymentMethodDeleted}
                  >
                    Yes{" "}
                    {isPaymentMethodDeleted ? <i className="spinner"></i> : ""}
                  </NavLink>
                  <NavLink
                    to="#!"
                    className="webBtn"
                    onClick={() =>
                      setPopup({ show: false, payment_method_id: null })
                    }
                  >
                    No
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
