import React, { useState } from "react";

const DigitalTimePicker = ({
  onChange,
  value,
  placeholder = "Select Time",
}) => {
  const [selectedTime, setSelectedTime] = useState(value || "");

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setSelectedTime(newTime);
    onChange(newTime);
  };

  // const generateOptions = () => {
  //     const options = [];
  //     for (let h = 1; h <= 12; h++) {
  //         for (let m = 0; m < 60; m += 30) {
  //             const hour = String(h).padStart(2, '0');
  //             const minute = String(m).padStart(2, '0');
  //             options.push(`${hour}:${minute} AM`);
  //         }
  //     }
  //     for (let h = 1; h <= 12; h++) {
  //         for (let m = 0; m < 60; m += 30) {
  //             const hour = String(h).padStart(2, '0');
  //             const minute = String(m).padStart(2, '0');
  //             options.push(`${hour}:${minute} PM`);
  //         }
  //     }
  //     return options;
  // };

  return (
    <select value={selectedTime} onChange={handleTimeChange} className="txtBox">
      <option
        value=""
        selected={
          value === undefined || value === null || value === "" ? true : false
        }
      >
        {placeholder}
      </option>
      {/* {generateOptions().map((time) => (
                <option key={time} value={time} selected={value !== undefined && value !== null && value !== '' && value === time ? true : false}>
                    {time}
                </option>
            ))} */}
      {/* <option value="">Select Start Time</option> */}
      <option value="01:00 AM">1:00 AM</option>
      <option value="01:30 AM">1:30 AM</option>
      <option value="02:00 AM">2:00 AM</option>
      <option value="02:30 AM">2:30 AM</option>
      <option value="03:00 AM">3:00 AM</option>
      <option value="03:30 AM">3:30 AM</option>
      <option value="04:00 AM">4:00 AM</option>
      <option value="04:30 AM">4:30 AM</option>
      <option value="05:00 AM">5:00 AM</option>
      <option value="05:30 AM">5:30 AM</option>
      <option value="06:00 AM">6:00 AM</option>
      <option value="06:30 AM">6:30 AM</option>
      <option value="07:00 AM">7:00 AM</option>
      <option value="07:30 AM">7:30 AM</option>
      <option value="08:00 AM">8:00 AM</option>
      <option value="08:30 AM">8:30 AM</option>
      <option value="09:00 AM">9:00 AM</option>
      <option value="09:30 AM">9:30 AM</option>
      <option value="10:00 AM">10:00 AM</option>
      <option value="10:30 AM">10:30 AM</option>
      <option value="11:00 AM">11:00 AM</option>
      <option value="11:30 AM">11:30 AM</option>
      <option value="12:00 PM">12:00 PM</option>
      <option value="12:30 PM">12:30 PM</option>
      <option value="01:00 PM">1:00 PM</option>
      <option value="01:30 PM">1:30 PM</option>
      <option value="02:00 PM">2:00 PM</option>
      <option value="02:30 PM">2:30 PM</option>
      <option value="03:00 PM">3:00 PM</option>
      <option value="03:30 PM">3:30 PM</option>
      <option value="04:00 PM">4:00 PM</option>
      <option value="04:30 PM">4:30 PM</option>
      <option value="05:00 PM">5:00 PM</option>
      <option value="05:30 PM">5:30 PM</option>
      <option value="06:00 PM">6:00 PM</option>
      <option value="06:30 PM">6:30 PM</option>
      <option value="07:00 PM">7:00 PM</option>
      <option value="07:30 PM">7:30 PM</option>
      <option value="08:00 PM">8:00 PM</option>
      <option value="08:30 PM">8:30 PM</option>
      <option value="09:00 PM">9:00 PM</option>
      <option value="09:30 PM">9:30 PM</option>
      <option value="10:00 PM">10:00 PM</option>
      <option value="10:30 PM">10:30 PM</option>
      <option value="11:00 PM">11:00 PM</option>
      <option value="11:30 PM">11:30 PM</option>
      <option value="12:00 AM">12:00 AM</option>
      <option value="12:30 AM">12:30 AM</option>
    </select>
  );
};

export default DigitalTimePicker;
