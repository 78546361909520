import React, { useEffect, useRef, useState } from "react";
import { consoleLog } from "../../helpers/api";

export default function PayPal(props) {
  let price = props.price;
  let priceMonth = props?.priceMonth;
  let total_price = 0;
  if (priceMonth !== 0 && priceMonth > 0) {
    total_price = parseFloat(price) * parseInt(priceMonth);
  } else {
    total_price = parseFloat(price);
  }

  const paypal = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: props?.description,
                amount: {
                  currency_code: "USD",
                  value: total_price,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          if (order.status === "COMPLETED") {
            props.getPaypalObject(order);
          }
        },
        onError: (err) => {
          consoleLog(err);
          props.errorPaypalOrder(err);
        },
        onCancel: function (data) {
          props.cancelPaypalOrder("cancel");
        },
      })
      .render(paypal.current);
  }, []);
  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
