import React from "react";
import Image from '../../common/image';

export default function WhyUs({ data }) {
    return (
        <>
            <section className="why_choose_us">
                <div className="contain">
                    <div className="sec_heading text-center">
                        <h2>{data.heading}</h2>
                    </div>
                    <div className="flex">
                        {data.why_us.map((val, index) => {
                            return(
                            <div className="col" key={index}>
                                <div className="inner">
                                    <div className="small_icon">
                                        <Image src={val.image} alt={val.sub_heagin} />
                                    </div>
                                    <div className="cntnt">
                                        <h4>{val.sub_heading}</h4>
                                        <p>{val.text}</p>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                        
                    </div>
                </div>
            </section>
        </>
    )
}
