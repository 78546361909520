import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import { NavLink } from "react-router-dom";
export default function SaleStep1({
  step,
  changeStep,
  register,
  errors,
  isValid,
  control,
  watchAllFields,
  trigger,
  listing_row,
  property_row,
  offer_row,
}) {
  const [contingenciesError, setContingenciesError] = useState(false);
  return (
    <>
      <fieldset>
        <div className="medium_blk">
          {/* <h4 className="ofer_heading">Offering</h4> */}
          <div className="form_row row ">
            <div className="col-xs-6">
              <h6>Price</h6>
              <div className="form_blk deposit_form_blk">
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="txtBox"
                  {...register("price", {
                    required: "Required",
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Only numbers are allowed!",
                    },
                  })}
                  defaultValue={offer_row?.price}
                />
                <span className="dollar_label">$</span>
                <ErrorMessage
                  errors={errors}
                  name="price"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Down Payment</h6>
              <div className="form_blk deposit_form_blk">
                <input
                  type="text"
                  name="down_payment"
                  id="down_payment"
                  className="txtBox"
                  {...register("down_payment", {
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Only numbers are allowed!",
                    },
                  })}
                  defaultValue={offer_row?.down_payment}
                />
                <ErrorMessage
                  errors={errors}
                  name="down_payment"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
                <span className="dollar_label">$</span>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="col-xs-6">
              <h6>Offer Response Deadline</h6>
              <div className="form_blk dateFormBlk">
                <Controller
                  control={control}
                  className="txtBox"
                  name="offer_response_deadline"
                  {...register("offer_response_deadline")}
                  defaultValue={
                    offer_row?.offer_response_deadline !== null &&
                    offer_row?.offer_response_deadline !== undefined &&
                    offer_row?.offer_response_deadline !== "" &&
                    offer_row?.offer_response_deadline !== "TBD"
                      ? new Date(offer_row?.offer_response_deadline_edit)
                      : ""
                  }
                  render={({ field }) => (
                    <DatePicker
                      className="txtBox"
                      placeholderText="_ _ / _ _ / _ _ _ _"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      minDate={new Date()}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="offer_response_deadline"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="col-xs-6">
              <h6>Target Closing Date</h6>
              <div className="form_blk dateFormBlk">
                <Controller
                  control={control}
                  className="txtBox"
                  name="target_closing_date"
                  {...register("target_closing_date")}
                  defaultValue={
                    offer_row?.target_closing_date !== "" &&
                    offer_row?.target_closing_date !== undefined &&
                    offer_row?.target_closing_date !== null &&
                    offer_row?.target_closing_date !== "TBD"
                      ? new Date(offer_row?.target_closing_date_edit)
                      : ""
                  }
                  render={({ field }) => (
                    <DatePicker
                      className="txtBox"
                      placeholderText="_ _ / _ _ / _ _ _ _"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      minDate={new Date()}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="target_closing_date"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="col-xs-6">
              <div className="sale_label_header flex">
                <h6>Earnest Money</h6>
                <div className="settings_header">
                  <div className="info">
                    <strong></strong>
                    <div className="infoIn ckEditor">
                      <p>
                        Also known as a good faith deposit, this helps to
                        reassure the seller that you are serious about buying
                        the home. This is typically 1%-3% of the purchase price
                        and is usually non-refundable, but applied towards the
                        final purchase price. It can be part of the down
                        payment, closing costs, or put into an escrow account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form_blk deposit_form_blk">
                <input
                  type="text"
                  name="earnest_money"
                  id="earnest_money"
                  className="txtBox"
                  {...register("earnest_money", {
                    pattern: {
                      value: /^(0|[1-9]\d*)(\.\d+)?$/,
                      message: "Only numbers are allowed!",
                    },
                  })}
                  defaultValue={offer_row?.earnest_money}
                />
                <span className="dollar_label">$</span>
                <ErrorMessage
                  errors={errors}
                  name="earnest_money"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
            {/* <div className="col-xs-4"></div> */}
          </div>
          <div className="amenities_blk_pro">
            <h5>
              <span>Check any contingencies you'd like to include:</span>
            </h5>
            <div className="form_row row flex">
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Financing"
                      id="Financing"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Financing")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="Financing">Financing</label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              This specifies when the buyer must obtain
                              financing to purchase the home; if the buyer is
                              not able to secure a mortgage loan by the date
                              indicated, they can withdraw.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Title"
                      id="Title"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Title")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="Title">Title</label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              This allows the buyer the right to obtain a title
                              search and withdraw if the seller does not clear
                              any objections that may interfere with the title
                              transfer.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Home Inspection"
                      id="HomeInspection"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Home Inspection")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="HomeInspection">Home Inspection</label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              This specifies how long the buyer has to have the
                              property professional inspected to check for any
                              serious issues, i.e. leaky roof, structural
                              issues, etc. The buyer can withdraw if the seller
                              elects not to fix any of the issues found in a
                              home inspection.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Home Sale"
                      id="HomeSale"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Home Sale")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="HomeSale">Home Sale</label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              If a buyer is in the midst of selling their
                              current home and needs the sale to help finance
                              the purchase of a new home, this allows them to
                              withdraw if they cannot find a buyer for their
                              current home.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Appraisal"
                      id="Appraisal"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Appraisal")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="Appraisal">Appraisal</label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              This allows the buyer to withdraw if the property
                              does not appraise for at least the sales price.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-4">
                <div className="form_blk">
                  <div className="lblBtn">
                    <input
                      type="checkbox"
                      name="contingencies"
                      value="Homeowners Insurance"
                      id="HomeownersInsurance"
                      {...register("contingencies")}
                      defaultChecked={
                        offer_row?.contingencies !== "" &&
                        offer_row?.contingencies !== null &&
                        offer_row?.contingencies !== undefined
                          ? offer_row?.contingencies
                              .split(",")
                              .includes("Homeowners Insurance")
                            ? true
                            : false
                          : false
                      }
                    />
                    <div className="sale_label_header flex">
                      <label htmlFor="HomeownersInsurance">
                        Homeowners Insurance
                      </label>
                      <div className="settings_header">
                        <div className="info">
                          <strong></strong>
                          <div className="infoIn ckEditor">
                            <p>
                              This is to mandate that the buyer obtain
                              homeowners insurance; if homeowners insurance is
                              not obtained, either party can withdraw.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="amenities_blk_pro">
            <h5>
              <span>Check any seller concessions you'd like to include:</span>
            </h5>
            <div className="form_row row flex">
              <div className="col-xs-6">
                <div className="form_row row flex">
                  <div className="col-xs-12">
                    <div className="form_blk">
                      <div className="lblBtn">
                        <input
                          type="checkbox"
                          name="seller_concessions"
                          value="Home Improvements / Repairs"
                          id="HomeImprovementsRepairs"
                          {...register("seller_concessions")}
                          defaultChecked={
                            offer_row?.seller_concessions !== "" &&
                            offer_row?.seller_concessions !== null &&
                            offer_row?.seller_concessions !== undefined
                              ? offer_row?.seller_concessions
                                  .split(",")
                                  .includes("Home Improvements / Repairs")
                                ? true
                                : false
                              : false
                          }
                        />
                        <label htmlFor="HomeImprovementsRepairs">
                          Home Improvements / Repairs
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="form_blk">
                      <div className="lblBtn">
                        <input
                          type="checkbox"
                          name="seller_concessions"
                          value="Furniture"
                          id="Furniture"
                          {...register("seller_concessions")}
                          defaultChecked={
                            offer_row?.seller_concessions !== "" &&
                            offer_row?.seller_concessions !== null &&
                            offer_row?.seller_concessions !== undefined
                              ? offer_row?.seller_concessions
                                  .split(",")
                                  .includes("Furniture")
                                ? true
                                : false
                              : false
                          }
                        />
                        <label htmlFor="Furniture">Furniture</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-6">
                <div className="sale_label_header flex">
                  <h6>Closing Costs Paid by Seller</h6>
                  <div className="settings_header">
                    <div className="info">
                      <strong></strong>
                      <div className="infoIn ckEditor">
                        <p>
                          The average closing costs for a home is 2%-6% of the
                          total sales price. Closing costs cover things such as
                          application fees, appraisal, attorney fees, discount
                          points, credit reporting fees, title search, property
                          taxes, etc.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form_blk deposit_form_blk">
                  <input
                    type="text"
                    name="total_closing_cost_amount"
                    id="total_closing_cost_amount"
                    className="txtBox"
                    {...register("total_closing_cost_amount", {
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: "Only numbers are allowed!",
                      },
                    })}
                    defaultValue={offer_row?.total_closing_cost_amount}
                  />
                  <span className="dollar_label">$</span>
                  {contingenciesError === true &&
                  (watchAllFields?.total_closing_cost_amount === "" ||
                    watchAllFields?.total_closing_cost_amount === undefined ||
                    watchAllFields?.total_closing_cost_amount === null) ? (
                    <p className="error">
                      <i className="fi-warning"></i> Required
                    </p>
                  ) : (
                    <ErrorMessage
                      errors={errors}
                      name="total_closing_cost_amount"
                      render={({ message }) => (
                        <p className="error">
                          <i className="fi-warning"></i> {message}
                        </p>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <h6>
                  If you'd like to include an offer letter or note to the
                  seller, you may enter it in the textbox below:
                </h6>
                <textarea
                  className="txtBox"
                  name="offer_note"
                  id="offer_note"
                  {...register("offer_note")}
                >
                  {offer_row?.offer_note}
                </textarea>
                <ErrorMessage
                  errors={errors}
                  name="offer_note"
                  render={({ message }) => (
                    <p className="error">
                      <i className="fi-warning"></i> {message}
                    </p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="cmnBtn text-center offering_send_btns">
            <NavLink to="/" className="webBtn backBtn">
              Previous
            </NavLink>
            {!isValid ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => {
                  trigger();
                }}
              >
                Next
              </button>
            ) : (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(step + 1)}
                disabled={!isValid}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}
