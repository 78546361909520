import React from "react";

export default function PaymentMethodTab({ type, changeType, total_bank_account, total_credit_cards }) {
    return <>
        <div className="listing_tabs flex">
            <ul className="nav nav-tabs">
                <li className={type === 'bank' ? "active" : ""}><a href="javascript:void(0)" onClick={(e) => changeType(e, 'bank')}>
                    Bank Accounts <span>({total_bank_account})</span></a>
                </li>
                <li className={type === 'card' ? "active" : ""}><a href="javascript:void(0)" onClick={(e) => changeType(e, 'card')}>
                    Credit Cards <span>({total_credit_cards})</span></a>
                </li>

            </ul>
        </div>
    </>
}
