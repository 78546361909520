import React from "react";

export default function Vision({ data }) {
    return (
        <>
           <section className="mission_vision">
                <div className="contain">
                    <div className="flex">
                    {data?.map((val, index) => {
                        return(
                            <div className="col" key={index}>
                                <h3>{val.heading}</h3>
                                <p>{val.text}</p>
                            </div>
                        )
                    })}
                    </div>
                </div>
            </section> 
        </>
    )
}
