import React from 'react'

export default function SearchFormFaq() {
    return (
        <form action="" method="get" className="srchBar" autoComplete="off">
            <input type="text" name="query" id="" className="txtBox"  placeholder="Search for answers" />
            <button type="submit"><i className="fi-search"></i></button>
        </form>
    )
}
