import React from 'react'
import Image from '../../common/image';

export default function BackgroundCheck({ data }) {
    return (
        <>
            <section className="background_check_sec">
                <div className="contain">
                    <div className="flex">
                        <div className="col">
                            <div className="inner">
                                <div className="image">
                                    <Image src={data.block1.image} alt={data.block1.heading} />
                                </div>
                                <div className="cntnt">
                                    <h4>{data.block1.heading}</h4>
                                    <p>{data.block1.text}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="inner">
                                <div className="image">
                                    <Image src={data.block2.image} alt={data.block2.heading} />
                                </div>
                                <div className="cntnt">
                                    <h4>{data.block2.heading}</h4>
                                    <p>{data.block2.text}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
