import React, { useEffect } from "react";

import { fetchMission } from "../../states/actions/fetchMission";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import Intro from './section/intro';
import Vision from './section/vision';
import Goals from './section/goals';

export default function Mission() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchMission.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchMission.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchMission());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const mission = content;

    return (
        <>
            <Intro data={mission.intro} />
            <Vision data={mission.vision} />
            <Goals data={mission.goals} />
        </>
    )
}
