import React from 'react'
import { NavLink } from 'react-router-dom';

export default function ListingStep1({ changeStep, step, listing_type, handleTypeChange }) {
    return (
        <>
            <fieldset>

                <div className="form_row row flex">
                    <div className="col-xs-6">
                        <div className="lblBtn">
                            <input type="radio" className="hidden" id="single" name="list_type" value="single" checked={listing_type === 'single' ? "checked" : ""} onChange={() => handleTypeChange('single')} />
                            <label htmlFor="single" className={listing_type === 'single' ? "dim_btn active color_dim" : "dim_btn"}>Single</label>
                        </div>
                    </div>
                    <div className="col-xs-6 cell_mrgn_top">
                        <div className="lblBtn">
                            <input type="radio" className="hidden" id="bulk" name="list-type" value="bulk" checked={listing_type === 'bulk' ? "checked" : ""} onChange={() => handleTypeChange('bulk')} />
                            <label htmlFor="bulk" className={listing_type === 'bulk' ? "dim_btn active color_dim" : "dim_btn"}>Bulk</label>
                        </div>
                    </div>
                    <div className="col-xs-12">
                        <div className="gap_line_divide"></div>
                        <div className="image_upload_blk uploadImg default_btn_prop" data-upload="gallery_image">
                            <div className="small_upload_icon" >
                                <img src="/images/dashboard/icons/upload.png" alt="" />
                            </div>
                            <h3>Upload</h3>
                        </div>
                    </div>
                </div>
                <div className="cmnBtn text-center">
                    <NavLink to="/listing" className="webBtn cancleBtn">Cancel</NavLink>
                    <button type="button" className="webBtn colorBtn nextBtn" onClick={() => changeStep(step + 1)}>Next</button>
                </div>
            </fieldset>
        </>
    )
}
