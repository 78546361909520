import React from "react";
import { format_amount, getServerImage } from "../../../../helpers/api";
import Text from "../../../common/Text";
import { NavLink } from "react-router-dom";
const mem_permissions = localStorage.getItem("mem_permissions");
export default function CreditLst({ credit_row, handleDelete, type }) {
  return (
    <div class="lst">
      <ul>
        <li class="hide_cell_hyp">
          <h5 class="lease_heading_mini">Branch</h5>
          <div>{credit_row?.branch_name}</div>
        </li>
        <li>
          <h5 class="lease_heading_mini">Property</h5>
          <div>
            <Text string={credit_row?.property_address} />
          </div>
        </li>
        <li class="hide_cell_hyp">
          <h5 class="lease_heading_mini">Tenant</h5>
          <ul class="tenants_dps_flex credits_flex_com">
            {credit_row?.tenants?.length > 0
              ? credit_row?.tenants?.map((tenant, index) => {
                  return (
                    <li key={index}>
                      <div className="inner">
                        <div className="mini_icon">
                          <img
                            src={getServerImage(
                              "storage/members/",
                              tenant?.thumb
                            )}
                            alt={tenant?.name}
                          />
                        </div>
                        <div className="bio_info">
                          <div className="name">
                            <h4>{tenant?.name}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="ico">
                        <img
                          src={getServerImage(
                            "storage/members/",
                            tenant?.thumb
                          )}
                          alt={tenant?.name}
                        />
                      </div>
                    </li>
                  );
                })
              : ""}
          </ul>
        </li>
        <li>
          <h5 class="lease_heading_mini">Amount</h5>
          <div> {format_amount(credit_row?.amount)}</div>
        </li>
        <li>
          <h5 class="lease_heading_mini">Cycle</h5>
          <div>{credit_row?.cycle}</div>
        </li>

        <li class="hide_cell_hyp">
          <h5 class="lease_heading_mini">Notes</h5>
          <div class="settings_header credits_settings_header">
            <div class="info">
              <strong></strong>
              <div class="infoIn ckEditor">{credit_row?.notes}</div>
            </div>
          </div>
        </li>
        {mem_permissions === "admin" ? (
          <li class="bTn action_drop_lease">
            <div class="action_drop _dropDown">
              <div class="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul class="_dropCnt dropLst">
                {type === "pending" ? (
                  <>
                    <li>
                      <NavLink to={"/edit-credit/" + credit_row?.id}>
                        Edit
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="#!"
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          )
                            handleDelete(e, credit_row?.id);
                        }}
                        className=""
                      >
                        Delete
                      </NavLink>
                    </li>
                  </>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </li>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
}
