import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { FaInstagram, FaTiktok } from "react-icons/fa";

const ShareButtons = ({ property_url, sharePopup }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this article!",
          text: "I thought you’d like this article",
          url: window.location.href,
        });
      } catch (error) {
        console.error("Sharing failed:", error);
      }
    } else {
      alert("Sharing not supported on this browser");
    }
  };
  return (
    <ul className="icons" style={{ display: "flex", gap: "10px" }}>
      <FacebookShareButton url={property_url} quote={sharePopup?.popup_title}>
        <i className="fa fa-facebook-f"></i>
      </FacebookShareButton>
      <TwitterShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        via={sharePopup?.description}
      >
        <i className="fa fa-twitter"></i>
      </TwitterShareButton>

      <button type="button" onClick={handleShare}>
        <i className="fa fa-instagram"></i>
      </button>
      <button type="button" onClick={handleShare}>
        <FaTiktok size={20} />
      </button>

      {/* <EmailShareButton
        url={property_url}
        subject={sharePopup?.popup_title}
        body={sharePopup?.description}
      >
        <i className="fa fa-envelope"></i>
      </EmailShareButton>

      <WhatsappShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        separator=" "
      >
        <i className="fa fa-whatsapp">
        </i>
      </WhatsappShareButton>

      <LinkedinShareButton
        url={property_url}
        title={sharePopup?.popup_title}
        summary={sharePopup?.description}
        source="Loftus"
      >
        <i className="fa fa-linkedin">
        </i>
      </LinkedinShareButton> */}
    </ul>
  );
};

export default ShareButtons;
