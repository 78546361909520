import React, { useState } from "react";
import { fileValidation, uploadBillingCsv } from "../../../../../helpers/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Text from "../../../../common/Text";
export default function BulkUpload() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  async function handleUploadThumbnail(e) {
    setIsLoading(true);
    setIsError(null);
    const files = e.target.files[0];
    let valid = fileValidation(files);
    if (valid.error) {
      setIsLoading(false);
      toast.error(valid.error);
      setIsError(valid?.error);
    } else {
      let csv_file_response = await uploadBillingCsv(files);
      setIsLoading(false);
      document.getElementById("bulk_csv").value = "";
      if (csv_file_response.status === 1) {
        toast.success("Successfully inserted billing csv file!");
        setTimeout(() => {
          navigate("/billing");
        }, 2000);
      } else {
        if (csv_file_response?.msg) {
          toast.error(<Text string={csv_file_response?.msg} />);
          setIsError(<Text string={csv_file_response?.msg} />);
        } else {
          toast.error(<Text string={csv_file_response?.error} />);
          setIsError(<Text string={csv_file_response?.error} />);
        }
      }
    }
  }

  let thumbnail = null;
  function uploadThumbnail() {
    thumbnail.click();
  }
  return (
    <>
      {isLoading ? (
        <div className="loadingDisabled">
          <i className="spinner"></i>
        </div>
      ) : (
        ""
      )}
      <div className="col-xs-12">
        <div className="gap_line_divide"></div>
        {isError !== null ? (
          <div className="alert alert-danger">{isError}</div>
        ) : (
          ""
        )}

        <div
          className="image_upload_blk uploadImg default_btn_prop"
          data-upload="gallery_image"
          onClick={uploadThumbnail}
        >
          <div className="small_upload_icon">
            <img src="/images/dashboard/icons/upload.png" alt="" />
          </div>
          <h3>Upload</h3>
        </div>
      </div>
      <input
        type="file"
        name=""
        id="bulk_csv"
        className="uploadFile"
        data-upload="gallery_image"
        onChange={handleUploadThumbnail}
        accept=".csv, .xls, .xlsx, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ref={(file) => (thumbnail = file)}
      />
    </>
  );
}
