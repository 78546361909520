import {
    GET_PAYMENTS,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_FAILED,
    FETCH_PAYMENT_DETAILS,
    FETCH_PAYMENT_DETAILS_SUCCESS,
    FETCH_PAYMENT_DETAILS_FAILED,
    SAVE_PAYMENT,
    SAVE_PAYMENT_SUCCESS,
    SAVE_PAYMENT_FAILED,
    SAVE_CREDIT_CARD,
    SAVE_CREDIT_CARD_SUCCESS,
    SAVE_CREDIT_CARD_FAILED,
    CARD_STATUS_CHANGE,
    GET_PAYMENT_METHODS,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_FAILED,
    GET_PAYMENT_METHOD_DETAILS,
    GET_PAYMENT_METHOD_DETAILS_SUCCESS,
    GET_PAYMENT_METHOD_DETAILS_FAILED,
    DELETE_CREDIT_CARD,
    DELETE_CREDIT_CARD_SUCCESS,
    DELETE_CREDIT_CARD_FAILED,
    GET_FILTER_PAYMENTS,
    GET_FILTER_PAYMENTS_SUCCESS,
    GET_FILTER_PAYMENTS_FAILED
} from "../actions/actionTypes";
const initialState = {
    isFormProcessing: false,
    payments_arr: [],
    landlord_payments: [],
    isLoading: false,
    content: {},
    payment_details_content: {},
    isPaymentDetailsLoading: false,
    isPaymentFormProcessing: false,
    mem_payment_methods: [],
    isAddNewCardProcessing: false,
    isCardSaved: false,
    mem_payment_methods_ach: [],
    isPaymentMethodDeleted: false,
    isFilterLoading: false
};

export default function (state = initialState, { type, payload, notification }) {

    switch (type) {
        case SAVE_PAYMENT:
            return {
                ...state,
                isPaymentFormProcessing: true,
            };
        case SAVE_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentFormProcessing: false,
            };
        case SAVE_PAYMENT_FAILED:
            return {
                ...state,
                isPaymentFormProcessing: false,
                error: payload,
            };
        case DELETE_CREDIT_CARD:
            return {
                ...state,
                isPaymentMethodDeleted: true,
            };
        case DELETE_CREDIT_CARD_SUCCESS:
            return {
                ...state,
                isPaymentMethodDeleted: false,
            };
        case DELETE_CREDIT_CARD_FAILED:
            return {
                ...state,
                isPaymentMethodDeleted: false,
                error: payload,
            };
        case CARD_STATUS_CHANGE:
            return {
                ...state,
                isCardSaved: false
            }
        case SAVE_CREDIT_CARD:
            return {
                ...state,
                isAddNewCardProcessing: true,
            };
        case SAVE_CREDIT_CARD_SUCCESS:
            if (payload?.status) {
                if (payload?.ach) {
                    return {
                        ...state,
                        isAddNewCardProcessing: false,
                        mem_payment_methods_ach: payload?.payment_methods_ach,
                        isCardSaved: true
                    };
                }
                else {
                    return {
                        ...state,
                        isAddNewCardProcessing: false,
                        mem_payment_methods: payload?.payment_methods,
                        isCardSaved: true
                    };
                }

            }
            else {
                return {
                    ...state,
                    isAddNewCardProcessing: false,
                };
            }

        case SAVE_CREDIT_CARD_FAILED:
            return {
                ...state,
                isAddNewCardProcessing: false,
                error: payload,
            };
        case GET_PAYMENTS:
            return {
                ...state,
                isLoading: true,
            };
        case GET_FILTER_PAYMENTS:
            return {
                ...state,
                isFilterLoading: true
            };
        case GET_FILTER_PAYMENTS_SUCCESS:
            return {
                ...state,
                isFilterLoading: false,
                content: payload,
                payments_arr: payload?.payments,
                landlord_payments: payload?.landlord_payments
            };

        case GET_FILTER_PAYMENTS_FAILED:
            return {
                ...state,
                isFilterLoading: false,
                error: payload
            };
        case GET_PAYMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
                payments_arr: payload?.payments,
                landlord_payments: payload?.landlord_payments
            };

        case GET_PAYMENTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        case GET_PAYMENT_METHODS:
            return {
                ...state,
                isLoading: true,
                content: {}
            };
        case GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };

        case GET_PAYMENT_METHODS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            };
        case FETCH_PAYMENT_DETAILS:
            return {
                ...state,
                isPaymentDetailsLoading: true,
                payment_details_content: {},
            };
        case FETCH_PAYMENT_DETAILS_SUCCESS:
            return {
                ...state,
                isPaymentDetailsLoading: false,
                payment_details_content: payload,
                mem_payment_methods: payload?.payment_methods,
                mem_payment_methods_ach: payload?.payment_methods_ach,
            };
        case FETCH_PAYMENT_DETAILS_FAILED:
            return {
                ...state,
                isPaymentDetailsLoading: false,
                error: payload,
            };
        case GET_PAYMENT_METHOD_DETAILS:
            return {
                ...state,
                isLoading: true,
                content: {},
            };
        case GET_PAYMENT_METHOD_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                content: payload,
            };
        case GET_PAYMENT_METHOD_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload,
            };
        default:
            return state;
    }
}
