import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Toaster } from "react-hot-toast";
export default function AddNewTenant({ addNewTenant, setPopup, popup }) {
    const { register, trigger, setValue, handleSubmit, getValues, control, watch, formState: { errors, isValid } } = useForm({ mode: 'all' });
    return <>
        <section className="popup small_popup terminate_popup" data-popup="terminate">
            <div className="tableDv">
                <div className="tableCell">
                    <Toaster position="top-center" />
                    <div className="_inner">
                        <div className="crosBtn" onClick={() => setPopup(!popup)}></div>
                        <h4>New Tenant Information</h4>
                        <form onSubmit={handleSubmit(addNewTenant)} method="post">
                            <div className="txtGrp">
                                <h6>Name</h6>
                                <input type="text" className="txtBox" id="tenant_name" {...register("name", {
                                    pattern: {
                                        value: /^[a-zA-Z][a-zA-Z ]*$/,
                                        message: 'Invalid value',
                                    },
                                    required: "Required"
                                })} />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                />
                            </div>
                            <div className="txtGrp">
                                <h6>Email</h6>
                                <input type="text" className="txtBox" id="tenant_email" {...register("email", {
                                    required: "Required", pattern: {
                                        value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/,
                                        message: "Email format is not valid!"
                                    }
                                })} />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="webBtn colorBtn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>;
}
