import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { consoleLog } from "../../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function PromotionLst({ promotion, handleDelete, type }) {
  const [promo, setPromo] = useState(false);
  const togglePromo = () => {
    setPromo(!promo);
  };
  return (
    <>
      <div
        className="lst promotion_lst list_promotion_toggle_new"
        onClick={togglePromo}
      >
        <ul className="ul_list_promotion_toggle_new">
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Branch</h5>
            <div>{promotion?.branch_name}</div>
          </li>
          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Properties</h5>
            <div class="view_property_tooltip relative">
              <div class="property_mainti_tooltip scrollbar">
                <ul className="promotion_ul_flex">
                  {promotion?.properties?.map((property) => {
                    return (
                      <li>
                        <a href="?">{property?.address}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div class="view_pro">
                {promotion?.apply_all === "Yes"
                  ? "All"
                  : promotion?.properties?.length}
              </div>
            </div>
          </li>
          <li>
            <h5 className="lease_heading_mini">Start</h5>
            <div>{promotion?.start_date}</div>
          </li>
          {type === "expired" ? (
            <li>
              <h5 className="lease_heading_mini">Expired</h5>
              <div>{promotion?.deleted_at}</div>
            </li>
          ) : (
            <li>
              <h5 className="lease_heading_mini">End</h5>
              <div>{promotion?.end_date}</div>
            </li>
          )}

          <li className="hide_cell_hyp">
            <h5 className="lease_heading_mini">Code</h5>
            <div>{promotion?.promo_code}</div>
          </li>
        </ul>
        <div
          className={
            promo
              ? "blk_toggle_promotion_blk active"
              : "blk_toggle_promotion_blk"
          }
        >
          <div className="inner_blk">
            <ul>
              <li>
                <h5 className="lease_heading_mini">Expense</h5>
              </li>
              <li>
                <h5 className="lease_heading_mini _new_lst_wide">Discount</h5>
              </li>
              <li>
                <h5 className="lease_heading_mini">Cycles</h5>
              </li>
            </ul>
            {promotion?.prices?.map((promotion_price, index) => {
              return (
                <ul>
                  <li>
                    <div>{promotion_price?.expense}</div>
                  </li>
                  <li>
                    <div className="_new_lst_wide text-right">
                      {promotion_price?.type === "percent"
                        ? promotion_price?.discount + "%"
                        : "$" + promotion_price?.discount}
                    </div>
                  </li>
                  <li>
                    <div>
                      {promotion_price?.expense_type === "one_time"
                        ? "N/A"
                        : promotion_price?.allcycle === "yes"
                        ? "All Cycles"
                        : promotion_price?.cycle}
                    </div>
                  </li>
                </ul>
              );
            })}
          </div>
          {mem_permissions === "admin" ? (
            <div className="btnBlk text-right">
              {promotion?.promotion_status === "pending" ? (
                <NavLink
                  className="webBtn colorBtn"
                  to={"/edit-promotion/" + promotion?.id}
                >
                  Edit
                </NavLink>
              ) : (
                ""
              )}
              {promotion?.is_deleted === 0 ? (
                <NavLink
                  to="#!"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Are you sure you wish to delete this item?"
                      )
                    )
                      handleDelete(e, promotion?.id);
                  }}
                  className="webBtn"
                >
                  Delete
                </NavLink>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
