import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_BRANCHES_CONTENT,
  FETCH_BRANCHES_CONTENT_SUCCESS,
  FETCH_BRANCHES_CONTENT_FAILED,
  BRANCH_DELETE__MESSAGE,
  BRANCH_DELETE_MESSAGE_SUCCESS,
  BRANCH_DELETE__MESSAGE_FAILED,
} from "./actionTypes";

export const fetchBranches = () => (dispatch) => {
  dispatch({
    type: FETCH_BRANCHES_CONTENT,
    payload: null,
  });
  http
    .get("branches")
    .then(({ data }) => {
      dispatch({
        type: FETCH_BRANCHES_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_BRANCHES_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const deleteBranch = (formData) => (dispatch) => {
  dispatch({
    type: BRANCH_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-branch/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/branches");
        }, 2000);
        dispatch({
          type: BRANCH_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: BRANCH_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: BRANCH_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
