import React, { useState } from "react";
import { getSiteImages } from "../../../../helpers/api";
import Pagination from "../../../common/pagination";
import BranchFilter from "./branch-filter";
import FloorLst from "./floor-lst";
import ListingFilter from "./listingFilter";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function FloorBlk({
  floor_plans,
  handleDelete,
  popup,
  showPopup,
  closePopup,
  branches,
  handleBranchChange,
  floorPlansArray,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = floor_plans.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div className="page_body">
        <ListingFilter
          branches={branches}
          handleBranchChange={handleBranchChange}
          is_property={true}
        />
        <div className="blk blockLst user_dash_tbl cell_block_cell floorBlkTbl">
          <table>
            <thead>
              <tr>
                <th className="hide_cell_hyp">Branch</th>

                <th>Floor Plan</th>
                <th>Size</th>
                <th>Images</th>
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <th className="text-right hide_cell_hyp">Actions</th>
                ) : (
                  ""
                )}
              </tr>
            </thead>
            <tbody>
              {floorPlansArray?.loading === true ? (
                <tr>
                  <td colSpan={4}>
                    <div className="relative">
                      <div id="loading" className="listingsLoading static">
                        {" "}
                        <img
                          src={getSiteImages("images/loading.gif")}
                          alt="Loading"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ) : currentItems !== undefined &&
                currentItems !== "" &&
                currentItems !== null &&
                currentItems?.length > 0 ? (
                currentItems?.map((floor_plan, index) => {
                  return (
                    <FloorLst
                      floor_plan={floor_plan}
                      key={index}
                      handleDelete={handleDelete}
                      popup={popup}
                      showPopup={showPopup}
                      closePopup={closePopup}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5">
                    <div className="alert alert-danger">
                      No floor plan(s) added yet!
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={floor_plans.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
