import React, { useState } from "react";
import ContactLst from "./lst";
import Pagination from "../../../common/pagination";

export default function ContactBlk({ items, type = null, handleDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div class="outer_list_c">
        {currentItems && currentItems.length > 0 ? (
          currentItems?.map((item, index) => {
            return (
              <ContactLst item={item} type={type} handleDelete={handleDelete} />
            );
          })
        ) : (
          <div>
            <div className="alert alert-danger">No contact(s) found!</div>
          </div>
        )}
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={items?.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
