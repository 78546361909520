import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import { getServerImage } from "../../helpers/api";
import LoggedMenu from "./loggedMenu";
const mem_type = localStorage.getItem("mem_type");
export default function LoggedNavigation({
  active,
  member,
  profileImage,
  HideMenu,
  unreadNotify,
  topMessagesCount,
  handleGetUserMessages,
}) {
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const [message, setMessage] = useState(false);
  const SubNav = () => {
    setShow(!show);
  };
  const UserMenu = () => {
    setMenu(!menu);
    setNotification(false);
    setMessage(false);
  };
  const UserHideMenu = () => {
    setMenu(false);
    setNotification(false);
    setMessage(false);
    HideMenu();
  };
  const showNotifications = () => {
    setNotification(!notification);
    setMenu(false);
    setMessage(false);
  };
  const showMessages = () => {
    setMessage(!message);
    setMenu(false);
    setNotification(false);
  };
  const Logout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  const notifications = member?.notifications;
  return (
    <>
      {mem_type && mem_type === "member" ? (
        <div className="left_nav nav_right">
          <ul id="nav" nav="" className={active ? "active" : ""}>
            <li className="">
              <NavLink
                to="/favorites"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Favorites
              </NavLink>
            </li>
            <li className="">
              <NavLink
                to="/payments"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Payments
              </NavLink>
            </li>
            <li className="">
              <NavLink
                to="/listing"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Listings
              </NavLink>
            </li>
            {/* <li className="">
              <NavLink
                to="/leases"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Leases
              </NavLink>
            </li> */}
            <li className="">
              <NavLink
                to="/offers"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Offers
              </NavLink>
            </li>
            {/* <li className="">
              <NavLink
                to="/payments"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Payments
              </NavLink>
            </li> */}
            <li className="">
              <NavLink
                to="/service-requests"
                className={({ isActive }) => (isActive ? "active" : "")}
                onClick={() => UserHideMenu()}
              >
                Service Requests
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
      <LoggedMenu
        unreadNotify={unreadNotify}
        HideMenu={HideMenu}
        profileImage={profileImage}
        member={member}
        topMessagesCount={topMessagesCount}
        handleGetUserMessages={handleGetUserMessages}
      />
      <div className="clearfix"></div>
    </>
  );
}
