import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  format_number,
  format_date,
  consoleLog,
} from "../../../../helpers/api";
import ListingPrices from "./prices";
import Pagination from "../../../common/pagination";

export default function PropertyListings({
  listings,
  branch_row,
  address,
  closePopup,
  showPopup,
  member,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = listings?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    // window.scrollTo(0, 3)
  }
  const [toggle, setToggle] = useState(null);
  const showToggle = (e, item) => {
    e.preventDefault();
    setToggle(toggle === null ? item : null);
  };
  const [pricePopup, setPricePopup] = useState(null);
  const showPricePopup = (e, item) => {
    e.preventDefault();
    setPricePopup(item);
    // alert(!popup);
  };
  const closePricePopup = (e) => {
    e.preventDefault();
    setPricePopup(null);
  };
  consoleLog(listings, "listings");
  return (
    <>
      <div className="search_toggle_com invoice_toggle_outer new_list_design">
        {currentItems !== undefined &&
        currentItems !== "" &&
        currentItems !== null &&
        currentItems?.length > 0 ? (
          currentItems?.map((listing, index) => {
            return (
              <div
                class={
                  toggle !== null
                    ? "invoice_toggle new_invoice_togg active"
                    : "invoice_toggle new_invoice_togg"
                }
                key={index}
                onClick={(e) => showToggle(e, listing)}
              >
                <ul>
                  <li>
                    <h5 className="lease_heading_mini">Size</h5>
                    <div className="specific_flex_span">
                      <span>
                        {listing?.floor_plan?.studio === "yes"
                          ? "Studio"
                          : parseInt(listing?.floor_plan?.bedrooms) + " Bed"}
                      </span>
                      {
                        // listing?.floor_plan?.full_bathrooms && listing?.floor_plan?.half_bathrooms ?

                        <span>
                          {parseInt(listing?.floor_plan?.full_bathrooms) +
                            parseInt(listing?.floor_plan?.half_bathrooms)}{" "}
                          Bath
                        </span>
                        // :
                        // ""
                      }
                      {
                        // listing?.floor_plan?.sq_feet ?

                        <span>
                          {format_number(
                            parseInt(listing?.floor_plan?.sq_feet)
                          )}{" "}
                          SF
                        </span>
                        // :
                        // ""
                      }
                    </div>
                  </li>
                  <li>
                    <h5 className="lease_heading_mini">Price</h5>
                    <div>{listing.price}</div>
                  </li>
                  {listing?.available ? (
                    <li className="hide_cell_hyp">
                      <h5 className="lease_heading_mini">Available</h5>
                      <div>{listing?.available}</div>
                    </li>
                  ) : (
                    ""
                  )}
                  {listing?.floor_plan?.floor_plan ? (
                    <li className="hide_cell_hyp">
                      <h5 className="lease_heading_mini">Floor Plan</h5>
                      <div>{listing?.floor_plan?.floor_plan}</div>
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="promo_abt_icon">
                    {listing?.promotion_row?.id > 0 ? (
                      <a
                        href="javascript:void(0)"
                        className="promotion_listing_btn"
                        data-description={listing?.promotion_row?.description}
                      >
                        <img src="/images/promotion.png" />
                      </a>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
                {toggle !== null && toggle.id === listing.id ? (
                  <div className="invoice_bdy" style={{ display: "block" }}>
                    <div className="header_commercial_search_pg">
                      <div className="address_commercial">
                        {listing?.address}
                      </div>

                      <div className="right_info cstm_mini_search_vertical flex">
                        <div className="bTn">
                          <NavLink
                            to="#!"
                            className="mini_btn cstm_mini_search"
                            onClick={() => showPopup(listing)}
                          >
                            <span>Images</span>
                          </NavLink>

                          <NavLink
                            to="#!"
                            className="mini_btn cstm_mini_search"
                            onClick={(e) => showPricePopup(e, listing)}
                          >
                            <span>Price</span>
                          </NavLink>
                          {parseInt(member?.mem_employee) !== 1 &&
                          member?.id !== branch_row?.mem_id ? (
                            <NavLink
                              to={
                                "/send-commercial-offer/" +
                                branch_row?.slug +
                                "/" +
                                listing?.listing_id
                              }
                              className="mini_btn cstm_mini_search active"
                            >
                              <span>Offer</span>
                            </NavLink>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="gap_line_divide"></div>
                    <h3 className="detail_heading">Property Details</h3>
                    <div className="main_feature_list">
                      <ul className="flex feature_lising">
                        {/* <li className="show_cell_floor_only">
                            <h6>Available</h6>
                            <p>{listing.available}</p>
                          </li> */}
                        <li>
                          <h6>Laundry</h6>
                          <p>{listing.floor_plan?.laundry}</p>
                        </li>

                        <li>
                          <h6>Flooring</h6>
                          <p>{listing.floor_plan?.flooring}</p>
                        </li>

                        <li>
                          <h6>Countertop Type</h6>
                          <p>{listing.floor_plan?.counter_top}</p>
                        </li>
                        <li>
                          <h6>Air Conditioning</h6>
                          <p>{listing.floor_plan?.air_conditioning}</p>
                        </li>
                        <li>
                          <h6>Heating</h6>
                          <p>{listing.floor_plan?.heating}</p>
                        </li>
                        {/* <li className="show_cell_floor_only">
                            <h6>Floor Plan</h6>
                            <p>{listing.floor_plan?.floor_plan}</p>
                          </li> */}
                      </ul>
                    </div>

                    <hr />
                    {toggle.floor_plan.features != undefined &&
                    toggle.floor_plan.features != "" &&
                    toggle.floor_plan.features.length > 0 ? (
                      <>
                        <h3 className="detail_heading">Features</h3>
                        <ul className="flex icon_listing listing_features">
                          {toggle.floor_plan.features?.map((feature, index) => {
                            return (
                              <li id={feature?.id}>
                                <span>{feature.name}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            );
          })
        ) : (
          <div className="alert alert-danger">No listing(s) added yet!</div>
        )}
        <div className="flex commercial_listing_flex">
          <div className="listing_count text-left">
            <h5>
              {listings?.length} of {branch_row?.total_listings} Listings
            </h5>
          </div>
          <Pagination
            paginate={paginate}
            itemsPerPage={itemsPerPage}
            totalItems={listings?.length}
            currentPage={currentPage}
          />
        </div>

        {pricePopup !== null ? (
          <ListingPrices
            closePricePopup={closePricePopup}
            pricePopup={pricePopup}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
