import React from "react";
import ExpenseFilters from "./filters";

export default function ExpenseTabs({
  setExpenseType,
  expenseType,
  active_count,
  pending_count,
  expired_count,
  branches,
  handleBranchChange,
}) {
  const handleChangeTab = (e, type) => {
    e.preventDefault();
    setExpenseType(type);
  };
  return (
    <>
      <div className="flterFlexListing flex">
        <ul className="nav nav-tabs expense-tabs">
          {active_count > 0 ? (
            <li className={expenseType === "active" ? "active" : ""}>
              <a
                className="a"
                data-toggle="tab"
                href="#"
                onClick={(e) => handleChangeTab(e, "active")}
              >
                Active <span>({active_count})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {pending_count > 0 ? (
            <li className={expenseType === "pending" ? "active" : ""}>
              <a
                className="b"
                data-toggle="tab"
                href="#"
                onClick={(e) => handleChangeTab(e, "pending")}
              >
                Pending <span>({pending_count})</span>
              </a>
            </li>
          ) : (
            ""
          )}
          {expired_count > 0 ? (
            <li className={expenseType === "expired" ? "active" : ""}>
              <a
                className="b"
                data-toggle="tab"
                href="#"
                onClick={(e) => handleChangeTab(e, "expired")}
              >
                Expired <span>({expired_count})</span>
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
        <ExpenseFilters
          branches={branches}
          handleBranchChange={handleBranchChange}
        />
      </div>
    </>
  );
}
