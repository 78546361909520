import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";

import {
  fetchSaleEditOffer,
  saleOfferSave,
  updateSaleOffer,
} from "../../../states/actions/fetchSendOfferProperty";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import PropertyInfo from "../send-offer/property-info";
import SendOfferStep1 from "../send-offer/step1";
import SendOfferStep2 from "../send-offer/step2";
import {
  convertToDashesDate,
  datepickerDateGet,
  moment_new_date,
  postData,
} from "../../../helpers/api";

export default function EditRentOffer() {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const { offer_id } = useParams();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSaleEditOffer.content);
  let property_row = data?.property_row;
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);

  const isLoading = useSelector(
    (state) => state.fetchSendOfferProperty.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSendOfferProperty.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSaleEditOffer(offer_id));
  }, []);
  const [formStep, setFormStep] = useState(0);
  const changeStep = (step) => {
    setFormStep(step);
  };

  const [tenantsVerified, setTenantVerified] = useState(false);
  const [tenantsVerifiedLoading, setTenantVerifiedLoading] = useState(false);
  const [removeTenant, setRemoveTenant] = useState(false);
  const [tenants, setTenants] = useState({
    tenants: [],
  });
  useEffect(() => {
    if (
      data?.offer_row !== undefined &&
      data?.offer_row !== null &&
      data?.offer_row !== "" &&
      data?.offer_row?.tenants_arr !== undefined
    ) {
      setTenants({ ...tenants, tenants: data?.offer_row?.tenants_arr });
    }
    setValue(
      "move_in_date",
      data?.offer_row !== false &&
        data?.offer_row !== null &&
        data?.offer_row !== undefined &&
        data?.offer_row !== ""
        ? datepickerDateGet(data?.offer_row?.move_in_date_edit)
        : ""
    );
  }, [data?.offer_row]);
  const handleTenantChange = (idx, evt) => {
    const newTenants = tenants.tenants.map((tenant, sidx) => {
      if (idx !== sidx) return tenant;
      if (evt.target.name === "email") {
        let emailVal = evt.target.value.replace(/\s+/g, "");
        return { ...tenant, [evt.target.name]: emailVal };
      } else {
        return { ...tenant, [evt.target.name]: evt.target.value };
      }
    });
    setTenants({ ...tenants, tenants: newTenants });
  };
  const handleAddTenant = () => {
    setTenants({
      ...tenants,
      tenants: tenants?.tenants.concat([
        { fname: "", lname: "", email: "", disabled: false },
      ]),
    });
    setTenantVerified(false);
  };
  const [tenantLoading, setTenantLoading] = useState(false);
  async function handleRemoveTenant(idx) {
    if (data?.offer_row?.id > 0) {
      let tenant_row = tenants?.tenants[idx];
      if (tenant_row?.id > 0) {
        if (
          tenant_row?.email !== "" &&
          tenant_row?.email !== undefined &&
          tenant_row?.email !== null
        ) {
          var form_data = new FormData();
          form_data.append("email", tenant_row?.email);
          setRemoveTenant(true);
          await postData("api/remove-offer-tenant/" + offer_id, form_data).then(
            (data) => {
              setRemoveTenant(false);
              if (data?.status === 1 || data?.not_found === 1) {
                // setTenants({
                //     ...tenants, tenants: tenants.tenants.filter((s, sidx) => idx !== sidx)
                // });
                setTenantLoading(true);
                setTenants({
                  ...tenants,
                  tenants: tenants.tenants
                    .slice(0, idx)
                    .concat(
                      tenants.tenants.slice(idx + 1, tenants.tenants.length)
                    ),
                });
              }
            }
          );
        } else {
          toast.error(
            "Please add email to remove this row from system!",
            TOAST_SETTINGS
          );
        }
      } else {
        setTenantLoading(true);
        // setTenants({
        //     ...tenants, tenants: tenants.tenants.filter((s, sidx) => idx !== sidx)
        // });
        setTenants({
          ...tenants,
          tenants: tenants.tenants
            .slice(0, idx)
            .concat(tenants.tenants.slice(idx + 1, tenants.tenants.length)),
        });
      }

      return;
    }
  }
  useEffect(() => {
    setTenantLoading(false);
  }, [tenants.tenants]);
  async function handleVerifyTenants() {
    if (
      tenants !== undefined &&
      tenants !== null &&
      tenants.tenants?.length > 0 &&
      tenants.tenants[0]?.fname !== ""
    ) {
      let tenants_arr = JSON.stringify(tenants?.tenants);
      setTenantVerifiedLoading(true);
      var form_data = new FormData();
      form_data.append("offer_id", offer_id);
      form_data.append("property_id", data?.property_row?.slug);
      form_data.append("tenants", tenants_arr);
      await postData("api/verify-offer-tenants/" + offer_id, form_data).then(
        (data) => {
          setTenantVerifiedLoading(false);
          if (data.status === 1) {
            setTenantVerified(true);
            if (data?.msg !== "") {
              // toast.success(data.msg,
              //     TOAST_SETTINGS
              // );
            }

            setTimeout(() => {
              changeStep(formStep + 1);
            }, 1000);
          } else {
            toast.error(data.msg, TOAST_SETTINGS);
          }
        }
      );
    }
  }
  const onSubmit = (formData) => {
    const tenants_arr = JSON.stringify(tenants);
    let newData = {
      ...formData,
      tenants: tenants_arr,
      move_in_date: convertToDashesDate(formData?.move_in_date),
      property_type: property_row?.mem_type,
      flexibile_lease_company: property_row?.branch_flexible_lease,
    };
    dispatch(updateSaleOffer(newData, offer_id, "rent"));
  };
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;

  let listing_row = data?.property_row?.listing_row;
  let user = data?.user;
  let offer_row = data?.offer_row;
  let member = data?.member;
  return (
    <>
      <main index="">
        <section className="offer_send_sec">
          <ToastContainer />
          <div className="contain">
            <div className="offer_send_flex_outer">
              <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex divide_offer_flex send_offer_new_blk">
                  <PropertyInfo property_row={property_row} user={user} />
                  <div className="inner_colR">
                    <div className="cmn_bt_mrgn multip_step_form_offer">
                      <div className="option_lbl">
                        <ul>
                          <li className={formStep === 0 ? "active" : ""}>
                            Tenant(s)
                          </li>
                          <li className={formStep === 1 ? "active" : ""}>
                            Offering
                          </li>
                        </ul>
                      </div>
                      {formStep === 0 && tenantLoading == false ? (
                        <SendOfferStep1
                          step={formStep}
                          changeStep={changeStep}
                          tenants={tenants}
                          handleAddTenant={handleAddTenant}
                          handleTenantChange={handleTenantChange}
                          handleRemoveTenant={handleRemoveTenant}
                          handleVerifyTenants={handleVerifyTenants}
                          tenantsVerified={tenantsVerified}
                          tenantsVerifiedLoading={tenantsVerifiedLoading}
                          trigger={trigger}
                          offer_row={offer_row}
                          removeTenant={removeTenant}
                          tenantLoading={tenantLoading}
                        />
                      ) : formStep === 1 ? (
                        <SendOfferStep2
                          step={formStep}
                          changeStep={changeStep}
                          register={register}
                          errors={errors}
                          isValid={isValid}
                          control={control}
                          watchAllFields={watchAllFields}
                          trigger={trigger}
                          listing_row={listing_row}
                          property_row={property_row}
                          offer_row={offer_row}
                          isFormProcessing={isFormProcessing}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
