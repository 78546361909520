import React from "react";
import Image from '../../common/image';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function PartnerLogos({ data }) {
    return (
        <>
            <section className="trusted_partners">
                <div className="contain">
                    <div className="sec_heading text-center">
                        <h2>{data.heading}</h2>
                    </div>
                    <OwlCarousel items={7} className="owl-theme partner_logo" loop>
                        {data.logos_lst.map((val, index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="image">
                                        <Image src={val.image} alt={data.heading} />
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>
            </section>
        </>
    )
}
