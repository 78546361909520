import React from "react";
import PopupImage from "../popupImage";
import { getRemainder, getServerImage } from "../../../../../helpers/api";

export default function MediaPopup({ item, closePopup }) {
  return (
    <>
      <section
        className="popup-full scrollbar"
        data-popup="gallery-pop"
        style={{ display: "block" }}
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="flex heading_popup_property">
              <div className="back_btn" onClick={closePopup}>
                <i className="fi-cross"></i>
              </div>
              <div className="property_heading_popup">
                <h3>{item?.branch}</h3>
              </div>
            </div>

            <div className="_inner gallery_pop">
              <div className="imgGallery flex order_flex_change last_full_wide">
                <PopupImage
                  image={getServerImage("storage/media/", item?.imageThumbnail)}
                  popup={true}
                />
              </div>
              {item?.images != undefined &&
              item?.images !== null &&
              item?.images !== "" &&
              item?.images.length > 0 ? (
                <div
                  className={
                    getRemainder(item?.images?.length) === 0
                      ? "imgGallery flex order_flex_change last_full_wide"
                      : "imgGallery flex order_flex_change"
                  }
                >
                  {item?.images?.map((image, index) => {
                    return (
                      <>
                        <PopupImage
                          image={getServerImage("storage/media/", image?.image)}
                          key={index}
                          popup={true}
                        />
                      </>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
