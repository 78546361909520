import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
import { toSluUrl } from "../../../../../helpers/api";
let studio_options = [
  {
    label: "No",
    value: "no",
  },
  {
    label: "Yes",
    value: "yes",
  },
];
let heating_options = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];
let air_conditioning_options = [
  {
    label: "Central",
    value: "Central",
  },
  {
    label: "Ductless",
    value: "Ductless",
  },
  {
    label: "Evaporative",
    value: "Evaporative",
  },
  {
    label: "Heat Pump",
    value: "Heat Pump",
  },
  {
    label: "PTAC",
    value: "PTAC",
  },
];
let flooring_options = [
  {
    label: "Carpet",
    value: "Carpet",
  },
  {
    label: "Hardwood",
    value: "Hardwood",
  },
  {
    label: "Tiles",
    value: "Tiles",
  },
  {
    label: "Concrete",
    value: "Concrete",
  },
];
let counter_top_options = [
  {
    label: "Granite",
    value: "Granite",
  },
  {
    label: "SoapStone",
    value: "SoapStone",
  },
  {
    label: "Marble",
    value: "Marble",
  },
  {
    label: "Quartz",
    value: "Quartz",
  },
  {
    label: "Solid Surface",
    value: "Solid Surface",
  },
  {
    label: "Ceramic Tile",
    value: "Ceramic Tile",
  },
];
let laundry_options = [
  {
    label: "In Unit",
    value: "In Unit",
  },
  {
    label: "Laundry Facility",
    value: "Laundry Facility",
  },
  {
    label: "None",
    value: "None",
  },
];
export default function FloorStep2({
  changeStep,
  step,
  features,
  register,
  isValid,
  errors,
  floor_plan_row,
  control,
  watchAllFields,
  trigger,
}) {
  return (
    <>
      <fieldset>
        <ToastContainer />
        <div className="form_row row flex">
          <div className="col-xs-4">
            <h6>Square Feet</h6>
            <div className="form_blk deposit_form_blk">
              <input
                type="text"
                name="sq_feet"
                id="sq_feet"
                className="txtBox"
                {...register("sq_feet", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row.sq_feet
                    : ""
                }
              />
              <span className="dollar_label">SF</span>
              <ErrorMessage
                errors={errors}
                name="sq_feet"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Studio</h6>
            <div className="form_blk">
              <Controller
                {...register("studio", { required: "Required" })}
                name="studio"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.studio
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={studio_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={studio_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="studio"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          {watchAllFields?.studio === "no" ? (
            <>
              <div className="col-xs-4">
                <h6>Bedrooms</h6>
                <div className="form_blk">
                  {/* {

                                watchAllFields?.studio === 'yes' ?
                                    <> */}

                  <input
                    type="text"
                    name="bedrooms"
                    id="bedrooms"
                    className="txtBox"
                    {...register(
                      "bedrooms",

                      {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Invalid value",
                        },
                        required: "Required",
                        // min: {
                        //     value: 1,
                        //     message: "Value must be greater than 0."
                        // },
                        // valueAsNumber: true,
                        validate: {
                          required: (value) => {
                            if (value <= 0 && watchAllFields?.studio == "no")
                              return "Value must be greater than 0";
                            return true;
                          },
                        },
                      }
                    )}
                    defaultValue={
                      floor_plan_row != undefined &&
                      floor_plan_row != null &&
                      floor_plan_row !== "" &&
                      floor_plan_row?.bedrooms != ""
                        ? floor_plan_row.bedrooms
                        : 0
                    }
                  />
                  <ErrorMessage
                    errors={errors}
                    name="bedrooms"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-xs-4"></div>
          )}
          <div className="col-xs-4">
            <h6>Full Bathrooms</h6>
            <div className="form_blk">
              <input
                type="text"
                name="full_bathrooms"
                id="full_bathrooms"
                className="txtBox"
                {...register("full_bathrooms", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                })}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row.full_bathrooms
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="full_bathrooms"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          <div className="col-xs-4">
            <h6>Half Bathrooms</h6>
            <div className="form_blk">
              <input
                type="text"
                name="half_bathrooms"
                id="half_bathrooms"
                className="txtBox"
                {...register("half_bathrooms", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                })}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row.half_bathrooms
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="half_bathrooms"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4"></div>
          <div className="col-xs-4">
            <h6>Air Conditioning</h6>
            <div className="form_blk">
              <Controller
                {...register("air_conditioning", { required: "Required" })}
                name="air_conditioning"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.air_conditioning
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={air_conditioning_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={air_conditioning_options.find(
                        (c) => c.value === value
                      )}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="air_conditioning"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Heating</h6>
            <div className="form_blk">
              <Controller
                {...register("heating", { required: "Required" })}
                name="heating"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.heating
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={heating_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={heating_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="heating"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4"></div>
          <div className="col-xs-4">
            <h6>Flooring</h6>
            <div className="form_blk">
              <Controller
                {...register("flooring", { required: "Required" })}
                name="flooring"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.flooring
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={flooring_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={flooring_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="flooring"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Countertop Type</h6>
            <div className="form_blk">
              <Controller
                {...register("counter_top", { required: "Required" })}
                name="counter_top"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.counter_top
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={counter_top_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={counter_top_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="counter_top"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Laundry</h6>
            <div className="form_blk">
              <Controller
                {...register("laundry", { required: "Required" })}
                name="laundry"
                control={control}
                defaultValue={
                  floor_plan_row != undefined &&
                  floor_plan_row != null &&
                  floor_plan_row !== ""
                    ? floor_plan_row?.laundry_options
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={laundry_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={laundry_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="laundry"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="amenities_blk_pro">
          <h5>
            <span>Check all that apply</span>
          </h5>
        </div>
        <div className="form_row row flex">
          {features && features?.length > 0
            ? features?.map((item, index) => {
                return (
                  <div className="col-xs-4" key={index}>
                    <div className="form_blk">
                      <div className="lblBtn">
                        <input
                          type="checkbox"
                          name="features"
                          defaultValue={item.id}
                          id={toSluUrl(item.title)}
                          {...register("features")}
                          defaultChecked={
                            floor_plan_row != undefined &&
                            floor_plan_row != null &&
                            floor_plan_row !== ""
                              ? watchAllFields?.features.includes(item.id)
                              : false
                          }
                        />
                        <label htmlFor={toSluUrl(item.title)}>
                          {item.title}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
          <ErrorMessage
            errors={errors}
            name="amenities"
            render={({ message }) => (
              <p className="error">
                <i className="fi-warning"></i> {message}
              </p>
            )}
          />
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : watchAllFields?.studio === "no" &&
            watchAllFields?.bedrooms <= 0 ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                toast.error(
                  "Bedroom value must be greater than 0.",
                  TOAST_SETTINGS
                );
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
