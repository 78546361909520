import React from 'react'


export default function PopupImage({ image, className = 'col', label = "", popup = false }) {
    return (
        <>

            {
                popup === true ?
                    <div className={className}>
                        <img src={image} />
                        {
                            label !== '' ?
                                <span>{label}</span>
                                :
                                ""
                        }
                    </div>
                    :

                    <a href={image} data-thumb={image} className={className} data-lg-size="2500-2303">
                        <img src={image} />
                        {
                            label !== '' ?
                                <span>{label}</span>
                                :
                                ""
                        }
                    </a>
            }



        </>
    )
}
