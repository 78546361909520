import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import CreditForm from "./credits/form";

import {
  fetchSingleCredit,
  postBilling,
  postCredit,
} from "../../../states/actions/fetchSingleCredits";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
export default function AddCredit() {
  const { credit_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleCredits.content);
  const isLoading = useSelector((state) => state.fetchSingleCredits.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleCredits.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleCredit(credit_id));
  }, []);
  const handleCreditForm = (frmData) => {
    // ; return;
    dispatch(postCredit(frmData, credit_id));
  };
  useEffect(() => {
    if (credit_id > 0 && data?.credit_row === null) {
      window.location.href = "/credits";
    }
  }, [credit_id, data?.credit_row]);

  useDocumentTitle(
    data?.credit_row?.id > 0 ? "Edit Credit - Loftus" : "Add Credit - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const credit_row = data?.credit_row;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="credits" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={credit_row?.id > 0 ? "Edit Credit" : "Add Credit"}
                  parent_name="Credits"
                  parent_link="/credits"
                />
                <div className="page_body">
                  <div className="dash_form_blk">
                    <CreditForm
                      isFormProcessing={isFormProcessing}
                      handleCreditForm={handleCreditForm}
                      branches={branches}
                      credit_row={credit_row}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
