import React from "react";
import { ErrorMessage } from "@hookform/error-message";

export default function BranchStep3({
  step,
  changeStep,
  register,
  isValid,
  errors,
  control,
  trigger,
  branch_row,
}) {
  return (
    <>
      <fieldset>
        <h4 className="screening_heading_af">
          Please select a screening package that tenant(s) are required to
          provide when submitting an offer on your listing.{" "}
        </h4>
        <div className="form_row row justify_center_flex">
          <div className="col-xs-6">
            <ul className="total_count_price_listing full_wide_price_listing">
              <li>
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="screening"
                    value="N"
                    id="N"
                    {...register("screening", { required: "Required" })}
                    defaultChecked={
                      branch_row?.screening === "N" ? true : false
                    }
                  />
                  <label htmlFor="N">None</label>
                </div>
              </li>
              {/* <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CC" id="CC" {...register("screening", { required: "Required" })} defaultChecked={branch_row?.screening === 'CC' ? true : false} />
                                    <label htmlFor="CC">Credit & Criminal</label>
                                </div>
                            </li>
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CCE" id="CCE" {...register("screening", { required: "Required" })} defaultChecked={branch_row?.screening === 'CCE' ? true : false} />
                                    <label htmlFor="CCE">Credit & Criminal and Evictions</label>
                                </div>
                            </li>
                            <li>
                                <div className="lblBtn">
                                    <input type="radio" name="screening" value="CCI" id="CCI" {...register("screening", { required: "Required" })} defaultChecked={branch_row?.screening === 'CCI' ? true : false} />
                                    <label htmlFor="CCI">Credit & Criminal and ID</label>
                                </div>
                            </li> */}
              <li>
                <div className="lblBtn">
                  <input
                    type="radio"
                    name="screening"
                    value="CCEI"
                    id="CCEI"
                    {...register("screening", { required: "Required" })}
                    defaultChecked={
                      branch_row?.screening === "CCEI" ? true : false
                    }
                  />
                  <label htmlFor="CCEI">
                    Credit & Criminal, Evictions, and ID
                  </label>
                </div>
              </li>
            </ul>
            <ErrorMessage
              errors={errors}
              name="screening"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>

        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
