import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { toSluUrl } from "../../../../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../../../../utils/siteSettings";
import { ToastContainer } from "react-toastify";
let studio_options = [
  {
    label: "No",
    value: "no",
  },
  {
    label: "Yes",
    value: "yes",
  },
];
let heating_options = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];
let air_conditioning_options = [
  {
    label: "Central",
    value: "Central",
  },
  {
    label: "Ductless",
    value: "Ductless",
  },
  {
    label: "Evaporative",
    value: "Evaporative",
  },
  {
    label: "Heat Pump",
    value: "Heat Pump",
  },
  {
    label: "PTAC",
    value: "PTAC",
  },
];
let flooring_options = [
  {
    label: "Carpet",
    value: "Carpet",
  },
  {
    label: "Hardwood",
    value: "Hardwood",
  },
  {
    label: "Tiles",
    value: "Tiles",
  },
  {
    label: "Concrete",
    value: "Concrete",
  },
];
let counter_top_options = [
  {
    label: "Granite",
    value: "Granite",
  },
  {
    label: "SoapStone",
    value: "SoapStone",
  },
  {
    label: "Marble",
    value: "Marble",
  },
  {
    label: "Quartz",
    value: "Quartz",
  },
  {
    label: "Solid Surface",
    value: "Solid Surface",
  },
  {
    label: "Ceramic Tile",
    value: "Ceramic Tile",
  },
];
let laundry_options = [
  {
    label: "In Unit",
    value: "In Unit",
  },
  {
    label: "Laundry Facility",
    value: "Laundry Facility",
  },
  {
    label: "None",
    value: "None",
  },
];
let parking_options = [
  {
    label: "No",
    value: "No",
  },
  {
    label: "Yes",
    value: "Yes",
  },
];
let parking_spaces_options = [
  {
    label: "Garage",
    value: "Garage",
  },
  {
    label: "Street",
    value: "Street",
  },
  {
    label: "None",
    value: "None",
  },
];
export default function PropertyStep3({
  step,
  changeStep,
  register,
  errors,
  property_row,
  isValid,
  features,
  trigger,
  watchAllFields,
  getValues,
  setValue,
  control,
}) {
  useEffect(() => {
    if (
      watchAllFields?.studio === undefined ||
      watchAllFields?.studio === null ||
      watchAllFields?.studio === ""
    ) {
      if (
        property_row?.studio !== undefined &&
        property_row?.studio !== null &&
        property_row?.studio !== ""
      ) {
        setValue("studio", property_row?.studio);
      } else {
        setValue("studio", "no");
      }
    }
  }, []);
  return (
    <>
      <fieldset>
        <ToastContainer />
        <div className="form_row row flex">
          <div className="col-xs-4">
            <h6>Square Feet</h6>
            <div className="form_blk">
              <input
                type="text"
                name="sq_feet"
                id="sq_feet"
                className="txtBox"
                {...register("sq_feet", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  min: {
                    value: 1,
                    message: "Value must be greater than 0.",
                  },
                })}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.sq_feet
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="sq_feet"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Studio</h6>
            <div className="form_blk">
              {/* <select name="studio" id="studio" className="txtBox " {...register("studio", { required: "Required" })}>
                                <option value="no" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.studio === 'no' ? 'selected' : '') : ''}>No</option>
                                <option value="yes" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.studio === 'yes' ? 'selected' : '') : ''}>Yes</option>
                            </select> */}
              <Controller
                {...register("studio", { required: "Required" })}
                name="studio"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.studio
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={studio_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={studio_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="studio"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          {watchAllFields?.studio === "no" ? (
            <>
              <div className="col-xs-4">
                <h6>Bedrooms</h6>
                <div className="form_blk">
                  {/* {

                                watchAllFields?.studio === 'yes' ?
                                    <> */}

                  <input
                    type="text"
                    name="bedrooms"
                    id="bedrooms"
                    className="txtBox"
                    {...register(
                      "bedrooms",

                      {
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Invalid value",
                        },
                        required: "Required",
                        // min: {
                        //     value: 1,
                        //     message: "Value must be greater than 0."
                        // },
                        // valueAsNumber: true,
                        validate: {
                          required: (value) => {
                            if (value <= 0 && watchAllFields?.studio == "no")
                              return "Value must be greater than 0";
                            return true;
                          },
                        },
                      }
                    )}
                    defaultValue={
                      property_row != undefined &&
                      property_row != null &&
                      property_row !== "" &&
                      property_row?.bedrooms != ""
                        ? property_row.bedrooms
                        : 0
                    }
                  />
                  <ErrorMessage
                    errors={errors}
                    name="bedrooms"
                    render={({ message }) => (
                      <p className="error">
                        <i className="fi-warning"></i> {message}
                      </p>
                    )}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-xs-4"></div>
          )}
          <div className="col-xs-4">
            <h6>Full Bathrooms</h6>
            <div className="form_blk">
              <input
                type="text"
                name="full_bathrooms"
                id="full_bathrooms"
                className="txtBox"
                {...register("full_bathrooms", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  // min: {
                  //     value: 0,
                  //     message: "Value must be greater than 0."
                  // },
                })}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.full_bathrooms
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="full_bathrooms"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          <div className="col-xs-4">
            <h6>Half Bathrooms</h6>
            <div className="form_blk">
              <input
                type="text"
                name="half_bathrooms"
                id="half_bathrooms"
                className="txtBox"
                {...register("half_bathrooms", {
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Invalid value",
                  },
                  required: "Required",
                  // min: {
                  //     value: 0,
                  //     message: "Value must be greater than 0."
                  // },
                })}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row.half_bathrooms
                    : ""
                }
              />
              <ErrorMessage
                errors={errors}
                name="half_bathrooms"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4"></div>
          <div className="col-xs-4">
            <h6>Air Conditioning</h6>
            <div className="form_blk">
              {/* <select name="air_conditioning" id="air_conditioning" className="txtBox" {...register("air_conditioning", { required: "Required" })}>
                                <option value="Central" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.air_conditioning === 'Central' ? 'selected' : '') : ''}>Central</option>
                                <option value="Ductless" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.air_conditioning === 'Ductless' ? 'selected' : '') : ''}>Ductless</option>
                                <option value="Evaporative" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.air_conditioning === 'Evaporative' ? 'selected' : '') : ''}>Evaporative</option>
                                <option value="Heat Pump" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.air_conditioning === 'Heat Pump' ? 'selected' : '') : ''}>Heat Pump</option>
                                <option value="PTAC" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.air_conditioning === 'PTAC' ? 'selected' : '') : ''}>PTAC</option>
                            </select> */}
              <Controller
                {...register("air_conditioning", { required: "Required" })}
                name="air_conditioning"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.air_conditioning
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={air_conditioning_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={air_conditioning_options.find(
                        (c) => c.value === value
                      )}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="air_conditioning"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Heating</h6>
            <div className="form_blk">
              {/* <select name="heating" id="heating" className="txtBox " {...register("heating", { required: "Required" })}>
                                <option value="Yes" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.heating === 'no' ? 'selected' : '') : ''}>Yes</option>
                                <option value="No" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.heating === 'no' ? 'selected' : '') : ''}>No</option>

                            </select> */}
              <Controller
                {...register("heating", { required: "Required" })}
                name="heating"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.heating
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={heating_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={heating_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="heating"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4"></div>
          <div className="col-xs-4">
            <h6>Flooring</h6>
            <div className="form_blk">
              {/* <select name="flooring" id="flooring" className="txtBox " {...register("flooring", { required: "Required" })}>
                                <option value="Carpet" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.flooring === 'Carpet' ? 'selected' : '') : ''}>Carpet</option>
                                <option value="Hardwood" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.flooring === 'Hardwood' ? 'selected' : '') : ''}>Hardwood</option>
                                <option value="Tiles" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.flooring === 'Tiles' ? 'selected' : '') : ''}>Tiles</option>
                                <option value="Concrete" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.flooring === 'Concrete' ? 'selected' : '') : ''}>Concrete</option>
                            </select> */}
              <Controller
                {...register("flooring", { required: "Required" })}
                name="flooring"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.flooring
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={flooring_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={flooring_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="flooring"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Countertop Type</h6>
            <div className="form_blk">
              {/* <select name="counter_top" id="counter_top" className="txtBox " {...register("counter_top", { required: "Required" })}>
                                <option value="Granite" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'Granite' ? 'selected' : '') : ''}>Granite</option>
                                <option value="SoapStone" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'SoapStone' ? 'selected' : '') : ""}>SoapStone</option>
                                <option value="Marble" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'Marble' ? 'selected' : '') : ''}>Marble</option>
                                <option value="Quartz" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'Quartz' ? 'selected' : '') : ''}>Quartz</option>
                                <option value="Solid Surface" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'Solid Surface' ? 'selected' : '') : ''}>Solid Surface</option>
                                <option value="Ceramic Tile" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.counter_top === 'Ceramic Tile' ? 'selected' : '') : ''}>Ceramic Tile</option>
                            </select> */}
              <Controller
                {...register("counter_top", { required: "Required" })}
                name="counter_top"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.counter_top
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={counter_top_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={counter_top_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="counter_top"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Laundry</h6>
            <div className="form_blk">
              {/* <select name="laundry" id="laundry" className="txtBox " {...register("laundry", { required: "Required" })}>
                                <option value="In Unit" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.coulaundrynter_top === 'In Unit' ? 'selected' : '') : ''}>In Unit</option>
                                <option value="Laundry Facility" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.laundry === 'Laundry Facility' ? 'selected' : '') : ''}>Laundry Facility</option>
                                <option value="None" selected={property_row != undefined && property_row != null && property_row !== '' ? (property_row.laundry === 'None' ? 'selected' : '') : ''}>None</option>
                            </select> */}
              <Controller
                {...register("laundry", { required: "Required" })}
                name="laundry"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.laundry_options
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={laundry_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={laundry_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="laundry"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>

          <div className="col-xs-4">
            <h6>Parking</h6>
            <div className="form_blk">
              {/* <select name="parking" id="parking" className="txtBox " {...register("parking", { required: "Required" })}>
                                <option value="Yes" selected>Yes</option>
                                <option value="No">No</option>

                            </select> */}
              <Controller
                {...register("parking", { required: "Required" })}
                name="parking"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.parking
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={parking_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={parking_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="parking"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Parking Spaces</h6>
            <div className="form_blk">
              {/* <select name="parking_spaces" id="parking_spaces" className="txtBox "{...register("parking_spaces", { required: "Required" })}>
                                <option value="Garage" selected>Garage</option>
                                <option value="None">None</option>
                                <option value="Street">Street</option>

                            </select> */}
              <Controller
                {...register("parking_spaces", { required: "Required" })}
                name="parking_spaces"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.parking_spaces
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={parking_spaces_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={parking_spaces_options.find(
                        (c) => c.value === value
                      )}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="parking_spaces"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-4">
            <h6>Guest Parking</h6>
            <div className="form_blk">
              {/* <select name="guest_parking" id="guest_parking" className="txtBox "{...register("guest_parking", { required: "Required" })}>
                                <option value="Yes" selected>Yes</option>
                                <option value="No">No</option>

                            </select> */}
              <Controller
                {...register("guest_parking", { required: "Required" })}
                name="guest_parking"
                control={control}
                defaultValue={
                  property_row != undefined &&
                  property_row != null &&
                  property_row !== ""
                    ? property_row?.guest_parking
                    : ""
                }
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={parking_options}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={parking_options.find((c) => c.value === value)}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="guest_parking"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="amenities_blk_pro">
          <h5>
            <span>Check all that apply</span>
          </h5>
        </div>
        <div className="form_row row flex">
          {features && features?.length > 0
            ? features?.map((item, index) => {
                return (
                  <div className="col-xs-4" key={index}>
                    <div className="form_blk">
                      <div className="lblBtn">
                        <input
                          type="checkbox"
                          name="features"
                          defaultValue={item.id}
                          id={toSluUrl(item.title)}
                          {...register("features")}
                          defaultChecked={
                            property_row != undefined &&
                            property_row != null &&
                            property_row !== ""
                              ? watchAllFields?.features.includes(item.id)
                              : false
                          }
                        />
                        <label htmlFor={toSluUrl(item.title)}>
                          {item.title}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          {!isValid ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : watchAllFields?.studio === "no" &&
            watchAllFields?.bedrooms <= 0 ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                toast.error(
                  "Bedroom value must be greater than 0.",
                  TOAST_SETTINGS
                );
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => changeStep(step + 1)}
              disabled={!isValid}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
