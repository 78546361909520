import React, { useEffect, useState } from "react";
import Sidebar from "./sections/sidebar";
import Breadcrumb from "../dashboard/sections/breadcrumb";

import {
  fetchSingleContact,
  postContact,
} from "../../../states/actions/fetchSingleContacts";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ContactsForm from "./contacts/form";
export default function AddContact() {
  const { contact_id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleContacts.content);
  const isLoading = useSelector((state) => state.fetchSingleContacts.isLoading);
  const isFormProcessing = useSelector(
    (state) => state.fetchSingleContacts.isFormProcessing
  );
  useEffect(() => {
    dispatch(fetchSingleContact(contact_id));
  }, []);
  const handleContactForm = (frmData) => {
    // ; return;
    dispatch(postContact(frmData, contact_id));
  };
  useEffect(() => {
    if (contact_id > 0 && data?.contact_row === null) {
      window.location.href = "/contacts";
    }
  }, [contact_id, data?.contact_row]);

  useDocumentTitle(
    data?.contact_row?.id > 0 ? "Edit Contact - Loftus" : "Add Contact - Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const contact_row = data?.contact_row;
  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="contacts" />
              </div>
              <div className="colR">
                <Breadcrumb
                  page_name={
                    contact_row?.id > 0 ? "Edit Contact" : "Add Contact"
                  }
                  parent_name="Contacts"
                  parent_link="/contacts"
                />
                <div className="page_body">
                  <div className="dash_form_blk cmn_user_form">
                    <ContactsForm
                      branches={branches}
                      handleContactForm={handleContactForm}
                      isFormProcessing={isFormProcessing}
                      contact_row={contact_row}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
