import React, { useState, useEffect, useRef } from "react";
import SendOfferForm from "./send-offer/send-offer-form";
import { useForm } from "react-hook-form";

import { useParams } from "react-router-dom";
import {
  fetchSendOfferProperty,
  sendOfferValidationRequest,
  postOfferPaypal,
  saleOfferSave,
} from "../../states/actions/fetchSendOfferProperty";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  consoleLog,
  convertToDashesDate,
  getPackagePrice,
  isPackageUpgraded,
  postData,
} from "../../helpers/api";
import SaleSendOffer from "./send-offer/sale/sale-send-offer";
import Error from "./Error";
import SendCommercialOffer from "./send-offer/send-commercial-offer";

export default function SendOffer({ property_type }) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const { slug } = useParams();
  const { listing_id } = useParams();
  const { offer_id } = useParams();
  const renderAfterCalled = useRef(false);
  const [tenantLoading, setTenantLoading] = useState(false);
  const [userCheckVerified, setUserCheckVerified] = useState(false);
  const [manualVerify, setManualVerify] = useState(false);
  const [formStep, setFormStep] = useState(0);
  const [packageUpgrade, setPackageUpgrade] = useState(false);
  const [verify, setVerify] = useState(false);
  const [tenantsVerified, setTenantVerified] = useState(false);
  const [tenantsVerifiedLoading, setTenantVerifiedLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSendOfferProperty.content);
  const mem_payment_methods = useSelector(
    (state) => state.fetchSendOfferProperty.payment_methods
  );
  let property_row = data.property_row;
  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const choosePaymentMethod = (value) => {
    setPaymentMethod(value);
  };
  const [examQuestions, setExamQuestions] = useState({
    examQuestions: [{ question: "", answer: "" }],
  });

  const [formWatchData, setWatchData] = useState({});
  const handleSetFormWatchData = (data) => {
    setWatchData(data);
  };

  //Set Checkout Error
  const [checkoutError, setCheckoutError] = useState();
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  console.log(mem_payment_methods);

  const calculateListingProcessingFee = () => {
    if (
      watchAllFields.package !== "" &&
      watchAllFields.package !== undefined &&
      watchAllFields.package !== null
    ) {
      let package_fee = parseFloat(
        getPackagePrice(watchAllFields.package, settings?.site_package_cost)
      );
      let site_stripe_fee =
        (package_fee * parseFloat(settings?.site_stripe_fee)) / 100;
      let processing_fee =
        site_stripe_fee + parseFloat(settings?.site_stripe_flat_fee);
      return parseFloat(processing_fee.toFixed(2));
    } else {
      return 0;
    }
  };
  //Paypal functions
  const getPaypalObject = (order) => {
    if (order.status === "COMPLETED") {
      let manual_verify = "";
      if (
        manualVerify !== false &&
        manualVerify?.request_status === "ManualVerificationRequired"
      ) {
        manual_verify = "true";
      } else {
        manual_verify = "false";
      }
      const tenants_arr = JSON.stringify(tenants);
      let data = watchAllFields;
      let newData = "";

      if (
        isPackageUpgraded(package_names, property_row, watchAllFields) === true
      ) {
        newData = {
          ...data,
          paymentMethod: paymentMethod,
          order_id: order.id,
          payer_id: order.payer.payer_id,
          property: slug,
          tenants: tenants_arr,
          amount:
            parseFloat(
              getPackagePrice(
                watchAllFields.package,
                settings?.site_package_cost
              )
            ) + parseFloat(calculateListingProcessingFee()),
          manual_verify: manual_verify,
          upgrade: true,
          move_in_date: convertToDashesDate(data?.move_in_date),
          property_type: property_row?.mem_type,
          flexibile_lease_company: property_row?.branch_flexible_lease,
        };
      } else {
        newData = {
          ...data,
          paymentMethod: paymentMethod,
          order_id: order.id,
          payer_id: order.payer.payer_id,
          property: slug,
          tenants: tenants_arr,
          amount:
            parseFloat(
              getPackagePrice(
                watchAllFields.package,
                settings?.site_package_cost
              )
            ) + parseFloat(calculateListingProcessingFee()),
          manual_verify: manual_verify,
          upgrade: false,
          move_in_date: convertToDashesDate(data?.move_in_date),
          property_type: property_row?.mem_type,
          flexibile_lease_company: property_row?.branch_flexible_lease,
        };
      }

      let formData = Object.assign(newData, formWatchData);
      dispatch(
        postOfferPaypal(property_type, formData, slug, listing_id, offer_id)
      );
    } else {
      consoleLog(order);
    }
  };
  const cancelPaypalOrder = (data) => {
    toast.error("You canceled the payment with PayPal.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const errorPaypalOrder = (data) => {
    toast.error("There is a technical problem. Try again later.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  //Strip function to submit data
  const stripePayment = (data, upgrade = false) => {
    let newData = null;

    let manual_verify = "";
    if (
      manualVerify !== false &&
      manualVerify?.request_status === "ManualVerificationRequired"
    ) {
      manual_verify = "true";
    } else {
      manual_verify = "false";
    }
    const tenants_arr = JSON.stringify(tenants);
    if (watchAllFields?.package === "N") {
      newData = {
        ...data,
        property: slug,
        tenants: tenants_arr,
        move_in_date: convertToDashesDate(data?.move_in_date),
        property_type: property_row?.mem_type,
        flexibile_lease_company: property_row?.branch_flexible_lease,
      };
    } else {
      newData = {
        ...data,
        paymentMethod: paymentMethod,
        property: slug,
        tenants: tenants_arr,
        amount:
          parseFloat(
            getPackagePrice(watchAllFields.package, settings?.site_package_cost)
          ) + parseFloat(calculateListingProcessingFee()),
        verified_user: "false",
        package_upgrade: packageUpgrade,
        manual_verify: manual_verify,
        upgrade: upgrade,
        move_in_date: convertToDashesDate(data?.move_in_date),
        property_type: property_row?.mem_type,
        flexibile_lease_company: property_row?.branch_flexible_lease,
      };
    }

    let formData = Object.assign(newData, formWatchData);
    dispatch(
      postOfferPaypal(property_type, formData, slug, listing_id, offer_id)
    );
  };

  const verifiedUserHandle = (data) => {
    const tenants_arr = JSON.stringify(tenants);
    let newData = {
      ...data,
      property: slug,
      tenants: tenants_arr,
      amount:
        parseFloat(
          getPackagePrice(watchAllFields.package, settings?.site_package_cost)
        ) + parseFloat(calculateListingProcessingFee()),
      verified_user: "true",
      package_upgrade: packageUpgrade,
      move_in_date: convertToDashesDate(data?.move_in_date),
      property_type: property_row?.mem_type,
      flexibile_lease_company: property_row?.branch_flexible_lease,
    };
    let formData = Object.assign(newData, formWatchData);
    if (data?.no_upgrade === "true") {
      dispatch(
        postOfferPaypal(
          property_type,
          formData,
          slug,
          listing_id,
          offer_id,
          true
        )
      );
    } else {
      dispatch(
        postOfferPaypal(
          property_type,
          formData,
          listing_id,
          slug,
          offer_id,
          true
        )
      );
    }
  };
  function handleExamChange(idx, evt) {
    const newOptions = examQuestions.examQuestions.map(
      (examQuestions, sidx) => {
        if (idx !== sidx) return examQuestions;
        return { ...examQuestions, [evt.target.name]: evt.target.value };
      }
    );

    setExamQuestions({ ...examQuestions, examQuestions: newOptions });
  }
  useEffect(() => {}, [examQuestions]);
  function updateQuestions(arr) {
    if (arr?.length > 0) {
      let newQuestions = arr?.map((q, i) => {
        return { question: q.questionKeyName, answer: "" };
      });
      setExamQuestions({ ...examQuestions, examQuestions: newQuestions });
    }
  }
  const changeStep = (step) => {
    setFormStep(step);
  };
  const handleCheckVerified = (verified) => {
    if (verified === undefined || verified === null || verified === false) {
      setUserCheckVerified(false);
    } else {
      if (verified === true || verified === 1) {
        setUserCheckVerified(true);
        setCheckIdentityVerification(false);
      }
    }
  };
  const [checkIdentityVerification, setCheckIdentityVerification] =
    useState(false);
  const [manualVerifyQuesAnsPost, setManualVerifyQuesAnsPost] = useState(false);
  const handleIdentityVerification = () => {
    setCheckIdentityVerification(true);
    setVerify(false);
    setManualVerifyQuesAnsPost(true);
    changeStep(formStep + 1);
  };
  useEffect(() => {}, [userCheckVerified]);

  const handleVerify = () => {
    setVerify(true);
  };

  const [tenants, setTenants] = useState({
    tenants: [],
  });
  const handleAddTenant = () => {
    setTenants({
      ...tenants,
      tenants: tenants.tenants.concat([{ fname: "", lname: "", email: "" }]),
    });
  };

  function handleRemoveTenant(idx, e) {
    e.preventDefault();
    setTenantLoading(true);
    // setTenants({
    //     ...tenants, tenants: tenants.tenants.filter((s, sidx) => idx !== sidx)
    // });

    setTenants({
      ...tenants,
      tenants: tenants.tenants
        .slice(0, idx)
        .concat(tenants.tenants.slice(idx + 1, tenants.tenants.length)),
    });
  }
  useEffect(() => {
    setTenantLoading(false);
  }, [tenants.tenants]);
  async function handleVerifyTenants() {
    if (
      tenants !== undefined &&
      tenants !== null &&
      tenants.tenants?.length > 0 &&
      tenants.tenants[0]?.fname !== ""
    ) {
      let tenants_arr = JSON.stringify(tenants?.tenants);
      setTenantVerifiedLoading(true);
      var form_data = new FormData();
      form_data.append("property_id", slug);
      form_data.append("offer_id", offer_id);
      form_data.append("listing_id", listing_id);
      form_data.append("tenants", tenants_arr);
      await postData("api/verify-tenants", form_data).then((data) => {
        setTenantVerifiedLoading(false);
        if (data.status === 1) {
          setTenantVerified(true);
          // toast.success(data.msg,
          //     TOAST_SETTINGS
          // );
          setTimeout(() => {
            changeStep(formStep + 1);
          }, 1000);
        } else {
          toast.error(data.msg, TOAST_SETTINGS);
        }
      });
    }
  }
  const handleTenantChange = (idx, evt) => {
    const newTenants = tenants.tenants.map((tenant, sidx) => {
      if (idx !== sidx) return tenant;
      // return { ...tenant, [evt.target.name]: evt.target.value };
      if (evt.target.name === "email") {
        const inputValue = evt.target.value;
        const cleanValue = inputValue.replace(/\s/g, ""); // Remove all spaces
        evt.target.value = cleanValue;
        return { ...tenant, [evt.target.name]: evt.target.value };
      } else {
        return { ...tenant, [evt.target.name]: evt.target.value };
      }
    });
    setTenants({ ...tenants, tenants: newTenants });
  };

  const isLoading = useSelector(
    (state) => state.fetchSendOfferProperty.isLoading
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchSendOfferProperty.isFormProcessing
  );
  const exam = useSelector((state) => state.fetchSendOfferProperty.questions);
  const requestResponse = useSelector(
    (state) => state.fetchSendOfferProperty.requestResponse
  );
  const handleOfferValidation = (formData) => {
    dispatch(
      sendOfferValidationRequest(formData, slug, listing_id, property_type)
    );
  };
  useEffect(() => {
    if (!renderAfterCalled.current) {
      dispatch(
        fetchSendOfferProperty(property_type, slug, listing_id, offer_id)
      );
    }
    renderAfterCalled.current = true;
  }, []);
  let offer_data = data?.offer_row;
  let offer_tenants = data?.offer_row?.tenants_arr;
  useEffect(() => {}, [tenants]);

  useEffect(() => {
    if (offer_data !== undefined && offer_data !== null && offer_data !== "") {
      if (data?.member?.package_verified) {
        setFormStep(6);
      } else if (data?.member?.verification_status === "manual") {
        if (data?.property_row?.listing_row?.screening === "N") {
          setValue("package", "N");
          setFormStep(6);
        }
      } else {
        setFormStep(2);
      }

      setTenants({ ...tenants, tenants: offer_tenants });
    }
  }, [offer_data]);
  useEffect(() => {
    if (
      data?.member_information !== undefined &&
      data?.member_information !== null &&
      data?.member_information !== "" &&
      parseInt(data?.member_information?.state) > 0
    ) {
      setValue("state", parseInt(data?.member_information?.state));
    }
    if (data?.member?.verification_status === "manual") {
      setValue("package", "N");
    } else {
      if (
        data?.member?.package_verified === true ||
        data?.member?.package_verified === 1 ||
        data?.member?.package_verified === "true" ||
        data?.member?.package_verified === "1"
      ) {
        setValue("package", "CCEI");
      }
    }
  }, [data]);

  //sale offer
  const [saleOfferCoverLetter, setSaleOfferCoverLetter] = useState({
    file: null,
    file_name: null,
    loading: false,
  });
  const handleSaleSubmit = (data) => {
    let offer_response_deadline = data?.offer_response_deadline;
    let target_closing_date = data?.target_closing_date;
    let contingencies = data?.contingencies;
    let seller_concessions = data?.seller_concessions;

    if (
      offer_response_deadline === false ||
      offer_response_deadline === null ||
      offer_response_deadline === undefined ||
      offer_response_deadline === ""
    ) {
      offer_response_deadline = "";
    }
    if (
      target_closing_date === false ||
      target_closing_date === null ||
      target_closing_date === undefined ||
      target_closing_date === ""
    ) {
      target_closing_date = "";
    }
    if (
      data?.seller_concessions === false ||
      data?.seller_concessions === null ||
      data?.seller_concessions === undefined ||
      data?.seller_concessions === ""
    ) {
      seller_concessions = null;
    }
    if (
      data?.contingencies === false ||
      data?.contingencies === null ||
      data?.contingencies === undefined ||
      data?.contingencies === ""
    ) {
      contingencies = null;
    }
    let newData = {
      ...data,
      seller_concessions: seller_concessions,
      contingencies: contingencies,
      seller_concessions: seller_concessions,
      offer_response_deadline: offer_response_deadline,
      target_closing_date: target_closing_date,
    };
    dispatch(saleOfferSave(newData, slug, listing_id, offer_id));
  };

  let stripePromise = "";
  if (settings?.site_stripe_type === 1 || settings?.site_stripe_type === "1") {
    stripePromise = loadStripe(settings?.site_stripe_testing_api_key);
  } else if (
    settings?.site_stripe_type === 0 ||
    settings?.site_stripe_type === "0"
  ) {
    stripePromise = loadStripe(settings?.site_stripe_live_api_key);
  }
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;

  let listing_row = data?.property_row?.listing_row;
  let user = data?.user;
  let offer_row = data?.offer_row;
  let member = data?.member;
  let member_information = data?.member_information;
  let package_verified = member?.package_verified;
  let package_upgrade = member?.package_upgrade;
  let package_names = member?.package_names;
  let site_package_cost = settings?.site_package_cost;
  let member_tu_verification_status = member?.verification_status;
  let manualOfferTenant = member?.manualOfferTenant;
  return (
    <>
      <main index="">
        <section className="offer_send_sec">
          <div className="contain">
            <div className="offer_send_flex_outer">
              <ToastContainer />
              <div className="colR">
                <Elements stripe={stripePromise}>
                  {listing_row?.mem_type === "company" ? (
                    <SendOfferForm
                      step={formStep}
                      changeStep={changeStep}
                      property_row={property_row}
                      user={user}
                      tenants={tenants}
                      handleAddTenant={handleAddTenant}
                      handleTenantChange={handleTenantChange}
                      handleRemoveTenant={handleRemoveTenant}
                      siteSettings={settings}
                      handleOfferValidation={handleOfferValidation}
                      isFormProcessing={isFormProcessing}
                      exam={exam}
                      examQuestions={examQuestions}
                      setExamQuestions={setExamQuestions}
                      handleExamChange={handleExamChange}
                      updateQuestions={updateQuestions}
                      verify={verify}
                      handleVerify={handleVerify}
                      paymentMethod={paymentMethod}
                      choosePaymentMethod={choosePaymentMethod}
                      checkoutError={checkoutError}
                      handleCardDetailsChange={handleCardDetailsChange}
                      stripePayment={stripePayment}
                      slug={slug}
                      register={register}
                      handleSubmit={handleSubmit}
                      control={control}
                      errors={errors}
                      isValid={isValid}
                      watchAllFields={watchAllFields}
                      cancelPaypalOrder={cancelPaypalOrder}
                      errorPaypalOrder={errorPaypalOrder}
                      getPaypalObject={getPaypalObject}
                      handleSetFormWatchData={handleSetFormWatchData}
                      handleVerifyTenants={handleVerifyTenants}
                      tenantsVerified={tenantsVerified}
                      tenantsVerifiedLoading={tenantsVerifiedLoading}
                      handleCheckVerified={handleCheckVerified}
                      userCheckVerified={userCheckVerified}
                      verifiedUserHandle={verifiedUserHandle}
                      offer_exist={data?.offer_exist}
                      offer_row={offer_row}
                      packageUpgrade={packageUpgrade}
                      setPackageUpgrade={setPackageUpgrade}
                      trigger={trigger}
                      requestResponse={requestResponse}
                      setManualVerify={setManualVerify}
                      manualVerify={manualVerify}
                      listing_row={listing_row}
                      member={member}
                      member_information={member_information}
                      package_verified={package_verified}
                      package_upgrade={package_upgrade}
                      package_names={package_names}
                      formWatchData={formWatchData}
                      setValue={setValue}
                      handleIdentityVerification={handleIdentityVerification}
                      checkIdentityVerification={checkIdentityVerification}
                      setCheckIdentityVerification={
                        setCheckIdentityVerification
                      }
                      manualVerifyQuesAnsPost={manualVerifyQuesAnsPost}
                      setManualVerifyQuesAnsPost={setManualVerifyQuesAnsPost}
                      site_package_cost={site_package_cost}
                      member_tu_verification_status={
                        member_tu_verification_status
                      }
                      manualOfferTenant={manualOfferTenant}
                      calculateListingProcessingFee={
                        calculateListingProcessingFee
                      }
                      offer_data={offer_data}
                      tenantLoading={tenantLoading}
                      mem_payment_methods={mem_payment_methods}
                    />
                  ) : listing_row?.listing_type === "rent" ? (
                    <SendOfferForm
                      step={formStep}
                      changeStep={changeStep}
                      property_row={property_row}
                      user={user}
                      tenants={tenants}
                      handleAddTenant={handleAddTenant}
                      handleTenantChange={handleTenantChange}
                      handleRemoveTenant={handleRemoveTenant}
                      siteSettings={settings}
                      handleOfferValidation={handleOfferValidation}
                      isFormProcessing={isFormProcessing}
                      exam={exam}
                      examQuestions={examQuestions}
                      setExamQuestions={setExamQuestions}
                      handleExamChange={handleExamChange}
                      updateQuestions={updateQuestions}
                      verify={verify}
                      handleVerify={handleVerify}
                      paymentMethod={paymentMethod}
                      choosePaymentMethod={choosePaymentMethod}
                      checkoutError={checkoutError}
                      handleCardDetailsChange={handleCardDetailsChange}
                      stripePayment={stripePayment}
                      slug={slug}
                      register={register}
                      handleSubmit={handleSubmit}
                      control={control}
                      errors={errors}
                      isValid={isValid}
                      watchAllFields={watchAllFields}
                      cancelPaypalOrder={cancelPaypalOrder}
                      errorPaypalOrder={errorPaypalOrder}
                      getPaypalObject={getPaypalObject}
                      handleSetFormWatchData={handleSetFormWatchData}
                      handleVerifyTenants={handleVerifyTenants}
                      tenantsVerified={tenantsVerified}
                      tenantsVerifiedLoading={tenantsVerifiedLoading}
                      handleCheckVerified={handleCheckVerified}
                      userCheckVerified={userCheckVerified}
                      verifiedUserHandle={verifiedUserHandle}
                      offer_exist={data?.offer_exist}
                      offer_row={offer_row}
                      packageUpgrade={packageUpgrade}
                      setPackageUpgrade={setPackageUpgrade}
                      trigger={trigger}
                      requestResponse={requestResponse}
                      setManualVerify={setManualVerify}
                      manualVerify={manualVerify}
                      listing_row={listing_row}
                      member={member}
                      member_information={member_information}
                      package_verified={package_verified}
                      package_upgrade={package_upgrade}
                      package_names={package_names}
                      formWatchData={formWatchData}
                      setValue={setValue}
                      handleIdentityVerification={handleIdentityVerification}
                      checkIdentityVerification={checkIdentityVerification}
                      setCheckIdentityVerification={
                        setCheckIdentityVerification
                      }
                      manualVerifyQuesAnsPost={manualVerifyQuesAnsPost}
                      setManualVerifyQuesAnsPost={setManualVerifyQuesAnsPost}
                      site_package_cost={site_package_cost}
                      member_tu_verification_status={
                        member_tu_verification_status
                      }
                      manualOfferTenant={manualOfferTenant}
                      calculateListingProcessingFee={
                        calculateListingProcessingFee
                      }
                      offer_data={offer_data}
                      tenantLoading={tenantLoading}
                      mem_payment_methods={mem_payment_methods}
                    />
                  ) : listing_row?.listing_type === "sale" ? (
                    <SaleSendOffer
                      step={formStep}
                      changeStep={changeStep}
                      property_row={property_row}
                      user={user}
                      listing_row={listing_row}
                      member={member}
                      register={register}
                      handleSubmit={handleSubmit}
                      control={control}
                      errors={errors}
                      isValid={isValid}
                      watchAllFields={watchAllFields}
                      trigger={trigger}
                      setSaleOfferCoverLetter={setSaleOfferCoverLetter}
                      saleOfferCoverLetter={saleOfferCoverLetter}
                      handleSaleSubmit={handleSaleSubmit}
                      isFormProcessing={isFormProcessing}
                      offer_exist={data?.offer_exist}
                    />
                  ) : (
                    <Error />
                  )}
                </Elements>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
