import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";

export const metadata = {
    title: 'Steps',
};

export default function PAymentMEthodStep1({ paymentMethod, changePaymentMethod, setFormStep }) {
    return (
        <fieldset>
            <div className="row form_row">
                <div className="col-xs-12">
                    <h4 className="text-center">What type of payment method would you like to add?</h4>
                </div>
                <div className="col-xs-12">
                    <div className="gap_line_divide"></div>
                    <div className="flex choose_payment_method_method">
                        <div className={paymentMethod === 'ach' ? "active lblBtn" : "lblBtn"}>
                            <input type="radio" name="bank" value="" className="hidden" id="bank" onChange={(e) => changePaymentMethod(e, 'ach')} />
                            <label for="bank" className="bankLbl">Bank Account</label>
                        </div>
                        <div className={paymentMethod === 'credit-card' ? "active lblBtn" : "lblBtn"}>
                            <input type="radio" name="bank" value="" className="hidden" id="card" onChange={(e) => changePaymentMethod(e, 'credit-card')} />
                            <label for="card" className="cardLbl">Credit Card</label>
                        </div>
                    </div>

                </div>
            </div>
            <div className="cmnBtn text-center">
                <NavLink to="/payment-methods" className="webBtn cancleBtn">Back</NavLink>
                {
                    paymentMethod === null && paymentMethod !== 'ach' && paymentMethod !== 'credit-card' ?
                        <button type="button" className="webBtn colorBtn nextBtn" onClick={() => toast.error("please select payment method first")}>Next</button>
                        :
                        <button type="button" className="webBtn colorBtn nextBtn" onClick={() => setFormStep(1)}>Next</button>
                }

            </div>
        </fieldset>
    );
}