import React from "react";
import { NavLink } from "react-router-dom";
import { getServerImage } from "../../../../helpers/api";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function DocumentLst({ active_document, handleDelete, type }) {
  return (
    <>
      <tr>
        <td class="hide_cell_hyp">
          <span class="mini-show-cell">Branch</span>
          {active_document?.branch_name}
        </td>
        <td>
          <span class="mini-show-cell">Document</span>
          {active_document?.name}
        </td>
        <td>
          <span class="mini-show-cell">Modified</span>
          {active_document?.updated_date}
        </td>
        <td>
          <span class="mini-show-cell">Document</span>
          <a
            href={getServerImage("storage/attachments/", active_document?.file)}
            className="sm_icon"
            target="_blank"
          >
            <img src="/images/document.png" alt="" />
          </a>
        </td>
        {mem_permissions === "admin" ? (
          <td class="flex_action_user_dash hide_cell_hyp">
            <div class="action_drop _dropDown">
              <div class="_dropBtn action_dots">
                <img src="/images/dashboard/dots.png" alt="" />
              </div>
              <ul class="_dropCnt dropLst">
                {type !== "expired" ? (
                  <>
                    <li>
                      <NavLink to={"/edit-document/" + active_document?.id}>
                        Edit
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="#!"
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            )
                          )
                            handleDelete(e, active_document?.id);
                        }}
                      >
                        Delete
                      </a>
                    </li>
                  </>
                ) : (
                  ""
                )}
                <li>
                  <a
                    href={getServerImage(
                      "storage/attachments/",
                      active_document?.file
                    )}
                    target="_blank"
                  >
                    Download
                  </a>
                </li>
              </ul>
            </div>
          </td>
        ) : (
          ""
        )}
      </tr>
    </>
  );
}
