import React, { useEffect } from "react";
import ForgotPasswordForm from './section/fotgor-password-form';
import { Link } from "react-router-dom";

import { fetchForgot, postForgot } from "../../states/actions/fetchForgot";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

export default function ForgotPassword() {

    const dispatch = useDispatch();
    const handleForgot = (formData) => {
        dispatch(postForgot(formData));
    };
    const isFormProcessing = useSelector(
        (state) => state.fetchForgot.isFormProcessing
    );
    const emptyFormData = useSelector(
        (state) => state.fetchForgot.emptyFormData
    );
    const data = useSelector((state) => state.fetchForgot.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchForgot.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchForgot());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const intro = content

    return (
        <>
            <section className="logon">
                <div className="contain">
                    <div className="log_blk">
                        <ForgotPasswordForm data={intro} handleForgot={handleForgot} isFormProcessing={isFormProcessing} emptyFormData={emptyFormData} />

                        <div className="haveAccount text-center">
                            <span className="a_space">Don’t have an account?</span>
                            <Link to="/signup"> Sign up</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
