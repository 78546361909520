import React from "react";
import PropertyStep1 from "./step1";
import PropertyStep2 from "./step2";
import PropertyStep3 from "./step3";
import PropertyStep4 from "./step4";
export default function AddUserPropertyForm({
  step,
  changeStep,
  countries,
  states,
  register,
  errors,
  control,
  watchAllFields,
  property_row,
  checkAddressAvailability,
  addressAvailable,
  isValid,
  amenities,
  images,
  uploadPropertyImages,
  handleAddLabel,
  handleLabelChange,
  labels,
  handleRemoveLabelImage,
  imageThumbnail,
  setThumbnail,
  path,
  handleSubmit,
  onSubmit,
  isFormProcessing,
  trigger,
  addressLoading,
  popup,
  showPopup,
  closePopup,
  handleUpload,
  verifyAddressFile,
  sendPropertyRequest,
  verifyFormLoading,
  features,
  handleRemoveLabel,
  getValues,
  setValue,
  checkTitleAvailability,
  titleLoading,
  titleAvailable,
}) {
  return (
    <>
      <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>
        {step === 0 ? (
          <PropertyStep1
            step={step}
            changeStep={changeStep}
            countries={countries}
            states={states}
            property_row={property_row}
            register={register}
            control={control}
            watchAllFields={watchAllFields}
            errors={errors}
            isValid={isValid}
            checkAddressAvailability={checkAddressAvailability}
            addressAvailable={addressAvailable}
            trigger={trigger}
            addressLoading={addressLoading}
            popup={popup}
            showPopup={showPopup}
            closePopup={closePopup}
            handleUpload={handleUpload}
            verifyAddressFile={verifyAddressFile}
            sendPropertyRequest={sendPropertyRequest}
            verifyFormLoading={verifyFormLoading}
            checkTitleAvailability={checkTitleAvailability}
            titleAvailable={titleAvailable}
            titleLoading={titleLoading}
          />
        ) : step === 1 ? (
          <PropertyStep2
            step={step}
            changeStep={changeStep}
            amenities={amenities}
            property_row={property_row}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
            watchAllFields={watchAllFields}
            setValue={setValue}
          />
        ) : step === 2 ? (
          <PropertyStep3
            step={step}
            changeStep={changeStep}
            features={features}
            property_row={property_row}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            trigger={trigger}
            watchAllFields={watchAllFields}
            setValue={setValue}
          />
        ) : step === 3 ? (
          <PropertyStep4
            step={step}
            isFormProcessing={isFormProcessing}
            changeStep={changeStep}
            property_row={property_row}
            register={register}
            control={control}
            errors={errors}
            isValid={isValid}
            images={images}
            uploadPropertyImages={uploadPropertyImages}
            handleAddLabel={handleAddLabel}
            handleLabelChange={handleLabelChange}
            labels={labels}
            handleRemoveLabelImage={handleRemoveLabelImage}
            imageThumbnail={imageThumbnail}
            setThumbnail={setThumbnail}
            path={path}
            trigger={trigger}
            handleRemoveLabel={handleRemoveLabel}
            watchAllFields={watchAllFields}
            features={features}
            getValues={getValues}
          />
        ) : (
          ""
        )}
      </form>
    </>
  );
}
