import {
    NEWSLETTER_MESSAGE,
    NEWSLETTER_MESSAGE_SUCCESS,
    NEWSLETTER_MESSAGE_FAILED,
    FETCH_NEWSLETTER,
} from "../actions/actionTypes";

const initialState = {
    isLoading: true,
    content: {},
    error: false,
    isFormProcessing: false,
    emptyFormData: false
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_NEWSLETTER:
            return {
                ...state,
                isLoading: true,
                content: {}
            };
        case NEWSLETTER_MESSAGE:
            return {
                ...state,
                isFormProcessing: true,
            };
        case NEWSLETTER_MESSAGE_SUCCESS:
            return {
                ...state,
                isFormProcessing: false,
                emptyFormData: true
            };
        case NEWSLETTER_MESSAGE_FAILED:
            return {
                ...state,
                isFormProcessing: false,
                error: payload
            };
        default:
            return state;
    }
}
