import React from 'react'

export default function TabsMenu({ type, handleType, active_count = '', pending_count = '', active_name = "Active", pending_name = "Pending" }) {

    return (
        <>
            <ul className="nav nav-tabs">
                <li class={type == 'active' ? 'active' : ""}>
                    <a className="a" data-toggle="tab" href="javascript:void(0)" onClick={() => handleType('active')}>
                        {active_name} <span>{active_count ? "(" + active_count + ")" : "(0)"}</span></a>
                </li>
                <li class={type == 'pending' ? 'active' : ""}>
                    <a className="b" data-toggle="tab" href="javascript:void(0)" onClick={() => handleType('pending')}>
                        {pending_name} <span>{pending_count ? "(" + pending_count + ")" : "(0)"}</span></a>
                </li>

            </ul>

        </>
    )
}
