import React from "react";
import PopupImage from "./popupImage";
import LightGallery from "lightgallery/react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgFullscreen from "lightgallery/plugins/fullscreen";
import lgZoom from "lightgallery/plugins/zoom";
import Text from "../../../common/Text";
import { getRemainder, getServerImage } from "../../../../helpers/api";

export default function FloorPopup({
  title,
  address,
  images,
  closePopup,
  property,
  floor_plan = false,
  type,
  thumbnail,
  floor_plan_item = false,
}) {
  return (
    <>
      <section
        className="popup-full scrollbar"
        data-popup="gallery-pop"
        style={{ display: "block" }}
      >
        <div className="tableDv">
          <div className="tableCell">
            <div className="flex heading_popup_property">
              <div className="back_btn" onClick={closePopup}>
                <i className="fi-cross"></i>
              </div>
              <div className="property_heading_popup">
                <h3>{title}</h3>
                {address !== null ? (
                  <p>
                    <Text string={address} parse={true} />
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="_inner gallery_pop">
              <div className="imgGallery flex order_flex_change last_full_wide">
                {floor_plan !== false &&
                floor_plan !== undefined &&
                floor_plan_item === false ? (
                  <PopupImage image={floor_plan?.image} popup={true} />
                ) : floor_plan_item === true ? (
                  ""
                ) : (
                  <PopupImage
                    image={getServerImage(
                      "storage/floor_plans/",
                      property?.imageThumbnail
                    )}
                    popup={true}
                  />
                )}
              </div>
              {images != undefined &&
              images !== null &&
              images !== "" &&
              images.length > 0
                ? images?.map((labelImages, index) => {
                    return (
                      <>
                        {labelImages?.images !== undefined &&
                        labelImages?.images !== null &&
                        labelImages?.images?.length > 0 ? (
                          <>
                            <h3 className="gallery_category_heading">
                              {labelImages.label}
                            </h3>
                            <div
                              className={
                                getRemainder(labelImages.images?.length) === 0
                                  ? "imgGallery flex order_flex_change last_full_wide"
                                  : "imgGallery flex order_flex_change"
                              }
                            >
                              {/* <LightGallery
                                                                speed={500}
                                                                plugins={[lgThumbnail, lgZoom, lgFullscreen]}
                                                                // showZoomInOutIcons={true}
                                                                thumbnail={true}
                                                            > */}
                              {labelImages.images?.map((image, index) => {
                                return (
                                  <PopupImage
                                    image={image?.image}
                                    key={index}
                                    label={image?.label}
                                    popup={true}
                                  />
                                );
                              })}
                              {/* </LightGallery> */}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
