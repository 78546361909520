import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import moment from "moment";

export default function ExpensesStep1({
  setFormStep,
  formStep,
  branches,
  register,
  errors,
  control,
  isValid,
  watchAllFields,
  trigger,
}) {
  const getNextMonthStartDate = () => {
    return moment().add(1, "months").startOf("month").toDate();
  };
  return (
    <>
      <fieldset>
        <div className="form_row row flex">
          <div className="col-xs-6">
            <h6>Select Branch</h6>
            <div className="form_blk">
              <Controller
                {...register("branch_id", { required: "Required" })}
                name="branch_id"
                control={control}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={branches}
                      onChange={(val) => {
                        onChange(val.value);
                      }}
                      label="Select Branch"
                      value={branches.filter(function (option) {
                        return option.value === value;
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="branch_id"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
          <div className="col-xs-6">
            <h6>Effective</h6>
            <div className="form_blk">
              <Controller
                control={control}
                name="effective"
                {...register("effective", { required: "Required" })}
                render={({ field }) => (
                  <DatePicker
                    className="txtBox"
                    placeholderText="_ _ / _ _ / _ _ _ _"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    minDate={getNextMonthStartDate()}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="effective"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
            </div>
          </div>
        </div>
        <div className="cmnBtn text-center">
          <NavLink to="/expenses" className="webBtn cancleBtn">
            Cancel
          </NavLink>
          {watchAllFields?.branch_id === "" ||
          watchAllFields?.branch_id === null ||
          watchAllFields?.branch_id === undefined ||
          watchAllFields?.effective === "" ||
          watchAllFields?.effective === null ||
          watchAllFields?.effective === undefined ? (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => {
                trigger();
              }}
            >
              Next
            </button>
          ) : (
            <button
              type="button"
              className="webBtn colorBtn nextBtn"
              onClick={() => setFormStep(formStep + 1)}
            >
              Next
            </button>
          )}
        </div>
      </fieldset>
    </>
  );
}
