import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ToastContainer } from "react-toastify";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { postStaffPerson } from "../../../../states/actions/fetchMaintenanceStaff";
export default function DirectInvitePopup({ closePopup, item,properties }) {
  const {
    register,
    trigger,
    setValue,
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const watchAllFields = watch();
  const isFormProcessing = useSelector(
    (state) => state.fetchMaintenanceStaff.isFormProcessing
  );
  const dispatch=useDispatch()
  const onSubmit = (frmData) => {
    frmData = {
      ...frmData,
      property: JSON.stringify(frmData?.property),type:"search",
      user_id:item?.mem_id
    };
    dispatch(postStaffPerson(frmData, null));
  };
  return (
    <>
      <section className="popup invite_popups_all small_popup">
        <ToastContainer />
        <div className="tableDv">
          <div className="tableCell">
            <div className="_inner">
              <div className="crosBtn" onClick={closePopup}></div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="txtGrp">
                  <div className="form_blk">
                  <input
                type="text"
                name=""
                id=""
                placeholder="Offering Rate"
                className="txtBox"
                {...register("offering_rate", {
                  required: "Required"})}
              />
              <ErrorMessage
                errors={errors}
                name="offering_rate"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
                    <span className="dollar_label">$/hr</span>
                  </div>
                </div>
                <div className="txtGrp">
                  <div className="form_blk">
                  <Controller
                {...register("property", { required: "Required" })}
                name="property"
                id="property"
                className=""
                control={control}
                defaultValue={watchAllFields?.property}
                render={({ field: { onChange, value, ref, name } }) => (
                  <>
                    <Select
                      options={properties}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      isMulti
                      label="Select Property"
                      value={properties?.filter(function (option) {
                        return value?.some(
                          (selected) => selected.value === option.value
                        );
                      })}
                    />
                  </>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="property"
                render={({ message }) => (
                  <p className="error">
                    <i className="fi-warning"></i> {message}
                  </p>
                )}
              />
                  </div>
                </div>
                <div className="text-center bTn">
                  <button type="button" className="webBtn" onClick={closePopup}>
                    Cancel
                  </button>
                  <button type="submit" className="webBtn colorBtn" disabled={isFormProcessing}>
                    Submit {isFormProcessing === true ? (
              <i className="spinner"></i>
            ) : (
              ""
            )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
