import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import {
  fetchListingDetails,
  deleteListing,
} from "../../../states/actions/fetchListingDetails";
import PropertyHeader from "../single-property/section/header";
import PropertyGallery from "../../pages/single-property/section/gallery";
import { format_amount, postData, projectUrl } from "../../../helpers/api";
import PropertyDetails from "../single-property/section/details";
import PropertyReviews from "../single-property/section/reviews";
import UpdateRentListingPopup from "./sections/updateRentListingPopup";
import ExtendRentListingPopup from "./sections/extendRentListingPopup";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import PropertyLocation from "../single-property/section/locations";
import ListingLogs from "../single-property/section/logs";
import OpenHousesBlk from "../single-property/section/openHousesBlk.jsx";

export default function ListingDetails() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchListingDetails.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector((state) => state.fetchListingDetails.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteListing(data));
  };
  useEffect(() => {
    dispatch(fetchListingDetails(slug));
  }, []);
  const [popup, setPopup] = useState({
    item: null,
    show: false,
  });
  const [extendListing, setExtendListing] = useState({
    item: null,
    show: false,
  });
  const [updateListing, setUpdateListing] = useState({
    item: null,
    show: false,
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [extendLoading, setExtendLoading] = useState(false);
  const handleUpdateListing = async (form_data, id) => {
    setUpdateLoading(true);
    await postData("api/update-rent-listing/" + id, form_data).then((data) => {
      setUpdateLoading(false);
      if (data?.status === 1) {
        toast.success(data.msg, TOAST_SETTINGS);
        setTimeout(() => {
          setUpdateListing({ ...updateListing, item: null, show: false });
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  };
  const handleExtendListing = async (form_data, id) => {
    setExtendLoading(true);
    await postData("api/extend-rent-listing/" + id, form_data).then((data) => {
      setExtendLoading(false);
      if (data?.status === 1) {
        toast.success(data.msg, TOAST_SETTINGS);
        setTimeout(() => {
          setExtendListing({ ...extendListing, item: null, show: false });
          window.location.reload();
        }, 2000);
      } else {
        toast.error(data.msg, TOAST_SETTINGS);
      }
    });
  };
  const showUpdateListingPopup = (item) => {
    setUpdateListing({ ...updateListing, item: item, show: true });
  };
  const hideUpdateListingPopup = () => {
    setUpdateListing({ ...updateListing, item: null, show: false });
  };
  const showExtendListingPopup = (item) => {
    setExtendListing({ ...extendListing, item: item, show: true });
  };
  const hideExtendListingPopup = () => {
    setExtendListing({ ...extendListing, item: null, show: false });
  };

  const [listingPopup, setListingPopup] = useState({
    item: null,
    show: false,
  });
  const showPopup = (item) => {
    setPopup({ ...popup, item: item, show: true });
    // alert(!popup);
  };
  const closePopup = () => {
    setPopup({ ...popup, item: null, show: false });
  };
  const listingEditPopup = (item) => {
    setListingPopup({ ...listingPopup, item: item, show: true });
    // alert(!popup);
  };
  const listingEditPopupClose = () => {
    setListingPopup({ ...listingPopup, item: null, show: false });
  };
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  const siteSettings = useSelector(
    (state) => state.fetchSiteSettings.siteSettings
  );
  const member = siteSettings?.member;
  if (member?.mem_verified === 0) {
    return window.location.replace("/dashboard");
  }
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const listing = data.listing_row;
  let listing_rent_row = null;
  let listing_sale_row = null;
  if (listing?.listing_type === "rent") {
    listing_rent_row = listing;
  }
  if (listing?.listing_type === "sale") {
    listing_sale_row = listing;
  }
  let property_url = "";
  if (listing?.mem_type !== "member") {
    property_url = projectUrl("property/" + listing?.property?.slug);
  } else {
    property_url = projectUrl(
      "property/" + listing?.property?.slug + "/" + listing?.listing_id
    );
  }

  return (
    <>
      {listing === undefined ? (window.location.href = "/listing") : ""}
      <ToastContainer />
      <div
        class={
          popup.show === true &&
          popup.item != undefined &&
          popup.item != null &&
          popup.item != ""
            ? "floor_popup main_dashboard"
            : "main_dashboard"
        }
      >
        <section className="property_detail_main_sr">
          <div className="contain">
            <div className="page_body">
              <PropertyHeader
                address={listing?.address}
                floor_plan_name={listing.property.title}
                listing={true}
                showUpdateListingPopup={showUpdateListingPopup}
                listing_row={listing}
                showExtendListingPopup={showExtendListingPopup}
                handleListingDelete={handleDelete}
                property={listing?.property}
                item={listing?.property}
                property_url={property_url}
              />

              <div className="view_property_flex flex">
                <div className="view_property_gallery colL">
                  <div className="search-detail-grid">
                    <PropertyGallery
                      images={listing.images}
                      popup={popup}
                      closePopup={closePopup}
                      showPopup={showPopup}
                      property={listing}
                      thumbnail={listing?.property?.imageThumbnail}
                    />
                  </div>
                </div>
                <div className="colR">
                  <div className="result_detail_blk">
                    <PropertyDetails
                      property={listing?.property}
                      listing={true}
                      listing_row={listing}
                      listing_rent_row={listing_rent_row}
                      listing_sale_row={listing_sale_row}
                      open_houses={listing?.open_houses_arr}
                      property_score={listing?.property?.property_score}
                    />
                    {listing?.listing_type === "rent" ? (
                      <PropertyReviews
                        listing={true}
                        property={listing?.property}
                        member={member}
                      />
                    ) : (
                      ""
                    )}
                    {listing?.property?.log_history !== undefined &&
                    listing?.property?.log_history !== null &&
                    listing?.property?.log_history !== "" &&
                    listing?.property?.log_history?.length > 0 ? (
                      <>
                        <ListingLogs
                          logs={listing?.logs}
                          extensions={listing?.extensions}
                          type={listing?.listing_type}
                          listing={true}
                          log_history={listing?.property?.log_history}
                        />
                        <hr />
                      </>
                    ) : (
                      ""
                    )}

                    {listing?.property?.formatted_address !== "" &&
                    listing?.property?.formatted_address !== null &&
                    listing?.property?.formatted_address !== undefined ? (
                      <PropertyLocation
                        address={listing?.property?.formatted_address}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {listingPopup?.show === true &&
        listingPopup?.item != undefined &&
        listingPopup?.item != null &&
        listingPopup?.item != "" ? (
          <section className="popup" data-popup="create-listing">
            <div className="tableDv">
              <div className="tableCell">
                <div className="_inner">
                  <div
                    className="crosBtn"
                    onClick={listingEditPopupClose}
                  ></div>
                  <h4>What do you want to do?</h4>
                  <div className="bTn justify_btn_center">
                    <NavLink
                      to="#!"
                      className="webBtn"
                      onClick={() => showUpdateListingPopup()}
                    >
                      Update
                    </NavLink>
                    <NavLink
                      to="#!"
                      className="webBtn colorBtn"
                      onClick={showExtendListingPopup}
                    >
                      Extend
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
        {updateListing?.show === true &&
        updateListing?.item != undefined &&
        updateListing?.item != null &&
        updateListing?.item != "" ? (
          <UpdateRentListingPopup
            hideUpdateListingPopup={hideUpdateListingPopup}
            listingPopup={updateListing}
            handleUpdateListing={handleUpdateListing}
            updateLoading={updateLoading}
          />
        ) : (
          ""
        )}
        {extendListing?.show === true &&
        extendListing?.item != undefined &&
        extendListing?.item != null &&
        extendListing?.item != "" ? (
          <ExtendRentListingPopup
            hideUpdateListingPopup={hideExtendListingPopup}
            listingPopup={extendListing}
            siteSettings={siteSettings}
            extendLoading={extendLoading}
            handleExtendListing={handleExtendListing}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}
