import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_LOGIN_CONTENT,
  FETCH_LOGIN_CONTENT_SUCCESS,
  FETCH_LOGIN_CONTENT_FAILED,
  LOGIN_MESSAGE,
  LOGIN_MESSAGE_SUCCESS,
  LOGIN_MESSAGE_FAILED,
  GOOGLE_LOGIN_MESSAGE,
  GOOGLE_LOGIN_MESSAGE_SUCCESS,
  GOOGLE_LOGIN_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchLogin = () => (dispatch) => {
  dispatch({
    type: FETCH_LOGIN_CONTENT,
    payload: null,
  });
  http
    .get("signin-page")
    .then(({ data }) => {
      //
      dispatch({
        type: FETCH_LOGIN_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_LOGIN_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const postLogin = (formData) => (dispatch) => {
  dispatch({
    type: LOGIN_MESSAGE,
    payload: null,
  });
  http
    .post("login", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        if (data?.phone_verified) {
          setTimeout(() => {
            window.location.replace("/member-verification");
          }, 1000);
        } else if (data.not_verified) {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1000);
        } else {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1000);
        }
        // toast.success(
        //     `${data.msg}`,
        //     TOAST_SETTINGS
        // );
        dispatch({
          type: LOGIN_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: LOGIN_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: LOGIN_MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const googlePostLogin = (formData) => (dispatch) => {
  dispatch({
    type: GOOGLE_LOGIN_MESSAGE,
    payload: null,
  });
  http
    .post("login", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        if (data?.phone_verified) {
          setTimeout(() => {
            window.location.replace("/member-verification");
          }, 1000);
        } else if (data.not_verified) {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1000);
        } else {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 1000);
        }
        // toast.success(
        //     `${data.msg}`,
        //     TOAST_SETTINGS
        // );
        dispatch({
          type: GOOGLE_LOGIN_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: GOOGLE_LOGIN_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: GOOGLE_LOGIN_MESSAGE_FAILED,
        payload: error,
      });
    });
};
