import { useEffect, useState } from "react";
import PaymentMethodBankStep from "./steps/bank";
import PaymentMEthodCreditCardStep from "./steps/credit-card";
import PAymentMEthodStep1 from "./steps/step1";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { doObjToFormData, postData } from "../../../../helpers/api";
import { ToastContainer } from "react-toastify";
import Text from "../../../common/Text";
import http from "../../../../helpers/http";
import { useNavigate } from "react-router-dom";

export default function PaymentMethodForm({
  saveCard,
  member,
  payment_method_row = null,
  states,
  countries,
  payment_method_id,
}) {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const changePaymentMethod = (e, type) => {
    e.preventDefault();
    setPaymentMethod(type);
  };
  const [checkoutError, setCheckoutError] = useState();
  const [isProcessing, setProcessingTo] = useState(false);
  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };
  const {
    register,
    trigger,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });
  const navigate = useNavigate();
  function getCountryById(id) {
    const item = countries?.find((element) => element.value === id);
    return item ? item?.shortname : null;
  }
  function getStateById(id) {
    const item = states?.find((element) => element.value === id);
    return item ? item?.label : null;
  }
  const onSubmit = async (frmData) => {
    if (
      payment_method_row?.id > 0 &&
      payment_method_row?.payment_method === "credit-card"
    ) {
      frmData = {
        ...frmData,
        country: frmData?.country?.value
          ? frmData?.country?.value
          : frmData?.country,
        state: frmData?.state?.value ? frmData?.state?.value : frmData?.state,
      };
      setProcessingTo(true);
      http
        .post("/update-card/" + payment_method_id, doObjToFormData(frmData))
        .then(({ data }) => {
          setProcessingTo(false);
          if (data.status) {
            toast.success(`${data.msg}`);
            setTimeout(() => {
              navigate(`/payment-methods`);
            }, 2000);
          } else {
            toast.error(<Text string={data.msg} parse={true} />);
          }
        })
        .catch((error) => {
          console.log(error);
          setProcessingTo(false);
          // toast.error(error);
        });
    } else if (
      payment_method_row?.id > 0 &&
      payment_method_row?.payment_method === "ach"
    ) {
      setProcessingTo(true);
      http
        .post(
          "/update-bank-details/" + payment_method_id,
          doObjToFormData(frmData)
        )
        .then(({ data }) => {
          setProcessingTo(false);
          if (data.status) {
            toast.success(`${data.msg}`);
            setTimeout(() => {
              navigate(`/payment-methods`);
            }, 2000);
          } else {
            toast.error(<Text string={data.msg} parse={true} />);
          }
        })
        .catch((error) => {
          console.log(error);
          setProcessingTo(false);
          // toast.error(error);
        });
    } else if (paymentMethod === "credit-card") {
      setProcessingTo(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      const cardElement = elements.getElement(CardElement);
      try {
        const paymentMethodReq = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: `${frmData?.fname} ${frmData?.lname}`,
            email: member?.mem_email,
            address: {
              line1: frmData?.address1,
              line2: frmData?.address2, // Use line2 if you have a second address line
              city: frmData?.city,
              state: getStateById(frmData?.state),
              postal_code: frmData?.postal_code,
              country: getCountryById(frmData?.country),
            },
          },
        });
        if (paymentMethodReq.error) {
          setCheckoutError(paymentMethodReq.error.message);
          setProcessingTo(false);
          return;
        } else {
          var form_data = new FormData();
          for (var key in frmData) {
            form_data.append(key, watchAllFields[key]);
          }
          form_data.append("payment_method", paymentMethodReq.paymentMethod.id);
          form_data.append("card_holder_name", frmData?.card_holder_name);
          await postData("api/create-lease-payment-intent", form_data).then(
            (data) => {
              let client_secret_setup = data.arr.setup_client_secret;
              if (data.status === 1) {
                let card_result = stripe.confirmCardSetup(client_secret_setup, {
                  payment_method: {
                    card: cardElement,
                    // billing_details: billingDetails,
                  },
                });

                if (card_result.error) {
                  setCheckoutError(card_result.error.message);
                  setProcessingTo(false);
                  return;
                } else {
                  let paymentMethod = "";
                  let setup_intent_id = "";
                  card_result.then((response) => {
                    if (response.error) {
                      setCheckoutError(response.error.message);
                      setProcessingTo(false);
                      return;
                    } else {
                      paymentMethod = response.setupIntent.payment_method;
                      setup_intent_id = response.setupIntent.id;
                      chargePayment(
                        paymentMethodReq,
                        setup_intent_id,
                        paymentMethod,
                        data.arr.customer
                      );
                    }
                  });
                }
              } else {
                toast.error(data?.msg);
              }
            }
          );
        }
      } catch (err) {
        setCheckoutError(err.message);
      }
    } else if (paymentMethod === "ach") {
      let newData = { ...frmData, payment_method: "ach" };
      dispatch(saveCard(newData, true, true));
    }
  };
  useEffect(() => {
    if (payment_method_row?.id > 0) {
      if (payment_method_row?.payment_method === "ach") {
        setValue(
          "account_type",
          payment_method_row?.account_type
            ? payment_method_row?.account_type
            : ""
        );
        setValue(
          "card_holder_name",
          payment_method_row?.card_holder_name
            ? payment_method_row?.card_holder_name
            : ""
        );
        setValue(
          "account_number",
          payment_method_row?.card_number ? payment_method_row?.card_number : ""
        );
        setValue(
          "routing_number",
          payment_method_row?.routing_number
            ? payment_method_row?.routing_number
            : ""
        );
      } else if (payment_method_row?.payment_method === "credit-card") {
        setValue("card_holder_name", payment_method_row?.card_holder_name);
        setValue(
          "fname",
          payment_method_row?.fname
            ? payment_method_row?.fname
            : member?.mem_fname
        );
        setValue(
          "lname",
          payment_method_row?.lname
            ? payment_method_row?.lname
            : member?.mem_lname
        );
        setValue(
          "address1",
          payment_method_row?.address1
            ? payment_method_row?.address1
            : member?.mem_address1
        );
        setValue(
          "address2",
          payment_method_row?.address2 !== null &&
            payment_method_row?.address2 !== "null" &&
            payment_method_row?.address2 !== undefined &&
            payment_method_row?.address2 !== ""
            ? payment_method_row?.address2
            : member?.mem_address2
        );
        setValue(
          "city",
          payment_method_row?.city ? payment_method_row?.city : member?.mem_city
        );
        setValue(
          "state",
          payment_method_row?.state
            ? payment_method_row?.state
            : member?.mem_state
        );
        setValue(
          "country",
          payment_method_row?.country
            ? payment_method_row?.country
            : member?.mem_country
        );
        setValue(
          "zip_code",
          payment_method_row?.zip_code
            ? payment_method_row?.zip_code
            : member?.mem_zip
        );
      }
    }
  }, [payment_method_row]);

  const chargePayment = async (
    paymentMethodReq,
    setup_id,
    paymentMethodSetup,
    customer_id
  ) => {
    let data = watchAllFields;

    let newData = {
      ...data,
      payment_method_id: paymentMethodReq.paymentMethod.id,
      customer_id: customer_id,
      card_number: paymentMethodReq.paymentMethod.card.last4,
      card_brand: paymentMethodReq.paymentMethod.card.brand,
      card_exp_month: paymentMethodReq.paymentMethod.card.exp_month,
      card_exp_year: paymentMethodReq.paymentMethod.card.exp_year,
      setup_id: setup_id,
      payment_method: "credit-card",
      country: data?.country?.value ? data?.country?.value : data?.country,
      state: data?.state?.value ? data?.state?.value : data?.state,
    };
    dispatch(saveCard(newData, false, true));
    // setAddNewCreditCard(false)
    setProcessingTo(false);
  };
  const watchAllFields = watch();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      {payment_method_row?.id > 0 ? (
        <fieldset>
          {payment_method_row?.payment_method === "ach" ? (
            <PaymentMethodBankStep
              setFormStep={setFormStep}
              register={register}
              errors={errors}
              member={member}
              payment_method_row={payment_method_row}
              isProcessing={isProcessing}
            />
          ) : (
            <PaymentMEthodCreditCardStep
              setFormStep={setFormStep}
              register={register}
              errors={errors}
              CardElement={CardElement}
              checkoutError={checkoutError}
              isProcessing={isProcessing}
              handleCardDetailsChange={handleCardDetailsChange}
              payment_method_row={payment_method_row}
              watchAllFields={watchAllFields}
              states={states}
              countries={countries}
              control={control}
            />
          )}
        </fieldset>
      ) : formStep === 0 ? (
        <PAymentMEthodStep1
          paymentMethod={paymentMethod}
          changePaymentMethod={changePaymentMethod}
          setFormStep={setFormStep}
        />
      ) : formStep === 1 ? (
        <fieldset>
          {paymentMethod === "ach" ? (
            <PaymentMethodBankStep
              setFormStep={setFormStep}
              register={register}
              errors={errors}
              member={member}
            />
          ) : paymentMethod === "credit-card" ? (
            <PaymentMEthodCreditCardStep
              setFormStep={setFormStep}
              register={register}
              errors={errors}
              CardElement={CardElement}
              checkoutError={checkoutError}
              isProcessing={isProcessing}
              handleCardDetailsChange={handleCardDetailsChange}
              watchAllFields={watchAllFields}
              states={states}
              countries={countries}
              control={control}
            />
          ) : (
            ""
          )}
        </fieldset>
      ) : (
        ""
      )}
    </form>
  );
}
