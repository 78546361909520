import React from "react";
import { NavLink } from "react-router-dom";

export default function ListingLst({ item, deleteItem, is_expired }) {
  return (
    <>
      <div className="col">
        <div className="inner">
          {/* <div className={item?.type === 'rent' ? "color_lbl for_rent" : "color_lbl for_sale"}>
                        {

                            <p>{item?.type}</p>
                        }

                    </div> */}
          <NavLink to={"/listing-details/" + item.id} className="image">
            <img src={item.image} alt="" />
            <div className="show_lbl_overlay expire_listing_label">
              {item.expire_label}
            </div>
            <div
              className={
                item?.type === "rent"
                  ? "show_lbl_overlay listing_type_label for_rent"
                  : "show_lbl_overlay listing_type_label"
              }
            >
              {item.type}
            </div>
          </NavLink>
          <div className="cntnt">
            <h3>{item.title}</h3>
            <ul className="flex">
              <li>
                {item?.studio === "yes" ? (
                  <span>Studio</span>
                ) : (
                  <span>
                    {parseInt(item.beds) > 0 ? item.beds + " Bed" : "Studio"}
                  </span>
                )}
              </li>
              <li>
                <span>{item.baths} Bath</span>
              </li>
              <li>
                <span>{item.sf}</span>
              </li>
            </ul>
            <ul className="flex">
              <li>
                <span dangerouslySetInnerHTML={{ __html: item.address }} />
              </li>
            </ul>
            <div className="flex price_flex">
              <div className="price">{item.price}</div>
              <p>{item.time}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
