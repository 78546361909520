import React, { useState } from "react";
import ExpenseLst from "./lst";
import Pagination from "../../../common/pagination";

export default function ExpenseBlk({ items, deleteExpense, expense_type }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        currentItems?.map((item, index) => {
          return (
            <ExpenseLst
              item={item}
              key={index}
              deleteExpense={deleteExpense}
              expense_type={expense_type}
            />
          );
        })
      ) : (
        <div>
          <div className="alert alert-danger">No expense(s) found!</div>
        </div>
      )}
      <Pagination
        paginate={paginate}
        itemsPerPage={itemsPerPage}
        totalItems={items?.length}
        currentPage={currentPage}
      />
    </>
  );
}
