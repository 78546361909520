import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import ListingBlk from "./sections/ListingBlk";
import Sidebar from "./sections/sidebar";
import { ToastContainer } from "react-toastify";

import {
  fetchListings,
  deleteListing,
} from "../../../states/actions/fetchListings";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from "../../../states/actions/fetchSiteSettings";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { getBranchListings, getSiteImages } from "../../../helpers/api";
import CommercialListingSort from "./sections/commercialListingSort";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function Listing() {
  const dispatch = useDispatch();
  const [listings, setListings] = useState({
    list_arr: [],
    loading: true,
  });
  const [listingType, setListingType] = useState("active");
  const changeType = (type) => {
    setListingType(type);
  };
  const data = useSelector((state) => state.fetchListings.content);
  const isLoading = useSelector((state) => state.fetchListings.isLoading);
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  const handleDelete = (data) => {
    dispatch(deleteListing(data));
  };

  useEffect(() => {
    dispatch(fetchListings());
    dispatch(fetchSiteSettings());
  }, []);
  useEffect(() => {
    if (data?.listings !== undefined && data.listings !== null) {
      setListings({ ...listings, list_arr: data?.listings, loading: false });
    }
  }, [data]);

  async function handleBranchChange(e) {
    let listings = await getBranchListings(e.target.value);
    if (listings?.status === 1) {
      setListings({
        ...listings,
        list_arr: listings?.listings,
        loading: false,
      });
    }
    // setProperty(properties.properties);
  }
  useDocumentTitle("Listings - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const expired_listings = data?.expired_listings;
  const branches = data.branches;
  return (
    <>
      <div index="" className="main_dashboard commercial_dashboard_new">
        <ToastContainer />
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="listing" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <Breadcrumb page_name="Listings" add_link="/add-listing" />
                ) : (
                  <Breadcrumb page_name="Listings" />
                )}

                <CommercialListingSort
                  listingType={listingType}
                  changeType={changeType}
                  active_count={listings?.list_arr?.length}
                  expired_count={expired_listings?.length}
                  branches={branches}
                  handleBranchChange={handleBranchChange}
                />
                {listingType === "active" ? (
                  <ListingBlk
                    items={listings?.list_arr}
                    handleDelete={handleDelete}
                    branches={branches}
                    handleBranchChange={handleBranchChange}
                    listings={listings}
                    listingType={listingType}
                  />
                ) : (
                  <ListingBlk
                    items={expired_listings}
                    handleDelete={handleDelete}
                    listingType={listingType}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
