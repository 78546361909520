import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";

export default function PaymentMEthodCreditCardStep({
  setFormStep,
  errors,
  register,
  CardElement,
  handleCardDetailsChange,
  checkoutError,
  isProcessing,
  payment_method_row = null,
  watchAllFields,
  states,
  countries,
  control,
}) {
  const isAddNewCardProcessing = useSelector(
    (state) => state.payments.isAddNewCardProcessing
  );

  return (
    <>
      <div className="row form_row">
        <div className="col-xs-12 info_heading_confirm">
          {payment_method_row?.id > 0 ? (
            <h4>Update Credit Card</h4>
          ) : (
            <h4>Add Credit Card</h4>
          )}
        </div>
        {payment_method_row?.id > 0 ? (
          <div className="col-xs-12">
            <h6>Card Number</h6>
            <div className="form_blk">
              <input
                type="text"
                className="txtBox"
                defaultValue={"**********" + payment_method_row?.card_number}
                disabled={true}
              />
            </div>
          </div>
        ) : (
          <div className="col-xs-12">
            <h6>Card Number</h6>
            <div class="form_blk credit_card_inner">
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: "14px",
                      color: "#424770",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                    },
                    invalid: {
                      color: "#9e2146",
                    },
                  },
                }}
                onChange={handleCardDetailsChange}
              />
              {checkoutError && (
                <div className="alert alert-danger">{checkoutError}</div>
              )}
            </div>
          </div>
        )}
        <div className="col-xs-6">
          <h6>First Name</h6>
          <div className="form_blk">
            <input
              type="text"
              name="fname"
              id="fname"
              className="txtBox"
              defaultValue={watchAllFields?.fname}
              {...register("fname", {
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "Invalid value",
                },
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="fname"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Last Name</h6>
          <div className="form_blk">
            <input
              type="text"
              name="lname"
              id="lname"
              className="txtBox"
              defaultValue={watchAllFields?.lname}
              {...register("lname", {
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "Invalid value",
                },
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="lname"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-6">
          <h6>Address Line 1</h6>
          <div className="form_blk">
            <input
              type="text"
              name="address1"
              id="address1"
              className="txtBox"
              defaultValue={watchAllFields?.address1}
              {...register("address1", {
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="address1"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>Address Line 2</h6>
          <div className="form_blk">
            <input
              type="text"
              name="address2"
              id="address2"
              className="txtBox"
              defaultValue={
                watchAllFields?.address2 !== null &&
                watchAllFields?.address2 !== undefined &&
                watchAllFields?.address2 !== "" &&
                watchAllFields?.address2 !== "null"
                  ? watchAllFields?.address2
                  : ""
              }
              {...register("address2")}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-6">
          <h6>City</h6>
          <div className="form_blk">
            <input
              type="text"
              name="city"
              id="city"
              className="txtBox"
              defaultValue={watchAllFields?.city}
              {...register("city", { required: "Required" })}
            />
            <ErrorMessage
              errors={errors}
              name="city"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>ZIP Code</h6>
          <div className="form_blk">
            <input
              type="text"
              name="zip_code"
              id="zip_code"
              className="txtBox"
              defaultValue={watchAllFields?.zip_code}
              {...register("zip_code", {
                required: "Required",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Invalid value",
                },
                minLength: {
                  value: 5,
                  message: "Zip code should be  equal to 5 digits",
                },
                maxLength: {
                  value: 5,
                  message: "Zip code should be  equal to 5 digits",
                },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="zip_code"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-6">
          <h6>Country</h6>
          <div className="form_blk">
            <Controller
              name="country"
              control={control}
              {...register("country", { required: "Required" })}
              defaultValue={watchAllFields?.country}
              render={({ field }) => (
                <Select
                  options={countries}
                  {...field}
                  label="Select Country"
                  value={countries?.find(
                    (option) => option.value === field?.value
                  )}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="country"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="col-xs-6">
          <h6>State</h6>
          <div className="form_blk">
            <Controller
              name="state"
              control={control}
              defaultValue={watchAllFields?.state}
              {...register("state", { required: "Required" })}
              render={({ field }) => (
                <Select
                  options={states}
                  {...field}
                  label="Select State"
                  value={states?.find(
                    (option) => option.value === field?.value
                  )}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="state"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div>
        <div className="clearfix"></div>

        {/* <div className="col-xs-12">
          <h6>Name On Card</h6>
          <div className="form_blk">
            <input
              type="text"
              name=""
              class="txtBox"
              {...register("card_holder_name", {
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z ]*$/,
                  message: "Invalid value",
                },
                required: "Required",
              })}
            />
            <ErrorMessage
              errors={errors}
              name="card_holder_name"
              render={({ message }) => (
                <p className="error">
                  <i className="fi-warning"></i> {message}
                </p>
              )}
            />
          </div>
        </div> */}
      </div>
      <div
        className={
          payment_method_row?.id > 0
            ? "cmnBtn justify_btn_center"
            : "cmnBtn text-center"
        }
      >
        {payment_method_row?.id > 0 ? (
          ""
        ) : (
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => setFormStep(0)}
          >
            Previous
          </button>
        )}
        <button
          type="submit"
          class="webBtn colorBtn"
          disabled={isProcessing || isAddNewCardProcessing ? "disabled" : ""}
        >
          {payment_method_row?.id > 0 ? "Update" : "Submit"}
          {isProcessing || isAddNewCardProcessing ? (
            <i className="spinner"></i>
          ) : (
            ""
          )}
        </button>
      </div>
    </>
  );
}
