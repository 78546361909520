import React, { useEffect } from "react";

import { fetchServices } from "../../states/actions/fetchServices";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";


import ServicesBanner from './section/services-banner';
import ServicesGrid from './section/services-grid';

export default function Services() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchServices.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchServices.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchServices());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const services = content;

    return (
        <>
            <ServicesBanner data={services.intro} />
            <ServicesGrid data={services.grid} />
        </>
    )
}
