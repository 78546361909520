import React from 'react'
import Markets from '../../common/markets'

export default function TopMarkets({ data }) {
    return (
        <>
            <section className="top_rated_searches">
                <div className="contain">
                    <div className="flex">
                        <div className="colL">
                            <h4>{data.heading}</h4>
                            <ul>
                                {data.blocks?.map((val, index) => {
                                    return (
                                        <Markets data={val} key={index} />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
