import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";
import {
  FETCH_FRONTEND_PROPERTY_CONTENT,
  FETCH_FRONTEND_PROPERTY_CONTENT_SUCCESS,
  FETCH_FRONTEND_PROPERTY_CONTENT_FAILED,
  PROPERTIES_DELETE__MESSAGE,
  PROPERTIES_DELETE_MESSAGE_SUCCESS,
  PROPERTIES_DELETE__MESSAGE_FAILED,
  LISTINGS_DELETE__MESSAGE,
  LISTINGS_DELETE_MESSAGE_SUCCESS,
  LISTINGS_DELETE__MESSAGE_FAILED,
} from "./actionTypes";

export const fetchUserPropertyDetails = (slug) => (dispatch) => {
  dispatch({
    type: FETCH_FRONTEND_PROPERTY_CONTENT,
    payload: null,
  });
  http
    .get("user-property-details-page/" + slug)
    .then(({ data }) => {
      if (data?.status === 0) {
        window.location.href = "/login";
      }
      dispatch({
        type: FETCH_FRONTEND_PROPERTY_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_FRONTEND_PROPERTY_CONTENT_FAILED,
        payload: error,
      });
    });
};

export const deleteProperty = (formData) => (dispatch) => {
  dispatch({
    type: PROPERTIES_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-property/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/properties");
        }, 2000);
        dispatch({
          type: PROPERTIES_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: PROPERTIES_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: PROPERTIES_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const deleteListing = (formData) => (dispatch) => {
  dispatch({
    type: LISTINGS_DELETE__MESSAGE,
    payload: null,
  });
  http
    .post("delete-listing/" + formData, helpers.doObjToFormData(formData))
    .then(({ data }) => {
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        setTimeout(() => {
          window.location.replace("/listing");
        }, 2000);
        dispatch({
          type: LISTINGS_DELETE_MESSAGE_SUCCESS,
          payload: data,
        });
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: LISTINGS_DELETE__MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: LISTINGS_DELETE__MESSAGE_FAILED,
        payload: error,
      });
    });
};
