import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SIGNUP_CONTENT,
  FETCH_SIGNUP_CONTENT_SUCCESS,
  FETCH_SIGNUP_CONTENT_FAILED,
  SIGNUP_MESSAGE,
  SIGNUP_MESSAGE_SUCCESS,
  SIGNUP_MESSAGE_FAILED,
  VERIFY_MESSAGE,
  VERIFY_MESSAGE_SUCCESS,
  VERIFY_MESSAGE_FAILED,
} from "./actionTypes";

export const fetchSignup = () => (dispatch) => {
  dispatch({
    type: FETCH_SIGNUP_CONTENT,
    payload: null,
  });
  http
    .get("signup-page")
    .then(({ data }) => {
      dispatch({
        type: FETCH_SIGNUP_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SIGNUP_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const postSignup = (formData) => (dispatch) => {
  dispatch({
    type: SIGNUP_MESSAGE,
    payload: null,
  });
  http
    .post("signup", helpers.doObjToFormData(formData))
    .then(({ data }) => {
      //
      if (data.status) {
        // toast.success(
        //     `${data.msg}`,
        //     TOAST_SETTINGS
        // );
        dispatch({
          type: SIGNUP_MESSAGE_SUCCESS,
          payload: data,
        });
        setTimeout(() => {
          window.location.replace("/member-verification");
        }, 2000);
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SIGNUP_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SIGNUP_MESSAGE_FAILED,
        payload: error,
      });
    });
};
export const postVerifyOtp = (formData) => (dispatch) => {
  dispatch({
    type: VERIFY_MESSAGE,
    payload: null,
  });
  http
    .post("verify-otp", formData)
    .then(({ data }) => {
      // ; return;
      if (data.status) {
        toast.success(`${data.msg}`, TOAST_SETTINGS);
        dispatch({
          type: VERIFY_MESSAGE_SUCCESS,
          payload: data,
        });
        if (data?.email_verify === 1) {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 3000);
        } else {
          setTimeout(() => {
            window.location.replace("/dashboard");
          }, 3000);
        }
      } else {
        toast.error(<Text string={data.msg} parse={true} />, TOAST_SETTINGS);
        dispatch({
          type: SIGNUP_MESSAGE_FAILED,
          payload: null,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VERIFY_MESSAGE_FAILED,
        payload: error,
      });
    });
};
