import React, { useEffect } from "react";

import { fetchPartners } from "../../states/actions/fetchPartners";
import { useSelector, useDispatch } from "react-redux";
import { fetchSiteSettings } from '../../states/actions/fetchSiteSettings';
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoadingScreen from "../common/LoadingScreen";

import AllBanner from './section/all-banner';
import PartnerBenefit from './section/partner-benefit';
export default function Partner() {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.fetchPartners.content);
    const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
    const isLoading = useSelector((state) => state.fetchPartners.isLoading);
    const isSiteSettingsLoading = useSelector(
        (state) => state.fetchSiteSettings.isLoading
    );

    useEffect(() => {
        dispatch(fetchPartners());
        dispatch(fetchSiteSettings());
    }, []);
    useDocumentTitle((data.page_title) ? data.page_title : data.page_title, (settings) ? settings.site_name : 'Loftus');
    if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
    let content = data.content;
    const partner = content;

    return (
        <>
            <AllBanner data={partner.intro} />
            <PartnerBenefit data={partner.benefits} />
        </>
    )
}
