import React from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
export default function ResetPasswordForm({ data, handleResetPassword, isFormProcessing, emptyFormData }) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = data => {
        handleResetPassword(data);
    }
    return (
        <>
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
                <h3>{data.heading}</h3>
                <p>{data.text}</p>
                <div className="txtGrp pasDv">
                    <label htmlFor="" className="move">New Password</label>
                    <input type="password" name="password" id="password" className='txtBox'  {...register("password", {
                        required: "Required", pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                            message: "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit."
                        }
                    })} />
                    <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                </div>
                <div className="txtGrp pasDv">
                    <label htmlFor="" className="move">Confirm Password</label>
                    <input type="password" name="confirm_password" id="confirm_password" className="txtBox" {...register("confirm_password", {
                        required: "Required",
                        validate: (val) => {
                            if (watch('password') != val) {
                                return "Your passwords do no match";
                            }
                        },
                    })} />
                    <ErrorMessage
                        errors={errors}
                        name="confirm_password"
                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                    />
                </div>
                <div className="bTn text-center">
                    <button type="submit" className="webBtn blockBtn icoBtn" disabled={(isFormProcessing === true) ? 'disabled' : ''}>{(isFormProcessing === true) ? <i className="spinner"></i> : 'Submit'}  <i className="spinner hidden" /></button>
                </div>
            </form>
        </>
    )
}
