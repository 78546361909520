import React from 'react'
import { NavLink } from 'react-router-dom';

export default function ResendCode({ resendOtpCode, phoneVerify }) {
    return (
        <>
            <div className='resend_blk'>
                {
                    phoneVerify === true ?
                        <>
                            <div className="appLoad">

                                <div className="appLoader"><span className="spiner"></span></div>

                            </div>
                        </>
                        :
                        <>
                            <p>Didn't receive a code? <NavLink to="#!" className="" disabled={(phoneVerify === true) ? 'disabled' : ''} onClick={resendOtpCode}>Resend</NavLink></p>

                        </>
                }
            </div>
        </>
    )
}
