import { useSelector, useDispatch } from "react-redux";
import { withdrawLease } from "../../../../states/actions/leases";
import { ToastContainer } from "react-toastify";

export default function LeaseDetailsWithdrawLeasePopup({
  lease_row,
  setLeaseWithdraw
}) {
  const disptach = useDispatch();
  const isWithdrawLease = useSelector((state) => state.leases.isWithdrawLease);
  const handleWithdrawLease = (e) => {
    e.preventDefault();
    const newData = {
      lease_id: lease_row?.id
    };
    disptach(withdrawLease(newData));
  };
  return (
    <section className="popup update_popup termination_popup small_popup">
      <ToastContainer />
      <div className="tableDv">
        <div className="tableCell">
          <div className="_inner">
            <div
              className="crosBtn"
              onClick={() => setLeaseWithdraw({ show: false, item: null })}
            ></div>
            <h4>Are you sure you want to withdraw this lease?</h4>

            <div class="bTn justify_btn_center">
              <a
                href="#!"
                class="webBtn"
                onClick={() => setLeaseWithdraw({ show: false, item: null })}
              >
                No
              </a>
              <a
                href="#!"
                class="webBtn colorBtn"
                onClick={handleWithdrawLease}
                disabled={isWithdrawLease}
              >
                Yes {isWithdrawLease ? <i className="spinner"></i> : ""}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
