import React, { useEffect, useState } from "react";
import Breadcrumb from "../dashboard/sections/breadcrumb";
import Sidebar from "./sections/sidebar";

import {
  fetchContacts,
  deleteContact,
  getFilteredContacts,
  postContact,
} from "../../../states/actions/fetchSingleContacts";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import LoadingScreen from "../../common/LoadingScreen";
import { ToastContainer } from "react-toastify";
import ContactSort from "./contacts/sort";
import ContactBlk from "./contacts/blk";
const mem_permissions = localStorage.getItem("mem_permissions");
const super_admin = localStorage.getItem("super_admin");

export default function Contacts() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fetchSingleContacts.content);
  const isLoading = useSelector((state) => state.fetchSingleContacts.isLoading);
  const isFilterLoading = useSelector(
    (state) => state.fetchSingleContacts.isFilterLoading
  );
  const isItemDeleted = useSelector(
    (state) => state.fetchSingleContacts.isItemDeleted
  );
  const isSiteSettingsLoading = useSelector(
    (state) => state.fetchSiteSettings.isLoading
  );
  useEffect(() => {
    dispatch(fetchContacts());
  }, []);
  const handleDelete = (e, contact_id) => {
    e.preventDefault();
    dispatch(deleteContact(contact_id));
  };
  const [filterData, setFilterData] = useState({
    branch_id: null,
  });
  const handleBranchChange = (e, type) => {
    setFilterData({ ...filterData, [type]: e.target.value });
    const formData = {
      branch_id: type === "branch_id" ? e.target.value : filterData?.branch_id,
    };
    dispatch(getFilteredContacts(formData));
  };
  useDocumentTitle("Contacts - Loftus");
  if (isLoading || isSiteSettingsLoading) return <LoadingScreen />;
  const branches = data?.branches;
  const active_contacts = data?.active_contacts;

  return (
    <>
      <ToastContainer />
      <div index="" className="main_dashboard commercial_dashboard_new">
        <section className="dashboard">
          <div className="contain">
            <div className="flex dash_intro_flex">
              <div className="colL">
                <Sidebar active="contacts" />
              </div>
              <div className="colR ">
                {mem_permissions === "admin" || mem_permissions === "power" ? (
                  <Breadcrumb page_name="Contacts" add_link="/add-contact" />
                ) : (
                  <Breadcrumb page_name="Contacts" />
                )}
                <div className="page_body">
                  <div class="listing_list_c">
                    {isItemDeleted || isFilterLoading ? (
                      <div className="loadingDisabled">
                        <i className="spinner"></i>
                      </div>
                    ) : (
                      ""
                    )}
                    <ContactSort
                      handleBranchChange={handleBranchChange}
                      branches={branches}
                    />
                    <ContactBlk
                      items={active_contacts}
                      handleDelete={handleDelete}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
