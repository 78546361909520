import React from 'react'
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

export default function PasswordForm({ handlePasswordChange, isFormProcessing }) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ mode: 'onChange' });
    const onSubmit = data => {
        handlePasswordChange(data)
    }
    return (
        <>
            <form action="" method="POST" onSubmit={handleSubmit(onSubmit)}>

                <fieldset>
                    <div className="setting_blk">
                        <div className="settings_header">
                            {/* <h4>Change Password</h4> */}
                            <div className="info">
                                <strong></strong>
                                <div className="infoIn ckEditor">
                                    <p>Your password must contain the following:</p>
                                    <ol>
                                        <li>At least 8 characters in length (a strong password has at least 14 characters)</li>
                                        <li>At least 1 letter and at least 1 number or symbol</li>
                                    </ol>
                                </div>
                            </div>

                        </div>
                        <div className="row form_row">
                            <div className="col-xs-4">
                                <h6>Old Password</h6>
                                <div className="form_blk">
                                    <input type="password" name="old_password" id="old_password" className="txtBox" {...register("old_password", { required: "Required" })} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="old_password"
                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <h6>New Password</h6>
                                <div className="form_blk">
                                    <input type="password" name="new_password" id="new_password" className="txtBox" {...register("new_password", {
                                        required: "Required", pattern: {
                                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\>\=\?\@\[\]\{\}\\\\\^\_\`\~]{8,}$/,
                                            message: "The password should contain at least 8 characters, that contain at least one lowercase letter, one uppercase letter and one numeric digit."
                                        }
                                    })} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="new_password"
                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-4">
                                <h6>Confirm New Password</h6>
                                <div className="form_blk">
                                    <input type="password" name="confirm_password" id="confirm_password" className="txtBox" {...register("confirm_password", {
                                        required: "Required",
                                        validate: (val) => {
                                            if (watch('new_password') != val) {
                                                return "Your passwords do no match";
                                            }
                                        },
                                    })} />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirm_password"
                                        render={({ message }) => <p className='error'><i className="fi-warning"></i> {message}</p>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="cmnBtn text-center">
                            <button type="submit" className="webBtn colorBtn nextBtn" disabled={(isFormProcessing === true) ? 'disabled' : ''}>{(isFormProcessing === true) ? <i className="spinner"></i> : 'Update Password'}</button>
                        </div>
                    </div>
                </fieldset>

            </form>
        </>
    )
}
