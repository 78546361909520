import React, { useState } from "react";
import EmployeeLst from "./lst";
import Pagination from "../../../common/pagination";
const mem_permissions = localStorage.getItem("mem_permissions");

export default function EmployeeBlk({ items, handleDelete }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);

  //get Current posts
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;
  const currentItems = items?.slice(indexOfFirstPost, indexOfLastPost);
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 3);
  }
  return (
    <>
      <div class="blk blockLst user_dash_tbl cell_block_cell">
        <table>
          <thead>
            <tr>
              <th class="hide_cell_hyp">Branch</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              {mem_permissions === "admin" ? (
                <th class="text-right hide_cell_hyp">Actions</th>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {currentItems && currentItems.length > 0 ? (
              currentItems?.map((item, index) => {
                return (
                  <EmployeeLst
                    key={index}
                    item={item}
                    handleDelete={handleDelete}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="alert alert-danger">
                    No employee(s) found!
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          paginate={paginate}
          itemsPerPage={itemsPerPage}
          totalItems={items?.length}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}
