import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchUserSingleOffer,
  deleteOffer,
  deleteOfferDecline,
} from "../../../states/actions/fetchUserSingleOffer";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "./sections/breadcrumb";
import LoadingScreen from "../../common/LoadingScreen";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import PropertyInfo from "./offers/property-info";
import OfferDetailsRight from "./offers/offer-details";
import { ToastContainer } from "react-toastify";

export default function OfferDetails() {
  const [reportPopUp, setReportPopup] = useState(null);
  const showPopup = (content, status_flag) => {
    if (status_flag === true) {
      setReportPopup(content);
    }
  };
  const closePopup = () => {
    setReportPopup(null);
  };

  const dispatch = useDispatch();
  const { offer_id } = useParams();

  const data = useSelector((state) => state.fetchUserSingleOffer.content);
  const settings = useSelector((state) => state.fetchSiteSettings.siteSettings);
  const isLoading = useSelector(
    (state) => state.fetchUserSingleOffer.isLoading
  );
  const isFormProcessing = useSelector(
    (state) => state.fetchUserSingleOffer.isFormProcessing
  );
  const handleOfferDelete = (data) => {
    dispatch(deleteOffer(data));
  };
  const handleOfferTenantDecline = (data) => {
    dispatch(deleteOfferDecline(offer_id));
  };

  useEffect(() => {
    dispatch(fetchUserSingleOffer(offer_id));
  }, []);
  useDocumentTitle(
    data.page_title ? data.page_title : data.page_title,
    settings ? settings.site_name : "Loftus"
  );
  if (isLoading) return <LoadingScreen />;
  let property_row = data.property_row;
  let listing_row = data?.listing_row;
  let user = data?.user;
  let offer_row = data?.offer_row;
  let current_offer_owner = data?.current_offer_owner;
  let is_not_tenant_offer_completed = offer_row?.is_not_tenant_offer_completed;
  return (
    <>
      <main index className="main_dashboard">
        <section className="dashboard">
          <div className="contain">
            <ToastContainer />
            <Breadcrumb
              page_name={"My Offer: " + offer_row?.offer_num}
              parent_name="Offers"
              parent_link="/offers"
            />
            <div className="page_body">
              <div className="offer_dash_detail_page">
                <div
                  class={
                    listing_row?.listing_type === "sale"
                      ? "sale_offer_flex flex"
                      : "flex"
                  }
                >
                  <PropertyInfo
                    property_row={property_row}
                    user={user}
                    tenant={true}
                    branch_id={
                      property_row?.mem_type === "company"
                        ? property_row?.branch_row?.id
                        : false
                    }
                  />
                  <OfferDetailsRight
                    offer_row={offer_row}
                    showPopup={showPopup}
                    listing_row={listing_row}
                    handleOfferDelete={handleOfferDelete}
                    current_offer_owner={current_offer_owner}
                    offer_id={offer_id}
                    is_not_tenant_offer_completed={
                      is_not_tenant_offer_completed
                    }
                    property_row={property_row}
                    handleOfferTenantDecline={handleOfferTenantDecline}
                    isFormProcessing={isFormProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {reportPopUp !== null &&
        reportPopUp !== undefined &&
        reportPopUp !== "" ? (
          <section
            className="popup popup_for_lease offerReportPopup"
            data-popup="choose-lease-property"
          >
            <div className="tableDv">
              <div className="tableCell">
                <div className="_inner">
                  <div className="crosBtn" onClick={() => closePopup()}></div>
                  <div dangerouslySetInnerHTML={{ __html: reportPopUp }} />
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )}
      </main>
    </>
  );
}
