import {
  FETCH_SINGLE_CREDIT_CONTENT,
  FETCH_SINGLE_CREDIT_CONTENT_SUCCESS,
  FETCH_SINGLE_CREDIT_CONTENT_FAILED,
  SINGLE_CREDIT_MESSAGE,
  SINGLE_CREDIT_MESSAGE_SUCCESS,
  SINGLE_CREDIT_MESSAGE_FAILED,
  FETCH_CREDIT_CONTENT,
  FETCH_CREDIT_CONTENT_FAILED,
  FETCH_CREDIT_CONTENT_SUCCESS,
  CREDIT_DELETE__MESSAGE,
  CREDIT_DELETE_MESSAGE_SUCCESS,
  CREDIT_DELETE__MESSAGE_FAILED,
  GET_FILTER_CREDIT,
  GET_FILTER_CREDIT_FAILED,
  GET_FILTER_CREDIT_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  content: {},
  error: false,
  isFormProcessing: false,
  emptyFormData: false,
  floor_plans: [],
  isFilterLoading: false,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTER_CREDIT:
      return {
        ...state,
        isFilterLoading: true,
      };
    case GET_FILTER_CREDIT_SUCCESS:
      return {
        ...state,
        isFilterLoading: false,
        content: payload,
      };

    case GET_FILTER_CREDIT_FAILED:
      return {
        ...state,
        isFilterLoading: false,
        error: payload,
      };
    case FETCH_CREDIT_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_CREDIT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_CREDIT_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case FETCH_SINGLE_CREDIT_CONTENT:
      return {
        ...state,
        isLoading: true,
        content: {},
      };
    case FETCH_SINGLE_CREDIT_CONTENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        content: payload,
      };
    case FETCH_SINGLE_CREDIT_CONTENT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SINGLE_CREDIT_MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case SINGLE_CREDIT_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case SINGLE_CREDIT_MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    case CREDIT_DELETE__MESSAGE:
      return {
        ...state,
        isFormProcessing: true,
      };
    case CREDIT_DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        isFormProcessing: false,
        emptyFormData: true,
      };
    case CREDIT_DELETE__MESSAGE_FAILED:
      return {
        ...state,
        isFormProcessing: false,
        error: payload,
      };
    default:
      return state;
  }
}
